import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Divider, Form, FormInstance, Select, Switch } from 'antd'
import React, { useState } from 'react'

interface props {
  form: FormInstance<any>
}

export const ListingPeakShaving: React.FC<props> = ({ form }) => {
  const [peakShavingEnabled, setPeakShavingEnabled] = useState(form.getFieldValue('shavingEnabled'))
  const [shavingPrimaryZoneStrategy, setShavingPrimaryZoneStrategy] = useState(
    form.getFieldValue('shavingPrimaryZoneStrategy'),
  )
  const [shavingSecondaryZoneStrategy, setShavingSecondaryZoneStrategy] = useState(
    form.getFieldValue('shavingSecondaryZoneStrategy'),
  )
  const [shavingEventSource, setShavingEventSource] = useState(form.getFieldValue('shavingEventSource'))
  return (
    <>
      <Divider>Peak Shaving Strategy</Divider>
      <Form.Item label={'Peak Shaving Enabled'} name="shavingEnabled" valuePropName="checked">
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          style={{ float: 'left' }}
          checked={peakShavingEnabled}
          onChange={(shavingEnabled: boolean) => {
            setPeakShavingEnabled(shavingEnabled)
          }}
        />
      </Form.Item>
      <Form.Item name="shavingPrimaryZoneStrategy" label="Primary Peak Zone">
        <Select
          placeholder="Primary Peak Zone"
          defaultValue={0}
          onChange={(strategy: number) => setShavingPrimaryZoneStrategy(strategy)}
          value={shavingPrimaryZoneStrategy}
        >
          <Select.Option value={0}>No Charging</Select.Option>
          <Select.Option value={40}>40% Charging</Select.Option>
          <Select.Option value={60}>60% Charging</Select.Option>
          <Select.Option value={80}>80% Charging</Select.Option>
          <Select.Option value={100}>100% Full Speed</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="shavingSecondaryZoneStrategy" label="Secondary Peak Zone">
        <Select
          placeholder="Secondary Peak Zone"
          defaultValue={0}
          onChange={(strategy: number) => setShavingSecondaryZoneStrategy(strategy)}
          value={shavingSecondaryZoneStrategy}
        >
          <Select.Option value={0}>No Charging</Select.Option>
          <Select.Option value={40}>40% Charging</Select.Option>
          <Select.Option value={60}>60% Charging</Select.Option>
          <Select.Option value={80}>80% Charging</Select.Option>
          <Select.Option value={100}>100% Full Speed</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="shavingEventSource" label="Peak Shaving Event Source">
        <Select
          placeholder="Peak Shaving Event Source"
          defaultValue="manual"
          onChange={(eventSource: string) => setShavingEventSource(eventSource)}
          value={shavingEventSource}
        >
          <Select.Option value="manual">Manual Schedule</Select.Option>
        </Select>
      </Form.Item>
    </>
  )
}
