import { Checkbox, Select, Spin } from 'antd'
import { debounce } from 'lodash'
import { MouseEvent, ReactElement, useEffect, useMemo, useRef, useState } from 'react'

import { tenantSVG } from '../../assets/svg/tenant'
import { TenantRef } from '../../models/tenant'
import { FindTenants } from '../../services/data-provider/tenants'
import { useAppState } from '../../state'

import { removeIconSVG } from '../../assets/svg/removeIcon'
import { MultiTenantSelectorContainer } from '../../atom/select'
import { useSelectorTranslation } from '../../hooks/translation/useSelectorTranslation'

interface props {
  defaultTenant?: TenantRef
  onOptionsChange: (options: TenantRef[]) => void
  onClear: () => void
  loading?: boolean
  isCombineRight?: boolean
  noChangeOnFocus?: boolean
}

export const MultiTenantSelector: React.FC<props> = ({
  defaultTenant,
  onOptionsChange,
  onClear,
  loading,
  isCombineRight,
  noChangeOnFocus,
}) => {
  const { IsDesktop, IsLaptop } = useAppState()

  const { locationTextPlaceholder } = useSelectorTranslation()

  const [selected, setSelected] = useState<TenantRef | undefined>(defaultTenant)
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [options, setOptions] = useState<TenantRef[]>([])
  const [selectedOptions, setSelectedOptions] = useState([''])
  const [dirty, setDirty] = useState(false)
  const fetchRef = useRef(0)
  const [fetching, setFetching] = useState(false)

  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      if (value.length >= 1) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setFetching(true)

        FindTenants(value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          setOptions(newOptions)
          setFetching(false)
        })
      }
    }

    return debounce(loadOptions, 800)
  }, [FindTenants, 800])

  const renderOption = (tenant: TenantRef): ReactElement => {
    return (
      <Select.Option key={tenant.id} value={tenant.displayName || tenant.name}>
        <Checkbox
          onClick={(e) => (dirty ? e.stopPropagation() : setDirty(false))}
          checked={selectedOptions.includes(tenant.displayName || tenant.name)}
        >
          {tenant.displayName || tenant.name}
        </Checkbox>
      </Select.Option>
    )
  }

  const handleChange = (value: string[]) => {
    setSelectedOptions(value)
    setDirty(true)
  }

  useEffect(() => {
    selectedOptions.length === 0
      ? onOptionsChange([])
      : onOptionsChange(tenants.filter((t) => selectedOptions.includes(t.displayName || t.name)))
  }, [selectedOptions, tenants])

  useEffect(() => {
    setTenants([...tenants, ...options])
  }, [options])

  const handleOnClear = () => {
    setSelectedOptions([''])
    onClear()
  }

  const canCombineBorder = (isDesktop || isLaptop) && isCombineRight

  const renderTenantLists = (tenants: TenantRef[]) => {
    const final = tenants
      .map((v) => renderOption(v))
      .sort((a: ReactElement, b: ReactElement) => {
        return b.props.children.props.checked - a.props.children.props.checked
      })
    return final
  }

  const handlePlaceholder = () => {
    return selectedOptions.length === 1 ? selectedOptions[0] : `${selectedOptions.length} Tenants Selected`
  }

  const handleMouseDown = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    setDirty(false)
    e.stopPropagation()
  }

  return (
    <MultiTenantSelectorContainer className="tenant-selector" isCombineRight={canCombineBorder}>
      {tenantSVG}
      {!selected && (
        <Select
          disabled={loading}
          showSearch
          mode="multiple"
          allowClear
          loading={fetching}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          style={{ minWidth: 156, width: '100%', lineHeight: '34px' }}
          placeholder={locationTextPlaceholder}
          onClear={handleOnClear}
          onChange={handleChange}
          onSearch={debounceFetcher}
          onMouseDown={(e) => handleMouseDown(e)}
          dropdownMatchSelectWidth={false}
          maxTagCount={0}
          maxTagPlaceholder={handlePlaceholder}
          removeIcon={removeIconSVG}
          options={tenants.map((t) => ({ label: t.displayName || t.name, value: t.displayName || t.name }))}
        >
          {renderTenantLists(tenants)}
        </Select>
      )}
    </MultiTenantSelectorContainer>
  )
}
