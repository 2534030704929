import { Cascader } from 'antd'
import { useEffect, useRef, useState } from 'react'

import { useFormatMessage } from '../../helpers/intl'
import { Option, locations } from '../../helpers/location'
import { useAppState } from '../../state'

import { removeIconSVG } from '../../assets/svg/removeIcon'
import { styled } from '../../theme'

interface props {
  onSearch: (chosenCountries: string[], chosenProvinces: string[]) => void
}

const LocationSelectorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  flex-direction: row;
  position: relative;
  min-width: 240px;

  .ant-select-selector {
    background: ${(props) => props.theme.colors.cultered};
  }

  svg.locationIcon {
    z-index: 1;
    position: absolute;
    top: 47%;
    right: 11px;
    left: 17.5px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all 0.3s;
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    left: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #242e2c;
    opacity: 0.3;
  }

  .ant-select.ant-cascader.ant-select-focused.ant-select-multiple.ant-select-allow-clear.ant-select-open.ant-select-show-search
    .ant-select-selection-placeholder {
    color: #dedede;
  }

  .ant-cascader-checkbox-inner {
    border-radius: 50%;
  }

  .ant-cascader-checkbox-checked .ant-cascader-checkbox-inner {
    background-color: ${(props) => props.theme.colors.neonGreen};
    border-color: ${(props) => props.theme.colors.neonGreen};
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: ${(props) => props.theme.colors.neonGreen};
    box-shadow: none;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 39.19px;
  }

  svg[data-icon='close']:after {
    content: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='red' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 1L1 9M9 9L1 1' stroke='%23242E2C' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    display: block;
    width: 22px;
    height: 10px;
    margin: 10px 5px 0 10px;
  }

  svg.removeIcon {
    width: 7px;
    height: 7px;
  }
`

export const LocationSelector: React.FC<props> = ({ onSearch }) => {
  const { currentUser, language } = useAppState()
  const [selectedLocations, setSelectedLocations] = useState<Option[]>([])
  const placeholder = useFormatMessage('dashboard.text.location', 'Location')
  const [locationText, setLocationText] = useState(placeholder)
  const cascaderRef = useRef<HTMLInputElement>(null)

  const onChange = (selectedLocations: any) => {
    const selectedCountries: string[] = []
    const selectedProvinces: string[] = []
    selectedLocations.forEach((selectedLocation: [string, string]) => {
      const [country, province] = selectedLocation
      !selectedCountries.includes(country) && selectedCountries.push(country)

      // when only the country clicked, the provinces are not selected
      if (!province) {
        const childrenObj: any = locations.filter((t) => t.value === country).map((t) => t.children)
        const allProvinces = childrenObj[0].map((t: Option) => t.value)
        selectedProvinces.push(...allProvinces)
      } else {
        selectedProvinces.push(province)
      }
    })

    onSearch(selectedCountries, selectedProvinces)
    setSelectedLocations(
      selectedProvinces.map((province) => {
        return { value: province, label: province }
      }),
    )
  }

  const handleOnFocus = () => {
    cascaderRef.current && cascaderRef.current.focus()
  }

  const handleOutFocus = () => {
    setLocationText(placeholder)
    cascaderRef.current && cascaderRef.current.blur()
  }

  useEffect(() => {
    cascaderRef.current && cascaderRef.current.blur()
    setLocationText(placeholder)
  }, [language])

  const renderMultiOptions = (options: Option[]): Option[] => {
    const temp: string[] = []
    selectedLocations.map((obj) => temp.push(String(obj.value)))
    options.map((o) =>
      o.children?.sort((a, b) => {
        const indexA = temp.indexOf(String(a.value))
        const indexB = temp.indexOf(String(b.value))

        return indexB - indexA
      }),
    )

    return options
  }

  useEffect(() => {
    selectedLocations && renderMultiOptions(selectedLocations)
    clearClass()
    const selectedProvinces: any = Array.from(
      document.querySelectorAll(
        ".ant-select-dropdown .ant-cascader-menus .ant-cascader-menu:nth-child(2) li[aria-checked='true']",
      ),
    )

    selectedLocations &&
      Array.isArray(selectedProvinces) &&
      selectedProvinces.map((t: any, i: any) => {
        if (i === selectedProvinces.length - 1) {
          t.classList.add('last-item-selected')
        } else {
          t.classList.remove('last-item-selected')
        }
        return t
      })
  }, [selectedLocations])

  const displayRender = (labels: any, selectedOptions: any) =>
    labels.map((label: any, index: any) => {
      const option = selectedOptions[index]
      return (
        <span key={option.value}>
          {label}
          {index === 0 && selectedOptions.length !== 1 ? ', ' : ''}
        </span>
      )
    })

  const clearClass = () => {
    const selectedProvinces: Element[] = Array.from(
      document.querySelectorAll('.ant-select-dropdown .ant-cascader-menus .ant-cascader-menu:nth-child(2) li'),
    )
    Array.isArray(selectedProvinces) &&
      selectedProvinces.map((t: Element) => {
       return t.classList.remove('last-item-selected')
      })
  }

  const onClear = () => {
    clearClass()
    cascaderRef.current && cascaderRef.current.blur()
  }

  return currentUser?.role === 'admin' ? (
    <LocationSelectorContainer className={`location-selector ${selectedLocations.length > 1 ? 'multiple' : 'single'}`}>
      <svg
        width="16"
        height="19"
        viewBox="0 0 16 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="locationIcon"
      >
        <path
          d="M13.3033 13.47L8 18.7733L2.69667 13.47C1.64779 12.4211 0.933489 11.0847 0.644107 9.62986C0.354725 8.17501 0.503256 6.66701 1.07092 5.29657C1.63858 3.92613 2.59987 2.7548 3.83324 1.93069C5.0666 1.10658 6.51665 0.666718 8 0.666718C9.48336 0.666718 10.9334 1.10658 12.1668 1.93069C13.4001 2.7548 14.3614 3.92613 14.9291 5.29657C15.4968 6.66701 15.6453 8.17501 15.3559 9.62986C15.0665 11.0847 14.3522 12.4211 13.3033 13.47ZM8 11.5C8.88406 11.5 9.7319 11.1488 10.357 10.5237C10.9821 9.89855 11.3333 9.05071 11.3333 8.16665C11.3333 7.2826 10.9821 6.43475 10.357 5.80963C9.7319 5.18451 8.88406 4.83332 8 4.83332C7.11595 4.83332 6.2681 5.18451 5.64298 5.80963C5.01786 6.43475 4.66667 7.2826 4.66667 8.16665C4.66667 9.05071 5.01786 9.89855 5.64298 10.5237C6.2681 11.1488 7.11595 11.5 8 11.5ZM8 9.83332C7.55798 9.83332 7.13405 9.65773 6.82149 9.34516C6.50893 9.0326 6.33334 8.60868 6.33334 8.16665C6.33334 7.72463 6.50893 7.3007 6.82149 6.98814C7.13405 6.67558 7.55798 6.49999 8 6.49999C8.44203 6.49999 8.86595 6.67558 9.17851 6.98814C9.49108 7.3007 9.66667 7.72463 9.66667 8.16665C9.66667 8.60868 9.49108 9.0326 9.17851 9.34516C8.86595 9.65773 8.44203 9.83332 8 9.83332Z"
          fill="#54DEC7"
        />
      </svg>

      <Cascader
        placeholder={locationText}
        options={renderMultiOptions(locations)}
        displayRender={displayRender}
        allowClear
        multiple
        showSearch
        onClear={onClear}
        onChange={onChange}
        style={{ width: 159, minWidth: '100%', lineHeight: '34px' }}
        maxTagCount="responsive"
        onFocus={handleOnFocus}
        onBlur={handleOutFocus}
        ref={cascaderRef}
        removeIcon={removeIconSVG}
        clearIcon={removeIconSVG}
      />
    </LocationSelectorContainer>
  ) : (
    <></>
  )
}
