import { Remittance, RemittanceObject } from '../models/remittance'

export const mockRemittance = {
  remittanceType: ['Cash', 'Check', 'Money order', ' Credit card', 'Debit card'],
} as Remittance

export const mockRemittanceObject = [
  {
    id: 6,
    name: 'Something',
  },
  {
    id: 1,
    name: 'Cash',
  },
  {
    id: 2,
    name: 'Check',
  },
  {
    id: 4,
    name: 'Credit Card',
  },
  {
    id: 5,
    name: 'Crypto',
  },
  {
    id: 7,
    name: 'Debit Card',
  },
  {
    id: 8,
    name: 'e-transfer',
  },
  {
    id: 3,
    name: 'Money Order',
  },
] as RemittanceObject[]
