import { DatePicker } from 'antd'
import moment from 'moment'
import React from 'react'
import { CaseConverter } from '../../services/data-provider/case-converter'
import { RangePickerProps } from 'antd/es/date-picker'

const { RangePicker } = DatePicker
interface props {
  startDate?: moment.Moment
  endDate?: moment.Moment
  onChangeDate: (dates: any) => void
  calendarRanges: string[]
  previousQuarter?: any
}

const setCalendarRanges = (calendarRanges: string[], otherRanges?: any) => {
  let ranges: Record<string, [moment.Moment, moment.Moment]> = {
    Today: [moment(), moment()],
    'This Week': [moment().startOf('week'), moment().endOf('week')],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'This Year': [moment().startOf('year'), moment().endOf('year')],
    ...otherRanges,
  }
  const newKeys: Record<string, string> = {
    Today: calendarRanges[0],
    'This Week': calendarRanges[1],
    'This Month': calendarRanges[2],
    'This Year': calendarRanges[3],
    'Previous Quarter': calendarRanges[4],
  }
  let caseConverter = new CaseConverter()
  const updatedRanges = caseConverter.renameKeys(ranges, newKeys)
  return updatedRanges
}

export const DateRangePicker: React.FC<props> = ({
  startDate,
  endDate,
  onChangeDate,
  calendarRanges,
  previousQuarter,
}) => {
  let updateCalendarRanges

  if (previousQuarter) {
    updateCalendarRanges = setCalendarRanges(calendarRanges, previousQuarter)
  } else {
    updateCalendarRanges = setCalendarRanges(calendarRanges)
  }

  let fieldProps: RangePickerProps = {
    ranges: updateCalendarRanges,
    onChange: onChangeDate,
    disabledDate: (current: moment.Moment) => current > moment().endOf('day'),
    allowClear: false,
  }

  if (startDate && endDate) {
    fieldProps.defaultValue = [startDate, endDate]
  }

  return <RangePicker {...fieldProps} />
}
