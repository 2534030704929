import { Button, Divider, Form, Input, message, Select, Space, Spin, Switch } from 'antd'
import { useState } from 'react'

import { layout, tailLayout } from '../../atom/form/page-layout'
import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'
import { renderConnectionMessage } from '../../helpers/status'
import { theme } from '../../theme'

import { ChannelPartnerSelector } from '../selector/channel-partner-selector'
import { MasterAccountSelector } from '../selector/master-account-selector'

import { connectorServiceStatus } from '../../models/connector'
import { MasterAccountRef } from '../../models/master-account'
import { PartnerRef } from '../../models/partner'
import { Tenant, TenantOverview } from '../../models/tenant'

import { useGeneralTranslation } from '../../hooks/translation/useGeneralTranslation'
import { useTenantTranslation } from '../../hooks/translation/useTenantTranslation'

interface tenantTabDetailProps {
  tenant: Tenant
  onSave: (tenant: Tenant) => void
}

export const TenantTabOverview: React.FC<tenantTabDetailProps> = ({ tenant, onSave }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [channelPartner, setChannelPartner] = useState<PartnerRef | undefined>(tenant.channelPartner)
  const [masterAccount, setMasterAccount] = useState<MasterAccountRef | undefined>(tenant.masterAccount)
  const [disabled, setDisabled] = useState(true)
  const [form] = Form.useForm()

  const tenantName = tenant.displayName || tenant.name
  const tenantUpdatedText = useFormatMessage(
    'dashboard.tenantPage.overviewTab.tenantUpdatedMessage',
    '-1 has been updated!',
  ).replace('-1', tenantName)

  const {
    uniformServiceStatusWarningText,
    connectorServiceStatusText,
    uniformServiceStatusText,
    omOptInText,
    masterAccountText,
    channelPartnerText,
    ocppNameText,
    taxRegistrationText,
    taxRegistrationOptionText,
    taxRegistrationOptionNotRegisteredText,
    taxRegistrationOptionJointElectedText,
  } = useTenantTranslation()

  const {
    displayNameText,
    omOptinYesText,
    omOptinNoText,
    noteText,
    modifyText,
    cancelText,
    saveText,
  } = useGeneralTranslation()

  const initialValues = {
    displayName: tenant.displayName,
    status: tenant.status,
    serviceLevel: tenant.serviceLevel,
    omOptin: String(tenant.omOptin),
    uniformServiceStatus: tenant.uniformServiceStatus,
    allowUniformServiceStatus: tenant.allowUniformServiceStatus,
    channelPartner: tenant.channelPartner,
    masterAccount: tenant.masterAccount,
    taxRegistration: tenant.taxRegistration,
    taxNumber: tenant.taxNumber,
  }

  const updateTenantOverview = async (values: TenantOverview) => {
    const data: TenantOverview = {
      displayName: values['displayName'],
      status: values['status'],
      omOptin: values['omOptin'],
      allowUniformServiceStatus: values['allowUniformServiceStatus'],
      channelPartner,
      masterAccount,
      taxRegistration: values['taxRegistration'],
      taxNumber:
        values['taxRegistration'] === 'joint_elected' || values['taxRegistration'] === 'registered'
          ? values['taxNumber']
          : null,
    }
    if (values['uniformServiceStatus'] !== undefined) {
      data['uniformServiceStatus'] = values['uniformServiceStatus']
    }
    setLoading(true)
    onSave({ ...tenant, ...data })
    message.success(tenantUpdatedText)
    setLoading(false)
    setDisabled(true)
  }

  return (
    <Spin spinning={loading}>
      <Form
        {...layout}
        form={form}
        name={`tenant-${tenant?.id}`}
        onFinish={updateTenantOverview}
        initialValues={initialValues}
      >
        <Form.Item name="name" label={ocppNameText}>
          <span className="ant-form-text">{tenant?.name}</span>
        </Form.Item>
        <Form.Item name="displayName" label={displayNameText} rules={[{ required: false }]}>
          <Input disabled={disabled} />
        </Form.Item>
        <Form.Item name="masterAccount" label={masterAccountText} rules={[{ required: false }]}>
          <MasterAccountSelector
            masterAccount={tenant.masterAccount}
            onOptionChange={(selectedMasterAccount) => setMasterAccount(selectedMasterAccount)}
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item name="channelPartner" label={channelPartnerText}>
          <ChannelPartnerSelector
            channelPartner={tenant.channelPartner}
            onOptionChange={(selectedPartner) => setChannelPartner(selectedPartner)}
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item name="omOptin" label={omOptInText} rules={[{ required: false }]}>
          <Select disabled={disabled}>
            <Select.Option value="true">{omOptinYesText}</Select.Option>
            <Select.Option value="false">{omOptinNoText}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="notes" label={noteText}>
          <Input.TextArea disabled={disabled} showCount maxLength={255} style={{ height: theme.space[6] }} />
        </Form.Item>
        <Divider />
        <Form.Item name="allowUniformServiceStatus" label={uniformServiceStatusText}>
          <Switch defaultChecked={tenant.allowUniformServiceStatus} disabled={disabled} />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.allowUniformServiceStatus !== currentValues.allowUniformServiceStatus
          }
        >
          {({ getFieldValue }) =>
            getFieldValue('allowUniformServiceStatus') === true ? (
              <Form.Item
                name="uniformServiceStatus"
                label={connectorServiceStatusText}
                rules={[{ required: false }]}
                validateStatus="warning"
                help={uniformServiceStatusWarningText}
              >
                <Select disabled={disabled} allowClear>
                  {connectorServiceStatus.map((status) => (
                    <Select.Option key={status} value={status}>
                      {renderFormatMessage(renderConnectionMessage(status), status)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : null
          }
        </Form.Item>
        <Divider />
        <Form.Item name="taxRegistration" label={taxRegistrationText}>
          <Select disabled={disabled} allowClear>
            <Select.Option key="registered" value="registered" label={taxRegistrationOptionText}>
              {taxRegistrationOptionText}
            </Select.Option>
            <Select.Option key="not_registered" value="not_registered" label={taxRegistrationOptionNotRegisteredText}>
              {taxRegistrationOptionNotRegisteredText}
            </Select.Option>
            <Select.Option key="joint_elected" value="joint_elected" label={taxRegistrationOptionJointElectedText}>
              {taxRegistrationOptionJointElectedText}
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.taxRegistration !== currentValues.taxRegistration}
        >
          {({ getFieldValue }) =>
            getFieldValue('taxRegistration') === 'registered' ||
            getFieldValue('taxRegistration') === 'joint_elected' ? (
              <Form.Item name="taxNumber" label="Tax Number" rules={[{ required: true }]}>
                <Input disabled={disabled} />
              </Form.Item>
            ) : null
          }
        </Form.Item>
        <Divider />
        <Form.Item {...tailLayout}>
          <Space>
            <Button htmlType="submit" type="primary" disabled={disabled}>
              {saveText}
            </Button>

            {disabled ? (
              <Button htmlType="button" onClick={() => setDisabled(!disabled)} disabled={!disabled} type="primary">
                {modifyText}
              </Button>
            ) : (
              <Button
                htmlType="button"
                onClick={() => {
                  setDisabled(!disabled)
                  form.resetFields()
                }}
                danger
              >
                {cancelText}
              </Button>
            )}
          </Space>
        </Form.Item>
      </Form>
    </Spin>
  )
}
