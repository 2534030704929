import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'

export const useListingBasicTrans = () => {
  const characterMaxLimitText = useFormatMessage(
    'dashboard.createNewListing.listingStep.detailedDescription.warning',
    '255 characters is the limit',
  )
  const listingAlreadyText = useFormatMessage(
    'dashboard.listing.listingAlreadyExistsText',
    'Listing title already exists',
  )
  const listingDescriptionText = useFormatMessage(
    'dashboard.createNewListing.listingStep.detailedDescription.info',
    'If your description contains YouTube links, the videos will be shown below the description',
  )
  const listingInfoText = useFormatMessage(
    'dashboard.createNewListing.listingStep.detailedDescription.heading',
    'Listing Info',
  )
  const listingTitleRequiredText = useFormatMessage(
    'dashboard.listing.createListingRequiredText',
    'Listing Title is required',
  )
  const listingTitleText = renderFormatMessage('dashboard.createNewListing.listingStep.heading', 'Listing Title')
  const listingCategoryText = useFormatMessage(
    'dashboard.createNewListing.listingStep.listingCategory.Txt',
    'Listing Category',
  )
  const listingNonResText = renderFormatMessage(
    'dashboard.createNewListing.listingStep.listingCategory.nonreservation',
    'Non Reservation',
  )
  const listingResText = renderFormatMessage(
    'dashboard.createNewListing.listingStep.listingCategory.Reservation',
    'Reservation',
  )
  const listingDestText = renderFormatMessage(
    'dashboard.createNewListing.listingStep.listingCategory.Destination',
    'Destination',
  )
  const listingNonNetworkText = renderFormatMessage(
    'dashboard.createNewListing.listingStep.listingCategory.Non Network',
    'Non Network',
  )
  const listingWalletFuncText = renderFormatMessage(
    'dashboard.createNewListing.listingStep.walletCategory.txt',
    'Wallet Functionality',
  )
  const listingWalletText = renderFormatMessage(
    'dashboard.createNewListing.listingStep.walletCategory.walletOption',
    'Wallet',
  )
  const listingNoWalletText = renderFormatMessage(
    'dashboard.createNewListing.listingStep.walletCategory.nowalletOption',
    'No Wallet',
  )
  const listingText = renderFormatMessage('dashboard.text.listing', 'Listing')

  const listingTitlesText = useFormatMessage('dashboard.text.listingTitles', 'Listing Title (s)')
  return {
    listingText,
    characterMaxLimitText,
    listingAlreadyText,
    listingDescriptionText,
    listingInfoText,
    listingTitleRequiredText,
    listingTitleText,
    listingCategoryText,
    listingNonResText,
    listingResText,
    listingDestText,
    listingNonNetworkText,
    listingWalletFuncText,
    listingWalletText,
    listingNoWalletText,
    listingTitlesText
  }
}
