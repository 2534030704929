import { Link } from 'react-router-dom'
import { ChargerConnectorStateBadge } from '../../../components/chargers/charger-connector-state-badge'

import { Links } from '../../../components/routes/paths'
import { renderFormatMessage } from '../../../helpers/intl'
import { Connector, ConnectorV2 } from '../../../models/connector'

export const chargersColumns = [
  {
    title: renderFormatMessage('dashboard.chargersPage.table.heading.listing', 'Listing'),
    key: 'listing',
    render: (conn: Connector) => (
      <Link to={Links.charger({ chargerId: `${conn.charger?.id}`, connectorId: conn.id })}>
        {conn.listing?.title || '-'}
      </Link>
    ),
  },
  {
    title: renderFormatMessage('dashboard.chargersPage.table.heading.connectorId', 'Connector ID'),
    dataIndex: 'connectorId',
  },
  {
    title: renderFormatMessage('dashboard.charger.serialNumber', 'Serial Number'),
    dataIndex: ['charger', 'chargePointSerialNumber'],
  },
  {
    title: renderFormatMessage('dashboard.chargerPage.tab.detail.firmwareVersion', 'Firmware Version'),
    key: 'firmwareVersion',
    dataIndex: ['charger', 'firmwareVersion'],
  },
  {
    title: renderFormatMessage('dashboard.charger.vendor', 'Vendor'),
    dataIndex: ['charger', 'chargePointVendor'],
  },
  {
    title: renderFormatMessage('dashboard.chargersPage.table.heading.tenant', 'Tenant'),
    key: 'tenant',
    render: (conn: Connector) => conn.charger?.tenant?.displayName || conn.charger?.tenant?.name,
  },
  {
    title: renderFormatMessage('dashboard.chargersPage.table.heading.serviceStatus', 'Service Status'),
    key: 'serviceStatus',
    render: (conn: ConnectorV2) => conn.serviceStatus && <ChargerConnectorStateBadge state={conn.serviceStatus} />,
  },
  {
    title: renderFormatMessage('dashboard.chargersPage.table.heading.status', 'Connector Status'),
    key: 'ocppStatus',
    render: (conn: Connector) => <ChargerConnectorStateBadge state={conn.state} />,
  },
]
