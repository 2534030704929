import { timeConvert } from '../../../helpers/duration'
import { formatPrice } from '../../../helpers/number-format'
import { NewListing } from '../../../models/listing'
import { Loitering } from '../../../models/loitering'
import { useAppState } from '../../../state'

interface props {
  listing: NewListing
}

const ListingLoiteringStatus: React.FC<props> = ({ listing }) => {
  const { IsDesktop, IsTablet, IsLaptop, IsMobile } = useAppState()

  const isMobile = IsMobile()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()

  const { loitering } = listing

  const isSmallScreen = isMobile || isTablet ? 'flex-right' : ''
  const isLargeScreen = isLaptop || isDesktop ? 'flex-and-gap-below' : ''

  return (
    <>
      {loitering.loiteringStatus === 'no_penalty' && 'No Penalty'}
      {loitering.loiteringStatus === 'time_based' && renderTimeBased(loitering, isSmallScreen, isLargeScreen)}
      {loitering.loiteringStatus === 'status_based' && renderStatusBased(loitering, isSmallScreen, isLargeScreen)}
    </>
  )
}

const renderTimeBased = (loitering: Loitering, isSmallScreen: string, isLargeScreen: string) => (
  <div className={isLargeScreen}>
    {renderPenalty(isSmallScreen, loitering)}
    {renderTimeLimitIdling(loitering)}
  </div>
)

const renderStatusBased = (loitering: Loitering, isSmallScreen: string, isLargeScreen: string) => (
  <div className={isLargeScreen}>
    {renderPenalty(isSmallScreen, loitering)}
    {renderGracePeriodCharging(loitering)}
  </div>
)

const renderPenalty = (isSmallScreen: string, loitering: Loitering) => (
  <div className={isSmallScreen}>{`${formatPrice(Number(loitering.loiteringPenalty))}/hr`}</div>
)

const renderTimeLimitIdling = (loitering: Loitering) => (
  <div className="opacity-06 paragraph-04-light">{`After ${timeConvert(
    loitering.loiteringTimeLimit,
  )} of charging`}</div>
)

const renderGracePeriodCharging = (loitering: Loitering) => (
  <div className="opacity-06 paragraph-04-light">{`After ${timeConvert(
    loitering.loiteringGracePeriod,
  )} of idling`}</div>
)

export default ListingLoiteringStatus
