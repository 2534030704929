import { Card, Col, Collapse, List, Space } from 'antd'
import { Link } from 'react-router-dom'

import { ChargerConnectorStateBadge } from '../../../components/chargers/charger-connector-state-badge'
import { Links } from '../../../components/routes/paths'
import { renderFormatMessage } from '../../../helpers/intl'
import { Connector } from '../../../models/connector'
import { useAppState } from '../../../state'
import { styled } from '../../../theme'

const { Panel } = Collapse

export interface props {
  connectors: Connector[]
  loading: boolean
}

const CardBody = styled.div`
  .ant-card-body {
    padding: 0;
  }
`

export const ChargersCollapseView: React.FC<props> = ({ connectors, loading }) => {
  const { currentUser } = useAppState()
  return (
    <Col xs={24} xl={24}>
      <CardBody>
        <Card loading={loading} bordered={false}>
          <Collapse accordion>
            {connectors.map((connector) => (
              <Panel
                extra={<Link to={Links.charger({ chargerId: `${connector.charger?.id}` })}>View Charger</Link>}
                key={connector.id}
                header={
                  <Space>
                    {connector.listing?.title || 'No Listing'} -{connector.charger?.chargePointSerialNumber}
                  </Space>
                }
              >
                <List
                  dataSource={[connector]}
                  renderItem={(connector) => (
                    <>
                      <List.Item actions={[connector.connectorId]}>
                        <List.Item.Meta
                          title={renderFormatMessage(
                            'dashboard.chargersPage.table.heading.connectorId',
                            'Connector Id',
                          )}
                        />
                      </List.Item>
                      <List.Item actions={[connector.charger?.chargePointVendor]}>
                        <List.Item.Meta title={renderFormatMessage('dashboard.text.vendor', 'Vendor')} />
                      </List.Item>
                      {currentUser?.role === 'admin' && (
                        <List.Item actions={[connector.charger?.firmwareVersion]}>
                          <List.Item.Meta
                            title={renderFormatMessage(
                              'dashboard.chargerPage.tab.detail.firmwareVersion',
                              'Firmware Version',
                            )}
                          />
                        </List.Item>
                      )}
                      <List.Item actions={[connector.charger?.tenant?.displayName || connector.charger?.tenant?.name]}>
                        <List.Item.Meta title={renderFormatMessage('dashboard.text.tenant', 'Tenant')} />
                      </List.Item>
                      <List.Item
                        actions={[
                          connector.charger && <ChargerConnectorStateBadge state={connector.charger.serviceStatus} />,
                        ]}
                      >
                        <List.Item.Meta
                          title={renderFormatMessage(
                            'dashboard.tenantPage.overviewTab.chargerServiceStatus',
                            'Charger Service Status',
                          )}
                        />
                      </List.Item>
                      <List.Item actions={[<ChargerConnectorStateBadge state={connector.state} />]}>
                        <List.Item.Meta
                          title={renderFormatMessage('dashboard.chargersPage.table.heading.status', 'Status')}
                        />
                      </List.Item>
                    </>
                  )}
                ></List>
              </Panel>
            ))}
          </Collapse>
        </Card>
      </CardBody>
    </Col>
  )
}
