import { Button, Form, Input, Modal } from 'antd'
import { useState } from 'react'

import { AlertErrorCompact } from '../../error-compact'
import { SwtchError } from '../../../models/error'
import { Plan } from '../../../models/plan'
import { layout } from '../../../atom/form/modal-layout'
import { UpdatePlan } from '../../../services/data-provider/plan'
import { FooterLayout } from '../../../atom/user-edit'
import { HeadingWrapper, ModalBodyWrapper, TextBodyWrapper } from '../../../atom/plans'
import { usePlanTranslation } from '../../../hooks/translation/usePlanTranslation'

interface props {
  plan: Plan
  onCancel: () => void
  onUpdatedPlan: (p: Plan) => void
}

interface FormFields {
  name: string
  description: string
}

export const UpdatePlanRedesignForm: React.FC<props> = ({ plan, onCancel, onUpdatedPlan }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const {
    cancelText,
    subscribeText,
    updateText,
    editPlanText,
    nameText,
    emailsText,
    descriptionText,
  } = usePlanTranslation()

  const afterClose = () => form.resetFields()

  const onOk = () => {
    setError(undefined)
    setLoading(true)
    form.validateFields().then((values: FormFields) => {
      const { name, description } = values
      UpdatePlan(plan, name, description)
        .then((p) => onUpdatedPlan(p))
        .catch((error) => setError(error))
        .finally(() => setLoading(false))
    })
  }

  return (
    <Modal
      title={<HeadingWrapper>{editPlanText}</HeadingWrapper>}
      closable={true}
      okText={updateText}
      cancelText={cancelText}
      visible={true}
      onCancel={onCancel}
      onOk={onOk}
      confirmLoading={loading}
      afterClose={afterClose}
      footer={
        <FooterLayout>
          <Button key="back" loading={loading} onClick={onCancel}>
            {cancelText}
          </Button>
          <Button key="submit" type="primary" loading={loading} onClick={onOk}>
            {subscribeText}
          </Button>
        </FooterLayout>
      }
    >
      <AlertErrorCompact error={error} />
      <Form
        {...layout}
        requiredMark={false}
        form={form}
        colon={false}
        labelAlign="left"
        initialValues={{
          name: plan.name,
          interval: plan.interval,
          description: plan.description,
          priceCents: plan.priceCents / 100 || 0,
          currency: plan.currency,
        }}
      >
        <ModalBodyWrapper>
          <Form.Item
            className="first-row"
            key="name"
            label={<TextBodyWrapper>{nameText}</TextBodyWrapper>}
            name="name"
            rules={[{ required: true, type: 'string', message: 'A name is required!' }]}
          >
            <Input disabled={loading} type="text" placeholder={emailsText} />
          </Form.Item>
          <Form.Item
            className="last-row"
            key="description"
            label={<TextBodyWrapper>{descriptionText}</TextBodyWrapper>}
            name="description"
            rules={[{ required: true, type: 'string', message: 'A description is required!' }]}
          >
            <Input placeholder="Description" type="text" disabled={loading} />
          </Form.Item>
        </ModalBodyWrapper>
      </Form>
    </Modal>
  )
}
