import { Checkbox, Select, Spin } from 'antd'
import { ReactElement, useMemo, useRef, useState, MouseEvent } from 'react'
import { debounce } from 'lodash'

import { TenantRef } from '../../models/tenant'
import { useAppState } from '../../state'

import { MultiOptionSelectorContainer } from '../../atom/select'
import { SearchListingIds } from '../../services/data-provider/listing'

import { magnifySVG } from '../../assets/svg/magnify'
interface props {
  onOptionChange: (listingIds: number[]) => void
  defaultTenant?: TenantRef
  tenants?: TenantRef[]
  loading?: boolean
  isCombineSelector?: boolean
}

export const MultiListingIdSelector: React.FC<props> = ({
  onOptionChange,
  defaultTenant,
  tenants,
  loading,
  isCombineSelector,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<number[]>([])
  const [listings, setListings] = useState<number[]>([])
  const fetchRef = useRef(0)
  const [fetching, setFetching] = useState(false)
  const [dirty, setDirty] = useState(false)

  const { IsDesktop, IsLaptop } = useAppState()
  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      if (value.length >= 1) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setListings([])
        setFetching(true)

        SearchListingIds(value, defaultTenant, tenants).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          setListings(newOptions)
          setFetching(false)
        })
      }
    }

    return debounce(loadOptions, 800)
  }, [SearchListingIds, 800])

  const renderOption = (id: number): ReactElement => (
    <Select.Option key={id} value={id} label={id}>
      <Checkbox onClick={handleDirty} checked={selectedOptions.includes(id)}>
        {id}
      </Checkbox>
    </Select.Option>
  )

  const handleDirty = (e: MouseEvent) => (dirty ? e.stopPropagation() : setDirty(false))

  const handleChange = (value: number[]) => {
    setDirty(true)
    setSelectedOptions(value)
    onOptionChange(value)
  }

  const renderTenantLists = (listings: number[]) => {
    return listings.map((v) => renderOption(v)).sort((a, b) => sortListingTitles(a, b))
  }

  const handlePlaceholder = () => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0] : selectedPlaceholder(selected)
  }

  const handleMouseDown = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    setDirty(false)
    e.stopPropagation()
  }

  const canCombineBorder = (isDesktop || isLaptop) && isCombineSelector

  return (
    <MultiOptionSelectorContainer isCombine={canCombineBorder}>
      {magnifySVG}
      <Select
        disabled={loading}
        showSearch
        mode="multiple"
        allowClear
        loading={fetching}
        tokenSeparators={[',']}
        style={{ minWidth: 156, width: '100%', lineHeight: '34px' }}
        placeholder="Listing Id (s)"
        onChange={handleChange}
        onSearch={debounceFetcher}
        onMouseDown={handleMouseDown}
        dropdownMatchSelectWidth={false}
        maxTagCount={0}
        maxTagPlaceholder={handlePlaceholder}
        notFoundContent={fetching ? <Spin size="small" /> : null}
      >
        {renderTenantLists(listings)}
      </Select>
    </MultiOptionSelectorContainer>
  )
}

export default MultiListingIdSelector

const sortListingTitles = (a: ReactElement, b: ReactElement) => {
  return b.props.children.props.checked - a.props.children.props.checked
}

const selectedPlaceholder = (selected: number) => `${selected} Listing Ids Selected`
