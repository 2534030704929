import { ChargerBase } from './charger'
import { ConnectorRef, ConnectorV2 } from './connector'
import { Loitering } from './loitering'
import { Price } from './price'
import { StripeAccount } from './stripe-account'
import { TenantRef } from './tenant'
import { UserRef } from './user'

export const accessType = ['public', 'private'] as const
export const paymentType = ['wallet', 'no_wallet'] as const
export const listingKind = ['no_reservation', 'reservation', 'destination', 'non_network'] as const
export const smartCharging = ['smart_control', 'monitoring_only', 'local', 'unknown']
export const connectivityState = [false, true, 'connecting', 'no_connection']

export type ListingKind = typeof listingKind[number]
export type PaymentType = typeof paymentType[number]
export type AccessType = typeof accessType[number]
export type SmartCharging = typeof smartCharging[number]
export type ConnectivityState = typeof connectivityState[number]

export interface ListingRef {
  id: number
  title: string
}

// Usage of DebounceSelect
export interface ListingOption {
  label: string | number
  value: string | number
  listing: ListingRef
}

export interface Listing extends ListingRef {
  pricePerUnitCents: number
  priceType: string
  connector: ConnectorRef
  price: Price
}

export interface ListingLoadMgmt extends ListingRef {
  addCloudLoadManagement: boolean
}

export interface ListingBase extends Listing {
  kind: ListingKind
  author: string
  open: boolean
  charger?: ChargerBase
  tenant?: string
}

export interface ListingType {
  kind: ListingKind
}

export interface ListingBasic extends ListingRef, ListingPaymentType {
  description: string
}

export interface ListingPaymentType {
  paymentType: PaymentType
}

export interface ListingTimeOfDay {
  enableTimeOfDayPricing: boolean
  timeOfDayPriceWeekday: number[]
  timeOfDayPriceWeekend: number[]
}

export type CreateNewListingType = Omit<ListingFull, 'id' | 'person'>

export interface ListingFull extends Loitering, ListingType, ListingTimeOfDay {
  id?: number
  authorId: string
  person?: UserRef
  title: string
  currency: string
  priceType: string
  additionalServiceFee: string
  timeOfDayEnabled: boolean
  pricePerHour: number
  commisionPercent: number
  accessType: string
  carDetailRequired: boolean
  customNotification: string
  output: number
  flatFee: number | string
  tenantId: string | number
  paymentType: string
  hidden: boolean
  stripeAccountId: number
  description: string
  preferedPricePerUnitCents: number
  enableTieredPricing: boolean
  pricePerUnitCents: number
  guestFeeCents: number
  paymentLimitCents: number
  enableLoadManagementPricing: boolean
  addCloudLoadManagement: boolean
  enableLoadManagementAutoStop: boolean
  tenant?: TenantRef
  stripeAccount?: StripeAccount
  shavingEnabled?: boolean
  shavingPrimaryZoneStrategy?: number
  shavingSecondaryZoneStrategy?: number
  shavingEventSource?: string
}

export interface ListingAccess {
  accessType: AccessType
  hidden: boolean
  accessCode?: number
}

export interface NewListing {
  id: number
  title: string
  connector?: ConnectorV2
  connectivity: ConnectivityState
  permission: ListingAccess
  kind: ListingKind
  charger?: ChargerBase
  tenant?: TenantRef
  loitering: Loitering
  smartCharging?: SmartCharging
  price: Price
}

export interface ListingAccess {
  accessType: AccessType
  hidden: boolean
  accessCode?: number
}
