import { Card, Col, Row } from 'antd'
import { useHomeTranslation } from '../../../../hooks/translation/useHomeTranslation'
import { TrxSummary } from '../../../../models/transaction'
import { CardWrapper } from '../../../../atom/card'

interface props {
  loading: boolean
  trxProfileSummary?: TrxSummary
}

const RevenueMetrics: React.FC<props> = ({ loading, trxProfileSummary }) => {
  const { fromDemandResponse, fromCharging, fromLoitering } = useHomeTranslation()
  return (
    <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
      <CardWrapper>
        <Card title="Revenue" loading={loading} className="revenue">
          <Row align="middle" justify="space-between">
            <Col span={24}>
              <h2 className="heading-02-black">
                {
                  <b>
                    {trxProfileSummary &&
                      (trxProfileSummary.revenueTotal ? renderRevenueData(trxProfileSummary.revenueTotal) : '$0')}
                  </b>
                }
              </h2>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={13} xl={13} xxl={12}>
              <p className="paragraph-04-regular">
                <span>
                  {trxProfileSummary && (
                    <b>
                      {trxProfileSummary.revenueDemandResponse
                        ? renderRevenueData(trxProfileSummary.revenueDemandResponse)
                        : '$0'}
                    </b>
                  )}
                </span>
                <span>{fromDemandResponse}</span>
              </p>
            </Col>
            <Col xs={24} sm={24} md={11} xl={11} xxl={12}>
              <p className="paragraph-04-regular">
                <span>
                  {trxProfileSummary && (
                    <b>
                      {trxProfileSummary.revenueCharging ? renderRevenueData(trxProfileSummary.revenueCharging) : '$0'}
                    </b>
                  )}
                </span>
                <span>{fromCharging}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <p className="paragraph-04-regular">
                <span>
                  {trxProfileSummary && (
                    <b>
                      {trxProfileSummary.revenueLoitering
                        ? renderRevenueData(trxProfileSummary.revenueLoitering)
                        : '$0'}
                    </b>
                  )}
                </span>
                <span>{fromLoitering}</span>
              </p>
            </Col>
          </Row>
        </Card>
      </CardWrapper>
    </Col>
  )
}

export default RevenueMetrics

const renderRevenueData = (revenue: number) => {
  const inDollars = revenue / 100
  const revenueStr =
    inDollars < 1000 ? `${inDollars.toFixed(2)}` : `${Number.parseFloat(`${inDollars / 1000}`).toFixed(2)}K`
  return `$${revenueStr}`
}
