import {
  AutoComplete,
  Button,
  Cascader,
  Divider,
  Form,
  Input,
  message,
  PageHeader,
  SelectProps,
  Space,
  Spin,
  Switch,
  TimePicker,
} from 'antd'
import { LatLng } from 'leaflet'
import { debounce } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { DebounceInput } from 'react-debounce-input'
import { useHistory } from 'react-router'
import { Box } from '../../atom/box'
import { layout, tailLayout } from '../../atom/form/page-layout'
import { AlertError } from '../../components/error'
import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { LocationView } from '../../components/map/location-view'
import { Links } from '../../components/routes/paths'
import { MustBeRequired } from '../../components/rules/rules'
import { useFormatMessage } from '../../helpers/intl'
import { locations } from '../../helpers/location'
import { twentyFourSevenWeeklyScheduleValues, weekdays, weeklySchedulesFromFormValues } from '../../helpers/weekdays'
import { SwtchError } from '../../models/error'
import { Tenant, TenantRef } from '../../models/tenant'
import { geocode, GeocodingSuccess, SwtchAddress } from '../../services/data-provider/geocoding'
import { CreateTenant, FindTenants } from '../../services/data-provider/tenants'
import TimeZone from '../../services/data-provider/timezone'

const GEOCODE_DEBOUNCE_TIMEOUT = 300

const renderTenantName = (tenant: TenantRef): any => {
  return {
    value: tenant.name,
  }
}

const TenantCreateBasePage: React.FC = () => {
  // @ts-ignore
  const [tenant, setTenant] = useState<Tenant>(undefined)
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<SwtchError>()
  const [twentyFourSeven, setTwentyFourSeven] = useState<boolean>()
  const [addressForGeocoding, setAddressForGeocoding] = useState<SwtchAddress>()
  const [geocodedLocation, setGeocodedLocation] = useState<LatLng | undefined>(undefined)
  const [geocodingError, setGeocodingError] = useState<SwtchError>()
  const [timeZone, setTimeZone] = useState<string>()

  const history = useHistory()
  const [options, setOptions] = useState<SelectProps<object>['options']>([])

  const [form] = Form.useForm()
  const defaultMapCenter: LatLng = new LatLng(43.75, -79.4)

  useEffect(() => {
    if (addressForGeocoding) geocodeAddress(addressForGeocoding)
  }, [addressForGeocoding])

  useEffect(() => {
    tenant &&
      CreateTenant(tenant)
        .then((tenant) => {
          message.success(`${tenant.displayName || tenant.name} ${tenantCreated}`)
          history.push(Links.tenant({ tenantId: tenant.id }))
        })
        .catch((err: SwtchError) => setError(err))
        .finally(() => setLoading(false))
  }, [tenant])

  useEffect(() => {
    setTwentyFourSeven(true)
    form.setFieldsValue({
      sunday: [moment().startOf('day'), moment().endOf('day')],
      monday: [moment().startOf('day'), moment().endOf('day')],
      tuesday: [moment().startOf('day'), moment().endOf('day')],
      wednesday: [moment().startOf('day'), moment().endOf('day')],
      thursday: [moment().startOf('day'), moment().endOf('day')],
      friday: [moment().startOf('day'), moment().endOf('day')],
      saturday: [moment().startOf('day'), moment().endOf('day')],
    })
    initializeTimeZone(defaultMapCenter.lat, defaultMapCenter.lng)
  }, [])

  useEffect(() => {
    FindTenants()
      .then((tenants) => {
        setTenants(tenants)
      })
      .catch((err: SwtchError) => {
        setTenants([])
        message.error(err.description)
        setError(err)
      })
  }, [])

  const tenantsNames = new Set(tenants.map((t) => t.name))

  const tenantsCreatePageHeading = useFormatMessage('dashboard.createnewtenantspage.heading', 'Create New Tenant')
  const accessCodeText = useFormatMessage('dashboard.tenantPage.detailTab.accessCode.heading', 'Access Code')
  const ocppText = useFormatMessage('dashboard.tenantPage.detailTab.ocppName.heading', 'OCPP Name')
  const displayNameText = useFormatMessage('dashboard.tenantPage.detailTab.displayName.heading', 'Display Name')
  const addressText = useFormatMessage('dashboard.tenantPage.detailTab.address.heading', 'Address')
  const cityText = useFormatMessage('dashboard.tenantPage.detailTab.city.heading', 'City')
  const postalCodeText = useFormatMessage('dashboard.tenantPage.detailTab.postalCode.heading', 'Postal Code')
  const countryAndProvinceText = useFormatMessage(
    'dashboard.tenantPage.detailTab.countryAndProvince.heading',
    'Country and Province',
  )
  const timeZoneText = useFormatMessage('dashboard.tenantPage.detailTab.timezone', 'Time Zone')
  const locationText = useFormatMessage('dashboard.tenantPage.detailTab.location', 'Location')
  const open247Text = useFormatMessage('dashboard.tenantPage.detailTab.open247', 'Open 24/7')
  const ocpiSharingText = useFormatMessage('dashboard.tenantPage.detailTab.ocpiSharing', 'OCPI Sharing')
  const saveText = useFormatMessage('dashboard.tenantPage.detailTab.saveBtn', 'Save')
  const resetText = useFormatMessage('dashboard.tenantPage.detailTab.resetbtn', 'Reset')
  const geoCodingError = useFormatMessage(
    'dashboard.tenantPage.error.geocodingError',
    'Geocoding result is outside province',
  )
  const geoCodingCoordinateError = useFormatMessage(
    'dashboard.tenantPage.error.geocodingError',
    'Geocoding result is outside province',
  )
  const countryandProvinceError = useFormatMessage(
    'dashboard.tenantPage.error.countryAndProvinceError',
    'Please pick a country and province for this tenant',
  )
  const googlelatlongError = useFormatMessage(
    'dashboard.tenantPage.error.googleLatLongError',
    'Google did not give us a latitude and longitude for that address',
  )
  const tenantCreated = useFormatMessage(
    'dashboard.tenantPage.chargersTab.tenantcreated',
    'has been successfully created!',
  )
  const accessCodeWarning = useFormatMessage(
    'dashboard.tenantPage.detailTab.accessCode.warning',
    'Please enter a 6-digit access code',
  )

  const postalCodeWarning = useFormatMessage(
    'dashboard.tenantPage.detailTab.postalCode.warning',
    "That's a bit long isn't it? Please limit yourself to 10 characters here.",
  )

  const ocppNameWarning = useFormatMessage(
    'dashboard.tenantPage.detailTab.ocppName.warning',
    'Please enter a OCPP Name',
  )

  const addressWarning = useFormatMessage('dashboard.tenantPage.detailTab.address.warning', 'Please enter an Address')
  const cityWarning = useFormatMessage('dashboard.tenantPage.detailTab.city.warning', 'Please enter City')
  const ocppWarning = useFormatMessage(
    'dashboard.tenantPage.detailTab.ocppNameAlreadyExists.warning',
    'OCPP Name already exists',
  )

  const onSearch = (searchText: string) => {
    const opts = tenants.map((tenant: TenantRef) => {
      return renderTenantName(tenant)
    })
    setOptions(opts?.filter((o: any) => o.value?.toString().toLowerCase().match(`${searchText.toLowerCase()}`)))
  }

  const geocodeAddress = (address: SwtchAddress) => {
    setGeocodingError(undefined)

    const geocodingResultAdmissible = (result: GeocodingSuccess): boolean => {
      if (address.province && address.country) {
        return (
          !!result.province &&
          !!result.country &&
          result.province === address.province &&
          result.country === address.country
        )
      } else return true
    }

    geocode(address).then((result) => {
      if (result.found) {
        if (!geocodingResultAdmissible(result)) {
          setGeocodedLocation(undefined)
          setGeocodingError(new SwtchError(`${geoCodingError} ${address.province}`))
        } else {
          setGeocodedLocation(new LatLng(result.lat, result.lng))
          initializeTimeZone(result.lat, result.lng)
        }
      } else {
        setGeocodedLocation(undefined)
        setGeocodingError(new SwtchError(geoCodingCoordinateError, [`${result.code}: ${result.message}`]))
      }
    })
  }

  const initializeTimeZone = async (lat: number, long: number) => {
    const result = await TimeZone(lat, long)
    result.found && setTimeZone(result.timeZoneId)
  }

  const onReset = () => form.resetFields()

  const isEmpty = (value: string | undefined): boolean => {
    return value == null || value.trim().length === 0
  }

  const onFinish = async (values: any) => {
    const weeklySchedules = twentyFourSeven
      ? twentyFourSevenWeeklyScheduleValues
      : weeklySchedulesFromFormValues(values)

    if (!values['province'] || !values['province'][0] || !values['province'][1]) {
      message.error(countryandProvinceError)
    } else if (geocodedLocation === undefined) {
      message.error(googlelatlongError)
    } else {
      setTenant({
        ...tenant,
        id: '',
        accessCode: values['accessCode'],
        name: values['name'].trim(),
        displayName: isEmpty(values['displayName']) ? undefined : values['displayName'],
        location: {
          address: values['address'],
          postalCode: values['postalCode'],
          city: values['city'],
          province: values['province'][1],
          country: values['province'][0],
          latitude: geocodedLocation.lat,
          longitude: geocodedLocation.lng,
          listingTimezone: timeZone,
        },
        weeklySchedules,
        publishToOcpi: values['publishToOcpi'],
        eyedroIpAddress: values['eyedroIpAddress'],
      })
      setLoading(true)
    }
  }

  const initialValues: any = {
    province: ['CA', 'ON'],
    twentyFourSeven: true,
    publishToOcpi: false,
    accessCode: 'xxxxxx',
    ...twentyFourSevenWeeklyScheduleValues,
  }

  return (
    <Spin spinning={loading}>
      <PageHeader title={tenantsCreatePageHeading} />
      <AlertError error={error} />
      <Box>
        <Form {...layout} form={form} name="create_new_tenant" onFinish={onFinish} initialValues={initialValues}>
          <Form.Item
            name="accessCode"
            label={accessCodeText}
            rules={[{ len: 6, message: accessCodeWarning, required: true }]}
          >
            <Input defaultValue="xxxxxx" />
          </Form.Item>

          <Divider />
          <Form.Item
            name="name"
            label={ocppText}
            rules={[
              MustBeRequired(ocppNameWarning),
              { max: 255 },
              () => ({
                validator(_, value) {
                  if (tenantsNames.has(value)) {
                    return Promise.reject(new Error(ocppWarning))
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <AutoComplete options={options} onSearch={debounce(onSearch, 1000)} />
          </Form.Item>
          <Form.Item name="displayName" label={displayNameText} rules={[{ max: 255 }, { required: false }]}>
            <Input />
          </Form.Item>
          <Form.Item name="address" label={addressText} rules={[{ message: addressWarning, required: true }]}>
            <DebounceInput
              debounceTimeout={GEOCODE_DEBOUNCE_TIMEOUT}
              onChange={(evt) => setAddressForGeocoding({ ...addressForGeocoding, numberAndStreet: evt.target.value })}
            />
          </Form.Item>
          <Form.Item name="city" label={cityText} rules={[{ message: cityWarning, required: true }]}>
            <DebounceInput
              debounceTimeout={GEOCODE_DEBOUNCE_TIMEOUT}
              onChange={(evt) => setAddressForGeocoding({ ...addressForGeocoding, city: evt.target.value })}
            />
          </Form.Item>
          <Form.Item
            name="postalCode"
            label={postalCodeText}
            rules={[{ max: 10, message: postalCodeWarning, required: true }]}
          >
            <DebounceInput
              debounceTimeout={GEOCODE_DEBOUNCE_TIMEOUT}
              onChange={(evt) => setAddressForGeocoding({ ...addressForGeocoding, postalCode: evt.target.value })}
            />
          </Form.Item>
          <Form.Item name="province" label={countryAndProvinceText} rules={[{ required: true }]}>
            <Cascader options={locations} allowClear={false} />
          </Form.Item>

          <Form.Item label={timeZoneText}>
            <span className="ant-form-text">{timeZone}</span>
          </Form.Item>

          <Divider />

          <Form.Item label={locationText}>
            {geocodingError ? (
              <AlertError error={geocodingError} />
            ) : (
              <LocationView
                value={geocodedLocation}
                defaultCenter={defaultMapCenter}
                defaultZoom={11}
                style={{ height: '50vh' }}
              />
            )}
          </Form.Item>

          <Divider />

          <Form.Item label={open247Text} name="twentyFourSeven">
            <Switch checked={twentyFourSeven} onChange={setTwentyFourSeven}></Switch>
          </Form.Item>

          {twentyFourSeven ||
            weekdays.map(([_, dayName]) => (
              <Form.Item key={dayName} label={dayName} name={dayName.toLowerCase()}>
                <TimePicker.RangePicker
                  format="HH:mm"
                  defaultValue={[moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')]}
                />
              </Form.Item>
            ))}

          <Divider />

          <Form.Item label={ocpiSharingText} name="publishToOcpi" valuePropName="checked">
            <Switch />
          </Form.Item>

          <Divider />

          <Form.Item {...tailLayout}>
            <Space>
              <Button htmlType="submit" type="primary">
                {saveText}
              </Button>

              <Button htmlType="button" onClick={onReset}>
                {resetText}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Box>
    </Spin>
  )
}

export const TenantCreatePage = withAuthenticatedLayout(TenantCreateBasePage)
