export const downloadFileSVG = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.69995 11.4152V14.1946C2.69995 14.6158 2.86589 15.0198 3.16126 15.3176C3.45663 15.6155 3.85724 15.7828 4.27495 15.7828H13.725C14.1427 15.7828 14.5433 15.6155 14.8386 15.3176C15.134 15.0198 15.3 14.6158 15.3 14.1946V11.4152M9.03247 11.2178L9.03247 2.21777M9.03247 2.21777L5.43247 5.65664M9.03247 2.21777L12.6324 5.65664"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
