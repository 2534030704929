import { useState, useRef, useEffect } from 'react'
import { Divider, Input, Select, Space, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import type { InputRef } from 'antd'

import { renderFormatMessage } from '../../helpers/intl'
import { MasterAccountRef } from '../../models/master-account'
import { CreateMasterAccount, GetMasterAccounts } from '../../services/data-provider/master-account'

interface props {
  masterAccount?: MasterAccountRef
  onOptionChange: (selectedMasterAccount?: MasterAccountRef) => void
  disabled: boolean
}

export const MasterAccountSelector: React.FC<props> = ({ masterAccount, onOptionChange, disabled }) => {
  const [masterAccounts, setMasterAccounts] = useState<MasterAccountRef[]>([])
  const [name, setName] = useState('')
  const inputRef = useRef<InputRef>(null)

  useEffect(() => {
    GetMasterAccounts().then((resp) => setMasterAccounts(resp))
  }, [])

  const handleSelectChange = (selectPartner: any) => {
    const findMasterAccount = masterAccounts.find((p) => p.name === selectPartner)
    onOptionChange(findMasterAccount)
  }

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    CreateMasterAccount(name).then((newMasterAccount) => {
      setMasterAccounts([...masterAccounts, { id: newMasterAccount.id, name: newMasterAccount.name }])
    })
    setName('')
    setTimeout(() => {
      inputRef.current?.focus()
    }, 500)
  }

  const checkDecuplicate = () => {
    const filterName = masterAccounts.filter((m) => m.name.toLowerCase() === name.toLowerCase())

    if (name.length === 0) {
      return true
    } else if (filterName.length !== 0) {
      return true
    } else {
      return false
    }
  }

  const defaultValue = masterAccount
    ? { label: masterAccount?.name, value: masterAccount?.name, id: masterAccount?.id }
    : null

  const options = masterAccounts.map((masterAccount) => ({
    label: masterAccount.name,
    value: masterAccount.name,
    id: masterAccount.id,
  }))

  return (
    <Select
      showSearch
      disabled={disabled}
      defaultValue={defaultValue}
      placeholder="Add new master account"
      onChange={handleSelectChange}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space wrap style={{ padding: '0 8px 4px' }}>
            <Input
              placeholder="Enter new account"
              ref={inputRef}
              value={name}
              onChange={onNameChange}
            />
            <Button type="primary" disabled={checkDecuplicate()} icon={<PlusOutlined />} onClick={addItem}>
              {renderFormatMessage('dashboard.text.add', 'Add')}
            </Button>
          </Space>
        </>
      )}
      options={options}
    />
  )
}
