import { useState } from 'react'
import { Button, DatePicker, DatePickerProps, Form, Input, Modal, Row, Select } from 'antd'
import en_US from 'antd/es/date-picker/locale/en_US'
import fr_CA from 'antd/es/date-picker/locale/fr_CA'
import moment, { Moment } from 'moment'

import { SwtchError } from '../../../models/error'
import { UserWallet } from '../../../models/user'
import { Plan } from '../../../models/plan'
import { Subscription } from '../../../models/subscription'
import { CreateSubscription } from '../../../services/data-provider/subscriptions'
import { renderFormatMessage, useFormatMessage } from '../../../helpers/intl'
import { labelAboveField } from '../../../atom/form/modal-layout'
import { Address, locations, provinces, states } from '../../../helpers/location'
import { MustBePostalCode } from '../../rules/rules'
import { disabledDate } from '../../../helpers/date/calendar'
import { HeadingWrapper, ModalBodyWrapper, TextBodyWrapper, TitleBodyWrapper } from '../../../atom/plans'
import { FooterLayout } from '../../../atom/user-edit'
import { RowInputWrapper } from '../../../atom/plans'
import { PlanSelectorRedesign } from '../../selector/plan-selector-redesign'
import { UserSelectorRedesign } from '../../selector/user-selector-redesign'
import { useAppState } from '../../../state'
import { useNotifications } from '../../../hooks/useNotification'
import { AlertErrorCompactRedesign } from '../../error-compact-redesign'

const { Option, OptGroup } = Select
interface props {
  defaultPlan?: Plan
  onCancel: () => void
  onSubscribe: (subscription: Subscription) => void
}

interface FormFields {
  zip: string
  state: string
  country: string
  user: UserWallet
}

const CreateSubscriptionRedesignForm: React.FC<props> = ({ onCancel, onSubscribe, defaultPlan }) => {
  const [form] = Form.useForm()
  const { language } = useAppState()
  const [error, setError] = useState<SwtchError>()
  const [user, setUser] = useState<UserWallet | undefined>()
  const [plan, setPlan] = useState<Plan | undefined>(defaultPlan)
  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState<moment.Moment | undefined | null>(undefined)
  const [time, setTime] = useState<moment.Moment | undefined | null>(undefined)
  const { openSuccessNotification } = useNotifications()

  const chooseDatePickerLocale = (lang: string) => {
    let locale = undefined
    switch (lang) {
      case 'fr':
        locale = fr_CA
        break
      case 'en':
        locale = en_US
        break
      default:
        locale = en_US
    }
    return locale
  }

  const afterClose = () => form.resetFields()

  const onOk = () => {
    // combining date and time for start date
    let fullDate: Moment | undefined = undefined
    if (date || (date && time)) {
      fullDate = moment(date?.format('LL') + ' ' + time?.format('HH:mm:ss z'), 'LL HH:mm:ss z')
    }

    setError(undefined)
    form.validateFields().then((values: FormFields) => {
      let address: Address = {}
      if (user?.hasBillingAddr) {
        const { zip, country, state } = user.walletStatus.billingAddr
        address = { zip, country, state }
      } else {
        const { zip, state, country } = values
        address = { zip, state: state.split(' -')[0], country: country.slice(-2) }
      }

      if (plan && user) {
        setLoading(true)
        CreateSubscription(plan, user, fullDate, address)
          .then((newSubscription) => {
            openSuccessNotification('Your subscription was created.')
            onSubscribe(newSubscription)
          })
          .catch((error) => setError(error))
          .finally(() => setLoading(false))
      }
    })
  }

  const onChangeStartDate: DatePickerProps['onChange'] = (date) => {
    setDate(date)
  }

  const onChangeTimeDate: DatePickerProps['onChange'] = (date) => {
    setTime(date)
  }

  const hasPlanAndUser = () => {
    return plan && user ? false : true
  }

  return (
    <>
      <Modal
        title={<HeadingWrapper>Create Subscription</HeadingWrapper>}
        visible={true}
        onCancel={onCancel}
        onOk={onOk}
        width={569}
        confirmLoading={loading}
        afterClose={afterClose}
        footer={
          <FooterLayout>
            <Button key="back" loading={loading} onClick={onCancel}>
              {useFormatMessage('dashboard.button.cancel', 'Cancel')}
            </Button>
            <Button key="submit" type="primary" loading={loading} disabled={hasPlanAndUser()} onClick={onOk}>
              {useFormatMessage('dashboard.button.subscribe', 'Subscribe')}
            </Button>
          </FooterLayout>
        }
      >
        <ModalBodyWrapper>
          <Form form={form} layout="horizontal" requiredMark={false}>
            <Form.Item
              {...labelAboveField}
              className="display-row plan"
              label={<TextBodyWrapper>Plan</TextBodyWrapper>}
              key="plan"
              name="plan"
            >
              <PlanSelectorRedesign
                onPlanSelected={(p) => setPlan(p)}
                onSearch={(p) => setPlan(p)}
                defaultPlan={plan}
              />
            </Form.Item>

            {plan && (
              <>
                <hr
                  style={{
                    border: 0,
                    borderTop: '1px solid #f0f0f0',
                    margin: '20px -24px 17px',
                  }}
                />
                <Form.Item
                  {...labelAboveField}
                  className="display-row email"
                  label={<TextBodyWrapper>Email</TextBodyWrapper>}
                  key="email"
                  name="email"
                >
                  <UserSelectorRedesign
                    onUserSelected={(user) => setUser(user)}
                    onClear={() => setUser(undefined)}
                    country={plan?.country}
                  />
                </Form.Item>
              </>
            )}
            <AlertErrorCompactRedesign error={error} />
            {plan && user && (
              <Row justify="space-between">
                <Form.Item
                  key="start_date"
                  name="startDate"
                  className="date-picker"
                  help="Leave blank to start subscription immediately or choose a date to schedule a subscription"
                >
                  <DatePicker
                    format="MMM DD, YYYY"
                    disabled={loading}
                    disabledDate={disabledDate}
                    placement="bottomLeft"
                    style={{ width: '338px' }}
                    locale={chooseDatePickerLocale(language)}
                    onChange={onChangeStartDate}
                  />
                  <DatePicker
                    popupStyle={{ width: 'fit-content' }}
                    className="time-date-picker"
                    placement="bottomRight"
                    picker="time"
                    format="hh:mm A"
                    onChange={onChangeTimeDate}
                  />
                </Form.Item>
              </Row>
            )}
          </Form>
          <Form form={form} layout="vertical" requiredMark={false}>
            {user && !user.hasBillingAddr ? (
              <>
                <hr
                  style={{
                    border: 0,
                    borderTop: '1px solid #f0f0f0',
                    margin: '0px -24px 0px',
                  }}
                />
                <TitleBodyWrapper>Billing Address</TitleBodyWrapper>
                <RowInputWrapper>
                  <Form.Item
                    className="row-input last-row"
                    key="zip"
                    name="zip"
                    label={<TextBodyWrapper>Postal Code</TextBodyWrapper>}
                    rules={[MustBePostalCode, { required: true }]}
                  >
                    <Input placeholder="Postal Code" disabled={loading} />
                  </Form.Item>
                  <Form.Item
                    {...labelAboveField}
                    className="row-input last-row"
                    label={<TextBodyWrapper>Province/State</TextBodyWrapper>}
                    key="state"
                    name="state"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder={renderFormatMessage('dashboard.text.provinceState', 'Province/State')}
                      showSearch
                      disabled={loading}
                    >
                      <OptGroup label="Province">
                        {provinces.map((province, index) => (
                          <Option key={`${province.value}-${index}`} value={`${province.label} - ${province.value}`}>
                            {province.label} - {province.value}
                          </Option>
                        ))}
                      </OptGroup>
                      <OptGroup label={renderFormatMessage('dashboard.text.state', 'State')}>
                        {states.map((state, index) => (
                          <Option key={`${state.value}-${index}`} value={`${state.label} - ${state.value}`}>
                            {state.label} - {state.value}
                          </Option>
                        ))}
                      </OptGroup>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...labelAboveField}
                    className="row-input last-row"
                    key="country"
                    label={<TextBodyWrapper>Country</TextBodyWrapper>}
                    name="country"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder={renderFormatMessage('dashboard.button.country', 'Country')}
                      showSearch
                      disabled={loading}
                    >
                      {locations.map((location, index) => (
                        <Option key={`${location}-${index}`} value={`${location.label} - ${location.value}`}>
                          {location.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </RowInputWrapper>
              </>
            ) : null}
          </Form>
        </ModalBodyWrapper>
      </Modal>
    </>
  )
}

export default CreateSubscriptionRedesignForm
