import { PageHeader, Skeleton, Tabs } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Box } from '../../atom/box'
import { AlertError } from '../../components/error'
import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { TenantTabAccessCode } from '../../components/tenants/tenant-tab-access-code'
import { TenantTabBilling } from '../../components/tenants/tenant-tab-billing'
import { TenantTabChargers } from '../../components/tenants/tenant-tab-chargers'
import { TenantTabContact } from '../../components/tenants/tenant-tab-contact'
import { TenantDeploymentTab } from '../../components/tenants/tenant-tab-deployment'
import { TenantTabLocation } from '../../components/tenants/tenant-tab-location'
import { TenantTabOverview } from '../../components/tenants/tenant-tab-overview'
import { TenantTabPricingV2 } from '../../components/tenants/tenant-tab-pricing-v2'
import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'
import { SwtchError } from '../../models/error'
import { Tenant } from '../../models/tenant'
import { GetTenant, UpdateTenant } from '../../services/data-provider/tenants'

const TenantBasePage: React.FC = () => {
  document.querySelector('body')?.classList.remove('redesignActive')
  // @ts-ignore
  const { tenantId } = useParams()
  const [tenant, setTenant] = useState<Tenant>()
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<SwtchError>()

  const tenantTabChargerText = useFormatMessage('dashboard.tenantPage.chargersTab.heading', 'Chargers')

  useEffect(() => {
    setLoading(true)
    GetTenant(tenantId)
      .then((tenant) => setTenant(tenant))
      .catch((err: SwtchError) => setError(err))
      .finally(() => setLoading(false))
  }, [tenantId])

  useEffect(() => {
    if (tenant && !loading) UpdateTenant(tenant)
  }, [tenant])

  if (!tenant) {
    return (
      <Box>
        <Skeleton active />
      </Box>
    )
  }
  return (
    <>
      <PageHeader title={tenant?.displayName || tenant?.name} />
      <Box padding>
        <AlertError error={error} />
        <Tabs defaultActiveKey="overview">
          {!tenant || (
            <Tabs.TabPane
              key="overview"
              tab={renderFormatMessage('dashboard.tenantPage.overviewTab.heading', 'Overview')}
            >
              <TenantTabOverview tenant={tenant} onSave={setTenant} />
            </Tabs.TabPane>
          )}
          <Tabs.TabPane key="billing" tab="Billing">
            <TenantTabBilling tenant={tenant} onSave={setTenant} />
          </Tabs.TabPane>
          {!tenant || (
            <Tabs.TabPane key="location" tab={renderFormatMessage('dashboard.tenantPage.location.heading', 'Location')}>
              <TenantTabLocation tenant={tenant} onSave={setTenant} />
            </Tabs.TabPane>
          )}
          {!tenant || (
            <Tabs.TabPane key="access" tab={renderFormatMessage('dashboard.text.access', 'Access')}>
              <TenantTabAccessCode tenant={tenant} onSave={setTenant} />
            </Tabs.TabPane>
          )}
          {tenant.tenantUniformPricing && (
            <Tabs.TabPane key="pricing" tab={renderFormatMessage('dashboard.tenantPage.heading.pricing', 'Pricing')}>
              <TenantTabPricingV2 tenant={tenant} onSave={setTenant} />
            </Tabs.TabPane>
          )}
          {!tenant || (
            <Tabs.TabPane key="contact" tab="Contact">
              <TenantTabContact tenant={tenant} />
            </Tabs.TabPane>
          )}
          {!tenant || (
            <Tabs.TabPane
              key="deployment"
              tab={renderFormatMessage('dashboard.tenantPage.heading.deployment', 'Deployment')}
            >
              <TenantDeploymentTab tenant={tenant} />
            </Tabs.TabPane>
          )}
          {!tenant?.chargerCount || (
            <Tabs.TabPane key="chargers" tab={`${tenantTabChargerText} (${tenant.chargerCount})`}>
              <TenantTabChargers />
            </Tabs.TabPane>
          )}
        </Tabs>
      </Box>
    </>
  )
}

export const TenantPageV2 = withAuthenticatedLayout(TenantBasePage)
