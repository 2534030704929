import { useEffect, useState } from 'react'

import { useAppState } from '../state'

import { SwtchError } from '../models/error'
import { TenantRef } from '../models/tenant'
import { NewListing } from '../models/listing'
import { ListingFilter } from '../models/filter'
import { PaginationMeta } from '../models/pagination'
import { ConnectorServiceStatus } from '../models/connector'

import { GetListings } from '../services/data-provider/listing'

const initialPagination = { currentPage: 1, perPage: 1, totalEntries: 2 }

export const useListings = () => {
  const { selectedTenant } = useAppState()

  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [pagination, setPagination] = useState<PaginationMeta>(initialPagination)
  const [listings, setListings] = useState<NewListing[]>([])

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()

  const [filter, setFilter] = useState<ListingFilter>({
    page: 1,
    defaultTenant: selectedTenant,
  })

  useEffect(() => {
    fetchListings()
  }, [filter.page, pagination.currentPage])

  const fetchListings = () => {
    setLoading(true)
    setError(undefined)
    GetListings(filter)
      .then((resp) => {
        setListings(resp.data)
        setPagination(resp.pagination)
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  const handlePaginationChange = (page: number, pageSize?: number) => {
    setPagination({ ...pagination, currentPage: page })
    setFilter({ ...filter, page })
    return
  }

  const handleTenantSelection = (tenants: TenantRef[]) => setFilter({ ...filter, tenants })

  const handleListingTitle = (listingTitle: string[]) => setFilter({ ...filter, listingTitle })

  const handleListingIds = (listingId: number[]) => setFilter({ ...filter, listingId })

  const handleServiceStatuses = (serviceStatuses: ConnectorServiceStatus[]) => setFilter({ ...filter, serviceStatuses })

  return {
    handlePaginationChange,
    handleTenantSelection,
    handleListingTitle,
    handleListingIds,
    handleServiceStatuses,
    fetchListings,
    setTenants,
    setFilter,
    filter,
    tenants,
    listings,
    pagination,
    loading,
    error,
  }
}
