import React, { useState } from 'react'

import { Button, DatePicker, Form, Input, Modal, Select, Space, Switch, message } from 'antd'
import moment from 'moment'
import { formLayout, tailLayout } from '../../atom/form/page-layout'
import { AlertError } from '../../components/error'
import { PeakShavingProgramSelector } from '../../components/selector/peak-shaving-program-selector'
import { SwtchApiError } from '../../models/error'
import { PeakShavingEvent } from '../../models/peak-shaving'
import { CreateNewPeakShavingEvent } from '../../services/data-provider/peak-shaving'
import { styled } from '../../theme'

interface props {
  visible: boolean
  onCancel(): void
  onSubmit(): void
}

const DatePickerContainer = styled.div`
  .ant-picker-footer {
    display: none;
  }
`

export const CreateNewEventModal: React.FC<props> = ({ visible, onCancel, onSubmit }) => {
  const [loading, setLoading] = useState(false)
  const [CreateNewEventForm] = Form.useForm()
  const [error, setError] = useState<SwtchApiError>()

  const handleOk = (
    values: Omit<PeakShavingEvent, 'id' | 'program' | 'state' | 'adminPersonId' | 'systemGenerated' | 'adminPerson'>,
  ) => {
    setLoading(true)
    CreateNewPeakShavingEvent(values)
      .then((resp) => {
        message.success(`Event for program ${resp.program.name} successfully created!`)
        onSubmit()
      })
      .catch((err: SwtchApiError) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleCancel = () => {
    CreateNewEventForm.resetFields()
    onCancel()
    setError(undefined)
  }

  return (
    <>
      <Modal
        title="Create New Event"
        visible={visible}
        confirmLoading={loading}
        footer={null}
        onCancel={() => {
          setError(undefined)
          onCancel()
        }}
        width={1024}
      >
        <AlertError error={error} />
        <Form
          {...formLayout}
          form={CreateNewEventForm}
          onFinish={handleOk}
          style={{ marginTop: '2rem' }}
          initialValues={{
            date: moment().format('YYYY-MM-DD'),
            source: 'manual',
            allow_opt_out: false,
          }}
        >
          <Form.Item label="Date" name="date">
            <DatePickerContainer>
              <DatePicker
                size={'large'}
                defaultValue={moment()}
                disabledDate={(current) => {
                  let customDate = moment().format('YYYY-MM-DD')
                  return current && current < moment(customDate, 'YYYY-MM-DD')
                }}
                onChange={(_, dateString: string) => {
                  CreateNewEventForm.setFieldsValue({ ['date']: dateString })
                }}
                showToday={false}
              />
            </DatePickerContainer>
          </Form.Item>
          <Form.Item label="Program" name="programId" rules={[{ required: true, message: 'Program is required!' }]}>
            <PeakShavingProgramSelector
              onProgramSelected={(l) => CreateNewEventForm.setFieldsValue({ ['programId']: l.id })}
              onClear={() => {}}
              state="published"
            />
          </Form.Item>
          <Form.Item label="Allow Opt Out" name="allow_opt_out" valuePropName="checked">
            <Switch />
          </Form.Item>

          <Form.Item label="Source" name="source">
            <Select options={[{ value: 'manual', label: 'Manual' }]} />
          </Form.Item>
          <Form.Item label="Notes" name="notes">
            <Input.TextArea rows={5} />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Space>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
