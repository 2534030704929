import evzoneLogo from '../assets/images/evzone_logo.png'
import evzoneCar from '../assets/images/evzone_car.png'
import swtchBanner from '../assets/images/swtch-banner.png'
import pcBanner from '../assets/images/pc-banner.png'
import swtchIcon from '../assets/images/swtch-icon-80x80.png'
import pcIcon from '../assets/images/pc-icon-2000x2000.png'
import atgIcon from '../assets/images/atg_logo.png'

export const swtchLogoStyle = {
  margin: '2px 13px',
  filter: 'invert(1)',
  width: '75%',
}

export const swtchIconStyle = {
  margin: '5px 20px',
  width: '53%',
  filter: 'invert(1)',
}

export const powerChargeLogoStyle = {
  width: '90%',
  margin: '5px 10px',
}

export const powerChargeIconStyle = {
  width: '50%',
  margin: '5px 20px',
}

export const evzoneLogoStyle = {
  width: '75%',
  margin: '10px 13px',
}

export const evzoneIconStyle = {
  width: '80%',
  margin: '0px 10px',
}
export const atgLogoStyle = {
  width: '75%',
  margin: '10px 25px',
}

export const atgIconStyle = {
  width: '80%',
  margin: '0.75rem',
}

export const swtchInfo = {
  logoAlt: 'SWTCH Energy Logo',
  iconAlt: 'SWTCH Energy Icon',
  name: 'swtch',
  title: 'Swtch',
  iconStyle: swtchIconStyle,
  logoStyle: swtchLogoStyle,
  icon: swtchIcon,
  banner: swtchBanner,
}

export const powerChargeInfo = {
  logoAlt: 'PowerCharge banner',
  iconAlt: 'PowerCharge icon',
  name: 'powercharge',
  title: 'PowerCharge',
  iconStyle: powerChargeIconStyle,
  logoStyle: powerChargeLogoStyle,
  icon: pcIcon,
  banner: pcBanner,
}

export const evzoneInfo = {
  logoAlt: 'EVZONE logo',
  iconAlt: 'EVZONE icon',
  name: 'evzone',
  title: 'EV ZONE',
  iconStyle: evzoneIconStyle,
  logoStyle: evzoneLogoStyle,
  icon: evzoneLogo,
  banner: evzoneCar,
}

export const atgInfo = {
  logoAlt: 'ATG logo',
  iconAlt: 'ATG icon',
  name: 'atg',
  title: 'ATG',
  iconStyle: atgIconStyle,
  logoStyle: atgLogoStyle,
  icon: atgIcon,
  banner: atgIcon,
}

export const clientInfo = {
  swtch: swtchInfo,
  powercharge: powerChargeInfo,
  evzone: evzoneInfo,
  atg: atgInfo
}
