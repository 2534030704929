import React from 'react'
import moment from 'moment'
import momentTz from 'moment-timezone'

interface props {
  date: moment.Moment | string
  timezone: string
}

export const DateTime: React.FC<props> = ({ date, timezone }) => {
  if (typeof date === 'string') {
    date = moment(date)
  }

  const format = 'YYYY-MM-DD, HH:mm:ss'
  return (
    <span>
      {date.format(format)}
      <small> {momentTz.tz(date, timezone).format('(Z)')}</small>
    </span>
  )
}
