import { theme } from '../../theme'

export const errorSVG = (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7744_27859)">
      <path
        d="M15.5932 3.03646L27.6222 23.5129C27.733 23.7015 27.7913 23.9155 27.7913 24.1333C27.7913 24.3512 27.733 24.5652 27.6222 24.7538C27.5113 24.9425 27.3519 25.0992 27.16 25.2081C26.968 25.317 26.7503 25.3743 26.5286 25.3743H2.47072C2.24907 25.3743 2.03132 25.317 1.83936 25.2081C1.6474 25.0992 1.488 24.9425 1.37718 24.7538C1.26635 24.5652 1.20801 24.3512 1.20801 24.1333C1.20801 23.9155 1.26636 23.7015 1.37718 23.5129L13.4061 3.03646C13.517 2.84782 13.6764 2.69117 13.8683 2.58226C14.0603 2.47335 14.278 2.41602 14.4997 2.41602C14.7213 2.41602 14.9391 2.47335 15.131 2.58226C15.323 2.69117 15.4824 2.84782 15.5932 3.03646ZM13.2369 19.1694V21.6514H15.7624V19.1694H13.2369ZM13.2369 10.4824V16.6874H15.7624V10.4824H13.2369Z"
        fill={theme.colors.dangerPrimary}
      />
    </g>
    <defs>
      <clipPath id="clip0_7744_27859">
        <rect width="29" height="29" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
