import { useState } from 'react'
import { useHistory } from 'react-router'
import { Form, message } from 'antd'

import { SwtchError } from '../models/error'
import { ListingRef } from '../models/listing'

import { useFormatMessage } from '../helpers/intl'
import { addQueryString } from '../helpers/url'

import { Links } from '../components/routes/paths'
import { CreateNewListing } from '../services/data-provider/listing'

export const useCreateListing = () => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<SwtchError>()
  const history = useHistory()

  const createdNewListingTxt = useFormatMessage(
    'dashboard.createNewListing.listingCreatedSuccessfullyText',
    'Listing -1 Created Successfully',
  )

  setTimeout(() => {
    setLoading(false)
  }, 1000)

  const onFinish = () => {
    let queryParams: {
      [key: string]: string
    } = {}
    setLoading(true)
    CreateNewListing({
      authorId: form.getFieldValue('authorId'),
      title: form.getFieldValue('title'),
      currency: form.getFieldValue('currency'),
      priceType: form.getFieldValue('priceType'),
      additionalServiceFee: form.getFieldValue('additionalServiceFee'),
      timeOfDayEnabled: form.getFieldValue('timeOfDayEnabled'),
      pricePerHour: form.getFieldValue('pricePerUnitCents'),
      commisionPercent:
        form.getFieldValue('commisionPercent') === '2.9% + 30c' ? 0.0 : form.getFieldValue('commisionPercent'),
      accessType: form.getFieldValue('accessType'),
      carDetailRequired: form.getFieldValue('carDetailRequired'),
      customNotification: form.getFieldValue('customNotification'),
      output: form.getFieldValue('output'),
      flatFee: form.getFieldValue('flatFee'),
      tenantId: `${form.getFieldValue('tenantId')}`,
      kind: form.getFieldValue('kind'),
      paymentType: form.getFieldValue('paymentType'),
      hidden: form.getFieldValue('hidden'),
      stripeAccountId: form.getFieldValue('stripeAccountId'),
      loiteringStatus: form.getFieldValue('loiteringStatus'),
      loiteringGracePeriod: form.getFieldValue('loiteringGracePeriod'),
      loiteringPenalty: form.getFieldValue('loiteringPenalty'),
      disableLoiteringForTenantMember: form.getFieldValue('disableLoiteringForTenantMember'),
      description: form.getFieldValue('description'),
      preferedPricePerUnitCents: form.getFieldValue('preferedPricePerUnitCents') * 100,
      enableTieredPricing: form.getFieldValue('enableTieredPricing'),
      pricePerUnitCents: form.getFieldValue('pricePerUnitCents') * 100,
      loiteringTimeLimit: form.getFieldValue('loiteringTimeLimit'),
      guestFeeCents: form.getFieldValue('guestFeeCents') * 100,
      paymentLimitCents: form.getFieldValue('paymentLimitCents') * 100,
      enableLoadManagementPricing: form.getFieldValue('enableLoadManagementPricing'),
      enableLoadManagementAutoStop: form.getFieldValue('enableLoadManagementAutoStop'),
      addCloudLoadManagement: form.getFieldValue('addCloudLoadManagement'),
      enableTimeOfDayPricing: form.getFieldValue('enableTimeOfDayPricing'),
      timeOfDayPriceWeekday: form.getFieldValue('timeOfDayPriceWeekday').map((price: number) => price * 100),
      timeOfDayPriceWeekend: form.getFieldValue('timeOfDayPriceWeekend').map((price: number) => price * 100),
      shavingEnabled: false,
      shavingPrimaryZoneStrategy: 0,
      shavingSecondaryZoneStrategy: 0,
      shavingEventSource: 'manual',
    })
      .then((listing: ListingRef) => {
        message.success(createdNewListingTxt.replace('-1', listing.title))
        queryParams['id'] = `${listing.id}`
        queryParams['listingTitle'] = `${listing.title}`
        history.push(addQueryString(Links.listings(), queryParams))
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }
  return { loading, error, initialValues, form, onFinish }
}

const initialValues = {
  additionalServiceFee: 'on',
  hidden: false,
  carDetailRequired: false,
  free: false,
  loiteringTimeLimit: 0,
  loiteringPenalty: 0,
  loiteringGracePeriod: 0,
  disableLoiteringForTenantMember: false,
  output: 3,
  paymentType: 'wallet',
  accessType: 'public',
  loiteringStatus: 'no_penalty',
  kind: 'no_reservation',
  priceType: 'Hour',
  currency: 'CAD',
  pricePerUnitCents: 0,
  preferedPricePerUnitCents: 0,
  addCloudLoadManagement: false,
  enableLoadManagementPricing: false,
  enableTieredPricing: false,
  timeOfDayEnabled: false,
  enableLoadManagementAutoStop: false,
  enableTimeOfDayPricing: false,
  timeOfDayPriceWeekday: Array(24).fill(0),
  timeOfDayPriceWeekend: Array(24).fill(0),
  commisionPercent: '2.9% + 30c',
  customComissionFee: 0,
  flatFee: 0,
  guestFeeCents: 0.5,
  paymentLimitCents: 10,
}
