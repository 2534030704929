import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'

export const useTransactionMetricTranslation = () => {
  const cancelHeading = useFormatMessage('dashboard.transactionsPage.statsView.cancel', 'Cancel')
  const errorHeading = useFormatMessage('dashboard.transactionsPage.statsView.error', 'Error')
  const totalHeading = useFormatMessage('dashboard.transactionsPage.statsView.total', 'Total')
  const totalTransactionsText = renderFormatMessage(
    'dashboard.transactionsPage.statsView.totalTransactions',
    'Total Transactions',
  )
  const totalEnergyText = renderFormatMessage('dashboard.transactionsPage.statsView.totalEnergy', 'Total Energy (kWh)')
  const totalRevenueText = renderFormatMessage(
    'dashboard.transactionsPage.statsView.totalNetRevenue',
    'Total Net Revenue',
  )
  const totalPlugInDurationText = renderFormatMessage(
    'dashboard.transactionsPage.statsView.totalPlugInDuration',
    'Total Plug-In Duration',
  )

  return {
    cancelHeading,
    errorHeading,
    totalHeading,
    totalTransactionsText,
    totalEnergyText,
    totalRevenueText,
    totalPlugInDurationText,
  }
}
