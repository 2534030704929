import { Button, Divider, Form, Input, PageHeader, Select, Space, Spin, Table, Tabs, Tooltip, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'

import { AlertError } from '../../components/error'
import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { Links } from '../../components/routes/paths'
import { ListingSelector } from '../../components/selector/listing-selector'

import { SwtchError } from '../../models/error'
import { ListingRef } from '../../models/listing'

import { CloseOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { formLayout } from '../../atom/form/page-layout'
import { PeakShavingEvent, PeakShavingProgram, PeakShavingProgramListing } from '../../models/peak-shaving'

import { DeleteProgramModal } from './delete-program-modal'
import {
  GetPeakShavingProgram,
  GetAllListingsForProgram,
  UpdatePeakShavingProgram,
  AddListingForProgram,
  publishProgram,
  unPublishProgram,
  removeListingFromProgram,
  GetAllPeakShavingEvents,
} from '../../services/data-provider/peak-shaving'
import { PeakShavingEventFilter } from '../../models/filter'
import { PaginationMeta } from '../../models/pagination'
import styled from 'styled-components'

const ProgramEventsContainer = styled.div`
  .ant-table-title {
    text-align: center;
  }
`

const PeakShavingEventBasePage: React.FC = () => {
  //@ts-ignore
  const { programId } = useParams()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const [form] = Form.useForm()
  const [peakShavingProgram, setPeakShavingProgram] = useState<PeakShavingProgram>()
  const [disabled, setDisabled] = useState(true)
  const [deleteProgramModalVisible, setDeleteProgramModalVisible] = useState(false)
  const [programListings, setProgramListings] = useState<PeakShavingProgramListing[]>()
  const [addListingToProgramDisabled, setAddListingToProgramDisabled] = useState(true)
  const [listing, setListing] = useState<ListingRef>()
  const [term, setTerm] = useState('')
  const history = useHistory()
  const [filter, setFilter] = useState<PeakShavingEventFilter>({ page: 1, perPage: 20, programId: programId })
  const [pagination, setPagination] = useState<PaginationMeta>()
  const [peakShavingEvents, setPeakShavingEvents] = useState<PeakShavingEvent[]>([])

  const handleListingSearch = (listing: ListingRef) => {
    setAddListingToProgramDisabled(!addListingToProgramDisabled)
    setListing(listing)
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Charger Serial Number',
      dataIndex: 'chargerSerialNumber',
    },
    {
      title: 'Charger Vendor',
      dataIndex: 'chargerVendor',
    },
    {
      title: 'Charger Model',
      dataIndex: 'chargerModel',
    },
    {
      title: 'Tenant',
      dataIndex: 'tenantName',
    },
    {
      title: 'Remove Listing from Program',
      render: (peakShavingProgramListing: PeakShavingProgramListing) => {
        return (
          <Button type="ghost" danger onClick={() => handleRemoveListingFromProgram(peakShavingProgramListing)}>
            <CloseOutlined style={{ fontSize: '20px' }} />
          </Button>
        )
      },
    },
  ]

  const eventsTableColumns = [
    {
      title: 'Id',
      dataIndex: 'id',
      render: (id: number) => {
        return <Link to={Links.peakShavingEvent({ peakShavingEventId: id })}>{id}</Link>
      },
    },
    {
      title: 'Admin Person Id',
      dataIndex: 'adminPersonId',
    },
    {
      title: 'Allow Opt Out',
      dataIndex: 'allowOptOut',
      render: (allowOptOut: boolean) => {
        return allowOptOut ? 'Yes' : 'No'
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
    },

    {
      title: 'Notes',
      dataIndex: 'notes',
    },
    {
      title: 'State',
      dataIndex: 'state',
    },
    {
      title: 'Source',
      dataIndex: 'source',
    },
    {
      title: 'System Generated',
      dataIndex: 'systemGenerated',
      render: (systemGenerated: boolean) => {
        return systemGenerated ? 'System' : 'User'
      },
    },
  ]

  useEffect(() => {
    document.querySelector('body')?.classList.add('redesignActive')
    setLoading(true)
    Promise.all([
      GetPeakShavingProgram(programId).then((resp) => {
        setPeakShavingProgram(resp)
      }),
      GetAllListingsForProgram(programId).then((resp) => {
        setProgramListings(resp)
      }),
    ])
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [programId, term])

  const getAllPeakShavingEvents = () => {
    setLoading(true)
    GetAllPeakShavingEvents(filter)
      .then(({ data, pagination }) => {
        setPeakShavingEvents(data)
        setPagination(pagination)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onPaginationChange = (page: number, pageSize?: number) => {
    setFilter({ ...filter, page })
  }

  useEffect(() => {
    getAllPeakShavingEvents()
  }, [programId, filter])

  const onModify = () => {
    setDisabled(!disabled)
    const { listingSelector, ...updatePeakShavingPrams } = form.getFieldsValue()
    setLoading(true)
    UpdatePeakShavingProgram(updatePeakShavingPrams)
      .then((resp) => {
        setPeakShavingProgram(resp)
        message.success(`Program ${resp.name} successfully updated!`)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const addListingToProgram = () => {
    setLoading(true)
    listing &&
      AddListingForProgram(programId, listing.id)
        .then(({ response }) => {
          message.success(response)
          setTerm(`${_.random(Number.MIN_VALUE, Number.MAX_VALUE)}`)
        })
        .catch((err) => {
          setError(err)
        })
        .finally(() => {
          setLoading(false)
        })
  }

  const handlepublishProgram = () => {
    setLoading(true)
    publishProgram(programId)
      .then(({ response }) => {
        message.success(response)
        setTerm(`${_.random(Number.MIN_VALUE, Number.MAX_VALUE)}`)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleUnPublishProgram = () => {
    setLoading(true)
    unPublishProgram(programId)
      .then(({ response }) => {
        message.success(response)
        setTerm(`${_.random(Number.MIN_VALUE, Number.MAX_VALUE)}`)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleRemoveListingFromProgram = (peakShavingProgramListing: PeakShavingProgramListing) => {
    const { id } = peakShavingProgramListing
    setLoading(true)
    removeListingFromProgram(programId, id)
      .then(({ response }) => {
        message.success(response)
        setTerm(`${_.random(Number.MIN_VALUE, Number.MAX_VALUE)}`)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Spin spinning={loading}>
      <AlertError error={error} />
      <PageHeader
        title="Program"
        extra={
          <Space>
            {peakShavingProgram &&
              peakShavingProgram.state === 'draft' &&
              programListings &&
              programListings.length > 0 && (
                <Tooltip
                  title={
                    programListings && programListings.length === 0
                      ? 'Program needs to be associated with at least 1 listing to publish'
                      : ''
                  }
                >
                  <Button
                    type="primary"
                    onClick={handlepublishProgram}
                    disabled={programListings && programListings.length === 0}
                  >
                    Publish Program
                  </Button>
                </Tooltip>
              )}
            {peakShavingProgram && peakShavingProgram.state === 'published' && (
              <Button type="primary" onClick={handleUnPublishProgram}>
                UnPublish Program
              </Button>
            )}
            {peakShavingProgram && peakShavingProgram.state === 'draft' && !peakShavingProgram.hasShavingEvents && (
              <Button
                type="primary"
                danger
                onClick={() => setDeleteProgramModalVisible(!deleteProgramModalVisible)}
                disabled={!disabled}
              >
                Delete Program
              </Button>
            )}
          </Space>
        }
      />

      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Program Details" key="programDetails">
          {peakShavingProgram && (
            <Form
              {...formLayout}
              form={form}
              // onFinish={handleOk}
              style={{ marginTop: '2rem' }}
              initialValues={{ ...peakShavingProgram }}
            >
              <Form.Item name="id">
                <Input disabled={disabled} hidden />
              </Form.Item>
              <Form.Item name="listingSelector" label="Listing">
                <Space>
                  <ListingSelector
                    onListingSelected={(selectedListing: ListingRef) => handleListingSearch(selectedListing)}
                    onClear={() => {
                      setAddListingToProgramDisabled(!addListingToProgramDisabled)
                      setListing(undefined)
                    }}
                    onSearch={(selectedListing: ListingRef) => handleListingSearch(selectedListing)}
                    shaving={false}
                    peakShavingProgramListing={programListings}
                  />
                  <Button type="primary" disabled={addListingToProgramDisabled} onClick={addListingToProgram}>
                    Add Listing to Program
                  </Button>
                </Space>
              </Form.Item>
              <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Name is required!' }]}>
                <Input disabled={disabled} />
              </Form.Item>
              <Form.Item label="Category" name="category">
                <Select
                  options={[
                    { value: 'peak shaving', label: 'Peak Shaving' },
                    { value: 'demand response', label: 'Demand Response' },
                  ]}
                  disabled={disabled}
                />
              </Form.Item>
              <Form.Item label="Initiative" name="initiative">
                <Input.TextArea rows={5} disabled={disabled} />
              </Form.Item>
              <Form.Item
                {...{
                  wrapperCol: { offset: 10, span: 18 },
                }}
              >
                <Space>
                  <Button onClick={() => setDisabled(!disabled)} disabled={disabled}>
                    Cancel
                  </Button>
                  {disabled && (
                    <Button type="primary" onClick={() => setDisabled(!disabled)}>
                      Modify Program
                    </Button>
                  )}
                  {!disabled && (
                    <Button type="primary" htmlType="submit" onClick={onModify}>
                      Update Program
                    </Button>
                  )}
                </Space>
              </Form.Item>
            </Form>
          )}

          {programListings && programListings.length > 0 && (
            <>
              <Divider>Listings Associated with Program</Divider>
              <Table columns={columns} dataSource={programListings} rowKey="id" pagination={false} />
            </>
          )}

          {peakShavingProgram && (
            <DeleteProgramModal
              visible={deleteProgramModalVisible}
              onCancel={() => {
                setDeleteProgramModalVisible(!deleteProgramModalVisible)
              }}
              onSubmit={() => {
                setDeleteProgramModalVisible(!deleteProgramModalVisible)
                history.push(Links.peakShavingPrograms())
              }}
              program={peakShavingProgram}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Events" key="programEvents">
          <ProgramEventsContainer>
            <Table
              title={() => `Events for Program ${peakShavingProgram?.name}`}
              columns={eventsTableColumns}
              dataSource={peakShavingEvents}
              loading={loading}
              rowKey="id"
              pagination={{
                position: ['bottomCenter'],
                total: pagination?.totalEntries,
                current: pagination?.currentPage,
                pageSize: pagination?.perPage,
                showSizeChanger: false,
                onChange: onPaginationChange,
              }}
            />
          </ProgramEventsContainer>
        </Tabs.TabPane>
      </Tabs>
    </Spin>
  )
}

export const PeakShavingProgramPage = withAuthenticatedLayout(PeakShavingEventBasePage)
