import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StatisticValue from '../../components/statistic/StatisticValue'
import { faPlug } from '@fortawesome/free-solid-svg-icons'

import { Widget } from '../../components/widget'
import { getLastConnectionTime } from '../../helpers/charger/lastConnectionTime'

import { useFormatMessage } from '../../helpers/intl'
import { renderConnectionMessage, statusColor, statusIcon } from '../../helpers/status'
import { useChargerTranslation } from '../../hooks/translation/useChargerTranslation'
import { useConnectorTranslation } from '../../hooks/translation/useConnectorTranslation'

import { Charger } from '../../models/charger'
import { Connector } from '../../models/connector'

import { theme } from '../../theme'

interface props {
  loading: boolean
  charger: Charger
  connector?: Connector
}

const ConnectorStatusWidget = ({ loading, charger, connector }: props) => {
  const { connectorStatusText, disconnectedText, disconnectedMessageText } = useConnectorTranslation()
  const { chargerStatusText, offlineText, onlineText } = useChargerTranslation()

  const connectorStatus =
    typeof connector?.state === 'string' ? renderConnectionMessage(connector.state) : renderConnectionMessage('Unknown')

  const connectorStatisticStatusText = useFormatMessage(connectorStatus, connectorStatus)

  const chargerOrConnectorStatus = () => {
    if (connector && typeof connector.state === 'string') {
      return connectorStatisticStatusText
    } else {
      return charger.online ? onlineText : offlineText
    }
  }

  const title = () => {
    if (!connector) {
      return chargerStatusText
    } else if (connector && charger.online) {
      return connectorStatusText
    } else {
      return chargerStatusText
    }
  }

  const getValueStyle = () => {
    return connector
      ? statusColor(connector?.state)
      : charger.online
      ? `${theme.colors.neonGreen}`
      : `${theme.colors.red}`
  }

  const prefix = () => {
    return connector ? (
      statusIcon(connector?.state)
    ) : (
      <FontAwesomeIcon color={charger.online ? `${theme.colors.neonGreen}` : `${theme.colors.red}`} icon={faPlug} />
    )
  }

  return (
    <Widget
      loading={loading}
      title={title()}
      description={getLastConnectionTime(charger, disconnectedText, disconnectedMessageText, connector)}
      header={
        <StatisticValue value={chargerOrConnectorStatus()} valueStyle={{ color: getValueStyle() }} prefix={prefix()} />
      }
    />
  )
}
export default ConnectorStatusWidget
