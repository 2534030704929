import { BackTop, Button, Col, Input, PageHeader, Row, Select, Space } from 'antd'
import moment from 'moment'
import 'moment/locale/fr-ca'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { Box, Section } from '../../atom/box'
import { MobileCalendar } from '../../atom/mobile-calendar'
import { DateRangePicker } from '../../components/datepicker/datepicker'
import { AlertError } from '../../components/error'
import { ExportTransactions } from '../../components/export/export-transactions'
import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { ChargerSelector } from '../../components/selector/charger-selector'
import { ConnectorSelector } from '../../components/selector/connector-selector'
import { ListingSelector } from '../../components/selector/listing-selector'
import { LocationSelector } from '../../components/selector/location-selector'
import { MultiTenantSelector } from '../../components/selector/multiple-tenant-selector'
import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'
import { TransactionMetric } from '../../models/analytics'
import { ChargerRef } from '../../models/charger'
import { SwtchError } from '../../models/error'
import { PaginationMeta } from '../../models/pagination'
import { TenantRef } from '../../models/tenant'
import {
  Transaction,
  TransactionStartMethod,
  transactionStartMethods,
  transactionState,
  TransactionState,
} from '../../models/transaction'
import {
  ErrorTransaction,
  GetTransactions,
  GetTransactionsStats,
  StartTransaction,
  StopTransaction,
  TransactionFilter,
} from '../../services/data-provider/transaction'
import { useAppState } from '../../state'
import { StatsView } from './views/stats-view'
import { TableView } from './views/table-view'

const { Option } = Select


export const TransactionsBasePage: React.FC = () => {
  document.querySelector('body')?.classList.remove('redesignActive')
  const { selectedTenant, currentUser, IsMobile, IsTablet, IsLaptop, IsDesktop, language } = useAppState()
  const [filter, setFilter] = useState<TransactionFilter>({
    page: 1,
    defaultTenant: selectedTenant,
  })
  const [startDate, setStartDate] = useState(moment().startOf('day'))
  const [endDate, setEndDate] = useState(moment().startOf('day'))
  const [pagination, setPagination] = useState<PaginationMeta>()

  const [metrics, setMetrics] = useState<TransactionMetric>()
  const [loadingMetrics, setLoadingMetrics] = useState(false)
  const [transactions, setTransactions] = useState<Transaction[]>([])
  const [exportToken, setExportToken] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [showAdvance, setShowAdvance] = useState(false)

  const [error, setError] = useState<SwtchError>()
  const isMobile = IsMobile()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()
  const [mobileCalendarVisible, setMobileCalendarVisible] = useState(false)
  const [enableExportButton, setEnableExportButton] = useState(false)
  const [tenants, setTenants] = useState<TenantRef[]>([])

  const calendarRanges: string[] = [
    useFormatMessage('dashboard.calendar.today', 'Today'),
    useFormatMessage('dashboard.calendar.thisWeek', 'This Week'),
    useFormatMessage('dashboard.calendar.thisMonth', 'This Month'),
    useFormatMessage('dashboard.calendar.thisYear', 'This Year'),
  ]

  const advancedSearchBtn = useFormatMessage('dashboard.transactionsPage.advancedSearch', 'Advanced Search')
  const advancedSearcHidehBtn = useFormatMessage(
    'dashboard.transactionsPage.advancedSearchHide',
    'Hide Advanced Search',
  )

  useEffect(() => {
    getTxs()
  }, [filter.page])

  useEffect(() => {
    getTxStats()
  }, [])

  useEffect(() => {
    exportToken && setEnableExportButton(true)
  }, [exportToken])

  const onChangeDate = (dates: moment.Moment[]) => {
    if (dates[0] !== startDate) {
      setStartDate(dates[0])
    }
    if (dates[1] !== endDate) {
      setEndDate(dates[1])
    }
  }

  const onConfirm = (startDateTime?: moment.Moment, endDateTime?: moment.Moment) => {
    setMobileCalendarVisible(false)

    if (startDateTime && startDateTime !== startDate) {
      setStartDate(startDateTime)
    }

    if (endDateTime && endDateTime !== endDate) {
      setEndDate(endDateTime)
    }
  }

  const onPaginationChange = (page: number, pageSize?: number) => setFilter({ ...filter, page: page })

  const getTxStats = () => {
    setError(undefined)
    setLoadingMetrics(true)
    GetTransactionsStats(filter, startDate, endDate)
      .then((metric) => setMetrics(metric))
      .catch((err) => {
        setError((prevState) => {
          if (prevState) {
            let newError = prevState
            newError.messages = prevState.messages || []
            newError.messages.push(...err.messages)
            return newError
          }
          return err
        })
        setEnableExportButton(false)
      })
      .finally(() => setLoadingMetrics(false))
  }

  const getTxs = () => {
    setError(undefined)
    setLoading(true)
    setEnableExportButton(false)
    GetTransactions(filter, startDate, endDate)
      .then((response) => {
        setTransactions(response.data)
        setPagination(response.pagination)
        setExportToken(response.filters.exportToken)
      })
      .catch((err) => {
        setError((prevState) => {
          if (prevState) {
            let newError = prevState
            newError.messages = prevState.messages || []
            newError.messages.push(...err.messages)
            return newError
          }
          return err
        })
      })
      .finally(() => setLoading(false))
  }

  const getData = () => {
    getTxs()
    getTxStats()
  }

  const handleErrorTransaction = async (transaction: Transaction): Promise<void> => {
    setError(undefined)
    return ErrorTransaction(transaction)
      .then((trx) => {
        const updatedTrx = transactions.map((transaction) => {
          if (transaction.id === trx.id) {
            return trx
          }
          return transaction
        })
        setTransactions(updatedTrx)
      })
      .catch((err: SwtchError) => setError(err))
  }

  const handleStartTransaction = async (transaction: Transaction): Promise<void> => {
    setError(undefined)
    return StartTransaction(transaction)
      .then((trx) => {
        const updatedTrx = transactions.map((transaction) => {
          if (transaction.id === trx.id) {
            return trx
          }
          return transaction
        })
        setTransactions(updatedTrx)
      })
      .catch((err: SwtchError) => setError(err))
  }

  const handleStopTransaction = async (transaction: Transaction): Promise<void> => {
    setError(undefined)
    return StopTransaction(transaction)
      .then((trx) => {
        const updatedTrx = transactions.map((transaction) => {
          if (transaction.id === trx.id) {
            return trx
          }
          return transaction
        })
        setTransactions(updatedTrx)
      })
      .catch((err: SwtchError) => setError(err))
  }

  const renderTenantSelector = () => (
    <MultiTenantSelector
      onOptionsChange={(tenants) => {
        setTenants(tenants)
        setFilter({ ...filter, tenants: tenants })
      }}
      onClear={() => setFilter({ ...filter, tenants: [] })}
    />
  )

  const renderListingSelector = () => (
    <ListingSelector
      defaultTenant={selectedTenant}
      tenants={tenants}
      onListingSelected={(l) => setFilter({ ...filter, listing: l })}
      onClear={() => setFilter({ ...filter, listing: undefined })}
      onSearch={(l) => setFilter({ ...filter, listing: l })}
    />
  )

  const renderChargerSelector = () => (
    <ChargerSelector
      defaultTenant={selectedTenant}
      tenants={tenants}
      onChargerSelected={(c) => setFilter({ ...filter, charger: c })}
      onClear={() => setFilter({ ...filter, charger: undefined })}
      onSearch={(c) => setFilter({ ...filter, charger: c })}
    />
  )

  const renderConnectorSelector = (chargerRef: ChargerRef) => (
    <ConnectorSelector
      charger={chargerRef}
      onConnectorSelected={(c) => setFilter({ ...filter, connector: c })}
      onClear={() => setFilter({ ...filter, connector: undefined })}
    />
  )

  const renderLocationSelector = (chosenCountries: string[], chosenProvinces: string[]) => {
    setFilter({
      ...filter,
      country: chosenCountries.join(','),
      province: chosenProvinces.join(','),
    })
  }

  const SearchAndExportButtons = (
    <>
      <Button type="primary" onClick={getData} disabled={loading} block={true}>
        {useFormatMessage('dashboard.searchBtn.title', 'Search')}
      </Button>
      <ExportTransactions token={exportToken} disabled={!enableExportButton} />
    </>
  )

  const advancedSearchOption = (
    <>
      <Input
        placeholder={useFormatMessage('dashboard.transactionsPage.advancedSearch.transactionId', 'Transaction ID')}
        onChange={(e) => {
          if (e.target) {
            setFilter({ ...filter, transactionId: e.target.value })
          }
        }}
      />
      <Input
        placeholder={useFormatMessage('dashboard.transactionsPage.advancedSearch.username', 'User Name')}
        onChange={(e) => {
          if (e.target) {
            setFilter({ ...filter, userName: e.target.value })
          }
        }}
      />
      <Input
        placeholder={useFormatMessage('dashboard.transactionsPage.advancedSearch.useremail', 'User Email')}
        onChange={(e) => {
          if (e.target) {
            setFilter({ ...filter, userEmail: e.target.value })
          }
        }}
      />
      <Input
        placeholder={useFormatMessage(
          'dashboard.transactionsPage.advancedSearch.ocppTransactionId',
          'OCPP Transaction ID',
        )}
        onChange={(e) => {
          if (e.target) {
            setFilter({ ...filter, ocppTransactionId: e.target.value })
          }
        }}
      />
      <Select
        style={{ minWidth: 156, width: '100%' }}
        allowClear
        placeholder={useFormatMessage('dashboard.transactionsPage.advancedSearch.startMethod', 'Start Method')}
        onChange={(e) => {
          if (e) {
            setFilter({ ...filter, startMethod: e.toString() as TransactionStartMethod })
          }
        }}
        onClear={() => setFilter({ ...filter, startMethod: undefined })}
      >
        {transactionStartMethods.map((v) => {
          return (
            <Option key={v} value={v}>
              {renderFormatMessage(`dashboard.transactionsPage.advancedSearch.startMethod.${v}`, v)}
            </Option>
          )
        })}
      </Select>
      <Select
        style={{ minWidth: 156, width: '100%' }}
        allowClear
        placeholder={useFormatMessage('dashboard.transactionsPage.advancedSearch.state', 'State')}
        onChange={(e) => {
          if (e) {
            setFilter({ ...filter, state: e.toString() as TransactionState })
          }
        }}
        onClear={() => setFilter({ ...filter, state: undefined })}
      >
        {transactionState.map((v) => {
          return (
            <Option key={v} value={v}>
              {renderFormatMessage(`dashboard.transactionsPage.advancedSearch.state.${v}`, v)}
            </Option>
          )
        })}
      </Select>
      <LocationSelector onSearch={renderLocationSelector} />
    </>
  )

  return (
    <>
      <PageHeader title={useFormatMessage('dashboard.navigation.transactions', 'Transactions')} />
      <Box padding>
        {(isDesktop || isLaptop) && (
          <Row justify="end">
            <Col
              lg={language === 'fr' ? 17 : currentUser?.role !== 'user' ? 18 : 20}
              xl={language === 'fr' ? 18 : currentUser?.role !== 'user' ? 19 : 21}
              xxl={currentUser?.role !== 'user' ? 20 : 22}
            >
              <Space>
                {!selectedTenant && renderTenantSelector()}
                {renderListingSelector()}
                {renderChargerSelector()}
                {filter.charger && renderConnectorSelector(filter.charger)}
                <DateRangePicker
                  onChangeDate={onChangeDate}
                  startDate={startDate}
                  endDate={endDate}
                  calendarRanges={calendarRanges}
                />
              </Space>
            </Col>

            <Col
              lg={language === 'fr' ? 7 : currentUser?.role !== 'user' ? 6 : 4}
              xl={language === 'fr' ? 6 : currentUser?.role !== 'user' ? 5 : 3}
              xxl={currentUser?.role !== 'user' ? 4 : 2}
            >
              <Space>
                {SearchAndExportButtons}
                {currentUser && currentUser.role !== 'user' && (
                  <Link onClick={() => setShowAdvance(!showAdvance)} to={'#'}>
                    {advancedSearchBtn}
                  </Link>
                )}
              </Space>
            </Col>
          </Row>
        )}
        {(isTablet || isMobile) && (
          <Row align="middle" gutter={[8, 8]}>
            <Col xs={24} sm={24} md={24}>
              {!selectedTenant && renderTenantSelector()}
            </Col>
            <Col xs={24} sm={24} md={24}>
              {renderListingSelector()}
            </Col>
            <Col xs={24} sm={24} md={24}>
              {renderChargerSelector()}
            </Col>
            <Col xs={24} sm={24} md={24}>
              {filter.charger && renderConnectorSelector(filter.charger)}
            </Col>
            <Col xs={24} sm={24} md={24}>
              <MobileCalendar
                visible={mobileCalendarVisible}
                setMobileCalendarVisible={setMobileCalendarVisible}
                onCancel={() => setMobileCalendarVisible(false)}
                handleConfirm={onConfirm}
                startDate={startDate}
                endDate={endDate}
                calendarRanges={calendarRanges}
                language={language}
              />
            </Col>
            {SearchAndExportButtons}
            {currentUser && currentUser.role !== 'user' && (
              <Button block={true} onClick={() => setShowAdvance(!showAdvance)}>
                {showAdvance ? advancedSearcHidehBtn : advancedSearchBtn}
              </Button>
            )}
          </Row>
        )}
        {currentUser &&
          currentUser.role !== 'user' &&
          showAdvance &&
          (isDesktop || isLaptop ? (
            <Space wrap style={{ marginTop: '8px' }}>
              {advancedSearchOption}
            </Space>
          ) : (
            <Space wrap direction="vertical" style={{ width: '100%' }}>
              {advancedSearchOption}
            </Space>
          ))}
      </Box>
      <AlertError error={error} />
      <StatsView loading={loadingMetrics} metrics={metrics} />
      <Section>
        <TableView
          loading={loading}
          transactions={transactions}
          pagination={pagination}
          currentPage={filter.page}
          onErrorTransaction={handleErrorTransaction}
          onStartTransaction={handleStartTransaction}
          onStopTransaction={handleStopTransaction}
          onPaginationChange={onPaginationChange}
        />
      </Section>
      <BackTop />
    </>
  )
}
export const TransactionsPage = withAuthenticatedLayout(TransactionsBasePage)
