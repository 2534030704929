import configProvider from '../../config'
import { addQueryString } from '../../helpers/url'
import { apiClient } from './client'

import { sanitizeCreateNewListingObj } from '../../helpers/listing'
import { log } from '../../logger'
import {
  mockAllListings,
  mockAllListingsRespV1,
  mockListingFull,
  mockListingResp,
  mockNewListingResp,
} from '../../mock/listing-mock'
import { mockStripeAccountsResp } from '../../mock/stripe-accounts-mock'
import { ListingsBaseResponse, ListingsResponse } from '../../models/http'
import { ListingFull, ListingBase, ListingRef, CreateNewListingType } from '../../models/listing'
import { ListingStripeAccounts } from '../../models/stripe-account'
import { TenantRef } from '../../models/tenant'
import { returnMock } from './mock'
import { listingQuery } from '../../helpers/filter/listing'
import { ListingFilter } from '../../models/filter'

export async function GetAllListings(props: ListingFilter): Promise<ListingsBaseResponse> {
  let url = '/listings'

  const queryParams = listingQuery(props)

  url = addQueryString(url, queryParams)

  log('Get All Listings', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockAllListingsRespV1(props.page))
  }
  return apiClient(url, {})
}

export async function FindListings(
  term?: string,
  tenant?: TenantRef,
  tenants?: TenantRef[],
  shaving?: boolean,
): Promise<ListingRef[]> {
  let url = '/listings/find'

  let queryParams: { [key: string]: string } = {}

  if (shaving) {
    queryParams['shaving'] = `${shaving}`
  }

  if (term) {
    queryParams['term'] = `${term}`
  }

  if (tenant) {
    queryParams['tenant_id'] = `${tenant.id}`
  }

  if (tenants && tenants.length > 0) {
    queryParams['tenant_id'] = `${tenants.map((t) => t.id)}`
  }

  url = addQueryString(url, queryParams)

  log('finding listings', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockListingResp)
  }
  return apiClient(url, {})
}

export async function SearchListingIds(
  term?: number | string,
  tenant?: TenantRef,
  tenants?: TenantRef[],
): Promise<number[]> {
  let url = '/listings/listing_ids'

  let queryParams: { [key: string]: string } = {}

  if (term) {
    queryParams['listing_id'] = `${term}`
  }

  if (tenant) {
    queryParams['tenant_id'] = `${tenant.id}`
  }

  if (tenants && tenants.length > 0) {
    queryParams['tenant_id'] = `${tenants.map((t) => t.id)}`
  }

  url = addQueryString(url, queryParams)

  log('finding listings by ID', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock([1, 2, 3])
  }
  return apiClient(url, {})
}

export async function GetListings(props: ListingFilter): Promise<ListingsResponse> {
  let url = '/listings'

  const queryParams = listingQuery(props)

  url = addQueryString(url, queryParams)

  log('Get All Listings', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockNewListingResp(props.page))
  }
  return apiClient(url, {})
}

export async function GetAllShavingEnabledListings(): Promise<ListingRef[]> {
  let url = '/listings/find'

  let queryParams: { [key: string]: string } = {
    shaving: 'true',
  }

  url = addQueryString(url, queryParams)

  log('finding listings', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockListingResp)
  }
  return apiClient(url, {})
}

export async function GetListingStripeAccounts(): Promise<ListingStripeAccounts[]> {
  let url = '/listings/stripe_accounts'

  let queryParams: { [key: string]: string } = {}

  url = addQueryString(url, queryParams)

  log('finding listings', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockStripeAccountsResp[0])
  }
  return apiClient(url, {})
}

export async function CreateNewListing(listing: CreateNewListingType): Promise<ListingBase> {
  const url = '/listings'

  const listingParams = sanitizeCreateNewListingObj(listing)

  log('create a listing', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockAllListings[0])
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      listing: {
        ...listingParams,
      },
    }),
  })
}

export async function UpdateListing(listingId: number, listing: ListingFull): Promise<string> {
  const url = `/listings/${listingId}/edit`

  const listingParams = sanitizeCreateNewListingObj(listing)

  console.log('🚀 ~ file: listing.ts:129 ~ UpdateListing ~ listingParams:', listingParams)

  log('update a listing', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock('Success')
  }

  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      listing: {
        ...listingParams,
      },
    }),
  })
}
export async function GetListing(listingId: number): Promise<any> {
  const url = `/listings/${listingId}`

  log('get a listing', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockListingFull)
  }

  return apiClient(url, {})
}
