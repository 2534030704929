import { Button, Checkbox, Form, Select, Spin, Switch, Tooltip } from 'antd'
import { ReactElement } from 'react'
import { UserPermissions, userInviteInfo } from '../../../models/user'
import { TenantRef } from '../../../models/tenant'
import { cancelSVG } from '../../../assets/svg/cancel'
import { useUserTranslation } from '../../../hooks/translation/useUserTranslation'
import { addSVG } from '../../../assets/svg/add'
import { darkGreyInfoSVG } from '../../../assets/svg/darkGreyInfo'
import {
  Body,
  DriverSvgSpacing,
  DropdownWrapper,
  RolesDropdownWrapper,
  RolesSelectWrapper,
  SvgSpacing,
  SwitchLabel,
  TenantInputBody,
  UnselectedDropdownWrapper,
} from '../../../atom/user-edit'
import { LoadingOutlined } from '@ant-design/icons'

interface props {
  tenantInputs: number
  tenants: TenantRef[]
  tenantDropdownData: any
  userPermissions: UserPermissions[]
  loading: boolean
  setTenantInputs: (tenantInputs: any) => void
  setTenantDropdownData: (tenantDropdownData: any) => void
  setUserPermissions: (userPermissions: UserPermissions[]) => void
  setChanged: (disableSave: boolean) => void
}

const EditForm: React.FC<props> = ({
  tenantInputs,
  tenants,
  tenantDropdownData,
  userPermissions,
  loading,
  setTenantInputs,
  setTenantDropdownData,
  setUserPermissions,
  setChanged,
}) => {
  const [form] = Form.useForm()
  const { tenantsText, driverText, roleText, managerText, viewerText, addMoreText, tooltipText } = useUserTranslation()
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#30d7ba' }} spin />

  const renderOption = (tenant: TenantRef, index: any): ReactElement => {
    if (userPermissions[index].name === tenant.name) {
      return (
        <Select.Option style={{ width: '100%', padding: 0 }} key={tenant.id} value={tenant.name}>
          <DropdownWrapper>
            <Checkbox
              style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
              checked={userPermissions[index].name === tenant.name}
            >
              {tenant.name}
              <Button type="link" onClick={() => handleClearTenant(index)}>
                {cancelSVG}
              </Button>
            </Checkbox>
          </DropdownWrapper>
        </Select.Option>
      )
    } else {
      return (
        <Select.Option style={{ marginTop: '10px' }} key={tenant.id} value={tenant.name}>
          <UnselectedDropdownWrapper>
            <Checkbox checked={userPermissions[index].name === tenant.name}>{tenant.name}</Checkbox>
          </UnselectedDropdownWrapper>
        </Select.Option>
      )
    }
  }

  const handleClearTenant = (index: number) => {
    const tempData = userPermissions
    tempData[index] = { name: '', id: '', access: tempData[index].access, driver: tempData[index].driver }
    setUserPermissions([...tempData])
  }

  const handleRoleChange = (value: string, index: number) => {
    setChanged(true)
    const tempData = userPermissions
    tempData[index] = {
      name: tempData[index].name,
      id: tempData[index].id,
      access: value,
      driver: tempData[index].driver,
    }
    setUserPermissions([...tempData])
  }

  const handleDriverChecked = (index: number) => {
    setChanged(true)
    const tempData = userPermissions
    tempData[index] = {
      name: tempData[index].name,
      id: tempData[index].id,
      access: tempData[index].access,
      driver: !tempData[index].driver,
    }
    setUserPermissions([...tempData])
  }

  const handleTenantChange = (value: string, index: number) => {
    setChanged(true)
    const tempData = userPermissions
    let id = ''

    tenantDropdownData[1].map((tenant: any) => {
      if (tenant.name === value) return (id = tenant.id)
      return null
    })
    tempData[index] = { name: value, id: id, access: tempData[index].access, driver: tempData[index].driver }
    setUserPermissions([...tempData])
    const tempTenantsArr = tenants.filter((tenant) => value !== tenant.name)
    tenants.filter((singleTenant) => {
      if (value === singleTenant.name) {
        return setTenantDropdownData({
          ...tenantDropdownData,
          [(index + 1) as keyof typeof tenantDropdownData]: [singleTenant, ...tempTenantsArr],
        })
      } else return null
    })
  }

  return (
    <Form form={form} labelAlign="right" layout="vertical">
      <>
        <hr
          style={{
            margin: '24px -24px 0px',
            border: 0,
            borderTop: '1px solid #f0f0f0',
          }}
        />
        <Spin spinning={loading} indicator={antIcon} style={{ background: 'white', top: '7px' }}>
          <TenantInputBody>
            {userPermissions.map((data, tenantInputIndex) => {
              return (
                <Body key={tenantInputIndex}>
                  {tenantDropdownData[1] && tenantInputIndex === 0 && (
                    <>
                      <Form.Item className="tenant-select" label={tenantsText}>
                        <Select
                          dropdownMatchSelectWidth={false}
                          showSearch
                          onChange={(value) => handleTenantChange(value, tenantInputIndex)}
                          value={data.name}
                        >
                          {tenantDropdownData[(tenantInputIndex + 1) as keyof typeof tenantDropdownData]
                            .map((v: any) => renderOption(v, tenantInputIndex))
                            .sort(
                              (a: ReactElement, b: ReactElement) =>
                                b.props.children.props.children.props.checked -
                                a.props.children.props.children.props.checked,
                            )}
                        </Select>
                      </Form.Item>
                      <Form.Item className="role-select" key="role" label={roleText}>
                        <RolesSelectWrapper>
                          <Select
                            onChange={(value) => handleRoleChange(value, tenantInputIndex)}
                            defaultValue={data.access}
                            value={data.access}
                          >
                            {userInviteInfo.map((role) => (
                              <Select.Option key={role} value={role}>
                                <RolesDropdownWrapper selected={data.access === role}>
                                  {role === 'none' ? '-' : role === 'manager' ? managerText : viewerText}
                                </RolesDropdownWrapper>
                              </Select.Option>
                            ))}
                          </Select>
                        </RolesSelectWrapper>
                      </Form.Item>
                      <Form.Item
                        className="switch"
                        key="driver"
                        label={
                          <>
                            {driverText}
                            <Tooltip
                              overlayInnerStyle={{ borderRadius: '5px', width: '172px', padding: '7px' }}
                              placement="top"
                              title={tooltipText}
                            >
                              <DriverSvgSpacing>{darkGreyInfoSVG}</DriverSvgSpacing>
                            </Tooltip>
                          </>
                        }
                      >
                        <div>
                          <SwitchLabel>Off</SwitchLabel>
                          <Switch
                            style={{
                              marginLeft: '5px',
                              marginRight: '5px',
                              backgroundColor: data.driver ? '#30D7BA' : '#bfbfbf',
                            }}
                            onChange={() => handleDriverChecked(tenantInputIndex)}
                            checked={data.driver}
                          />
                          <SwitchLabel>On</SwitchLabel>
                        </div>
                      </Form.Item>
                    </>
                  )}
                  {tenantDropdownData[(tenantInputIndex + 1) as keyof typeof tenantDropdownData] &&
                    tenantInputIndex > 0 && (
                      <>
                        <Form.Item className="tenant-select">
                          <Select
                            dropdownMatchSelectWidth={false}
                            showSearch
                            onChange={(value) => handleTenantChange(value, tenantInputIndex)}
                            value={data.name}
                          >
                            {tenantDropdownData[(tenantInputIndex + 1) as keyof typeof tenantDropdownData]
                              .map((v: any) => renderOption(v, tenantInputIndex))
                              .sort(
                                (a: ReactElement, b: ReactElement) =>
                                  b.props.children.props.children.props.checked -
                                  a.props.children.props.children.props.checked,
                              )}
                          </Select>
                        </Form.Item>
                        <Form.Item className="role-select" key="role">
                          <RolesSelectWrapper>
                            <Select
                              onChange={(value) => handleRoleChange(value, tenantInputIndex)}
                              defaultValue={data.access}
                              value={data.access}
                            >
                              {userInviteInfo.map((role) => (
                                <Select.Option key={role} value={role}>
                                  <RolesDropdownWrapper selected={data.access === role}>
                                    {role === 'none' ? '-' : role === 'manager' ? managerText : viewerText}
                                  </RolesDropdownWrapper>
                                </Select.Option>
                              ))}
                            </Select>
                          </RolesSelectWrapper>
                        </Form.Item>
                        <Form.Item className="switch" key="driver">
                          <SwitchLabel>Off</SwitchLabel>
                          <Switch
                            style={{
                              marginLeft: '5px',
                              marginRight: '5px',
                              backgroundColor: data.driver ? '#30D7BA' : '#bfbfbf',
                            }}
                            onChange={() => handleDriverChecked(tenantInputIndex)}
                            checked={data.driver}
                          />
                          <SwitchLabel>On</SwitchLabel>
                        </Form.Item>
                      </>
                    )}
                </Body>
              )
            })}
          </TenantInputBody>
          <Button
            style={{
              color: '#30D7BA',
              padding: 0,
              height: 'fit-content',
              marginBottom: '0px',
              letterSpacing: '0.05em',
            }}
            type="link"
            onClick={() => {
              setTenantInputs((currentTenantInputs: any) => currentTenantInputs + 1)
              setUserPermissions([...userPermissions, { name: '', id: '', access: '', driver: false }])
              setTenantDropdownData({ ...tenantDropdownData, [tenantInputs + 1]: [...tenants] })
            }}
          >
            <SvgSpacing>{addSVG}</SvgSpacing>
            {addMoreText}
          </Button>
        </Spin>
      </>
    </Form>
  )
}

export default EditForm
