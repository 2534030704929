import { theme } from '../../theme'

export const listingSVG = (
  <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.1111 18.5H0.888889C0.653141 18.5 0.427048 18.4052 0.260349 18.2364C0.0936505 18.0676 0 17.8387 0 17.6V1.4C0 1.16131 0.0936505 0.932387 0.260349 0.763604C0.427048 0.594821 0.653141 0.5 0.888889 0.5H15.1111C15.3469 0.5 15.573 0.594821 15.7397 0.763604C15.9064 0.932387 16 1.16131 16 1.4V17.6C16 17.8387 15.9064 18.0676 15.7397 18.2364C15.573 18.4052 15.3469 18.5 15.1111 18.5ZM4.44444 5V6.8H11.5556V5H4.44444ZM4.44444 8.6V10.4H11.5556V8.6H4.44444ZM4.44444 12.2V14H8.88889V12.2H4.44444Z"
      fill={theme.colors.neonGreen}
    />
  </svg>
)

export const listingWhiteSVG = (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.1111 18H0.888889C0.653141 18 0.427048 17.9052 0.260349 17.7364C0.0936505 17.5676 0 17.3387 0 17.1V0.9C0 0.661305 0.0936505 0.432387 0.260349 0.263604C0.427048 0.0948211 0.653141 0 0.888889 0H15.1111C15.3469 0 15.573 0.0948211 15.7397 0.263604C15.9064 0.432387 16 0.661305 16 0.9V17.1C16 17.3387 15.9064 17.5676 15.7397 17.7364C15.573 17.9052 15.3469 18 15.1111 18ZM4.44444 4.5V6.3H11.5556V4.5H4.44444ZM4.44444 8.1V9.9H11.5556V8.1H4.44444ZM4.44444 11.7V13.5H8.88889V11.7H4.44444Z"
      fill={theme.colors.white}
    />
  </svg>
)
