import styled from 'styled-components'

interface props {
  disabled: boolean
}

export const EmailWrapper = styled.div`
  margin-top: -4px;
  margin-bottom: -4px;

  div .ant-row .ant-form-item .email .ant-form-item-has-error {
    margin: 0 !important;
  }
  div {
    margin: 0;
  }
  .ant-form-item {
    margin: 0;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: -11px;
  }

  label.ant-form-item-required {
    position: relative;
  }

  .ant-form-item-control > .ant-form-item-explain {
    height: 0;
    min-height: 0;
    display: none;
  }

  .ant-select-selection-item,
  .redesignActive .ant-select-multiple .ant-select-selection-item {
    background-color: transparent !important;
  }
`

export const FooterLayout = styled.div<props>`
  display: flex;
  justify-content: space-between;
  padding: 10px 9px;

  .ant-btn-default {
    background-color: #ffffff;
    color: #000000;
    border-color: #dedede;
    box-shadow: none;
    text-shadow: none;
    height: 38px;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-align: center;
  }

  .ant-btn-primary {
    box-shadow: none;
    text-shadow: none;
    height: 38px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: center;
    background-color: ${({ disabled }) => (disabled ? '#F1F1F1 !important' : '#30D7BA')};
    border-color: ${({ disabled }) => (disabled ? '#F1F1F1 !important' : '#30D7BA')};
  }

  .ant-btn-default:hover {
    background-color: #ffffff;
    border-color: #dedede;
  }

  .ant-btn-primary:hover {
    background-color: #00b799;
    border-color: #00b799;
  }

  .ant-btn-primary > span > span {
    opacity: ${({ disabled }) => (disabled ? '0.15' : '1')};
  }

  .ant-btn-primary > svg > path {
    fill: ${({ disabled }) => (disabled ? '#cdcdcd' : '#000000')};
  }
`

export const Heading = styled.div`
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 400;
  font-family: AkkuratLLWeb-Regular;
`

export const HeadingDescription = styled.div`
  font-size: 12px;
  color: #7d838a;
  font-weight: 400;
  font-family: AkkuratLLWeb-Regular;
`

export const Body = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2px;

  .tenant-select {
    width: 240px;
    margin-right: 15px;
    margin-bottom: 16px;
  }

  .role-select {
    width: 120px;
    margin-right: 15px;
    margin-bottom: 16px;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-form-item-label > label {
    color: #7d838a;
    letter-spacing: 0.05em;
  }

  .switch .ant-form-item-label > label {
    color: #7d838a;
    letter-spacing: 0.05em;
    margin-top: 7px;
  }

  .ant-modal-body {
    max-height: 200px;
  }

  .ant-select-selection-item {
    background-color: transparent !important;
  }

  .ant-select-selection-item .ant-checkbox,
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    display: none;
  }

  svg.usersSelectorIcon {
    z-index: 2;
    position: absolute;
    top: 47%;
    right: 11px;
    left: 12px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all 0.3s;
  }

  .ant-checkbox + span {
    padding-left: 0px;
    max-width: 190px;
    overflow: hidden;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-search
    > .ant-select-selection-item
    > div
    > label
    > span {
    color: black !important;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-search
    > .ant-select-selection-item
    > div
    > label
    > span
    > svg {
    display: none;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-item
    > div
    > .ant-checkbox-wrapper
    > span {
    color: black !important;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-item
    > div
    > .ant-checkbox-wrapper
    > span
    > svg {
    display: none;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-item
    > div
    > .ant-checkbox-wrapper
    > span
    > button {
    display: none;
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #30d7ba !important;
    box-shadow: none !important;
  }
`

export const SvgSpacing = styled.span`
  svg {
    margin-right: 5px;
  }
`

export const SwitchLabel = styled.span`
  color: #7d838a;
`

export const ButtonTextSpacing = styled.span`
  margin-right: 2px;
`

export const SelectStyling = styled.div<{ disabled: boolean }>`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 20px;
    min-height: 38px;
    padding-top: 2.5px;
  }

  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    padding-top: 5px;
    border-radius: 0 !important;
  }

  .ant-select-selection-overflow {
    margin-left: 0px;
  }

  .ant-btn {
    border: 0;

    background-color: ${({ disabled, theme }) => (disabled ? theme.colors.lightGray : theme.colors.neonGreen)};
    &:hover {
      background-color: ${({ disabled, theme }) => (disabled ? theme.colors.lightGray : theme.colors.neonGreenHover)};
    }
  }
`

export const TenantInputBody = styled.div`
  max-height: 225px;
  overflow-y: scroll;
  padding-top: 21px;
  margin-bottom: -24px;

  // show scrollbar
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 11px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 11px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 8px;
  }
`

export const ErrorMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #ec4747;
  margin-top: 5px !important;
`

export const UsersTagsWrapper = styled.div`
  margin-top: 8px !important;

  .ant-tag {
    background: #f1f1f1;
    font-size: 11px;
    font-family: AkkuratLLWeb-Regular;
    margin-top: 7px;
  }
`

export const DropdownWrapper = styled.div`
  .ant-checkbox,
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    display: none;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #30d7ba;
  border-bottom: solid 1px #dedede;
  min-width: 100%;

  label > .ant-checkbox-checked {
    padding-left: 10px;
    margin-top: -5px;
  }

  label > span {
    color: #30d7ba !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    width: 100%;
  }

  label > span > button {
    background-color: transparent !important;
    border: none;
    padding: 10px 0px 10px 10px !important;
  }
`

export const UnselectedDropdownWrapper = styled.div`
  .ant-checkbox,
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    display: none;
  }
`

export const AddMoreWrapper = styled.div`
  .add-more-button {
    margin-bottom: -4px;
    margin-top: 24px;
  }
`
