import { Empty, EmptyProps } from 'antd'

import { renderFormatMessage } from '../../helpers/intl'
import { emptyBoxSVG } from '../../assets/svg/EmptyBox'

export const EmptyData: React.FC<EmptyProps> = ({ ...props }) => {
  return (
    <>
      <Empty
        {...props}
        image={emptyBoxSVG}
        description={renderFormatMessage('dashboard.text.nodataavailable', 'Sorry, no data to display.')}
      />
      <br />
    </>
  )
}
