import { useFormatMessage } from '../../helpers/intl'

export const useCalendarTransactions = () => {
  const calendarRanges: string[] = [
    useFormatMessage('dashboard.calendar.today', 'Today'),
    useFormatMessage('dashboard.calendar.past7days', 'Past 7 days'),
    useFormatMessage('dashboard.calendar.weektodate', 'Week to Date'),
    useFormatMessage('dashboard.calendar.monthtodate', 'Month to Date'),
    useFormatMessage('dashboard.calendar.yeartodate', 'Year to Date'),
    useFormatMessage('dashboard.calendar.lastmonth', 'Last Month'),
    useFormatMessage('dashboard.calendar.lastquarter', 'Last Quarter'),
    useFormatMessage('dashboard.calendar.customdate', 'Custom Date'),
  ]

  const shorterCalendarRanges: string[] = [
    useFormatMessage('dashboard.calendar.today', 'Today'),
    useFormatMessage('dashboard.calendar.thisWeek', 'This Week'),
    useFormatMessage('dashboard.calendar.thisMonth', 'This Month'),
    useFormatMessage('dashboard.calendar.thisYear', 'This Year'),
  ]

  return {
    calendarRanges,
    shorterCalendarRanges,
  }
}
