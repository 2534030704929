import { Card, Col, Row } from 'antd'

import { TrxSummary } from '../../../../models/transaction'
import { useHomeTranslation } from '../../../../hooks/translation/useHomeTranslation'
import { CardWrapper } from '../../../../atom/card'

interface props {
  loading: boolean
  trxProfileSummary?: TrxSummary
}

const EnergyMetrics: React.FC<props> = ({ loading, trxProfileSummary }) => {
  const { chargingPeakHours, energyTxt } = useHomeTranslation()
  return (
    <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
      <CardWrapper>
        <Card title={energyTxt} loading={loading} className="energy">
          <Row align="middle" justify="space-between">
            <Col sm={24} xxl={24}>
              {trxProfileSummary && (
                <h2 className="heading-02-black">{`${
                  trxProfileSummary.energyConsumeTotal
                    ? Math.round(trxProfileSummary.energyConsumeTotal / 1000).toLocaleString()
                    : '0'
                } kWh`}</h2>
              )}
              <p className="paragraph-04-regular">
                <span>
                  {trxProfileSummary && (
                    <b>
                      {`${
                        trxProfileSummary.energyConsumeInPeakHrs && trxProfileSummary.energyConsumeTotal
                          ? Math.floor(
                              (trxProfileSummary.energyConsumeInPeakHrs / trxProfileSummary.energyConsumeTotal) * 100,
                            )
                          : '0'
                      } %`}
                    </b>
                  )}
                </span>
                <span>{chargingPeakHours}</span>
              </p>
            </Col>
          </Row>
        </Card>
      </CardWrapper>
    </Col>
  )
}

export default EnergyMetrics