import { Select, Spin } from 'antd'
import type { SelectProps } from 'antd/es/select'
import debounce from 'lodash/debounce'
import React, { useMemo, useRef, useState } from 'react'

import { ListingOption, ListingRef } from '../../models/listing'
import { TenantRef } from '../../models/tenant'
import { FindListings } from '../../services/data-provider/listing'

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>
  debounceTimeout?: number
}

interface ChargerBaseProps {
  onOptionChange: (names: ListingOption[]) => void
  defaultTenant?: TenantRef
  tenants?: TenantRef[]
}

const renderListingOption = (listing: ListingRef): ListingOption => {
  return {
    label: listing.title,
    value: listing.id,
    listing,
  }
}

function DebounceSelect<ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any>({
  fetchOptions,
  debounceTimeout = 800,
  ...props
}: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState<ValueType[]>([])
  const fetchRef = useRef(0)

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }
        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      maxTagCount={2}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  )
}

const AntSelector: React.FC<ChargerBaseProps> = ({ onOptionChange, defaultTenant, tenants }) => {
  const [value, setValue] = useState<ListingOption[]>([])

  async function fetchUserList(searchValue: string): Promise<ListingOption[]> {
    return FindListings(searchValue, defaultTenant, tenants).then((listings) => {
      return listings.map((listing: ListingRef) => renderListingOption(listing))
    })
  }

  return (
    <DebounceSelect
      mode="multiple"
      value={value}
      allowClear
      placeholder="Search listings"
      fetchOptions={fetchUserList}
      onChange={(newValue) => {
        setValue(newValue as ListingOption[])
        onOptionChange(newValue as ListingOption[])
      }}
      style={{ minWidth: 159, width: '100%' }}
    />
  )
}

export default AntSelector
