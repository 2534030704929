import { Price } from '../models/price'

export const mockPrice = [
  {
    preferPricePerUnitCents: 300,
    pricePerUnitCents: 200,
    priceType: 'Hour',
    preferredPricingPriceType: 'Hour',
    flatFee: 0,
    timeOfDayEnabled: true,
    timeOfDayPriceWeekday: [...Array(8).fill(100), ...Array(7).fill(150), ...Array(9).fill(80)],
    timeOfDayPriceWeekend: [...Array(24).fill(200)],
  },
  {
    preferPricePerUnitCents: 350,
    pricePerUnitCents: 250,
    priceType: 'kWh',
    preferredPricingPriceType: 'Hour',
    flatFee: 500,
    timeOfDayEnabled: false,
  },
  {
    preferPricePerUnitCents: 400,
    pricePerUnitCents: 500,
    priceType: 'Hour',
    preferredPricingPriceType: 'Hour',
    flatFee: 0,
    timeOfDayEnabled: false,
  },
  {
    preferPricePerUnitCents: 300,
    pricePerUnitCents: 200,
    priceType: 'kWh',
    preferredPricingPriceType: 'Hour',
    flatFee: 200,
    timeOfDayEnabled: true,
    timeOfDayPriceWeekday: [...Array(8).fill(200), ...Array(7).fill(140), ...Array(9).fill(710)],
    timeOfDayPriceWeekend: [...Array(24).fill(300)],
  },
  {
    preferPricePerUnitCents: 0,
    pricePerUnitCents: 0,
    priceType: 'kWh',
    preferredPricingPriceType: 'Hour',
    flatFee: 500,
    timeOfDayEnabled: false,
  },
  {
    preferPricePerUnitCents: 0,
    pricePerUnitCents: 0,
    priceType: 'hour',
    preferredPricingPriceType: 'Hour',
    flatFee: 0,
    timeOfDayEnabled: false,
  },
  {
    preferPricePerUnitCents: 300,
    pricePerUnitCents: 400,
    priceType: 'kWh',
    preferredPricingPriceType: 'kWh',
    flatFee: 0,
    timeOfDayEnabled: false,
  },
] as Price[]
