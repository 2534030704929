import { Badge } from 'antd'

import { connectorServiceStatus, connectorStatus } from '../../models/connector'
import { theme } from '../../theme'
import { useFormatMessage } from '../../helpers/intl'
import { serviceStatus } from '../../models/charger'
import { renderConnectionMessage } from '../../helpers/status'

export const allStatus = [...connectorStatus, ...serviceStatus, ...connectorServiceStatus] as const
export type AllStatus = typeof allStatus[number]
interface props {
  state: AllStatus
}

export const ChargerConnectorStateBadge: React.FC<props> = ({ state }) => {
  const [status, color] = GetConnectorState(state)
  const translatedStatus = useFormatMessage(renderConnectionMessage(state), status)
  return <Badge count={translatedStatus} style={{ backgroundColor: color }} />
}

// Returns the a tupple, where the first argument in the Humanize state and the second is the css class
export const GetConnectorState = (connectorStatus: AllStatus): string[] => {
  switch (connectorStatus) {
    case 'Unavailable':
    case 'Faulted':
    case 'under_repair':
    case 'Offline':
    case 'troubleshooting':
    case 'terminated':
      return [connectorStatus, theme.colors.red]
    case 'Charging':
      return [connectorStatus, theme.colors.blue]
    case 'Available':
    case 'service_mode':
    case 'active':
      return [connectorStatus, theme.colors.green]
    case 'Preparing':
    case 'SuspendedEV':
    case 'SuspendedEVSE':
    case 'Finishing':
    case 'activating':
    case 'property_activating':
    case 'driver_activating':
      return [connectorStatus, theme.colors.orange]
    case 'in_maintenance':
    case 'awaiting_commissioning':
    case 'on_hold':
    case 'other':
    case 'decommissioned':
      return [connectorStatus, theme.colors.gray]
    default:
      return ['Unknown', theme.colors.gray]
  }
}
