export const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 24 },
}
export const tailLayout = {
  wrapperCol: { offset: 5, span: 16 },
}

export const labelAboveField = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
}
