import { Button, message } from 'antd'

import { theme } from '../../../theme'
import { TenantRef } from '../../../models/tenant'
import { Links } from '../../../components/routes/paths'
import { copySVG } from '../../../assets/svg/copy'

interface props {
  tenant: TenantRef
}

const ListingTenant: React.FC<props> = ({ tenant }) => {
  const tenantName = tenant.name || tenant.displayName
  const tenantCountry = tenant && tenant.country ? `[${tenant?.country || '---'}]` : null

  return (
    <div className="flex-and-space-between-gap-below">
      <div className="pointer underline">
        <a
          style={{ color: theme.colors.softBlack }}
          href={Links.tenant({ tenantId: tenant.id })}
          target="_blank"
          rel="noopener noreferrer"
        >
          {tenantName} {tenantCountry}
        </a>
      </div>
      <div>
        <Button type="text" onClick={() => handleCopyTenantName(tenant.name)}>
          {copySVG}
        </Button>
      </div>
    </div>
  )
}

const handleCopyTenantName = (tenantName: string) => {
  const clipBoard = navigator.clipboard
  clipBoard.writeText(tenantName).then(() => {
    message.success(`Copied to clipboard: ${tenantName}`)
  })
  navigator.clipboard.writeText(tenantName)
}

export default ListingTenant
