import { Menu } from 'antd'
import { csvIconSVG } from '../assets/svg/csvIcon'
import { downloadFileSVG } from '../assets/svg/downloadFile'
import { pdfIconSVG } from '../assets/svg/pdfIcon'
import { styled } from '../theme'
import { useFormatMessage } from '../helpers/intl'
import { buildPublicFontUrl } from '../helpers/url'

interface props {
  chart: any
  onClickCSV?(): void
}

const ExportFilesContainer = styled.div`
  .exportData-sub-menu {
    color: #000000;
    background: #30d7ba;
    border: 1px solid #30d7ba;
    border-radius: 20px;
    text-transform: uppercase;
    text-shadow: none;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    padding: 0 !important;
  }

  li.exportData-sub-menu svg {
    margin-left: 10px;
    transition: transform 0.4s ease-in-out;
  }

  li.exportData-sub-menu:hover {
    background: #00b799;
    border: 1px solid #00b799;
    border-radius: 20px;
    text-transform: uppercase;
    text-shadow: none;
  }

  li.exportData-sub-menu.ant-menu-submenu-open.ant-menu-submenu-active svg {
    transform: rotate(180deg);
  }
`

const appendFontFamily = () => {
  var fontStyle = document.createElement('style')

  fontStyle.appendChild(
    document.createTextNode(
      `\
@font-face {\
font-family: 'AkkuratLLWeb-Regular';\
font-style: normal;\
font-weight: 400;\
src: local('AkkuratLLWeb Regular'), local('AkkuratLLWeb-Regular'), url(${buildPublicFontUrl(
        'AkkuratLLWeb-Regular.woff2',
      )}) format('woff2');\
              \
            }\
            `,
    ),
  )

  document.head.appendChild(fontStyle)
}

export const DownloadFilesMenu: React.FC<props> = ({ chart, onClickCSV }) => {
  const exportToPdfText = useFormatMessage('dashboard.homePage.exportGraph.pdf', 'Export as PDF')
  const exportToCSVText = useFormatMessage('dashboard.homePage.exportGraph.csv', 'Export as CSV')
  return (
    <ExportFilesContainer>
      <Menu theme="light" mode="horizontal" className="download-sub-menu">
        <Menu.SubMenu key="exportData-sub-menu" icon={downloadFileSVG} className="exportData-sub-menu">
          <Menu.Item
            key="import-to-pdf"
            icon={pdfIconSVG}
            onClick={() => {
              //@ts-ignore
              appendFontFamily()
              chart.current.chart.exportChart({
                type: 'application/pdf',
              })
            }}
            className="download-sub-menu-item pdf"
          >
            {exportToPdfText}
          </Menu.Item>
          <Menu.Item
            key="import-to-csv"
            icon={csvIconSVG}
            onClick={() => {
              typeof onClickCSV !== 'undefined' && onClickCSV()
              //@ts-ignore
              chart.current.chart.downloadCSV()
            }}
            className="download-sub-menu-item csv"
          >
            {exportToCSVText}
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    </ExportFilesContainer>
  )
}
