import React, { useState } from 'react'

import { Button, Modal, Space, Spin, message } from 'antd'
import { AlertError } from '../../components/error'
import { SwtchError } from '../../models/error'
import { PeakShavingProgram } from '../../models/peak-shaving'
import { DeletePeakShavingProgram } from '../../services/data-provider/peak-shaving'

interface props {
  visible: boolean
  program: PeakShavingProgram
  onCancel(): void
  onSubmit(): void
}

export const DeleteProgramModal: React.FC<props> = ({ visible, onCancel, onSubmit, program }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()

  const handleOk = () => {
    setLoading(true)
    DeletePeakShavingProgram(program.id)
      .then(({ response }) => {
        message.success(response)
        onSubmit()
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <AlertError error={error} />
      <Spin spinning={loading}>
        <Modal
          title={`Delete Program ${program.name}`}
          visible={visible}
          confirmLoading={loading}
          footer={
            <>
              <Space>
                <Button onClick={onCancel}>Cancel</Button>
                <Button type="primary" htmlType="submit" onClick={handleOk} danger>
                  Delete
                </Button>
              </Space>
            </>
          }
          onCancel={onCancel}
          width={1024}
        >
          {`Are you sure you want to delete program ${program.name} ?`}
        </Modal>
      </Spin>
    </>
  )
}
