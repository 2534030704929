import { ListingRef } from './listing'
import { Charger } from './charger'

export const connectorStatus = [
  'Available',
  'Charging',
  'Preparing',
  'Finishing',
  'SuspendedEV',
  'SuspendedEVSE',
  'Faulted',
  'Unavailable',
  'Offline',
] as const

export const connectorServiceStatus = [
  'active', // (end state; no action needed -> no owner)
  'property_activating',
  'driver_activating',
  'awaiting_commissioning', // Previously known as 'Commissioning'
  'commissioned', // Previously known as 'Activating'
  'decommissioned',
  'on_hold',
  'other',
  'testing',
  'terminated', // (end state; no action needed -> no owner)
  'troubleshooting',
  'under_repair',
]

export const connectorType = [
  'J1722',
  'J1772',
  'CCS',
  'CHAdeMO',
  'Tesla',
  'Type 2',
  'Type 1',
  'Type 3',
  'GB/T',
  'Other',
] as const

export type ConnectorType = typeof connectorType[number]
export type ConnectorStatus = typeof connectorStatus[number]
export type ConnectorServiceStatus = typeof connectorServiceStatus[number]

export const ConnectorStatusAlias: { [key: string]: ConnectorStatus[] } = {
  available: ['Available'],
  inuse: ['Charging', 'Preparing', 'Finishing', 'SuspendedEV', 'SuspendedEVSE'],
  unavailable: ['Unavailable', 'Faulted', 'Offline'],
  active: ['Available', 'Charging', 'Preparing', 'Finishing', 'SuspendedEV', 'SuspendedEVSE', 'Unavailable', 'Faulted'],
}

export const ConnectorServiceStatusAlias: { [key: string]: ConnectorServiceStatus[] } = {
  underRepair: ['under_repair'],
  activating: ['property_activating', 'driver_activating', 'commissioned'],
  commissioning: ['on_hold', 'awaiting_commissioning', 'troubleshooting'],
  active: ['active'],
}

export interface ConnectorRef {
  id: number
  connectorId: number
}

export interface Connector extends ConnectorRef {
  state: ConnectorStatus
  charger?: Charger
  listing?: ListingRef
  lastStatusChangedAt: string
}

export interface ConnectorLog {
  message: string
  success: boolean
  createdAt: string
  errorCode: string
  vendorErrorCode: string
}

export interface ConnectorV2 extends ConnectorRef {
  serviceStatus?: ConnectorServiceStatus
  ocppStatus?: ConnectorStatus
  connectorType?: ConnectorType
}
