import { useState } from 'react'

import { NewListing } from '../../../models/listing'
import { Price } from '../../../models/price'

import { useAppState } from '../../../state'
import { formatCentsPrice } from '../../../helpers/number-format'
import { formatListingCategory } from '../../../helpers/listing'

import TimeOfDayModal from '../modal/TimeOfDayModal'

interface props {
  listing: NewListing
  term?: string
}

const formatHour = (priceType: string) => (priceType === 'Hour' ? 'hr' : 'kWh')

const HandlePrice: React.FC<props> = ({ listing, term }) => {
  const { IsDesktop, IsTablet, IsLaptop, IsMobile } = useAppState()
  const [selectedListing, setSelectedListing] = useState<NewListing | undefined>()
  const isMobile = IsMobile()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()

  const showTimeOfDayModal = (listing: NewListing) => setSelectedListing(listing)

  const handleModal = () => setSelectedListing(undefined)

  const isSmallScreen = isMobile || isTablet ? 'flex-right' : ''
  const isLargeScreen = isLaptop || isDesktop ? 'flex-and-gap-below' : ''

  const justPrice = (price: Price, parseListingKind: JSX.Element) => {
    const { priceType, pricePerUnitCents } = price

    return (
      <div className={isLargeScreen}>
        <div className={isSmallScreen}>{`${formatCentsPrice(pricePerUnitCents)}/${formatHour(priceType)}`}</div>
        {parseListingKind}
      </div>
    )
  }

  const handlePrice = (listing: NewListing, term?: string) => {
    if (term === 'preferredPrice') {
      return handlePreferredPrice(listing.price)
    }

    return parseGeneralPrice(listing)
  }

  const freelisting = (parseListingKind: JSX.Element) => (
    <div className={isLargeScreen}>
      <div className={isSmallScreen}>Free</div>
      {parseListingKind}
    </div>
  )

  const flatFeeOnly = (parseListingKind: JSX.Element, flatFee: number | string) => {
    return (
      <div className={isLargeScreen}>
        <div className={`${isSmallScreen}`}>{formatCentsPrice(Number(flatFee))} Fee</div>
        {parseListingKind}
      </div>
    )
  }

  const flatFeeAndPrice = (
    parseListingKind: JSX.Element,
    pricePerUnitCents: number,
    priceType: string,
    flatFee: number,
  ) => {
    return (
      <div className={isLargeScreen}>
        <div className={isSmallScreen}>
          {formatCentsPrice(pricePerUnitCents)}/{formatHour(priceType)} + {formatCentsPrice(flatFee)} Fee
        </div>
        {parseListingKind}
      </div>
    )
  }

  const renderTimeOfDay = (listing: NewListing, flatFee: number, parseListingKind: JSX.Element) => (
    <div className={isLargeScreen}>
      <span onClick={() => showTimeOfDayModal(listing)} className={`${isSmallScreen} pointer underline`}>
        {flatFee > 0 ? `Time of Day + ${formatCentsPrice(Number(flatFee))} Fee` : 'Time of Day'}
      </span>
      {parseListingKind}
    </div>
  )

  const parseGeneralPrice = (listing: NewListing) => {
    const { price, kind } = listing
    const { timeOfDayEnabled, flatFee, priceType, pricePerUnitCents } = price

    const numberFlatFee = Number(flatFee)

    const parseListingKind = <div className={`${isSmallScreen} opacity-06  paragraph-04-light`}>{formatListingCategory(kind)}</div>

    if (timeOfDayEnabled) return renderTimeOfDay(listing, numberFlatFee, parseListingKind)

    if (numberFlatFee === 0 && pricePerUnitCents === 0) return freelisting(parseListingKind)

    if (numberFlatFee === 0 && pricePerUnitCents > 0) return justPrice(price, parseListingKind)

    if (numberFlatFee > 0 && pricePerUnitCents === 0) return flatFeeOnly(parseListingKind, numberFlatFee)

    if (numberFlatFee > 0 && pricePerUnitCents > 0)
      return flatFeeAndPrice(parseListingKind, pricePerUnitCents, priceType, numberFlatFee)
  }

  return (
    <>
      {handlePrice(listing, term)}
      {selectedListing && (
        <TimeOfDayModal
          title={selectedListing.title}
          priceType={formatHour(selectedListing.price.priceType)}
          isModalOpen={!!selectedListing}
          flatFee={Number(selectedListing.price.flatFee)}
          onModalOpenClose={handleModal}
          timeOfDayPriceWeekend={selectedListing.price.timeOfDayPriceWeekend}
          timeOfDayPriceWeekday={selectedListing.price.timeOfDayPriceWeekday}
        />
      )}
    </>
  )
}

export default HandlePrice

const iskWhOrHour = (priceType: string, price: string) => (priceType === 'kWh' ? `${price}/kWh` : `${price}/hr`)

const handlePreferredPrice = (price: Price) => {
  if (price.preferPricePerUnitCents) {
    const formattedPrice = formatCentsPrice(price?.preferPricePerUnitCents)
    iskWhOrHour(price.priceType, formattedPrice)
  }
  return '---'
}
