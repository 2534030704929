import { styled } from '../theme'

export const LineBreak = styled.div`
  border-right: 1px solid ${(props) => props.theme.colors.fadedGrayBlue};
  height: 24px;
  z-index: 1;
  position: absolute;
  margin-top: -12px;
  :hover {
    /* transform: scale(1.2); */
    /* margin-right: 20px; */
    display: none;
  }
`
