import { theme } from '../../theme'

export const pauseSVG = (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.20005 12.6676V7.26758M10.8 12.6676V7.26758M9.00005 17.1676C5.0236 17.1676 1.80005 13.944 1.80005 9.96758C1.80005 5.99113 5.0236 2.76758 9.00005 2.76758C12.9765 2.76758 16.2 5.99113 16.2 9.96758C16.2 13.944 12.9765 17.1676 9.00005 17.1676Z"
      stroke={theme.colors.darkGray}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
