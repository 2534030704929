import queryString from 'query-string'
import { RouteComponentProps } from 'react-router'

import { withAuthenticatedLayout } from '../../components/layouts/layout'

import { TenantRef } from '../../models/tenant'
import { ConnectorServiceStatus, ConnectorServiceStatusAlias, ConnectorStatus, ConnectorStatusAlias } from '../../models/connector'

import { ChargersBasePage } from './chargers-base-page'

const ChargersPageWrapper: React.FC<RouteComponentProps> = (props) => {
  const { tenant_id, tenant_name, tenant_displayName, ocppStatus, serviceStatus } = queryString.parse(
    props.location.search,
  )
  let tenantRef: TenantRef | undefined = undefined
  let connOCPPStatus: ConnectorStatus[] | undefined = undefined
  let chargerServiceStatus: ConnectorServiceStatus[] | undefined = undefined

  if (tenant_displayName) {
    if (typeof tenant_id === 'string' && typeof tenant_name === 'string' && typeof tenant_displayName === 'string') {
      tenantRef = { id: tenant_id, name: tenant_name, displayName: tenant_displayName }
    }
  } else {
    if (typeof tenant_id === 'string' && typeof tenant_name === 'string') {
      tenantRef = { id: tenant_id, name: tenant_name }
    }
  }
  if (typeof ocppStatus === 'string') {
    connOCPPStatus = ConnectorStatusAlias[ocppStatus] || undefined
  }
  if (typeof serviceStatus === 'string') {
    chargerServiceStatus = ConnectorServiceStatusAlias[serviceStatus] || undefined
  }
  return (
    <ChargersBasePage
      defaultTenantRef={tenantRef}
      defaultOCPPStatus={connOCPPStatus}
      defaultServiceStatus={chargerServiceStatus}
    />
  )
}

export const ChargersPage = withAuthenticatedLayout(ChargersPageWrapper)
