import { Button, Form, Modal, Select, message } from 'antd'
import { AugmentedUser, UserPermissions } from '../../models/user'
import { useEffect, useState } from 'react'
import { TenantRef } from '../../models/tenant'
import { FindTenants } from '../../services/data-provider/tenants'
import { SwtchError } from '../../models/error'
import { useUserTranslation } from '../../hooks/translation/useUserTranslation'
import UserBasicInfo from './modal/UserBasicInfo'
import { NewUpdateUser } from '../../services/data-provider/users'
import { AlertError } from '../../components/error'
import EditForm from './modal/user-edit-form'
import { ButtonTextSpacing, FooterLayout, Heading } from '../../atom/user-edit'
import { useAppState } from '../../state'

interface props {
  selectedUser: AugmentedUser
  user: AugmentedUser
  onCancel: (e: React.FormEvent<EventTarget>) => void
  onUserEdit: () => void
}

export const UserEditForm: React.FC<props> = ({ onCancel, selectedUser, onUserEdit }) => {
  const [form] = Form.useForm()
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [tenantInputs, setTenantInputs] = useState(0)
  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(false)
  const [tenantDropdownData, setTenantDropdownData] = useState<{ [index: number]: any }>({})
  const [userPermissions, setUserPermissions] = useState<UserPermissions[]>([])
  const [originalUserPermissions, setOriginalUserPermissions] = useState<UserPermissions[]>([])
  const [name, setName] = useState(selectedUser.name)
  const [note, setNote] = useState(selectedUser.note)
  const [selectedUserRole, setSelectedUserRole] = useState('')
  const [disableSave, setDisableSave] = useState(true)
  const [changed, setChanged] = useState(false)
  const { editUserText, saveText, cancelText, updateText } = useUserTranslation()
  const { currentUser } = useAppState()

  useEffect(() => {
    if (selectedUser.isAdmin) {
      setSelectedUserRole('admin')
    }
    if (selectedUser.isSupport) {
      setSelectedUserRole('support')
    }
    if (selectedUser.isUser) {
      setSelectedUserRole('user')
    }
    setLoading(true)
    FindTenants()
      .then((tenants) => {
        const tenantsArr: TenantRef[] = []
        tenants.map((tenant) => {
          if (tenant.name) return tenantsArr.push(tenant)
          return null
        })
        setTenants([...tenantsArr])
        handleUserAccess(tenantsArr)
        setLoading(false)
      })
      .catch((err: SwtchError) => {
        setTenants([])
        message.error(err.description)
      })
  }, [])

  useEffect(() => {
    let role = ''
    if (selectedUser.isAdmin) {
      role = 'admin'
    }
    if (selectedUser.isSupport) {
      role = 'support'
    }
    if (selectedUser.isUser) {
      role = 'user'
    }
    if (name !== selectedUser.name || note !== selectedUser.note || role !== selectedUserRole || changed) {
      setDisableSave(false)
    } else {
      setDisableSave(true)
    }
  }, [name, note, selectedUserRole, changed])

  const handleUserAccess = (tenantData: TenantRef[]) => {
    let obj = {}
    let objArr: any = []
    let match = 0
    let input = 0
    let tempObj: any = {}
    selectedUser.accesses.map((access) => {
      return tenantData.map((tenant) => {
        if (tenant.id === access.resourceId) {
          match = match + 1
          input = input + 1
          tempObj[input] = [...tenantData]
          let driver = false
          let roles = 'none'
          access.permissions.map((permission) => {
            if (permission === 'tenant.manager') roles = 'manager'
            if (permission === 'tenant.viewer') roles = 'viewer'
            if (permission === 'tenant.none') roles = 'none'
            if (permission === 'tenant.driver') driver = true
            return null
          })
          obj = {
            name: access.display,
            id: access.resourceId,
            access: roles,
            driver: driver,
          }
          objArr.push(obj)
          setTenantInputs(match)
          setTenantDropdownData({ ...tempObj })
        }
        return null
      })
    })
    setUserPermissions(objArr)
    setOriginalUserPermissions(objArr)
  }

  const handleUserRoleChange = (role: string) => {
    setSelectedUserRole(role)
  }

  const afterClose = () => {
    form.resetFields()
  }
  const onOk = () => {
    let updateName = ''
    let updateNote = ''
    if (selectedUser.name !== name) updateName = name
    if (selectedUser.note !== note) updateNote = note
    const userToUpdate = [
      { id: selectedUser.id, email: selectedUser.email, name: updateName, note: updateNote, role: selectedUserRole },
    ]
    setLoading(true)
    NewUpdateUser(userPermissions, originalUserPermissions, userToUpdate)
      .then(() => {
        message.success(`Updated ${userToUpdate[0].email} info`)
        return onUserEdit()
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <AlertError error={error} />
      <Modal
        title={<Heading>{editUserText}</Heading>}
        closable={true}
        okText={updateText}
        cancelText={cancelText}
        visible={true}
        onCancel={onCancel}
        onOk={onOk}
        afterClose={afterClose}
        width={540}
        footer={
          <FooterLayout>
            <Button style={{ width: '240px' }} loading={loading} onClick={onCancel}>
              <span className="paragraph-02-regular">{cancelText}</span>
            </Button>
            <Button style={{ width: '240px' }} type="primary" loading={loading} onClick={onOk} disabled={disableSave}>
              <ButtonTextSpacing disabled={disableSave}>
                <span className="paragraph-02-regular">{saveText}</span>
              </ButtonTextSpacing>
            </Button>
          </FooterLayout>
        }
      >
        {currentUser?.role === 'admin' && (
          <>
            <Select
              style={{ width: '100%' }}
              value={selectedUserRole}
              onChange={(value) => handleUserRoleChange(value)}
            >
              <Select.Option value="admin">Admin</Select.Option>
              <Select.Option value="support">Support</Select.Option>
              <Select.Option value="user">User</Select.Option>
            </Select>
            <hr
              style={{
                margin: '24px -24px 24px',
                border: 0,
                borderTop: '1px solid #f0f0f0',
              }}
            />
          </>
        )}
        <UserBasicInfo editUser={selectedUser} note={note} name={name} setName={setName} setNote={setNote} />
        <EditForm
          tenantInputs={tenantInputs}
          tenants={tenants}
          tenantDropdownData={tenantDropdownData}
          userPermissions={userPermissions}
          loading={loading}
          setTenantInputs={setTenantInputs}
          setTenantDropdownData={setTenantDropdownData}
          setUserPermissions={setUserPermissions}
          setChanged={setChanged}
        />
      </Modal>
    </>
  )
}
