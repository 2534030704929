import { theme } from '../../theme'

export const tenantSVG = (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="tenantSelectorIcon"
  >
    <path
      d="M16.3636 15.9H18V17.5H0V15.9H1.63636V1.3C1.63636 1.08783 1.72256 0.884344 1.876 0.734315C2.02944 0.584285 2.23755 0.5 2.45455 0.5H15.5455C15.7624 0.5 15.9706 0.584285 16.124 0.734315C16.2774 0.884344 16.3636 1.08783 16.3636 1.3V15.9ZM5.72727 8.7V10.3H8.18182V8.7H5.72727ZM5.72727 5.5V7.1H8.18182V5.5H5.72727ZM5.72727 11.9V13.5H8.18182V11.9H5.72727ZM9.81818 11.9V13.5H12.2727V11.9H9.81818ZM9.81818 8.7V10.3H12.2727V8.7H9.81818ZM9.81818 5.5V7.1H12.2727V5.5H9.81818Z"
      fill={theme.colors.neonGreen}
    />
  </svg>
)

export const tenantWhiteSVG = (
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.4997 15.6667H19.1663V17.3334H0.833008V15.6667H2.49967V1.50002C2.49967 1.27901 2.58747 1.06704 2.74375 0.910765C2.90003 0.754484 3.11199 0.666687 3.33301 0.666687H16.6663C16.8874 0.666687 17.0993 0.754484 17.2556 0.910765C17.4119 1.06704 17.4997 1.27901 17.4997 1.50002V15.6667ZM6.66634 8.16669V9.83335H9.16634V8.16669H6.66634ZM6.66634 4.83335V6.50002H9.16634V4.83335H6.66634ZM6.66634 11.5V13.1667H9.16634V11.5H6.66634ZM10.833 11.5V13.1667H13.333V11.5H10.833ZM10.833 8.16669V9.83335H13.333V8.16669H10.833ZM10.833 4.83335V6.50002H13.333V4.83335H10.833Z"
      fill={theme.colors.white}
    />
  </svg>
)
