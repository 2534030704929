import { AutoComplete, Divider, Form, FormInstance, message, Radio, SelectProps, Space, Spin } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { debounce } from 'lodash'
import { useState } from 'react'

import { useListingBasicTrans } from '../../hooks/translation/useListingBasicTrans'
import { SwtchError } from '../../models/error'
import { ListingRef } from '../../models/listing'
import { FindListings } from '../../services/data-provider/listing'
import { AlertError } from '../error'
import { MustBeRequired, MustMaxOut } from '../rules/rules'

interface props {
  form: FormInstance<any>
}

interface ListingOption {
  value: string
  label: JSX.Element
  listing: ListingRef
}

const renderListing = (listing: ListingRef): ListingOption => {
  return {
    value: listing.title,
    listing,
    label: <div>{listing.title}</div>,
  }
}

export const ListingBasic: React.FC<props> = ({ form }) => {
  const [loading, setLoading] = useState(false)
  const [wallet, setWallet] = useState(form.getFieldValue('paymentType'))
  const [kind, setKind] = useState(form.getFieldValue('kind'))
  const [error, setError] = useState<SwtchError>()
  const [options, setOptions] = useState<SelectProps<object>['options']>([])

  const {
    listingText,
    characterMaxLimitText,
    listingAlreadyText,
    listingDescriptionText,
    listingInfoText,
    listingTitleRequiredText,
    listingTitleText,
    listingCategoryText,
    listingNonResText,
    listingResText,
    listingDestText,
    listingNonNetworkText,
    listingWalletFuncText,
    listingWalletText,
    listingNoWalletText,
  } = useListingBasicTrans()

  const checkListingTitleAlreadyPresent = (term: string) => {
    setLoading(true)
    setError(undefined)
    FindListings(term)
      .then((listings) => {
        const opts = listings.map((listing) => renderListing(listing))
        setOptions(opts)
      })
      .catch((err: SwtchError) => {
        setOptions([])
        setError(err)
        message.error(err.description)
      })
      .finally(() => setLoading(false))
  }

  const handleSearch = (value: string) => {
    value && value.length > 0 && checkListingTitleAlreadyPresent(value)
  }

  const debouncedTitleSearch = debounce(handleSearch, 1000)
  return (
    <>
      <AlertError error={error} />
      <Divider>{listingText}</Divider>
      <Form.Item
        label={listingTitleText}
        name="title"
        rules={[
          MustBeRequired(listingTitleRequiredText),
          () => ({
            validator(_, value: string) {
              if (options?.map((opt) => String(opt.value).toLowerCase()).includes(String(value).toLowerCase())) {
                return Promise.reject(new Error(listingAlreadyText))
              }
              return Promise.resolve()
            },
          }),
        ]}
      >
        <AutoComplete
          options={options}
          onSearch={debouncedTitleSearch}
          placeholder={listingTitleText}
          filterOption
          allowClear
          notFoundContent={loading ? <Spin size="small" /> : null}
        />
      </Form.Item>
      <Form.Item label={listingInfoText} name="description" rules={[MustMaxOut(characterMaxLimitText)]}>
        <TextArea maxLength={255} showCount placeholder={listingDescriptionText} />
      </Form.Item>
      <Form.Item label={listingCategoryText} name="kind">
        <Radio.Group value={kind} onChange={(e) => setKind(e.target.value)} buttonStyle="solid">
          {form.getFieldValue('priceType') === 'kWh' ? (
            <Radio.Button value="no_reservation">{listingNonResText}</Radio.Button>
          ) : (
            <Space wrap>
              <Radio.Button value="reservation">{listingResText}</Radio.Button>
              <Radio.Button value="no_reservation">{listingNonResText}</Radio.Button>
              <Radio.Button value="destination">{listingDestText}</Radio.Button>
              <Radio.Button value="non_network">{listingNonNetworkText}</Radio.Button>
            </Space>
          )}
        </Radio.Group>
      </Form.Item>
      <Form.Item label={listingWalletFuncText} name="paymentType" required={true}>
        <Radio.Group value={wallet} onChange={(e) => setWallet(e.target.value)} buttonStyle="solid">
          <Space>
            <Radio.Button value="wallet">{listingWalletText}</Radio.Button>
            <Radio.Button value="no_wallet">{listingNoWalletText}</Radio.Button>
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  )
}
