import { useState, useRef, useEffect } from 'react'
import { Divider, Input, Select, Space, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import type { InputRef } from 'antd'

import { renderFormatMessage } from '../../helpers/intl'
import { PropertyMgmtComp } from '../../models/property-management'
import { CreatePropertyMgmtComp, GetPropertyMgmtComps } from '../../services/data-provider/property-management-company'

interface props {
  defaultPropMgmtComp?: PropertyMgmtComp
  onOptionChange: (selectedProperty?: PropertyMgmtComp) => void
  disabled: boolean
}

export const PropertyMgmtCompSelector: React.FC<props> = ({ defaultPropMgmtComp, onOptionChange, disabled }) => {
  const [properties, setProperties] = useState<PropertyMgmtComp[]>([])
  const [name, setName] = useState('')
  const inputRef = useRef<InputRef>(null)

  const defaultP = defaultPropMgmtComp
    ? { label: defaultPropMgmtComp?.name, value: defaultPropMgmtComp?.name, id: defaultPropMgmtComp?.id }
    : null

  useEffect(() => {
    GetPropertyMgmtComps().then((resp) => setProperties(resp))
  }, [])

  const handleSelectChange = (selectedProperty: any) => {
    const findProperty = properties.find((p) => p.name === selectedProperty)
    onOptionChange(findProperty)
  }

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    CreatePropertyMgmtComp(name).then((newProperty) => {
      setProperties([...properties, { id: newProperty.id, name: newProperty.name }])
    })
    setName('')
    setTimeout(() => {
      inputRef.current?.focus()
    }, 500)
  }

  const checkDecuplicate = () => {
    const filterName = properties.filter((property) => property.name.toLowerCase() === name.toLowerCase())

    if (name.length === 0) {
      return true
    } else if (filterName.length !== 0) {
      return true
    } else {
      return false
    }
  }

  const options = properties?.map((property) => ({
    label: property.name,
    value: property.name,
    id: property.id,
  }))

  return (
    <Select
      showSearch
      allowClear
      disabled={disabled}
      defaultValue={defaultP}
      placeholder="Select a property"
      onChange={handleSelectChange}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space wrap style={{ padding: '0 8px 4px' }}>
            <Input
              allowClear
              placeholder="Select a property"
              ref={inputRef}
              value={name}
              onChange={onNameChange}
              style={{ width: '100%' }}
            />
            <Button type="primary" disabled={checkDecuplicate()} icon={<PlusOutlined />} onClick={addItem}>
              {renderFormatMessage('dashboard.text.add', 'Add')}
            </Button>
          </Space>
        </>
      )}
      options={options}
    />
  )
}
