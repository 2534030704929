import { Select, Tag } from 'antd'
import { SelectProps } from 'antd/es/select'
import React, { useEffect, useState } from 'react'
import { SwtchError } from '../../models/error'
import { PeakShavingProgram, PeakShavingState } from '../../models/peak-shaving'
import { GetAllPeakShavingPrograms } from '../../services/data-provider/peak-shaving'
import { AlertError } from '../error'
import { PeakShavingEventFilter } from '../../models/filter'
import { debounce } from 'lodash'
import { useAppState } from '../../state'
import { peakPowerEnergyFolks } from '../../pages/listing/peak-shaving-events-page'

interface props {
  onProgramSelected: (program: PeakShavingProgram) => void
  onClear: () => void
  defaultOption?: string
  disabled?: boolean
  state?: PeakShavingState
}

const renderOption = (program: PeakShavingProgram): any => {
  return {
    value: program.id,
    program,
    label: program.name,
  }
}

export const PeakShavingProgramSelector: React.FC<props> = ({
  onProgramSelected,
  onClear,
  defaultOption,
  disabled,
  state,
}) => {
  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState<PeakShavingProgram | undefined>()
  const [options, setOptions] = useState<SelectProps<object>['options']>([])
  const [filter, setFilter] = useState<PeakShavingEventFilter>({ page: 1, perPage: 10, state: state })
  const { currentUser } = useAppState()

  useEffect(() => {
    setLoading(true)
    if (currentUser && peakPowerEnergyFolks.includes(currentUser?.email)) {
      filter.programName = 'Ontario’s Global Adjustment Program'
    }
    GetAllPeakShavingPrograms(filter)
      .then(({ data }) => {
        const opts = data.map((tenant) => {
          return renderOption(tenant)
        })
        setOptions(opts)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [filter])

  const onSelect = (value: string) => {
    options?.forEach((opt) => {
      if (opt.value === value) {
        setSelected(opt.program)
        onProgramSelected(opt.program)
      }
    })
  }

  return (
    <>
      <AlertError error={error} />
      {!selected && (
        <Select
          showSearch
          allowClear
          style={{ minWidth: 159, width: '100%' }}
          placeholder="Select Program"
          onClear={onClear}
          onSearch={debounce((value) => {
            setFilter({ ...filter, programName: value })
          }, 1000)}
          options={options}
          filterOption={(input, option) =>
            (option ? String(option.label) : '').toLowerCase().includes(input.toLowerCase())
          }
          dropdownMatchSelectWidth={false}
          onSelect={onSelect}
          loading={loading}
          defaultValue={defaultOption}
          disabled={disabled}
        />
      )}

      {selected && (
        <Tag
          closable={true}
          onClose={(e) => {
            setSelected(undefined)
            onClear()
            setFilter({ ...filter, programName: '', page: 1, perPage: 10 })
          }}
        >
          {selected.name}
        </Tag>
      )}
    </>
  )
}
