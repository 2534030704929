import { styled, theme } from '../theme'

interface props {
  usersPage?: boolean
}

export const LineBreak = styled.span`
  border-right: 1px solid ${theme.colors.fadedGrayBlue};
  height: 24px;
  z-index: 2;
  position: absolute;
  left: 320px;
  top: 28px;
`

export const MultiTenantStyling = styled.div`
  div > .ant-select > .ant-select-selector {
    width: 321px;
    border-radius: 48px 0px 0px 48px !important;
    border-right-width: 0px;
    z-index: 1;
  }
`

export const UserSelectorStyling = styled.div`
  width: 210px;

  .ant-input-group-wrapper > .ant-input-wrapper > .ant-input-affix-wrapper {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    width: 310px;
    line-height: 26px;
    border-radius: 0px 48px 48px 0px !important;
    border-left-width: 1px;
    font-size: 13px;
    font-weight: 400;
    border-left-color: transparent;
    margin-left: -1px;
    height: 39px;
  }

  .ant-input-group-wrapper > .ant-input-wrapper > .ant-input-affix-wrapper:hover {
    border-left-width: 1px !important;
    border-color: ${theme.colors.neonGreen};
    box-shadow: none !important;
  }

  .ant-input-group-wrapper > .ant-input-wrapper > .ant-input-affix-wrapper-focused {
    border-left-width: 1px !important;
    border-color: ${theme.colors.neonGreen} !important;
    box-shadow: none !important;
  }

  .ant-input-affix-wrapper > input.ant-input {
    border-radius: 0 !important;
    padding-left: 5px;
    padding-top: 1px;
    height: 25px;
    font-size: 13px;
  }
`

export const AccessTagContainer = styled.div`
  span {
    white-space: break-spaces;
    margin-bottom: 5px;
  }
`

export const InviteButtonWrapper = styled.div`
  button > svg {
    margin-bottom: 1px;
  }
`

export const SearchButtonWrapper = styled.div`
  .ant-btn-primary:hover {
    background-color: ${theme.colors.neonGreenHover};
    border-color: ${theme.colors.neonGreenHover};
  }
  margin-top: 2.5px;

  button > svg {
    margin-top: 4px;
  }
`

export const DriverSvgSpacing = styled.span`
  svg {
    margin-bottom: -5px;
    margin-left: 5px;
    height: 18px;
    width: 18px;
  }
`

export const TableStyling = styled.div<props>`
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background: none;
  }

  .ant-table.ant-table-small .ant-table-title {
    padding: 0;
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    text-align: left;
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    text-align: left;
    font-weight: 700;
    font-size: 13px;
    padding: 12px 10px !important;
    line-height: 16px;
    color: ${theme.colors.softBlack} !important;
  }

  .ant-pagination-item {
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px !important;
    width: 36px;
    border: none;
  }

  .ant-pagination-item a {
    color: ${theme.colors.black};
  }

  .ant-pagination-prev::after {
    top: ${(props) => (props.usersPage ? '3px' : '5px')};
  }

  .ant-spin-container {
    display: flex;
    flex-direction: column;
    min-height: 83vh;
    justify-content: space-between;
    background-color: white;
  }

  .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-pagination
    > ant-pagination-next
    > .ant-pagination-item-link
    > .anticon,
  .anticon-right {
    display: inline !important;
  }

  .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
    background-color: ${theme.colors.ternary25};
    border: 1px ${theme.colors.cultered} solid;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    display: none;
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev,
  .ant-pagination-prev {
    margin-right: 8px !important;
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev,
  .ant-pagination-next,
  .ant-pagination-prev {
    margin-left: 8px !important;
  }

  .ant-table-pagination.ant-pagination {
    margin-bottom: 40px;
    align-items: center;
  }
`

export const CustomBox = styled.div`
  background-color: ${theme.colors.ternary25};
  margin: 0;
  min-height: 84vh;

  .tabs-styling > .ant-tabs-nav {
    margin-bottom: 20px;
    margin-top: 12px;
  }
`

export const EditButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const TabsButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  &.subscriptions-header {
    display: flex;
    gap: 15px;
  }

  .subscription-email-search {
    width: 244px !important;
    padding-top: 0px !important;
  }

  .subscription-email-search > .ant-input-group > .ant-input-affix-wrapper:first-child {
    height: 39px;
    border-radius: 20px;
  }

  .subscription-email-search > .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
    padding-left: 5px;
    height: 25px;
    font-size: 13px;
  }

  .subscription-email-search > .ant-input-group > .ant-input-affix-wrapper:hover {
    border-color: ${theme.colors.neonGreen};
    box-shadow: none;
  }

  .subscription-email-search > .ant-input-group > .ant-input-affix-wrapper-focused {
    border-color: ${theme.colors.neonGreen};
    box-shadow: none;
  }
`

export const TabsWrapper = styled.div`
  .tabs-styling > .ant-tabs-nav > .ant-tabs-extra-content {
    width: 67%;
  }

  .tabs-styling > .ant-tabs-nav > .ant-tabs-extra-content > .ant-row > .user-selector > span {
    top: 8px;
  }

  .tabs-styling > .ant-tabs-nav > .ant-tabs-extra-content > .ant-row > .user-selector > div > svg {
    top: 19px;
  }

  .tabs-styling > .ant-tabs-nav > .ant-tabs-extra-content > .ant-row > .ant-space {
    position: absolute;
    right: 0;
    top: 0;
  }

  .tabs-styling > .ant-tabs-nav > .ant-tabs-extra-content > .ant-row > .user-selector {
    display: flex;
    position: absolute;
    left: 358px;
    top: 0;
  }
`
