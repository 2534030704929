import { Select } from 'antd'
import { ReactElement, ReactNode } from 'react'

import { renderFormatMessage } from '../../helpers/intl'
import { renderConnectionMessage } from '../../helpers/status'

const SELECTOR_OPTIONS: { [key: string]: JSX.Element } = {
  'Select OCPP Status': renderFormatMessage('dashboard.selector.selectConnectorStatus', 'Connector Status'),
  'Select Service Status': renderFormatMessage('dashboard.selector.serviceStatus', 'Service Status'),
  'Select Vendor': renderFormatMessage('dashboard.chargersPage.selectVendor', 'Select Vendor'),
}
interface props<T> {
  options: T[]
  defaultValues?: T[]
  onOptionsChange: (options: T[]) => void
  onClear: () => void
  onSearch: (options: T[]) => void
  placeholder: string
}

const renderOption = (option: string, placeholder?: string): ReactElement => {
  const translateOpt = renderFormatMessage(renderConnectionMessage(option), option)
  // Vendors have fixed names so it doesn't need translation.
  const modifiedOption = placeholder !== 'Select Vendor' ? translateOpt : option

  return (
    <Select.Option key={option} value={option}>
      {modifiedOption}
    </Select.Option>
  )
}
export const MultiOptionSelector = <T extends string>(props: props<T> & { children?: ReactNode }) => {
  const { defaultValues, options, onOptionsChange, onClear, onSearch, placeholder } = props

  const handleChange = (values: string[]) => {
    onOptionsChange((values as unknown) as T[])
  }

  const handleOnSearch = (input: string) => {
    options.forEach((opt) => {
      if (opt.toLowerCase() === input.toLowerCase()) {
        onSearch(options)
      }
    })
  }

  return (
    <Select
      mode="multiple"
      allowClear
      showArrow
      style={{ minWidth: 159, width: '100%' }}
      placeholder={SELECTOR_OPTIONS[placeholder]}
      defaultValue={defaultValues}
      dropdownMatchSelectWidth={false}
      onChange={handleChange}
      onClear={onClear}
      onSearch={(e) => handleOnSearch(e)}
      maxTagCount={1}
    >
      {options.map((v) => renderOption(v, placeholder))}
    </Select>
  )
}
