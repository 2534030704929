import React from 'react'
import { Card } from 'antd'

interface props {
  loading?: boolean
  title: React.ReactElement | string
  description?: string
  header: React.ReactElement
  color?: string
  extra?: React.ReactElement
  hoverable?: boolean
}

export const Widget: React.FC<props> = ({ title, description, header, loading, color, extra, hoverable }) => {
  return (
    <Card
      title={title}
      bordered={true}
      loading={loading}
      extra={extra}
      hoverable={hoverable}
      style={{ height: '100%' }}
    >
      <h2 className={color}>{header}</h2>
      {description && <small>{description}</small>}
    </Card>
  )
}
