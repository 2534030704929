import moment from 'moment'
import { useState } from 'react'
import { Col, PageHeader, Row, Select, Skeleton, Space } from 'antd'
import { useParams } from 'react-router-dom'

import { Box } from '../../atom/box'
import { MobileCalendar } from '../../atom/mobile-calendar'
import { useFormatMessage } from '../../helpers/intl'

import { AlertError } from '../../components/error'
import { DateRangePicker } from '../../components/datepicker/datepicker'

import { withAuthenticatedLayout } from '../../components/layouts/layout'

import { Connector } from '../../models/connector'
import { useAppState } from '../../state'
import { styled } from '../../theme'
import { ChargerTabs } from './views/charger/charger-tabs'
import { ChargerGraphs } from './views/charger/charger-graphs'
import { ChargerStats } from './views/charger/charger-stats'
import { useCharger } from '../../hooks/useCharger'
import { useChargerTransMetrics } from '../../hooks/useChargerTransMetric'
import { useCalendarTransactions } from '../../hooks/translation/useCalendarTranslation'

const SelectorWrapper = styled.div`
  ${(props) => props.theme.breakpoints.down('md')} {
    .ant-page-header-heading {
      .ant-page-header-heading-extra {
        min-width: 100%;
        width: 100%;
        .ant-space.ant-space-horizontal.ant-space-align-center {
          min-width: 100%;
          width: 100%;
          .ant-space-item {
            min-width: 100%;
            width: 100%;
          }
        }
      }
    }
  }
`

const startDateMoment = moment().startOf('month')
const endDateMoment = moment().endOf('month')

const ChargerBasePage: React.FC = () => {
  document.querySelector('body')?.classList.remove('redesignActive')
  const { IsMobile, IsTablet, IsDesktop, language } = useAppState()

  // @ts-ignore
  const { chargerId, connectorId } = useParams()

  const [startDate, setstartDate] = useState(startDateMoment)
  const [endDate, setEndDate] = useState(endDateMoment)

  const isMobile = IsMobile()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()

  const [mobileCalendarVisible, setMobileCalendarVisible] = useState(false)

  const { shorterCalendarRanges } = useCalendarTransactions()

  const chargerPageHeading = useFormatMessage('dashboard.chargerPage.heading', 'Charger')
  const allConnectorsText = useFormatMessage('dashboard.chargerPage.allConnectors', 'All Connectors')
  const noListingText = useFormatMessage('dashboard.chargerPage.noListing', 'No Listing')

  const { chargerLoading, chargerError, charger, connector, title, handleSelectConnector } = useCharger(
    chargerId,
    connectorId,
  )

  const { transMetricLoading, metrics, transMetricError } = useChargerTransMetrics(
    chargerId,
    startDate,
    endDate,
    connector,
  )

  const onChangeDate = (dates: moment.Moment[]) => {
    if (dates[0] !== startDate) {
      setstartDate(dates[0])
    }
    if (dates[1] !== endDate) {
      setEndDate(dates[1])
    }
  }

  const onConfirm = (startDateTime?: moment.Moment, endDateTime?: moment.Moment) => {
    setMobileCalendarVisible(false)

    if (startDateTime && startDateTime !== startDate) {
      setstartDate(startDateTime)
    }

    if (endDateTime && endDateTime !== endDate) {
      setEndDate(endDateTime)
    }
  }

  const hasMultipleConnectors = charger && charger.connectors.length > 1

  const connectorDisplay = (conn: Connector): string => {
    if (conn.listing) {
      return `${conn.listing.title} - ${conn.connectorId}`
    } else {
      return `${noListingText} - ${conn.connectorId}`
    }
  }

  const connectorsSelector = (
    <Select
      loading={chargerLoading}
      disabled={chargerLoading}
      defaultValue={connector ? connectorDisplay(connector) : allConnectorsText}
      onChange={handleSelectConnector}
      style={{ minWidth: 156, width: '100%' }}
    >
      <>
        <Select.Option key="allConnectors" value="">
          {allConnectorsText}
        </Select.Option>
        {charger?.connectors.map((conn) => (
          <Select.Option key={conn.id} value={conn.id}>
            {connectorDisplay(conn)}
          </Select.Option>
        ))}
      </>
    </Select>
  )

  const dateRangePicker = (
    <DateRangePicker
      onChangeDate={onChangeDate}
      startDate={startDate}
      endDate={endDate}
      calendarRanges={shorterCalendarRanges}
    />
  )

  const tabletDateRangePicker = isTablet && dateRangePicker

  const mobileDateRangePicker = isMobile && (
    <MobileCalendar
      visible={mobileCalendarVisible}
      setMobileCalendarVisible={setMobileCalendarVisible}
      onCancel={() => setMobileCalendarVisible(false)}
      handleConfirm={onConfirm}
      startDate={startDate}
      endDate={endDate}
      calendarRanges={shorterCalendarRanges}
      language={language}
    />
  )

  const desktopPageHeaderExtra = (
    <Space wrap>
      {hasMultipleConnectors && connectorsSelector}
      {dateRangePicker}
    </Space>
  )

  const mobilePageHeaderExtra = (
    <Row justify="space-between" align="middle">
      <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
        {hasMultipleConnectors && connectorsSelector}
      </Col>
      <Col span={24}>
        {tabletDateRangePicker}
        {mobileDateRangePicker}
      </Col>
    </Row>
  )

  const pageHeaderTitle = `${chargerPageHeading} ${charger?.chargePointSerialNumber} ${title ? `(${title})` : ''}`

  if (!charger) {
    return (
      <Box>
        <Skeleton active />
      </Box>
    )
  }

  return (
    <>
      <SelectorWrapper>
        <PageHeader title={pageHeaderTitle} extra={isDesktop ? desktopPageHeaderExtra : mobilePageHeaderExtra} />
      </SelectorWrapper>

      <AlertError error={chargerError || transMetricError} />

      <ChargerStats loading={transMetricLoading} charger={charger} connector={connector} metrics={metrics} />

      <ChargerGraphs loading={transMetricLoading} metrics={metrics} />

      <ChargerTabs loading={chargerLoading} charger={charger} connector={connector} />
    </>
  )
}

export const ChargerPage = withAuthenticatedLayout(ChargerBasePage)
