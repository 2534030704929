import { Divider, Form, FormInstance, Input, InputNumber } from 'antd'

import { useListingChargerTranslation } from '../../hooks/translation/useListingChargerTrans'

interface props {
  form: FormInstance<any>
}

export const ListingChargerV2: React.FC<props> = ({ form }) => {
  const { customNotifText, customNotifPlaceholderText, chargerText, chargerOutputText } = useListingChargerTranslation()

  return (
    <>
      <Divider>{chargerText}</Divider>

      <Form.Item label={chargerOutputText} name="output" rules={[{ required: true }]}>
        <InputNumber style={{ width: '100%' }} precision={1} step="0.1" min={0} max={50} />
      </Form.Item>
      <Form.Item label={customNotifText} name="customNotification" rules={[{ required: false }]}>
        <Input.TextArea maxLength={100} placeholder={customNotifPlaceholderText} />
      </Form.Item>
      <Form.Item name="tenantId" hidden>
        <span></span>
      </Form.Item>
    </>
  )
}
