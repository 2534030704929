import { styled } from '../../../theme'

export const HomePageContainer = styled.div`
  .ant-page-header {
    background-color: ${(props) => props.theme.colors.grayBackground};
    padding: 16px 0 4px 0;
  }

  .card {
    background: #ffffff;
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    overflow: hidden;
    height: 100%;
    max-height: 128px;
  }

  .card .ant-card-head-title {
    font-family: 'Akkurat LL Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
      'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    font-style: normal;
    opacity: 0.6;
    white-space: normal;
  }

  .ant-card-head {
    border-bottom: 0;
    padding: 0 15px;
  }

  .active-count {
    margin: 0 10px;
  }

  .card .ant-card-body {
    padding: 0 15px;
  }

  .station-overview-meta-container span {
    opacity: 0.6;
  }

  .station-overview-meta-container p:nth-of-type(2) {
    margin-left: 10px;
  }

  .station-overview-meta-container {
    display: flex;
    align-content: space-between;
    justify-content: start;
    align-items: baseline;
    flex-direction: row;
  }

  section {
    margin: 2px 15px;
  }

  .utlization-insight-tabs {
    padding: 0px 5px;
  }

  .utlization-insight-tabs.consumption > .ant-tabs-nav {
    padding: 10px 20px;
  }

  .utlization-insight-tabs .ant-tabs-nav {
    position: absolute;
    top: 0;
    right: 10px;
    padding: 20px;
  }

  .consumption-insight-tabs > .ant-tabs-nav {
    position: absolute;
    top: -70px;
    right: 0px;
  }

  .station-activation .ant-btn.ant-btn-primary {
    /* background-color: white;
    color: black;
    border: 1px solid #dedede; */

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
    flex-wrap: nowrap;
    flex-direction: row;
  }

  .station-activation svg {
    margin-left: 5px;
  }

  .ant-picker-panels {
    min-width: 700px;
    display: flex;
    justify-content: end;
  }

  .ant-picker-footer {
    position: absolute;
    top: 50%;
    display: flex;
  }

  .ant-picker-ranges {
    display: flex;
    flex-direction: column;
  }

  /* .trnx-btn span {
    font-family: 'AkkuratLLWeb-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  } */

  .trnx-btn {
    padding: 9px 20px;
  }

  .transactions .ant-card-head-title,
  .revenue .ant-card-head-title,
  .energy .ant-card-head-title,
  .ghg-savings .ant-card-head-title,
  .current .ant-card-head-title,
  .stations .ant-card-head-title {
    opacity: 0.6;
    font-family: 'AkkuratLLWeb-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 15.6px;
    font-style: normal;
    color: #000000;
  }

  .ghg-savings .ant-card-extra {
    padding-bottom: 0;
    padding-top: 10px;
  }

  .connectors-plugged-in-meta-container {
    margin-top: 29px;
  }

  .utlization-insight-tabs-2 {
    min-height: 450px;
  }

  .utlization-insight-tabs-2 > .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs.ant-tabs-top.utlization-insight-tabs-2 {
    /* position: absolute; */
    /* top: 0px;
    left: 0;
    right: 0; */
    margin: 0 auto;
  }

  .ant-tabs.ant-tabs-top.utlization-insight-tabs-2 .ant-tabs-nav-wrap {
    justify-content: center;
  }

  .chargers-availability h1.active-count.heading-02-black.laptop-active {
    /* font-size: 2em; */
  }

  .chargers-availability h1.active-count.heading-02-black.laptop-active-small {
    /* font-size: 1.5em; */
  }

  .chargers-availability.laptop-active .station-overview-meta-container {
    /* position: absolute;
    top: 5px; */
  }

  .chargers-availability.laptop-active .connectorsAvailability {
    top: 0px;
  }

  .ant-empty {
    margin: 32px auto;
  }

  .empty-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .empty-consumption-container .ant-tabs-content-holder {
    min-height: 309px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .empty-utilization-insight-container .ant-tabs-content-holder {
    min-height: 400px;
  }

  .ant-tabs.ant-tabs-top.utlization-insight-tabs-2 {
    margin-top: 21px;
  }

  .ant-tabs.ant-tabs-top.utlization-insight-tabs-2.empty .ant-tabs-nav {
    margin: 0;
  }

  .empty-utilization-insight-container .ant-tabs-content-holder,
  .empty .ant-tabs-content-holder {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .active-users-meta-container {
    align-items: center;
  }
`

export const MetricsWrapper = styled.div`
  .ant-card {
    min-height: 170px;
  }

  .paragraph-04-regular span:nth-of-type(2) {
    opacity: 0.6;
    margin-left: 5px;
  }

  .ghg-savings .paragraph-04-regular span {
    opacity: 0.6;
  }
`

export const StationOverviewMetaContainer = styled.div`
  display: flex;
  align-content: space-between;
  justify-content: start;
  align-items: baseline;
  flex-direction: row;
`

export const StationOverviewMetaCountContainer = styled.div`
  display: flex;
  align-content: space-between;
  justify-content: start;
  align-items: baseline;
  flex-direction: row;
  margin: 21px auto;

  .paragraph-03-regular {
    margin: 0;
    padding: 0;
    position: relative;
  }

  .paragraph-03-regular span {
    color: #000;
    opacity: 0.6;
    margin-right: 5px;
  }

  .paragraph-03-regular:nth-of-type(2) {
    margin-left: 15px;
  }

  svg {
    position: absolute;
    right: -25px;
    bottom: 0px;
    cursor: pointer;
  }
`

export const MicroChartContainer = styled.div`
  width: 100%;
  height: 50px;
  minwidth: 0;
  overflow: hidden;
  display: flex;
  padding-right: 12px;
  opacity: 0;

  .highcharts-tooltip-box {
    fill: #242e2c;
    display: block;
    border: none;
    stroke-width: 0;
  }
  .highcharts-tooltip text {
    fill: white;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    height: 200px;
    color: white;
  }

  .highcharts-tooltip-container .highcharts-tooltip > span {
    min-width: 350px;
    width: 400px;
    min-height: 200px;
    color: white;
  }

  .highcharts-label.highcharts-tooltip.highcharts-color-0,
  .highcharts-label.highcharts-tooltip.highcharts-color-1,
  .highcharts-label.highcharts-tooltip.highcharts-color-2 {
    stroke: unset;
  }
`

export const UtilizationGaugeInfoContainer = styled.div`
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
  /* transform: translateY(100%); */
`

export const HomePageUtilizationContainer = styled.div`
  position: relative;
  /* border-left: 1px solid rgb(222, 222, 222); */
  .utlization-gauge-info {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
  }

  .utlization-gauge-info:nth-of-type(1) {
    margin-bottom: 10px;
  }

  .utlization-gauge-info,
  .utlization-gauge-info p {
    margin-left: 20px;
  }
`
export const UtlizationInsightContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
`

export const ConsumptionInsightContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
`

export const ConsumptionInsightMetaContainer = styled.div`
  .ant-card-head-title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .ant-card {
    border-radius: 0;
    box-shadow: unset;
  }

  .card {
    min-height: unset;
    border-radius: 0;
    box-shadow: unset;
    border: 0;
    border-bottom: 1px solid #dedede;
  }

  .ant-card-head-title {
    padding-bottom: 0;
  }

  p {
    opacity: 0.6;
    margin-left: 10px;
    color: #000;
  }

  /* sub {
    font-size: 12px;
    font-weight: normal;
  } */

  .ant-card-bordered {
    border: 0;
  }

  .ant-card-bordered.uniqueUsers,
  .ant-card-bordered.avgNumberOfTransactions,
  .ant-card-bordered.averageConsumption {
    border-bottom: 1px solid #dedede;
  }
`

export const HomePageStationActivationContainer = styled.div`
  box-shadow: 0px 4px 17px rgb(0 0 0 / 7%);
  border-radius: 5px;
  background-color: #fff;
  height: 100%;
  overflow: hidden;
  min-height: 550px;
`
export const HomePageTransactionOverviewTableContainer = styled.div`
  box-shadow: 0px 4px 17px rgb(0 0 0 / 7%);
  border-radius: 5px;

  svg[data-icon='caret-up'],
  svg[data-icon='caret-down'] {
    fill: gray;
  }
`

export const DateContainer = styled.div`
  p {
    margin-bottom: 0;
  }

  p:nth-of-type(2) {
    opacity: 0.6;
  }
`

export const ChargersAvailabilityMetaContainer = styled.div`
  /* margin: 42px auto 21px auto; */

  ${(props) => props.theme.breakpoints.up('xxl')} {
    margin: 21px auto;
  }
`

export const SVGFilterContainer = styled.div`
  #gooyeSVGFilter {
    position: absolute;
    left: -999%;
    top: -999%;
  }
`

export const TrnsactionOverviewRowContainer = styled.div``
