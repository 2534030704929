import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Form, message } from 'antd'

import { SwtchError } from '../models/error'
import { ListingFull } from '../models/listing'

import { GetListing, UpdateListing } from '../services/data-provider/listing'

import { useFormatMessage } from '../helpers/intl'
import { addQueryString } from '../helpers/url'

import { Links } from '../components/routes/paths'

export const useListing = () => {
  // @ts-ignore
  const { listingId } = useParams()
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<SwtchError>()
  const [listing, setListing] = useState<ListingFull>()
  const [form] = Form.useForm()

  const successEditListingText = useFormatMessage('dashboard.updated.listing', 'Listing has been updated successfully')

  useEffect(() => {
    setError(undefined)
    setLoading(true)
    GetListing(listingId)
      .then((resp) => setListing(resp))
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }, [])

  const initialValues = initializingValues(listing)

  const onFinish = () => {
    setError(undefined)
    setLoading(true)
    UpdateListing(listingId, {
      id: listingId,
      authorId: form.getFieldValue('authorId'),
      // author: form.getFieldValue('author'),
      title: form.getFieldValue('title'),
      currency: form.getFieldValue('currency'),
      priceType: form.getFieldValue('priceType'),
      additionalServiceFee: form.getFieldValue('additionalServiceFee'),
      timeOfDayEnabled: form.getFieldValue('timeOfDayEnabled'),
      pricePerHour: form.getFieldValue('pricePerUnitCents'),
      commisionPercent:
        form.getFieldValue('commisionPercent') === '2.9% + 30c' ? 0.0 : form.getFieldValue('commisionPercent'),
      accessType: form.getFieldValue('accessType'),
      carDetailRequired: form.getFieldValue('carDetailRequired'),
      customNotification: form.getFieldValue('customNotification'),
      output: form.getFieldValue('output'),
      flatFee: form.getFieldValue('flatFee'),
      tenantId: `${form.getFieldValue('tenantId')}`,
      kind: form.getFieldValue('kind'),
      paymentType: form.getFieldValue('paymentType'),
      hidden: form.getFieldValue('hidden'),
      stripeAccountId: form.getFieldValue('stripeAccountId'),
      loiteringStatus: form.getFieldValue('loiteringStatus'),
      loiteringGracePeriod: form.getFieldValue('loiteringGracePeriod'),
      loiteringPenalty: form.getFieldValue('loiteringPenalty'),
      disableLoiteringForTenantMember: form.getFieldValue('disableLoiteringForTenantMember'),
      description: form.getFieldValue('description'),
      preferedPricePerUnitCents: form.getFieldValue('preferedPricePerUnitCents') * 100,
      enableTieredPricing: form.getFieldValue('enableTieredPricing'),
      pricePerUnitCents: form.getFieldValue('pricePerUnitCents') * 100,
      loiteringTimeLimit: form.getFieldValue('loiteringTimeLimit'),
      guestFeeCents: form.getFieldValue('guestFeeCents') * 100,
      paymentLimitCents: form.getFieldValue('paymentLimitCents') * 100,
      enableLoadManagementPricing: form.getFieldValue('enableLoadManagementPricing'),
      enableLoadManagementAutoStop: form.getFieldValue('enableLoadManagementAutoStop'),
      addCloudLoadManagement: form.getFieldValue('addCloudLoadManagement'),
      enableTimeOfDayPricing: form.getFieldValue('enableTimeOfDayPricing'),
      timeOfDayPriceWeekday: form.getFieldValue('timeOfDayPriceWeekday').map((price: number) => price * 100),
      timeOfDayPriceWeekend: form.getFieldValue('timeOfDayPriceWeekend').map((price: number) => price * 100),
      shavingEnabled: form.getFieldValue('shavingEnabled'),
      shavingPrimaryZoneStrategy: form.getFieldValue('shavingPrimaryZoneStrategy'),
      shavingSecondaryZoneStrategy: form.getFieldValue('shavingSecondaryZoneStrategy'),
      shavingEventSource: form.getFieldValue('shavingEventSource'),
    })
      .then(() => message.success(successEditListingText))
      .catch((err) => setError(err))
      .finally(() => {
        setTimeout(() => {
          setLoading(false)
          history.push(addQueryString(Links.listings(), {}))
        }, 2000)
      })
  }

  return { loading, error, listing, initialValues, form, onFinish }
}

const initializingValues = (listing: ListingFull | undefined) => {
  return {
    title: listing?.title,
    description: listing?.description,
    kind: listing?.kind,
    paymentType: listing?.paymentType,

    // author
    authorId: listing?.authorId,
    listingAuthor: listing?.person?.name,
    stripeAccountId: listing?.stripeAccountId,
    // stripeAccount: listing?.stripeAccount,
    stripeAccountName: listing?.stripeAccount?.name,
    // author: listing?.author,

    // map & access
    accessType: listing?.accessType === null ? 'public' : listing?.accessType,
    hidden: listing?.hidden,
    carDetailRequired: listing?.carDetailRequired,

    // charger
    tenantId: listing?.tenantId,
    // tenant: listing?.tenant,
    tenantName: listing?.tenant?.displayName || listing?.tenant?.name,
    output: listing?.output,
    customNotification: listing?.customNotification,

    // commmission & fee
    commisionPercent: listing?.commisionPercent,
    additionalServiceFee: listing?.additionalServiceFee,
    customComissionFee: listing?.commisionPercent,
    flatFee: listing?.flatFee,
    guestFeeCents: listing ? listing.guestFeeCents / 100 : null,
    paymentLimitCents: listing ? listing.paymentLimitCents / 100 : null,
    addCloudLoadManagement: listing?.addCloudLoadManagement,
    enableLoadManagementAutoStop: listing?.enableLoadManagementAutoStop,

    // loitering
    loiteringStatus: listing ? listing.loiteringStatus : 'no_penalty',
    loiteringPenalty: listing ? listing.loiteringPenalty : 0,
    loiteringTimeLimit: listing ? listing.loiteringTimeLimit : 0,
    loiteringGracePeriod: listing ? listing.loiteringGracePeriod : 0,
    disableLoiteringForTenantMember: listing ? listing.disableLoiteringForTenantMember : false,

    // price
    pricePerUnitCents: listing ? listing.pricePerUnitCents / 100 : null,
    priceType: listing?.priceType,
    currency: listing?.currency,
    enableLoadManagementPricing: listing?.enableLoadManagementPricing,
    enableTieredPricing: listing?.enableTieredPricing,
    preferedPricePerUnitCents: listing ? listing.preferedPricePerUnitCents / 100 : null,
    enableTimeOfDayPricing: listing?.enableTimeOfDayPricing,
    timeOfDayPriceWeekday:
      listing?.timeOfDayPriceWeekday.length !== 0
        ? listing?.timeOfDayPriceWeekday.map((todWeekday) => todWeekday / 100)
        : Array(24).fill(0),
    timeOfDayPriceWeekend:
      listing?.timeOfDayPriceWeekend.length !== 0
        ? listing?.timeOfDayPriceWeekend.map((todWeekend) => todWeekend / 100)
        : Array(24).fill(0),

    timeOfDayEnabled: listing?.timeOfDayEnabled,

    // peak shaving
    shavingEnabled: listing?.shavingEnabled,
    shavingPrimaryZoneStrategy: listing ? listing.shavingPrimaryZoneStrategy : 0,
    shavingSecondaryZoneStrategy: listing ? listing.shavingSecondaryZoneStrategy : 0,
    shavingEventSource: listing ? listing.shavingEventSource : 'manual',
  }
}
