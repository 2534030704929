import configProvider from '../../config'
import { peakShavingEventQuery } from '../../helpers/filter/peakShavingEvent'
import { addQueryString } from '../../helpers/url'
import { log } from '../../logger'
import {
  mockGetAllPeakShavingEventSchedule,
  mockListAllListingsForAProgram,
  mockListingAddedToProgram,
  mockListingRemovedFromProgram,
  mockPeakShavingAllProgramsResp,
  mockPeakShavingEventDeleted,
  mockPeakShavingEventPublished,
  mockPeakShavingEventScheduleRemoved,
  mockPeakShavingEventUnPublished,
  mockPeakShavingEvents,
  mockPeakShavingProgramDelete,
  mockProgramUnPublished,
} from '../../mock/peak-shaving'
import { PeakShavingEventFilter } from '../../models/filter'
import { PeakShavingEventsResponse, PeakShavingProgramsResponse } from '../../models/http'
import {
  PeakShavingEvent,
  PeakShavingEventSchedule,
  PeakShavingProgram,
  PeakShavingProgramListing,
} from '../../models/peak-shaving'
import CaseConverter from './case-converter'
import { apiClient } from './client'
import { returnMock } from './mock'

export async function GetAllPeakShavingPrograms(filter?: PeakShavingEventFilter): Promise<PeakShavingProgramsResponse> {
  let url = '/programs'

  if (filter) {
    const queryParams = peakShavingEventQuery(filter)

    url = addQueryString(url, queryParams)
  }

  log('get all peak shaving programs', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingAllProgramsResp)
  }

  return apiClient(url, {})
}

export async function GetPeakShavingProgram(programId: number): Promise<PeakShavingProgram> {
  const url = `/programs/${programId}`

  log('get all peak shaving programs', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingAllProgramsResp.data[0])
  }

  return apiClient(url, {})
}

export async function CreatePeakShavingProgram(program: Omit<PeakShavingProgram, 'id'>): Promise<PeakShavingProgram> {
  const url = `/programs`

  log('get all peak shaving programs', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingAllProgramsResp.data[0])
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      program: {
        ...program,
      },
    }),
  })
}

export async function UpdatePeakShavingProgram(program: PeakShavingProgram): Promise<PeakShavingProgram> {
  const url = `/programs/${program.id}`

  log('get all peak shaving programs', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingAllProgramsResp.data[0])
  }

  const { id, ...programUpdateParams } = program

  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      program: {
        ...programUpdateParams,
      },
    }),
  })
}

export async function DeletePeakShavingProgram(programId: number): Promise<{ response: string }> {
  const url = `/programs/${programId}`

  log('get all peak shaving programs', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingProgramDelete)
  }

  return apiClient(url, {
    method: 'DELETE',
  })
}

export async function GetAllListingsForProgram(programId: number): Promise<PeakShavingProgramListing[]> {
  const url = `/programs/${programId}/program_listings`

  log('get all peak shaving programs', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockListAllListingsForAProgram)
  }

  return apiClient(url, {})
}

export async function AddListingForProgram(programId: number, listingId: number): Promise<{ response: string }> {
  const url = `/programs/${programId}/program_listings`

  log('add Listing to Program', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockListingAddedToProgram)
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      listing_id: listingId,
      document: false,
    }),
  })
}

export async function publishProgram(programId: number): Promise<{ response: string }> {
  const url = `/programs/${programId}/publish`

  log('publishing Program', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockListingAddedToProgram)
  }

  return apiClient(url, {
    method: 'PUT',
  })
}

export async function unPublishProgram(programId: number): Promise<{ response: string }> {
  const url = `/programs/${programId}/unpublish`

  log('publishing Program', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockProgramUnPublished)
  }

  return apiClient(url, {
    method: 'PUT',
  })
}

export async function removeListingFromProgram(programId: number, listingId: number): Promise<{ response: string }> {
  const url = `/programs/${programId}/program_listings/remove`

  log('removing listing from Program', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockListingRemovedFromProgram)
  }

  return apiClient(url, {
    method: 'DELETE',
    body: JSON.stringify({
      listing_id: listingId,
    }),
  })
}

export async function GetAllPeakShavingEvents(filter: PeakShavingEventFilter): Promise<PeakShavingEventsResponse> {
  let url = `/shaving_events`

  const queryParams = peakShavingEventQuery(filter)

  url = addQueryString(url, queryParams)

  log('Getting all Shaving Events', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEvents)
  }

  return apiClient(url, {})
}

export async function GetPeakShavingEventDetails(id: number): Promise<PeakShavingEvent> {
  const url = `/shaving_events/${id}`

  log('Getting Peak Shaving Event Detail', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEvents.data[0])
  }

  return apiClient(url, {})
}

export async function CreateNewPeakShavingEvent(
  params: Omit<
    PeakShavingEvent,
    'id' | 'program' | 'state' | 'allowOptOut' | 'adminPersonId' | 'systemGenerated' | 'adminPerson'
  >,
): Promise<PeakShavingEvent> {
  const url = `/shaving_events`

  const snakeCasedParams = CaseConverter.camelToSnakeCase(params)

  log('Creating new Peak Shaving Event', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEvents.data[0])
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      shaving_event: snakeCasedParams,
    }),
  })
}

export async function ShowPeakShavingEventDetails(id: number): Promise<PeakShavingEvent> {
  const url = `/shaving_events/${id}`

  log('Getting Peak Shaving Event Details', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEvents.data[0])
  }

  return apiClient(url, {})
}

export async function UpdatePeakShavingEvent(
  params: Omit<
    PeakShavingEvent,
    'program' | 'state' | 'allowOptOut' | 'adminPersonId' | 'systemGenerated' | 'adminPerson'
  >,
): Promise<PeakShavingEvent> {
  const { id, ...updateParans } = params
  const url = `/shaving_events/${id}`

  const snakeCasedParams = CaseConverter.camelToSnakeCase(updateParans)

  log('Updating Peak Shaving Event', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEvents.data[0])
  }

  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      shaving_event: snakeCasedParams,
    }),
  })
}

export async function DeletePeakShavingEvent(id: number): Promise<{ response: string }> {
  const url = `/shaving_events/${id}`

  log('Updating Peak Shaving Event', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEventDeleted)
  }

  return apiClient(url, {
    method: 'DELETE',
  })
}

export async function PublishPeakShavingEvent(id: number): Promise<{ response: string }> {
  const url = `/shaving_events/${id}/publish`

  log('Updating Peak Shaving Event', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEventPublished)
  }

  return apiClient(url, {
    method: 'PUT',
  })
}

export async function UnPublishPeakShavingEvent(id: number): Promise<{ response: string }> {
  const url = `/shaving_events/${id}/unpublish`

  log('Updating Peak Shaving Event', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEventUnPublished)
  }

  return apiClient(url, {
    method: 'PUT',
  })
}

export async function GetAllEventSchedulesForEvent(shavingEventId: number): Promise<PeakShavingEventSchedule[]> {
  const url = `/shaving_events/${shavingEventId}/shaving_event_schedules`

  log('Updating Peak Shaving Event', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockGetAllPeakShavingEventSchedule)
  }

  return apiClient(url, {})
}

export async function CreateEventScheduleForEvent(
  shavingEventId: number,
  params: Omit<PeakShavingEventSchedule, 'id' | 'to'>,
): Promise<PeakShavingEventSchedule> {
  const url = `/shaving_events/${shavingEventId}/shaving_event_schedules`

  const snakeCasedParams = CaseConverter.camelToSnakeCase(params)

  log('Creating Peak Shaving Schedule', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockGetAllPeakShavingEventSchedule[0])
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      shaving_event_schedule: { shaving_event_id: shavingEventId, ...snakeCasedParams },
    }),
  })
}

export async function DeleteEventScheduleForEvent(
  shavingEventId: number,
  shavingEventScheduleId: number,
): Promise<{ response: string }> {
  const url = `/shaving_events/${shavingEventId}/shaving_event_schedules/${shavingEventScheduleId}`

  log('Deleting Peak Shaving Schedule', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEventScheduleRemoved)
  }

  return apiClient(url, {
    method: 'DELETE',
  })
}

export async function UpdateEventScheduleForEvent(
  shavingEventId: number,
  shavingEventScheduleId: number,
  params: Omit<PeakShavingEventSchedule, 'id'>,
): Promise<{ response: string }> {
  const url = `/shaving_events/${shavingEventId}/shaving_event_schedules/${shavingEventScheduleId}`

  log('Update Peak Shaving Schedule', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPeakShavingEventScheduleRemoved)
  }

  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      shaving_event_schedule: { ...params },
    }),
  })
}
