import { styled } from '../../theme'

export const MoreSpacing = styled.div`
  .ant-form-item-explain {
    margin: 0.5em 0;
  }
`

export const FormContainer = styled.div`
  label {
    word-wrap: break-word;
    white-space: normal;
  }

  .ant-row.ant-form-item:last-of-type > div {
    align-items: center;
  }
`
