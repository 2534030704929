import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_exportData from 'highcharts/modules/export-data'
import HC_exporting from 'highcharts/modules/exporting'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'

import { DownloadFilesMenu } from '../../../../atom/download-files-menu'
import { useFormatMessage } from '../../../../helpers/intl'
import { ChargerProfile } from '../../../../models/transaction'
import { useAppState } from '../../../../state'
import { theme } from '../../../../theme'
import { HomePageChargerUtilization } from '../../../../atom/chart/chart'

HC_exporting(Highcharts)
HC_exportData(Highcharts)

const borderRadius = require('highcharts-border-radius')
borderRadius(Highcharts)

const handleFileName = (tabKey: string) => {
  return `Utilization Insights ${tabKey} Data ${moment().format('YYYY-DD-MM')}`
}

interface props {
  tabKey: string
  chargerProfile: ChargerProfile[]
  timezone?: string
}

export const HomePageUtilizationInsightsChart: React.FC<props> = ({ tabKey, chargerProfile, timezone }) => {
  const timzeZoneText = useFormatMessage('dashboard.homePage.operationoverview.timeZone.heading', 'Time (EST)')
  const { language } = useAppState()

  const [utilizationInsightOptions, setUtilizationInsightOptions] = useState({
    chart: {
      type: 'column',
      styled: true,
      height: 400,
      style: {
        fontFamily: theme.fontFamily.regularFontFamily,
        fontWeight: '400',
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      headerFormat: '<table>',
      pointFormat: '<tr>' + '<td style="text-align: right"><b>{point.y}</b></td></tr>',
      footerFormat: '</table>',
      style: {
        color: '#fff',
        fontFamily: theme.fontFamily.regularFontFamily,
        fontSize: '11px',
        fontWeight: 700,
        textAlign: 'center',
      },
      //@ts-ignore
      // formatter: function () {
      //   //@ts-ignore
      //   return `<table><tr><td style="text-align: center;">${this.y}</td></tr></table>`
      // },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    title: {
      text: '',
    },
    //   tooltip: {},
    plotOptions: {
      groupPadding: 0,
      pointWidth: 15,
      pointPadding: 0,
      borderWidth: 0,
      series: {
        stacking: 'normal',
      },
      column: {
        borderRadiusTopLeft: 5,
        borderRadiusTopRight: 5,
      },
    },
    xAxis: {
      categories: [''],
      tickWidth: 0,
      fontSize: '11px',
      labels: {
        rotation: -45,
        style: {
          fontFamily: theme.fontFamily.regularFontFamily,
          fontWeight: 400,
          color: '#000',
        },
      },
      title: {
        text: '',
        margin: 20,
        style: {
          fontFamily: theme.fontFamily.regularFontFamily,
          fontWeight: 400,
          fontSize: '12px',
        },
      },
    },
    yAxis: {
      className: 'utilization-insight-yaxis',
      max: 40,
      maxPadding: 0,
      allowDecimals: false,
      labels: {
        style: {
          fontFamily: theme.fontFamily.regularFontFamily,
          fontWeight: 400,
          color: '#000',
        },
      },
      gridLineDashStyle: 'dash',
    },
    series: [
      {
        name: 'Fill Series',
        legendIndex: 1,
        color: '#242E2C',
        opacity: 0.2,
        data: [1],
        enableMouseTracking: false,
      },

      {
        name: '# of Connectors Plugged In',
        LegendIndex: 0,
        color: '#54dec7',
        data: [1],
      },
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
      csv: {
        dateFormat: '%Y-%m-%d',
      },
      filename: '',
      chartOptions: {
        chart: {
          style: {
            fontFamily: theme.fontFamily.regularFontFamily,
          },
        },
        title: {
          style: {
            fontFamily: theme.fontFamily.regularFontFamily,
            fontWeight: 400,
            fontSize: '10px',
          },
        },
        xAxis: {
          title: {
            style: {
              fontFamily: theme.fontFamily.regularFontFamily,
              fontWeight: 400,
              fontSize: '8px',
            },
          },
          labels: {
            style: {
              fontFamily: theme.fontFamily.regularFontFamily,
              fontWeight: 400,
              fontSize: '8px',
            },
          },
        },
        yAxis: {
          title: {
            text: '# of Connectors Plugged In',
            style: {
              fontFamily: theme.fontFamily.regularFontFamily,
              fontWeight: 400,
              fontSize: '8px',
            },
          },
          labels: {
            style: {
              fontFamily: theme.fontFamily.regularFontFamily,
              fontWeight: 400,
              fontSize: '8px',
            },
          },
        },
      },
    },
  })

  const chartComponent = useRef(null)

  useEffect(() => {
    const timeSeries = chargerProfile.map((obj) => obj.time)
    const chargerInUseWeek = chargerProfile.map((obj) => obj.chargerInUseWeek)
    const chargerInUseWeekday = chargerProfile.map((obj) => obj.chargerInUseWeekday)
    const chargerInUseWeekend = chargerProfile.map((obj) => obj.chargerInUseWeekend)
    const chargerInUseWeekMax = Math.max(...chargerInUseWeek)
    const chargerInUseWeekdayMax = Math.max(...chargerInUseWeekday)
    const chargerInUseWeekendMax = Math.max(...chargerInUseWeekend)
    const chargerInUseWeekFill = chargerProfile.map((obj) => Number.MAX_VALUE - obj.chargerInUseWeek)
    const chargerInUseWeekdayFill = chargerProfile.map((obj) => Number.MAX_VALUE - obj.chargerInUseWeekday)
    const chargerInUseWeekendFill = chargerProfile.map((obj) => Number.MAX_VALUE - obj.chargerInUseWeekend)

    switch (tabKey) {
      case 'All':
        setUtilizationInsightOptions({
          ...utilizationInsightOptions,
          xAxis: {
            ...utilizationInsightOptions.xAxis,
            title: {
              ...utilizationInsightOptions.xAxis.title,
              text: timezone && timezone === 'America/Toronto' ? timzeZoneText : '',
            },
            categories: timeSeries,
          },
          yAxis: {
            ...utilizationInsightOptions.yAxis,
            max: chargerInUseWeekMax,
          },
          series: [
            {
              ...utilizationInsightOptions.series[0],
              data: chargerInUseWeekFill,
            },
            {
              ...utilizationInsightOptions.series[1],
              data: chargerInUseWeek,
            },
          ],
          exporting: {
            ...utilizationInsightOptions.exporting,
            filename: handleFileName(tabKey),
          },
        })

        break
      case 'Weekday':
        setUtilizationInsightOptions({
          ...utilizationInsightOptions,
          xAxis: {
            ...utilizationInsightOptions.xAxis,
            title: {
              ...utilizationInsightOptions.xAxis.title,
              text: timezone && timezone === 'America/Toronto' ? timzeZoneText : '',
            },
            categories: timeSeries,
          },
          yAxis: {
            ...utilizationInsightOptions.yAxis,
            max: chargerInUseWeekdayMax,
          },
          series: [
            {
              ...utilizationInsightOptions.series[0],
              data: chargerInUseWeekdayFill,
            },
            {
              ...utilizationInsightOptions.series[1],
              data: chargerInUseWeekday,
            },
          ],
          exporting: {
            ...utilizationInsightOptions.exporting,
            filename: handleFileName(tabKey),
          },
        })

        break
      case 'Weekend':
        setUtilizationInsightOptions({
          ...utilizationInsightOptions,
          xAxis: {
            ...utilizationInsightOptions.xAxis,
            title: {
              ...utilizationInsightOptions.xAxis.title,
              text: timezone && timezone === 'America/Toronto' ? timzeZoneText : '',
            },
            categories: timeSeries,
          },
          yAxis: {
            ...utilizationInsightOptions.yAxis,
            max: chargerInUseWeekendMax,
          },
          series: [
            {
              ...utilizationInsightOptions.series[0],
              data: chargerInUseWeekendFill,
            },
            {
              ...utilizationInsightOptions.series[1],
              data: chargerInUseWeekend,
            },
          ],
          exporting: {
            ...utilizationInsightOptions.exporting,
            filename: handleFileName(tabKey),
          },
        })

        break
    }
  }, [chargerProfile, tabKey, timezone, language])

  return (
    <HomePageChargerUtilization lang={language}>
      {utilizationInsightOptions && (
        <>
          <DownloadFilesMenu
            chart={chartComponent}
            onClickCSV={() => {
              // @ts-ignore
              const series1 = chartComponent.current.chart.series[0]
              series1.setVisible(!series1.visible)
              setTimeout(() => {
                series1.setVisible(!series1.visible)
              }, 500)
            }}
          />
          {tabKey && (
            <HighchartsReact ref={chartComponent} highcharts={Highcharts} options={utilizationInsightOptions} />
          )}
        </>
      )}
    </HomePageChargerUtilization>
  )
}
