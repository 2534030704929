import { useState } from 'react'
import moment from 'moment'
import { Button, Pagination, Table } from 'antd'

import { DateTime } from '../../../components/date-time'
import { TransactionStateBadge } from '../../../components/transactions/transaction-state-badge'
import { TransactionsListView } from '../../../components/transactions/transactions-list-view'

import { PaginationMeta } from '../../../models/pagination'
import { isTenantViewer, UserRef } from '../../../models/user'
import { errorTransaction, hasEnded, Transaction } from '../../../models/transaction'

import { calculateDuration } from '../../../helpers/duration'
import { renderFormatMessage } from '../../../helpers/intl'
import { formatEnergy, formatPrice } from '../../../helpers/number-format'

import { useAppState } from '../../../state'
import { styled } from '../../../theme'
import { TransactionsNestedColumns } from '../table/transactions-nested-columns'

interface props {
  transactions: Transaction[]
  loading: boolean
  currentPage: number
  pagination: PaginationMeta | undefined
  onErrorTransaction: (transaction: Transaction) => Promise<void>
  onStartTransaction: (transaction: Transaction) => Promise<void>
  onStopTransaction: (transaction: Transaction) => Promise<void>
  onPaginationChange: (page: number, pageSize?: number) => void
}

const TableWrapper = styled.div`
  ${(props) => props.theme.breakpoints.between('lg', 'xl')} {
    .ant-table-column-sorters-with-tooltip {
      width: 0;
    }
    .ant-table {
      font-size: 0.9vw;
    }
  }
  .ant-table-content {
    overflow: auto;
  }
`

export const TableView: React.FC<props> = ({
  transactions,
  onErrorTransaction,
  onStartTransaction,
  onStopTransaction,
  onPaginationChange,
  loading,
  pagination,
  currentPage,
}) => {
  const { selectedTenant, currentUser, IsMobile, IsTablet, IsLaptop, IsDesktop } = useAppState()
  const [actLoading, setActLoading] = useState(false)

  const isMobile = IsMobile()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()

  const isViewerToTenant = isTenantViewer(selectedTenant)

  let columns: any = transactionsColumnsMain()

  const transactionRow = (transaction: Transaction) => <TransactionsNestedColumns transaction={transaction} />

  const transColumnsFilter = columns.filter((el: any) => (currentUser?.role === 'admin' ? el : el.key !== 'action'))

  const noViewerFilter = isViewerToTenant
    ? transactions.filter((trx) => trx.currentState !== 'errored' && trx.currentState !== 'canceled')
    : transactions

  return (
    <>
      {(isDesktop || isLaptop) && (
        <TableWrapper>
          <Table
            loading={loading}
            columns={transColumnsFilter}
            expandable={{ expandedRowRender: (record) => transactionRow(record) }}
            dataSource={noViewerFilter}
            pagination={{
              position: ['bottomCenter'],
              total: pagination?.totalEntries,
              current: pagination?.currentPage,
              pageSize: pagination?.perPage,
              showSizeChanger: false,
              onChange: onPaginationChange,
            }}
            rowKey={(transaction: Transaction): string => `${transaction.id}`}
          />
        </TableWrapper>
      )}
      {(isMobile || isTablet) && (
        <>
          <TransactionsListView
            transactions={noViewerFilter}
            onStopTransaction={onStopTransaction}
            onStartTransaction={onStartTransaction}
            onErrorTransaction={onErrorTransaction}
            loading={loading}
          />
          <br />
          <Pagination
            current={currentPage}
            onChange={onPaginationChange}
            total={pagination?.totalEntries}
            showQuickJumper={false}
            showSizeChanger={false}
            showTitle={true}
            style={{ textAlign: 'center' }}
          />
        </>
      )}
    </>
  )

  function transactionsColumnsMain() {
    let columns: any = [
      {
        title: renderFormatMessage('dashboard.transactionsPage.table.transactionId', 'Transaction ID'),
        dataIndex: 'id',
        defaultSortOrder: 'descend',
        sorter: (a: Transaction, b: Transaction) => a.id - b.id,
      },
      {
        title: renderFormatMessage('dashboard.transactionsPage.table.startMethod', 'Start Method'),
        dataIndex: 'startMethod',
        sorter: (a: Transaction, b: Transaction) => a.startMethod.localeCompare(b.startMethod),
      },
      {
        title: renderFormatMessage('dashboard.transactionsPage.table.user', 'User'),
        dataIndex: 'user',
        sorter: (a: Transaction, b: Transaction) => {
          return a.user?.name.localeCompare(b.user?.name)
        },
        render: (user: UserRef) => user?.name,
      },
    ]

    if (!selectedTenant) {
      columns.push({
        title: renderFormatMessage('dashboard.text.tenant', 'Tenant'),
        sorter: (a: Transaction, b: Transaction) => {
          return a.tenant.name.localeCompare(b.tenant.name)
        },
        render: (trx: Transaction) => {
          return trx.tenant.displayName || trx.tenant.name
        },
      })
    }

    columns.push(
      {
        title: renderFormatMessage('dashboard.transactionsPage.table.state', 'State'),
        key: 'currentState',
        sorter: (a: Transaction, b: Transaction) => a.currentState.localeCompare(b.currentState),
        render: (trx: Transaction) => <TransactionStateBadge transaction={trx} />,
      },
      {
        title: renderFormatMessage('dashboard.transactionsPage.table.connectedAt', 'Connected At'),
        key: 'startAt',
        sorter: (a: Transaction, b: Transaction) => moment(a.startAt).unix() - moment(b.startAt).unix(),
        render: ({ startAt, timezone }: Transaction) => <DateTime date={startAt} timezone={timezone} />,
      },
      {
        title: renderFormatMessage('dashboard.transactionsPage.table.disconnectedAt', 'Disconnected At'),
        key: 'endAt',
        sorter: (a: Transaction, b: Transaction) => moment(a.endAt).unix() - moment(b.endAt).unix(),
        render: ({ endAt, timezone }: Transaction) => (endAt ? <DateTime date={endAt} timezone={timezone} /> : '---'),
      },
      {
        title: renderFormatMessage('dashboard.transactionsPage.table.plugInDuration', 'Plug-in Duration'),
        render: ({ startAt, endAt }: Transaction) => {
          if (!endAt) {
            return '---'
          }
          return <span>{calculateDuration(moment(startAt), moment(endAt))}</span>
        },
      },
      {
        title: renderFormatMessage('dashboard.transactionsPage.table.energy', 'Energy (kWh)'),
        dataIndex: 'energy',
        sorter: (a: Transaction, b: Transaction) => a.energy - b.energy,
        render: (energy: Transaction['energy']) => (energy ? formatEnergy(energy / 1000) : '---'),
      },
      {
        title: renderFormatMessage('dashboard.transactionsPage.table.total', 'Total'),
        dataIndex: 'financial',
        sorter: (a: Transaction, b: Transaction) => a.financial.total - b.financial.total,
        render: ({ total }: Transaction['financial']) => {
          if (total && total !== 0) {
            return formatPrice(total / 100)
          }
          return formatPrice(total)
        },
      },
      {
        title: renderFormatMessage('dashboard.transactionsPage.table.action', 'Action'),
        key: 'action',
        align: 'center',
        render: (transaction: Transaction) => {
          return (
            <>
              {transaction.ocppTransactionId && !hasEnded(transaction) && (
                <Button
                  loading={actLoading}
                  block={true}
                  onClick={() => {
                    setActLoading(true)
                    onStopTransaction(transaction).finally(() => {
                      setActLoading(false)
                    })
                  } }
                  style={{ marginBottom: '5px' }}
                >
                  {renderFormatMessage('dashboard.text.stop', 'Stop')}
                </Button>
              )}
              {!transaction.ocppTransactionId && !hasEnded(transaction) && (
                <Button
                  type="primary"
                  block={true}
                  loading={actLoading}
                  onClick={() => {
                    setActLoading(true)
                    onStartTransaction(transaction).finally(() => {
                      setActLoading(false)
                    })
                  } }
                  style={{ marginBottom: '5px' }}
                >
                  {renderFormatMessage('dashboard.text.start', 'Start')}
                </Button>
              )}
              {errorTransaction(transaction) && (
                <Button
                  type="ghost"
                  block={true}
                  danger
                  loading={actLoading}
                  onClick={() => {
                    setActLoading(true)
                    onErrorTransaction(transaction).finally(() => {
                      setActLoading(false)
                    })
                  } }
                  style={{ marginBottom: '5px' }}
                >
                  {renderFormatMessage('dashboard.text.error', 'Error')}
                </Button>
              )}
            </>
          )
        },
      }
    )
    return columns
  }
}
