import { log } from '../../logger'
import configProvider from '../../config'

import { apiClient } from './client'
import { mockInstallers } from '../../mock/installer-mock'
import { Installer } from '../../models/installer'

export async function GetInstallers(): Promise<Installer[]> {
  const url = '/installers'

  log('fetching installers', { url: url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockInstallers)
  }
  return apiClient(url, {})
}

export async function CreateInstaller(name: string): Promise<Installer> {
  const url = '/installers'

  log('create an installer', { url: url })
  if (configProvider.config.mockEnabled) {
    const newInstaller = { id: mockInstallers.length + 1, name }
    return Promise.resolve(newInstaller)
  }
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      installer: {
        name,
      },
    }),
  })
}
