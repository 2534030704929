import { Card, Col, Row } from 'antd'
import { useHomeTranslation } from '../../../../hooks/translation/useHomeTranslation'
import { TrxSummary } from '../../../../models/transaction'
import { percentage } from '../../../../helpers/calculation'
import { CardWrapper } from '../../../../atom/card'

interface props {
  loading: boolean
  trxProfileSummary?: TrxSummary
}

const TransactionMetrics: React.FC<props> = ({ loading, trxProfileSummary }) => {
  const { transactionsAfter6PM, transactionsOnWeekdays } = useHomeTranslation()

  return (
    <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
      <CardWrapper>
        <Card title="Transactions" loading={loading} className="transactions">
          <Row align="middle" justify="space-between">
            <Col span={24}>
              {trxProfileSummary && (
                <h2 className="heading-02-black">
                  {trxProfileSummary.txnCount ? renderTransactions(trxProfileSummary.txnCount) : '0'}
                </h2>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <p className="paragraph-04-regular">
                <span>
                  {trxProfileSummary &&
                    calculateTrxPercentage(trxProfileSummary.txnAfterHr, trxProfileSummary.txnCount)}
                </span>
                <span>{transactionsAfter6PM}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <p className="paragraph-04-regular">
                <span>
                  {trxProfileSummary && calculateTrxPercentage(trxProfileSummary.txnOnDays, trxProfileSummary.txnCount)}
                </span>
                <span>{transactionsOnWeekdays}</span>
              </p>
            </Col>
          </Row>
        </Card>
      </CardWrapper>
    </Col>
  )
}

export default TransactionMetrics

const calculateTrxPercentage = (value: number, total: number) => {
  return <b>{`${value && total ? percentage(value, total) : '0'}%`}</b>
}

const renderTransactions = (trnxCount: number) => {
  const output = trnxCount < 1000 ? trnxCount : `${(trnxCount / 1000).toFixed(2)}K`
  return output
}
