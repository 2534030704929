import React, { useEffect, useState } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'

import { SVGFilterContainer } from '../../ContainerStyles/homepageStyles'
import { ChargerStationInfo } from '../../../../models/chargerv2'
import { useFormatMessage } from '../../../../helpers/intl'
import { useAppState } from '../../../../state'
import { styled } from '../../../../theme'

interface props {
  chargerStationInfo: ChargerStationInfo
}

const StationsPieChartWrapper = styled.div`
  .highcharts-tooltip span {
    height: auto;
    width: 240px;
    background-color: #242e2c;
    color: white;
    overflow: auto;
    white-space: normal !important;
  }

  .highcharts-tooltip div.sub-text {
    opacity: 0.5;
    padding: '15px auto';
  }

  .highcharts-legend {
    pointer-events: none;
  }

  .highcharts-tooltip-box {
    fill-opacity: 1;
  }

  /* .highcharts-root {
    overflow: visible;
  } */
`

export const HomePageStationPieChart: React.FC<props> = ({ chargerStationInfo }) => {
  const { language } = useAppState()

  const onlineChargers = useFormatMessage(
    'dashboard.homePage.stationoverview.stations.onlinechargers.text',
    'Online Connnectors',
  )

  const offlineChargers = useFormatMessage(
    'dashboard.homePage.stationoverview.stations.offlinechargers.text',
    'Offline Connectors',
  )

  const underRepairChargers = useFormatMessage(
    'dashboard.homePage.stationoverview.stations.underrepairchargers.text',
    'Under Repair',
  )

  const onlineChargersDescription = useFormatMessage(
    'dashboard.homePage.stationoverview.stations.onlinechargers.description',
    'Functional and connection established allowing for remote monitoring and management',
  )

  const offlineChargersDescription = useFormatMessage(
    'dashboard.homePage.stationoverview.stations.offlinechargers.description',
    'Unable to establish connection and may require further troubleshooting or escalation',
  )

  const underRepairChargersDescription = useFormatMessage(
    'dashboard.homePage.stationoverview.stations.underrepairchargers.description',
    'Currently out of service due to maintenance to restore functionality',
  )

  const chargerStatuses = [onlineChargers, offlineChargers, underRepairChargers]

  const [chartOptions, setChartOptions] = useState({
    chart: {
      plotShadow: false,
      height: 312,
      styledMode: true,
    },
    title: {
      text: '',
    },
    legend: {
      floating: true,
      align: 'left',
      x: -10,
      y: 10,
      symbolRadius: 3,
      layout: 'vertical',
      itemDistance: 70,
      padding: 1,
      itemMarginTop: 0,
      itemMarginBottom: 11,
      itemStyle: {
        lineHeight: '140%',
      },
      labelFormatter: function (): any {
        // console.log('---label--- station --')
        //@ts-ignore
        // console.log(this)
        //@ts-ignore
        // const [value] = this.ydata
        //@ts-ignore
        var output = `<span class='paragraph-03-regular seriesName'> ${this.name} </span> <span class='paragraph-03-regular'><b> (${this.y})</b></span>`
        return output
      },
    },
    tooltip: {
      shared: false,
      useHTML: true,
      //@ts-ignore
      formatter: function () {
        //@ts-ignore
        return 'The value for <b>' + this.x + '</b> is <b>' + this.y + '</b>'
      },
      style: {},
    },
    plotOptions: {
      pie: {
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
        startAngle: -115,
        endAngle: 115,
        showInLegend: true,
        slicedOffset: 5,
      },
      series: {
        states: {
          hover: {
            halo: {
              size: 1,
              opacity: 0.4,
              attributes: {
                fill: '#54DEC7',
                strokeWidth: 25,
                stroke: '#54DEC7',
              },
            },
          },
          inactive: {
            enabled: false,
          },
          lineWidth: 5,
        },
        point: {
          events: {
            mouseOver: function () {
              const point = this,
                //@ts-ignore
                chart = this.series.chart,
                //@ts-ignore
                legendItemGroup = this.legendItem.group,
                { x, y, height } = legendItemGroup.element.getBBox()

              chart.renderedLegendRect = chart.renderer
                .rect(x, y - 10, 999999999, height + 20)
                .attr({
                  fill: '#f4f6f4',
                  stroke: '#f4f6f4',
                })
                .add(legendItemGroup)
            },
            mouseOut: function () {
              const point = this
              //@ts-ignore
              point.series.chart.renderedLegendRect.destroy()
            },
          },
        },
      },
    },
    series: [
      {
        type: 'pie',
        innerSize: '60%',
        data: [
          {
            name: onlineChargers,
            y: 0,
            sliced: true,
          },
          {
            name: offlineChargers,
            y: 0,
            sliced: true,
          },
          {
            name: underRepairChargers,
            y: 0,
            sliced: true,
          },
        ],
      },
    ],
  })

  Highcharts.addEvent(Highcharts.Series, 'afterRender', function () {
    this.points.forEach(function (p) {
      if (p && p.graphic && chargerStatuses.includes(p.name)) {
        p.graphic.element.setAttribute('filter', 'url(#goo)')
      }
    })
  })

  useEffect(() => {
    setChartOptions({
      chart: {
        plotShadow: false,
        height: 312,
        styledMode: true,
      },
      title: {
        text: '',
      },
      legend: {
        floating: true,
        align: 'left',
        x: 20,
        y: 10,
        symbolRadius: 3,
        layout: 'vertical',
        itemDistance: 70,
        padding: 1,
        itemMarginTop: 0,
        itemMarginBottom: 11,
        itemStyle: {
          lineHeight: '140%',
        },
        labelFormatter: function (): any {
          // console.log('---label--- station --')
          //@ts-ignore
          // console.log(this)
          //@ts-ignore
          // const [value] = this.ydata
          //@ts-ignore
          var output = `<span class='paragraph-03-regular seriesName'> ${
            //@ts-ignore
            this.name
            //@ts-ignore
          } </span> <span class='paragraph-03-regular'><b> (${this.y})</b></span>`
          return output
        },
      },
      tooltip: {
        shared: false,
        useHTML: true,
        formatter: function () {
          //@ts-ignore
          switch (this.key) {
            case onlineChargers:
              //@ts-ignore
              return (
                //@ts-ignore
                `<span class="paragraph-04-regular"> ${onlineChargers} : ${this.y}</span>` +
                `</br>` +
                `<div class="sub-text paragraph-04-regular">${onlineChargersDescription}</div>`
              )
            case offlineChargers:
              //@ts-ignore
              return (
                //@ts-ignore
                `<span class="paragraph-04-regular">${offlineChargers} : ${this.y}</span>` +
                `</br>` +
                `<div class="sub-text paragraph-04-regular">${offlineChargersDescription}</div>`
              )
            case underRepairChargers:
              //@ts-ignore
              return (
                //@ts-ignore
                `<span class="paragraph-04-regular"> ${underRepairChargers} : ${this.y}</span>` +
                `</br>` +
                `<div class="sub-text paragraph-04-regular">${underRepairChargersDescription}</div>`
              )
          }

          //return 'The value for <b>' + this.key + '</b> is <b>' + this.y + '</b>'
        },
        style: {
          maxWidth: '350px',
        },
      },
      plotOptions: {
        pie: {
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
          startAngle: -115,
          endAngle: 115,
          showInLegend: true,
          slicedOffset: 5,
        },
        series: {
          states: {
            hover: {
              halo: {
                size: 1,
                opacity: 0.4,
                attributes: {
                  fill: '#54DEC7',
                  strokeWidth: 25,
                  stroke: '#54DEC7',
                },
              },
            },
            inactive: {
              enabled: false,
            },
            lineWidth: 5,
          },
          point: {
            events: {
              mouseOver: function () {
                const point = this,
                  //@ts-ignore
                  chart = this.series.chart,
                  //@ts-ignore
                  legendItemGroup = this.legendItem.group,
                  { y } = legendItemGroup.element.getBBox()

                chart.renderedLegendRect = chart.renderer
                  .rect(-350, y - 7, 999999999, 28)
                  .attr({
                    fill: '#f4f6f4',
                    stroke: '#f4f6f4',
                  })
                  .add(legendItemGroup)
              },
              mouseOut: function () {
                const point = this
                //@ts-ignore
                point.series.chart.renderedLegendRect.destroy()
              },
            },
          },
        },
      },
      series: [
        {
          type: 'pie',
          innerSize: '60%',
          data: [
            {
              name: onlineChargers,
              y: chargerStationInfo.online,
              sliced: true,
            },
            {
              name: offlineChargers,
              y: chargerStationInfo.offline,
              sliced: true,
            },
            {
              name: underRepairChargers,
              y: chargerStationInfo.repair + chargerStationInfo.other + chargerStationInfo.troubleshooting,
              sliced: true,
            },
          ],
        },
      ],
    })

    if (
      chargerStationInfo.offline /
        (chargerStationInfo.offline + chargerStationInfo.online + chargerStationInfo.repair) >
      0.5
    ) {
      setTimeout(() => {
        document
          .querySelector('.stations .highcharts-pie-series path.highcharts-point.highcharts-color-0')
          ?.setAttribute('transform', 'translate(0,1)')
        document
          .querySelector('.stations .highcharts-pie-series path.highcharts-point.highcharts-color-1')
          ?.setAttribute('transform', 'translate(0,-5)')
        document
          .querySelector('.stations .highcharts-pie-series path.highcharts-point.highcharts-color-2')
          ?.setAttribute('transform', 'translate(0,2)')
      }, 2000)
    }
  }, [chargerStationInfo, language])

  return (
    chartOptions && (
      <StationsPieChartWrapper>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        <SVGFilterContainer>
          <svg width="240" height="240" id="gooyeSVGFilter">
            <filter id="goo">
              <feGaussianBlur in="SourceGraphic" stdDeviation="3" result="blur" />
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 20 -10"
                result="goo"
              />
            </filter>
          </svg>
        </SVGFilterContainer>
      </StationsPieChartWrapper>
    )
  )
}
