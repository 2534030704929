import { useFormatMessage } from '../../helpers/intl'

export const useAppHeaderTranslation = () => {
  const adminDashboardText = useFormatMessage('dashboard.title.admindashboard', 'Admin Dashboard')
  const supportDashboardText = useFormatMessage('dashboard.title.supportdashboard', 'Support Dashboard')
  const dashboardText = useFormatMessage('dashboard.title', 'Dashboard')
  const logoutText = useFormatMessage('dashboard.header.logout', 'Logout')
  const listingText = useFormatMessage('dashboard.text.listings', 'Listings')
  const switchHomePageText = useFormatMessage('dashboard.text.switchHomePage', 'Switch to the classic dashboard view')
  const switchUsersPageText = useFormatMessage('dashboard.text.switchUsersPage', 'Switch to the classic users page')
  const switchHomePageNewVersionText = useFormatMessage(
    'dashboard.text.switchHomePageNewVersion',
    'Switch to the new dashboard view',
  )
  const switchUsersPageNewVersionText = useFormatMessage(
    'dashboard.text.switchUsersPageNewVersion',
    'Switch to the new users page',
  )

  return {
    adminDashboardText,
    supportDashboardText,
    dashboardText,
    listingText,
    logoutText,
    switchHomePageText,
    switchUsersPageText,
    switchUsersPageNewVersionText,
    switchHomePageNewVersionText,
  }
}
