import { Checkbox, Select, Spin } from 'antd'
import { ReactElement,useMemo, useRef, useState } from 'react'
import { debounce } from 'lodash'

import { TenantRef } from '../../models/tenant'
import { useAppState } from '../../state'

import { MultiOptionSelectorContainer } from '../../atom/select'
import { FindListings } from '../../services/data-provider/listing'
import { ListingRef } from '../../models/listing'

import { magnifySVG } from '../../assets/svg/magnify'
import { useListingBasicTrans } from '../../hooks/translation/useListingBasicTrans'
interface props {
  onOptionChange: (listingTitles: string[]) => void
  defaultTenant?: TenantRef
  tenants?: TenantRef[]
  loading?: boolean
  isCombineSelector?: boolean
}

export const MultiListingTitleSelector: React.FC<props> = ({
  onOptionChange,
  defaultTenant,
  tenants,
  loading,
  isCombineSelector,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([''])
  const [listings, setListings] = useState<ListingRef[]>([])
  const fetchRef = useRef(0)
  const [fetching, setFetching] = useState(false)

  const { IsDesktop, IsLaptop } = useAppState()
  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()

  const { listingTitlesText } = useListingBasicTrans()

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      if (value.length >= 2) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setListings([])
        setFetching(true)

        FindListings(value, defaultTenant, tenants).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          setListings(newOptions)
          setFetching(false)
        })
      }
    }

    return debounce(loadOptions, 800)
  }, [FindListings, 800])

  const renderOption = ({ id, title }: ListingRef): ReactElement => (
    <Select.Option key={id} value={title} label={title}>
      <Checkbox checked={selectedOptions.includes(title)}>{title}</Checkbox>
    </Select.Option>
  )

  const handleChange = (value: string[]) => {
    setSelectedOptions(value)
    onOptionChange(value)
  }

  const renderTenantLists = (listings: ListingRef[]) => {
    return listings.map((v) => renderOption(v)).sort((a, b) => sortListingTitles(a, b))
  }

  const sortListingTitles = (a: ReactElement, b: ReactElement) => {
    return b.props.children.props.checked - a.props.children.props.checked
  }

  const handlePlaceholder = () => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0] : selectedPlaceholder(selected)
  }

  const selectedPlaceholder = (selected: number) => `${selected} Listing Titles Selected`

  const canCombineBorder = (isDesktop || isLaptop) && isCombineSelector

  return (
    <MultiOptionSelectorContainer isCombine={canCombineBorder}>
      {magnifySVG}
      <Select
        disabled={loading}
        showSearch
        mode="multiple"
        allowClear
        loading={fetching}
        style={{ minWidth: 156, width: '100%', lineHeight: '34px' }}
        placeholder={listingTitlesText}
        onChange={handleChange}
        onSearch={debounceFetcher}
        dropdownMatchSelectWidth={false}
        maxTagCount={0}
        maxTagPlaceholder={handlePlaceholder}
        notFoundContent={fetching ? <Spin size="small" /> : null}
      >
        {renderTenantLists(listings)}
      </Select>
    </MultiOptionSelectorContainer>
  )
}

export default MultiListingTitleSelector
