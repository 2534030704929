import { Button, Col, Row, Space } from 'antd'
import { debounce } from 'lodash'

import { ButtonIcon } from '../../../atom/button'
import { magnifyBlackSVG } from '../../../assets/svg/magnify'
import { useAppState } from '../../../state'
import { useGeneralTranslation } from '../../../hooks/translation/useGeneralTranslation'

import MultiListingTitleSelector from '../../../components/selector/MultiListingTitleSelector'
import MultiOptionSelector from '../../../components/redesign/MultiOptionSelector'
import MultiListingIdSelector from '../../../components/redesign/MultiListingIdSelector'
import { MultiTenantSelector } from '../../../components/redesign/multiple-tenant-selector'

import { ConnectorServiceStatus, connectorServiceStatus } from '../../../models/connector'
import { TenantRef } from '../../../models/tenant'
import { ListingFilter } from '../../../models/filter'
import { LineBreak } from '../../../atom/line-break'

interface props {
  loading: boolean
  tenants: TenantRef[]
  defaultTenant?: TenantRef
  filter: ListingFilter
  onTenants: (tenants: TenantRef[]) => void
  onFilter: (filters: ListingFilter) => void
  onServiceStatus: (serviceStatuses: ConnectorServiceStatus[]) => void
  onListingTitle: (listingTitles: string[]) => void
  onListingIds: (listingIds: number[]) => void
  onTenantSelection: (tenants: TenantRef[]) => void
  onFetchListings: () => void
}

const ListingAllSelectors: React.FC<props> = ({
  onFetchListings,
  tenants,
  defaultTenant,
  filter,
  onTenants,
  onFilter,
  onServiceStatus,
  onListingTitle,
  onListingIds,
  onTenantSelection,
  ...props
}) => {
  const { IsDesktop, IsLaptop } = useAppState()

  const { searchText } = useGeneralTranslation()

  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()

  const renderMultTenantSelector = (
    <MultiTenantSelector
      onOptionsChange={debounce(onTenantSelection, 1000)}
      onClear={() => onTenants([])}
      isCombineRight
      {...props}
    />
  )

  const renderMultiListingTitleSelector = (
    <MultiListingTitleSelector
      onOptionChange={(listingTitle: string[]) => onListingTitle(listingTitle)}
      defaultTenant={defaultTenant}
      tenants={tenants}
      isCombineSelector
      {...props}
    />
  )

  const renderMultiOptionSelector = (
    <MultiOptionSelector
      options={connectorServiceStatus.map((v) => v)}
      onOptionsChange={(opts) => onServiceStatus(opts)}
      onClear={() => onFilter({ ...filter, serviceStatuses: [] })}
      onSearch={(opts) => onServiceStatus(opts)}
      placeholder="Service Status"
      isCombineLeft
      {...props}
    />
  )

  const renderMultiListingIdSelector = (
    <MultiListingIdSelector
      onOptionChange={(listingId: number[]) => onListingIds(listingId)}
      defaultTenant={defaultTenant}
      tenants={tenants}
      isCombineSelector
      {...props}
    />
  )
  return (
    <>
      {isDesktop || isLaptop ? (
        <Space wrap size={[0, 6]} style={{ display: 'flex' }}>
          {renderMultTenantSelector}
          <LineBreak />
          {renderMultiListingTitleSelector}
          <LineBreak />
          {renderMultiListingIdSelector}
          <LineBreak />
          {renderMultiOptionSelector}
          <ButtonIcon icon={magnifyBlackSVG} loading={props.loading} fetchData={onFetchListings} />
        </Space>
      ) : (
        <Row gutter={[8, 8]}>
          <Col xs={24} md={12}>
            {renderMultTenantSelector}
          </Col>
          <Col xs={24} md={12}>
            {renderMultiListingTitleSelector}
          </Col>
          <Col xs={24} md={12}>
            {renderMultiListingIdSelector}
          </Col>
          <Col xs={24} md={12}>
            {renderMultiOptionSelector}
          </Col>
          <Col span={24}>
            <Button block type="default" disabled={props.loading} onClick={onFetchListings}>
              {searchText}
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}

export default ListingAllSelectors
