import { Collapse, List, Pagination, Skeleton } from 'antd'

import { NewListing } from '../../../models/listing'
import React from 'react'
import { PaginationMeta } from '../../../models/pagination'
import HandlePrice from '../functions/price'

interface props {
  loading: boolean
  listings: NewListing[]
  pagination: PaginationMeta
  onPermission: (listing: NewListing) => React.ReactNode
  onLoitering: (listing: NewListing) => React.ReactNode
  onConnectivity: (listing: NewListing) => React.ReactNode
  onPaginationChange: (page: number) => void
}

const ListingCollapse: React.FC<props> = ({
  loading,
  listings,
  pagination,
  onPermission,
  onLoitering,
  onConnectivity,
  onPaginationChange,
}) => {
  return (
    <Skeleton loading={loading}>
      <Collapse accordion bordered={false} expandIconPosition="right">
        {listings.map((listing) => (
          <Collapse.Panel
            key={listing.id}
            header={
              <div>
                {listing.title} {onConnectivity(listing)}
                <div style={{ opacity: 0.6 }}> ID: {listing.id}</div>
              </div>
            }
          >
            <List
              dataSource={[listing]}
              renderItem={(listing) => (
                <>
                  <List.Item actions={[listing.charger ? listing?.charger.chargePointSerialNumber : null]}>
                    <List.Item.Meta key={`${listing.id}-Charger Serial Number`} title="Charger Serial Number" />
                  </List.Item>
                  <List.Item actions={[listing.charger ? listing?.charger.chargingType : null]}>
                    <List.Item.Meta key={`${listing.id}-Charging Type`} title="Charging Type" />
                  </List.Item>
                  <List.Item actions={[listing.connector ? listing.connector.connectorId : null]}>
                    <List.Item.Meta key={`${listing.id}-Connector Id`} title="Connector Id" />
                  </List.Item>
                  <List.Item actions={[listing.tenant?.displayName || listing.tenant?.name]}>
                    <List.Item.Meta key={`${listing.id}-Tenant`} title="Tenant" />
                  </List.Item>
                  <List.Item actions={[listing.connector ? listing.connector.serviceStatus : null]}>
                    <List.Item.Meta key={`${listing.id}-service-status`} title="Service Status" />
                  </List.Item>
                  <List.Item actions={[<HandlePrice listing={listing} />]}>
                    <List.Item.Meta key={`${listing.id}-General Price`} title="General Price" />
                  </List.Item>
                  <List.Item actions={[<HandlePrice listing={listing} term="preferredPrice" />]}>
                    <List.Item.Meta key={`${listing.id}-Preferred Price`} title="Preferred Price" />
                  </List.Item>
                  <List.Item actions={[onPermission(listing)]}>
                    <List.Item.Meta key={`${listing.id}-Permission`} title="Permission" />
                  </List.Item>
                  <List.Item actions={[onLoitering(listing)]}>
                    <List.Item.Meta key={`${listing.id}-Loitering`} title="Loitering" />
                  </List.Item>
                  <List.Item actions={[listing.smartCharging]}>
                    <List.Item.Meta key={`${listing.id}-Smart Charging`} title="Smart Charging" />
                  </List.Item>
                </>
              )}
            />
          </Collapse.Panel>
        ))}
      </Collapse>
      <Pagination
        current={pagination.currentPage}
        onChange={onPaginationChange}
        total={pagination?.totalEntries}
        showQuickJumper={false}
        showSizeChanger={false}
        showTitle={true}
        style={{ textAlign: 'center' }}
      />
    </Skeleton>
  )
}

export default ListingCollapse
