import styled from 'styled-components'
import { theme } from '../theme'

interface props {
  isCombine?: boolean
}

interface styleProps {
  isCombineRight?: boolean
  isCombineLeft?: boolean
}

export const SelectorWrapper = styled.div`
  .ant-select,
  .ant-btn {
    margin-bottom: 8px;
  }
`

export const MultiOptionSelectorContainer = styled.div<props>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  flex-direction: row;
  position: relative;
  min-width: 213px;

  .ant-select-selector {
    background: ${(props) => props.theme.colors.cultered};
  }

  svg.mangifyIcon {
    z-index: 1;
    position: absolute;
    top: 47%;
    right: 11px;
    left: 14px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all 0.3s;
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    left: 35px;
    font-weight: 400;
  }

  .ant-select-selection-item-content {
    max-width: 170px;
  }

  .ant-select-selection-item .ant-checkbox,
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    display: none;
  }

  .ant-select.ant-select-focused.ant-select-multiple.ant-select-allow-clear.ant-select-open.ant-select-show-search
    .ant-select-selection-placeholder {
    color: ${theme.colors.lightGray};
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: ${(props) => (props.isCombine ? '0' : '48px')};
    border-left-color: transparent;
    border-right-color: transparent;
  }
  .ant-select-customize-input .ant-select-selector {
    border-radius: ${(props) => (props.isCombine ? '0' : '48px')};
    border-right-width: 0;
    border-left-width: 0;
  }
`

export const MultiTenantSelectorContainer = styled.div<styleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  flex-direction: row;
  position: relative;
  min-width: 240px;

  .ant-select-selector {
    background: ${(props) => props.theme.colors.cultered};
  }

  svg.tenantSelectorIcon {
    z-index: 2;
    position: absolute;
    top: 47%;
    right: 11px;
    left: 14px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all 0.3s;
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    left: 37px;
    font-weight: 400;
  }

  .ant-select-selection-item-content {
    max-width: 170px;
  }

  .ant-select-selection-item .ant-checkbox,
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    display: none;
  }

  .ant-select.ant-select-focused.ant-select-multiple.ant-select-allow-clear.ant-select-open.ant-select-show-search
    .ant-select-selection-placeholder {
    color: #dedede;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: ${(props) => (props.isCombineRight ? '48px 0 0 48px' : '48px')};
    border-right-width: ${(props) => (props.isCombineRight ? 0 : '1px')};
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #30d7ba !important;
    box-shadow: none !important;
  }
`

export const MultiOptionSelectorWrapper = styled.div<styleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  flex-direction: row;
  position: relative;
  min-width: 330px;

  .ant-select-clear {
    right: 52px;
  }

  .ant-select-selector {
    background: ${(props) => props.theme.colors.cultered};
  }

  svg.mangifyIcon {
    z-index: 1;
    position: absolute;
    top: 47%;
    right: 11px;
    left: 14px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all 0.3s;
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    left: 35px;
    font-weight: 400;
  }

  .ant-select-selection-item-content {
    max-width: 200px;
  }

  .ant-select-selection-item .ant-checkbox,
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    display: none;
  }

  .ant-select.ant-select-focused.ant-select-multiple.ant-select-allow-clear.ant-select-open.ant-select-show-search
    .ant-select-selection-placeholder {
    color: ${theme.colors.lightGray};
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: ${(props) => (props.isCombineLeft ? '0 48px 48px 0' : '48px')};
    border-left-color: transparent;
    /* TODO: add a prop to this for future multiOption selector */
    /* border-right-color: transparent; */
  }
`
