import { useState, useRef, useEffect } from 'react'
import { Divider, Input, Select, Space, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import type { InputRef } from 'antd'

import { CreateChannelPartner, GetChannelPartners } from '../../services/data-provider/channel-partner'
import { PartnerRef } from '../../models/partner'
import { renderFormatMessage } from '../../helpers/intl'

interface props {
  channelPartner?: PartnerRef
  onOptionChange: (selectedPartner?: PartnerRef) => void
  disabled: boolean
}

export const ChannelPartnerSelector: React.FC<props> = ({ channelPartner, onOptionChange, disabled }) => {
  const [partners, setPartners] = useState<PartnerRef[]>([])
  const [name, setName] = useState('')
  const inputRef = useRef<InputRef>(null)

  const defaultP = channelPartner
    ? { label: channelPartner?.name, value: channelPartner?.name, id: channelPartner?.id }
    : null

  useEffect(() => {
    GetChannelPartners().then((resp) => setPartners(resp))
  }, [])

  const handleSelectChange = (selectPartner: any) => {
    const findPartner = partners.find((p) => p.name === selectPartner)
    onOptionChange(findPartner)
  }

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    CreateChannelPartner(name).then((newPartner) => {
      // setPartners([...partners, { id: partners.length == 0 ? newPartner.id : newPartner.id, name }])
      setPartners([...partners, { id: newPartner.id, name: newPartner.name }])
    })
    setName('')
    setTimeout(() => {
      inputRef.current?.focus()
    }, 500)
  }

  const checkDecuplicate = () => {
    const filterName = partners.filter((p) => p.name.toLowerCase() === name.toLowerCase())

    if (name.length === 0) {
      return true
    } else if (filterName.length !== 0) {
      return true
    } else {
      return false
    }
  }

  const options = partners.map((partner) => ({ label: partner.name, value: partner.name, id: partner.id }))

  return (
    <Select
      showSearch
      allowClear
      disabled={disabled}
      defaultValue={defaultP}
      placeholder="Add new channel partner"
      onChange={handleSelectChange}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space wrap style={{ padding: '0 8px 4px' }}>
            <Input
              allowClear
              placeholder="Enter new partner"
              ref={inputRef}
              value={name}
              onChange={onNameChange}
            />
            <Button type="primary" disabled={checkDecuplicate()} icon={<PlusOutlined />} onClick={addItem}>
              {renderFormatMessage('dashboard.text.add', 'Add')}
            </Button>
          </Space>
        </>
      )}
      options={options}
    />
  )
}
