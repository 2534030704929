import styled from 'styled-components'
import { theme } from '../theme'

interface props {
  selected: boolean
}

interface buttonProps {
  disabled: boolean
}

export const Heading = styled.div`
  font-size: 18px;
  font-weight: 400;
  font-family: ${theme.fontFamily.regularFontFamily};
`

export const FooterLayout = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 9px;

  .ant-btn-default {
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};
    border-color: ${theme.colors.lightGray};
    box-shadow: none;
    text-shadow: none;
    height: 38px;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-align: center;
    font-family: ${theme.fontFamily.regularFontFamily};
    width: 50%;
  }

  .ant-btn-default:hover {
    background-color: ${theme.colors.white};
    border-color: ${theme.colors.lightGray};
  }

  .ant-btn-primary {
    box-shadow: none;
    text-shadow: none;
    height: 38px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: center;
    font-family: ${theme.fontFamily.regularFontFamily};
    width: 50%;
  }

  .ant-btn-primary:hover {
    background-color: ${theme.colors.neonGreenHover};
    border-color: ${theme.colors.neonGreenHover};
  }

  .red {
    background-color: ${theme.colors.dangerPrimary};
    border-color: ${theme.colors.dangerPrimary};
    color: ${theme.colors.white};

    :hover,
    :focus {
      background-color: ${theme.colors.dangerPrimary};
      border-color: ${theme.colors.dangerPrimary};
      color: ${theme.colors.white};
    }
  }
`

export const ButtonTextSpacing = styled.span<buttonProps>`
  margin-right: 5px;
  text-shadow: none;
  opacity: ${(buttonProps) => (buttonProps.disabled ? '0.15' : '1')};
`

export const Body = styled.div`
  display: flex;
  align-items: center;
  margin-top: -8px;

  .tenant-select {
    width: 240px;
    margin-right: 14px;
    margin-left: 2px;
    margin-bottom: 16px;
  }

  .role-select {
    width: 120px;
    margin-right: 14px;
    margin-left: 2px;
    margin-bottom: 16px;
  }

  .ant-form-item-label > label {
    color: ${theme.colors.darkGray};
    letter-spacing: 0.05em;
  }

  .switch .ant-form-item-label > label {
    color: ${theme.colors.darkGray};
    letter-spacing: 0.05em;
    margin-top: 8px;
  }

  .ant-select-selection-item {
    background-color: transparent;
  }

  .ant-select-selection-item .ant-checkbox,
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    display: none;
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: ${theme.colors.neonGreen} !important;
    box-shadow: none !important;
  }

  .ant-checkbox + span {
    padding-left: 0px;
    max-width: 190px;
    overflow: hidden;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-search
    > .ant-select-selection-item
    > div
    > label
    > span {
    color: black !important;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-search
    > .ant-select-selection-item
    > div
    > label
    > span
    > svg {
    display: none;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-item
    > div
    > .ant-checkbox-wrapper
    > span {
    color: black !important;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-item
    > div
    > .ant-checkbox-wrapper
    > span
    > svg {
    display: none;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-item
    > div
    > .ant-checkbox-wrapper
    > span
    > button {
    display: none;
  }
`

export const TenantInputBody = styled.div`
  max-height: 225px;
  overflow-y: scroll;
  padding-top: 21px;

  // show scrollbar
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 11px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 11px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar-track {
    background-color: ${theme.colors.white};
    border-radius: 8px;
  }
`

export const SvgSpacing = styled.span`
  svg {
    margin-right: 5px;
  }
`

export const SwitchLabel = styled.span`
  color: ${theme.colors.darkGray};
`

export const DropdownWrapper = styled.div`
  .ant-checkbox,
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    display: none;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${theme.colors.neonGreen};
  border-bottom: solid 1px ${theme.colors.lightGray};
  min-width: 100%;

  label > .ant-checkbox-checked {
    padding-left: 10px;
    margin-top: -5px;
  }

  label > span {
    color: ${theme.colors.neonGreen} !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    width: 100%;
  }

  label > span > button {
    background-color: transparent !important;
    border: none;
    padding: 10px 0px 10px 10px !important;
  }
`

export const UnselectedDropdownWrapper = styled.div`
  .ant-checkbox,
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    display: none;
  }
`

export const DriverSvgSpacing = styled.span`
  svg {
    margin-bottom: -5px;
    margin-left: 5px;
    height: 18px;
    width: 18px;
  }
`

export const RolesDropdownWrapper = styled.span<props>`
  color: ${(props) => (props.selected ? theme.colors.neonGreen : 'black')};
  font-weight: 400;
`

export const RolesSelectWrapper = styled.div`
  .ant-select > .ant-select-selector > .ant-select-selection-item > span {
    color: black !important;
  }
`
