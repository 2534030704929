import { Button, Col, Form, Input, message, Row, Space } from 'antd'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { labelOnTheLeftField, labelOnTheRightField, layout, tailLayout } from '../../atom/form/page-layout'
import { renderFormatMessage } from '../../helpers/intl'
import { Billing } from '../../models/billing'
import { SwtchError } from '../../models/error'
import { RemittanceObject } from '../../models/remittance'
import { Tenant } from '../../models/tenant'
import { UpdateTenantBilling } from '../../services/data-provider/tenants'
import { theme } from '../../theme'
import { AlertError } from '../error'
import { MustBeNumerical } from '../rules/rules'
import { RemittanceSelector } from '../selector/remittance-selector'
import { FormContainer } from '../../atom/form/form-layout'

interface props {
  tenant: Tenant
  onSave: (tenant: Tenant) => void
}

export const TenantTabBilling: React.FC<props> = ({ tenant, onSave }) => {
  // @ts-ignore
  const { tenantId } = useParams()

  const [billing, setBilling] = useState<Billing>()
  const [loading, setLoading] = useState<boolean>(false)
  const [remittance, setRemittance] = useState<RemittanceObject>()
  const [disabled, setDisabled] = useState(true)
  const [error, setError] = useState<SwtchError>()
  const [form] = Form.useForm()

  const onFinish = (values: Billing) => {
    const billingData: Billing = {
      contactName: values['contactName'],
      email: values['email'],
      phone: values['phone'],
      notes: values['notes'],
      remittanceType: remittance,
    }

    setLoading(true)
    UpdateTenantBilling(tenantId, billingData)
      .then((resp) => {
        setBilling({
          contactName: resp.contactName,
          email: resp.email,
          phone: resp.phone,
          notes: resp.notes,
          remittanceType: resp.remittanceType,
        })
        message.success('Billing has been updated!')
      })
      .catch((err) => setError(err))
      .finally(() => {
        setLoading(false)
        setDisabled(true)
      })
  }

  const initialValues = {
    contactName: tenant.billing?.contactName,
    email: tenant.billing?.email,
    phone: tenant.billing?.phone,
    notes: tenant.billing?.notes,
    remittance: tenant.billing?.remittanceType,
  }
  return (
    <>
      <AlertError error={error} />
      <FormContainer>
        <Form {...layout} form={form} onFinish={onFinish} initialValues={initialValues}>
          <Row justify="start">
            <Col span={12}>
              <Form.Item name="contactName" label="Billing Contact Name" {...labelOnTheLeftField}>
                <Input disabled={disabled || loading} placeholder="John Doe" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="title" label="Title" {...labelOnTheRightField}>
                <Input disabled={disabled || loading} placeholder="Job title" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item name="email" label="Email" rules={[{ type: 'email' }]} {...labelOnTheLeftField}>
                <Input disabled={disabled} placeholder="johndoe@gmail.com" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="phone" label="Phone" rules={[MustBeNumerical]} {...labelOnTheRightField}>
                <Input disabled={disabled} placeholder="Enter phone number" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name="remittance" label="Remittance Type" rules={[{ required: false }]}>
            <RemittanceSelector
              disabled={disabled}
              defaultRemittance={tenant.billing?.remittanceType || billing?.remittanceType}
              onOptionChange={(selectedRemittance) => setRemittance(selectedRemittance)}
            />
          </Form.Item>

          <Form.Item name="notes" label={renderFormatMessage('dashboard.tenantPage.notes', 'Notes')}>
            <Input.TextArea disabled={disabled} showCount maxLength={255} style={{ height: theme.space[6] }} />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Space>
              <Button htmlType="submit" type="primary" disabled={disabled}>
                {renderFormatMessage('dashboard.text.save', 'Save')}
              </Button>

              {disabled ? (
                <Button htmlType="button" onClick={() => setDisabled(!disabled)} disabled={!disabled} type="primary">
                  {renderFormatMessage('dashboard.tenantPage.overviewTab.modifybtn', 'Modify')}
                </Button>
              ) : (
                <Button htmlType="button" onClick={() => setDisabled(!disabled)} danger>
                  {renderFormatMessage('dashboard.tenantPage.overviewTab.cancelBtn', 'Cancel')}
                </Button>
              )}
            </Space>
          </Form.Item>
        </Form>
      </FormContainer>
    </>
  )
}
