import { Alert, Button, Col, Divider, Form, Input, message, Row, Space, Spin } from 'antd'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { SwtchError } from '../../models/error'
import { Installer } from '../../models/installer'
import { Tenant, TenantContactInfo } from '../../models/tenant'
import { PropertyMgmtComp } from '../../models/property-management'

import { PropertyMgmtCompSelector } from '../selector/property-management-company-selector'
import { InstallerSelector } from '../selector/installer-selector'

import { UpdateTenantContacts } from '../../services/data-provider/tenants'

import { labelOnTheLeftField, labelOnTheRightField, layout } from '../../atom/form/page-layout'
import { renderFormatMessage } from '../../helpers/intl'

import { useContactTranslation } from '../../hooks/translation/useContactTranslation'
import { MustBeNumerical } from '../rules/rules'
import { AlertError } from '../error'
import { FormContainer } from '../../atom/form/form-layout'

interface props {
  tenant: Tenant
}

export const TenantTabContact: React.FC<props> = ({ tenant }) => {
  // @ts-ignore
  const { tenantId }: string = useParams()

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<SwtchError>()
  const [disabled, setDisabled] = useState(true)
  const [selectedProperty, setSelectedProperty] = useState<PropertyMgmtComp>()
  const [selectedInstaller, setInstaller] = useState<Installer>()

  const [accountContact] = tenant.tenantContacts?.filter((c) => c.typeOfContact === 'account')
  const [onSiteContact] = tenant.tenantContacts?.filter((c) => c.typeOfContact === 'onsite')
  const [installerContact] = tenant.tenantContacts?.filter((c) => c.typeOfContact === 'installer')

  const {
    accountContactText,
    onSiteContactText,
    installerContactText,
    nameText,
    titleText,
    phoneNumberText,
    emailText,
    typeofContactText,
    installerText,
    locationMissingText,
    contactUpdatedText,
    propertyManagementCompanyText,
    validEmailText,
    masterAccountText,
    channelPartnerText,
  } = useContactTranslation()

  const initialValues = {
    masterAccount: tenant.masterAccount?.name,
    channelPartner: tenant.channelPartner?.name,
    account: {
      name: accountContact?.namePrimary,
      email: accountContact?.email,
      phoneNumber: accountContact?.phoneNumber,
      title: accountContact?.title,
      propertyManagementCompany: accountContact?.propertyManagementCompany,
      typeOfContact: accountContact?.typeOfContact,
    },
    onsite: {
      name: onSiteContact?.namePrimary,
      email: onSiteContact?.email,
      phoneNumber: onSiteContact?.phoneNumber,
      title: onSiteContact?.title,
      typeOfContact: onSiteContact?.typeOfContact,
      evPanelLocation: onSiteContact?.evPanelLocation,
    },
    installer: {
      namePrimary: installerContact?.namePrimary,
      email: installerContact?.email,
      phoneNumber: installerContact?.phoneNumber,
      title: installerContact?.title,
      typeOfContact: installerContact?.typeOfContact,
      install: installerContact?.installer,
      nameSecondary: installerContact?.nameSecondary,
      emailSecondary: installerContact?.emailSecondary,
      phoneNumberSecondary: installerContact?.phoneNumberSecondary,
      titleSecondary: installerContact?.titleSecondary,
    },
  }

  const onFinish = async (values: { [key: string]: TenantContactInfo }) => {
    values.account.propertyManagementCompany = selectedProperty
    values.installer.installer = selectedInstaller

    const contactInfoData: TenantContactInfo[] = setupContactData(values)

    setError(undefined)
    setLoading(true)
    UpdateTenantContacts(tenantId, contactInfoData)
      .then(() => message.success(contactUpdatedText))
      .catch((err: SwtchError) => setError(err))
      .finally(() => setLoading(false))
  }

  if (!tenant.location) {
    return <Alert message={locationMissingText} type="error" banner={true} />
  }

  return (
    <Spin spinning={loading}>
      <AlertError error={error} />

      <Divider>{accountContactText}</Divider>
      <FormContainer>
        <Form {...layout} name={`tenant-${tenant?.id}-contact`} onFinish={onFinish} initialValues={initialValues}>
          <Form.Item
            label={typeofContactText}
            name={['account', 'typeOfContact']}
            initialValue="account"
            hidden={true}
          />
          <Form.Item label={masterAccountText} name="masterAccount">
            <Input disabled placeholder="Master Account" />
          </Form.Item>
          <Row justify="start">
            <Col span={12}>
              <Form.Item label={nameText} name={['account', 'name']} {...labelOnTheLeftField}>
                <Input placeholder={nameText} disabled={disabled} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={titleText} name={['account', 'title']} {...labelOnTheRightField}>
                <Input placeholder={titleText} disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label={phoneNumberText}
                name={['account', 'phoneNumber']}
                rules={[MustBeNumerical]}
                {...labelOnTheLeftField}
              >
                <Input placeholder={phoneNumberText} disabled={disabled} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={emailText}
                name={['account', 'email']}
                rules={[{ type: 'email', required: false, pattern: new RegExp('/S+@S+.S+/'), message: validEmailText }]}
                {...labelOnTheRightField}
              >
                <Input placeholder={emailText} disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label={propertyManagementCompanyText} name={['account', 'propertyManagementCompany']}>
            <PropertyMgmtCompSelector
              defaultPropMgmtComp={accountContact?.propertyManagementCompany}
              onOptionChange={(selectedProperty) => setSelectedProperty(selectedProperty)}
              disabled={disabled}
            />
          </Form.Item>

          <Divider>{onSiteContactText}</Divider>

          <Row>
            <Form.Item
              label={typeofContactText}
              name={['onsite', 'typeOfContact']}
              initialValue="onsite"
              hidden={true}
            />
            <Col span={12}>
              <Form.Item label={nameText} name={['onsite', 'name']} {...labelOnTheLeftField}>
                <Input placeholder={nameText} disabled={disabled} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={titleText} name={['onsite', 'title']} {...labelOnTheRightField}>
                <Input placeholder={titleText} disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label={phoneNumberText}
                name={['onsite', 'phoneNumber']}
                rules={[MustBeNumerical]}
                {...labelOnTheLeftField}
              >
                <Input placeholder={phoneNumberText} disabled={disabled} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={emailText}
                name={['onsite', 'email']}
                rules={[{ type: 'email', required: false, pattern: new RegExp('/S+@S+.S+/'), message: validEmailText }]}
                {...labelOnTheRightField}
              >
                <Input placeholder={emailText} disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="EV Panel Location" name={['onsite', 'evPanelLocation']}>
            <Input placeholder="EV Panel Location" disabled={disabled} />
          </Form.Item>

          <Divider>{installerContactText}</Divider>

          <Form.Item
            label={typeofContactText}
            name={['installer', 'typeOfContact']}
            initialValue="installer"
            hidden={true}
          />

          <Form.Item label={installerText} name={['installer', 'install']}>
            <InstallerSelector
              disabled={disabled}
              defaultInstaller={installerContact?.installer}
              onOptionChange={(selectedInstaller) => setInstaller(selectedInstaller)}
            />
          </Form.Item>
          <Row>
            <Col span={12}>
              <Form.Item label={nameText} name={['installer', 'namePrimary']} {...labelOnTheLeftField}>
                <Input placeholder={nameText} disabled={disabled} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={titleText} name={['installer', 'title']} {...labelOnTheRightField}>
                <Input placeholder={titleText} disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label={phoneNumberText}
                name={['installer', 'phoneNumber']}
                rules={[MustBeNumerical]}
                {...labelOnTheLeftField}
              >
                <Input placeholder={phoneNumberText} disabled={disabled} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={emailText}
                name={['installer', 'email']}
                rules={[{ type: 'email', required: false, pattern: new RegExp('/S+@S+.S+/'), message: validEmailText }]}
                {...labelOnTheRightField}
              >
                <Input placeholder={emailText} disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label={channelPartnerText} name="channelPartner">
            <Input disabled placeholder={channelPartnerText} />
          </Form.Item>
          <Row>
            <Col span={12}>
              <Form.Item label={nameText} name={['installer', 'nameSecondary']} {...labelOnTheLeftField}>
                <Input placeholder={nameText} disabled={disabled} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={titleText} name={['installer', 'titleSecondary']} {...labelOnTheRightField}>
                <Input placeholder={titleText} disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label={phoneNumberText}
                name={['installer', 'phoneNumberSecondary']}
                rules={[MustBeNumerical]}
                {...labelOnTheLeftField}
              >
                <Input placeholder={phoneNumberText} disabled={disabled} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={emailText}
                name={['installer', 'emailSecondary']}
                rules={[{ type: 'email', required: false, pattern: new RegExp('/S+@S+.S+/'), message: validEmailText }]}
                {...labelOnTheRightField}
              >
                <Input placeholder={emailText} disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item wrapperCol={{ offset: 0 }}>
            <Space size={[8, 16]} wrap>
              <Button htmlType="submit" type="primary" disabled={disabled} block={true}>
                {renderFormatMessage('dashboard.text.save', 'Save')}
              </Button>

              {disabled ? (
                <Button
                  htmlType="button"
                  onClick={() => setDisabled(!disabled)}
                  block={true}
                  disabled={!disabled}
                  type="primary"
                >
                  {renderFormatMessage('dashboard.text.modify', 'Modify')}
                </Button>
              ) : (
                <Button htmlType="button" onClick={() => setDisabled(!disabled)} block={true} danger>
                  {renderFormatMessage('dashboard.text.cancel', 'Cancel')}
                </Button>
              )}
            </Space>
          </Form.Item>
        </Form>
      </FormContainer>
    </Spin>
  )
}
const setupContactData = (values: { [key: string]: TenantContactInfo }) => {
  const contactInfoData: any[] = []

  const addData = (contactProperty: string) => {
    contactInfoData.push({
      namePrimary: values[contactProperty]?.namePrimary || null,
      title: values[contactProperty]?.title || null,
      phoneNumber: values[contactProperty]?.phoneNumber || null,
      email: values[contactProperty]?.email || null,
      nameSecondary: values[contactProperty]?.nameSecondary || null,
      titleSecondary: values[contactProperty]?.titleSecondary || null,
      phoneNumberSecondary: values[contactProperty]?.phoneNumberSecondary || null,
      emailSecondary: values[contactProperty]?.emailSecondary || null,
      typeOfContact: values[contactProperty]?.typeOfContact || null,
      evPanelLocation: values[contactProperty]?.evPanelLocation || null,
      propertyManagementCompanyId: values[contactProperty]?.propertyManagementCompany?.id || null,
      installerId: values[contactProperty]?.installer?.id || null,
    })
  }

  Object.keys(values).map((value) => {
    if (value.includes('account')) {
      addData('account')
    } else if (value.includes('onsite')) {
      addData('onsite')
    } else if (value.includes('installer')) {
      addData('installer')
    }
    return value
  })
  return contactInfoData
}
