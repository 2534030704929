import { useState, useRef, useEffect } from 'react'
import { Divider, Input, Select, Space, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import type { InputRef } from 'antd'

import { renderFormatMessage } from '../../helpers/intl'
import { Installer } from '../../models/installer'
import { CreateInstaller, GetInstallers } from '../../services/data-provider/installer'

interface props {
  defaultInstaller?: Installer
  onOptionChange: (selectedInstaller?: Installer) => void
  disabled: boolean
}

export const InstallerSelector: React.FC<props> = ({ defaultInstaller, onOptionChange, disabled }) => {
  const [installers, setInstallers] = useState<Installer[]>([])
  const [name, setName] = useState('')
  const inputRef = useRef<InputRef>(null)

  const defaultP = defaultInstaller
    ? { label: defaultInstaller?.name, value: defaultInstaller?.name, id: defaultInstaller?.id }
    : null

  useEffect(() => {
    GetInstallers().then((resp) => setInstallers(resp))
  }, [])

  const handleSelectChange = (selectedInstaller: any) => {
    const findInstaller = installers.find((p) => p.name === selectedInstaller)
    onOptionChange(findInstaller)
  }

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    CreateInstaller(name).then((newProperty) => {
      setInstallers([...installers, { id: newProperty.id, name: newProperty.name }])
    })
    setName('')
    setTimeout(() => {
      inputRef.current?.focus()
    }, 500)
  }

  const checkDecuplicate = () => {
    const filterName = installers.filter((installer) => installer.name.toLowerCase() === name.toLowerCase())

    if (name.length === 0) {
      return true
    } else if (filterName.length !== 0) {
      return true
    } else {
      return false
    }
  }

  const options = installers?.map((installer) => ({
    label: installer.name,
    value: installer.name,
    id: installer.id,
  }))

  return (
    <Select
      showSearch
      allowClear
      disabled={disabled}
      defaultValue={defaultP}
      placeholder="Select an installer"
      onChange={handleSelectChange}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space wrap style={{ padding: '0 8px 4px' }}>
            <Input
              allowClear
              placeholder="Add new installer"
              ref={inputRef}
              value={name}
              onChange={onNameChange}
              style={{ width: '100%' }}
            />
            <Button type="primary" disabled={checkDecuplicate()} icon={<PlusOutlined />} onClick={addItem}>
              {renderFormatMessage('dashboard.text.add', 'Add')}
            </Button>
          </Space>
        </>
      )}
      options={options}
    />
  )
}
