import { ConfigProvider, Table } from 'antd'
import moment from 'moment'
import { useMediaQuery } from 'react-responsive'

import { Transactionv2 } from '../../../../models/transaction'
import { DateContainer } from '../../ContainerStyles/homepageStyles'
import { EmptyData } from '../../../../components/redesign/emptyData'
import { renderFormatMessage } from '../../../../helpers/intl'
import { styled } from '../../../../theme'

const TransactionTableUserNameWrapper = styled.div`
  span {
    display: block;
    text-transform: capitalize;
  }
  span:first-of-type {
    margin-bottom: 10px;
  }

  span:nth-of-type(2) {
    opacity: 0.6;
  }
`

const renderState = (state: string) => {
  let stateClass = undefined
  switch (state.toLowerCase()) {
    case 'free':
      stateClass = 'free'
      break
    case 'confirmed':
      stateClass = 'confirmed'
      break
    case 'no-reservation':
      stateClass = 'no-reservation'
      break
    case 'cancelled':
      stateClass = 'cancelled'
      break
    case 'errored':
      stateClass = 'errored'
      break
    case 'paid':
      stateClass = 'paid'
      break
    case 'preauthorized':
      stateClass = 'preauthorized'
      break
    case 'pending_payment':
      stateClass = 'pending_payment'
      break
    case 'checked_in':
      stateClass = 'checked_in'
      break
  }

  return stateClass
}

const renderStateText = (state: string) => {
  let text = undefined

  switch (state.toLowerCase()) {
    case 'confirmed':
      text = renderFormatMessage('dashboard.transaction.confirmed', 'Confirmed')
      break

    case 'free':
      text = renderFormatMessage('dashboard.transaction.free', 'Free')
      break

    case 'pending_payment':
      text = renderFormatMessage('dashboard.transaction.pending_payment', 'Pending Payment')
      break

    case 'cancelled':
      text = renderFormatMessage('dashboard.transaction.canceled', 'Canceled')
      break

    case 'errored':
      text = renderFormatMessage('dashboard.transaction.errored', 'Errored')
      break

    case 'paid':
      text = renderFormatMessage('dashboard.transaction.paid', 'Paid')
      break

    case 'pre-authorized':
      text = renderFormatMessage('dashboard.transaction.preauthorized', 'Pre-authorized')
      break

    case 'no-reservation':
      text = renderFormatMessage('dashboard.transaction.noReservation', 'No Reservation')
      break
    case 'checked_in':
      text = 'Checked In'
      break
  }

  return text
}

export const secondsToTime = (secs: number) => {
  var hours = Math.floor(secs / (60 * 60))

  var divisor_for_minutes = secs % (60 * 60)
  var minutes = Math.floor(divisor_for_minutes / 60)

  var divisor_for_seconds = divisor_for_minutes % 60
  var seconds = Math.ceil(divisor_for_seconds)

  var obj = {
    h: hours,
    m: minutes,
    s: seconds,
  }
  return obj
}

const renderTZ = (dateStr: string) => {
  const dateArr = dateStr.split(' ')
  let tzPart = dateArr[1].substring(dateArr[1].indexOf('-') + 1)
  let timeZone = `(-${tzPart.substring(0, 2) + ':' + tzPart.substring(2)})`
  return timeZone
}

let columns: any = [
  {
    title: renderFormatMessage('dashboard.transactionsPage.table.transactionId', 'Transaction ID'),
    // dataIndex: 'txnId',
    defaultSortOrder: 'descend',
    sorter: (a: Transactionv2, b: Transactionv2) => a.txnId - b.txnId,
    render: (t: Transactionv2) => {
      return <span className="paragraph-02-regular">{`#${t.txnId}`}</span>
    },
    align: 'left' as 'left',
  },
  {
    title: renderFormatMessage('dashboard.transactionsPage.table.userName', 'User Name'),
    sorter: (a: Transactionv2, b: Transactionv2) => a.username.localeCompare(b.username),
    render: (t: Transactionv2) => {
      return (
        <TransactionTableUserNameWrapper>
          <span className="paragraph-02-regular">{t.username}</span>
          {t.startMethod == null ? (
            <p className="paragraph-04-regular isUndefined">...</p>
          ) : (
            <span className="paragraph-04-light">{t.startMethod}</span>
          )}
        </TransactionTableUserNameWrapper>
      )
    },
    align: 'left' as 'left',
  },
  {
    title: renderFormatMessage('dashboard.transactionsPage.table.connected', 'Connected'),
    sorter: (a: Transactionv2, b: Transactionv2) => moment(a.connected).unix() - moment(b.connected).unix(),
    render: (t: Transactionv2) => {
      return t.connected === null ? (
        <p className="paragraph-04-regular isUndefined">...</p>
      ) : (
        <DateContainer>
          <p className="paragraph-02-regular">{t.connected.split(' ')[0].replaceAll('-', '/')}</p>
          <p className="paragraph-04-light">{`${t.connected
            .split(' ')[1]
            .substring(0, t.connected.split(' ')[1].indexOf('-'))} ${renderTZ(t.connected)}`}</p>
        </DateContainer>
      )
    },
    align: 'left' as 'left',
  },
  {
    title: renderFormatMessage('dashboard.transactionsPage.table.disconnected', 'Disconnected'),
    sorter: (a: Transactionv2, b: Transactionv2) => moment(a.disconnected).unix() - moment(b.disconnected).unix(),
    render: (t: Transactionv2) => {
      return t.disconnected === null ? (
        <p className="paragraph-04-regular isUndefined">...</p>
      ) : (
        <DateContainer>
          <p className="paragraph-02-regular">{t.disconnected.split(' ')[0].replaceAll('-', '/')}</p>
          <p className="paragraph-04-light">{`${t.disconnected
            .split(' ')[1]
            .substring(0, t.disconnected.split(' ')[1].indexOf('-'))} ${renderTZ(t.disconnected)}`}</p>
        </DateContainer>
      )
    },
    align: 'left' as 'left',
  },
  {
    title: renderFormatMessage('dashboard.transactionsPage.table.totalEnergy', 'Total Energy'),
    sorter: (a: Transactionv2, b: Transactionv2) => {
      return a.energy - b.energy
    },
    render: (t: Transactionv2) =>
      t.state == null ? (
        <p className="paragraph-04-regular isUndefined">...</p>
      ) : (
        `${(t.energy / 1000).toFixed(2)}  kWh`
      ),
    align: 'left' as 'left',
  },
  {
    title: renderFormatMessage('dashboard.transactionsPage.table.state', 'State'),
    sorter: (a: Transactionv2, b: Transactionv2) => {
      return a.state.localeCompare(b.state)
    },
    render: (t: Transactionv2) => {
      return t.state === 'no-reservation' || t.state === 'checked_in' ? (
        <p className="paragraph-04-regular isUndefined">...</p>
      ) : (
        <span className={`state ${renderState(t.state)}`}>{renderStateText(t.state)}</span>
      )
    },
    align: 'left' as 'left',
  },
  {
    title: renderFormatMessage('dashboard.transactionsPage.table.total', 'Total'),
    sorter: (a: Transactionv2, b: Transactionv2) => {
      return a.total - b.total
    },
    render: (t: Transactionv2) => `$${(t.total / 100).toFixed(2)}`,
    align: 'left' as 'left',
  },
]

const HomePageTransactionOverviewContainer = styled.div`
  .website {
    background-color: #c8f9e8;
  }

  .mobile {
    background-color: #54dec7;
  }

  .rfid {
    background-color: #026e78;
    color: #fff;
  }

  .cancelled {
    background-color: #7d838a;
  }

  .roaming-partner {
    color: #fff;
    background-color: #000;
  }

  .state {
    text-transform: capitalize;
    border-radius: 10px;
    padding: 2.5px 10px;
    font-size: 1.1rem;
  }

  .state.confirmed {
    padding: 2.5px 7px;
  }

  .free,
  .confirmed,
  .paid,
  .checked_in {
    color: ${(props) => props.theme.colors.neonGreen};
  }

  .errored,
  .cancelled {
    color: ${(props) => props.theme.colors.dangerPrimary};
  }

  .pre-authorized,
  .pending_payment,
  .no-reservation {
    color: ${(props) => props.theme.colors.orangePeel};
  }

  .state.pre-authorized,
  .state.pending_payment,
  .state.no-reservation {
    background-color: ${(props) => props.theme.colors.orangePeel15};
  }

  .state.free,
  .state.confirmed,
  .state.paid,
  .state.checked_in {
    background-color: ${(props) => props.theme.colors.skyBlue15};
  }

  .state.errored,
  .state.cancelled {
    background-color: ${(props) => props.theme.colors.indianRed10};
  }

  .no-reservation {
    color: ${(props) => props.theme.colors.warningPrimary};
  }

  ${(props) => props.theme.breakpoints.down('xxl')} {
    .state.non-reservation {
      padding: 2px 6px;
    }

    .state.non-reservation.smallScreen {
      padding: 2px;
      font-size: 1rem;
    }
  }

  .isUndefined {
    background: rgba(255, 153, 0, 0.15);
    border-radius: 18px;
    width: 30px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: ${(props) => props.theme.colors.orangePeel};
  }
`

interface props {
  transactions: Transactionv2[]
}

export const HomePageTransactionOverview: React.FC<props> = ({ transactions }) => {
  const checkIfSmallScreen = useMediaQuery({ minWidth: 1200, maxWidth: 1250 })

  if (checkIfSmallScreen) {
    document.querySelector('.non-reservation')?.classList.add('smallScreen')
  } else {
    document.querySelector('.non-reservation')?.classList.remove('smallScreen')
  }

  return (
    <HomePageTransactionOverviewContainer>
      <ConfigProvider renderEmpty={() => <EmptyData />}>
        <Table columns={columns} dataSource={transactions} pagination={false} rowKey="txnId" />
      </ConfigProvider>
    </HomePageTransactionOverviewContainer>
  )
}
