import styledComponents, { createGlobalStyle, ThemedStyledInterface } from 'styled-components'
import { Breakpoints, createBreakpoints } from './breakpoints'

export const styled = styledComponents as ThemedStyledInterface<Theme>

// **Important** Most fit colors defined in `<root>/config/antd-theme.js`, always keep both
//               files in sync. Restart server to see changes being applied to `antd-theme.js` file.
export type Theme = typeof theme

const borderWidths = ['0', '1px', '2px', '4px', '8px']

// Typographic Scale (numbers are converted to px values)
const fontSizes = ['10px', '12px', '14px', '16px', '20px', '24px', '32px', '40px', '48px', '64px', '72px']
const lineHeights = ['18px', '22px', '26px', '34px', '40px', '50px']

const radii = ['0', '1px', '2px', '4px', '8px']

// Spacing Scale (used for margin and padding)
const space = ['0px', '4px', '8px', '16px', '32px', '64px', '128px', '256px', '512px']

const fontFamily = {
  regularFontFamily: 'AkkuratLLWeb-Regular',
  blackFontFamily: 'AkkuratLLWeb-Black',
  lightFontFamily: 'AkkuratLLWeb-Light',
}

const colors = {
  primary: '#22a891',
  yellow: '#F0AD4E',
  orange: '#FF951E',
  fadedGrayBlue: '#d0d5dd',
  blue: '#5bc0de',
  red: '#d9534f',
  red100: '#cf1322',
  gray: '#777777',
  darkBlue: '#1C84C6',
  white: '#fff',
  beightWhite: '#ffffff',
  green: '#44D333',
  green100: '#e7f5da',
  green200: '#60a902',
  green300: '#389e0d',
  transparancy: 'rgba(255,255,255,0)',
  ternary25: '#fafafa',
  ternary50: '#fafbfc',
  ternary100: '#f6f8f9',
  ternary200: '#f0f3f5',
  ternary250: '#e4eaef',
  ternary300: '#d8e1e9',
  ternary400: '#bbc7d3',
  ternary500: '#9fadbc',
  ternary600: '#8294a6',
  ternary700: '#657a90',
  ternary800: '#49617a',
  ternary900: '#2c4863',
  ternary950: '#203d5a',
  ternary1000: '#0f2e4d',
  ternary1100: '#0c243b',
  ternary1200: '#081929',

  link400: '#707bdb',
  link500: '#6673E5',
  link700: '#5a5ab4',

  neonGreenHover: '#00B799',
  highlight1: '#61d8c8',
  highlight2: '#34cfbd',
  revisedSwtchGgreen: '#06b692',
  neonGreenOpacity: 'rgba(48, 215, 186, 0.2)',
  neonGreenGradient: 'linear-gradient(181.07deg, rgba(48, 215, 186, 0.21) 50.2%, rgba(48, 215, 186, 0) 99.08%)',

  alert1000: '#fbab0b',

  grey1: '#f8f8fa',
  grey2: '#dcdde2',
  grey3: '#c9cacf',
  grey4: '#b2b2b9',
  grey5: '#9a9ba3',
  grey6: '#7a7c84',
  grey7: '#5a5d65',
  grey8: '#3a3e47',
  grey9: '#1a1f28',
  grey10: '#000',
  grey11: '#f3f3f4',
  grey12: 'rgba(0, 0, 0, 0.05)',
  softBlackHover: '#3F514D',
  blackOpacity85: '#000000d8',
  darkGray: '#7D838A',
  shadowGray: '#bec0c0',
  frenchGray: '#C1C3D0',
  cultered: '#F1F1F1',
  caribbeanCurrent: '#1C6D78',
  grayBackground: '#FAFAFA',
  lightGray: '#dedede',
  magicMint: '#b9f0d2',
  neonGreen: '#30D7BA',
  carribeanCurrent: '#1C6D78',
  softBlack: '#242e2c',
  black05: '#0000000d',
  black: '#000000',
  successPrimary: '#179942',
  successSecondary: '#cef2da',
  dangerPrimary: '#ec4747',
  dangerSecondary: '#ffd9d9',
  warningPrimary: '#ff9900',
  warningSecondary: '#f8e9c7',
  greenBase: '#179942',
  underRepair: 'rgba(222, 84, 84, 0.1)',
  active: 'rgba(100, 199, 221, 0.15)',
  skyBlue15: 'rgba(100, 199, 221, 0.15)',
  orangePeel15: 'rgba(255, 153, 0, 0.15)',
  orangePeel: '#c37500',
  indianRed10: 'rgba(222, 84, 84, 0.1)',
}
const breakpoints: Breakpoints = createBreakpoints()
export const theme = {
  breakpoints,
  colors,
  fontSizes,
  borderWidths,
  lineHeights,
  radii,
  space,
  fontFamily,
}

export const GlobalStyle = createGlobalStyle`
html,body{
  background: #f3f3f4;
  font-size: 12px;
}

.ant-page-header {
  background-color: #ffffff;
}
.am-list-item .am-list-line .am-list-content,
.am-calendar .week-panel .cell,
.am-calendar .single-month .row .cell .date-wrapper .date,
.am-calendar .shortcut-panel .item,
.am-calendar .confirm-panel .button,
.am-list-item .am-list-line .am-list-extra,
.ant-list-item .ant-list-item-main,
.ant-list-item .ant-list-item-extra,
.am-button.am-button-inline,
.am-card-body,
.am-card-header-content    {
    font-size: 12px;
    font-family: sans-serif;
}
`
