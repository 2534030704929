import {
  Charger,
  ChargerBase,
  ChargerDetails,
  ChargerRef,
  ChargerListings,
  ChargersListingsLoadMgmt,
} from '../models/charger'
import moment from 'moment'
import { mockTenant } from './tenant-mock'
import { mockListing } from './listing-mock'
import { ChargersListingsLoadMgmtResponse, ChargersListingsResponse } from '../models/http'

export const mockChargerRef: { [serialNumber: string]: ChargerRef } = {
  '1EC1-1-1824-00035': {
    id: 1,
    chargePointSerialNumber: '1EC1-1-1824-00035',
  },
  '1EC1-1-1834-00188': {
    id: 2,
    chargePointSerialNumber: '1EC1-1-1834-00188',
  },
  '1EC1-1-1834-00185': {
    id: 3,
    chargePointSerialNumber: '1EC1-1-1834-00185',
  },
  '1EC0-1-1823-00061': {
    id: 63,
    chargePointSerialNumber: '1EC0-1-1823-00061',
  },
  '1920511': {
    id: 5,
    chargePointSerialNumber: '1920511',
  },
  '1EC1-1-1834-00059': {
    id: 6,
    chargePointSerialNumber: '1EC1-1-1834-00059',
  },
  '1EC1-1-1834-00060': {
    id: 7,
    chargePointSerialNumber: '1EC1-1-1834-00060',
  },
}

export const mockCharger: { [serialNumber: string]: ChargerBase } = {
  '1EC1-1-1824-00035': {
    ...mockChargerRef['1EC1-1-1824-00035'],
    serviceStatus: 'under_repair',
    chargePointModel: 'foo',
    chargePointVendor: 'LITE-ON',
    tenant: mockTenant['10-DeanPark-Rd-Scarborough-ON-M1B3G8'],
    online: false,
    chargingType: 'Level 2',
  },
  '1EC1-1-1834-00188': {
    ...mockChargerRef['1EC1-1-1834-00188'],
    serviceStatus: 'activating',
    chargePointModel: 'foo',
    chargePointVendor: 'LITE-ON',
    tenant: mockTenant['18-Yorkville-Av-Toronto-ON-M4W2J1'],
    online: true,
    chargingType: 'DC',
  },
  '1EC1-1-1834-00185': {
    ...mockChargerRef['1EC1-1-1834-00185'],
    serviceStatus: 'service_mode',
    chargePointModel: 'foo',
    chargePointVendor: 'LITE-ON',
    tenant: mockTenant['81-NavyWharf-Ct-Toronto-ON-M5B3S2'],
    online: true,
    chargingType: 'Level 2',
  },
  '1EC0-1-1823-00061': {
    ...mockChargerRef['1EC0-1-1823-00061'],
    serviceStatus: 'service_mode',
    chargePointModel: 'foo',
    chargePointVendor: 'LITE-ON',
    tenant: mockTenant['25-Bamburgh-Ci-Scarborough-ON-M1W3W2'],
    online: true,
    chargingType: 'Level 2',
  },
  '1920511': {
    ...mockChargerRef['1920511'],
    serviceStatus: 'service_mode',
    chargePointModel: 'foo',
    chargePointVendor: 'EV-BOX',
    tenant: mockTenant['25-Bamburgh-Ci-Scarborough-ON-M1W3W2'],
    online: false,
    chargingType: 'Level 2',
  },
  '1EC1-1-1834-00059': {
    ...mockChargerRef['1EC1-1-1834-00059'],
    serviceStatus: 'service_mode',
    chargePointModel: 'foo',
    chargePointVendor: 'LITE-ON',
    tenant: mockTenant['133-Hazelton-Av-Toronto-ON-M5R0A6'],
    online: true,
    chargingType: 'DC',
  },
  '1EC1-1-1834-00060': {
    ...mockChargerRef['1EC1-1-1834-00060'],
    serviceStatus: 'in_maintenance',
    chargePointModel: 'foo',
    chargePointVendor: 'LITE-ON',
    tenant: mockTenant['344-Kitchner-Ave-West-ON-M9E8E4'],
    online: false,
    chargingType: 'Level 2',
  },
}
export const mockFullCharger = {
  ...mockCharger['1EC1-1-1824-00035'],
  connectors: [
    {
      id: 1,
      connectorId: 1,
      listing: mockListing['DP01'],
      state: 'SuspendedEV',
      lastStatusChangedAt: moment().startOf('hour').format(),
    },
    {
      id: 2,
      connectorId: 2,
      listing: mockListing['DP02'],
      state: 'Charging',
      lastStatusChangedAt: moment().startOf('hour').format(),
    },
  ],
  firmwareVersion: '2',
  chargerType: 'single-port',
  serviceStatus: 'service_mode',
  maxWatt: 1700,
  lastConnectedAt: moment().startOf('week').format(),
  lastDisconnectedAt: moment().startOf('month').format(),

  createdAt: moment().format(),
  updatedAt: moment().format(),
} as Charger

export const mockChargerById: { [chargerId: number]: Charger } = {
  1: {
    ...mockCharger['1EC1-1-1824-00035'],
    connectors: [
      {
        id: 1,
        listing: mockListing['DP01'],
        connectorId: 1,
        lastStatusChangedAt: moment().startOf('hour').format(),
        state: 'SuspendedEV',
      },
    ],
    firmwareVersion: 'v2.1.0',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: moment().startOf('month').format(),
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
  2: {
    ...mockCharger['1EC1-1-1834-00188'],
    connectors: [
      {
        id: 9,
        listing: mockListing['YK01'],
        connectorId: 1,
        lastStatusChangedAt: moment().startOf('hour').format(),
        state: 'Available',
      },
    ],
    firmwareVersion: 'v2.1.0',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: moment().startOf('month').format(),
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
  3: {
    ...mockCharger['1EC1-1-1834-00185'],
    connectors: [
      {
        id: 3,
        listing: mockListing['NA01'],
        connectorId: 1,
        lastStatusChangedAt: moment().startOf('hour').format(),
        state: 'Preparing',
      },
    ],
    firmwareVersion: 'v2.1.0',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: moment().startOf('month').format(),
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
  63: {
    ...mockCharger['1EC0-1-1823-00061'],
    connectors: [
      {
        id: 4,
        listing: mockListing['BN01'],
        connectorId: 1,
        lastStatusChangedAt: moment().startOf('hour').format(),
        state: 'Finishing',
      },
    ],
    firmwareVersion: 'v2.1.0',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: moment().startOf('month').format(),
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
  5: {
    ...mockCharger['1920511'],
    connectors: [
      {
        id: 5,
        listing: mockListing['BY01'],
        connectorId: 1,
        lastStatusChangedAt: moment().startOf('hour').format(),
        state: 'Unavailable',
      },
    ],
    firmwareVersion: 'v2.1.0',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: moment().startOf('month').format(),
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
  6: {
    ...mockCharger['1EC1-1-1834-00059'],
    connectors: [
      {
        id: 6,
        listing: mockListing['DN01'],
        connectorId: 1,
        lastStatusChangedAt: moment().startOf('hour').format(),
        state: 'Charging',
      },
      {
        id: 7,
        connectorId: 2,
        lastStatusChangedAt: moment().startOf('hour').format(),
        state: 'Faulted',
      },
    ],
    firmwareVersion: 'v2.1.0',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: moment().startOf('month').format(),
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
  7: {
    ...mockCharger['1EC1-1-1834-00060'],
    connectors: [
      {
        id: 8,
        listing: mockListing['DC01'],
        connectorId: 1,
        lastStatusChangedAt: moment().startOf('hour').format(),
        state: 'Available',
      },
    ],
    firmwareVersion: 'v2.1.0',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: moment().startOf('month').format(),
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
}

export const mockChargers = [
  {
    id: 63,
    chargePointSerialNumber: '1EC0-1-1823-00061',
  },
  {
    id: 5,
    chargePointSerialNumber: '1920511',
  },
] as ChargerRef[]

export const mockChargersDetails = [
  {
    ...mockChargerRef['1EC1-1-1824-00035'],
    phases: 'AC',
    ampere_max: 12,
    ampere_min: 12,
    ampere_fallback: 12,
    listings: [mockListing['DP01']],
  },
  {
    ...mockChargerRef['1EC1-1-1834-00188'],
    phases: 'AC',
    ampere_max: 12,
    ampere_min: 12,
    ampere_fallback: 12,
    listings: [mockListing['YK01']],
  },

  {
    ...mockChargerRef['1EC1-1-1834-00185'],
    phases: 'AC',
    ampere_max: 10,
    ampere_min: 2,
    ampere_fallback: 8,
    listings: [mockListing['NA01']],
  },
  {
    ...mockChargerRef['1EC0-1-1823-00061'],
    phases: 'AC',
    ampere_max: 200,
    ampere_min: 2,
    ampere_fallback: 50,
    listings: [mockListing['BN01']],
  },
  {
    ...mockChargerRef['1EC1-1-1834-00059'],
    phases: 'AC',
    ampere_max: 154,
    ampere_min: 2,
    ampere_fallback: 50,
    listings: [mockListing['DN01']],
  },
  {
    ...mockChargerRef['1920511'],
    phases: ['B', 'C'],
    ampere_max: 78,
    ampere_min: 6,
    ampere_fallback: 12,
    listings: [{ id: 5, title: 'BY01' }],
  },
  {
    ...mockChargerRef['1EC1-1-1834-00060'],
    phases: ['C', 'D'],
    ampere_max: 60,
    ampere_min: 40,
    ampere_fallback: 50,
    listings: [{ id: 7, title: 'DC01' }],
  },
] as ChargerDetails[]

export const mockChargersListings = [
  {
    id: 63,
    chargePointSerialNumber: '1EC0-1-1823-00061',
    listings: [{ id: 2, title: 'FR02' }],
    connectors: [{ id: 4, connectorId: 1 }],
  },
  {
    id: 52,
    chargePointSerialNumber: 'OCPIT2',
    listings: [{ id: 47, title: 'OpenADRTestListing3' }],
    connectors: [{ id: 4, connectorId: 1 }],
  },
  {
    id: 51,
    chargePointSerialNumber: 'OCPIT1',
    listings: [{ id: 45, title: 'OpenADRTestListing' }],
    connectors: [{ id: 4, connectorId: 1 }],
  },
  {
    id: 48,
    chargePointSerialNumber: 'PHIWIFI',
    listings: [{ id: 4, title: 'TV01' }],
    connectors: [{ id: 4, connectorId: 1 }],
  },
  {
    id: 29,
    chargePointSerialNumber: '1EC3-1-1917-00010',
    listings: [{ id: 3, title: 'FR03' }],
    connectors: [{ id: 4, connectorId: 1 }],
  },
] as ChargerListings[]

export const mockChargersListingsLoadMgmt = [
  {
    id: 63,
    chargePointSerialNumber: '1EC0-1-1823-00061',
    listings: [{ id: 2, title: 'FR02', addCloudLoadManagement: false }],
  },
  {
    id: 52,
    chargePointSerialNumber: 'OCPIT2',
    listings: [{ id: 47, title: 'OpenADRTestListing3', addCloudLoadManagement: false }],
  },
  {
    id: 51,
    chargePointSerialNumber: 'OCPIT1',
    listings: [{ id: 45, title: 'OpenADRTestListing', addCloudLoadManagement: true }],
  },
  {
    id: 48,
    chargePointSerialNumber: 'PHIWIFI',
    listings: [{ id: 4, title: 'TV01', addCloudLoadManagement: true }],
  },
  {
    id: 29,
    chargePointSerialNumber: '1EC3-1-1917-00010',
    listings: [{ id: 3, title: 'FR03', addCloudLoadManagement: true }],
  },
] as ChargersListingsLoadMgmt[]

export const mockChargersListingsResponse = (tenantId: number) => {
  return {
    data: mockChargersListings,
    pagination: {
      currentPage: 1,
      totalEntries: 5,
      perPage: 20,
    },
  } as ChargersListingsResponse
}

export const mockChargersListingsLoadMgmtResponse = (tenantId: number) => {
  return {
    data: mockChargersListingsLoadMgmt,
    pagination: {
      currentPage: 1,
      totalEntries: 5,
      perPage: 20,
    },
  } as ChargersListingsLoadMgmtResponse
}
