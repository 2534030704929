import { log } from '../../logger'
import configProvider from '../../config'

import { apiClient } from './client'
import { MasterAccountRef } from '../../models/master-account'
import { mockMasterAccounts } from '../../mock/master-account-mock'

export async function GetMasterAccounts(): Promise<MasterAccountRef[]> {
  const url = '/master_accounts'

  log('fetching master accounts', { url: url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockMasterAccounts)
  }
  return apiClient(url, {})
}

export async function CreateMasterAccount(name: string): Promise<MasterAccountRef> {
  const url = '/master_accounts'

  log('create a master account', { url: url })
  if (configProvider.config.mockEnabled) {
    const newMockPartner = { id: mockMasterAccounts.length + 1, name }
    return Promise.resolve(newMockPartner)
  }
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      master_account: {
        name,
      },
    }),
  })
}
