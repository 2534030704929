import { theme } from '../../theme'

export const editSVG = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.02459 1.25404C8.36294 0.915522 8.91162 0.915292 9.25025 1.25352L10.7458 2.7473C11.0845 3.08567 11.0848 3.63462 10.7463 3.97327L4.59556 10.1276C4.47483 10.2484 4.3211 10.3308 4.15368 10.3646L1 11L1.63659 7.85014C1.67036 7.68308 1.75263 7.52967 1.87313 7.40911L8.02459 1.25404Z"
      stroke={theme.colors.neonGreen}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export const editGreySVG = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.25 10.875L7.4375 12.75M4.9375 10.875L11.0997 4.49762C11.7632 3.83413 12.8389 3.83413 13.5024 4.49762C14.1659 5.16111 14.1659 6.23684 13.5024 6.90033L7.125 13.0625L4 14L4.9375 10.875Z"
      stroke={theme.colors.darkGray}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
