import { Button, Descriptions, Input } from 'antd'
import { AugmentedUser } from '../../../models/user'
import { styled, theme } from '../../../theme'
import { editSVG } from '../../../assets/svg/edit'
import { ButtonModal } from '../../../atom/button'
import { noteSVG } from '../../../assets/svg/note'
import { useState } from 'react'
import { cancelSVG } from '../../../assets/svg/cancel'
import { checkmarkSVG } from '../../../assets/svg/checkmark'

interface props {
  editUser: AugmentedUser
  note: string
  name: string
  setName: (value: string) => void
  setNote: (value: string) => void
}

const DescriptionsWrapper = styled.div`
  .ant-descriptions-item-label {
    width: 80px;
  }

  .ant-descriptions-item-container {
    display: flex;
    align-items: center;
  }

  .ant-descriptions-item-content {
    display: flex;
    justify-content: space-between;
  }

  .ant-input {
    border-radius: 22px;
    height: 29px;
    :hover {
      border-color: #30d7ba;
    }
    :focus,
    :active {
      box-shadow: none;
      border-color: #30d7ba;
    }
  }

  .svg-button {
    margin-left: 6px;
    background-color: #fff;
    border-color: #dedede;
    display: inline;
    padding: 3px 6px;
    height: 31px;

    :hover {
      background-color: #fff;
      border-color: #dedede;
    }
  }

  .checkmark {
    padding: 5px 6px;
  }

  .display-description > div > span > .ant-btn {
    padding: 0px;
    position: absolute;
    float: right;
    right: 20px;
  }

  .display-description > div > span > .ant-btn > .edit-svg > svg {
    height: 14.5px;
    width: 15px;
  }

  .display-description > div > span > .ant-btn > .note-svg {
    margin-right: -3px;
  }

  .display-description > div > span > .ant-btn > .note-svg > svg {
    height: 18px;
    width: 18px;
  }

  .note {
    padding-bottom: 0px;
  }

  .name {
    padding-bottom: 16px;
  }
`

const labelStyle = { color: '#000000', fontSize: '13px', lineHeight: '16px' }

const contentStyle = {
  color: `${theme.colors.darkGray}`,
  fontSize: '13px',
  lineHeight: '16px',
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'AkkuratLLWeb-Regular',
}

const UserBasicInfo: React.FC<props> = ({ editUser, note, name, setName, setNote }) => {
  const [editName, setEditName] = useState(false)
  const [editNote, setEditNote] = useState(false)
  const [updatingName, setUpdatingName] = useState(name)
  const [updatingNote, setUpdatingNote] = useState(note)

  const handleChange = (value: string, type: string) => {
    if (type === 'name') {
      setUpdatingName(value)
    }
    if (type === 'note') {
      setUpdatingNote(value)
    }
  }

  const handleCancel = (type: string) => {
    if (type === 'name') {
      setEditName(false)
      setUpdatingName(name)
    }
    if (type === 'note') {
      setEditNote(false)
      setUpdatingNote(note)
    }
  }

  const handleAccept = (type: string) => {
    if (type === 'name') {
      setEditName(false)
      setName(updatingName)
    }
    if (type === 'note') {
      setEditNote(false)
      setNote(updatingNote)
    }
  }

  return (
    <DescriptionsWrapper>
      <Descriptions
        labelStyle={labelStyle}
        contentStyle={contentStyle}
        colon={false}
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
      >
        <Descriptions.Item label="ID">{editUser.id}</Descriptions.Item>
        <Descriptions.Item label="Email">{editUser.email}</Descriptions.Item>
        {editName ? (
          <Descriptions.Item className="name" label="Name">
            <Input
              status={updatingName ? '' : 'error'}
              maxLength={50}
              value={updatingName}
              placeholder="Enter first and last name"
              onChange={(e) => handleChange(e.target.value, 'name')}
            />
            <Button className="svg-button" shape="circle" icon={cancelSVG} onClick={() => handleCancel('name')} />
            <Button
              className="svg-button checkmark"
              shape="circle"
              icon={checkmarkSVG}
              onClick={() => handleAccept('name')}
            />
          </Descriptions.Item>
        ) : (
          <Descriptions.Item className="display-description name" label="Name">
            {name === '' || name === null ? <>-</> : name}
            <ButtonModal
              type="link"
              icon={<span className="edit-svg">{editSVG}</span>}
              text={''}
              click={() => setEditName(true)}
            />
          </Descriptions.Item>
        )}
        {editNote ? (
          <Descriptions.Item className="note" label="Note">
            <Input maxLength={50} value={updatingNote} onChange={(e) => handleChange(e.target.value, 'note')} />
            <Button className="svg-button" shape="circle" icon={cancelSVG} onClick={() => handleCancel('note')} />
            <Button
              className="svg-button checkmark"
              shape="circle"
              icon={checkmarkSVG}
              onClick={() => handleAccept('note')}
            />
          </Descriptions.Item>
        ) : (
          <Descriptions.Item className="display-description note" label="Note">
            {note === '' || note === null ? <>-</> : note}
            <ButtonModal
              type="link"
              icon={<span className="note-svg">{noteSVG}</span>}
              text={''}
              click={() => setEditNote(true)}
            />
          </Descriptions.Item>
        )}
      </Descriptions>
    </DescriptionsWrapper>
  )
}

export default UserBasicInfo
