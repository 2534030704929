import { useFormatMessage } from '../../helpers/intl'

export const useConnectorTranslation = () => {
  const disconnectedText = useFormatMessage('dashboard.chargerPage.connectorStatus.disconnected', 'Disconncted')
  const disconnectedMessageText = useFormatMessage(
    'dashboard.chargerPage.connectorStatus.disconnected.message',
    'About -1 ago at -2',
  )
  const connectorStatusText = useFormatMessage('dashboard.chargerPage.connectorStatus.heading', 'Connector Status')

  return { connectorStatusText, disconnectedText, disconnectedMessageText }
}
