import { NewListing } from '../../../models/listing'
import { theme } from '../../../theme'
import { Links } from '../../../components/routes/paths'
import { buildRailsListingUrl } from '../../../helpers/url'
import { useAppState } from '../../../state'

interface props {
  listing: NewListing
}

const ListingTitle: React.FC<props> = ({ listing }) => {
  return (
    <div className="flex-and-gap-below">
      <ListTitle listing={listing} />
      <ListingId listing={listing} />
    </div>
  )
}

const ListTitle: React.FC<props> = ({ listing }) => (
  <span className="pointer underline">
    <a
      key={listing.id}
      href={buildRailsListingUrl(listing)}
      style={{ color: theme.colors.softBlack }}
      target="_blank"
      rel="noreferrer"
    >
      {listing.title || '-'}
    </a>
  </span>
)

const ListingId: React.FC<props> = ({ listing }) => {
  const { currentUser } = useAppState()

  const handleListingIdURL =
    currentUser?.role !== 'admin' ? buildRailsListingUrl(listing) : Links.listing({ listingId: listing.id })

  return (
    <span className="pointer underline opacity-06 paragraph-04-light">
      <a style={{ color: theme.colors.softBlack }} href={handleListingIdURL} target="_blank" rel="noopener noreferrer">
        ID: {listing.id}
      </a>
    </span>
  )
}

export default ListingTitle
