import { useState, useRef, useEffect } from 'react'
import { Divider, Input, Select, Space, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import type { InputRef } from 'antd'

import { renderFormatMessage } from '../../helpers/intl'
import { CreateRemittance, GetRemittance } from '../../services/data-provider/remittance'
import { RemittanceObject } from '../../models/remittance'

interface props {
  defaultRemittance?: RemittanceObject
  onOptionChange: (selectedRemittance?: RemittanceObject) => void
  disabled: boolean
}

export const RemittanceSelector: React.FC<props> = ({ defaultRemittance, onOptionChange, disabled }) => {
  const [remittances, setRemittances] = useState<RemittanceObject[]>([])
  const [name, setName] = useState('')
  const inputRef = useRef<InputRef>(null)

  const defaultP = defaultRemittance
    ? { label: defaultRemittance?.name, value: defaultRemittance?.name, id: defaultRemittance?.id }
    : null

  useEffect(() => {
    GetRemittance().then((resp) => setRemittances(resp))
  }, [])

  const handleSelectChange = (selectedRemittance: any) => {
    const findRemittance = remittances.find((p) => p.name === selectedRemittance)
    onOptionChange(findRemittance)
  }

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    CreateRemittance(name).then((newRemittance) => {
      setRemittances([...remittances, { id: newRemittance.id, name: newRemittance.name }])
    })
    setName('')
    setTimeout(() => {
      inputRef.current?.focus()
    }, 500)
  }

  const checkDecuplicate = () => {
    const filterName = remittances.filter((remitt) => remitt.name.toLowerCase() === name.toLowerCase())

    if (name.length === 0) {
      return true
    } else if (filterName.length !== 0) {
      return true
    } else {
      return false
    }
  }

  const options = remittances?.map((remit) => ({
    label: remit.name,
    value: remit.name,
    id: remit.id,
  }))

  return (
    <Select
      showSearch
      allowClear
      disabled={disabled}
      defaultValue={defaultP}
      placeholder="Select a remittance"
      onChange={handleSelectChange}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space wrap style={{ padding: '0 8px 4px' }}>
            <Input
              allowClear
              placeholder="Select a remittance"
              ref={inputRef}
              value={name}
              onChange={onNameChange}
              style={{ width: '100%' }}
            />
            <Button type="primary" disabled={checkDecuplicate()} icon={<PlusOutlined />} onClick={addItem}>
              {renderFormatMessage('dashboard.text.add', 'Add')}
            </Button>
          </Space>
        </>
      )}
      options={options}
    />
  )
}
