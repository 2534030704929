import { PeakShavingProgramsResponse } from '../models/http'
import { PeakShavingEventScheduleZone, PeakShavingProgramCategoryType, PeakShavingState } from '../models/peak-shaving'

export const mockPeakShavingAllProgramsResp: PeakShavingProgramsResponse = {
  data: [
    {
      id: 1,
      name: 'Fugiat voluptas reprehenderit est.',
      category: 'peak shaving' as PeakShavingProgramCategoryType,
      initiative: 'Officia commodi.',
      state: 'draft' as PeakShavingState,
      hasShavingEvents: true,
    },
    {
      id: 2,
      name: 'Reiciendis provident molestiae est.',
      category: 'peak shaving' as PeakShavingProgramCategoryType,
      initiative: 'Tenetur quo.',
      state: 'draft' as PeakShavingState,
      hasShavingEvents: true,
    },
  ],
  pagination: { currentPage: 1, totalEntries: 2, perPage: 10 },
}

export const mockPeakShavingProgramDelete = {
  response: 'Program has been deleted successfully',
}

export const mockListAllListingsForAProgram = [
  {
    id: 6,
    title: 'L7SME',
    chargerSerialNumber: '372b428f-fee0-400f-b33b-d6f149198602',
    chargerVendor: 'LITE-ON',
    chargerModel: 'EX-1762-1EC0',
    tenantName: 'TestTenant',
  },
  {
    id: 7,
    title: 'RB8S1',
    chargerSerialNumber: 'faa952a8-7a67-4e33-b890-e34be878e917',
    chargerVendor: 'LITE-ON',
    chargerModel: 'EX-1762-1EC0',
    tenantName: 'TestTenant',
  },
]

export const mockListingAddedToProgram = {
  response: 'Listing has been added to the program successfully',
}

export const mockProgramPublished = {
  response: 'Program has been published successfully',
}

export const mockProgramUnPublished = {
  response: 'Program has been converted to draft successfully',
}

export const mockListingRemovedFromProgram = {
  response: 'Listing has been removed from the program successfully',
}

export const mockPeakShavingEvents = {
  data: [
    {
      id: 2,
      state: 'draft' as PeakShavingState,
      date: '2023-05-18',
      allowOptOut: false,
      source: 'manual',
      notes: 'Iusto eos id quia.',
      adminPersonId: 0,
      systemGenerated: false,
      programId: 18,
      program: {
        id: 18,
        name: 'Occaecati et accusamus consequuntur.',
        category: 'demand response' as PeakShavingProgramCategoryType,
        initiative: 'EnergyHub',
        state: 'draft' as PeakShavingState,
        hasShavingEvents: true,
      },
    },
    {
      id: 1,
      state: 'draft' as PeakShavingState,
      date: '2023-05-17',
      allowOptOut: false,
      source: 'manual',
      notes: 'Et quam provident rem.',
      adminPersonId: 0,
      systemGenerated: false,
      programId: 18,
      program: {
        id: 18,
        name: 'Occaecati et accusamus consequuntur.',
        category: 'demand response' as PeakShavingProgramCategoryType,
        initiative: 'EnergyHub',
        state: 'draft' as PeakShavingState,
        hasShavingEvents: true,
      },
    },
  ],
  pagination: { currentPage: 1, totalEntries: 2, perPage: 10 },
}

export const mockPeakShavingEventDeleted = {
  response: 'Shaving event has been deleted successfully',
}

export const mockPeakShavingEventPublished = {
  response: 'Shaving event has been published successfully',
}

export const mockPeakShavingEventUnPublished = {
  response: 'Shaving event has been unpublished successfully',
}

export const mockGetAllPeakShavingEventSchedule = [
  {
    id: 1,
    from: 8,
    to: 9,
    zone: 'primary' as PeakShavingEventScheduleZone,
  },
  {
    id: 2,
    from: 9,
    to: 10,
    zone: 'secondary' as PeakShavingEventScheduleZone,
  },
  {
    id: 3,
    from: 20,
    to: 21,
    zone: 'secondary' as PeakShavingEventScheduleZone,
  },
]

export const mockPeakShavingEventScheduleRemoved = {
  response: 'Schedule has been removed successfully',
}
