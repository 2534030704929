import _ from 'lodash'
import configProvider from '../../config'
import { addQueryString } from '../../helpers/url'
import { log } from '../../logger'
import { mockBilling } from '../../mock/billing'
import { mockTenantById, mockTenantContact } from '../../mock/tenant-mock'
import { Billing } from '../../models/billing'
import { TenantFilter } from '../../models/filter'
import { TenantResponse } from '../../models/http'
import { Tenant, TenantContactInfo, TenantDeployment, TenantRef, TenantUniformPricing } from '../../models/tenant'
import { AugmentedUser, toAugmentedUser, User, UserRef } from '../../models/user'
import CaseConverter from './case-converter'
import { apiClient } from './client'
import { mockTenants, mockTenantsRef, mockTenantsResponse, mockUserResponse } from './mock'

export async function GetTenants(filter: TenantFilter): Promise<TenantResponse> {
  let url = '/tenants'
  let queryParams: { [key: string]: string } = {
    page: `${filter.page}`,
  }

  if (filter.defaultTenant) {
    queryParams['tenant_id'] = `${filter.defaultTenant.id}`
  }

  if (filter.tenants && filter.tenants.length > 0) {
    queryParams['term'] = `${filter.tenants.map((t) => t.id)}`
  }

  if (filter.country && filter.country.length > 0) {
    queryParams['country'] = `${filter.country}`
  }
  if (filter.province && filter.province.length > 0) {
    queryParams['province'] = `${filter.province}`
  }

  url = addQueryString(url, queryParams)

  log('fetching tenants', { url: url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockTenantsResponse)
  }
  return apiClient(url, {})
}

export async function GetTenant(tenantId: number): Promise<Tenant> {
  let url = `/tenants/${tenantId}`

  log('fetching tenant', { url: url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockTenantById[tenantId])
  }
  return apiClient(url, {})
}

export async function CreateTenant(tenant: Tenant): Promise<Tenant> {
  let url = `/tenants`

  log('creating tenant', { url: url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockTenants[0])
  }

  const snakeCasedTenantModel = CaseConverter.camelToSnakeCase(tenant)
  const newKeys = { location: 'location_attributes', weekly_schedules: 'weekly_schedules_attributes' }
  const updatedTenantModel = CaseConverter.renameKeys(snakeCasedTenantModel, newKeys)
  delete updatedTenantModel.id

  return apiClient<Tenant>(url, {
    method: 'POST',
    body: JSON.stringify(updatedTenantModel),
  })
}

export async function UpdateTenant(tenant: Tenant): Promise<Tenant> {
  let url = `/tenants/${tenant.id}`
  log('updating tenant', { url: url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(tenant)
  }

  const snakeCasedTenantModel = CaseConverter.camelToSnakeCase(tenant)
  const newKeys = {
    location: 'location_attributes',
    weekly_schedules: 'weekly_schedules_attributes',
    tenant_contacts: 'tenant_contacts_attributes',
  }
  const updatedTenantModel = CaseConverter.renameKeys(snakeCasedTenantModel, newKeys)
  delete updatedTenantModel.id

  return apiClient<Tenant>(url, {
    method: 'PUT',
    body: JSON.stringify(updatedTenantModel),
  })
}

export async function AuthorizeUser(tenantIds: string[], user: UserRef): Promise<AugmentedUser[]> {
  let url = `/tenants/authorize`

  log('fetching tenant', { url: url })
  if (configProvider.config.mockEnabled) {
    if (configProvider.config.mockEnabled) {
      return Promise.resolve(mockUserResponse['data'].map((u) => toAugmentedUser(u)))
    }
  }

  return apiClient<User[]>(url, {
    method: 'POST',
    body: JSON.stringify({
      tenant_id: `${tenantIds.join(',')}`,
      user_id: user.id,
    }),
  }).then((users) => users.map((u) => toAugmentedUser(u)))
}

export async function GetTenantContacts(tenantId: string): Promise<TenantContactInfo[]> {
  let url = `/tenants/${tenantId}/tenant_contacts`

  log('updating tenant', { url: url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockTenantContact)
  }

  return apiClient(url, {})
}

export async function UpdateTenantContacts(tenantId: string, tenantContactInfo: TenantContactInfo[]): Promise<Tenant> {
  const url = `/tenants/${tenantId}/tenant_contacts`

  const snakeCasedTenantModel = tenantContactInfo.map((contact) => {
    return CaseConverter.camelToSnakeCase(contact)
  })

  log('updating tenant contacts', { url: url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockTenants[0])
  }

  return apiClient<Tenant>(url, {
    method: 'POST',
    body: JSON.stringify({
      tenant_contact: [...snakeCasedTenantModel],
    }),
  })
}

export async function UpdateTenantDeployment(tenantId: string, tenantDeployment: TenantDeployment): Promise<Tenant> {
  let url = `/tenants/${tenantId}/tenant_deployment`

  log('updating tenant', { url: url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockTenants[0])
  }

  const snakeCasedTenantModel = CaseConverter.camelToSnakeCase(tenantDeployment)

  if (tenantDeployment.parkingStalls) {
    snakeCasedTenantModel.parking_stalls = [...Object.values(tenantDeployment.parkingStalls)]
  }
  if (tenantDeployment.loadManagementConfiguration) {
    snakeCasedTenantModel.load_management_configuration = [
      ...Object.values(tenantDeployment.loadManagementConfiguration!),
    ]
  }
  if (tenantDeployment.remoteDesktopIds) {
    snakeCasedTenantModel.remote_desktop_ids = [...Object.values(tenantDeployment.remoteDesktopIds)]
  }

  return apiClient<Tenant>(url, {
    method: 'POST',
    body: JSON.stringify({ tenant_deployment: { ...snakeCasedTenantModel } }),
  })
}

export async function UpdateTenantUniformPricing(
  tenantId: string,
  tenantUniformPricing: TenantUniformPricing,
): Promise<Tenant> {
  let url = `/tenants/${tenantId}/tenant_uniform_pricing`

  log('updating tenant uniform pricing', { url: url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockTenants[0])
  }

  // remove any null or undefined properties
  const cleandTenantUniformPricingObj = _.omitBy(tenantUniformPricing, _.isNil)

  const snakeCasedUniformPricingModel = CaseConverter.camelToSnakeCase(cleandTenantUniformPricingObj)
  if (snakeCasedUniformPricingModel.hasOwnProperty('tenant_listing_authors')) {
    delete snakeCasedUniformPricingModel.tenant_listing_authors
  }
  if (snakeCasedUniformPricingModel.hasOwnProperty('tenant_listing_stripe_accounts')) {
    delete snakeCasedUniformPricingModel.tenant_listing_stripe_accounts
  }

  snakeCasedUniformPricingModel.time_of_day_price_weekday = [
    ...Object.values(tenantUniformPricing.timeOfDayPriceWeekday),
  ].map((num) => {
    return Number((num * 100).toFixed(2))
  })
  snakeCasedUniformPricingModel.time_of_day_price_weekend = [
    ...Object.values(tenantUniformPricing.timeOfDayPriceWeekend),
  ].map((num) => {
    return Number((num * 100).toFixed(2))
  })

  return apiClient<Tenant>(url, {
    method: 'POST',
    body: JSON.stringify({ tenant_uniform_pricing: { ...snakeCasedUniformPricingModel } }),
  })
}

export async function FindTenants(term?: string): Promise<TenantRef[]> {
  let url = '/tenants/find'

  if (term) {
    let queryParams: { [key: string]: string } = {
      term: `${term}`,
    }
    url = addQueryString(url, queryParams)
  }

  log('fetching tenants', { url: url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockTenantsRef)
  }
  return apiClient(url, {})
}

export async function GetTenantBilling(tenantId: string): Promise<Billing> {
  const url = `/tenants/${tenantId}/billing`
  log('Fetching tenant billing', { url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockBilling)
  }
  return apiClient(url, {})
}

export async function UpdateTenantBilling(tenantId: string, billing: Billing): Promise<Billing> {
  const url = `/tenants/${tenantId}/update_billing`
  log('update tenant billing info', { url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockBilling)
  }
  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      billing: {
        tenant_id: tenantId,
        contact_name: billing.contactName,
        email: billing.email,
        phone: billing.phone,
        notes: billing.notes,
        remittance_type_id: billing.remittanceType?.id || null,
      },
    }),
  })
}
