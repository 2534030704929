import { Link } from 'react-router-dom'

import { Button } from 'antd'
import { styled } from '../theme'

type ButtonSize = 'large' | 'middle' | 'small'
type ButtonType = 'link' | 'text' | 'default' | 'ghost' | 'primary' | 'dashed'

interface props {
  loading: boolean
  icon: JSX.Element
  fetchData?: () => void
  text?: JSX.Element | string
}

interface linkProps extends props {
  url: string
}

interface selectedProps {
  icon: JSX.Element
  text?: JSX.Element | string
  click: () => void
  type: ButtonType
  size?: ButtonSize
}

const StyledButton = styled.button<{ disabled: boolean }>`
  height: 34px;
  width: 44px;
  border: 0;
  border-radius: 40px;
  z-index: 1;

  margin-left: -46px;
  position: relative;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.2s ease-in-out;

  background-color: ${({ disabled, theme }) => (disabled ? theme.colors.lightGray : theme.colors.neonGreen)};
  &:hover {
    background-color: ${({ disabled, theme }) => (disabled ? theme.colors.lightGray : theme.colors.neonGreenHover)};
  }
`

const StyledCircleButton = styled.button<{ disabled: boolean }>`
  height: 32px;
  width: 32px;
  border: 0;
  border-radius: 40px;
  z-index: 1;

  svg {
    margin-left: 1px;
    margin-bottom: -1.5px;

    path {
      fill: ${({ disabled, theme }) => (disabled ? '#cdcdcd' : theme.colors.black)};
    }
  }

  margin-left: -35px;
  position: relative;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.2s ease-in-out;

  background-color: ${({ disabled, theme }) => (disabled ? theme.colors.cultered : theme.colors.neonGreen)};
  &:hover {
    background-color: ${({ disabled, theme }) => (disabled ? theme.colors.cultered : theme.colors.neonGreenHover)};
  }
`

export const ButtonIcon: React.FC<props> = ({ loading, icon, fetchData }) => {
  return (
    <StyledButton disabled={loading} onClick={fetchData}>
      {icon}
    </StyledButton>
  )
}

export const ButtonIconCircle: React.FC<props> = ({ loading, icon, fetchData }) => {
  return (
    <StyledCircleButton disabled={loading} onClick={fetchData}>
      {icon}
    </StyledCircleButton>
  )
}

export const ButtonLink: React.FC<linkProps> = ({ loading, icon, text, url }) => (
  <Link to={url}>
    <Button size="large" type="primary" disabled={loading} icon={icon}>
      <span className="regular-cap">{text}</span>
    </Button>
  </Link>
)

export const ButtonModal: React.FC<selectedProps> = ({ icon, text, click, type, size = 'middle' }) => (
  <Button size={size} icon={icon} type={type} onClick={click}>
    <span className="regular-cap">{text}</span>
  </Button>
)
