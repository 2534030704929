import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsMore from 'highcharts/highcharts-more'
import { useEffect, useState } from 'react'

import { ChargerStationInfo } from '../../../../models/chargerv2'
import { useFormatMessage } from '../../../../helpers/intl'
import { useAppState } from '../../../../state'
import { styled } from '../../../../theme'

highchartsMore(Highcharts)

interface StyledLiProps {
  translateStr: string
}

const HomePageCurrentContainer = styled.div<StyledLiProps>`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  position: relative;

  .highcharts-color-0 {
    fill: #54dec7;
    stroke: #54dec7;
    stroke-width: 3;
  }

  .highcharts-axis-title {
    display: none;
  }

  .odometer {
    text-align: center;
  }

  .highcharts-label.highcharts-data-label.highcharts-data-label-color-0.highcharts-tracker span {
    z-index: 100;
    font-family: 'AkkuratLLWeb-Black';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    font-style: normal;
    top: -40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .maximum-charger-current {
    opacity: 0.6;
  }

  .highcharts-label.highcharts-data-label.highcharts-data-label-color-0.highcharts-tracker span::before {
    content: 'Active Charger Current';
    position: absolute;
    bottom: -25px;
    font-family: 'AkkuratLLWeb-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 145%;
    font-style: normal;
    width: 100px;
    white-space: normal;
    text-align: center;
    opacity: 0.5;
  }

  .highcharts-label.highcharts-data-label.highcharts-data-label-color-0.highcharts-tracker span.fr::before {
    content: 'Courant de chargeur actif';
  }

  .highcharts-label.highcharts-data-label.highcharts-data-label-color-0.highcharts-tracker span::after {
    content: '';
    position: absolute;
    width: 100px;
    height: 100px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 16%);
    border-radius: 50%;
    transform: ${(props) => props.translateStr};
    left: 0;
    top: 0;
    z-index: -1;
  }

  ${(props) => props.theme.breakpoints.down('xxl')} {
    .highcharts-label.highcharts-data-label.highcharts-data-label-color-0.highcharts-tracker span::after {
      width: 100px;
      height: 100px;
    }
  }

  .current-reading {
    margin-bottom: 10px;
  }

  text[text-anchor='middle'] {
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    color: #242e2c;
    fill: #242e2c;
  }

  .highcharts-series.highcharts-series-0.highcharts-solidgauge-series.highcharts-color-0.highcharts-tracker.highcharts-series-hover
    path {
    stroke: #78f8e3;
    fill: #78f8e3;
  }

  .seriesName {
    opacity: 0.6;
  }

  .maximum-charger-current.paragraph-03-regular.highcharts-strong {
    font-weight: bolder;
    opacity: 0.6 !important;
    cursor: pointer;
  }

  text.maximum-charger-current.paragraph-03-regular:not(:first-of-type) {
    opacity: 0;
  }

  .current-gauge::before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 5px;
    position: absolute;
    bottom: 20px;
    left: 40px;
    border-radius: 3px;
    background: #242e2c;
    opacity: 0.05;
  }

  .maximum-charger-current-text-container {
    position: absolute;
    left: 60px;
    bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 4px;
  }

  .highcharts-legend {
    pointer-events: none;
  }
`

interface props {
  chargerStationInfo: ChargerStationInfo
}

export const HomePageCurrentChart: React.FC<props> = ({ chargerStationInfo }) => {
  const [translationStr, setTranslateStr] = useState('translate(-20%, -25%)')
  Highcharts.addEvent(Highcharts.Series, 'afterRender', function () {
    this.points.forEach(function (p) {
      //@ts-ignore
      if (p && p.graphic && p.graphic.symbolName === 'arc') {
        //@ts-ignore
        p.graphic.element.setAttribute('filter', 'url(#goo)')
      }
    })
  })
  const activeChargerCurrent = useFormatMessage(
    'dashboard.homePage.stationoverview.current.description1',
    'Active Charger Current',
  )

  const { language } = useAppState()

  useEffect(() => {
    switch (language) {
      case 'fr':
        document
          .querySelector(
            '.highcharts-label.highcharts-data-label.highcharts-data-label-color-0.highcharts-tracker span',
          )
          ?.classList.add('fr')
        break
      case 'en':
        document
          .querySelector(
            '.highcharts-label.highcharts-data-label.highcharts-data-label-color-0.highcharts-tracker span',
          )
          ?.classList.remove('fr')
        break
    }
  }, [language])

  const [options, setOptions] = useState({
    chart: {
      type: 'solidgauge',
      height: 312,
      // width: 288,
      styledMode: true,
      className: 'current-gauge',
      events: {
        load() {
          //@ts-ignore
          setTimeout(this.reflow.bind(this), 0)
        },
      },
    },

    title: {
      text: '',
      // align: 'top',
      // verticalAlign: 'left',
      // y: 20,
    },
    legend: {
      floating: true,
      align: 'left',
      layout: 'vertical',
      itemMarginBottom: 5,
      // width: 250,
      x: -10,
      y: -20,
      symbolRadius: 3,
      labelFormatter: function (): any {
        //console.log('---label---')
        //@ts-ignore
        const reading = Math.round(chargerStationInfo.currentAmp)
        //@ts-ignore
        // const [value] = this.ydata
        //@ts-ignore
        var output = `<span class='paragraph-03-regular seriesName'> ${this.userOptions.name} </span> <span class='paragraph-03-regular'><b> (${reading} A)</b></span>`
        return output
      },
    },

    //   pane: {
    //     center: ['50%', '70%'],
    //     size: '80%',
    //     startAngle: -130,
    //     endAngle: 130,
    //     background: {
    //       backgroundColor: '#EEE',
    //       //   innerRadius: '55%',
    //       //   outerRadius: '80%',
    //       shape: 'arc',
    //     },
    //   },
    pane: {
      center: ['50%', '50%'],
      size: '90%',
      startAngle: -115,
      endAngle: 115,
      background: {
        backgroundColor: '#EEE',
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc',
      },
    },

    exporting: {
      enabled: false,
    },

    tooltip: {
      enabled: false,
    },

    // the value axis
    yAxis: {
      stops: [
        [0, '#54DEC7'], // green
      ],
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 2,
      title: {
        y: 20,
        x: 0,
        text: 'Current',
      },
      labels: {
        y: 25,
        // text: 'Current',
        formatter: function (obj: any) {
          //console.log('-------')
          //console.log(obj.value)
          return `${obj.value} A`
        },
      },
      min: 0,
      max: chargerStationInfo.maxAmp,
    },

    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: 5,
          borderWidth: 0,
          useHTML: true,
        },
      },
      series: {
        color: '#54DEC7',
        events: {
          mouseOver: function () {
            // console.log('-----mouse in------')
            //console.log(this)
            document.querySelector('.current-gauge')?.classList.add('mouse-in')
          },
          mouseOut: function () {
            //console.log('-----mouse out------')
            document.querySelector('.current-gauge')?.classList.remove('mouse-in')
          },
        },
      },
    },
    series: [
      {
        name: activeChargerCurrent,
        data: [Math.round(chargerStationInfo.currentAmp)],
        showInLegend: true,
        dataLabels: {
          format: ' <div class="current-reading">{y} A</div>',
        },
        tooltip: {
          enable: false,
        },
        type: 'solidgauge',
        color: '#54DEC7',
        point: {
          events: {
            mouseOver: function () {
              //@ts-ignore
              const chart = this.series.chart,
                //@ts-ignore
                legendItemGroup = chart.legend.box,
                { y, height } = legendItemGroup.element.getBBox()
              //@ts-ignore

              chart.renderedLegendRect = chart.renderer
                .rect(-350, y, 99999999, height)
                .attr({
                  fill: '#f4f6f4',
                  stroke: '#f4f6f4',
                })
                .add(chart.legend.box.parentGroup)
            },
            mouseOut: function () {
              const point = this
              //@ts-ignore
              point.series.chart.renderedLegendRect.destroy()
            },
          },
        },
      },
    ],
  })

  useEffect(() => {
    setOptions({
      chart: {
        type: 'solidgauge',
        height: 312,
        // width: 288,
        styledMode: true,
        className: 'current-gauge',
        events: {
          load() {
            //@ts-ignore
            setTimeout(this.reflow.bind(this), 0)
          },
        },
      },

      title: {
        text: '',
        // align: 'top',
        // verticalAlign: 'left',
        // y: 20,
      },
      legend: {
        floating: true,
        align: 'left',
        layout: 'vertical',
        itemMarginBottom: 5,
        // width: 250,
        x: 20,
        y: -20,
        symbolRadius: 3,
        labelFormatter: function (): any {
          //console.log('---label---')
          //@ts-ignore
          const reading = Math.round(chargerStationInfo.currentAmp)
          //@ts-ignore
          // const [value] = this.ydata
          //@ts-ignore
          var output = `<span class='paragraph-03-regular seriesName'> ${this.userOptions.name} </span> <span class='paragraph-03-regular'><b> (${reading} A)</b></span>`
          return output
        },
      },

      //   pane: {
      //     center: ['50%', '70%'],
      //     size: '80%',
      //     startAngle: -130,
      //     endAngle: 130,
      //     background: {
      //       backgroundColor: '#EEE',
      //       //   innerRadius: '55%',
      //       //   outerRadius: '80%',
      //       shape: 'arc',
      //     },
      //   },
      pane: {
        center: ['50%', '50%'],
        size: '90%',
        startAngle: -115,
        endAngle: 115,
        background: {
          backgroundColor: '#EEE',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc',
        },
      },

      exporting: {
        enabled: false,
      },

      tooltip: {
        enabled: false,
      },

      // the value axis
      yAxis: {
        stops: [
          [0, '#54DEC7'], // green
        ],
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        title: {
          y: 20,
          x: 0,
          text: 'Current',
        },
        labels: {
          y: 25,
          // text: 'Current',
          formatter: function (obj: any) {
            //console.log('-------')
            //console.log(obj.value)
            return `${obj.value} A`
          },
        },
        min: 0,
        max: chargerStationInfo.maxAmp,
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true,
          },
        },
        series: {
          color: '#54DEC7',
          events: {
            mouseOver: function () {
              // console.log('-----mouse in------')
              //console.log(this)
              document.querySelector('.current-gauge')?.classList.add('mouse-in')
            },
            mouseOut: function () {
              //console.log('-----mouse out------')
              document.querySelector('.current-gauge')?.classList.remove('mouse-in')
            },
          },
        },
      },
      series: [
        {
          name: activeChargerCurrent,
          data: [Math.round(chargerStationInfo.currentAmp)],
          showInLegend: true,
          dataLabels: {
            format: ' <div class="current-reading">{y} A</div>',
          },
          tooltip: {
            enable: false,
          },
          type: 'solidgauge',
          color: '#54DEC7',
          point: {
            events: {
              mouseOver: function () {
                //@ts-ignore
                const chart = this.series.chart,
                  //@ts-ignore
                  legendItemGroup = chart.legend.box,
                  { y } = legendItemGroup.element.getBBox()
                //@ts-ignore

                chart.renderedLegendRect = chart.renderer
                  .rect(-350, y, 9999999, 27)
                  .attr({
                    fill: '#f4f6f4',
                    stroke: '#f4f6f4',
                  })
                  .add(chart.legend.box.parentGroup)
              },
              mouseOut: function () {
                const point = this
                //@ts-ignore
                point.series.chart.renderedLegendRect.destroy()
              },
            },
          },
        },
      ],
    })

    const currentReading = Math.round(chargerStationInfo.currentAmp)

    if (currentReading < 10) {
      setTranslateStr('translate(-35%, -25%)')
    } else if (currentReading > 10 && currentReading < 100) {
      setTranslateStr('translate(-30%, -25%)')
    } else if (currentReading > 100 && currentReading < 1000) {
      setTranslateStr('translate(-25%, -25%)')
    } else if (currentReading >= 1000) {
      setTranslateStr('translate(-20%, -25%)')
    }
  }, [chargerStationInfo, language])

  return (
    <HomePageCurrentContainer translateStr={translationStr} className={language}>
      <HighchartsReact highcharts={Highcharts} options={options}></HighchartsReact>
      <div className="maximum-charger-current-text-container">
        <p className="maximum-charger-current paragraph-03-regular">
          {useFormatMessage('dashboard.homePage.stationoverview.current.description2', 'Maximum Charger Current')}{' '}
        </p>
        <span className="max-current-reading paragraph-03-regular">
          <b>{`(${chargerStationInfo.maxAmp} A)`}</b>
        </span>
      </div>
    </HomePageCurrentContainer>
  )
}
