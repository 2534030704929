import { useEffect, useState } from 'react'
import { Button, Col, PageHeader, Row, Space, Table } from 'antd'
import { Link } from 'react-router-dom'

import { AlertError } from '../../components/error'
import { Links } from '../../components/routes/paths'
import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { LocationSelector } from '../../components/selector/location-selector'
import { MultiTenantSelector } from '../../components/selector/multiple-tenant-selector'

import { SwtchError } from '../../models/error'
import { TenantFilter } from '../../models/filter'
import { PaginationMeta } from '../../models/pagination'
import { Tenant, TenantRef } from '../../models/tenant'

import { Box } from '../../atom/box'
import { useFormatMessage } from '../../helpers/intl'
import { GetTenants } from '../../services/data-provider/tenants'
import { useAppState } from '../../state'

interface TableRow extends Tenant {
  key: string
}

const TenantsBasePage: React.FC = () => {
  const { selectedTenant } = useAppState()
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [pagination, setPagination] = useState<PaginationMeta>()
  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(false)
  const [selectedCountries, setSelectedCountries] = useState<string[]>([])
  const [selectedProvinces, setSelectedProvinces] = useState<string[]>([])
  const [filter, setFilter] = useState<TenantFilter>({
    page: 1,
    defaultTenant: selectedTenant,
  })

  const tenantsPageHeading = useFormatMessage('dashboard.tenantsPage.heading', 'Tenants')
  const createNewTenantBtn = useFormatMessage('dashboard.tenantsPage.createNewTenantBtn', 'Create New Tenant')
  const tenantsTableNameHeading = useFormatMessage('dashboard.tenantsPage.table.heading.name', 'Name')
  const tenantsTableChargerCountHeading = useFormatMessage(
    'dashboard.tenantsPage.table.heading.chargerCount',
    'Charger Count',
  )
  const searchBtnText = useFormatMessage('dashboard.searchBtn.title', 'Search')

  useEffect(() => {
    getData()
  }, [filter.page])

  const getData = () => {
    setLoading(true)
    GetTenants({
      page: filter.page,
      tenants: filter.tenants,
      country: `${selectedCountries.join(',')}`,
      province: `${selectedProvinces.join(',')}`,
    })
      .then((resp) => {
        setTenants(resp.data)
        setPagination(resp.pagination)
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  const onPaginationChange = (page: number, pageSize?: number) => {
    setFilter({ ...tenants, page })
  }

  const columns = [
    {
      title: tenantsTableNameHeading,
      render: (t: Tenant) => {
        return <Link to={Links.tenant({ tenantId: `${t.id}` })}>{t.displayName || t.name || '-'}</Link>
      },
    },
    {
      title: tenantsTableChargerCountHeading,
      dataIndex: 'chargerCount',
      sorter: (a: Tenant, b: Tenant) => {
        const av = a.chargerCount ? a.chargerCount : 0
        const bv = b.chargerCount ? b.chargerCount : 0
        return av - bv
      },
    },
  ]

  const rows: TableRow[] = tenants.map((t) => {
    let v = t as TableRow
    v['key'] = `tenant-${t.id}`
    return v
  })

  const renderLocationSelector = (chosenCountries: string[], chosenProvinces: string[]) => {
    setSelectedCountries(chosenCountries)
    setSelectedProvinces(chosenProvinces)
  }

  const renderTitle = () => {
    return (
      <Row justify="space-between">
        <Col>
          <Space>
            <MultiTenantSelector
              onOptionsChange={(tenants) => setFilter({ ...filter, tenants: tenants })}
              onClear={() => setFilter({ ...filter, tenants: [] })}
            />
            <LocationSelector onSearch={renderLocationSelector} />
            <Button type="primary" onClick={getData} disabled={loading}>
              {searchBtnText}
            </Button>
          </Space>
        </Col>
      </Row>
    )
  }

  return (
    <>
      <PageHeader
        title={tenantsPageHeading}
        extra={
          <Button type="primary">
            <Link to={Links.tenantsCreate()}> {createNewTenantBtn}</Link>
          </Button>
        }
      />
      <Box padding>
        <Row>
          <Col span={24}>
            <AlertError error={error} />
            <Table
              title={renderTitle}
              loading={loading}
              size="small"
              dataSource={rows}
              columns={columns}
              pagination={{
                position: ['bottomCenter'],
                total: pagination?.totalEntries,
                current: pagination?.currentPage,
                pageSize: pagination?.perPage,
                showSizeChanger: false,
                onChange: onPaginationChange,
              }}
            />
          </Col>
        </Row>
      </Box>
    </>
  )
}

export const TenantsPage = withAuthenticatedLayout(TenantsBasePage)
