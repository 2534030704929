import { Button, DatePicker, Space } from 'antd'
import { RangePickerProps } from 'antd/es/date-picker'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'
import { CaseConverter } from '../../services/data-provider/case-converter'
import { useAppState } from '../../state'

const { RangePicker } = DatePicker
interface props {
  startDate?: moment.Moment
  endDate?: moment.Moment
  onChangeDate: (dates: any) => void
  calendarRanges: string[]
  previousQuarter?: any
}

const setCalendarRanges = (calendarRanges: string[], otherRanges?: any) => {
  let ranges: Record<string, [moment.Moment, moment.Moment]> = {
    Today: [moment().startOf('day'), moment()],
    'Past 7 days': [moment().subtract(6, 'day').startOf('day'), moment().endOf('day')],
    'Week to Date': [moment().startOf('week'), moment().endOf('day')],
    'Month to Date': [moment().startOf('month'), moment().endOf('day')],
    'Year to Date': [moment().startOf('year'), moment().endOf('day')],
    'Last Month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
    'Last Quarter': [moment().subtract(3, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
    'Custom Date': [moment().subtract(1, 'months').startOf('month'), moment()],
    ...otherRanges,
  }
  const newKeys: Record<string, string> = {
    Today: calendarRanges[0],
    'Past 7 days': calendarRanges[1],
    'Week to Date': calendarRanges[2],
    'Month to Date': calendarRanges[3],
    'Year to Date': calendarRanges[4],
    'Last Month': calendarRanges[5],
    'Last Quarter': calendarRanges[6],
    'Custom Date': calendarRanges[7],
  }
  let caseConverter = new CaseConverter()
  const updatedRanges = caseConverter.renameKeys(ranges, newKeys)
  return updatedRanges
}

export const CustomDateRangePicker: React.FC<props> = ({
  startDate,
  endDate,
  onChangeDate,
  calendarRanges,
  previousQuarter,
}) => {
  let updateCalendarRanges

  if (previousQuarter) {
    updateCalendarRanges = setCalendarRanges(calendarRanges, previousQuarter)
  } else {
    updateCalendarRanges = setCalendarRanges(calendarRanges)
  }

  let fieldProps: RangePickerProps = {
    ranges: updateCalendarRanges,
    onChange: onChangeDate,
    disabledDate: (current: moment.Moment) => current > moment().endOf('day'),
    allowClear: false,
  }

  if (startDate && endDate) {
    fieldProps.defaultValue = [startDate, endDate]
  }

  const [open, setOpen] = useState(false)
  const [count, setCount] = useState(0)
  const datePickerRef = useRef<any>(null)
  const [customPresetOpen, setCustomPresetOpen] = useState(false)

  const { language } = useAppState()

  const customDateTxt = useFormatMessage('dashboard.calendar.customdate', 'Custom Date')

  const customClickHandler = (e: Event) => {
    if (e.target) {
      //@ts-ignore
      const target = e.target.closest('.ant-picker-preset')
      if (target) {
        var elems = document.querySelector('.active-preset')
        if (elems !== null) {
          elems.classList.remove('active-preset')
          elems.classList.remove('custom-preset-active')
          document.querySelector('.ant-picker-panel-container')?.classList.remove('custom-preset-active')
        }
        target.classList.add('active-preset')
        const text = document.querySelector('.active-preset .ant-tag')

        if (text?.innerHTML === customDateTxt) {
          setOpen(true)
          setCustomPresetOpen(true)
          datePickerRef && datePickerRef.current.focus()
          target.classList.add('custom-preset-active')
          document.querySelector('.ant-picker-panel-container')?.classList.add('custom-preset-active')
        } else {
          setCustomPresetOpen(false)
        }
      }
    }
  }

  const renderCustomCheckboxes = () => {
    for (let i = 1; i <= document.querySelectorAll('.ant-picker-ranges .ant-picker-preset').length; i++) {
      if (i === 2) {
        document.querySelector(`.ant-picker-ranges  .ant-picker-preset:nth-of-type(3)`)?.classList.add('active-preset')
      }

      document
        .querySelector(`.ant-picker-ranges  .ant-picker-preset:nth-of-type(${i}) `)
        ?.insertAdjacentHTML(
          'afterbegin',
          `<label class="ant-checkbox-wrapper"><span class="ant-checkbox ${
            i === 2 ? 'ant-checkbox-checked ' : ''
          }"><input type="checkbox" class="ant-checkbox-input" value=""><span class="ant-checkbox-inner"></span></span></label>`,
        )

      document
        .querySelector(`.ant-picker-ranges  .ant-picker-preset:nth-of-type(${i})`)
        ?.addEventListener('click', function () {
          var elemt = document.querySelectorAll(
            `.ant-picker-ranges  .ant-picker-preset:not(:nth-of-type(${i})) span.ant-checkbox.ant-checkbox-checked`,
          )
          if (elemt.length > 0) {
            //@ts-ignore
            elemt[0].click()
          }
          //@ts-ignore
          document
            .querySelector(`.ant-picker-ranges  .ant-picker-preset:nth-of-type(${i}) .ant-checkbox-input`)
            //@ts-ignore
            ?.click()
          document
            .querySelector(`.ant-picker-ranges  .ant-picker-preset:nth-of-type(${i}) span.ant-checkbox`)
            ?.classList.toggle('ant-checkbox-checked')
          //@ts-ignore
          document.querySelector(`.ant-picker-ranges  .ant-picker-preset:nth-of-type(${i}) > span`)?.click()
          //@ts-ignore
        })
    }
  }

  useEffect(() => {
    if (open && count === 0) {
      renderCustomCheckboxes()
      setCount(1)
    }
    if (open) {
      document.addEventListener('click', customClickHandler)
    } else {
      document.removeEventListener('click', customClickHandler)
    }
  }, [open])

  useEffect(() => {
    setCount(0)
    setOpen(false)
    //@ts-ignore
    document.querySelector(`.ant-picker-ranges  .ant-picker-preset:nth-of-type(3) > span`)?.click()
  }, [language])

  return (
    <RangePicker
      {...fieldProps}
      open={open}
      onClick={() => setOpen(true)}
      ref={datePickerRef}
      renderExtraFooter={() => (
        <Space>
          <Button
            onClick={() => {
              setOpen(false)
              setCustomPresetOpen(false)
              setTimeout(() => {
                datePickerRef && datePickerRef.current.blur()
              }, 100)
            }}
            type="ghost"
          >
            <span className="regular-cap">{renderFormatMessage('dashboard.text.cancel', 'Cancel')}</span>
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setOpen(false)
              setCustomPresetOpen(false)
              setTimeout(() => {
                datePickerRef && datePickerRef.current.blur()
              }, 100)
            }}
          >
            <span className="regular-cap">{renderFormatMessage('dashboard.text.apply', 'Apply')}</span>
          </Button>
        </Space>
      )}
      onBlur={() => {
        customPresetOpen ? setOpen(true) : setOpen(false)
      }}
      format="MMM DD, YYYY"
      className={`rangePicker ${language}`}
    />
  )
}
