import { theme } from '../../theme'

export const logoutSVG = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.82967 2H4.75624C4.29046 2 3.84375 2.18437 3.51439 2.51256C3.18503 2.84075 3 3.28587 3 3.75V14.25C3 14.7141 3.18503 15.1592 3.51439 15.4874C3.84375 15.8156 4.29046 16 4.75624 16H7.82967M8.04795 9H16M16 9L13.5 6M16 9L13.5 12"
      stroke={theme.colors.darkGray}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
