import { styled, theme } from '../theme'

interface styleProps {
  isDedicated?: boolean
  isLast?: boolean
  vertical?: boolean
}

interface props {
  pauseSubscriptionDisabled?: boolean
  unpauseSubscriptionDisabled?: boolean
  cancelSubscriptionDisabled?: boolean
  subscribeUserDisabled?: boolean
  editPlanDisabled?: boolean
}

export const MoreButtonWrapper = styled.span<styleProps>`
  font-family: ${(props) => props.theme.fontFamily.regularFontFamily};

  .ant-btn-group > .ant-btn-icon-only {
    border-radius: 40px !important;
    background-color: ${theme.colors.white} !important;
    border-color: ${theme.colors.frenchGray} !important;
    padding: 0;
    height: 38px;
    width: 38px;
  }

  .ant-btn:hover {
    border-color: ${theme.colors.frenchGray} !important;
    color: ${theme.colors.softBlack} !important;
  }

  .ant-btn:focus {
    border-color: ${theme.colors.neonGreen} !important;
    color: ${theme.colors.softBlack} !important;
  }

  .ant-btn > span {
    transform: rotate(0deg);
  }

  .ant-btn > span > svg {
    fill: ${theme.colors.softBlack};
    height: 28px;
    width: 28px;
  }

  .ant-btn:focus > span {
    transform: ${(props) => (props.vertical ? 'rotate(90deg)' : 'rotate(0deg)')};
    transition: all 0.3s ease-in-out;
  }
`

export const MoreDropdownWrapper = styled.div<props>`
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.frenchGray};
  border-radius: 5px;
  padding: 15px;
  width: 197px;

  .dropdown-list-item {
    padding-bottom: 22px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 13px;
    line-height: 16px;
    cursor: pointer;
    font-family: ${theme.fontFamily.regularFontFamily};
  }

  .pause-subscription {
    color: ${(props) => (props.pauseSubscriptionDisabled ? theme.colors.shadowGray : theme.colors.softBlack)};
    cursor: ${(props) => (props.pauseSubscriptionDisabled ? 'not-allowed' : 'pointer')};

    svg {
      opacity: ${(props) => (props.pauseSubscriptionDisabled ? '0.35' : '1')};
    }
  }

  .unpause-subscription {
    color: ${(props) => (props.unpauseSubscriptionDisabled ? theme.colors.shadowGray : theme.colors.softBlack)};
    cursor: ${(props) => (props.unpauseSubscriptionDisabled ? 'not-allowed' : 'pointer')};

    svg {
      opacity: ${(props) => (props.unpauseSubscriptionDisabled ? '0.35' : '1')};
    }
  }

  .cancel-subscription {
    color: ${(props) => (props.cancelSubscriptionDisabled ? theme.colors.shadowGray : theme.colors.softBlack)};
    cursor: ${(props) => (props.cancelSubscriptionDisabled ? 'not-allowed' : 'pointer')};

    svg {
      opacity: ${(props) => (props.cancelSubscriptionDisabled ? '0.35' : '1')};
    }
  }

  .subscribe-user {
    color: ${(props) => (props.subscribeUserDisabled ? theme.colors.shadowGray : theme.colors.softBlack)};
    cursor: ${(props) => (props.subscribeUserDisabled ? 'not-allowed' : 'pointer')};

    svg {
      opacity: ${(props) => (props.subscribeUserDisabled ? '0.35' : '1')};
    }
  }

  .edit-plan {
    color: ${(props) => (props.editPlanDisabled ? theme.colors.shadowGray : theme.colors.softBlack)};
    cursor: ${(props) => (props.editPlanDisabled ? 'not-allowed' : 'pointer')};

    svg {
      opacity: ${(props) => (props.editPlanDisabled ? '0.35' : '1')};
    }
  }

  .last-item {
    padding-bottom: 0;
    display: flex;
    align-items: center;
    gap: 14px;
    font-size: 13px;
    line-height: 16px;
    cursor: pointer;
    font-family: ${theme.fontFamily.regularFontFamily};
  }
`

export const RowInputWrapper = styled.span`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  .row-input {
    width: 50%;
    display: flex;
    flex-direction: inherit;
  }

  .row-input
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > div
    > div
    > .ant-input-number {
    width: 100%;
    margin-bottom: 2px;
  }
`

export const HeadingWrapper = styled.div`
  font-size: 18px;
  font-weight: 400;
  font-family: AkkuratLLWeb-Regular;
  margin-top: 4px;
  margin-bottom: 4px;
`

export const TextBodyWrapper = styled.div`
  font-family: AkkuratLLWeb-Regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
`

export const TitleBodyWrapper = styled.div`
  font-family: AkkuratLLWeb-Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
  margin-bottom: 20px;
`

export const ModalBodyWrapper = styled.span`
  .ant-row {
    margin-bottom: 15px;
  }

  .email {
    display: flex;
    align-items: center;
    margin-bottom: -4px;
  }

  .email > .ant-form-item-label {
    margin-top: 15px;
  }

  .plan {
    margin-top: -4px;
    margin-bottom: -9px;
  }

  .plan > .ant-form-item-label {
    margin-top: 5px;
  }

  .display-row > .ant-form-item-label {
    display: flex;
    flex: inherit;
    margin-right: 27px;
  }

  .ant-input-number {
    width: 100%;
  }

  .first-row {
    margin-top: -4px;
  }

  .last-row {
    margin-bottom: -4px;
  }

  .ant-select-selector {
    height: 38px !important;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    margin-top: 2px;
  }

  .ant-select-selection-item {
    margin-top: 2px;
  }

  .date-picker
    > .ant-form-item-control
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-picker {
    height: 39px;
    border-radius: 50px;
    margin-right: 15px;
    width: 153px;
    margin-top: 16px;
  }

  .date-picker
    > .ant-form-item-control
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-picker-range {
    width: 338px;
  }

  .date-picker > .ant-form-item-control > .ant-form-item-explain {
    padding-top: 10px;
  }
  .ant-tag {
    height: 21px;
    padding-top: 0px;
  }

  .ant-btn {
    padding-left: 0px;
    padding-right: 0px;
  }

  .ant-picker-panel-container {
    width: fit-content;
  }
`
