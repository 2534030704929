import { Button, Card, Col, PageHeader, Row, Space, Spin, Tabs, Tooltip } from 'antd'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { debounce } from 'lodash'
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more'
import solidGauge from 'highcharts/modules/solid-gauge'

import { Section } from '../../atom/box'
import { AlertError } from '../../components/error'

import { LocationSelector } from '../../components/redesign/location-selector'
import { MultiTenantSelector } from '../../components/redesign/multiple-tenant-selector'

import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'
import { SwtchError } from '../../models/error'
import { TenantRef } from '../../models/tenant'
import { useAppState } from '../../state'
import { styled, theme } from '../../theme'

import { HomePageActiveUsersChart } from './charts/active-users/active-users'
import { HomePageStationsChart } from './charts/active-users/stations'
import { HomePageCurrentChart } from './charts/active-users/current'
import { HomePageChargersAvailbality } from './charts/active-users/chargers-availability'

import { HomePageConnectorsPluggedInChart } from './charts/active-users/connectors-plugged-in'
import { HomePageGaugeChartUtlizationInsight } from './charts/active-users/gauge-utilization-insight'
import { HomePageUtilizationInsightsChart } from './charts/active-users/utilization-insights'
import { HomePageComumptionInsightLoadProfileChart } from './charts/active-users/consumption-insight-load-profile'
import { HomePageTransactionOverview } from './charts/active-users/transaction-overview'
import { HomePageStationActivation } from './charts/active-users/station-activation'
import {
  ChargersAvailabilityMetaContainer,
  ConsumptionInsightContainer,
  ConsumptionInsightMetaContainer,
  HomePageContainer,
  HomePageStationActivationContainer,
  HomePageTransactionOverviewTableContainer,
  MicroChartContainer,
  StationOverviewMetaContainer,
  StationOverviewMetaCountContainer,
  UtilizationGaugeInfoContainer,
  UtlizationInsightContainer,
} from './ContainerStyles/homepageStyles'
import {
  FetchTransactions,
  FetchTransactionsSummary,
  TransactionFilter,
} from '../../services/data-provider/transactionv2'
import { TransactionResponsev2, TransactionSummary } from '../../models/http'
import {
  ChargerProfile,
  DailyProfile,
  StationActivation,
  Transactionv2,
  TrxnOverview,
  TrxSummary,
} from '../../models/transaction'
import { ChargerInfo, ChargerStationInfo, ConnectorInfo } from '../../models/chargerv2'

import { Links } from '../../components/routes/paths'
import { getSession } from '../../state/session-store'
import { HomePageChargersParticpatingInDemandResponseEventsChart } from './charts/active-users/chargers-participating-demand-response-events'
import { log } from '../../logger'
import { EmptyData } from '../../components/redesign/emptyData'
import configProvider from '../../config'
import { ConnectorAvailabilityIcon } from '../../assets/svg/connectorAvailability'
import { ConnectorPluggedInIcon } from '../../assets/svg/connectorPluggedIn'
import { InfoExclamation } from '../../assets/svg/info-excalamation'
import { uniqueUserSVG } from '../../assets/svg/user'
import { exchangeSVG } from '../../assets/svg/exchange'
import { boltSVG } from '../../assets/svg/bolt'
import { dollarSVG } from '../../assets/svg/currency'
import OperationOverview from './components/OperationOverview'
import MetricsSummary from './components/metrics/MetricsSummary'
import { CardWrapper } from '../../atom/card'
import { mockChargerInfo } from '../../mock/websocket/charger'

highchartsMore(Highcharts)
solidGauge(Highcharts)

const PageHeaderContainer = styled.div`
  h5.heading-05-regular {
    font-weight: bold;
    color: ${(props) => props.theme.colors.softBlack};
  }
`

export const DashboardBasePageRedesign: React.FC = () => {
  const { selectedTenant } = useAppState()

  const [startDate, setStartDate] = useState(moment().subtract(6, 'd'))
  const [endDate, setEndDate] = useState(moment(moment()))

  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(true)
  const [wsLoading, setWsLoading] = useState(false)

  const [selectedCountries, setSelectedCountries] = useState<string[]>([])
  const [selectedProvinces, setSelectedProvinces] = useState<string[]>([])
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [transactionsSummary, setTransactionsSummary] = useState<TransactionSummary>()
  const [utilizationInsightChargerProfiles, setUtilizationInsightChargerProfiles] = useState<ChargerProfile[]>()
  const [stationActivation, setStationActivation] = useState<StationActivation>()
  const [trxProfileSummary, setTrxProfileSummary] = useState<TrxSummary>()
  const [trnxOverview, setTrnxOverview] = useState<TrxnOverview[]>()
  const [dailyProfiles, setDailyProfiles] = useState<DailyProfile[]>()
  const [chargerInfo, setChargerInfo] = useState<ChargerInfo>()
  const [chargerStationInfo, setChargerStationInfo] = useState<ChargerStationInfo>()
  const [connectorInfo, setConnectorInfo] = useState<ConnectorInfo>()
  const [utlizationInsightTabKey, setUtlizationInsightTabKey] = useState('All')
  const [utlizationInsightGaugeTabKey, setUtlizationInsightGaugeTabKey] = useState('All')
  const [consumptionInsightLoadProfileTabKey, setConsumptionInsightLoadProfileTabKey] = useState('All')
  const [operationOverviewTabKey, setOperationOverviewTabKey] = useState('Revenue')
  const [reveneChartVisible, setRevenueChartVisible] = useState(true)
  const [transactionsChartVisible, setTransactionsChartVisible] = useState(false)
  const [energyChartVisible, setEnergyChartVisible] = useState(false)
  const [utilizationAllVisible, setUtilizationAllVisible] = useState(true)
  const [utilizationWeekdayVisible, setUtilizationWeekdayVisible] = useState(false)
  const [utilizationWeekendVisible, setUtilizationWeekendVisible] = useState(false)
  const [consumptionAllVisible, setConsumptionAllVisible] = useState(true)
  const [consumptionWeekdayVisible, setConsumptionWeekdayVisible] = useState(false)
  const [consumptionWeekendVisible, setConsumptionWeekendVisible] = useState(false)

  const [chargingDurationAllVisible, setChargingDurationAllVisible] = useState(true)
  const [chargingDurationLevel2Visible, setChargingDurationLevel2Visible] = useState(false)
  const [chargingDurationDCFCVisible, setChargingDurationDCFCVisible] = useState(false)

  const [stationActivationDataEmpty, setStationActivationDataEmpty] = useState(false)
  const [averageDailyLoadProfileEmpty, setAverageDailyLoadProfileEmpty] = useState(false)
  const [utilizationProfileEmpty, setUtilizationProfileEmpty] = useState(false)
  const [utilizationGauageEmpty, setUtilizationGaugeEmpty] = useState(false)

  const [ws, setWebsocket] = useState<WebSocket>()
  const [updateDict, setUpdateDict] = useState(`update=1`)
  const [tenantsUpdateStr, setTenantsUpdateStr] = useState('')
  const [locationUpdateStr, setLocationUpdateStr] = useState('')

  const [timeZone, setTimeZone] = useState('')

  const [wsConnected, setWsConnected] = useState(false)

  const [locationDictStr, setLocationDictStr] = useState('')
  const [tenantDictStr, setTenantDictStr] = useState('')
  const wsURL = useRef(configProvider.config.dashboardWsDomain)

  const [filter, setFilter] = useState<TransactionFilter>({
    page: 1,
    perPage: 10,
    defaultTenant: selectedTenant,
  })

  const [transactionResponse, setTransactionResponse] = useState<TransactionResponsev2>()
  const [transactions, setTransactions] = useState<Transactionv2[]>()

  const dayText = useFormatMessage('dashboard.homePage.consumptionInsights.avgNumberOfTransactions.day', 'day')

  document.querySelector('body')?.classList.add('redesignActive')

  const onChangeDate = (dates: moment.Moment[]) => {
    if (dates[0] !== startDate) {
      setStartDate(dates[0])
    }
    if (dates[1] !== endDate) {
      setEndDate(dates[1])
    }
  }

  const handleLocation = (chosenCountries: string[], chosenProvinces: string[]) => {
    setSelectedCountries(chosenCountries)
    setSelectedProvinces(chosenProvinces)
    setFilter({ ...filter, country: chosenCountries.join('%'), province: chosenProvinces.join('%') })
  }

  const handleTenantSelection = (tenants: TenantRef[]) => {
    // update tenants and remove any duplicates
    setTenants(
      tenants.filter((obj, index) => {
        return index === tenants.findIndex((o) => obj.id === o.id)
      }),
    )
    setFilter({ ...filter, tenants: tenants })
  }

  useEffect(() => {
    fetchTransactionSummary()
  }, [filter])

  useEffect(() => {
    if (!wsConnected) {
      setWsLoading(true)
      startWebSocket()
    }
    return () => {
      ws && ws.send('update=0Tclose=1')
    }
  }, [wsConnected])

  const fetchTransactionSummary = () => {
    setLoading(true)
    setError(undefined)
    const controller = new AbortController()

    Promise.all([
      FetchTransactionsSummary(filter, startDate, endDate, controller).then((resp) => {
        setTransactionsSummary(resp)
      }),
      FetchTransactions(filter, startDate, endDate, controller).then((resp) => {
        setTransactionResponse(resp)
      }),
    ])
      .catch((err: SwtchError) => setError(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    transactionsSummary && setUtilizationInsightChargerProfiles(transactionsSummary.chargers)
    transactionsSummary && setStationActivation(transactionsSummary.summary.stationActivation)
    transactionsSummary && setTrxProfileSummary(transactionsSummary.summary)
    transactionsSummary && setTrnxOverview(transactionsSummary.txnOverview)
    transactionsSummary && setDailyProfiles(transactionsSummary.dailyProfiles)
    transactionsSummary && transactionsSummary.timezone
      ? setTimeZone(transactionsSummary.timezone)
      : setTimeZone('local')
    transactionResponse && setTransactions(transactionResponse.transactions)

    setUtlizationInsightGaugeTabKey(utlizationInsightGaugeTabKey)
    setOperationOverviewTabKey(operationOverviewTabKey)
    setUtlizationInsightTabKey(utlizationInsightTabKey)
    setConsumptionInsightLoadProfileTabKey(consumptionInsightLoadProfileTabKey)

    transactionsSummary &&
      setStationActivationDataEmpty(
        Object.values(transactionsSummary.summary.stationActivation).every((obj) => obj === 0),
      )
    handleTabChangeUtilizationInsightTab(utlizationInsightTabKey)
    handleTabChangeConsumptionInsightTab(consumptionInsightLoadProfileTabKey)
    handleTabUtilizationInsightChargingDurationTab(utlizationInsightGaugeTabKey)
    handleTabChangeOperationOverviewTab(operationOverviewTabKey)
  }, [transactionsSummary, transactionResponse])

  const startWebSocket = () => {
    if (configProvider.config.mockEnabled || configProvider.config.mockWebSocket) {
      setWsLoading(false)
      setChargerInfo(mockChargerInfo)
      return
    }
    const session = getSession()

    if (!session) throw new Error('not logged in')

    log('---before creating ws :', wsURL.current)

    const ws = new WebSocket(encodeURI(wsURL.current), [`${session.token}`, `${session.id}`])

    setWebsocket(ws)

    ws.addEventListener('open', (event) => {
      //console.log('WebSocket open')
      log('WebSocket open')
    })

    ws.addEventListener('message', (event) => {
      log('Received message ', event.data)
    })

    ws.addEventListener('close', (event) => {
      log('WebSocket connection closed')
    })

    ws.addEventListener('error', (event) => {
      log('WebSocket connection error:', event)
    })

    // console.log('-------------socket----------------------')

    ws.onopen = function (e) {
      log('[open] Connection established')
      log('Sending to server')
      setWsConnected(true)
      // ws.send(dict)
    }

    ws.onmessage = function (event) {
      setWsLoading(false)
      // console.log(`[message] Data received from server: ${event.data}`)
      log(`[message] Data received from server: ${event.data}`)
      // console.log('---resp----')
      // console.log(event.data)
      //ws.send('1')
      setChargerInfo(JSON.parse(event.data))
      event.data.chargers && setChargerStationInfo(JSON.parse(event.data.chargers))
    }

    ws.onclose = function (event) {
      if (event.wasClean) {
        //console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`)
        log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`)
      } else {
        // e.g. server process killed or network down
        // event.code is usually 1006 in this case
        //console.log('[close] Connection died')
        log('[close] Connection died')
      }
      log('wsURL onClose:', wsURL.current)
      // log('closeFlag.current', !closeFlag.current)
      // setTimeout(() => {
      //   startWebSocket()
      // }, 1000)
    }
  }

  useEffect(() => {
    // console.log('----i am triggered----')

    tenants.length > 0
      ? setTenantsUpdateStr(`Ttenants_id=${[...tenants.map((t) => t.id)].join('%')}`)
      : setTenantsUpdateStr('')

    selectedCountries.length > 0 && selectedProvinces.length > 0
      ? setLocationUpdateStr(
          `Tcountry=${[...selectedCountries.map((country) => country)].join('%')}Tprovince=${[
            ...selectedProvinces.map((province) => province),
          ].join('%')}`,
        )
      : setLocationUpdateStr('')

    tenants.length > 0
      ? setTenantDictStr(`tenants_id=${[...tenants.map((t) => t.id)].join('%')}`)
      : setTenantDictStr('')

    selectedCountries.length > 0 && selectedProvinces.length > 0
      ? setLocationDictStr(
          `country=${[...selectedCountries.map((country) => country)].join('%')}&province=${[
            ...selectedProvinces.map((province) => province),
          ].join('%')}`,
        )
      : setLocationDictStr('')
  }, [tenants, selectedCountries, selectedProvinces])

  useEffect(() => {
    setUpdateDict(`update=1${tenantsUpdateStr}${locationUpdateStr}`)
  }, [tenantsUpdateStr, locationUpdateStr])

  useEffect(() => {
    log('updateDict: ', updateDict)
    if (ws) {
      ws.send(updateDict)
    }
  }, [updateDict])

  useEffect(() => {
    if (tenantDictStr && tenantDictStr.length > 0 && !locationDictStr) {
      wsURL.current = `${configProvider.config.dashboardWsDomain}${tenantDictStr}`
    } else if (locationDictStr && locationDictStr.length > 0 && !tenantDictStr) {
      wsURL.current = `${configProvider.config.dashboardWsDomain}${locationDictStr}`
    } else if (locationDictStr && locationDictStr.length > 0 && tenantDictStr && tenantDictStr.length > 0) {
      wsURL.current = `${configProvider.config.dashboardWsDomain}${tenantDictStr}&${locationDictStr}`
    }
  }, [tenantDictStr, locationDictStr])

  useEffect(() => {
    chargerInfo && setChargerStationInfo(chargerInfo.chargers)
    chargerInfo && setConnectorInfo(chargerInfo.connectors)
  }, [chargerInfo])

  const handleTabChangeOperationOverviewTab = (activeKey: string) => {
    switch (activeKey) {
      case 'Revenue':
        setRevenueChartVisible(true)
        setTransactionsChartVisible(false)
        setEnergyChartVisible(false)
        setOperationOverviewTabKey('Revenue')
        break
      case 'Transactions':
        setRevenueChartVisible(false)
        setTransactionsChartVisible(true)
        setEnergyChartVisible(false)
        setOperationOverviewTabKey('Transactions')
        break
      case 'Energy':
        setRevenueChartVisible(false)
        setTransactionsChartVisible(false)
        setEnergyChartVisible(true)
        setOperationOverviewTabKey('Energy')
        break
    }
  }

  const handleTabChangeUtilizationInsightTab = (activeKey: string) => {
    switch (activeKey) {
      case 'All':
        setUtilizationAllVisible(true)
        setUtilizationWeekdayVisible(false)
        setUtilizationWeekendVisible(false)
        setUtlizationInsightTabKey('All')
        transactionsSummary &&
          setUtilizationProfileEmpty(
            transactionsSummary.chargers.map((obj) => obj.chargerInUseWeek).every((entry) => entry === 0),
          )
        break
      case 'Weekday':
        setUtilizationAllVisible(false)
        setUtilizationWeekdayVisible(true)
        setUtilizationWeekendVisible(false)
        setUtlizationInsightTabKey('Weekday')
        transactionsSummary &&
          setUtilizationProfileEmpty(
            transactionsSummary.chargers.map((obj) => obj.chargerInUseWeekday).every((entry) => entry === 0),
          )
        break
      case 'Weekend':
        setUtilizationAllVisible(false)
        setUtilizationWeekdayVisible(false)
        setUtilizationWeekendVisible(true)
        setUtlizationInsightTabKey('Weekend')
        transactionsSummary &&
          setUtilizationProfileEmpty(
            transactionsSummary.chargers.map((obj) => obj.chargerInUseWeekend).every((entry) => entry === 0),
          )
        break
    }
  }

  const handleTabChangeConsumptionInsightTab = (activeKey: string) => {
    switch (activeKey) {
      case 'All':
        setConsumptionAllVisible(true)
        setConsumptionWeekdayVisible(false)
        setConsumptionWeekendVisible(false)
        setConsumptionInsightLoadProfileTabKey('All')
        transactionsSummary &&
          setAverageDailyLoadProfileEmpty(
            transactionsSummary.dailyProfiles.map((obj) => obj.consumeWeek).every((entry) => entry === 0),
          )
        break
      case 'Weekday':
        setConsumptionAllVisible(false)
        setConsumptionWeekdayVisible(true)
        setConsumptionWeekendVisible(false)
        setConsumptionInsightLoadProfileTabKey('Weekday')
        transactionsSummary &&
          setAverageDailyLoadProfileEmpty(
            transactionsSummary.dailyProfiles.map((obj) => obj.consumeWeekday).every((entry) => entry === 0),
          )
        break
      case 'Weekend':
        setConsumptionAllVisible(false)
        setConsumptionWeekdayVisible(false)
        setConsumptionWeekendVisible(true)
        setConsumptionInsightLoadProfileTabKey('Weekend')
        transactionsSummary &&
          setAverageDailyLoadProfileEmpty(
            transactionsSummary.dailyProfiles.map((obj) => obj.consumeWeekend).every((entry) => entry === 0),
          )
        break
    }
  }

  const handleTabUtilizationInsightChargingDurationTab = (activeKey: string) => {
    switch (activeKey) {
      case 'All':
        setChargingDurationAllVisible(true)
        setChargingDurationLevel2Visible(false)
        setChargingDurationDCFCVisible(false)
        setUtlizationInsightGaugeTabKey('All')
        transactionsSummary &&
          setUtilizationGaugeEmpty(
            transactionsSummary.summary.chargerAverageDayDuration === 0 &&
              transactionsSummary.summary.chargerAverageDayPlugin === 0,
          )
        break
      case 'Level2':
        setChargingDurationAllVisible(false)
        setChargingDurationLevel2Visible(true)
        setChargingDurationDCFCVisible(false)
        setUtlizationInsightGaugeTabKey('Level2')
        transactionsSummary &&
          setUtilizationGaugeEmpty(
            transactionsSummary.summary.chargerAverageDayLevel2Plugin === 0 &&
              transactionsSummary.summary.chargerAverageDayLevel2Duration === 0,
          )
        break
      case 'DCFC':
        setChargingDurationAllVisible(false)
        setChargingDurationLevel2Visible(false)
        setChargingDurationDCFCVisible(true)
        setUtlizationInsightGaugeTabKey('DCFC')
        transactionsSummary &&
          setUtilizationGaugeEmpty(
            transactionsSummary.summary.chargerAverageDayDcfcDuration === 0 &&
              transactionsSummary.summary.chargerAverageDayDcfcPlugin === 0,
          )
        break
    }
  }

  return (
    <HomePageContainer>
      <AlertError error={error} />
      <PageHeaderContainer>
        <PageHeader
          title={
            <Space>
              <h5
                className="heading-05-regular"
                style={{ marginBottom: 0, paddingTop: '7px', fontWeight: 400, color: theme.colors.softBlack }}
              >
                {useFormatMessage('dashboard.homePage.stationoverview.heading', 'Station Overview')}
              </h5>
              {configProvider.config.mockWebSocket ? (
                <div
                  style={{
                    color: theme.colors.dangerPrimary,
                    border: `1px solid ${theme.colors.dangerPrimary}`,
                    fontSize: theme.fontSizes[2],
                    position: 'absolute',
                    transform: 'rotate(-10deg) scale(2) translateX(30%) translateY(-50%)',
                  }}
                >
                  Mock
                </div>
              ) : null}
            </Space>
          }
          extra={
            <>
              <Space>
                <LocationSelector onSearch={debounce(handleLocation, 1000)} />
                <MultiTenantSelector
                  onOptionsChange={debounce(handleTenantSelection, 1000)}
                  onClear={() => setTenants([])}
                />
              </Space>
            </>
          }
        />
      </PageHeaderContainer>
      <Spin spinning={wsLoading}>
        <Section>
          <Row gutter={[15, 15]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
              <Row gutter={[15, 15]} style={{ height: '100%' }}>
                <Col xs={24} sm={12} lg={12} xl={12} xxl={12}>
                  <CardWrapper>
                    <Card
                      title={
                        <span className="paragraph-02-regular">
                          {useFormatMessage('dashboard.homePage.stationoverview.activeUsers', 'Active Users')}
                        </span>
                      }
                      loading={wsLoading}
                    >
                      <Row align="middle" justify="space-between">
                        <Col sm={24} xl={12} xxl={12}>
                          <StationOverviewMetaContainer className="active-users-meta-container">
                            <svg
                              width="20"
                              height="27"
                              viewBox="0 0 14 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="7.00031" cy="4.03156" r="4.00031" fill="#54DEC7" />
                              <ellipse cx="6.99956" cy="15.0325" rx="7.00054" ry="4.00031" fill="#54DEC7" />
                            </svg>

                            {chargerInfo && chargerInfo.users && (
                              <h1 className="active-count heading-02-black">{chargerInfo.users.active}</h1>
                            )}
                          </StationOverviewMetaContainer>
                        </Col>
                        <Col sm={24} xl={12} xxl={12}>
                          {chargerInfo && (
                            <MicroChartContainer>
                              <HomePageActiveUsersChart chargerInfo={chargerInfo} />
                            </MicroChartContainer>
                          )}
                        </Col>
                      </Row>
                      <Row align="middle" justify="start">
                        <Col sm={24} xl={22} xxl={22}>
                          <StationOverviewMetaCountContainer>
                            {chargerInfo && chargerInfo.users && (
                              <p className="paragraph-03-regular">
                                <span>{renderFormatMessage('dashboard.homePage.chargingText', 'Charging')}</span>
                                <b>({chargerInfo.users.charging})</b>
                              </p>
                            )}
                            {chargerInfo && chargerInfo.users && (
                              <p className="paragraph-03-regular">
                                <span>
                                  {renderFormatMessage('dashboard.homePage.stationoverview.activeUsers.idle', 'Idle')}
                                </span>
                                <b>({chargerInfo.users.idle})</b>
                                <Tooltip
                                  title={renderFormatMessage(
                                    'dashboard.homePage.stationOverview.activeUsers.idle.info',
                                    'The transaction is ongoing, but the vehicle is not actively charging.',
                                  )}
                                >
                                  <InfoExclamation />
                                </Tooltip>
                              </p>
                            )}
                          </StationOverviewMetaCountContainer>
                        </Col>
                      </Row>
                    </Card>
                  </CardWrapper>
                </Col>
                <Col xs={24} sm={12} lg={12} xl={12} xxl={12}>
                  <CardWrapper>
                    <Card
                      title={
                        <span className="paragraph-02-regular">
                          {useFormatMessage(
                            'dashboard.homePage.stationoverview.connectorsPluggedIn',
                            'Connectors Plugged In',
                          )}
                        </span>
                      }
                      loading={wsLoading}
                    >
                      <Row align="middle" justify="space-between">
                        <Col
                          sm={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
                        >
                          <StationOverviewMetaContainer>
                            <ConnectorPluggedInIcon />

                            {chargerInfo && chargerInfo.connectors && (
                              <h1 className="active-count heading-02-black">{chargerInfo.connectors.pluggedIn}</h1>
                            )}
                          </StationOverviewMetaContainer>
                        </Col>
                        <Col
                          sm={24}
                          xl={12}
                          xxl={12}
                          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                          {chargerInfo && <HomePageConnectorsPluggedInChart chargerInfo={chargerInfo} />}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={24} xl={24} xxl={24}>
                          <StationOverviewMetaCountContainer className="connectors-plugged-in-meta-container">
                            {chargerInfo && chargerInfo.connectors && (
                              <p className="paragraph-03-regular">
                                <span>
                                  {renderFormatMessage(
                                    'dashboard.homePage.stationoverview.connectorsPluggedIn.level2connectors',
                                    'Level 2 Connectors',
                                  )}
                                </span>
                                <b>({chargerInfo.connectors.pluggedInLevel2})</b>
                              </p>
                            )}
                            {chargerInfo && chargerInfo.connectors && (
                              <p className="paragraph-03-regular">
                                <span>
                                  {renderFormatMessage(
                                    'dashboard.homePage.stationoverview.connectorsPluggedIn.dcfastconnectors',
                                    'DC Fast Connectors',
                                  )}
                                </span>
                                <b>({chargerInfo.connectors.pluggedInDc})</b>
                              </p>
                            )}
                          </StationOverviewMetaCountContainer>
                        </Col>
                      </Row>
                    </Card>
                  </CardWrapper>
                </Col>
                <Col xs={24} sm={12} lg={12} xl={12} xxl={12}>
                  <CardWrapper>
                    <Card
                      title={
                        <span className="paragraph-02-regular">
                          {useFormatMessage(
                            'dashboard.homePage.stationoverview.chargersParcipatingDREvents.heading',
                            'Chargers Enrolled in Demand Response',
                          )}
                        </span>
                      }
                      loading={wsLoading}
                    >
                      <Row align="middle" justify="space-between">
                        <Col xl={12} xxl={12}>
                          <div className="station-overview-meta-container">
                            <svg
                              width="18"
                              height="28"
                              viewBox="0 0 18 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0.208984 17.4442L11.2501 0.344666V10.7968H17.7952L6.75403 27.8964V17.4442H0.208984Z"
                                fill="#54DEC7"
                              />
                            </svg>
                            {chargerInfo && chargerInfo.connectors && (
                              <h1 className="active-count heading-02-black">{chargerInfo.connectors.drProgram}</h1>
                            )}
                          </div>
                        </Col>
                        <Col sm={24} xl={12} xxl={12}>
                          {chargerInfo && (
                            <MicroChartContainer>
                              <HomePageChargersParticpatingInDemandResponseEventsChart chargerInfo={chargerInfo} />
                            </MicroChartContainer>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={24} xl={24} xxl={24}>
                          <StationOverviewMetaCountContainer>
                            {chargerInfo && chargerInfo.connectors && (
                              <p className="paragraph-03-regular">
                                <span>
                                  {renderFormatMessage(
                                    'dashboard.homePage.stationoverview.chargersParcipatingDREvents.level2connectors',
                                    'Level 2 Connectors',
                                  )}
                                </span>
                                <b>({chargerInfo.connectors.drProgramLevel2})</b>
                              </p>
                            )}
                            {chargerInfo && chargerInfo.connectors && (
                              <p className="paragraph-03-regular">
                                <span>
                                  {renderFormatMessage(
                                    'dashboard.homePage.stationoverview.chargersParcipatingDREvents.dcfastconnectors',
                                    'DC Fast Connectors',
                                  )}
                                </span>
                                <b>({chargerInfo.connectors.drProgramDc})</b>
                              </p>
                            )}
                          </StationOverviewMetaCountContainer>
                        </Col>
                      </Row>
                    </Card>
                  </CardWrapper>
                </Col>
                <Col xs={24} sm={12} lg={12} xl={12} xxl={12}>
                  <CardWrapper className="chargers-availability">
                    <Card
                      title={
                        <span className="paragraph-02-regular">
                          {useFormatMessage(
                            'dashboard.homePage.stationoverview.connectorAvailability',
                            'Connector Availability',
                          )}
                        </span>
                      }
                      loading={wsLoading}
                    >
                      <Row align="middle" justify="space-between">
                        <Col xl={18} xxl={18}>
                          <div className="station-overview-meta-container">
                            <ConnectorAvailabilityIcon />
                            {connectorInfo && (
                              <h1 className="active-count heading-02-black">
                                {connectorInfo.available}/{connectorInfo.totalConnectors}
                              </h1>
                            )}
                          </div>
                        </Col>
                        <Col sm={24} xl={6} xxl={6}>
                          <MicroChartContainer>
                            {chargerInfo && <HomePageChargersAvailbality chargerInfo={chargerInfo} />}
                          </MicroChartContainer>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={24} xl={24} xxl={24}>
                          <ChargersAvailabilityMetaContainer>
                            <StationOverviewMetaCountContainer>
                              {connectorInfo && (
                                <p className="paragraph-03-regular">
                                  <span>
                                    {renderFormatMessage(
                                      'dashboard.homePage.stationoverview.connectorAvailability.level2connectors',
                                      'Level 2 Connectors',
                                    )}
                                  </span>
                                  <b>({connectorInfo.availableLevel2})</b>
                                </p>
                              )}
                              {connectorInfo && (
                                <p className="paragraph-03-regular">
                                  <span>
                                    {renderFormatMessage(
                                      'dashboard.homePage.stationoverview.connectorAvailability.dcfastconnectors',
                                      'DC Fast Connectors',
                                    )}
                                  </span>
                                  <b>({connectorInfo.availableDc})</b>
                                </p>
                              )}
                            </StationOverviewMetaCountContainer>
                          </ChargersAvailabilityMetaContainer>
                        </Col>
                      </Row>
                    </Card>
                  </CardWrapper>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
              <Row gutter={[15, 15]} style={{ height: '100%' }}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <CardWrapper>
                    <Card
                      title={renderFormatMessage('dashboard.homePage.stationoverview.stations.heading', 'Stations')}
                      className="stations"
                      loading={wsLoading}
                    >
                      {chargerStationInfo && connectorInfo && (
                        <HomePageStationsChart chargerStationInfo={chargerStationInfo} connectorInfo={connectorInfo} />
                      )}
                    </Card>
                  </CardWrapper>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <CardWrapper>
                    <Card
                      title={renderFormatMessage('dashboard.homePage.stationoverview.current.heading', 'Current')}
                      className="current"
                      loading={wsLoading}
                    >
                      {chargerStationInfo && <HomePageCurrentChart chargerStationInfo={chargerStationInfo} />}
                    </Card>
                  </CardWrapper>
                </Col>
              </Row>
            </Col>
          </Row>
        </Section>
      </Spin>
      <Spin spinning={loading}>
        <OperationOverview
          loading={loading}
          startDate={startDate}
          endDate={endDate}
          trnxOverview={trnxOverview}
          operationOverviewTabKey={operationOverviewTabKey}
          reveneChartVisible={reveneChartVisible}
          energyChartVisible={energyChartVisible}
          transactionsChartVisible={transactionsChartVisible}
          onChangeDate={onChangeDate}
          onFetchTransactionSummary={fetchTransactionSummary}
          onHandleTabChangeOperationOverviewTab={handleTabChangeOperationOverviewTab}
        />
        <MetricsSummary loading={loading} trxProfileSummary={trxProfileSummary} />

        <Section style={{ margin: '30px auto' }}>
          <Row align="middle" justify="start" gutter={[15, 15]}>
            <Col span={24}>
              <h5
                className="heading-05-regular"
                style={{ marginBottom: '19px', fontWeight: 400, color: theme.colors.softBlack }}
              >
                {useFormatMessage('dashboard.homePage.utilizationInsights.heading', 'Utilization Insights')}
              </h5>
            </Col>
          </Row>
          <UtlizationInsightContainer
            className={`${utilizationProfileEmpty ? 'empty-utilization-insight-container' : ''}`}
          >
            <Row align="middle" justify="start" gutter={[0, 16]} className="content">
              <Col xs={24} md={24} lg={16} xl={18} xxl={18} style={{ borderRight: '1px solid #dedede' }}>
                <p
                  className="paragraph-02-regular"
                  style={{ padding: '20px', color: '#000', opacity: 0.6, fontWeight: 700 }}
                >
                  {useFormatMessage(
                    'dashboard.homePage.utilizationInsights.numberOfConnectorsPluggedIn',
                    '# of Connectors Plugged In',
                  )}
                </p>
                {utilizationInsightChargerProfiles && (
                  <Tabs
                    className="utlization-insight-tabs"
                    onChange={handleTabChangeUtilizationInsightTab}
                    activeKey={utlizationInsightTabKey}
                  >
                    <Tabs.TabPane
                      tab={<span className="regular-cap">{renderFormatMessage('dashboard.text.all', 'All')}</span>}
                      key="All"
                    >
                      {utilizationProfileEmpty ? (
                        <EmptyData />
                      ) : (
                        <>
                          {utilizationAllVisible && (
                            <HomePageUtilizationInsightsChart
                              tabKey={utlizationInsightTabKey}
                              chargerProfile={utilizationInsightChargerProfiles}
                              timezone={timeZone}
                            />
                          )}
                        </>
                      )}
                    </Tabs.TabPane>

                    <Tabs.TabPane
                      tab={
                        <span className="regular-cap">{renderFormatMessage('dashboard.text.weekday', 'Weekday')}</span>
                      }
                      key="Weekday"
                    >
                      {utilizationProfileEmpty ? (
                        <EmptyData />
                      ) : (
                        <>
                          {utilizationWeekdayVisible && (
                            <HomePageUtilizationInsightsChart
                              tabKey={utlizationInsightTabKey}
                              chargerProfile={utilizationInsightChargerProfiles}
                              timezone={timeZone}
                            />
                          )}
                        </>
                      )}
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={
                        <span className="regular-cap">{renderFormatMessage('dashboard.text.weekend', 'Weekend')}</span>
                      }
                      key="Weekend"
                    >
                      {utilizationProfileEmpty ? (
                        <EmptyData />
                      ) : (
                        <>
                          {utilizationWeekendVisible && (
                            <HomePageUtilizationInsightsChart
                              tabKey={utlizationInsightTabKey}
                              chargerProfile={utilizationInsightChargerProfiles}
                              timezone={timeZone}
                            />
                          )}
                        </>
                      )}
                    </Tabs.TabPane>
                  </Tabs>
                )}
              </Col>
              <Col xs={24} md={24} lg={8} xl={6} xxl={6}>
                <>
                  {trxProfileSummary && (
                    <Tabs
                      className={`utlization-insight-tabs-2 ${utilizationGauageEmpty ? 'empty' : ''}`}
                      onChange={handleTabUtilizationInsightChargingDurationTab}
                      activeKey={utlizationInsightGaugeTabKey}
                    >
                      <Tabs.TabPane
                        tab={<span className="regular-cap">{renderFormatMessage('dashboard.text.all', 'All')}</span>}
                        key="All"
                      >
                        {utilizationGauageEmpty ? (
                          <EmptyData />
                        ) : (
                          <>
                            {chargingDurationAllVisible && (
                              <UtilizationGaugeInfoContainer>
                                <HomePageGaugeChartUtlizationInsight
                                  trxSummary={trxProfileSummary}
                                  activeKey={utlizationInsightGaugeTabKey}
                                />
                              </UtilizationGaugeInfoContainer>
                            )}
                          </>
                        )}
                      </Tabs.TabPane>

                      <Tabs.TabPane
                        tab={
                          <span className="regular-cap">{renderFormatMessage('dashboard.text.level2', 'Level 2')}</span>
                        }
                        key="Level2"
                      >
                        {utilizationGauageEmpty ? (
                          <EmptyData />
                        ) : (
                          <>
                            {chargingDurationLevel2Visible && (
                              <UtilizationGaugeInfoContainer>
                                <HomePageGaugeChartUtlizationInsight
                                  trxSummary={trxProfileSummary}
                                  activeKey={utlizationInsightGaugeTabKey}
                                />
                              </UtilizationGaugeInfoContainer>
                            )}
                          </>
                        )}
                      </Tabs.TabPane>
                      <Tabs.TabPane tab={<span className="regular-cap">DCFC</span>} key="DCFC">
                        {utilizationGauageEmpty ? (
                          <EmptyData />
                        ) : (
                          <>
                            {chargingDurationDCFCVisible && (
                              <UtilizationGaugeInfoContainer>
                                <HomePageGaugeChartUtlizationInsight
                                  trxSummary={trxProfileSummary}
                                  activeKey={utlizationInsightGaugeTabKey}
                                />
                              </UtilizationGaugeInfoContainer>
                            )}
                          </>
                        )}
                      </Tabs.TabPane>
                    </Tabs>
                  )}
                </>
              </Col>
            </Row>
          </UtlizationInsightContainer>
        </Section>
        <Section style={{ margin: '30px auto' }}>
          <Row
            align="middle"
            justify="start"
            gutter={[15, 15]}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}
          >
            <Col span={24}>
              <h5
                className="heading-05-regular"
                style={{ color: theme.colors.softBlack, marginBottom: '14px', fontWeight: 400 }}
              >
                {useFormatMessage('dashboard.homePage.consumptionInsights.heading', 'Consumption Insights')}
              </h5>
            </Col>
          </Row>
          <ConsumptionInsightContainer className={averageDailyLoadProfileEmpty ? 'empty-consumption-container' : ''}>
            <Row align="middle" justify="start" gutter={[0, 16]} className="content">
              <Col
                sm={24}
                md={24}
                lg={18}
                xl={18}
                xxl={18}
                style={{ borderRight: '1px solid #dedede', backgroundColor: '#fff' }}
              >
                <p
                  className="paragraph-02-regular"
                  style={{ padding: '20px', color: '#242E2C', opacity: 0.6, fontWeight: 700 }}
                >
                  {useFormatMessage('dashboard.homePage.consumptionInsights.chart.title', 'Average Daily Load Profile')}
                </p>
                <Tabs
                  className="utlization-insight-tabs consumption"
                  onChange={handleTabChangeConsumptionInsightTab}
                  activeKey={consumptionInsightLoadProfileTabKey}
                >
                  <Tabs.TabPane
                    tab={<span className="regular-cap">{renderFormatMessage('dashboard.text.all', 'All')}</span>}
                    key="All"
                  >
                    {averageDailyLoadProfileEmpty ? (
                      <EmptyData />
                    ) : (
                      <>
                        {dailyProfiles && consumptionAllVisible && (
                          <HomePageComumptionInsightLoadProfileChart
                            tabKey={consumptionInsightLoadProfileTabKey}
                            dailyProfile={dailyProfiles}
                            timezone={timeZone}
                          />
                        )}
                      </>
                    )}
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <span className="regular-cap">{renderFormatMessage('dashboard.text.weekday', 'Weekday')}</span>
                    }
                    key="Weekday"
                  >
                    {averageDailyLoadProfileEmpty ? (
                      <EmptyData />
                    ) : (
                      <>
                        {dailyProfiles && consumptionWeekdayVisible && (
                          <HomePageComumptionInsightLoadProfileChart
                            tabKey={consumptionInsightLoadProfileTabKey}
                            dailyProfile={dailyProfiles}
                            timezone={timeZone}
                          />
                        )}
                      </>
                    )}
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <span className="regular-cap">{renderFormatMessage('dashboard.text.weekend', 'Weekend')}</span>
                    }
                    key="Weekend"
                  >
                    {averageDailyLoadProfileEmpty ? (
                      <EmptyData />
                    ) : (
                      <>
                        {dailyProfiles && consumptionWeekendVisible && (
                          <HomePageComumptionInsightLoadProfileChart
                            tabKey={consumptionInsightLoadProfileTabKey}
                            dailyProfile={dailyProfiles}
                            timezone={timeZone}
                          />
                        )}
                      </>
                    )}
                  </Tabs.TabPane>
                </Tabs>
              </Col>
              <Col sm={24} md={24} lg={6} xl={6} xxl={6}>
                <ConsumptionInsightMetaContainer>
                  <CardWrapper>
                    <Card
                      title={
                        <>
                          {uniqueUserSVG}
                          <p className="paragraph-02-regular">
                            <b>
                              {useFormatMessage(
                                'dashboard.homePage.consumptionInsights.uniqueUsers.heading',
                                'Unique users',
                              )}
                            </b>
                          </p>
                        </>
                      }
                      loading={loading}
                      className="uniqueUsers"
                    >
                      {trxProfileSummary && <h3 className="heading-03-black">{trxProfileSummary.usersTotal}</h3>}
                    </Card>
                  </CardWrapper>
                  <CardWrapper>
                    <Card
                      title={
                        <>
                          {exchangeSVG}
                          <p className="paragraph-02-regular">
                            <b>
                              {useFormatMessage(
                                'dashboard.homePage.consumptionInsights.avgNumberOfTransactions.heading',
                                'Average number of transactions',
                              )}
                            </b>
                          </p>
                        </>
                      }
                      loading={loading}
                      className="avgNumberOfTransactions"
                    >
                      {trxProfileSummary && (
                        <h3 className="heading-03-black">
                          {trxProfileSummary.avgTxnDay}
                          <sub className="paragraph-04-regular">{`/ ${dayText}`}</sub>
                        </h3>
                      )}
                    </Card>
                  </CardWrapper>
                  <CardWrapper>
                    <Card
                      title={
                        <>
                          {boltSVG}
                          <p className="paragraph-02-regular">
                            {renderFormatMessage(
                              'dashboard.homePage.consumptionInsights.averageConsumption.heading',
                              'Average energy consumption',
                            )}
                          </p>
                        </>
                      }
                      loading={loading}
                      className="averageConsumption"
                    >
                      {trxProfileSummary && (
                        <h3 className="heading-03-black">
                          {`${Number.parseFloat(`${trxProfileSummary.avgEnergyConsumePerTxn / 1000}`).toFixed(2)} kWh`}
                          <sub className="paragraph-04-regular">/ transaction</sub>
                        </h3>
                      )}
                    </Card>
                  </CardWrapper>
                  <CardWrapper>
                    <Card
                      title={
                        <>
                          {dollarSVG}
                          <p className="paragraph-02-regular">
                            {renderFormatMessage(
                              'dashboard.homePage.consumptionInsights.averageConsumption.averageUserSpend',
                              'Average transaction amount',
                            )}
                          </p>
                        </>
                      }
                      loading={loading}
                      className="averageUserSpend"
                    >
                      {trxProfileSummary && (
                        <h3 className="heading-03-black">
                          ${trxProfileSummary.avgUserSpend / 100}
                          <span className="paragraph-04-regular">/ transaction</span>
                        </h3>
                      )}
                    </Card>
                  </CardWrapper>
                </ConsumptionInsightMetaContainer>
              </Col>
            </Row>
          </ConsumptionInsightContainer>
        </Section>
        <Section style={{ margin: '30px auto' }}>
          <Row gutter={[15, 15]} style={{ marginBottom: '15px' }}>
            <Col sm={24} md={24} lg={7} xl={7} xxl={6}>
              <>
                <h5
                  className="heading-05-regular"
                  style={{ marginBottom: 0, paddingTop: '5px', fontWeight: 400, color: theme.colors.softBlack }}
                >
                  {useFormatMessage('dashboard.homePage.stationActivation.heading', 'Station Activation')}
                </h5>
              </>
            </Col>
            <Col sm={24} md={24} lg={17} xl={17} xxl={18} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h5
                className="heading-05-regular"
                style={{ marginBottom: 0, paddingTop: '5px', fontWeight: 400, color: theme.colors.softBlack }}
              >
                {useFormatMessage('dashboard.homePage.transactionOverview.heading', 'Transaction Overview')}
              </h5>
              <Button type="ghost" className="trnx-btn">
                <Link
                  to={Links.transactions()}
                  onClick={() => {
                    document.querySelector('body')?.classList.remove('redesignActive')
                  }}
                >
                  <span className="regular-cap">
                    {useFormatMessage('dashboard.homePage.seeAllTransactions.heading', 'See All Transactions')}
                  </span>
                </Link>
              </Button>
            </Col>
          </Row>
          <Row gutter={[15, 15]}>
            <Col sm={24} md={24} lg={7} xl={7} xxl={6}>
              <HomePageStationActivationContainer className={`${stationActivationDataEmpty ? 'empty-container' : ''}`}>
                {stationActivationDataEmpty ? (
                  <EmptyData />
                ) : (
                  <>{stationActivation && <HomePageStationActivation stationActivation={stationActivation} />}</>
                )}
              </HomePageStationActivationContainer>
            </Col>
            <Col sm={24} md={24} lg={17} xl={17} xxl={18}>
              <HomePageTransactionOverviewTableContainer>
                {transactions && <HomePageTransactionOverview transactions={transactions} />}
              </HomePageTransactionOverviewTableContainer>
            </Col>
          </Row>
        </Section>
      </Spin>
    </HomePageContainer>
  )
}
