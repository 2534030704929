const renderSmartCharging = (smartCharging?: string): string => {
  let smartChargingName = ''
  switch (smartCharging) {
    case 'monitoring_only':
      smartChargingName = 'Monitoring Only'
      break
    case 'swtch_control':
      smartChargingName = 'SWTCH Control'
      break
    case 'local':
      smartChargingName = 'Local'
      break
    case 'unknown':
      smartChargingName = 'Unknown'
      break
    default:
      smartChargingName = 'None'
      break
  }
  return smartChargingName
}

export default renderSmartCharging