import { Button, Col, PageHeader, Row, Space, Spin, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { Box } from '../../atom/box'

import { AlertError } from '../../components/error'
import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { Links } from '../../components/routes/paths'

import { SwtchError } from '../../models/error'

import _ from 'lodash'
import styled from 'styled-components'
import { PeakShavingProgram } from '../../models/peak-shaving'
import { GetAllPeakShavingPrograms } from '../../services/data-provider/peak-shaving'
import { CreateNewProgramModal } from './create-new-program-modal'
import { PeakShavingEventFilter } from '../../models/filter'
import { PaginationMeta } from '../../models/pagination'
import { useAppState } from '../../state'
import { peakPowerEnergyFolks } from './peak-shaving-events-page'

const PeakShavingEventsPageContainer = styled.div`
  .category {
    text-transform: capitalize;
  }
`

const PeakShavingProgramsBasePage: React.FC = () => {
  const [peekShavingPrograms, setPeekShavingPrograms] = useState<PeakShavingProgram[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const [createNewProgramModalVisible, setCreateNewProgramModalVisible] = useState(false)
  const [filter, setFilter] = useState<PeakShavingEventFilter>({ page: 1, perPage: 10 })
  const [pagination, setPagination] = useState<PaginationMeta>()
  const { currentUser } = useAppState()


  const [getAllPeakShavingProgramterm, setGetAllPeakShavingProgramTerm] = useState(
    `${_.random(Number.MIN_VALUE, Number.MAX_VALUE)}`,
  )

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Name',
      render: (program: PeakShavingProgram) => (
        <Link to={Links.peakShavingProgram({ programId: `${program.id}` })}>{program.name}</Link>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      className: 'category',
    },
    {
      title: 'Initiative',
      dataIndex: 'initiative',
    },
    {
      title: 'State',
      dataIndex: 'state',
    },
  ]

  useEffect(() => {
    document.querySelector('body')?.classList.add('redesignActive')
    setLoading(true)

    if (currentUser && peakPowerEnergyFolks.includes(currentUser?.email)) {
      filter.programName = 'Ontario’s Global Adjustment Program'
    }

    GetAllPeakShavingPrograms(filter)
      .then(({ data, pagination }) => {
        setPeekShavingPrograms(data)
        setPagination(pagination)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [getAllPeakShavingProgramterm, filter])

  const handleOnSubmit = () => {
    setGetAllPeakShavingProgramTerm(`${_.random(Number.MIN_VALUE, Number.MAX_VALUE)}`)
    setCreateNewProgramModalVisible(!createNewProgramModalVisible)
  }

  const onPaginationChange = (page: number, pageSize?: number) => {
    setFilter({ ...filter, page })
  }

  return (
    <Spin spinning={loading}>
      <PeakShavingEventsPageContainer>
        <PageHeader
          title="Programs"
          extra={
            <Space>
              <Button type="primary" onClick={() => setCreateNewProgramModalVisible(!createNewProgramModalVisible)}>
                Create New Program
              </Button>
            </Space>
          }
        />
        <Box>
          <Row>
            <Col span={24}>
              <AlertError error={error} />
              <Table
                columns={columns}
                dataSource={peekShavingPrograms}
                loading={loading}
                rowKey="id"
                pagination={{
                  position: ['bottomCenter'],
                  total: pagination?.totalEntries,
                  current: pagination?.currentPage,
                  pageSize: pagination?.perPage,
                  showSizeChanger: false,
                  onChange: onPaginationChange,
                }}
              />
            </Col>
          </Row>
        </Box>
        <CreateNewProgramModal
          visible={createNewProgramModalVisible}
          onCancel={() => setCreateNewProgramModalVisible(!createNewProgramModalVisible)}
          onSubmit={handleOnSubmit}
        />
      </PeakShavingEventsPageContainer>
    </Spin>
  )
}

export const PeakShavingProgramsPage = withAuthenticatedLayout(PeakShavingProgramsBasePage)
