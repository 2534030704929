import { Links } from '../../../components/routes/paths'
import ServiceStatusBadge from '../../../components/connectors/ServiceStatusBadge'

import { ChargerBase } from '../../../models/charger'
import { ConnectorV2 } from '../../../models/connector'

import { theme } from '../../../theme'
import { SpaceBetween } from '../../../atom/group'
import { DCFastChargingSVG, J1722ConnectorSVG } from '../../../assets/svg/connectorPlug'

interface props {
  charger?: ChargerBase
  connector?: ConnectorV2
}

const handleChargerPlug = (charger?: ChargerBase, connector?: ConnectorV2) => {
  let plugIcon = J1722ConnectorSVG
  if (charger?.chargingType === 'DC') {
    plugIcon = DCFastChargingSVG
  }

  return (
    <SpaceBetween>
      {plugIcon}
      <span className="opacity-06 paragraph-04-light">{connector ? connector.connectorId : null}</span>
      {connector && connector.ocppStatus ? <ServiceStatusBadge state={connector.ocppStatus} /> : null}
    </SpaceBetween>
  )
}

const ListingChargerAndConnector: React.FC<props> = ({ charger, connector }) => {
  return (
    <div className="flex-and-gap-below">
      {charger && connector ? (
        <ChargerSerialNumber charger={charger} connector={connector} />
      ) : (
        <ChargerSerialNumber charger={charger} />
      )}
      {handleChargerPlug(charger, connector)}
    </div>
  )
}

const ChargerSerialNumber: React.FC<{ charger?: ChargerBase; connector?: ConnectorV2 }> = ({ charger, connector }) => {
  if (connector) {
    return (
      <div className="pointer underline">
        <a
          key={charger?.id}
          style={{ color: theme.colors.softBlack }}
          href={Links.charger({ chargerId: charger?.id, connectorId: connector.id })}
          target="_blank"
          rel="noopener noreferrer"
        >
          {charger?.chargePointSerialNumber || '-'}
        </a>
      </div>
    )
  } else {
    return (
      <div className="pointer underline">
        <a
          key={charger?.id}
          style={{ color: theme.colors.softBlack }}
          href={Links.charger({ chargerId: charger?.id, connectorId: 1 })}
          target="_blank"
          rel="noopener noreferrer"
        >
          {charger?.chargePointSerialNumber || '-'}
        </a>
      </div>
    )
  }
}

export default ListingChargerAndConnector
