import { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { useFormatMessage } from '../../../../helpers/intl'
import { StationActivation } from '../../../../models/transaction'
import { useAppState } from '../../../../state'
import { styled, theme } from '../../../../theme'

const HomePageStationActivationContainer = styled.div`
  .highcharts-color-3 {
    fill: #54dec7;
    stroke: #54dec7;
  }

  .highcharts-color-1 {
    fill: #026e78;
    stroke: #026e78;
  }

  .highcharts-color-2 {
    fill: #242e2c;
    stroke: #242e2c;
  }

  .highcharts-color-0 {
    fill: #c8f9e8;
    stroke: #c8f9e8;
  }

  div[data-highcharts-chart],
  .highcharts-container,
  svg.highcharts-root {
    overflow: visible !important;
  }

  .highcharts-background {
    fill: transparent;
  }

  .highcharts-data-label {
    font-size: 1.17em;
  }

  .highcharts-data-label text {
    font-family: 'AkkuratLLWeb-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
  }

  .highcharts-pie-series .highcharts-point {
    -webkit-filter: none !important;
    filter: none !important;
    stroke-width: 0 !important;
  }

  div[data-highcharts-chart] {
    margin: 0 auto;
  }

  .paragraph-02-regular tspan {
    font-weight: bold;
    fill: #000;
  }

  .highcharts-legend {
    pointer-events: none;
  }
`

interface props {
  stationActivation: StationActivation
}

export const HomePageStationActivation: React.FC<props> = ({ stationActivation }) => {
  const webApp = useFormatMessage('dashboard.homePage.stationActivation.webapp', 'Web App')
  const roamingPartner = useFormatMessage('dashboard.homePage.stationActivation.roamingpartner', 'Roaming Partner')
  const mobileApp = useFormatMessage('dashboard.homePage.stationActivation.mobileapp', 'Mobile App')

  const { language } = useAppState()

  const [stationActivationChartOptions, setStationActivationChartOptions] = useState({
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      styled: true,
      events: {
        render: function () {
          //@ts-ignore
          this.reflow()
        },
      },
    },
    title: {
      text: '',
      align: 'left',
    },
    tooltip: {
      enabled: false,
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },

    legend: {
      layout: 'vertical',
      backgroundColor: '#FFFFFF',
      align: 'left',
      verticalAlign: 'bottom',
      floating: true,
      itemMarginTop: 10,
      itemMarginBottom: 10,
      symbolRadius: 3,
      itemStyle: {
        color: '#858a88',
        fontFamily: 'AkkuratLLWeb-Regular',
        fontWeight: 400,
        cursor: 'auto',
      },
      enableMouseTracking: false,
      labelFormatter: function (): any {
        //@ts-ignore

        var output = `<span class='paragraph-02-regular seriesName'> ${
          //@ts-ignore
          this.name
          //@ts-ignore
        } </span> <span class='paragraph-02-regular'><b> (${this.y})</b></span>`
        return output
      },
      x: 10,
      y: 140,
    },

    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        innerSize: 90,
        dataLabels: {
          enabled: true,
          distance: -40,
          style: {
            textOutline: null,
          },
          //@ts-ignore
          formatter: function () {
            //@ts-ignore
            if (this.y > 0) {
              //@ts-ignore
              return Highcharts.numberFormat(this.point.percentage, 1) + ' %'
            }
          },
        },
        showInLegend: true,
        center: ['50%', '50%'],
        size: '80%',
        slicedOffset: 1,
      },
      series: {
        states: {
          inactive: {
            enabled: false,
          },
        },
      },
    },

    series: [
      {
        name: 'Station Activation',
        colorByPoint: true,
        data: [
          {
            name: webApp,
            y: 0,
            color: '#C8F9E8',
            sliced: false,
            legendIndex: 2,
          },
          {
            name: 'RFID',
            y: 0,
            color: '#026E78',
            sliced: false,
            legendIndex: 3,
          },
          {
            name: roamingPartner,
            y: 0,
            color: '#242e2c',
            sliced: false,
            legendIndex: 4,
          },
          {
            name: mobileApp,
            y: 0,
            color: '#54dec7',
            sliced: false,
            legendIndex: 1,
          },
        ],
        point: {
          events: {
            mouseOver: function () {
              const point = this,
                //@ts-ignore
                chart = this.series.chart,
                //@ts-ignore
                legendItemGroup = this.legendItem.group,
                { y, height } = legendItemGroup.element.getBBox()

              chart.renderedLegendRect = chart.renderer
                .rect(-50, y - 10, 9999999999, height + 20)
                .attr({
                  fill: '#f4f6f4',
                  stroke: '#f4f6f4',
                })
                .add(legendItemGroup)
              //@ts-ignore
              point.graphic.attr({
                transform: `scale(1.08) `,
                'transform-origin': 'center',
              })
              //@ts-ignore
              point.graphic.css({
                transition: 'transform .5s',
              })
            },
            mouseOut: function () {
              const point = this
              //@ts-ignore
              point.series.chart.renderedLegendRect.destroy()
              //@ts-ignore
              point.graphic.attr({
                transform: `scale(1) `,
                'transform-origin': 'center',
              })
            },
          },
        },
        states: {
          hover: {
            opacity: 1,
            halo: null,
          },
        },
      },
    ],
  })

  useEffect(() => {
    setStationActivationChartOptions({
      ...stationActivationChartOptions,
      series: [
        {
          ...stationActivationChartOptions.series[0],
          data: [
            {
              name: webApp,
              y: stationActivation.web,
              color: theme.colors.magicMint,
              sliced: false,
              legendIndex: 2,
            },
            {
              name: 'RFID',
              y: stationActivation.rfid,
              color: theme.colors.caribbeanCurrent,
              sliced: false,
              legendIndex: 3,
            },
            {
              name: roamingPartner,
              y: stationActivation.roaming,
              color: theme.colors.softBlack,
              sliced: false,
              legendIndex: 4,
            },

            {
              name: mobileApp,
              y: stationActivation.mobile,
              color: theme.colors.neonGreen,
              sliced: false,
              legendIndex: 1,
            },
          ],
        },
      ],
    })
  }, [stationActivation, language])

  return (
    <HomePageStationActivationContainer>
      <HighchartsReact highcharts={Highcharts} options={stationActivationChartOptions} />
    </HomePageStationActivationContainer>
  )
}
