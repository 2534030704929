import { Badge } from 'antd'

import { ConnectivityState } from '../../../models/listing'

export const handleConnectivity = (connectivity: ConnectivityState) => {
  switch (connectivity) {
    case false:
      return <Badge status="error" />
    case true:
      return <Badge status="success" />
    case 'connecting':
      return <Badge status="warning" />
    default:
      return <Badge status="default" /> // CONNECTIVITY.NO_CONNECTION
  }
}
