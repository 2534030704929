import { Checkbox, Select } from 'antd'
import { ReactElement, ReactNode, useState, MouseEvent } from 'react'

import { renderFormatMessage } from '../../helpers/intl'
import { renderConnectionMessage } from '../../helpers/status'
import { magnifySVG } from '../../assets/svg/magnify'
import { useAppState } from '../../state'
import { MultiOptionSelectorWrapper } from '../../atom/select'
import { SELECTOR_OPTIONS } from '../../helpers/select'

interface props<T> {
  options: T[]
  defaultValues?: T[]
  onOptionsChange: (options: T[]) => void
  onClear: () => void
  onSearch: (options: T[]) => void
  placeholder: string
  loading: boolean
  isCombineLeft?: boolean
}

const MultiOptionSelector = <T extends string>(props: props<T> & { children?: ReactNode }) => {
  const { defaultValues, options, onOptionsChange, onClear, onSearch, placeholder, loading, isCombineLeft } = props
  const { IsDesktop, IsLaptop } = useAppState()

  const [selectedOptions, setSelectedOptions] = useState([''])
  const [dirty, setDirty] = useState(false)

  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()

  const handleChange = (values: string[]) => {
    setSelectedOptions(values)
    setDirty(true)
    onOptionsChange((values as unknown) as T[])
  }

  const handleOnSearch = (input: string) => {
    options.forEach((opt) => {
      if (opt.toLowerCase() === input.toLowerCase()) {
        onSearch(options)
      }
    })
  }

  const renderMultiOptions = (options: T[]) => {
    const final = options
      .map((v) => renderOption(v, placeholder))
      .sort((a: ReactElement, b: ReactElement) => {
        return b.props.children.props.checked - a.props.children.props.checked
      })
    return final
  }

  const renderOption = (option: string, placeholder?: string): ReactElement => {
    const translateOpt = renderFormatMessage(renderConnectionMessage(option), option)
    // Vendors have fixed names so it doesn't need translation.
    const modifiedOption = placeholder !== 'Select Vendor' ? translateOpt : option

    return (
      <Select.Option key={option} value={option}>
        <Checkbox onClick={handleDirty} checked={selectedOptions.includes(option)}>
          {modifiedOption}
        </Checkbox>
      </Select.Option>
    )
  }

  const humanizeFirstSelected = renderFormatMessage(renderConnectionMessage(selectedOptions[0]), selectedOptions[0])

  const handleMaxTagPlaceholder = () => {
    return selectedOptions.length === 1 ? humanizeFirstSelected : `${selectedOptions.length} Service Statuses Selected`
  }

  const handleDirty = (e: MouseEvent) => (dirty ? e.stopPropagation() : setDirty(false))

  const handleMouseDown = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    setDirty(false)
    e.stopPropagation()
  }

  const canCombineBorder = (isDesktop || isLaptop) && isCombineLeft

  return (
    <MultiOptionSelectorWrapper isCombineLeft={canCombineBorder}>
      {magnifySVG}
      <Select
        mode="multiple"
        allowClear
        showArrow
        disabled={loading}
        style={{ width: '100%', lineHeight: '34px' }}
        placeholder={SELECTOR_OPTIONS[placeholder]}
        defaultValue={defaultValues}
        onChange={handleChange}
        onClear={onClear}
        onMouseDown={handleMouseDown}
        dropdownMatchSelectWidth={false}
        onSearch={(e) => handleOnSearch(e)}
        maxTagCount={0}
        maxTagPlaceholder={handleMaxTagPlaceholder}
      >
        {renderMultiOptions(options)}
      </Select>
    </MultiOptionSelectorWrapper>
  )
}

export default MultiOptionSelector
