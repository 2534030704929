import { styled, theme } from '../theme'

interface props {
  padding?: boolean
}

export const Box = styled.div<props>`
  background-color: white;
  margin: 8px 0;
  padding: ${(props) => (props.padding ? '10px' : 0)};
`

export const Section = styled.div`
  margin: 10px 0;
`

export const GreyBackground = styled.div`
  background-color: ${theme.colors.grey11};
  padding: '12px';
  border-radius: '12px;
`
