import { useParams } from 'react-router-dom'
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Result,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tag,
} from 'antd'
import { useEffect, useState } from 'react'
import { SelectProps } from 'antd/es/select'

import { layout, tailLayout } from '../../atom/form/page-layout'

import { SwtchError } from '../../models/error'
import { PriceType } from '../../models/price'
import { StripeAccount } from '../../models/stripe-account'
import { LoiteringStatusType } from '../../models/loitering'
import { Tenant, TenantListingAuthorsType, TenantUniformPricing } from '../../models/tenant'

import { GetStripeAccounts } from '../../services/data-provider/stripe'
import { GetTenant, UpdateTenantUniformPricing } from '../../services/data-provider/tenants'

import { renderFormatMessage } from '../../helpers/intl'
import { MustBePositiveIntegers } from '../rules/rules'
import { AlertError } from '../error'
import { styled } from '../../theme'
import { useTenantTabPricingTranslation } from '../../hooks/translation/useTenantTabPricingTranslation'

interface tenantTabDetailProps {
  tenant: Tenant
  onSave: (tenant: Tenant) => void
}

interface StripeAccountOption {
  value: string
  label: JSX.Element
  stripeAccount: StripeAccount
}

const InputNumberContainer = styled.div`
  .ant-input-number-input-wrap > input {
    text-align: center;
  }
`

const renderStripeAccount = (stripeAccount: StripeAccount): StripeAccountOption => {
  return {
    value: stripeAccount.name,
    stripeAccount,
    label: <span title={stripeAccount.name}>{`${stripeAccount.name} [${stripeAccount.country || '---'}]`}</span>,
  }
}

// TODO: refactor this page using SOLID principle and atomic design
export const TenantTabPricingV2: React.FC<tenantTabDetailProps> = ({ tenant, onSave }) => {
  // @ts-ignore
  const { tenantId } = useParams()
  const [defaultTenant, setDefaultTenant] = useState<Tenant>(tenant)
  const { uniformPricing, tenantUniformPricing } = defaultTenant
  const [uniformPricingObj, setUniformPricingObj] = useState<TenantUniformPricing>(tenantUniformPricing)
  const {
    tenantListingAuthors,
    pricePerUnitCents,
    priceType,
    flatFee,
    additionalServiceFee,
    guestFeeCents,
    paymentLimitCents,
    loiteringStatus,
    loiteringGracePeriod,
    loiteringTimeLimit,
    loiteringPenalty,
    preferredPricingPriceType,
    preferedPricePerUnitCents,
    commisionPercent,
    stripeAccountId,
    disableLoitering
  } = tenantUniformPricing

  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [authorId, setAuthorId] = useState<string>(tenantUniformPricing.authorId)

  const initialStripeAccount = tenantUniformPricing.tenantListingStripeAccounts.find((o) => o.id === stripeAccountId)

  const [defaultStripeAccount, setDefaultStripeAccount] = useState<StripeAccount | undefined>(initialStripeAccount)
  const [stripeAccountOptions, setStripeAccountOptions] = useState<SelectProps<object>['options']>([])
  const [uniformPricingCheckBoxChecked, setUniformPricingCheckBoxChecked] = useState(uniformPricing)
  const [isLoiteringDisabled, setIsLoiteringDisabled] = useState(disableLoitering)
  const [pricingType, setPricingType] = useState<PriceType>(priceType)
  const [enableTieredPricing, setEnableTieredPricing] = useState(uniformPricingObj.enableTieredPricing)
  const [enableTimeOfDayPricing, setEnableTimeOfDayPricing] = useState(uniformPricingObj.enableTimeOfDayPricing)
  const [comissionFeeType, setComissionFeeType] = useState<String>(
    commisionPercent === 0 ? '2.9% + 30c' : commisionPercent === 10 ? '10%' : 'defineNew',
  )
  const [loiteringStatusType, setLoiteringStatusType] = useState<LoiteringStatusType>(loiteringStatus)
  const [enableLoadManagementPricing, setEnableLoadManagementPricing] = useState(
    uniformPricingObj.enableLoadManagementPricing,
  )

  const [timeOfDayPriceWeekday, setTimeOfDayPricingWeekDay] = useState<number[]>(
    uniformPricingObj.timeOfDayPriceWeekday.length === 0
      ? Array(24).fill(tenantUniformPricing.pricePerUnitCents / 100)
      : uniformPricingObj.timeOfDayPriceWeekday.map((num) => num / 100),
  )
  const [timeOfDayPriceWeekend, setTimeOfDayPricingWeekEnd] = useState<number[]>(
    uniformPricingObj.timeOfDayPriceWeekend.length === 0
      ? Array(24).fill(tenantUniformPricing.pricePerUnitCents / 100)
      : uniformPricingObj.timeOfDayPriceWeekend.map((num) => num / 100),
  )

  const [error, setError] = useState<SwtchError>()
  const [form] = Form.useForm()

  const [checkPricePerUnitCentsValue, setCheckPricePerUnitCentsValue] = useState<number>(pricePerUnitCents)

  const {
    uniformPricingUpdatedText,
    uniformEnabledText,
    uniformDisabledText,
    listingAuthorText,
    infoTextp1,
    infoTextp2,
    stripeAccountText,
    selectListingAuthorText,
    selectStripeAccountText,
    priceCentsText,
    enableLoadManagementPricingText,
    enableTenantPreferredPricing,
    tenantPreferredPricingText,
    tenantPreferredPricingWarningText,
    tenantPreferredPricingPriceTypeWarningText,
    priceTypeHourText,
    priceText,
    flatFeeText,
    defineNewComissionTypeText,
    defineNewComissionFeeText,
    defineNewComissionTypeWarningText,
    guestFeeText,
    paymentLimitText,
    loiteringStatusText,
    loiteringStatusNoneText,
    loiteringStatusStatusBasedText,
    loiteringStatusTimeBasedText,
    loiteringPenaltyPerHourText,
    loiteringtimeLimitText,
    emptyFieldWarning,
    loiteringtimeLimitGraceMinutesText,
    selectComissionFeeText,
    selectPreferredPricingOptionText,
    selectPricingOptionText,
    uniformPricingText,
    enableText,
    priceTxt,
    todTxt,
    weekdaysTxt,
    weekendsTxt,
    startTxt,
    endTxt,
    disableLoiteringTxt,
  } = useTenantTabPricingTranslation()

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (checkPricePerUnitCentsValue !== pricePerUnitCents) {
        setEnableTimeOfDayPricing(false)
      }
    }, 2000)

    return () => clearTimeout(delayDebounceFn)
  }, [checkPricePerUnitCentsValue])

  const defaultAuthor = () => {
    const [defaultAuthorName] = tenantListingAuthors
      .filter((o: TenantListingAuthorsType) => o.userId === authorId)
      .map((o) => o.userId)
    return defaultAuthorName
  }

  const initialValues = {
    authorId: defaultAuthor(),
    stripeAccountId,
    priceType,
    pricePerUnitCents: (pricePerUnitCents / 100).toFixed(2),
    additionalServiceFee,
    enableTieredPricing,
    preferredPricingPriceType,
    preferedPricePerUnitCents: (preferedPricePerUnitCents / 100).toFixed(2),
    enableLoadManagementPricing,
    commisionPercent: commisionPercent === 0 ? '2.9% + 30c' : commisionPercent === 10 ? '10%' : 'defineNew',
    flatFee,
    guestFeeCents: (guestFeeCents / 100).toFixed(2),
    paymentLimitCents: (paymentLimitCents / 100).toFixed(2),
    loiteringStatus,
    loiteringGracePeriod,
    loiteringPenalty,
    loiteringTimeLimit,
    enableTimeOfDayPricing,
    disableLoitering,
  }

  const toggleTenantUniformPricing = async () => {
    setLoading(true)
    setDisabled(true)
    onSave({
      ...tenant,
      uniformPricing: !uniformPricingCheckBoxChecked,
    })
    !uniformPricingCheckBoxChecked ? message.success(uniformEnabledText) : message.success(uniformDisabledText)
    setLoading(false)
  }

  const uniformPricingInfo = (
    <>
      <p>{infoTextp1}</p>
      <p>{infoTextp2}</p>
    </>
  )

  const enablePricing = (
    <Button
      type="primary"
      onClick={() => {
        setUniformPricingCheckBoxChecked(!uniformPricingCheckBoxChecked)
        toggleTenantUniformPricing()
      }}
    >
      {enableText}
    </Button>
  )

  useEffect(() => {
    GetStripeAccounts(authorId, tenantId)
      .then((stripeAccounts) => {
        const opts = stripeAccounts.map((stripeAcc) => renderStripeAccount(stripeAcc))
        setStripeAccountOptions(opts)
      })
      .catch((err: SwtchError) => {
        setError(err)
        message.error(err.description)
      })
      .finally(() => setLoading(false))
  }, [authorId])

  useEffect(() => {
    GetTenant(tenantId)
      .then((tenant) => setDefaultTenant(tenant))
      .catch((err: SwtchError) => setError(err))
  }, [uniformPricingObj, isLoiteringDisabled])

  useEffect(() => {
    defaultTenant &&
      loading &&
      UpdateTenantUniformPricing(tenantId, uniformPricingObj)
        .then((tenant) => setDefaultTenant(tenant))
        .catch((err: SwtchError) => setError(err))
        .finally(() => {
          setDisabled(true)
          setLoading(false)
        })
  }, [uniformPricingObj])

  useEffect(() => {
    defaultTenant &&
      loading &&
      UpdateTenantUniformPricing(tenant.id, uniformPricingObj)
        .then((tenant) => setDefaultTenant(tenant))
        .catch((err: SwtchError) => setError(err))
        .finally(() => {
          setDisabled(true)
          setLoading(false)
        })
  }, [uniformPricingObj])

  const onFinish = async (values: any) => {
    setLoading(true)
    setUniformPricingObj((uniformPricingObj) => ({
      ...uniformPricingObj,
      authorId: values['authorId'],
      stripeAccountId: values['stripeAccountId'],
      priceType: values['priceType'],
      pricePerUnitCents: values['pricePerUnitCents'] * 100,
      additionalServiceFee: values['commisionPercent'] === '2.9% + 30c' ? 'on' : 'off',
      enableTieredPricing: enableTieredPricing,
      preferredPricingPriceType: values['priceType'],
      preferedPricePerUnitCents: values['preferedPricePerUnitCents'] * 100,
      enableLoadManagementPricing: enableLoadManagementPricing,
      commisionPercent:
        values['commisionPercent'] === 'defineNew'
          ? values['customComissionFee']
          : values['commisionPercent'] === '2.9% + 30c'
          ? 0
          : 10,
      flatFee: values['flatFee'],
      guestFeeCents: values['guestFeeCents'] * 100,
      paymentLimitCents: values['paymentLimitCents'] * 100,
      loiteringStatus: values['loiteringStatus'],
      loiteringGracePeriod: values['loiteringGracePeriod'],
      loiteringPenalty: values['loiteringPenalty'],
      loiteringTimeLimit: values['loiteringTimeLimit'],
      enableTimeOfDayPricing,
      timeOfDayPriceWeekday,
      timeOfDayPriceWeekend,
      disableLoitering: values['disableLoitering'],
    }))

    message.success(`${tenant.displayName || tenant.name} ${uniformPricingUpdatedText}`)
  }

  const todColumns = [
    {
      title: startTxt,
      dataIndex: 'startdate',
      key: 'startdate',
    },
    {
      title: endTxt,
      dataIndex: 'enddate',
      key: 'enddate',
    },
    {
      title: `${priceTxt} / ${pricingType === 'Hour' ? priceTypeHourText : 'kWh'}`,
      dataIndex: 'price',
      key: 'price',
    },
  ]

  const handleStripeAccount = (selectedStripeAccount: string) => {
    const finalStripeAccount = stripeAccountOptions?.find((stripeAccountOption) => {
      return stripeAccountOption.stripeAccount.name === selectedStripeAccount
    })

    setDefaultStripeAccount(finalStripeAccount?.stripeAccount)
    form.setFieldsValue({ stripeAccountId: finalStripeAccount?.stripeAccount.id })
    form.setFieldsValue({ stripeAccountName: finalStripeAccount?.stripeAccount.name })
  }

  const handleSelectAuthor = (selectedAuthor: string) => {
    setAuthorId(selectedAuthor)
    setDefaultStripeAccount(undefined)
    form.setFieldsValue({ stripeAccountName: null })
  }

  return (
    <Spin spinning={loading}>
      <AlertError error={error} />

      {!uniformPricingCheckBoxChecked && (
        <Row gutter={8}>
          <Col span={24} style={{ textAlign: 'center' }}>
            {!uniformPricingCheckBoxChecked && (
              <Result title={uniformPricingText} subTitle={uniformPricingInfo} extra={enablePricing} />
            )}
          </Col>
        </Row>
      )}

      <Form
        {...layout}
        hidden={!uniformPricingCheckBoxChecked}
        initialValues={{ ...initialValues }}
        form={form}
        onFinish={onFinish}
      >
        <Divider orientation="center" />

        <Form.Item name="uniformPricing" label={uniformPricingText}>
          <Switch
            checked={uniformPricingCheckBoxChecked}
            onChange={() => {
              setUniformPricingCheckBoxChecked(!uniformPricingCheckBoxChecked)
              toggleTenantUniformPricing()
            }}
          />
        </Form.Item>
        <Divider orientation="center">Author</Divider>
        <Form.Item name="authorId" label={listingAuthorText}>
          <Select
            showSearch
            placeholder={selectListingAuthorText}
            optionFilterProp="children"
            disabled={disabled}
            onSelect={handleSelectAuthor}
          >
            {tenantListingAuthors
              .filter((t) => tenantUniformPricing.tenantListingStripeAccounts.map((s) => s.personId).includes(t.userId))
              .map((author: TenantListingAuthorsType) => {
                return (
                  <Select.Option value={author.userId} key={author.userId}>
                    {author.name}
                  </Select.Option>
                )
              })}
          </Select>
        </Form.Item>
        <Form.Item name="stripeAccountName" label={stripeAccountText}>
          <Select
            showSearch
            disabled={disabled}
            defaultValue={defaultStripeAccount?.name}
            style={{ minWidth: 175 }}
            placeholder={selectStripeAccountText}
            dropdownMatchSelectWidth={false}
            onSelect={handleStripeAccount}
            options={stripeAccountOptions}
          />
        </Form.Item>
        <Form.Item name="stripeAccountId" hidden>
          <Input disabled />
        </Form.Item>
        <Divider orientation="center">{priceTxt}</Divider>
        <Form.Item name="pricePerUnitCents" label={priceCentsText}>
          <InputNumber
            step="0.01"
            precision={2}
            addonBefore={
              <Form.Item name="priceType" noStyle>
                <Select
                  placeholder={selectPricingOptionText}
                  onChange={(e: PriceType) => setPricingType(e)}
                  disabled={disabled}
                >
                  <Select.Option value="Hour">{priceTypeHourText}</Select.Option>
                  <Select.Option value="kWh">kWh</Select.Option>
                </Select>
              </Form.Item>
            }
            addonAfter={
              <Tag style={{ width: '100%', border: 'none' }}>
                {priceText} / {pricingType === 'Hour' ? priceTypeHourText : 'kWh'}
              </Tag>
            }
            disabled={disabled}
            onChange={(input) => {
              setCheckPricePerUnitCentsValue(Number(input) * 100)
              setTimeOfDayPricingWeekDay(Array(24).fill(input))
              setTimeOfDayPricingWeekEnd(Array(24).fill(input))
            }}
          />
        </Form.Item>
        {pricingType === 'Hour' && (
          <Form.Item name="enableLoadManagementPricing" label={enableLoadManagementPricingText}>
            <Checkbox
              checked={enableLoadManagementPricing}
              onChange={(e) => setEnableLoadManagementPricing(!enableLoadManagementPricing)}
              disabled={disabled}
            />
          </Form.Item>
        )}
        <Form.Item name="enableTieredPricing" label={enableTenantPreferredPricing}>
          <Checkbox
            checked={enableTieredPricing}
            onChange={(e) => setEnableTieredPricing(!enableTieredPricing)}
            disabled={disabled}
          />
        </Form.Item>
        {enableTieredPricing && (
          <Form.Item
            name="preferedPricePerUnitCents"
            label={tenantPreferredPricingText}
            rules={[{ required: true, message: tenantPreferredPricingWarningText }]}
          >
            <InputNumber
              step="0.01"
              precision={2}
              addonBefore={
                <Form.Item
                  name="priceType"
                  noStyle
                  rules={[{ required: true, message: tenantPreferredPricingPriceTypeWarningText }]}
                >
                  <Select
                    placeholder={selectPreferredPricingOptionText}
                    disabled={disabled}
                    onChange={(e: PriceType) => setPricingType(e)}
                  >
                    <Select.Option value="Hour">{priceTypeHourText}</Select.Option>
                    <Select.Option value="kWh">kWh</Select.Option>
                  </Select>
                </Form.Item>
              }
              addonAfter={
                <Tag style={{ width: '100%', border: 'none' }}>
                  {priceText} / {pricingType === 'Hour' ? priceTypeHourText : 'kWh'}
                </Tag>
              }
              disabled={disabled}
            />
          </Form.Item>
        )}
        <Form.Item name="enableTimeOfDayPricing" label={todTxt}>
          <Checkbox
            checked={enableTimeOfDayPricing}
            onChange={() => {
              setEnableTimeOfDayPricing(!enableTimeOfDayPricing)
            }}
            disabled={disabled}
          />
        </Form.Item>

        {enableTimeOfDayPricing && (
          <Form.Item style={{ justifyContent: 'center' }}>
            <Row align="middle" justify="center">
              <Col xs={24} sm={24} md={24} xl={7} xxl={7} style={{ textAlign: 'center' }}>
                <Table
                  dataSource={[
                    ...timeOfDayPriceWeekday.map((price, index) => {
                      return {
                        key: index,
                        startdate: `${index < 9 ? `0${index}:00` : `${index}:00`}`,
                        enddate: `${index < 9 ? `0${index + 1}:00` : `${index + 1}:00`}`,
                        price: (
                          <InputNumberContainer>
                            <InputNumber
                              step={'0.01'}
                              precision={2}
                              defaultValue={price}
                              disabled={disabled}
                              style={{ textAlign: 'center' }}
                              onChange={(input) => {
                                timeOfDayPriceWeekday[index] = Number(input)
                                setTimeOfDayPricingWeekDay(timeOfDayPriceWeekday)
                              }}
                            />
                          </InputNumberContainer>
                        ),
                      }
                    }),
                  ]}
                  columns={todColumns}
                  pagination={false}
                  title={() => {
                    return <h3 style={{ textAlign: 'center' }}>{weekdaysTxt}</h3>
                  }}
                />
              </Col>
              <Col xs={24} sm={24} md={24} xl={{ span: 7, offset: 5 }} xxl={{ span: 7, offset: 5 }}>
                <Table
                  dataSource={[
                    ...timeOfDayPriceWeekend.map((price, index) => {
                      return {
                        key: index,
                        startdate: `${index < 9 ? `0${index}:00` : `${index}:00`}`,
                        enddate: `${index < 9 ? `0${index + 1}:00` : `${index + 1}:00`}`,
                        price: (
                          <InputNumberContainer>
                            <InputNumber
                              step={'0.01'}
                              precision={2}
                              defaultValue={price}
                              disabled={disabled}
                              style={{ textAlign: 'center' }}
                              onChange={(input) => {
                                timeOfDayPriceWeekend[index] = Number(input)
                                setTimeOfDayPricingWeekEnd(timeOfDayPriceWeekend)
                              }}
                            />
                          </InputNumberContainer>
                        ),
                      }
                    }),
                  ]}
                  columns={todColumns}
                  pagination={false}
                  title={() => {
                    return <h3 style={{ textAlign: 'center' }}>{weekendsTxt}</h3>
                  }}
                />
              </Col>
            </Row>
          </Form.Item>
        )}

        <Divider orientation="center">Commission & Fee</Divider>
        <Form.Item name="commisionPercent" label={'Commission (%)'}>
          <Select
            placeholder={selectComissionFeeText}
            onChange={(comissionFeeType: string) => setComissionFeeType(comissionFeeType)}
            disabled={disabled}
          >
            <Select.Option value="2.9% + 30c">2.9% + 30c</Select.Option>
            <Select.Option value="10%">10%</Select.Option>
            <Select.Option value="defineNew">{defineNewComissionTypeText}</Select.Option>
          </Select>
        </Form.Item>
        {comissionFeeType === 'defineNew' && (
          <Form.Item
            name="customComissionFee"
            label={defineNewComissionFeeText}
            rules={[{ required: true, message: defineNewComissionTypeWarningText }]}
          >
            <InputNumber
              step="0.01"
              precision={2}
              disabled={disabled}
              min={0}
              max={100}
              defaultValue={commisionPercent}
            />
          </Form.Item>
        )}
        <Form.Item name="flatFee" label={flatFeeText}>
          <InputNumber step="0.01" precision={2} disabled={disabled} />
        </Form.Item>
        <Form.Item name="guestFeeCents" label={guestFeeText}>
          <InputNumber step="0.01" precision={2} disabled={disabled} />
        </Form.Item>
        <Form.Item name="paymentLimitCents" label={paymentLimitText}>
          <InputNumber step="0.01" precision={2} disabled={disabled} />
        </Form.Item>

        <Divider orientation="center">Loitering</Divider>
        <Form.Item name="disableLoitering" label={disableLoiteringTxt}>
          <Switch
            disabled={disabled}
            checked={isLoiteringDisabled}
            onChange={() => setIsLoiteringDisabled(!isLoiteringDisabled)}
          />
        </Form.Item>

        <Form.Item name="loiteringStatus" label={loiteringStatusText}>
          <Select
            placeholder="Loitering Status"
            onChange={(l: LoiteringStatusType) => setLoiteringStatusType(l)}
            disabled={disabled}
          >
            <Select.Option value="no_penalty">{loiteringStatusNoneText}</Select.Option>
            <Select.Option value="time_based">{loiteringStatusTimeBasedText}</Select.Option>
            <Select.Option value="status_based">{loiteringStatusStatusBasedText}</Select.Option>
          </Select>
        </Form.Item>
        {loiteringStatusType === 'time_based' && (
          <>
            <Form.Item
              name="loiteringPenalty"
              label={loiteringPenaltyPerHourText}
              rules={[{ required: true, message: emptyFieldWarning }]}
            >
              <Row gutter={8} align="middle">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <InputNumber step="0.01" precision={2} disabled={disabled} defaultValue={loiteringPenalty} />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              name="loiteringTimeLimit"
              label={loiteringtimeLimitText}
              rules={[{ required: true }, MustBePositiveIntegers]}
            >
              <Row gutter={8} align="middle">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <InputNumber step="1" disabled={disabled} defaultValue={loiteringTimeLimit} />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              name="loiteringGracePeriod"
              label={loiteringtimeLimitGraceMinutesText}
              rules={[{ required: true, message: emptyFieldWarning }]}
            >
              <Row gutter={8} align="middle">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <InputNumber step="1" disabled={disabled} defaultValue={loiteringGracePeriod} />
                </Col>
              </Row>
            </Form.Item>
          </>
        )}
        {loiteringStatusType === 'status_based' && (
          <>
            <Form.Item
              name="loiteringPenalty"
              label={loiteringPenaltyPerHourText}
              rules={[{ required: true, message: emptyFieldWarning }]}
            >
              <Row gutter={8} align="middle">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <InputNumber step="0.01" precision={2} disabled={disabled} defaultValue={loiteringPenalty} />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              name="loiteringGracePeriod"
              label={loiteringtimeLimitGraceMinutesText}
              rules={[{ required: true, message: emptyFieldWarning }]}
            >
              <Row gutter={8} align="middle">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <InputNumber step="1" disabled={disabled} defaultValue={loiteringGracePeriod} />
                </Col>
              </Row>
            </Form.Item>
          </>
        )}

        <Divider />

        <Form.Item {...tailLayout}>
          <Space>
            <Button htmlType="submit" type="primary" disabled={disabled}>
              {renderFormatMessage('dashboard.tenantPage.uniformPricingTab.uniformPricing.saveBtn', 'Save')}
            </Button>

            {disabled ? (
              <Button htmlType="button" onClick={() => setDisabled(!disabled)} disabled={!disabled} type="primary">
                {renderFormatMessage('dashboard.tenantPage.uniformPricingTab.uniformPricing.modifybtn', 'Modify')}
              </Button>
            ) : (
              <Button
                danger
                htmlType="button"
                onClick={() => {
                  setDisabled(!disabled)
                  form.resetFields()
                }}
              >
                {renderFormatMessage('dashboard.tenantPage.uniformPricingTab.uniformPricing.cancelBtn', 'Cancel')}
              </Button>
            )}
          </Space>
        </Form.Item>
      </Form>
    </Spin>
  )
}
