import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Col, Divider, Form, FormInstance, InputNumber, Row, Select, Switch, Table, Tag } from 'antd'
import React, { useEffect, useState } from 'react'

import { useListingPriceTranslation } from '../../hooks/translation/useListingPriceTrans'
import { PriceType } from '../../models/price'
import { styled } from '../../theme'

const InputNumberContainer = styled.div`
  .ant-input-number-input-wrap > input {
    text-align: center;
  }
`

interface props {
  form: FormInstance<any>
  isTimeOfDayPricingEnabled?: boolean
}

export const ListingPrice: React.FC<props> = ({ form, isTimeOfDayPricingEnabled }) => {
  const [pricingType, setPricingType] = useState<PriceType>(form.getFieldValue('priceType'))
  const [pricePerUnitCents, setPricePerUnitCents] = useState(form.getFieldValue('pricePerUnitCents'))
  const [preferedPricePerUnitCents, setPreferedPricePerUnitCents] = useState(
    form.getFieldValue('preferedPricePerUnitCents'),
  )
  const [enableLoadManagementPricing, setEnableLoadManagementPricing] = useState(
    form.getFieldValue('enableLoadManagementPricing'),
  )
  const [enableTieredPricing, setEnableTieredPricing] = useState(form.getFieldValue('enableTieredPricing'))

  const [enableTimeOfDayPricing, setEnableTimeOfDayPricing] = useState(isTimeOfDayPricingEnabled)

  const [timeOfDayPriceWeekday, setTimeOfDayPricingWeekDay] = useState<number[]>(
    form.getFieldValue('timeOfDayPriceWeekday'),
  )
  const [timeOfDayPriceWeekend, setTimeOfDayPricingWeekEnd] = useState<number[]>(
    form.getFieldValue('timeOfDayPriceWeekend'),
  )

  const {
    priceCentsText,
    selectPricingOptionText,
    priceTypeHourText,
    hourlyPriceBasedOnkWh,
    enableLoadManagementPricingText,
    enablePricingForTenantMembers,
    enableTimeOfDayPricingText,
    startTxt,
    endTxt,
    priceTxt,
    weekdaysTxt,
    weekendsTxt,
    preferredPricingTxt,
  } = useListingPriceTranslation()

  const todColumns = [
    {
      title: startTxt,
      dataIndex: 'startdate',
      key: 'startdate',
      align: 'center' as 'center',
    },
    {
      title: endTxt,
      dataIndex: 'enddate',
      key: 'enddate',
      align: 'center' as 'center',
    },
    {
      title: `${priceTxt} / ${pricingType === 'Hour' ? priceTypeHourText : 'kWh'}`,
      dataIndex: 'price',
      key: 'price',
      align: 'center' as 'center',
    },
  ]

  useEffect(() => {
    setTimeOfDayPricingWeekDay(timeOfDayPriceWeekday)
    setTimeOfDayPricingWeekDay(timeOfDayPriceWeekend)
    form.setFieldsValue({ timeOfDayPriceWeekday })
    form.setFieldsValue({ timeOfDayPriceWeekend })
  }, [pricePerUnitCents])

  const timeOfDayPricingWeekdayTable = (
    <Table
      dataSource={[
        ...timeOfDayPriceWeekday.map((price, index) => {
          return {
            key: index,
            startdate: `${index < 9 ? `0${index}:00` : `${index}:00`}`,
            enddate: `${index < 9 ? `0${index + 1}:00` : `${index + 1}:00`}`,
            price: (
              <InputNumberContainer>
                <InputNumber
                  step="0.01"
                  precision={2}
                  defaultValue={price}
                  style={{ textAlign: 'center' }}
                  onChange={(input) => handleTimeOfDayPricingWeekday(input, index)}
                />
              </InputNumberContainer>
            ),
          }
        }),
      ]}
      columns={todColumns}
      pagination={false}
      title={() => <h3 style={{ textAlign: 'center' }}>{weekdaysTxt}</h3>}
    />
  )

  const timeOfDayPricingWeekendTable = (
    <Table
      dataSource={[
        ...timeOfDayPriceWeekend.map((price, index) => {
          return {
            key: index,
            startdate: `${index < 9 ? `0${index}:00` : `${index}:00`}`,
            enddate: `${index < 9 ? `0${index + 1}:00` : `${index + 1}:00`}`,
            price: (
              <InputNumberContainer>
                <InputNumber
                  step="0.01"
                  precision={2}
                  defaultValue={price}
                  style={{ textAlign: 'center' }}
                  onChange={(input) => handleTimeOfDayWeekendPricing(input, index)}
                />
              </InputNumberContainer>
            ),
          }
        }),
      ]}
      columns={todColumns}
      pagination={false}
      title={() => <h3 style={{ textAlign: 'center' }}>{weekendsTxt}</h3>}
    />
  )

  const handleTimeOfDayWeekendPricing = (input: number, index: number) => {
    timeOfDayPriceWeekend[index] = Number(input)
    setTimeOfDayPricingWeekEnd(timeOfDayPriceWeekend)
    form.setFieldsValue({ timeOfDayPriceWeekend: timeOfDayPriceWeekend })
  }

  const handleTimeOfDayPricingWeekday = (input: number, index: number) => {
    timeOfDayPriceWeekday[index] = Number(input)
    setTimeOfDayPricingWeekDay(timeOfDayPriceWeekday)
    form.setFieldsValue({ timeOfDayPriceWeekday: timeOfDayPriceWeekday })
  }

  const handleListingKind = (priceType: PriceType) => {
    setPricingType(priceType)
    priceType === 'kWh' && form.setFieldsValue({ kind: 'no_reservation' })
  }

  const handleEnableLoadMgmtPricing = () => {
    setEnableLoadManagementPricing(!enableLoadManagementPricing)
    form.setFieldsValue({ enableLoadManagementPricing: !enableLoadManagementPricing })
  }

  const handleEnableTimeOfDayPricing = () => {
    setEnableTimeOfDayPricing(!enableTimeOfDayPricing)
    form.setFieldsValue({ enableTimeOfDayPricing: !enableTimeOfDayPricing })
  }

  return (
    <>
      <Divider>{hourlyPriceBasedOnkWh}</Divider>
      <Form.Item name="pricePerUnitCents" label={priceCentsText}>
        <InputNumber
          step="0.01"
          precision={2}
          addonBefore={
            <Form.Item name="priceType" noStyle>
              <Select
                placeholder={selectPricingOptionText}
                onChange={(priceType: PriceType) => handleListingKind(priceType)}
                value={pricingType}
              >
                <Select.Option value="Hour">{priceTypeHourText}</Select.Option>
                <Select.Option value="kWh">kWh</Select.Option>
              </Select>
            </Form.Item>
          }
          addonAfter={
            <Tag style={{ width: '100%', border: 'none' }}>
              {hourlyPriceBasedOnkWh} / {pricingType === 'Hour' ? priceTypeHourText : 'kWh'}
            </Tag>
          }
          onChange={(input) => {
            setPricePerUnitCents(input)
            setTimeOfDayPricingWeekDay(Array(24).fill(input))
            setTimeOfDayPricingWeekEnd(Array(24).fill(input))
            form.setFieldsValue({ timeOfDayPriceWeekday: timeOfDayPriceWeekday })
            form.setFieldsValue({ timeOfDayPriceWeekend: timeOfDayPriceWeekend })
          }}
        />
      </Form.Item>
      {pricingType === 'Hour' && (
        <Form.Item name="enableLoadManagementPricing" label={enableLoadManagementPricingText}>
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            style={{ float: 'left' }}
            onChange={handleEnableLoadMgmtPricing}
            checked={enableLoadManagementPricing}
          />
        </Form.Item>
      )}
      <Form.Item name="enableTieredPricing" label={enablePricingForTenantMembers}>
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          style={{ float: 'left' }}
          checked={enableTieredPricing}
          onChange={() => {
            setEnableTieredPricing(!enableTieredPricing)
            form.setFieldsValue({ enableTieredPricing: !enableTieredPricing })
          }}
        />
      </Form.Item>
      {enableTieredPricing && (
        <Form.Item name="preferedPricePerUnitCents" label={preferredPricingTxt}>
          <InputNumber
            step="0.01"
            precision={2}
            style={{ textAlign: 'center' }}
            onChange={(val) => setPreferedPricePerUnitCents(val)}
            value={preferedPricePerUnitCents}
          />
        </Form.Item>
      )}
      <Form.Item name="enableTimeOfDayPricing" label={enableTimeOfDayPricingText}>
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          style={{ float: 'left' }}
          checked={enableTimeOfDayPricing}
          onChange={handleEnableTimeOfDayPricing}
          defaultChecked={isTimeOfDayPricingEnabled}
        />
      </Form.Item>
      {enableTimeOfDayPricing && (
        <Row>
          <Col xs={24} lg={12}>
            <Form.Item style={{ justifyContent: 'center' }} name="timeOfDayPriceWeekday">
              {timeOfDayPricingWeekdayTable}
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item style={{ justifyContent: 'center' }} name="timeOfDayPriceWeekend">
              {timeOfDayPricingWeekendTable}
            </Form.Item>
            
          </Col>
        </Row>
      )}
    </>
  )
}
