import configProvider from '../../config'
import { addQueryString } from '../../helpers/url'
import { log } from '../../logger'
import { mockUserEmailResponse, mockUserWallet } from '../../mock/user-mock'
import { AugmentedUserResponse, UserResponse } from '../../models/http'
import { TenantRef } from '../../models/tenant'
import {
  AugmentedUser,
  DashboardAccessType,
  toAugmentedUser,
  User,
  UserEmailResponse,
  UserPermissions,
  UserRef,
  UserRole,
  UserUpdate,
  UserWallet,
} from '../../models/user'
import { apiClient } from './client'
import { mockAugmentedUserResponse, mockUserResponse } from './mock'

export async function GetUsers(
  page: number,
  term?: string,
  defaultTenant?: TenantRef,
  tenants?: TenantRef[],
): Promise<AugmentedUserResponse> {
  let url = '/users'

  let queryParams: { [key: string]: string } = {}

  if (term) {
    queryParams['term'] = term
  }

  if (defaultTenant) {
    queryParams['tenant_id'] = `${defaultTenant.id}`
  }

  if (tenants && tenants.length > 0) {
    queryParams['tenant_id'] = `${tenants.map((t) => t.id)}`
  }

  if (page) {
    queryParams['page'] = `${page}`
  }

  url = addQueryString(url, queryParams)
  log('fetching users', { url: url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockAugmentedUserResponse())
  }

  return apiClient<UserResponse>(url, {}).then((resp: UserResponse) => {
    return {
      ...resp,
      data: resp.data.map((u) => toAugmentedUser(u)),
    }
  })
}

export async function FindUser(term?: string, tenants?: TenantRef[]): Promise<AugmentedUser> {
  let url = '/users/find'
  let queryParams: { [key: string]: string } = {}

  if (term) {
    queryParams['term'] = term
  }

  if (tenants && tenants.length > 0) {
    queryParams['tenant_id'] = `${tenants.map((t) => t.id)}`
  }

  url = addQueryString(url, queryParams)

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(toAugmentedUser(mockUserResponse['data'][0]))
  }

  return apiClient<User>(url, {}).then((u) => toAugmentedUser(u))
}

export async function SearchUsers(term?: string, country?: string): Promise<UserWallet[]> {
  let url = '/users/search'
  let queryParams: { [key: string]: string } = {}

  if (term) {
    queryParams['term'] = term
  }

  if (country) {
    queryParams['country'] = country
  }

  url = addQueryString(url, queryParams)

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockUserWallet)
  }
  return apiClient(url, {})
}

export async function SearchEmail(term?: string, country?: string): Promise<UserEmailResponse> {
  let url = '/users/check_email_exist'
  let queryParams: { [key: string]: string } = {}

  if (term) {
    queryParams['term'] = term
  }

  url = addQueryString(url, queryParams)

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockUserEmailResponse)
  }

  return apiClient(url, {})
}

export async function InviteUser(
  tenants: UserPermissions[],
  email: string,
  name: string,
  // role: TenantPermissionType,
  id: string,
): Promise<User> {
  const url = '/users/invite'

  // const tenantsArr: UserPermissions[] = []

  // tenants.map(tenant => {
  //   tenantsArr.push({id: tenant.id, name: tenant.name, access: role, driver: false})
  // })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockUserResponse['data'][0])
  }

  log('adding User', { url: url })
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      tenants,
      users: [
        {
          id: id,
          email: email,
          name: name,
          // leaving note as empty string means no update in name
          note: '',
        },
      ],
    }),
  })
}

export async function NewInviteUser(tenants: UserPermissions[], users: UserRef[]): Promise<User> {
  const url = '/users/invite'

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockUserResponse['data'][0])
  }

  log('adding User', { url: url })
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      tenants,
      users: users,
    }),
  })
}
export async function ToggleRole(userId: string, role: UserRole): Promise<AugmentedUser> {
  const url = `/users/${userId}/role`

  log('updating user', { url, role })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(toAugmentedUser(mockUserResponse['data'][0]))
  }

  return apiClient<User>(url, {
    method: 'PUT',
    body: JSON.stringify({ user: { role: role } }),
  }).then((u) => toAugmentedUser(u))
}

export async function UpdateUser(tenants: TenantRef[], userId: string, updates: UserUpdate): Promise<AugmentedUser> {
  const url = `/users/${userId}`

  log('updating user', { url, updates })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(toAugmentedUser(mockUserResponse['data'][0]))
  }

  return apiClient<User>(url, {
    method: 'PUT',
    body: JSON.stringify({ tenant_id: `${tenants.map((t) => t.id)}`, user: updates }),
  }).then((u) => toAugmentedUser(u))
}

export async function NewUpdateUser(
  tenants: UserPermissions[],
  originalTenants: UserPermissions[],
  user: UserRef[],
): Promise<AugmentedUser> {
  const url = '/users'
  log('updating user', { url, tenants })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(toAugmentedUser(mockUserResponse['data'][0]))
  }

  return apiClient<User>(url, {
    method: 'PUT',
    body: JSON.stringify({
      tenant_id: `${tenants.map((t) => t.id)}`,
      users: user,
      tenants,
    }),
  }).then((u) => toAugmentedUser(u))
}

export async function AddUserToTenants(
  tenantIds: number[],
  userId: string,
  email: string,
  role: DashboardAccessType,
): Promise<AugmentedUser> {
  let url = `/users/add`

  log('adding user to tenants', { url: url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(toAugmentedUser(mockUserResponse['data'][0]))
  }

  return apiClient<User>(url, {
    method: 'POST',
    body: JSON.stringify({ id: userId, role: role, email: email, tenant_ids: tenantIds }),
  }).then((u) => toAugmentedUser(u))
}

export async function SearchEntityOwners(term?: string): Promise<UserRef[]> {
  let url = '/users/search_entity_owner'
  let queryParams: { [key: string]: string } = {}

  if (term) {
    queryParams['term'] = term
  }

  url = addQueryString(url, queryParams)

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockUserWallet)
  }
  return apiClient(url, {})
}
