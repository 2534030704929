import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'

export const useGeneralTranslation = () => {
  const activeText = useFormatMessage('dashboard.text.active', 'Active')
  const cancelText = useFormatMessage('dashboard.reports.form.sendReport.cancelText', 'Cancel')
  const createText = useFormatMessage('dashboard.button.create', 'Create')
  const countryText = useFormatMessage('dashboard.text.country', 'Country')
  const currencyText = useFormatMessage('dashboard.text.currency', 'Currency')
  const descriptionText = useFormatMessage('dashboard.text.description', 'Description')
  const inactiveText = useFormatMessage('dashboard.text.inactive', 'Inactive')
  const intervalText = useFormatMessage('dashboard.text.interval', 'Interval')
  const okText = useFormatMessage('dashboard.button.create', 'Create')
  const titleText = useFormatMessage('dashboard.text.name', 'Name')
  const priceText = useFormatMessage('dashboard.text.price', 'Price')
  const refreshText = renderFormatMessage('dashboard.text.refresh', 'Refresh')
  const statusText = useFormatMessage('dashboard.text.status', 'Status')
  const listingsText = renderFormatMessage('dashboard.listing.ListingsText', 'Listings')
  const searchText = useFormatMessage('dashboard.text.search', 'Search')
  const startDateText = useFormatMessage('dashboard.mobileCalendar.startDate', 'Start Date')
  const endDateText = useFormatMessage('dashboard.mobileCalendar.endDate', 'End Date')
  const unavailableText = useFormatMessage('dashboard.homePage.unavailable', 'Unavailable')
  const displayNameText = useFormatMessage('dashboard.tenantPage.detailTab.displayName.heading', 'Display Name')

  const omOptinYesText = renderFormatMessage('dashboard.tenantPage.overviewTab.o_m_opt_in.yes', 'Yes')

  const omOptinNoText = renderFormatMessage('dashboard.tenantPage.overviewTab.o_m_opt_in.no', 'No')

  const noteText = useFormatMessage('dashboard.tenantPage.notes', 'Notes')

  const modifyText = renderFormatMessage('dashboard.tenantPage.overviewTab.modifybtn', 'Modify')

  const saveText = useFormatMessage('dashboard.tenantPage.overviewTab.saveBtn', 'Save')
  return {
    activeText,
    cancelText,
    countryText,
    createText,
    currencyText,
    descriptionText,
    inactiveText,
    intervalText,
    listingsText,
    okText,
    priceText,
    refreshText,
    titleText,
    statusText,
    searchText,
    startDateText,
    endDateText,
    unavailableText,
    displayNameText,
    omOptinYesText,
    omOptinNoText,
    noteText,
    modifyText,
    saveText,
  }
}
