import { toAugmentedUser, User } from '../../models/user'
import { Tenant } from '../../models/tenant'
import { JWTIssue } from '../../models/auth'
import { AugmentedUserResponse, TenantResponse, UserResponse } from '../../models/http'

export const returnMock = <T>(obj: T): Promise<T> => {
  return new Promise<T>((resolve) =>
    setTimeout(() => {
      return resolve(obj)
    }, 2000),
  )
}

export const mockAdminJwt = {
  token:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiIxIiwiaXNzIjoic3d0Y2giLCJhIjp0cnVlLCJleHAiOjE1MTYyMzkwMjJ9.6K-fLr5P-tBn2Ksm4kyuDh0JIX0VvwoovsfqkPjSrrk',
  expiresAt: 1516239022,
} as JWTIssue

export const mockSelf = {
  id: '1',
  name: 'Julien Cassis',
  email: 'juliencassis@gmail.com',
  role: 'admin',
  //role: 'user',
  //role: 'support',
  accesses: [
    {
      resourceType: 'Tenant',
      resourceId: '76',
      permissions: ['tenant.manager'],
      display: '9-GreatNorth-Rd-ParrySound-ON-P2A2X8',
    },
    {
      resourceType: 'Tenant',
      resourceId: '68',
      permissions: ['tenant.viewer'],
      display: 'swtchhq',
    },
    {
      resourceType: 'Tenant',
      resourceId: '90',
      permissions: ['tenant.manager'],
      display: 'NewGold',
    },
  ],
} as User

export const mockTenantsRef = [
  {
    id: '8',
    name: 'swtchhq',
    displayName: 'test_swtch_hq',
  },
  { id: '2', name: 'lan-fake-tenant', displayName: 'lan-fake-tenant-display-name', chargerCount: 15 },
  { id: '3', name: '123-lake-street', displayName: '', chargerCount: 28 },
  { id: '4', name: '83-beaver-hall', displayName: 'beaver hall', chargerCount: 73 },
  { id: '5', name: '9-GreatNorth-Rd-ParrySound-ON-P2A2X8', displayName: 'Green North Road', chargerCount: 7 },
]

export const mockTenants = [
  {
    id: '4',
    name: 'swtchhq',
    displayName: 'test_swtch_hq',
    chargerCount: 23,
    accessCode: 'LALALA',
    accessPolicy: 'Private Individual',
    notes: 'lorem ipsum dolor',
    omOptin: true,
    channelPartner: {
      id: 1,
      name: 'Lan Partner',
    },
    location: {
      address: '109 ossington ave',
      city: 'Toronto',
      country: 'CA',
      latitude: 43.6373,
      listingTimezone: 'America/Toronto',
      longitude: -79.4242,
      postalCode: 'M6J 0G1',
      province: 'ON',
    },
    publishToOcpi: true,
    eyedroIpAddress: '1209.2343.123',

    weeklySchedules: [
      {
        dayOfWeek: 0,
        endHour: '23:45:00',
        startHour: '00:00:00',
      },
      {
        dayOfWeek: 1,
        endHour: '23:45:00',
        startHour: '00:00:00',
      },
      {
        dayOfWeek: 2,
        endHour: '23:45:00',
        startHour: '00:00:00',
      },
      {
        dayOfWeek: 2,
        endHour: '23:45:00',
        startHour: '00:00:00',
      },
      {
        dayOfWeek: 3,
        endHour: '23:45:00',
        startHour: '00:00:00',
      },
      {
        dayOfWeek: 4,
        endHour: '23:45:00',
        startHour: '00:00:00',
      },
      {
        dayOfWeek: 5,
        endHour: '23:45:00',
        startHour: '00:00:00',
      },
      {
        dayOfWeek: 6,
        endHour: '23:45:00',
        startHour: '00:00:00',
      },
    ],
  },
  {
    id: '2',
    name: 'lan-fake-tenant',
    displayName: 'lan-fake-tenant-display-name',
    chargerCount: 15,
    eyedroIpAddress: null,
  },
  { id: '3', name: '123-lake-street', displayName: '', chargerCount: 28 },
  { id: '4', name: '83-beaver-hall', displayName: 'beaver hall', chargerCount: 73 },
  { id: '5', name: '9-GreatNorth-Rd-ParrySound-ON-P2A2X8', displayName: 'Green North Road', chargerCount: 7 },
] as Tenant[]

export const mockTenantsResponse = {
  data: mockTenants,
  pagination: {
    currentPage: 1,
    perPage: 20,
    totalEntries: 23,
  },
} as TenantResponse

export const mockAugmentedUserResponse = (): AugmentedUserResponse => {
  return {
    ...mockUserResponse,
    data: mockUserResponse.data.map((u) => toAugmentedUser(u)),
  }
}
export const mockUserResponse = {
  data: [
    {
      id: 'jXD9CMKXeavenIGoSK6qDQ',
      email: 'laura.bryson@gmail.com',
      name: 'Laura Christine Bryson',
      role: 'admin',
      note: 'test',
      accesses: [
        {
          resourceType: 'Tenant',
          resourceId: '42',
          permissions: ['tenant.driver'],
          display: '100-Victoria-St-S-Kitchener-ON-N2G2B3',
        },
        {
          resourceType: 'Tenant',
          resourceId: '109',
          permissions: ['tenant.driver'],
          display: 'opus',
        },
        {
          resourceType: 'Tenant',
          resourceId: '56',
          permissions: ['tenant.driver'],
          display: 'Royal-Botanical-Gardens-ON',
        },
      ],
    },
    {
      id: 'yinh_nzFeJ4OK4MzU-ja-A',
      email: 'thomas.martin@swtchenergy.com',
      name: 'Thomas Martin-Admin',
      role: 'admin',
      accesses: [
        {
          resourceType: 'Tenant',
          resourceId: '76',
          permissions: ['tenant.driver'],
          display: '525-North-Avenue-NE-Atlanta-GA',
        },
        {
          resourceType: 'Tenant',
          resourceId: '68',
          permissions: ['tenant.driver'],
          display: '2000-Argentia-Rd-310-Mississauga-ON-L5N1W1',
        },
        {
          resourceType: 'Tenant',
          resourceId: '90',
          permissions: ['tenant.driver'],
          display: 'NewGold',
        },
        {
          resourceType: 'Tenant',
          resourceId: '41',
          permissions: ['tenant.driver'],
          display: 'School-District-43',
        },
        {
          resourceType: 'Tenant',
          resourceId: '40',
          permissions: ['tenant.driver'],
          display: '109-Front-St-E-Toronto-ON-M5A4P7',
        },
      ],
    },
    {
      id: 'kSOpOau2d3Fnm2Rk7Mxc6A',
      email: 'weihang.tang@swtchev.com',
      name: 'Weihang Tang',
      role: 'admin',
      accesses: [
        {
          resourceType: 'Tenant',
          resourceId: '71',
          permissions: ['tenant.driver'],
          display: '6775-Financial-Dr-100-Mississauga-ON-L5N0A4',
          displayName: 'Financial Drive Mississauga',
        },
        {
          resourceType: 'Tenant',
          resourceId: '110',
          permissions: ['tenant.driver'],
          display: '7905-Bayview-Avenue-Thornhill-ON-L3T7N3',
          displayName: null,
        },
        {
          resourceType: 'Tenant',
          resourceId: '47',
          permissions: ['tenant.driver'],
          display: '4205-Shipp-Dr-Mississauga-ON-L4Z2Y9',
          displayName: null,
        },
        {
          resourceType: 'Tenant',
          resourceId: '90',
          permissions: ['tenant.driver'],
          display: 'NewGold',
          displayName: null,
        },
        {
          resourceType: 'Tenant',
          resourceId: '8',
          permissions: ['tenant.driver'],
          display: 'swtchhq',
          displayName: 'test_swtch_hq',
        },
      ],
    },
    {
      id: '6J-GlL-V4rWCIInaxp5USg',
      email: 'nicole.politis@swtchenergy.com',
      name: 'Nicole Politis',
      role: 'admin',
      accesses: [
        {
          resourceType: 'Tenant',
          resourceId: '108',
          permissions: ['tenant.driver'],
          display: '9-GreatNorth-Rd-ParrySound-ON-P2A2X8',
          displayName: null,
        },
      ],
    },
    {
      id: 'bR0mXLrpBHqdvR6-AJHAqw',
      email: 'vladyslav.vakulenko@swtchenergy.com',
      name: 'Vladyslav Vakulenko',
      role: 'admin',
      accesses: [
        {
          resourceType: 'Tenant',
          resourceId: '110',
          permissions: ['tenant.driver'],
          display: '7905-Bayview-Avenue-Thornhill-ON-L3T7N3',
          displayName: null,
        },
        {
          resourceType: 'Tenant',
          resourceId: '47',
          permissions: ['tenant.driver'],
          display: '4205-Shipp-Dr-Mississauga-ON-L4Z2Y9',
          displayName: null,
        },
        {
          resourceType: 'Tenant',
          resourceId: '134',
          permissions: ['tenant.driver'],
          display: '5760-11-St-SE-Calgary-AB-T2H2G1',
          displayName: null,
        },
        {
          resourceType: 'Tenant',
          resourceId: '151',
          permissions: ['tenant.driver'],
          display: '7321-Victoria-Park-Ave8-Markham-ON-L3R2Z8',
          displayName: null,
        },
        {
          resourceType: 'Tenant',
          resourceId: '152',
          permissions: ['tenant.driver'],
          display: '7321-Victoria-Park-Ave8-Markham-ON-L3R2Z8',
          displayName: null,
        },
        {
          resourceType: 'Tenant',
          resourceId: '34',
          permissions: ['tenant.driver'],
          display: '288-Church-St-Toronto-ON-M5B1Z5',
          displayName: null,
        },
      ],
    },
    {
      id: 'g0lCrYG7jpsrVxtGS3K67g',
      email: 'dylan.victoratto@swtchenergy.com',
      name: 'Dylan Victoratto',
      role: 'support',
      accesses: [],
    },
    {
      id: 'snDWpfj5p_y-Bn_fbUaBfg',
      email: 'sam.arabastani@swtchenergy.com',
      name: 'Sam Arabastani',
      role: 'admin',
      accesses: [
        {
          resourceType: 'Tenant',
          resourceId: '8',
          permissions: ['tenant.driver'],
          display: 'swtchhq',
          displayName: 'test_swtch_hq',
        },
      ],
    },
    {
      id: 'q3uVBMCJPOacdrQB60bSrA',
      email: 'juliencassis@gmail.com',
      name: 'Julien Cassis',
      role: 'user',
      accesses: [
        {
          resourceType: 'Tenant',
          resourceId: '8',
          permissions: ['tenant.manager'],
          display: 'swtchhq',
          displayName: 'test_swtch_hq',
        },
        {
          resourceType: 'Tenant',
          resourceId: '12',
          permissions: ['tenant.viewer'],
          display: '410-Dundas-St-E-Toronto-ON-M5A2A8',
          displayName: 'Dundas Street Toronto',
        },
      ],
    },
  ],
  pagination: {
    currentPage: 1,
    totalEntries: 8,
    perPage: 20,
  },
} as UserResponse

export const mockUserAddedToTenantsResponse = {
  tenantsAdded: ['swtchhq', '100-Victoria-St-S-Kitchener-ON-N2G2B3'],
  tenantsNotAdded: [],
}

export const mockDREventResponse = {
  status: 'failed',
  message: 'listing is not a reservation type',
}
