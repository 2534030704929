import { Alert, Button, Col, Row, Select, Space, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'
import { ConnectorResponse, RemoteMultiChargerTriggerMessageParams } from '../../models/charger-remote'
import { SwtchError } from '../../models/error'
import { RemoteMultiChargerTriggerMessage } from '../../services/data-provider/charger-remote'

const { Option } = Select

interface props {
  chargers: RemoteMultiChargerTriggerMessageParams[]
}

type TriggerMessageType = {
  label: string
  value: string
}

const initialMessage = {
  label: 'Message',
  value: '',
}

export const RemoteTriggerMultipleChargers: React.FC<props> = ({ chargers }) => {
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [passed, setPassed] = useState(0)
  const [failed, setFailed] = useState(0)

  const [message, setMessage] = useState<ConnectorResponse[]>([])
  const [triggermessage, setTriggerMessage] = useState(initialMessage)
  const [error, setError] = useState<SwtchError[]>([])
  const [errorMessage, setErrorMessasge] = useState<ConnectorResponse[]>([])

  const TriggerText = useFormatMessage('dashboard.chargerPage.remoteControlTab.triggerMessage.trigger', 'Trigger')

  const messageTypePlaceHolder = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.triggerMessage.select.placeholder',
    'Select a message type',
  )

  const sendTriggerMessageMultipleChargers = useFormatMessage(
    'dashboard.chargersPage.modal.configuration.trigger.message',
    "Sending Trigger Message '123' to selected charger(s)",
  )

  const connectorText = useFormatMessage('dashboard.chargersPage.connector.heading', 'Connector')

  useEffect(() => {
    setLoading(false)
    setMessage([])
    setError([])
  }, [])

  const availableMessages: { label: string; value: string }[] = [
    {
      label: useFormatMessage(
        'dashboard.chargerPage.remoteControlTab.triggerMessage.select.bootNotification',
        'Boot Notification',
      ),
      value: 'BootNotification',
    },
    {
      label: useFormatMessage(
        'dashboard.chargerPage.remoteControlTab.triggerMessage.select.diagnosticsStatusNotification',
        'Diagnostics Status Notification',
      ),
      value: 'DiagnosticsStatusNotification',
    },
    {
      label: useFormatMessage(
        'dashboard.chargerPage.remoteControlTab.triggerMessage.select.firmwareStatusNotification',
        'Firmware Status Notification',
      ),
      value: 'FirmwareStatusNotification',
    },
    {
      label: useFormatMessage('dashboard.chargerPage.remoteControlTab.triggerMessage.select.heartbeat', 'Heart Beat'),
      value: 'Heartbeat',
    },
    {
      label: useFormatMessage('dashboard.chargerPage.remoteControlTab.triggerMessage.select.meterValue', 'Meter Value'),
      value: 'MeterValues',
    },
    {
      label: useFormatMessage(
        'dashboard.chargerPage.remoteControlTab.triggerMessage.select.statusNotification',
        'Status Notification',
      ),
      value: 'StatusNotification',
    },
  ]

  const handleTriggerMessage = () => {
    setLoading(true)
    setError([])
    setMessage([])
    setPassed(0)
    setFailed(0)
    RemoteMultiChargerTriggerMessage({
      charger_params: [...chargers],
      message: triggermessage.value,
    })
      .then((resp) => {
        setPassed(resp.passed)
        setFailed(resp.failed)
        let p: ConnectorResponse[] = []
        let f: ConnectorResponse[] = []
        resp.payload.forEach((r: ConnectorResponse) => {
          r.success === true ? p.push(r) : f.push(r)
        })
        setMessage(p)
        setErrorMessasge(f)
      })
      .catch((err: SwtchError) => setError((error) => [...error, err]))
      .finally(() => setLoading(false))
  }

  const handleSelectTriggerMessage = ({ label, value }: TriggerMessageType) => {
    setTriggerMessage({ label, value })
    setDisabled(false)
  }

  const renderMessages = () => {
    return (
      <>
        {passed >= 1 &&
          message.map(
            (res, index) =>
              Array.isArray(res.message) && (
                <Alert
                  key={index}
                  message={`${res.serialNumber} - ${connectorText} ${res.connectorId}`}
                  description={res.message[2].status}
                  type="info"
                  style={{ marginBottom: '10px' }}
                />
              ),
          )}
        {failed >= 1 &&
          errorMessage.map((err, index) => {
            const errMssg: any = err.message
            return (
              <>
                {
                  <Alert
                    key={index}
                    message={`${err.serialNumber} - ${connectorText} ${err.connectorId}`}
                    description={errMssg['error'] ? errMssg['error'] : err.message}
                    type="error"
                  />
                }
              </>
            )
          })}
        {failed >= 1 &&
          error.map((err, index) => <Alert key={index} message={err.name} description={err.message} type="error" />)}
      </>
    )
  }

  return (
    <Row>
      <Col span={24}>
        <p>
          {useFormatMessage(
            'dashboard.chargersPage.modal.configuration.trigger.heading',
            'Select a message below for selected chargers to initiate a trigger message',
          )}
        </p>
        <Space>
          <Select
            showSearch
            labelInValue
            dropdownMatchSelectWidth={false}
            placeholder={messageTypePlaceHolder}
            optionFilterProp="children"
            onChange={handleSelectTriggerMessage}
          >
            {availableMessages.map((opt) => {
              return (
                <Option key={opt.value} value={opt.value}>
                  {opt.label}
                </Option>
              )
            })}
          </Select>
          <Button type="primary" onClick={() => handleTriggerMessage()} loading={loading} disabled={disabled}>
            {message
              ? `${TriggerText} `
              : `${renderFormatMessage(
                  'dashboard.chargerPage.remoteControlTab.triggerMessage.btn.sendTriggerMessage',
                  'Send Trigger Message',
                )}`}
          </Button>
        </Space>
      </Col>
      <Col span={24} style={{ paddingTop: '10px' }}>
        {loading && (
          <Alert
            message={
              <div>
                <Spin style={{ marginRight: '10px' }} />
                {sendTriggerMessageMultipleChargers.replace('123', triggermessage.label)}
              </div>
            }
          />
        )}
        {!loading && renderMessages()}
      </Col>
    </Row>
  )
}
