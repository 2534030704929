import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more'

import { ChargerStationInfo, ConnectorInfo } from '../../../../models/chargerv2'
import { HomePageGaugeChart } from './gauge-station'
import { HomePageStationPieChart } from './stations-pie-chart'
import { styled } from '../../../../theme'

highchartsMore(Highcharts)

const HomePageStationsChartContainer = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  position: relative;
  /* height: 310px; */
  .highcharts-point.highcharts-color-0.highcharts-point-hover {
    fill: #78f8e3;
  }

  .highcharts-point.highcharts-color-1.highcharts-point-hover {
    fill: #3f514d;
  }

  .highcharts-point.highcharts-color-2.highcharts-point-hover {
    fill: #7d838a;
  }

  .stations .highcharts-series-group .highcharts-point {
    stroke-width: 5px;
  }
  .highcharts-color-0 {
    fill: #54dec7;
    stroke: #54dec7;
  }
  .highcharts-color-1 {
    fill: #242e2c;
    stroke: #242e2c;
  }
  .highcharts-color-2 {
    fill: #dedede;
    stroke: #dedede;
  }
  .highcharts-legend-item {
    border-radius: 3px;
  }
  z-index: 100;

  .highcharts-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .highcharts-tooltip-box {
    fill: #242e2c;
    /* fill-opacity: 0.6;
    stroke-width: 0; */
    display: block;
  }
  .highcharts-tooltip text {
    fill: white;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    height: 200px;
  }

  .highcharts-tooltip-container .highcharts-tooltip > span {
    min-width: 350px;
    width: 400px;
    min-height: 200px;
  }

  .highcharts-label.highcharts-tooltip.highcharts-color-0,
  .highcharts-label.highcharts-tooltip.highcharts-color-1,
  .highcharts-label.highcharts-tooltip.highcharts-color-2 {
    stroke: unset;
  }

  .textContainer {
    min-height: 200px;
    min-width: 300px;
  }

  .paragraph-03-regular.seriesName {
    opacity: 0.6;
  }

  .highcharts-background {
    fill: none;
  }

  .highcharts-gauge-series .highcharts-dial {
    filter: none;
  }

  .highcharts-point {
    stroke-width: 0px;
  }
`

const HomePageStationsOdometerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: -1;
  /* transform: translateX(25%); */
  text-align: center;

  /* width: 190px;
  height: 190px; */

  .highcharts-container.stations rect.highcharts-background {
    fill: none;
  }

  /* rect.highcharts-background {
    fill: transparent;
  } */

  .highcharts-pivot,
  .highcharts-title {
    display: none;
  }

  .highcharts-tick {
    stroke: #cccccc;
  }

  .textContainer span {
    fill: white;
  }
`

interface props {
  chargerStationInfo: ChargerStationInfo
  connectorInfo: ConnectorInfo
}

export const HomePageStationsChart: React.FC<props> = ({ chargerStationInfo, connectorInfo }) => {
  return (
    <>
      <HomePageStationsChartContainer>
        <HomePageStationPieChart chargerStationInfo={chargerStationInfo} />
        <HomePageStationsOdometerContainer className="odometer-container">
          <HomePageGaugeChart connectorInfo={connectorInfo} chargerStationInfo={chargerStationInfo} />
        </HomePageStationsOdometerContainer>
      </HomePageStationsChartContainer>
    </>
  )
}
