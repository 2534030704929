import { Col, Row, Space, Tabs, Tag, Tooltip, message } from 'antd'
import { useEffect, useState } from 'react'

import { ResponsiveTable } from '../../atom/responsive-table'
import { capitalizeFirstLetter } from '../../helpers/users'
import { AlertError } from '../../components/error'
import { UsersListView } from '../../components/users/users-list-view'
import { SwtchError } from '../../models/error'
import { PaginationMeta } from '../../models/pagination'
import { TenantRef } from '../../models/tenant'
import {
  AugmentedUser,
  DashboardAccessType,
  toAugmentedUser,
  User,
  UserRole,
  UserUpdate,
  UserWallet,
} from '../../models/user'
import { GetUsers, ToggleRole, UpdateUser } from '../../services/data-provider/users'
import { useAppState } from '../../state'
import { TableWrapper } from '../../atom/table-wrapper'
import { MultiTenantSelector } from '../../components/redesign/multiple-tenant-selector'
import { UserInvitationRedesign } from './user-invitation-redesign'
import { UserEditForm } from './user-edit-redesign'
import { editSVG } from '../../assets/svg/edit'
import { searchSVG } from '../../assets/svg/search'
import { plusSVG } from '../../assets/svg/plus'
import { ButtonIcon, ButtonModal } from '../../atom/button'
import { magnifyBlackSVG } from '../../assets/svg/magnify'
import { greyInfoSVG } from '../../assets/svg/greyInfo'
import { FindTenants } from '../../services/data-provider/tenants'
import { SubscriptionsRedesign } from '../../components/subscriptions/subscriptions-redesign'
import { PlansRedesign } from '../../components/plans/plans-redesign'
import { useUserTranslation } from '../../hooks/translation/useUserTranslation'
import { NoIconSearch } from '../../components/redesign/no-icon-search'
import {
  AccessTagContainer,
  CustomBox,
  DriverSvgSpacing,
  EditButtonWrapper,
  InviteButtonWrapper,
  LineBreak,
  MultiTenantStyling,
  SearchButtonWrapper,
  TableStyling,
  TabsButtonWrapper,
  TabsWrapper,
  UserSelectorStyling,
} from '../../atom/users-page-redesign-styles'
import { UserSelectorNoTagRedesign } from '../../components/selector/user-selector-no-tag'

document.querySelector('body')?.classList.add('redesignActive')

export const UsersRedesignBasePage: React.FC = () => {
  const { currentUser, selectedTenant, IsLaptop, IsDesktop, IsTablet, IsMobile } = useAppState()
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [users, setUsers] = useState<AugmentedUser[]>([])
  const [term, setTerm] = useState<string>('')
  const [selectedUser, setSelectedUser] = useState<AugmentedUser>()
  const [userUpdate, setUserUpdate] = useState<UserUpdate>()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState<PaginationMeta>()
  const [currentPage, setCurrentPage] = useState(1)
  const [showInvitationForm, setShowInvitationForm] = useState(false)
  const [showEditUserNameForm, setEditUserNameForm] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const [currentAugmentedUser, setCurrentAugmentedUser] = useState<AugmentedUser>()
  const [tab, setTab] = useState('overview')
  const [createPlanForm, setShowCreatePlanForm] = useState(false)
  const [showInviteForm, setShowInviteForm] = useState(false)
  const [subscriptionEmailSearch, setSubscriptionEmailSearch] = useState<UserWallet | undefined>()
  const [searchSubscriptions, setSearchSubscriptions] = useState(false)
  const {
    emailTxt,
    driverInfoTxt,
    accessInfoTxt,
    driverTxt,
    inviteUser,
    tenantsTxt,
    userTenantWarningText,
    createPlanText,
    subscribeUserText,
    idText,
    userText,
    accessText,
    noAccessText,
    roleTxt,
    overviewText,
    plansText,
    subscriptionsText,
  } = useUserTranslation()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isMobile = IsMobile()
  const isLaptop = IsLaptop()

  const fetchUsers = () => {
    setLoading(true)
    setError(undefined)
    if (tenants.length === 0) {
      if (currentUser?.role === 'admin') {
        GetUsers(currentPage, term)
          .then((resp) => {
            setUsers(resp.data)
            setPagination(resp.pagination)
          })
          .catch((error) => setError(error))
          .finally(() => setLoading(false))
      } else {
        FindTenants()
          .then((tenantsForUser) => {
            GetUsers(currentPage, term, selectedTenant, tenantsForUser)
              .then((resp) => {
                setUsers(resp.data)
                setPagination(resp.pagination)
              })
              .catch((error) => setError(error))
              .finally(() => setLoading(false))
          })
          .catch((err: SwtchError) => {
            message.error(err.description)
          })
      }
    } else {
      GetUsers(currentPage, term, selectedTenant, tenants)
        .then((resp) => {
          setUsers(resp.data)
          setPagination(resp.pagination)
        })
        .catch((error) => setError(error))
        .finally(() => setLoading(false))
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [tenants, currentPage, selectedUser])

  const tenantScope: TenantRef[] = selectedTenant
    ? [{ id: selectedTenant.id, name: selectedTenant.name, displayName: selectedTenant.displayName }]
    : tenants
  const hasTenantScope = tenantScope !== undefined

  useEffect(() => {
    if (selectedTenant) {
      setTenants([{ id: selectedTenant.id, name: selectedTenant.name, displayName: selectedTenant.displayName }])
    }
    let currentAugmentedUserTemp = currentUser && toAugmentedUser(currentUser)
    setCurrentAugmentedUser(currentAugmentedUserTemp)
  }, [])

  const handleToggleRole = (userId: string, role: UserRole) => {
    setError(undefined)
    ToggleRole(userId, role)
      .then((user) => {
        addOrUpdateUser(user)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {})
  }

  const handleUserUpdate = () => {
    if (!tenantScope) {
      return
    }

    if (!selectedUser || !userUpdate) {
      return
    }
    setLoading(true)

    tenantScope &&
      Array.isArray(tenantScope) &&
      UpdateUser(tenantScope, selectedUser.id, userUpdate)
        .then((u: AugmentedUser) => {
          addOrUpdateUser(u)
          setSelectedUser(undefined)
          setUserUpdate(undefined)
          setEditUserNameForm(false)
        })
        .catch((err: SwtchError) => setError(err))
        .finally(() => setLoading(false))
  }

  const handleUserInvited = (newUser: User) => {
    setShowInvitationForm(false)
    return fetchUsers()
  }

  const onPaginationChange = (page: number, pageSize?: number) => {
    setCurrentPage(page)
  }

  const addOrUpdateUser = (user: AugmentedUser) => {
    let updated = false
    const newUserList = users.map((u) => {
      if (u.id === user.id) {
        updated = true
        return user
      }
      return u
    })
    if (!updated) {
      newUserList.push(user)
    }
    setUsers(newUserList)
  }

  const handleClickCross = () => {
    setUserUpdate((prev) => (prev ? { ...prev, access: undefined } : { driver: false, access: undefined, name: '' }))
  }

  const handleClickPlus = () => {
    setUserUpdate((prev) => (prev ? { ...prev, access: 'manager' } : { driver: false, access: 'manager', name: '' }))
  }

  const handleUserSelect = (value: DashboardAccessType) => {
    const access = value
    setUserUpdate((prev) => (prev ? { ...prev, access: access } : { driver: false, access: access, name: '' }))
  }

  const handleDriverSelect = (checked: boolean) => {
    setUserUpdate((prev) => (prev ? { ...prev, driver: checked } : { driver: checked, access: undefined, name: '' }))
  }

  const handleUserForm = (firstName: string, lastName: string) => {
    const fullName = `${firstName.trim()} ${lastName.trim()}`
    const updatedName = capitalizeFirstLetter(fullName)
    userUpdate &&
      setUserUpdate(() => {
        userUpdate.name = updatedName
        return userUpdate
      })
    handleUserUpdate()
  }

  const renderDriverInfoText = () => {
    let temp = undefined
    if (tenants && tenants.length === 1) {
      temp = tenants[0].displayName || tenants[0].name
    } else if (tenants && tenants.length > 1) {
      temp = `${tenants.length} ${tenantsTxt}`
    }
    return `${driverInfoTxt} ${temp}`
  }

  const renderAccessInfoText = () => {
    let temp = undefined
    if (tenants && tenants.length === 1) {
      temp = tenants[0].displayName || tenants[0].name
    } else if (tenants && tenants.length > 1) {
      temp = `${tenants.length} ${tenantsTxt}`
    }
    return `${accessInfoTxt} ${temp}`
  }

  const handleModifyBtn = (user: AugmentedUser) => {
    tenantScope &&
      Array.isArray(tenantScope) &&
      tenantScope.map((tenantScopeObj) => {
        const { id } = tenantScopeObj

        const driver = user.tenantPermission[id]?.driver
        const access = user.tenantPermission[id]?.access
        const name = user.name

        return setUserUpdate({
          driver: driver,
          access: access,
          name: name,
        })
      })
    setSelectedUser(user)
    setEditUserNameForm(true)
  }

  // const getTenantDisplayName = () => {
  //   const tenantName = currentUser?.accesses.filter((obj) => {
  //     return obj.display === selectedTenant?.name
  //   })
  //   if (tenantName !== undefined && tenantName.length !== 0) {
  //     // setTenantDisplayName(tenantName[0].displayName)
  //   }
  // }

  const handleAddUser = () => {
    let currentAugmentedUserTemp = currentUser && toAugmentedUser(currentUser)
    setCurrentAugmentedUser(currentAugmentedUserTemp)
    // setAddUserToTenant(currentAugmentedUserTemp)
    setShowInvitationForm(true)
  }

  const renderDriverCheckbox = (user: AugmentedUser, tenants: TenantRef[]): boolean => {
    const { accesses } = user
    let driver = false
    if (isDesktop) {
      accesses.map((access) => {
        if (access.resourceId === tenants[0].id) {
          return access.permissions.map((permission) => {
            if (permission === 'tenant.driver') return (driver = true)
            return (driver = false)
          })
        }
        return driver
      })
    }
    return driver
  }

  const renderAccessTag = (user: AugmentedUser): JSX.Element => {
    const { tenantPermission } = user
    const permissions = Object.values(tenantPermission)

    const selectedUserTenants = permissions.map((permission) => permission.name)

    const selectedTenants = tenants.map((tenant) => tenant.name)
    const checkSelectedTenants = selectedTenants.every((v) => selectedUserTenants.includes(v))

    let access: DashboardAccessType | 'Multi' | undefined
    if (tenants.length === 1) {
      access = permissions
        .filter((permission) => permission.id === tenants[0].id)
        .map((permission) => permission.access)[0]
    } else if (tenants.length > 1) {
      const accesses = permissions.map((obj) => obj.access)
      if (accesses.every((access) => access === 'manager')) {
        access = 'manager'
      } else if (accesses.every((access) => access === 'viewer')) {
        access = 'viewer'
      } else if (accesses.some((access) => access === 'manager' || access === 'viewer')) {
        access = 'Multi'
      }
    }

    if (access && checkSelectedTenants) {
      return <Tag>{access}</Tag>
    } else if (!checkSelectedTenants) {
      return (
        <AccessTagContainer>
          <Tag>{access}</Tag>
          {term && <Tag color="red">{userTenantWarningText}</Tag>}
        </AccessTagContainer>
      )
    }
    return <Tag />
  }

  const renderAccess = (user: AugmentedUser) => {
    const { tenantPermission } = user
    const permissions = Object.values(tenantPermission)

    let access: DashboardAccessType | 'Multi' | undefined
    if (tenants.length === 1) {
      access = permissions
        .filter((permission) => permission.id === tenants[0].id)
        .map((permission) => permission.access)[0]
    }
    if (access === undefined) {
      access = 'none'
    }

    if (access === 'none') {
      return <div>None</div>
    } else if (access === 'manager') {
      return <div>Manager</div>
    } else if (access === 'viewer') {
      return <div>Viewer</div>
    } else if (access === 'Multi') {
      return <div>Multi</div>
    }
  }

  const columns: any = [
    {
      title: idText,
      dataIndex: 'id',
    },
    {
      title: emailTxt,
      dataIndex: 'email',
    },
    {
      title: userText,
      render: (user: User) => {
        return user.name
      },
    },
  ]

  if (hasTenantScope && tenants && tenants.length === 1) {
    columns.push(
      {
        title: (
          <>
            {driverTxt}
            <Tooltip
              overlayInnerStyle={{ borderRadius: '5px', width: '172px', padding: '7px' }}
              placement="top"
              title={renderDriverInfoText()}
            >
              <DriverSvgSpacing>{greyInfoSVG}</DriverSvgSpacing>
            </Tooltip>
          </>
        ),
        key: 'driver',
        render: (user: AugmentedUser) => {
          if (!tenantScope) {
            return <></>
          }
          const isDriver = renderDriverCheckbox(user, tenants)

          if (isDriver) {
            return <>{accessText}</>
          } else {
            return <div style={{ color: '#7D838A' }}>{noAccessText}</div>
          }
        },
      },
      {
        title: <div>{roleTxt}</div>,
        key: 'tenantAccess',
        render: (user: AugmentedUser) => {
          return renderAccess(user)
        },
      },
      {
        title: '',
        key: 'action',
        render: (user: AugmentedUser) => (
          <EditButtonWrapper>
            <ButtonModal type="link" icon={editSVG} click={() => handleModifyBtn(user)} />
          </EditButtonWrapper>
        ),
      },
    )
  } else {
    if (currentUser?.role === 'admin' || currentUser?.role === 'user') {
      columns.push({
        title: '',
        key: 'action',
        render: (user: AugmentedUser) => (
          <EditButtonWrapper>
            <ButtonModal type="link" icon={editSVG} click={() => handleModifyBtn(user)} />
          </EditButtonWrapper>
        ),
      })
    }
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTerm(e.target.value)
  }

  const handleSubscriptionEmailChange = (e: UserWallet | undefined) => {
    setSubscriptionEmailSearch(e)
  }

  const handleTabOptions = () => {
    switch (tab) {
      case 'overview':
        return (
          <Row>
            <div className="user-selector">
              <MultiTenantStyling>
                <MultiTenantSelector
                  isCombineRight
                  noChangeOnFocus={true}
                  onOptionsChange={(tenants) => setTenants(tenants)}
                  onClear={() => setTenants([])}
                />
              </MultiTenantStyling>
              <LineBreak />
              <UserSelectorStyling>
                <div style={{ display: 'flex' }}>
                  <NoIconSearch
                    svg={searchSVG}
                    placeholder={emailTxt}
                    onChange={handleEmailChange}
                    onPressEnter={fetchUsers}
                  />
                  <SearchButtonWrapper>
                    <ButtonIcon icon={magnifyBlackSVG} loading={loading} fetchData={fetchUsers} />
                  </SearchButtonWrapper>
                </div>
              </UserSelectorStyling>
            </div>
            <Space>
              <InviteButtonWrapper>
                <ButtonModal size="large" click={handleAddUser} icon={plusSVG} text={inviteUser} type="primary" />
              </InviteButtonWrapper>
            </Space>
          </Row>
        )
      case 'plans':
        return (
          <TabsButtonWrapper>
            <ButtonModal
              size="large"
              click={() => setShowCreatePlanForm(true)}
              icon={plusSVG}
              text={createPlanText}
              type="primary"
            />
          </TabsButtonWrapper>
        )
      case 'subscriptions':
        return (
          <TabsButtonWrapper className="subscriptions-header">
            <div style={{ display: 'flex' }}>
              <UserSelectorNoTagRedesign
                onUserSelected={(user) => handleSubscriptionEmailChange(user)}
                onPressEnter={() => setSearchSubscriptions(!searchSubscriptions)}
              />
            </div>
            <ButtonModal
              size="large"
              click={() => setShowInviteForm(true)}
              icon={plusSVG}
              text={subscribeUserText}
              type="primary"
            />
          </TabsButtonWrapper>
        )
      default:
        return null
    }
  }

  const renderTitle = () => {
    if (currentUser?.role !== 'admin') {
      return (
        <Row
          gutter={[8, 8]}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#fafafa',
            padding: '20px 4px 21px',
          }}
        >
          <div style={{ display: 'flex' }}>
            <MultiTenantStyling>
              <MultiTenantSelector
                isCombineRight
                noChangeOnFocus={true}
                onOptionsChange={(tenants) => setTenants(tenants)}
                onClear={() => setTenants([])}
              />
            </MultiTenantStyling>
            <LineBreak />
            <UserSelectorStyling>
              <div style={{ display: 'flex' }}>
                <NoIconSearch
                  svg={searchSVG}
                  placeholder={emailTxt}
                  onChange={handleEmailChange}
                  onPressEnter={fetchUsers}
                />
                <SearchButtonWrapper>
                  <ButtonIcon icon={magnifyBlackSVG} loading={loading} fetchData={fetchUsers} />
                </SearchButtonWrapper>
              </div>
            </UserSelectorStyling>
          </div>
          <Space>
            <InviteButtonWrapper>
              <ButtonModal size="large" click={handleAddUser} icon={plusSVG} text={inviteUser} type="primary" />
            </InviteButtonWrapper>
          </Space>
        </Row>
      )
    }
  }

  const renderColumns = (): any => {
    let currentAugmentedUser = currentUser && toAugmentedUser(currentUser)
    const tenantAccesses =
      currentAugmentedUser?.tenantPermission && Object.values(currentAugmentedUser?.tenantPermission)
    const isManager = tenantAccesses?.some((item) => item.access === 'manager')

    if ((currentAugmentedUser?.isAdmin || isManager) && !term) {
      return columns
        .filter((col: any) => col.key !== 'manageraddusertotenants')
        .filter((col: any) => col.key !== 'addusertotenants')
    }
    return term ? columns : columns.filter((col: any) => col.key !== 'addusertotenants')
  }

  const renderTitleMobile = () => {
    if (selectedTenant) {
      return <></>
    }
    return (
      <Row
        gutter={[8, 8]}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: '#fafafa',
          padding: '20px 4px 21px',
        }}
      >
        <div style={{ display: 'flex' }}>
          <MultiTenantStyling>
            <MultiTenantSelector
              isCombineRight
              noChangeOnFocus={true}
              onOptionsChange={(tenants) => setTenants(tenants)}
              onClear={() => setTenants([])}
            />
          </MultiTenantStyling>
          <LineBreak />
          <UserSelectorStyling>
            <div style={{ display: 'flex' }}>
              <NoIconSearch
                svg={searchSVG}
                placeholder={emailTxt}
                onChange={handleEmailChange}
                onPressEnter={fetchUsers}
              />
              <SearchButtonWrapper>
                <ButtonIcon icon={magnifyBlackSVG} loading={loading} fetchData={fetchUsers} />
              </SearchButtonWrapper>
            </div>
          </UserSelectorStyling>
        </div>
        <Space>
          <InviteButtonWrapper>
            <ButtonModal size="large" click={handleAddUser} icon={plusSVG} text={inviteUser} type="primary" />
          </InviteButtonWrapper>
        </Space>
      </Row>
    )
  }

  const usersTable = (
    <Row>
      <Col span={24}>
        <AlertError error={error} />
        <Col>
          {(isDesktop || isLaptop) && (
            <TableStyling usersPage>
              <TableWrapper userIsAdmin={currentUser?.role === 'admin'}>
                <ResponsiveTable
                  title={renderTitle}
                  loading={loading}
                  size="small"
                  pagination={{
                    position: ['bottomCenter'],
                    total: pagination?.totalEntries,
                    current: pagination?.currentPage,
                    pageSize: pagination?.perPage,
                    showSizeChanger: false,
                    onChange: onPaginationChange,
                  }}
                  dataSource={users}
                  columns={renderColumns()}
                  rowKey="id"
                />
              </TableWrapper>
            </TableStyling>
          )}
          {(isMobile || isTablet) && (
            <>
              {renderTitleMobile()}
              <UsersListView
                users={users}
                onToggleRole={handleToggleRole}
                hasTenantScope={hasTenantScope}
                tenantScope={selectedTenant}
                selectedUser={selectedUser}
                pagination={pagination}
                onPaginationChange={onPaginationChange}
                loading={loading}
                onSelectedUser={(u) => setSelectedUser(u)}
                userUpdate={userUpdate}
                onClickSaveBtn={(firstName, lastName) => handleUserForm(firstName, lastName)}
                onClickCross={() => handleClickCross()}
                onClickPlus={() => handleClickPlus()}
                onUserSelect={(value) => handleUserSelect(value)}
                onDriverSelect={(checked) => handleDriverSelect(checked)}
                onClickModifyBtn={(user) => handleModifyBtn(user)}
                isMobile={isMobile}
                renderDriverCheckbox={(user) => renderDriverCheckbox(user, tenants)}
                renderAccessTag={(user) => renderAccessTag(user)}
                renderDriverInfoText={renderDriverInfoText}
                renderAccessInfoText={renderAccessInfoText}
              />
            </>
          )}
        </Col>
      </Col>
    </Row>
  )

  const handleUserEdit = () => {
    setEditUserNameForm(false)
    return fetchUsers()
  }

  return (
    <>
      <>
        <CustomBox>
          {currentUser?.role === 'admin' ? (
            <TabsWrapper>
              <Tabs
                className="tabs-styling"
                defaultActiveKey="overview"
                onChange={(key) => setTab(key)}
                tabBarExtraContent={handleTabOptions()}
              >
                <Tabs.TabPane key="overview" tab={<span className="regular-cap">{overviewText}</span>}>
                  {usersTable}
                </Tabs.TabPane>
                <Tabs.TabPane key="plans" tab={<span className="regular-cap">{plansText}</span>}>
                  <PlansRedesign createPlanForm={createPlanForm} onCancel={() => setShowCreatePlanForm(false)} />
                </Tabs.TabPane>
                <Tabs.TabPane key="subscriptions" tab={<span className="regular-cap">{subscriptionsText}</span>}>
                  <SubscriptionsRedesign
                    searchUser={subscriptionEmailSearch}
                    searchSubscriptions={searchSubscriptions}
                    onUserInvited={() => setShowInviteForm(false)}
                    showInviteForm={showInviteForm}
                  />
                </Tabs.TabPane>
              </Tabs>
            </TabsWrapper>
          ) : (
            usersTable
          )}
        </CustomBox>
      </>
      {showInvitationForm && currentAugmentedUser && (
        <UserInvitationRedesign
          onCancel={(e: React.FormEvent<EventTarget>) => setShowInvitationForm(false)}
          onUserInvited={handleUserInvited}
        />
      )}

      {selectedUser && showEditUserNameForm && currentAugmentedUser && (
        <UserEditForm
          onCancel={() => setEditUserNameForm(false)}
          onUserEdit={handleUserEdit}
          selectedUser={selectedUser}
          user={currentAugmentedUser}
        />
      )}
    </>
  )
}
