import { log } from '../../logger'
import configProvider from '../../config'
import { PartnerRef } from '../../models/partner'
import { mockPartners } from '../../mock/channel-partner-mock'

import { apiClient } from './client'

export async function GetChannelPartners(): Promise<PartnerRef[]> {
  const url = '/channel_partners'

  log('fetching channel partners', { url: url })
  if (configProvider.config.mockEnabled) {
  return Promise.resolve(mockPartners)
  }
  return apiClient(url, {})
}

export async function CreateChannelPartner(name: string): Promise<PartnerRef> {
  const url = '/channel_partners'

  log('create a channel partner', { url: url })
  if (configProvider.config.mockEnabled) {
    const newMockPartner = { id: mockPartners.length + 1, name }
    return Promise.resolve(newMockPartner)
  }
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      channel_partner: {
        name,
      },
    }),
  })
}
