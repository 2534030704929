import React, { useContext } from 'react'
import configProvider from '../config'
import { Network } from '../models/network'
import { clientInfo } from '../atom/white-label'

interface NetworkContextType {
  network: Network
}

const NetworkContext = React.createContext<NetworkContextType>(null!)

export function NetworkProvider(props: React.PropsWithChildren<{}>) {
  let logo = (
    <>
      <svg
        width="110"
        height="30"
        viewBox="0 0 110 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="swtch-icon swtch-logo"
      >
        <path
          d="M36.3432 12.4438C35.8715 12.3834 35.3382 12.323 34.7639 12.2626C33.9845 12.182 33.2051 12.1015 32.5692 12.0008C31.8718 11.88 31.0309 11.5175 31.0309 10.4101C31.0309 9.90667 31.195 9.52409 31.5437 9.14151C32.2821 8.39649 33.5127 8.27567 34.1691 8.27567C35.1126 8.27567 36.1586 8.61798 36.6304 9.10124C37.0201 9.52409 37.3072 10.108 37.3278 10.6114L37.3483 10.8732H41.1428V10.5913C41.1223 9.20192 40.4864 7.87295 39.2763 6.74535C38.0661 5.63788 36.2202 5.01367 34.2101 5.01367C32.118 5.01367 30.1284 5.71842 28.8978 6.90644C27.8517 7.93336 27.2979 9.18178 27.2979 10.4705C27.2979 13.1687 29.0413 15.0614 31.9539 15.5246C32.5077 15.6252 33.164 15.6856 33.7794 15.7461C34.4767 15.8065 35.1946 15.887 35.8099 15.9877C36.6509 16.1286 37.6559 16.5716 37.6559 17.7596C37.6559 18.5248 37.3688 18.8671 37.0406 19.2094C36.3637 19.894 35.1331 20.0551 34.2101 20.0551C33.3486 20.0551 32.1795 19.9142 31.4821 19.2094C30.9489 18.6859 30.6617 18.0013 30.6617 17.2965V17.0347H26.8672V17.3166C26.8672 18.8671 27.5851 20.4377 28.7747 21.5049C30.0874 22.6929 32.0154 23.3372 34.1691 23.3372C35.892 23.3372 38.2713 22.9949 39.8711 21.4243C40.8966 20.4176 41.4094 19.149 41.4094 17.659C41.3684 15.4843 40.0557 12.9673 36.3432 12.4438Z"
          fill="white"
        />
        <path
          d="M57.8393 16.4302L54.578 5.61723H51.5014L48.1786 16.4302L45.9019 5.35547H42.3125L45.9429 23.0347H49.5528L52.9987 11.8795L56.424 23.0347H60.0339L63.6233 5.35547H60.157L57.8393 16.4302Z"
          fill="white"
        />
        <path d="M65.1836 8.79869H70.3523V23.0347H74.0648V8.79869H79.2336V5.35547H65.1836V8.79869Z" fill="white" />
        <path
          d="M87.2531 8.41688C88.2991 8.41688 89.1811 8.75919 89.6734 9.3834C90.1246 9.92706 90.3297 10.4103 90.3912 11.0547L90.4117 11.2963H94.2063L94.1652 10.9943C94.0422 9.90693 93.6525 8.35647 92.4218 7.10805C91.1091 5.79922 89.2221 5.0542 87.2326 5.0542C85.202 5.0542 83.438 5.75895 82.0843 7.06778C80.0127 9.10149 79.8281 11.7191 79.8281 14.216C79.8281 16.7128 79.9922 19.3305 82.0843 21.3642C83.4175 22.673 85.1815 23.3576 87.2326 23.3576C89.1811 23.3576 91.0886 22.5925 92.4218 21.2434C93.3858 20.2769 94.0012 18.8875 94.1652 17.337L94.2063 17.035H90.4117L90.3912 17.2766C90.3297 17.9411 90.1041 18.5049 89.6528 19.0284C89.1606 19.6527 88.2581 20.0151 87.2326 20.0151C86.2891 20.0151 85.3866 19.6325 84.7507 18.968C83.6226 17.8203 83.6226 15.7463 83.6226 14.216C83.6226 12.7058 83.6226 10.6318 84.7507 9.46394C85.4071 8.79946 86.3096 8.41688 87.2531 8.41688Z"
          fill="white"
        />
        <path
          d="M106.287 5.35547V12.0607H99.6617V5.35547H95.9492V23.0347H99.6617V15.5643H106.287V23.0347H109.999V5.35547H106.287Z"
          fill="white"
        />
        <path
          d="M10.6034 0C7.59376 0 4.81248 1.12722 2.9652 2.91868C-5.25412 10.6683 5.72573 21.8599 10.4373 29.066C10.5203 29.1868 10.7071 29.1868 10.7902 29.066C15.5225 21.8599 26.4609 10.6683 18.2208 2.91868C16.1452 1.12722 13.613 0 10.6034 0ZM14.9206 11.8559C14.9206 13.7883 13.5299 15.4791 11.5996 15.9219V19.9477C11.5996 20.471 11.1638 20.8937 10.6241 20.8937C10.0845 20.8937 9.64859 20.471 9.64859 19.9477V15.9219C7.7183 15.4791 6.32765 13.7883 6.32765 11.8559V8.7963H8.0919V6.50162C8.0919 6.1393 8.38249 5.85749 8.75609 5.85749C9.1297 5.85749 9.42028 6.1393 9.42028 6.50162V8.7963H11.8072V6.50162C11.8072 6.1393 12.0978 5.85749 12.4714 5.85749C12.845 5.85749 13.1356 6.1393 13.1356 6.50162V8.7963H14.8998V11.8559H14.9206Z"
          fill="white"
        />
      </svg>
    </>
  )
  let icon = (
    <>
      <svg
        width="21.2"
        height="29.16"
        viewBox="0 0 22 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="swtch-icon"
      >
        <path
          d="M10.6034 0C7.59376 0 4.81248 1.12722 2.9652 2.91868C-5.25412 10.6683 5.72573 21.8599 10.4373 29.066C10.5203 29.1868 10.7071 29.1868 10.7902 29.066C15.5225 21.8599 26.4608 10.6683 18.2208 2.91868C16.1452 1.12722 13.613 0 10.6034 0ZM14.9206 11.8559C14.9206 13.7883 13.5299 15.4791 11.5996 15.9219V19.9477C11.5996 20.471 11.1638 20.8937 10.6241 20.8937C10.0845 20.8937 9.64859 20.471 9.64859 19.9477V15.9219C7.7183 15.4791 6.32765 13.7883 6.32765 11.8559V8.7963H8.0919V6.50162C8.0919 6.1393 8.38249 5.85749 8.75609 5.85749C9.1297 5.85749 9.42028 6.1393 9.42028 6.50162V8.7963H11.8072V6.50162C11.8072 6.1393 12.0978 5.85749 12.4714 5.85749C12.845 5.85749 13.1356 6.1393 13.1356 6.50162V8.7963H14.8998V11.8559H14.9206Z"
          fill="white"
        />
      </svg>
    </>
  )
  let name = 'swtch'
  let title = 'Swtch'

  if (configProvider.config.network) {
    switch (configProvider.config.network) {
      case 'powercharge':
        logo = (
          <img
            src={clientInfo.powercharge.banner}
            alt={clientInfo.powercharge.logoAlt}
            style={clientInfo.powercharge.logoStyle}
          />
        )
        icon = (
          <img
            src={clientInfo.powercharge.icon}
            alt={clientInfo.powercharge.iconAlt}
            style={clientInfo.powercharge.iconStyle}
          />
        )
        name = clientInfo.powercharge.name
        title = clientInfo.powercharge.title
        break
      case 'evzone':
        logo = <img src={clientInfo.evzone.icon} alt={clientInfo.evzone.logoAlt} style={clientInfo.evzone.logoStyle} />
        icon = (
          <img src={clientInfo.evzone.banner} alt={clientInfo.evzone.iconAlt} style={clientInfo.evzone.iconStyle} />
        )
        name = clientInfo.evzone.name
        title = clientInfo.evzone.title
        break
      case 'atg':
        logo = <img src={clientInfo.atg.icon} alt={clientInfo.atg.logoAlt} style={clientInfo.atg.logoStyle} />
        icon = <img src={clientInfo.atg.banner} alt={clientInfo.atg.iconAlt} style={clientInfo.atg.iconStyle} />
        name = clientInfo.atg.name
        title = clientInfo.atg.title
    }
  }

  return (
    <NetworkContext.Provider
      value={{
        network: { logo, name, title, icon },
      }}
    >
      {props.children}
    </NetworkContext.Provider>
  )
}

export const useNetwork = (): Network => {
  const context = useContext(NetworkContext)
  if (!context) {
    throw new Error('useNetwork must be used within the <NetworkProvider>..</NetworkProvider>')
  }
  return context.network
}
