import { PeakShavingEventFilter } from '../../models/filter'

export const peakShavingEventQuery = (f: PeakShavingEventFilter): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {}
  if (f.page) {
    queryParams['page'] = `${f.page}`
  }

  if (f.programId) {
    queryParams['program_id'] = `${f.programId}`
  }

  if (f.programName) {
    queryParams['q'] = `${f.programName}`
  }

  if (f.state) {
    queryParams['state'] = `${f.state}`
  }

  return queryParams
}
