import { Button, Col, Divider, Form, Input, PageHeader, Row, Select, Space, Spin, Switch, message } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import { formLayout } from '../../atom/form/page-layout'
import { AlertError } from '../../components/error'
import { Links } from '../../components/routes/paths'
import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { PeakShavingProgramSelector } from '../../components/selector/peak-shaving-program-selector'

import { SwtchError } from '../../models/error'
import { PeakShavingEvent, PeakShavingEventSchedule, PeakShavingEventScheduleZone } from '../../models/peak-shaving'

import {
  CreateEventScheduleForEvent,
  GetAllEventSchedulesForEvent,
  GetPeakShavingEventDetails,
  PublishPeakShavingEvent,
  UnPublishPeakShavingEvent,
  UpdatePeakShavingEvent,
} from '../../services/data-provider/peak-shaving'

import { DeleteEventModal } from './delete-event-modal'
import { styled } from '../../theme'

const PeakScheduleContainer = styled.div`
  /* display: flex;
  justify-content: center; */

  .ant-space-align-center {
    align-items: baseline;
  }

  input {
    width: 90px;
    border-radius: 5px;
    text-align: center;
  }

  .zoneContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`

const PeakZoneItemContainer = styled.div`
  .ant-space {
    width: 180px;
  }
`

const PeakZoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 auto;
`

const PeakShavingEventBasePage: React.FC = () => {
  //@ts-ignore
  const { peakShavingEventId } = useParams()
  const [peakShavingEvent, setPeakShavingEvent] = useState<PeakShavingEvent>()
  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(false)
  const [modifyEventForm] = Form.useForm()
  const [disabled, setDisabled] = useState(true)
  const [eventSchduledisabled, setEventSchduleDisabled] = useState(true)
  const [deleteEventModalVisible, setDeleteEventModalVisible] = useState(false)
  const [term, setTerm] = useState('')
  const history = useHistory()
  const [eventSchedules, setEventSchedules] = useState<PeakShavingEventSchedule[]>([])
  const [eventZones, setEventZones] = useState<PeakShavingEventScheduleZone[]>([...Array(24).fill('none')])
  const [peakSchedulesForm] = Form.useForm()

  const renderZone = (zone: PeakShavingEventScheduleZone): string => {
    let output = ''
    switch (zone) {
      case 'none':
        output = 'None'
        break
      case 'primary':
        output = 'Primary Peak Zone'
        break
      case 'secondary':
        output = 'Secondary Peak Zone'
        break
    }
    return output
  }

  useEffect(() => {
    document.querySelector('body')?.classList.add('redesignActive')
    setLoading(true)
    GetAllEventSchedulesForEvent(peakShavingEventId)
      .then((resp) => setEventSchedules(resp))
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    eventSchedules &&
      Array.isArray(eventSchedules) &&
      eventSchedules.length !== 0 &&
      eventSchedules.map((obj) => {
        return (eventZones[obj.from] = obj.zone)
      })
    setEventZones(eventZones)
    peakSchedulesForm.setFieldsValue({ ...eventZones })
  }, [eventSchedules])

  useEffect(() => {
    setLoading(true)
    GetPeakShavingEventDetails(peakShavingEventId)
      .then((resp) => setPeakShavingEvent(resp))
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }, [term])

  const handlePublishEvent = () => {
    setLoading(true)
    PublishPeakShavingEvent(peakShavingEventId)
      .then(({ response }) => {
        message.success(response)
        setTerm(`${_.random(Number.MIN_VALUE, Number.MAX_VALUE)}`)
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  const handleUnPublishEvent = () => {
    setLoading(true)
    UnPublishPeakShavingEvent(peakShavingEventId)
      .then(({ response }) => {
        message.success(response)
        setTerm(`${_.random(Number.MIN_VALUE, Number.MAX_VALUE)}`)
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  const handleCancel = () => {
    setDisabled(true)
    modifyEventForm.resetFields()
  }

  const handleEventSchduleForm = () => {
    setEventSchduleDisabled(true)
    peakSchedulesForm.resetFields()
  }

  const handleOk = (values: any) => {
    setDisabled(!disabled)
    const { date, ...params } = values
    const updateParams = {
      date: String(moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ')),
      id: peakShavingEventId,
      ...params,
    }
    setLoading(true)
    UpdatePeakShavingEvent(updateParams)
      .then((resp) => {
        setPeakShavingEvent(resp)
        setTerm(`${_.random(Number.MIN_VALUE, Number.MAX_VALUE)}`)
        message.success(`Event Successfully updated!`)
        modifyEventForm.resetFields()
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  const onSelectEventZone = (values: any, index: number) => {
    const [zone] = Object.entries(values)
      .map((obj) => obj[1])
      .filter((_, i) => i === index)

    setLoading(true)
    CreateEventScheduleForEvent(peakShavingEventId, {
      from: index,
      zone: zone as PeakShavingEventScheduleZone,
    })
      .then((resp) => message.success(`Updated Zone to ${renderZone(resp.zone)}`))
      .catch((err) => setError(err))
      .finally(() => {
        setLoading(false)
        setEventSchduleDisabled(!eventSchduledisabled)
      })
  }

  return (
    <Spin spinning={loading}>
      <AlertError error={error} />
      <PageHeader
        title="Event"
        extra={
          <Space>
            {peakShavingEvent && peakShavingEvent.state === 'draft' && (
              <Button type="primary" onClick={handlePublishEvent}>
                Publish Event
              </Button>
            )}
            {peakShavingEvent && peakShavingEvent.state === 'published' && (
              <Button type="primary" onClick={handleUnPublishEvent}>
                UnPublish Event
              </Button>
            )}
            {peakShavingEvent && peakShavingEvent.state === 'draft' && (
              <Button
                type="primary"
                danger
                onClick={() => setDeleteEventModalVisible(!deleteEventModalVisible)}
                disabled={!disabled}
              >
                Delete Event
              </Button>
            )}
          </Space>
        }
      />
      {peakShavingEvent && (
        <>
          <Divider>Event Details</Divider>
          <Form
            {...formLayout}
            form={modifyEventForm}
            onFinish={handleOk}
            style={{ marginTop: '2rem' }}
            initialValues={{
              date: peakShavingEvent.date,
              programId: peakShavingEvent.programId,
              allow_opt_out: peakShavingEvent.allowOptOut,
              source: peakShavingEvent.source,
              notes: peakShavingEvent.notes,
            }}
          >
            <Form.Item label="Date" name="date" key="date">
              {!disabled ? <Input disabled /> : <span>{peakShavingEvent.date}</span>}
            </Form.Item>
            <Form.Item
              label="Program"
              name="programId"
              key="programId"
              rules={[{ required: true, message: 'Program is required!' }]}
            >
              {!disabled ? (
                <PeakShavingProgramSelector
                  onProgramSelected={(l) => modifyEventForm.setFieldsValue({ ['programId']: l.id })}
                  defaultOption={peakShavingEvent.program.name}
                  disabled={disabled}
                  onClear={() => {}}
                />
              ) : (
                <span>{peakShavingEvent.program.name}</span>
              )}
            </Form.Item>
            <Form.Item label="Allow Opt Out" name="allow_opt_out" valuePropName="checked" key="allow_opt_out">
              {!disabled ? <Switch disabled={disabled} /> : <span>{peakShavingEvent.allowOptOut ? 'Yes' : 'No'}</span>}
            </Form.Item>

            <Form.Item label="Source" name="source" key="source">
              {!disabled ? (
                <Select options={[{ value: 'manual', label: 'Manual' }]} disabled={disabled} />
              ) : (
                <span>{peakShavingEvent.source}</span>
              )}
            </Form.Item>
            <Form.Item label="Notes" name="notes" key="notes">
              {!disabled ? <Input.TextArea rows={5} disabled={disabled} /> : <span>{peakShavingEvent.notes}</span>}
            </Form.Item>
            <Form.Item
              {...{
                wrapperCol: { offset: 11, span: 2 },
              }}
            >
              {disabled && (
                <Button onClick={() => setDisabled(!disabled)} type="primary" block>
                  Edit
                </Button>
              )}
              {!disabled && (
                <Space>
                  <Button onClick={handleCancel}>Cancel</Button>
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                </Space>
              )}
            </Form.Item>
          </Form>
        </>
      )}
      <Divider>Event Schedule</Divider>
      {eventZones && (
        <PeakScheduleContainer>
          <Form {...formLayout} initialValues={{ ...eventZones }} form={peakSchedulesForm}>
            <Row gutter={[32, 32]}>
              <PeakZoneContainer>
                {eventZones.map((_, index) => {
                  return (
                    <PeakZoneItemContainer key={index}>
                      <Col span={24}>
                        <Space>
                          <span>{`${index < 10 ? `0${index}:00` : `${index}:00`}`}</span>

                          <span>{`${index + 1 < 10 ? `0${index + 1}:00` : `${index + 1}:00`}`}</span>

                          {eventSchduledisabled ? (
                            <Form.Item name={`${index}`} shouldUpdate={true} style={{ minWidth: 380 }}>
                              <span>{renderZone(peakSchedulesForm.getFieldValue(index))}</span>
                            </Form.Item>
                          ) : (
                            <Form.Item name={`${index}`} shouldUpdate={true}>
                              <Select
                                style={{ width: 180 }}
                                options={[
                                  {
                                    value: 'none',
                                    label: 'None',
                                    from: `${index}`,
                                    to: `${index + 1}`,
                                    key: 'none',
                                  },
                                  {
                                    value: 'primary',
                                    label: 'Primary Peak Zone',
                                    from: `${index}`,
                                    to: `${index + 1}`,
                                    key: 'primary',
                                  },
                                  {
                                    value: 'secondary',
                                    label: 'Secondary Peak Zone',
                                    from: `${index}`,
                                    to: `${index + 1}`,
                                    key: 'secondary',
                                  },
                                ]}
                                onSelect={() => onSelectEventZone(peakSchedulesForm.getFieldsValue(), index)}
                                disabled={eventSchduledisabled}
                              />
                            </Form.Item>
                          )}
                        </Space>
                      </Col>
                    </PeakZoneItemContainer>
                  )
                })}
              </PeakZoneContainer>
            </Row>
            <Form.Item
              {...{
                wrapperCol: { offset: 11, span: 2 },
              }}
            >
              {eventSchduledisabled && (
                <Button
                  block
                  type="primary"
                  onClick={() => setEventSchduleDisabled(!eventSchduledisabled)}
                  style={{ marginTop: '8px' }}
                >
                  Edit
                </Button>
              )}
              {!eventSchduledisabled && (
                <Button onClick={handleEventSchduleForm} block>
                  Done Editing
                </Button>
              )}
            </Form.Item>
          </Form>
        </PeakScheduleContainer>
      )}
      {peakShavingEvent && (
        <DeleteEventModal
          visible={deleteEventModalVisible}
          event={peakShavingEvent}
          onCancel={() => setDeleteEventModalVisible(!deleteEventModalVisible)}
          onSubmit={() => {
            setDeleteEventModalVisible(!deleteEventModalVisible)
            history.push(Links.peakShavingEvents())
          }}
        />
      )}
    </Spin>
  )
}

export const PeakShavingEventPage = withAuthenticatedLayout(PeakShavingEventBasePage)
