import { Row } from 'antd'

import { Section } from '../../../../atom/box'
import { MetricsWrapper } from '../../ContainerStyles/homepageStyles'
import { TrxSummary } from '../../../../models/transaction'
import TransactionMetrics from './TransactionMetric'
import RevenueMetrics from './RevenueMetric'
import EnergyMetrics from './EnergyMetric'
import GHGMetrics from './GHGMetric'

interface props {
  loading: boolean
  trxProfileSummary?: TrxSummary
}

const MetricsSummary: React.FC<props> = ({ loading, trxProfileSummary }) => {
  return (
    <Section style={{ margin: '30px auto' }}>
      <MetricsWrapper>
        <Row align="middle" justify="start" gutter={[15, 15]} className="content metrics">
          <TransactionMetrics loading={loading} trxProfileSummary={trxProfileSummary} />
          <RevenueMetrics loading={loading} trxProfileSummary={trxProfileSummary} />
          <EnergyMetrics loading={loading} trxProfileSummary={trxProfileSummary} />
          <GHGMetrics loading={loading} trxProfileSummary={trxProfileSummary} />
        </Row>
      </MetricsWrapper>
    </Section>
  )
}

export default MetricsSummary
