import { Badge } from 'antd'
import React from 'react'
import { useFormatMessage } from '../../helpers/intl'
import { Transaction } from '../../models/transaction'
import { theme } from '../../theme'

interface props {
  transaction: Transaction
}

export const TransactionStateBadge: React.FC<props> = ({ transaction }) => {
  const headings = [
    useFormatMessage('dashboard.transaction.confirmed', 'Confirmed'),
    useFormatMessage('dashboard.transaction.free', 'Free'),
    useFormatMessage('dashboard.transaction.pending_payment', 'Pending Payment'),
    useFormatMessage('dashboard.transaction.canceled', 'Canceled'),
    useFormatMessage('dashboard.transaction.errored', 'Errored'),
    useFormatMessage('dashboard.transaction.paid', 'Paid'),
    useFormatMessage('dashboard.transaction.preauthorized', 'Pre-authorized'),
    useFormatMessage('dashboard.transaction.noReservation', 'No Reservation'),
  ]
  const [status, color] = getStyle(transaction, headings)

  return <Badge count={status} style={{ backgroundColor: color }} />
}

const getStyle = (trx: Transaction, headings: string[]): string[] => {
  switch (trx.currentState) {
    case 'confirmed':
      return [headings[0], theme.colors.blue]
    case 'free':
      return [headings[1], theme.colors.blue]
    case 'pending_payment':
      return [headings[2], theme.colors.blue]
    case 'canceled':
      return [headings[3], theme.colors.gray]
    case 'errored':
      return [headings[4], theme.colors.red]
    case 'paid':
      return [headings[5], theme.colors.yellow]
    case 'preauthorized':
      return [headings[6], theme.colors.yellow]
    case 'no_reservation':
      return [headings[7], theme.colors.yellow]
    default:
      return [trx.currentState, theme.colors.blue]
  }
}