import { CodeMessageResponse, EnableLoadMgmtTenantResponse } from '../models/http'
import { Tenant, TenantRef } from '../models/tenant'
import { mockInstallers } from './installer-mock'
import { mockPropertyMgmtComps } from './property-management-company-mock'

export const mockTenant: { [tenantName: string]: TenantRef } = {
  '10-DeanPark-Rd-Scarborough-ON-M1B3G8': {
    id: '1',
    name: '10-DeanPark-Rd-Scarborough-ON-M1B3G8',
    displayName: 'DeanPark Scarborough',
  },
  '18-Yorkville-Av-Toronto-ON-M4W2J1': {
    id: '2',
    name: '18-Yorkville-Av-Toronto-ON-M4W2J1',
    displayName: 'Yorkville Toronto',
  },
  '81-NavyWharf-Ct-Toronto-ON-M5B3S2': {
    id: '3',
    name: '81-NavyWharf-Ct-Toronto-ON-M5B3S2',
    displayName: '81 NavyWharf Ct Toronto ON M5B3S2',
  },
  '25-Bamburgh-Ci-Scarborough-ON-M1W3W2': {
    id: '4',
    name: '25-Bamburgh-Ci-Scarborough-ON-M1W3W2',
    displayName: 'Bamburgh Scarborogn',
  },
  '133-Hazelton-Av-Toronto-ON-M5R0A6': {
    id: '5',
    name: '133-Hazelton-Av-Toronto-ON-M5R0A6',
    displayName: 'Hazelton Toronto',
  },
  swtchhq: {
    id: '6',
    name: 'swtchhq',
    displayName: 'test_swtch_hq',
  },
  '34-Victoria-Av-Kitchnber-ON-AZX8E9': {
    id: '7',
    name: '133-Hazelton-Av-Toronto-ON-M5R0A6',
    displayName: 'Hazelton Avenue Kitchner',
  },
  '202 - Waverly - Rd - Dartmouth - NS - B2X2H3': {
    id: '8',
    name: '202 - Waverly - Rd - Dartmouth - NS - B2X2H3',
    displayName: '202 Waverly Rd, Dartmouth, NS, B2X2H3',
  },
  '344-Kitchner-Ave-West-ON-M9E8E4': {
    id: '9',
    name: '344-Kitchner-Ave-West-ON-M9E8E4',
    displayName: '344 Kitchner Ave West ON M9E8E4',
  },
}

export const mockTenantById: { [tenantId: number]: Tenant } = {
  4: {
    id: '4',
    name: 'swtchhq',
    displayName: 'test_swtch_hq',
    chargerCount: 23,
    accessCode: 'LALALA',
    accessPolicy: 'Private Individual',
    notes: 'lorem ipsum dolor',
    omOptin: true,
    taxRegistration: 'joint_elected',
    taxNumber: '12-3456789',
    channelPartner: {
      id: 1,
      name: 'Lan Partner',
    },
    masterAccount: {
      id: 1,
      name: 'mc',
    },
    uniformPricing: true,
    allowUniformLocation: false,
    allowUniformAccessPolicy: false,
    displayListingsOnMap: false,
    uniformAccessPolicy: 'private',
    location: {
      address: '109 ossington ave',
      city: 'Toronto',
      country: 'CA',
      latitude: 43.6373,
      listingTimezone: 'America/Toronto',
      longitude: -79.4242,
      postalCode: 'M6J 0G1',
      province: 'ON',
    },
    publishToOcpi: true,
    eyedroIpAddress: '1209.2343.123',
    weeklySchedules: [
      {
        dayOfWeek: 0,
        endHour: '23:45:00',
        startHour: '00:00:00',
      },
      {
        dayOfWeek: 1,
        endHour: '23:45:00',
        startHour: '00:00:00',
      },
      {
        dayOfWeek: 2,
        endHour: '23:45:00',
        startHour: '00:00:00',
      },
      {
        dayOfWeek: 2,
        endHour: '23:45:00',
        startHour: '00:00:00',
      },
      {
        dayOfWeek: 3,
        endHour: '23:45:00',
        startHour: '00:00:00',
      },
      {
        dayOfWeek: 4,
        endHour: '23:45:00',
        startHour: '00:00:00',
      },
      {
        dayOfWeek: 5,
        endHour: '23:45:00',
        startHour: '00:00:00',
      },
      {
        dayOfWeek: 6,
        endHour: '23:45:00',
        startHour: '00:00:00',
      },
    ],
    tenantContacts: [
      {
        namePrimary: 'new updated firstname',
        // lastName: 'new lastname',
        title: 'new title',
        phoneNumber: 'new phonenumber',
        email: 'new email !!!!!!',
        typeOfContact: 'account',
        propertyManagementCompany: mockPropertyMgmtComps[0],
      },
      {
        namePrimary: 'new firstname',
        // lastName: 'new lastname',
        title: 'new title',
        phoneNumber: 'new phonenumber',
        email: 'new email !!!!!!',
        typeOfContact: 'onsite',
      },
      {
        namePrimary: 'new updated firstname',
        // lastName: 'new lastname',
        title: 'new title',
        phoneNumber: 'new phonenumber',
        email: 'new email !!!!!!',
        typeOfContact: 'installer',
        installer: mockInstallers[0],
        nameSecondary: 'secondary first name',
        titleSecondary: 'secondary title',
        phoneNumberSecondary: '1231231313',
        emailSecondary: 'sdfsdf23f@gmail.com',
      },
    ],
    tenantDeployment: {
      electricalService: 'electrical_service_aa rere !!!!',
      networkDevice: 'Wired Ethernet (RJ45)',
      notes: 'notes_bb rr',
      parkingStalls: ['11', '12', '13', '14', '15', '16'],
      loadManagementConfiguration: ['aa', 'bb', 'cc', 'dd0'],
      remoteDesktopIds: ['111', '211', '311', '1011', '222'],
    },
    allowLoadManagement: false,
    allowUniformServiceStatus: true,
    uniformServiceStatus: 'under_repair',
    tenantUniformPricing: {
      tenantListingAuthors: [
        {
          userId: 'NHAwTS6YcUkFb4SYT_4k2w',
          name: 'xwei tang',
        },
        {
          userId: '7_SfFp5X0-kKx620vmnk5A',
          name: 'weihang tang',
        },
        {
          userId: 'QlViWeENlQC7uJz61grRSw',
          name: 'Laura Bryson',
        },
        {
          userId: 'v7NpvrHi6nT225fFCs9t3A',
          name: 'Sam Arabastani',
        },
        {
          userId: 'Li4HGdIsPIsZFVC4L4G6MA',
          name: 'Laura Bryson',
        },
        {
          userId: 'VrIO5E-X7TrGciHUAzrfcQ',
          name: 'Reinier Lamers',
        },
      ],
      tenantListingStripeAccounts: [
        {
          id: 1,
          personId: 'NHAwTS6YcUkFb4SYT_4k2w',
          name: 'primer',
        },
        {
          id: 2,
          personId: 'v7NpvrHi6nT225fFCs9t3A',
          name: 'Test123',
        },
        {
          id: 3,
          personId: 'v7NpvrHi6nT225fFCs9t3A',
          name: 'Quadreal Property Group',
        },
        {
          id: 4,
          personId: 'QlViWeENlQC7uJz61grRSw',
          name: 'SWTCH Energy Inc.',
        },
        {
          id: 5,
          personId: 'NHAwTS6YcUkFb4SYT_4k2w',
          name: 'Test1234',
        },
        {
          id: 6,
          personId: '7_SfFp5X0-kKx620vmnk5A',
          name: '123',
        },
      ],
      authorId: 'NHAwTS6YcUkFb4SYT_4k2w',
      stripeAccountId: 1,
      priceType: 'Hour',
      pricePerUnitCents: 3400,
      enableTieredPricing: false,
      preferredPricingPriceType: 'Hour',
      preferedPricePerUnitCents: 65,
      enableLoadManagementPricing: false,
      commisionPercent: 0.0,
      flatFee: 0.0,
      guestFeeCents: 50,
      paymentLimitCents: 10000,
      disableLoitering: true,
      disableLoiteringForTenantMember: true,
      loiteringStatus: 'no_penalty',
      loiteringGracePeriod: 0,
      loiteringPenalty: 0.0,
      loiteringTimeLimit: 0,
      additionalServiceFee: 'off',
      enableTimeOfDayPricing: true,
      timeOfDayPriceWeekday: Array(24).fill(68.89),
      timeOfDayPriceWeekend: Array(24).fill(68.89),
    },
  },
  2: {
    id: '2',
    name: 'lan-fake-tenant',
    displayName: 'lan-fake-tenant-display-name',
    chargerCount: 15,
    accessCode: 'LALALA',
    accessPolicy: 'Private Individual',
    notes: 'lorem ipsum dolor',
    omOptin: true,
    taxRegistration: 'joint_elected',
    taxNumber: null,
    uniformPricing: true,
    allowUniformLocation: false,
    allowUniformAccessPolicy: false,
    displayListingsOnMap: false,
    uniformAccessPolicy: 'private',
    location: {
      address: '110 ossington ave',
      city: 'Toronto',
      country: 'CA',
      latitude: 43.6373,
      listingTimezone: 'America/Toronto',
      longitude: -79.4242,
      postalCode: 'M6J 0G1',
      province: 'ON',
    },
    publishToOcpi: true,
    eyedroIpAddress: '1209.2343.123',
    weeklySchedules: [
      {
        dayOfWeek: 0,
        endHour: '23:45:00',
        startHour: '00:00:00',
      },
      {
        dayOfWeek: 1,
        endHour: '23:45:00',
        startHour: '00:00:00',
      },
      {
        dayOfWeek: 2,
        endHour: '23:45:00',
        startHour: '00:00:00',
      },
      {
        dayOfWeek: 2,
        endHour: '23:45:00',
        startHour: '00:00:00',
      },
      {
        dayOfWeek: 3,
        endHour: '23:45:00',
        startHour: '00:00:00',
      },
      {
        dayOfWeek: 4,
        endHour: '23:45:00',
        startHour: '00:00:00',
      },
      {
        dayOfWeek: 5,
        endHour: '23:45:00',
        startHour: '00:00:00',
      },
      {
        dayOfWeek: 6,
        endHour: '23:45:00',
        startHour: '00:00:00',
      },
    ],
    tenantContacts: [
      {
        namePrimary: 'new updated firstname',
        title: 'new title',
        phoneNumber: 'new phonenumber',
        email: 'new email !!!!!!',
        typeOfContact: 'account',
        propertyManagementCompany: mockPropertyMgmtComps[1],
      },
      {
        namePrimary: 'new firstname',
        title: 'new title',
        phoneNumber: 'new phonenumber',
        email: 'new email !!!!!!',
        typeOfContact: 'owner',
      },
      {
        namePrimary: 'new updated firstname',
        title: 'new title',
        phoneNumber: 'new phonenumber',
        email: 'new email !!!!!!',
        typeOfContact: 'owner new',
      },
    ],
    tenantDeployment: {
      electricalService: 'electrical_service_aa rere !!!!',
      networkDevice: 'Cellular',
      notes: 'notes_bb rr',
      parkingStalls: ['11', '12', '13', '14', '15', '16'],
      loadManagementConfiguration: ['aa', 'bb', 'cc', 'dd0'],
      remoteDesktopIds: ['111', '211', '311', '1011', '222'],
    },
    allowLoadManagement: false,
    allowUniformServiceStatus: false,
    uniformServiceStatus: 'service_mode',
    tenantUniformPricing: {
      tenantListingAuthors: [
        {
          userId: 'NHAwTS6YcUkFb4SYT_4k2w',
          name: 'xwei tang',
        },
        {
          userId: '7_SfFp5X0-kKx620vmnk5A',
          name: 'weihang tang',
        },
        {
          userId: 'QlViWeENlQC7uJz61grRSw',
          name: 'Laura Bryson',
        },
        {
          userId: 'v7NpvrHi6nT225fFCs9t3A',
          name: 'Sam Arabastani',
        },
        {
          userId: 'Li4HGdIsPIsZFVC4L4G6MA',
          name: 'Laura Bryson',
        },
      ],
      tenantListingStripeAccounts: [
        {
          id: 1,
          personId: 'NHAwTS6YcUkFb4SYT_4k2w',
          name: 'primer',
        },
        {
          id: 2,
          personId: 'v7NpvrHi6nT225fFCs9t3A',
          name: 'Test123',
        },
        {
          id: 3,
          personId: 'v7NpvrHi6nT225fFCs9t3A',
          name: 'Quadreal Property Group',
        },
        {
          id: 4,
          personId: 'QlViWeENlQC7uJz61grRSw',
          name: 'SWTCH Energy Inc.',
        },
        {
          id: 5,
          personId: '7_SfFp5X0-kKx620vmnk5A',
          name: 'Test123',
        },
      ],
      authorId: 'v7NpvrHi6nT225fFCs9t3A',
      stripeAccountId: 1,
      priceType: 'Hour',
      pricePerUnitCents: 3400,
      enableTieredPricing: false,
      preferredPricingPriceType: 'Hour',
      preferedPricePerUnitCents: 34,
      enableLoadManagementPricing: false,
      commisionPercent: 0.0,
      flatFee: 0.0,
      guestFeeCents: 50,
      paymentLimitCents: 10000,
      disableLoitering: false,
      disableLoiteringForTenantMember: false,
      loiteringStatus: 'no_penalty',
      loiteringGracePeriod: 0,
      loiteringPenalty: 0.0,
      loiteringTimeLimit: 0,
      additionalServiceFee: 'off',
      enableTimeOfDayPricing: true,
      timeOfDayPriceWeekday: Array(24).fill(68.89),
      timeOfDayPriceWeekend: Array(24).fill(68.89),
    },
  },
}

export const mockEnableLoadMgmtTenantReponse = {
  data: [
    {
      tenants: [{ tenant_id: '4', tenant_name: 'engineering' }],
    },
  ],
  code: 200,
  message: "Tenant's load management has been enabled",
} as EnableLoadMgmtTenantResponse

export const mockDisableLoadMgmtTenantResponse = {
  code: 200,
  message: "Tenant's load management has been disabled",
} as CodeMessageResponse

export const mockGetTenantsLoadMgmtResponse = {
  data: [
    {
      tenants: [
        {
          tenant_id: '25',
          tenant_name: 'Girardin-Bluebird-Dashboard',
        },
      ],
    },
  ],
  code: 200,
  message: 'Tenant data retrieved successfully',
} as EnableLoadMgmtTenantResponse

export const mockTenantContact = [
  {
    firstName: 'Lan',
    lastName: 'Phan',
    title: 'Mr',
    phoneNumber: '1234567891',
    email: 'lanp@gmail.com',
    typeOfContact: 'account',
    propertyManagementCompany: mockPropertyMgmtComps[0],
    masterAccount: { id: 1, email: 'cor@gmail.com', name: 'Corporate 1' },
  },
  {
    firstName: 'Lan',
    lastName: 'Phan',
    title: 'Mr',
    phoneNumber: '1234567891',
    email: 'lanp@gmail.com',
    typeOfContact: 'onsite',
    masterAccount: { id: 2, email: 'cor@gmail.com', name: 'Corporate 2' },
  },
  {
    firstName: 'Lan',
    lastName: 'Phan',
    title: 'Mr',
    phoneNumber: '1234567891',
    email: 'lanp@gmail.com',
    typeOfContact: 'installer',
    installer: mockInstallers[1],
    masterAccount: { id: 3, email: 'cor@gmail.com', name: 'Corporate 3' },
  },
]
