import { log } from '../../logger'
import configProvider from '../../config'

import { apiClient } from './client'
import { RemittanceObject } from '../../models/remittance'
import { mockRemittanceObject } from '../../mock/remittance-mock'

export async function GetRemittance(): Promise<RemittanceObject[]> {
  const url = '/remittance_types'

  log('fetching remittance types', { url: url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockRemittanceObject)
  }
  return apiClient(url, {})
}

export async function CreateRemittance(name: string): Promise<RemittanceObject> {
  const url = '/remittance_types'
  log('create a remittance', { url: url })
  if (configProvider.config.mockEnabled) {
    const newRemittance = { id: mockRemittanceObject.length + 1, name }
    return Promise.resolve(newRemittance)
  }
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      remittance_type: {
        name,
      },
    }),
  })
}
