import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Divider, Form, FormInstance, InputNumber, Select, Switch } from 'antd'
import { useState } from 'react'

import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'
import { useListingCommissionFeeTranslation } from '../../hooks/translation/useListingCommissionFeeTrans'

interface props {
  form: FormInstance<any>
}
export const ListingCommissionFee: React.FC<props> = ({ form }) => {
  const [addCloudLoadManagement, setAddCloudLoadManagement] = useState(form.getFieldValue('addCloudLoadManagement'))
  const [enableLoadManagementAutoStop, setEnableLoadManagementAutoStop] = useState(
    form.getFieldValue('enableLoadManagementAutoStop'),
  )
  const [comissionFeeType, setComissionFeeType] = useState(form.getFieldValue('commisionPercent'))

  const {
    selectComissionFeeText,
    defineNewComissionTypeText,
    defineNewComissionFeeText,
    defineNewComissionTypeWarningText,
    flatFeeText,
    commissionFeeText,
    guestFeeCents,
    commissionPerecentText,
    enableLoadMgmtAutoStopText,
  } = useListingCommissionFeeTranslation()
  return (
    <>
      <Divider>{commissionFeeText}</Divider>
      <Form.Item name="commisionPercent" label={commissionPerecentText}>
        <Select
          placeholder={selectComissionFeeText}
          onChange={(comissionFeeType: string) => {
            setComissionFeeType(comissionFeeType)
            comissionFeeType === '2.9% + 30c'
              ? form.setFieldsValue({ additionalServiceFee: 'on' })
              : form.setFieldsValue({ additionalServiceFee: 'off' })
          }}
        >
          <Select.Option value="2.9% + 30c">2.9% + 30c</Select.Option>
          <Select.Option value="10%">10%</Select.Option>
          <Select.Option value="defineNew">{defineNewComissionTypeText}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="additionalServiceFee" hidden>
        <span />
      </Form.Item>
      {comissionFeeType === 'defineNew' && (
        <Form.Item
          name="customComissionFee"
          label={defineNewComissionFeeText}
          rules={[{ required: true, message: defineNewComissionTypeWarningText }]}
        >
          <InputNumber step="0.01" precision={2} min={0} max={100} />
        </Form.Item>
      )}
      <Form.Item name="flatFee" label={flatFeeText}>
        <InputNumber step="0.01" precision={2} />
      </Form.Item>
      <Form.Item name="guestFeeCents" label={guestFeeCents}>
        <InputNumber step="0.01" />
      </Form.Item>
      <Form.Item
        name="paymentLimitCents"
        label={renderFormatMessage('dashboard.createNewListing.comissionStep.paymentLimit.txt', 'Payment Limit ($)')}
      >
        <InputNumber step="0.01" />
      </Form.Item>
      <Form.Item
        name="addCloudLoadManagement"
        label={useFormatMessage(
          'dashboard.createNewListing.priceStep.allowClouldLoadManagement.txt',
          'Allow Cloud Load Management',
        )}
      >
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          style={{ float: 'left' }}
          onChange={() => {
            setAddCloudLoadManagement(!addCloudLoadManagement)
            form.setFieldsValue({ addCloudLoadManagement: !addCloudLoadManagement })
          }}
          checked={addCloudLoadManagement}
        />
      </Form.Item>

      <Form.Item name="enableLoadManagementAutoStop" label={enableLoadMgmtAutoStopText}>
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          style={{ float: 'left' }}
          onChange={() => {
            setEnableLoadManagementAutoStop(!enableLoadManagementAutoStop)
            form.setFieldsValue({ enableLoadManagementAutoStop: !enableLoadManagementAutoStop })
          }}
          checked={enableLoadManagementAutoStop}
        />
      </Form.Item>
    </>
  )
}
