import { useFormatMessage } from '../../helpers/intl'

export const useChargerTranslation = () => {
  const chargerText = useFormatMessage('dashboard.text.charger', 'Charger')
  const chargerStatusText = useFormatMessage('dashboard.chargerPage.chargerStatus.heading', 'Charger Status')
  const onlineText = useFormatMessage('dashboard.chargerPage.chargerStatus.online', 'Online')
  const offlineText = useFormatMessage('dashboard.chargerPage.chargerStatus.offline', 'Offline')

  return { chargerText, chargerStatusText, onlineText, offlineText }
}
