import configProvider from '../config'
import { BaseLocal } from './base'

export const English: BaseLocal = {
  /*************************** General ***************************/
  'dashboard.title': 'Dashboard',
  'dashboard.title.admindashboard': 'Admin Dashboard',
  'dashboard.title.supportdashboard': 'Support Dashboard',
  'dashboard.calendar.today': 'Today',
  'dashboard.calendar.yesterday': 'Yesterday',
  'dashboard.calendar.past7days': 'Past 7 days',
  'dashboard.calendar.weektodate': 'Week to date',
  'dashboard.calendar.monthtodate': 'Month to date',
  'dashboard.calendar.yeartodate': 'Year to date',
  'dashboard.calendar.lastweek': 'Last Week',
  'dashboard.calendar.lastmonth': 'Last Month',
  'dashboard.calendar.lastquarter': 'Last Quarter',
  'dashboard.calendar.customdate': 'Custom Date',
  'dashboard.calendar.thisWeek': 'This Week',
  'dashboard.calendar.thisMonth': 'This Month',
  'dashboard.calendar.thisYear': 'This Year',
  'dashboard.calendar.previousQuarter': 'Previous Quarter',
  'dashboard.error.somethingwentwrong': 'Something went wrong',
  'dashboard.text.active': 'Active',
  'dashboard.text.access': 'Access',
  'dashboard.text.country': 'Country',
  'dashboard.text.on': 'on',
  'dashboard.text.close': 'Close',
  'dashboard.selected': 'selected',
  'dashboard.button.delete': 'Delete',
  'dashboard.text.masterAccount': 'Master Account',
  'dashboard.text.channelPartner': 'Channel Partner',
  'dashboard.text.generated': 'Generated',
  'dashboard.text.hour': 'Hour',
  'dashboard.text.by': 'by',
  'dashboard.text.billingAddress': 'Billing Address',
  'dashboard.button.create': 'Create',
  'dashboard.button.city': 'City',
  'dashboard.text.currency': 'Currency',
  'dashboard.button.country': 'Country',
  'dashboard.text.description': 'Description',
  'dashboard.button.subscribe': 'Subscribe',
  'dashboard.button.cancel': 'Cancel',
  'dashboard.text.charger': 'Charger',
  'dashboard.text.search': 'Search',
  'dashboard.text.modify': 'Modify',
  'dashboard.text.refresh': 'Refresh',
  'dashboard.text.action': 'Action',
  'dashboard.text.details': 'Details',
  'dashbaord.text.inactive': 'Inactive',
  'dashbaord.text.interval': 'Interval',
  'dashbaord.text.installer': 'Installer',
  'dashboard.text.configure': 'Configure',
  'dashboard.text.listing': 'Listing',
  'dashboard.text.name': 'Name',
  'dashboard.text.note': 'Note',
  'dashboard.text.status': 'Status',
  'dashboard.text.online': 'Online',
  'dashboard.text.offline': 'Offline',
  'dashboard.text.panel': 'Panel',
  'dashboard.text.plans': 'Plans',
  'dashboard.text.plan': 'Plan',
  'dashboard.text.postalCodeZipCode': 'Postal Code/Zip Code',
  'dashboard.text.price': 'Price',
  'dashboard.text.province': 'Province',
  'dashboard.text.provinceState': 'Province/State',
  'dashboard.text.enable': 'Enable',
  'dashboard.text.enabled': 'Enabled',
  'dashboard.text.disabled': 'Disabled',
  'dashboard.text.cancel': 'Cancel',
  'dashboard.text.apply': 'Apply',
  'dashboard.text.cancelled': 'Cancelled',
  'dashboard.text.paused': 'Paused',
  'dashboard.text.save': 'Save',
  'dashboard.text.state': 'State',
  'dashboard.text.streetAddress': 'Stress Address',
  'dashboard.text.subscribers': 'Subscribers',
  'dashboard.text.subscription': 'subscription',
  'dashboard.text.subscriptions': 'Subscriptions',
  'dashboard.text.user': 'User',
  'dashboard.text.add': 'Add',
  'dashboard.text.edit': 'Edit',
  'dashboard.text.tenant': 'Tenant',
  'dashboard.text.vendor': 'Vendor',
  'dashboard.text.start': 'Start',
  'dashboard.text.stop': 'Stop',
  'dashboard.text.update': 'Update',
  'dashboard.text.error': 'Error',
  'dashboard.text.cost': 'Cost',
  'dashboard.text.location': 'Location',
  'dashboard.text.searchlocation': 'Search Location',
  'dashboard.text.map&access': 'Map & Access',
  'dashboard.text.loiteringStatuses': 'Loitering Statuses',
  'dashboard.text.pending': 'Pending',
  'dashboard.text.inactive': 'Inactive',
  'dashboard.text.all': 'All',
  'dashboard.text.weekday': 'Weekday',
  'dashboard.text.weekend': 'Weekend',
  'dashboard.text.level2': 'Level 2',
  'dashboard.text.noDataAvailable': 'Sorry, no data to display.',
  'dashboard.text.unknown': 'Unknown',
  'dashboard.text.switchHomePage': 'Switch to the classic dashboard view',
  'dashboard.text.switchUsersPage': 'Switch to the classic users page',
  'dashboard.text.switchHomePageNewVersion': 'Switch to the new dashboard view',
  'dashboard.text.switchUsersPageNewVersion': 'Switch to the new users page',

  /************************* Listing *************************/
  'dashboard.text.listingTitles': 'Listing Title (s)',
  'dashboard.text.generalPrice': 'General Price',
  'dashboard.text.preferredPrice': 'Preferred Price',
  'dashboard.text.smartCharging': 'Smart Charging',

  /************************* Loitering *************************/
  'dashboard.text.loitering': 'Loitering',

  /***************** Connector Service Status *****************/
  'dashboard.serviceStatus.awaitingCommissioning': 'Awaiting Commissioning',
  'dashboard.serviceStatus.decommissioned': 'Decommissioned',
  'dashboard.serviceStatus.commissioned': 'Commissioned',
  'dashboard.serviceStatus.testing': 'Testing',
  'dashboard.serviceStatus.activatingPendingProperty': 'Activating - Pending Property',
  'dashboard.serviceStatus.onHold': 'On Hold',
  'dashboard.serviceStatus.activatingPendingDriverSub': 'Activating - Pending Driver Subscription',
  'dashboard.serviceStatus.other': 'Other',
  'dashboard.serviceStatus.troubleshooting': 'Troubleshooting',
  'dashboard.serviceStatus.terminated': 'Terminated',

  /************************** Placeholder **************************/
  'dashboard.placeholder.masterAccount': 'Select a master account',
  'dashboard.placeholder.pleaseEnterPartner': 'Please enter partner',

  /*********************** General Messasge ***********************/
  'dashboard.message.positiveNumber': 'Please enter a positive number',
  'dashboard.placeholder.searchEmail': 'Search Email',
  'dashboard.placeholder.email': 'Email',

  /*************************** Navigation ***************************/
  'dashboard.navigation.dashboard': 'Dashboard',
  'dashboard.navigation.chargers': 'Chargers',
  'dashboard.navigation.transactions': 'Transactions',
  'dashboard.navigation.tenants': 'Tenants',
  'dashboard.navigation.listings': 'Listings',
  'dashboard.navigation.analytics': 'Analytics',
  'dashboard.navigation.reports': 'Reports',
  'dashboard.navigation.users': 'Users',

  /*********************** Pagination ***********************/
  'dashboard.pagination.previous': 'Previous',
  'dashboard.pagination.next': 'Next',

  /*************************** Header ***************************/
  'dashboard.header': 'Admin Dashboard',
  'dashboard.header.logout': 'Logout',
  'dashboard.header.chooseLanguage': 'Choose Language',

  /*************************** Footer ***************************/
  'dashboard.footer.poweredby': 'Powered By ',

  /************************ Export and Search Button *********************/
  'dashboard.exportbtn.heading': 'Export',
  'dashboard.exportbtn.title': 'Export Data',
  'dashboard.exportbtn.csv': 'CSV',
  'dashboard.exportbtn.pdf': 'PDF',
  'dashboard.exportbtn.info': 'Choose either to export the data via PDF or CSV. The file will be emailed to',
  'dashboard.searchBtn.title': 'Search',
  'dashboard.search.placeholder.user': 'Search user',

  /*************************** Selector ***************************/
  'dashboard.selector.searchTenant': 'Search Tenant',
  'dashboard.selector.Tenants': 'Tenant (s)',
  'dashboard.selector.searchTenants': 'Search Tenant(s)',
  'dashboard.selector.searchCharger': 'Search Charger',
  'dashboard.selector.searchListing': 'Search Listing',
  'dashboard.selector.serviceStatus': 'Service Status',
  'dashboard.selector.selectConnectorStatus': 'Connector Status',

  /*************************** Charger ***************************/
  'dashboard.charger.phases': 'Phases',
  'dashboard.charger.minAmp': 'Min. Cap',
  'dashboard.charger.maxAmp': 'Max. Cap',
  'dashboard.charger.fallback': 'Fallback',
  'dashboard.charger.circuitSize': 'Circuit Size',
  'dashboard.charger.existingLoad': 'Existing Load',
  'dashboard.charger.breakerSize': 'Breaker Size',
  'dashboard.charger.endPoint': 'Endpoint',
  'dashboard.charger.type.mixed': 'Mixed',
  'dashboard.charger.type.dedicated': 'Dedicated',
  'dashboard.charger.serviceStatus.active': 'Active',
  'dashboard.charger.serviceStatus.commissioning': 'Commissioning',
  'dashboard.charger.serviceStatus.activating': 'Activating',
  'dashboard.charger.serviceStatus.underRepair': 'Under Repair',
  'dashboard.charger.serialNumber': 'Serial Number',
  'dashboard.charger.vendor': 'Vendor',
  'dashboard.charger.model': 'Model',

  /*************************** Connector ***************************/
  'dashboard.connector.connectorId': 'Connector ID',
  'dashboard.connector.lastStatusChangedAt': 'Last status changed at',

  /*************************** Tenant Selector Page ***************************/
  'dashboard.tenantselectorpage.pagereload': 'Loading your profile',
  'dashboard.tenantselectorpage.sessionexpired': 'Session Expired',
  'dashboard.tenantselectorpage.redirect': 'Redirecting back to the login page!',
  'dashboard.tenantselectorpage.title': 'Oops something went wrong',
  'dashboard.tenantselectorpage.subtitle': 'Try again.',
  'dashboard.tenantselectorpage.backtologin': 'Back to login',
  'dashboard.tenantselectorpage.tenantpermission.title': 'You do not have access to a Tenant',
  'dashboard.tenantselectorpage.tenantpermission.subtitle': 'Please contact an administrator',

  /*************************** Auth0 Login Page ***************************/
  'dashboard.authpage.title': 'Oops something went wrong',
  'dashboard.authpage.subtitle': 'We were unable to log you in. Try again',
  'dashboard.authpage.backtologin': 'Back to login',
  'dashboard.authpage.error,invalidcallbackparameters': 'Invalid callback parameters',

  /*************************** Home Page ***************************/
  'dashboard.homePage.home': 'Home',
  'dashboard.homePage.selectTenant': 'Select a tenant',
  'dashboard.homePage.search': 'Search',
  'dashboard.homePage.revenue': 'Revenue',
  'dashboard.homePage.transaction': 'Transaction',
  'dashboard.homePage.transactions': 'Transactions',
  'dashboard.homePage.transactions.info.p1': 'of transactions were started after 6 PM',
  'dashboard.homePage.transactions.info.p2': 'of transactions were started on weekday',
  'dashboard.homePage.demandResponseText': 'from demand response',
  'dashboard.homePage.chargingText': 'Charging',
  'dashboard.homePage.fromChargingText': 'from charging',
  'dashboard.homePage.loiteringText': 'from loitering',
  'dashboard.homePage.energy': 'Energy',
  'dashboard.homePage.energy.info.p1': 'of charging occured during peak hours',
  'dashboard.homePage.energyConsumptions': 'Energy Consumption (kWh)',
  'dashboard.homePage.energyConsumptions.info.header': 'Greenhouse Gases Info',
  'dashboard.homePage.energyConsumptions.info.p1': 'How are my greenhouse gas savings computed?',
  'dashboard.homePage.energyConsumptions.info.p2':
    'ICE fuel economy in 2017 based on Canada’s average vehicle: 8.9 L of Gasoline/100 km',
  'dashboard.homePage.energyConsumptions.info.p3':
    'CO2 eq emissions per litre of gasoline for ICE: 2.3 kg of CO2 eq/L of Gasoline',
  'dashboard.homePage.energyConsumptions.info.p4':
    'EV fuel economy based on Canada’s 2020 battery electric vehicle fleet: 19.51 kWh/100 km',
  'dashboard.homePage.energyConsumptions.info.p5': 'CO2 eq emissions per kWh in Canada: 318.9 g CO2 eq/kWh',
  'dashboard.homePage.energyConsumptions.info.p6': 'In Canada',
  'dashboard.homePage.energyConsumptions.info.okbtn': 'Ok',
  'dashboard.homePage.ghgsavings': 'GHG Savings (kg C02 eq)',
  'dashboard.homePage.ghgsavings.text': 'GHG Savings',
  'dashboard.homePage.totalConnectors': 'Total Connectors',
  'dashboard.homePage.inuse': 'In Use',
  'dashboard.homePage.available': 'Available',
  'dashboard.homePage.unavailable': 'Unavailable',
  'dashboard.homePage.commissioning': 'Commissioning',
  'dashboard.homePage.totalActive': 'Total Active Connectors',
  'dashboard.homePage.activating': 'Activating',
  'dashboard.homePage.underRepair': 'Under Repair',
  'dashboard.homePage.info.totalActive': 'These are all connectors from the Available, In Use, and Unavailable blocks',
  'dashboard.homePage.info.totalActivating':
    'Connectors where service status is set to Commissioned, Activating - Pending Property, or Activating - Pending Driver Subscription',
  'dashboard.homePage.info.totalCommissioning':
    'Connectors where service status is set to On Hold, Awaiting Commissioning, or Troubleshooting',
  'dashboard.homePage.stationoverview.heading': 'Station Overview',
  'dashboard.homePage.stationoverview.activeUsers': 'Active Users',
  'dashboard.homePage.stationoverview.activeUsers.idle': 'Idle',
  'dashboard.homePage.stationOverview.activeUsers.idle.info':
    'The transaction is ongoing, but the vehicle is not actively charging.',
  'dashboard.homePage.stationoverview.connectorsPluggedIn': 'Connectors Plugged In',
  'dashboard.homePage.stationoverview.connectorsPluggedIn.level2connectors': 'Level 2 Connectors',
  'dashboard.homePage.stationoverview.connectorsPluggedIn.dcfastconnectors': 'DC Fast Connectors',
  'dashboard.homePage.stationoverview.chargersParcipatingDREvents.heading': 'Chargers Enrolled in Demand Response',
  'dashboard.homePage.stationoverview.chargersParcipatingDREvents.level2connectors': 'Level 2 Connectors',
  'dashboard.homePage.stationoverview.chargersParcipatingDREvents.dcfastconnectors': 'DC Fast Connectors',
  'dashboard.homePage.stationoverview.connectorAvailability': 'Connector Availability',
  'dashboard.homePage.stationoverview.connectorAvailability.level2connectors': 'Level 2 Connectors',
  'dashboard.homePage.stationoverview.connectorAvailability.dcfastconnectors': 'DC Fast Connectors',
  'dashboard.homePage.operationoverview.heading': 'Operation Overview',
  'dashboard.homePage.operationoverview.timeZone.heading': 'Time (EST)',
  'dashboard.homePage.utilizationInsights.heading': 'Utilization Insights',
  'dashboard.homePage.utilizationInsights.numberOfConnectorsPluggedIn': '# of Connectors Plugged In',
  'dashboard.homePage.consumptionInsights.heading': 'Consumption Insights',
  'dashboard.homePage.consumptionInsights.uniqueUsers.heading': 'Unique users',
  'dashboard.homePage.consumptionInsights.avgNumberOfTransactions.heading': 'Average number of transactions',
  'dashboard.homePage.consumptionInsights.avgNumberOfTransactions.day': 'day',
  'dashboard.homePage.consumptionInsights.averageConsumption.heading': 'Average energy consumption',
  'dashboard.homePage.consumptionInsights.averageConsumption.averageUserSpend': 'Average transaction amount',
  'dashboard.homePage.utilizationInsights.dial.chargingDurationText': 'Charging Duration',
  'dashboard.homePage.utilizationInsights.dial.plugInDurationText': 'Plug-in-duration',
  'dashboard.homePage.consumptionInsights.chart.title': 'Average Daily Load Profile',
  'dashboard.homePage.stationActivation.heading': 'Station Activation',
  'dashboard.homePage.transactionOverview.heading': 'Transaction Overview',
  'dashboard.homePage.seeAllTransactions.heading': 'See All Transactions',
  'dashboard.homePage.stationoverview.stations.heading': 'Stations',
  'dashboard.homePage.stationoverview.current.heading': 'Current',
  'dashboard.homePage.stationoverview.stations.onlinechargers.text': 'Online Connnectors',
  'dashboard.homePage.stationoverview.stations.offlinechargers.text': 'Offline Connectors',
  'dashboard.homePage.stationoverview.stations.underrepairchargers.text': 'Under Repair',
  'dashboard.homePage.stationoverview.stations.onlinechargers.description':
    'Functional and connection established allowing for remote monitoring and management',
  'dashboard.homePage.stationoverview.stations.offlinechargers.description':
    'Unable to establish connection and may require further troubleshooting or escalation',
  'dashboard.homePage.stationoverview.stations.underrepairchargers.description':
    'Currently out of service due to maintenance to restore functionality',
  'dashboard.homePage.stationoverview.current.description1': 'Active Charger Current',
  'dashboard.homePage.stationoverview.current.description2': 'Maximum Charger Current',
  'dashboard.homePage.stationActivation.webapp': 'Web App',
  'dashboard.homePage.stationActivation.roamingpartner': 'Roaming Partner',
  'dashboard.homePage.stationActivation.mobileapp': 'Mobile App',
  'dashboard.homePage.exportGraph.pdf': 'Export as PDF',
  'dashboard.homePage.exportGraph.csv': 'Export as CSV',

  /*************************** Chargers Page ***************************/
  'dashboard.chargersPage.heading': 'Chargers',
  'dashboard.chargersPage.connector.heading': 'Connector',
  'dashboard.chargersPage.searchTenant': 'Search Tenant',
  'dashboard.chargersPage.selectVendor': 'Select Vendor',
  'dashboard.chargersPage.selectStatus': 'Select Status',
  'dashboard.chargersPage.table.heading.connectorId': 'Connector ID',

  'dashboard.chargersPage.table.heading.tenant': 'Tenant',
  'dashboard.chargersPage.table.heading.serviceStatus': 'Service Status',
  'dashboard.chargersPage.table.heading.status': 'Connector Status',
  'dashboard.chargersPage.modal.updatechargers': 'Update Chargers',
  'dashboard.chargersPage.modal.updatechargers.selected': 'selected',
  'dashboard.chargersPage.modal.firmware': 'Firmware',
  'dashboard.chargersPage.modal.firmware.location': 'Location',
  'dashboard.chargersPage.modal.firmware.location.warning': 'Please input a location!',
  'dashboard.chargersPage.modal.firmware.location.help':
    'Date after which the charger must retrieve the (new) firmware',
  'dashboard.chargersPage.modal.firmware.retrieveDate': 'Retrieve date',
  'dashboard.chargersPage.modal.firmware.retrieveDate.warning': 'Please retrieve a date!',
  'dashboard.chargersPage.modal.firmware.updatefirmware': 'Update Firmware',
  'dashboard.chargersPage.modal.configuration': 'Configuration',
  'dashboard.chargersPage.modal.configuration.key': 'Key',
  'dashboard.chargersPage.modal.configuration.key.info': 'Key of the value you want to change',
  'dashboard.chargersPage.modal.configuration.key.warning': 'Please input a key!',
  'dashboard.chargersPage.modal.configuration.value': 'Value',
  'dashboard.chargersPage.modal.configuration.value.info': 'Value to change',
  'dashboard.chargersPage.modal.configuration.updateConfiguration': 'Update Configuration',
  'dashboard.chargersPage.modal.configuration.updatefirmwarechanges': 'Sending firmware update for',
  'dashboard.chargersPage.modal.configuration.trigger.heading':
    'Select a message below for selected chargers to initiate a trigger message',
  'dashboard.chargersPage.modal.configuration.trigger.message': "Sending Trigger Message '123' to selected chargers",

  /*************************** Charger Page ***************************/
  'dashboard.chargerPage.allConnectors': 'All Connectors',
  'dashboard.chargerPage.noListing': 'No Listing',
  'dashboard.chargerPage.chargerStatus.heading': 'Charger Status',
  'dashboard.chargerPage.chargerStatus.energyConsumption': 'Energy Consumption (kWh)',
  'dashboard.chargerPage.chargerStatus.revenue': 'Revenue ($)',
  'dashboard.chargerPage.graph.heading': 'Revenue, Transactions, and Energy Consumption',
  'dashboard.chargerPage.connectorStatus.heading': 'Connector Status',
  'dashboard.chargerPage.connectorStatus.disconnected': 'Disconnected',
  'dashboard.chargerPage.connectorStatus.disconnected.message': 'About -1 at -2',
  'dashboard.chargerPage.chargerStatus.offline': 'Offline',
  'dashboard.chargerPage.chargerStatus.online': 'Online',
  'dashboard.chargerPage.chargerStatus.available': 'Available',
  'dashboard.chargerPage.chargerStatus.charging': 'Charging',
  'dashboard.chargerPage.chargerStatus.commissioning': 'Commissioning',
  'dashboard.chargerPage.chargerStatus.commissioned': 'Commissioned',
  'dashboard.chargerPage.chargerStatus.suspendedEV': 'SuspendedEV',
  'dashboard.chargerPage.chargerStatus.preparing': 'Preparing',
  'dashboard.chargerPage.chargerStatus.suspendedEVSE': 'SuspendedEVSE',
  'dashboard.chargerPage.chargerStatus.faulted': 'Faulted',
  'dashboard.chargerPage.chargerStatus.unavailable': 'Unavailable',
  'dashboard.chargerPage.chargerStatus.reserved': 'Reserved',
  'dashboard.chargerPage.chargerStatus.finishing': 'Finishing',
  'dashboard.chargerPage.chargerStatus.unknown': 'Unknown',
  'dashboard.chargerPage.tab.detail': 'Details',
  'dashboard.chargerPage.tab.detail.title': 'Title',
  'dashboard.chargerPage.tab.detail.connectorid': 'Connector ID',
  'dashboard.chargerPage.tab.detail.status': 'Status',
  'dashboard.chargerPage.tab.detail.tenant': 'Tenant',
  'dashboard.chargerPage.tab.detail.vendor': 'Vendor',
  'dashboard.chargerPage.tab.detail.firmwareVersion': 'Firmware Version',
  'dashboard.chargerPage.diagnosticsTab.heading': 'Diagnostics',
  'dashboard.chargerPage.diagnostics.files.requestTime.heading': 'Request Time',
  'dashboard.chargerPage.diagnostics.files.filename.heading': 'Filename',
  'dashboard.chargerPage.diagnostics.files.state.heading': 'State',
  'dashboard.chargerPage.diagnostics.button.text': 'Request Diagnostics',
  'dashboard.chargerPage.diagnostics.kickButton.text': 'Kick Charger',
  'dashboard.chargerPage.remoteControlTab.heading': 'Remote Control',
  'dashboard.chargerPage.remoteControlTab.description': 'Select a connector to proceed',
  'dashboard.chargerPage.remoteControlTab.restartChargeBox.heading': 'Restart charge box',
  'dashboard.chargerPage.remoteControlTab.restartChargeBox.p1': 'Click below to restart the',
  'dashboard.chargerPage.remoteControlTab.restartChargeBox.restartbtn': 'Restart',
  'dashboard.chargerPage.remoteControlTab.restartChargeBox.chargerStarted': 'Charger 1234 has been restarted',
  'dashboard.chargerPage.remoteControlTab.restartChargeBox.chargerFailedToStart': 'Charger 1234 failed to restart',
  'dashboard.chargerPage.remoteControlTab.restartChargeBox.hardResetRequest': 'Sending Hard Reset request for',
  'dashboard.chargerPage.remoteControlTab.clearCache.heading': 'Clear Cache',
  'dashboard.chargerPage.remoteControlTab.clearCache.title': "Click below to clear the charger's cache",
  'dashboard.chargerPage.remoteControlTab.clearCache.btn.heading': 'Clear 1234 Cache',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.errormessage': 'Select a message type to send',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.message':
    'Sending message 0000 to connector -1 for charger 1234',
  'dashboard.chargerPage.remoteControlTab.clearCache.request': 'Sending clear cache request to',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.heading': 'Trigger Message',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.text':
    'Select a message below for 1234 - connector -1 to initiate a trigger message',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.trigger': 'Trigger',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.success':
    '1234 has been sent to 0000 - connector -1 for charger 1111',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.error.message.accepted': 'Accepted',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.error.message.rejected': 'Rejected',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.error':
    '1234 has failed to send to connector -1 for charger 1111',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.error.message': 'Rejected',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.placeholder': 'Select a message type',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.bootNotification': 'Boot Notification',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.diagnosticsStatusNotification':
    'Diagnostics Status Notification',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.firmwareStatusNotification':
    'Firmware Status Notification',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.heartbeat': 'Heart Beat',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.meterValue': 'Meter Value',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.statusNotification': 'Status Notification',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.btn.sendTriggerMessage': 'Send Trigger Message',
  'dashboard.chargerPage.remoteControlTab.updateFirmware.heading': 'Update Firmware',
  'dashboard.chargerPage.remoteControlTab.changeConfiguration.heading': 'Change Configuration',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.heading': 'Get Configuration',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.text': 'Click below to get the charger configuration',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.getchargerconfiguration': "Get 1234's Configuration",
  'dashboard.chargerPage.remoteControlTab.getConfiguration.sendchargerconfiguration':
    'Sending get configuration request to',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.key': 'Key',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.readonly': 'Read Only',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.value': 'Value',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.table.callresult': 'Call Result',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.table.callid': 'Call ID',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.modal.heading': "1234's Configuration",
  'dashboard.chargerPage.remoteControlTab.getConfiguration.modal.configurationRetrieved':
    'Charger 1234 configuration retrieved',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.modal.configurationDidNotRetrieve':
    'Failed to retrieve Charger 1234 configuration',
  'dashboard.chargerPage.DREventTab.heading': 'Demand Response',
  'dashboard.chargerPage.logs.chargerLogs.heading': 'Charger Logs',
  'dashboard.chargerPage.logs.timestamp.heading': 'Timestamp',
  'dashboard.chargerPage.logs.heading': 'Log',
  'dashboard.chargerPage.logs.errorCode.heading': 'Error Code',
  'dashboard.chargerPage.logs.vendorErrorCode.heading': 'Vendor Error Code',
  'dashboard.chargerPage.listing.heading': 'Listing',
  'dashboard.chargerPage.listing.listingTitle.heading': 'Listing Title',
  'dashboard.chargerPage.listing.connectorid.heading': 'Connector ID',
  'dashboard.chargerPage.listing.price.heading': 'Price',
  'dashboard.chargerPage.listing.pricetype.heading': 'Price Type',
  'dashboard.chargerPage.listing.action.heading': 'Action',
  'dashboard.chargerPage.listing.action.modifybtn': 'Modify',
  'dashboard.chargerPage.listing.action.saveBtn': 'Save',
  'dashboard.chargerPage.listing.action.cancelBtn': 'Cancel',

  /*************************** Transactions Page ***************************/
  'dashboard.transactionsPage.heading': 'Transactions',
  'dashboard.transactionsPage.exportbtn': 'Export',
  'dashboard.transactionsPage.advancedSearch': 'Advanced Search',
  'dashboard.transactionsPage.advancedSearchHide': 'Hide Advanced Search',
  'dashboard.transactionsPage.advancedSearch.transactionId': 'Transaction ID',
  'dashboard.transactionsPage.advancedSearch.username': 'User Name',
  'dashboard.transactionsPage.advancedSearch.useremail': 'User Email',
  'dashboard.transactionsPage.advancedSearch.useremail.warning': 'The input is not valid E-mail!',
  'dashboard.transactionsPage.advancedSearch.ocppTransactionId': 'OCPP Transaction ID',
  'dashboard.transactionsPage.advancedSearch.startMethod': 'Start Method',
  'dashboard.transactionsPage.advancedSearch.state': 'State',
  'dashboard.transactionsPage.advancedSearch.state.cancelled': 'cancelled',
  'dashboard.transactionsPage.advancedSearch.state.confirmed': 'confirmed',
  'dashboard.transactionsPage.advancedSearch.state.errored': 'errored',
  'dashboard.transactionsPage.advancedSearch.state.free': 'free',
  'dashboard.transactionsPage.advancedSearch.state.no_reservation': 'no_reservation',
  'dashboard.transactionsPage.advancedSearch.state.paid': 'paid',
  'dashboard.transactionsPage.advancedSearch.state.preauthorized': 'preauthorized',
  'dashboard.transactionsPage.advancedSearch.state.checked_in': 'checked_in',
  'dashboard.transactionsPage.advancedSearch.state.pending_payment': 'pending_payment',
  'dashboard.transactionsPage.advancedSearch.startMethod.rfid': 'rfid',
  'dashboard.transactionsPage.advancedSearch.startMethod.mobile': 'mobile',
  'dashboard.transactionsPage.advancedSearch.startMethod.website': 'website',
  'dashboard.transactionsPage.advancedSearch.startMethod.location': 'Location',
  'dashboard.transactionsPage.totalenergy': 'Total Energy (kWh)',
  'dashboard.transactionsPage.totalnetenergy': 'Total Net Revenue',
  'dashboard.transactionsPage.totalpluginduration': 'Total Plug-In Duration',
  'dashboard.transactionsPage.table.transactionId': 'Transaction ID',
  'dashboard.transactionsPage.table.startMethod': 'Start Method',
  'dashboard.transactionsPage.table.user': 'User',
  'dashboard.transactionsPage.table.userName': 'User Name',
  'dashboard.transactionsPage.table.state': 'State',
  'dashboard.transactionsPage.table.state.errored': 'Errored',
  'dashboard.transactionsPage.table.state.pendingpayment': 'Pending Payment',
  'dashboard.transactionsPage.table.state.free': 'Free',
  'dashboard.transactionsPage.table.state.confirmed': 'Confirmed',
  'dashboard.transactionsPage.table.state.cancelled': 'Cancelled',
  'dashboard.transactionsPage.table.state.no_reservation': 'no_reservation',
  'dashboard.transactionsPage.table.state.paid': 'Paid',
  'dashboard.transactionsPage.table.state.preauthorized': 'preauthorized',
  'dashboard.transactionsPage.table.state.checkedin': 'Checked In',
  'dashboard.transactionsPage.table.connectedAt': 'Connected At',
  'dashboard.transactionsPage.table.connected': 'Connected',
  'dashboard.transactionsPage.table.disconnected': 'Disconnected',
  'dashboard.transactionsPage.table.disconnectedAt': 'Disconnected At',
  'dashboard.transactionsPage.table.plugInDuration': 'Plug-in Duration',
  'dashboard.transactionsPage.table.energy': 'Energy (kWh)',
  'dashboard.transactionsPage.table.total': 'Total',
  'dashboard.transactionsPage.table.totalEnergy': 'Total Energy',
  'dashboard.transactionsPage.table.action': 'Action',
  'dashboard.transactionsPage.table.nestedTable.heading.connectorId': 'Connector ID',
  'dashboard.transactionsPage.table.nestedTable.heading.battery': 'Battery Percent (%)',
  'dashboard.transactionsPage.table.nestedTable.heading.chargedAt': 'Charged At',
  'dashboard.transactionsPage.table.nestedTable.heading.endChargedAt': 'End Charging At',
  'dashboard.transactionsPage.table.nestedTable.heading.chargingDuration': 'Charging Duration',
  'dashboard.transactionsPage.table.nestedTable.heading.subtotal': 'Sub-total',
  'dashboard.transactionsPage.table.nestedTable.heading.tax': 'Tax',
  'dashboard.transactionsPage.table.nestedTable.heading.seller': 'Seller',
  'dashboard.transactionsPage.table.nestedTable.heading.chargingStats': 'Charging Stats',
  'dashboard.transactionsPage.statsView.totalTransactions': 'Total Transactions',
  'dashboard.transactionsPage.statsView.totalEnergy': 'Total Energy (kWh)',
  'dashboard.transactionsPage.statsView.totalNetRevenue': 'Total Net Revenue',
  'dashboard.transactionsPage.statsView.totalPlugInDuration': 'Total Plug-In Duration',
  'dashboard.transactionsPage.export.message':
    'Choose either to export the data via PDF or CSV. The file will be emailed to',

  /*************************** Transaction Page ***************************/
  'dashboard.transactionPage.transactionId': 'Transaction ID',
  'dashboard.transactionPage.tenant': 'Tenant',
  'dashboard.transactionPage.startMethod': 'Start Method',
  'dashboard.transactionPage.user': 'User',
  'dashboard.transactionPage.batteryLevel': 'Battery Level (%)',
  'dashboard.transactionPage.connectedAt': 'Connected At',
  'dashboard.transactionPage.disconnectedAt': 'Disconnected At',
  'dashboard.transactionPage.pluginDuraction': 'Plug-in Duration',
  'dashboard.transactionPage.energy': 'Energy (kWh)',
  'dashboard.transactionPage.total': 'Total',
  'dashboard.transactionPage.stop': 'Stop',
  'dashboard.transactionPage.start': 'Start',
  'dashboard.transactionPage.error': 'Error',
  'dashboard.transactionPage.action': 'Action',
  'dashboard.transactionPage.listing': 'Listing',
  'dashboard.transactionPage.connectorId': 'Connector ID',
  'dashboard.transactionPage.chargedAt': 'Charged At',
  'dashboard.transactionPage.endChargingAt': 'End Charging At',
  'dashboard.transactionPage.chargingDuration': 'Charging Duration',
  'dashboard.transactionPage.subtotal': 'Sub-Total',
  'dashboard.transactionPage.tax': 'Tax',
  'dashboard.transactionPage.seller': 'Seller',
  'dashboard.transactionsPage.statsView.cancel': 'Cancel',
  'dashboard.transactionsPage.statsView.error': 'Error',
  'dashboard.transactionsPage.statsView.total': 'Total',

  /*************************** Transaction State Badge  ***************************/
  'dashboard.transaction.confirmed': 'Confirmed',
  'dashboard.transaction.free': 'Free',
  'dashboard.transaction.pending_payment': 'Pending Payment',
  'dashboard.transaction.canceled': 'Cancelled',
  'dashboard.transaction.errored': 'Errored',
  'dashboard.transaction.paid': 'Paid',
  'dashboard.transaction.preauthorized': 'Pre Authorized',
  'dashboard.transaction.noReservation': 'No Reservation',

  /*************************** Tenants Page ***************************/
  'dashboard.tenantsPage.heading': 'Tenants',
  'dashboard.tenantsPage.createNewTenantBtn': 'Create New Tenant',
  'dashboard.tenantsPage.searchTenantTxt': 'Search Tenant',
  'dashboard.tenantsPage.table.heading.name': 'Name',
  'dashboard.tenantsPage.table.heading.chargerCount': 'Charger Count',

  /******************* Individual Tenant Page ***************************/
  'dashboard.tenantPage.detailTab.heading': 'Detail',
  'dashboard.tenantPage.detailTab.channelPartner': 'Channel Partner',
  'dashboard.tenantPage.overviewTab.uniformServiceStatuses': 'Uniform Service Statuses',
  'dashboard.tenantPage.overviewTab.heading': 'Overview',
  'dashboard.tenantPage.chargersTab.heading': 'Chargers',
  'dashboard.tenantPage.detailTab.accessCode.warning': 'Please enter a 6-digit access code',
  'dashboard.tenantPage.detailTab.ocppName.heading': 'OCPP Name',
  'dashboard.tenantPage.detailTab.ocppName.warning': 'Please enter a OCPP Name',
  'dashboard.tenantPage.detailTab.address.warning': 'Please enter an Address',
  'dashboard.tenantPage.detailTab.city.warning': 'Please enter City',
  'dashboard.tenantPage.detailTab.displayName.heading': 'Display Name',
  'dashboard.tenantPage.detailTab.address.heading': 'Address',
  'dashboard.tenantPage.detailTab.city.heading': 'City',
  'dashboard.tenantPage.detailTab.postalCode.heading': 'Postal Code',
  'dashboard.tenantPage.detailTab.postalCode.warning':
    "That's a bit long isn't it? Please limit yourself to 10 characters here.",
  'dashboard.tenantPage.detailTab.countryAndProvince.heading': 'Country and Province',
  'dashboard.tenantPage.detailTab.timezone': 'Time Zone',
  'dashboard.tenantPage.detailTab.location': 'Location',
  'dashboard.tenantPage.detailTab.open247': 'Open 24/7',
  'dashboard.tenantPage.detailTab.ocpiSharing': 'OCPI Sharing',
  'dashboard.tenantPage.detailTab.saveBtn': 'Save',
  'dashboard.tenantPage.detailTab.resetbtn': 'Reset',
  'dashboard.tenantPage.detailTab.ocppNameAlreadyExists.warning': 'OCPP Name already exists',
  'dashboard.tenantPage.unSavedChanges.warning':
    'There are unsaved changes to the tenant. Are you sure you want to leave?',
  'dashboard.tenantPage.postalCode.19103.info': 'Tax rate of 8% will apply to chargers in this zip code',
  'dashboard.tenantPage.overviewTab.heading.accessPolicy': 'Access Policy',
  'dashboard.tenantPage.overviewTab.heading.billingPlan': 'Billing Plan',
  'dashboard.tenantPage.contactInfo.ownerContact': 'Owner Contact',
  'dashboard.tenantPage.contactInfo.ownerContact.info':
    'If charger is privately owned by a condo or strata resident, indicate the owner’s contact information here',
  'dashboard.tenantPage.contactInfo.accountContact': 'Account Contact',
  'dashboard.tenantPage.contactInfo.onsiteContact': 'On-Site Contact',
  'dashboard.tenantPage.contactInfo.installerContact': 'Installer Contact',
  'dashboard.tenantPage.contactInfo.additionalContacts': 'Additional Contacts',
  'dashboard.tenantPage.contactInfo.add-additionalContacts': 'Add Additional Contacts',
  'dashboard.tenantPage.contactInfo.location.err': 'Please update tenants Location',
  'dashboard.tenantPage.contactInfo.firstName': 'First Name',
  'dashboard.tenantPage.contactInfo.lastName': 'Last Name',
  'dashboard.tenantPage.contactInfo.title': 'Job Title',
  'dashboard.tenantPage.contactInfo.modal.title': 'Add Additional Contact',
  'dashboard.tenantPage.contactInfo.modal.addbtn': 'Add',
  'dashboard.tenantPage.contactInfo.phoneNumber': 'Phone Number',
  'dashboard.tenantPage.contactInfo.email': 'Email',
  'dashboard.tenantPage.contactInfo.typeofcontact': 'Type Of Contact',
  'dashboard.tenantPage.contactInfo.validemail.warning': 'Enter valid E-mail!',
  'dashboard.tenantPage.contactInfo.typeofcontactalreadyexixts.warning': 'Type of contact already exists',
  'dashboard.tenantPage.contactInfo.typeofcontact.warning': 'Please enter type of contact',
  'dashboard.tenantPage.contactInfo.propertymanagementcompany': 'Property Management Company',
  'dashboard.tenantPage.contactInfo.contactUpdated': 'Contact Updated',
  'dashboard.tenantPage.contactInfo.contactCreated': 'Contact Created',
  'dashboard.tenantPage.deployment.parkingstalls.heading': 'Parking Stalls',
  'dashboard.tenantPage.deployment.parkingstall.heading': 'Parking Stall',
  'dashboard.tenantPage.deployment.loadmanagementconfiguration.heading': 'Load Management Configuration',
  'dashboard.tenantPage.deployment.remotedesktopids.heading': 'Remote Desktop IDs',
  'dashboard.tenantPage.deployment.electricalservice.heading': 'Electrical Service',
  'dashboard.tenantPage.deployment.networkdevice.heading': 'Network Device',
  'dashboard.tenantPage.deployment.notes.heading': 'Notes',
  'dashboard.tenantPage.deployment.btn.save': 'Save',
  'dashboard.tenantPage.deployment.btn.modify': 'Modify',
  'dashboard.tenantPage.deployment.btn.addfield': 'Add Field',
  'dashboard.tenantPage.deployment.btn.cancel': 'Cancel',
  'dashboard.tenantPage.deployment.successmsg': 'Deployment Information Updated',
  'dashboard.tenantPage.deployment.modal.addnewparkingstall.heading': 'Add New Parking Stall Field',
  'dashboard.tenantPage.deployment.modal.loadmanagementconfiguration.heading':
    'Add New Load Management Configuration Field',
  'dashboard.tenantPage.deployment.modal.remotedesktopids.heading': 'Add New Remote Desktop ID',
  'dashboard.tenantPage.deployment.field.warning': 'Field cannot be blank!',
  'dashboard.tenantPage.overviewTab.heading.userType': 'User Type',
  'dashboard.tenantPage.overviewTab.heading.networkinformation': 'Network Information',
  'dashboard.tenantPage.overviewTab.heading.circuitSharingInformation': 'Circuit Sharing Information',
  'dashboard.tenantPage.overviewTab.saveBtn': 'Save',
  'dashboard.tenantPage.overviewTab.modifybtn': 'Modify',
  'dashboard.tenantPage.overviewTab.cancelBtn': 'Cancel',
  'dashboard.tenantPage.overviewTab.tenantUpdatedMessage': '-1 has been updated!',
  'dashboard.tenantPage.overviewTab.status.heading': 'Status',
  'dashboard.tenantPage.overviewTab.status.inprogress': 'In Progress',
  'dashboard.tenantPage.overviewTab.status.commissioned': 'Commissioned',
  'dashboard.tenantPage.overviewTab.status.active': 'Active',
  'dashboard.tenantPage.overviewTab.status.inmaintenance': 'In Maintenance',
  'dashboard.tenantPage.overviewTab.status.nonNetworked': 'Non-Networked',
  'dashboard.tenantPage.overviewTab.status.testerInternal': 'Tester – Internal',
  'dashboard.tenantPage.overviewTab.status.testerExternal': 'Tester – External',
  'dashboard.tenantPage.overviewTab.status.decommissioned': 'Decommissioned',
  'dashboard.tenantPage.overviewTab.o_m_opt_in.heading': 'O&M Opt-in',
  'dashboard.tenantPage.overviewTab.o_m_opt_in.yes': 'Yes',
  'dashboard.tenantPage.overviewTab.o_m_opt_in.no': 'No',
  'dashboard.tenantPage.overviewTab.chargerServiceStatus': 'Charger Service Status',
  'dashboard.tenantPage.overviewTab.chargerServiceStatus.help':
    "IMPORTANT: Selecting one of the values will update all chargers' service status associated with this tenant",
  'dashboard.tenantPage.overviewTab.connectorServiceStatus': 'Connector Service Status',
  'dashboard.tenantPage.locationTab.uniformupdate.help':
    'IMPORTANT: Checking this checkbox will hide all listings associated with this tenant from the map',
  'dashboard.tenantPage.location.heading': 'Location',
  'dashboard.tenantPage.accesscontrolsetting.heading': 'Access Control Setting',
  'dashboard.tenantPage.accesscontrolsetting.restricted': 'Restricted',
  'dashboard.tenantPage.accesscontrolsetting.openaccess': 'Open Access',
  'dashboard.tenantPage.accesscode.heading': 'Access Code',
  'dashboard.tenantPage.accesspolicy.heading': 'Access Policy',
  'dashboard.tenantPage.accesscode.option.public': 'Public',
  'dashboard.tenantPage.accesscode.option.privateindividual': 'Private Individual',
  'dashboard.tenantPage.accesscode.option.privateshared': 'Private Shared',
  'dashboard.tenantPage.notes': 'Notes',
  'dashboard.tenantPage.notes.warning': 'Limit is 65,535 characters',
  'dashboard.tenantPage.heading.pricing': 'Pricing',
  'dashboard.tenantPage.heading.contactInfo': 'Contact Info',
  'dashboard.tenantPage.heading.loadManagement': 'Load Management',
  'dashboard.tenantPage.heading.deployment': 'Deployment',
  'dashboard.tenantPage.chargersTab.listings': 'Listings',
  'dashboard.tenantPage.chargersTab.tenantupdated': 'has been updated!',
  'dashboard.tenantPage.eyedroIpAddress.heading': 'Building Service - EV Panel Load',
  'dashboard.tenantPage.eyedroIpAddress.powerfactor': 'Power Factor',
  'dashboard.tenantPage.eyedroIpAddress.voltage': 'Voltage (V)',
  'dashboard.tenantPage.eyedroIpAddress.amperage': 'Amperage (A)',
  'dashboard.tenantPage.eyedroIpAddress.wattage': 'Wattage (W)',
  'dashboard.tenantPage.eyedroIpAddress.ipaddress': 'IP Address',
  'dashboard.tenantPage.error.geocodingError': 'Geocoding result is outside province',
  'dashboard.tenantPage.error.coordinateError': 'Could not find coordinates for this address',
  'dashboard.tenantPage.error.countryAndProvinceError': 'Please pick a country and province for this tenant',
  'dashboard.tenantPage.error.googleLatLongError': 'Google did not give us a latitude and longitude for that address',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.checkbox.text': 'Uniform Pricing',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.enabled':
    'Uniform Pricing Enabled for all the listings in the tenant',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.disabled':
    'Uniform Pricing Disabled for all the listings in the tenant',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.updated': 'uniform pricing updated!',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.info.p1':
    'If enabled, it indicates that all chargers in this tenant will have the same pricing configuration.',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.info.p2':
    ' If disabled, then each charger’s pricing settings must be configured individually',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.listing': 'Listing Author',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.stripeAccount.text': 'Stripe Account',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectListingAuthorText': 'Select listing author',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectStripeAccountText': 'Select Stripe Account',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.priceCentsText': 'Price ($)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.kwOutputBasedPricingText': 'Hourly pricing based on kWh',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.enableTenantPreferredPricingText':
    'Enable Tenant Preferred Pricing',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.tenantPreferredPricingText': 'Tenant Preferred Pricing ($)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.tenantPreferredWarningText':
    'Preferred Price cannot be empty!',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.tenantPreferredPriceTypeWarningText':
    'Please select a type for preferred price',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.priceText': 'Price',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.priceTypeHourText': 'Hour',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.flatFeeText': 'Flat Fee ($)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.defineNewComissionTypeText': 'Define New Commission Fee Type',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.defineNewComissionFeeText': 'Define New Commission Fee (%)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.defineNewComissionTypeWarningText':
    'Commission fee cannot be empty!',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.guestFeeText': 'Guest Fee ($)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.paymentLimitText': 'Payment Limit ($)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText': 'Loitering Status',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText.none': 'None',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText.statusBased': 'Status Based',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText.timeBased': 'Time Based',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringtimeLimitText':
    'Loitering Time Limit For Time-Based In Minutes',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringPenaltyPerHour': 'Loitering Penalty Per Hour ($)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringtimeLimit.emptyfield': 'Field cannot be empty!',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringtimeLimitText.gracetimelimit.minutes':
    'Loitering Time Limit For Grace-Period in Minutes',
  'dashboard.tenantPage.uniformPricingTab.updatedtext': 'uniform pricing updated!',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectComissionFeeText': 'Select Comission Fee',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectPreferredPricingOptionText':
    'Select Preferred Pricing Option',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectPricingOptionText': 'Select Pricing Option',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.saveBtn': 'Save',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.modifybtn': 'Modify',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.cancelBtn': 'Cancel',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.toggleUniformPricingText': 'Toggle Uniform Pricing',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.uniformPricing.text': 'Uniform Pricing',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.uniformPricing..enable.text': 'Enable',
  'dashboard.tenantPage.accessCodeuniformUpdateText': 'Uniform Access Control',
  'dashboard.tenantPage.mapSettingsuniformUpdateText': 'Uniform Map Settings',
  'dashboard.tenantPage.locationTab.hideTenantFromListingsText': 'Listings Hidden on Map',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.heading': 'Enable Time of Day Pricing',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.weekdays': 'Weekdays',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.weekends': 'Weekends',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.start': 'Start',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.end': 'End',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.enablePricingForTenantMembers':
    'Enable pricing for tenant members',
  'dashboard.tenantPage.enableLoitering': 'Disable Loitering For Tenant Member',
  'dashboard.tenantPage.overviewTab.taxRegistration.heading': 'Tax Registration',
  'dashboard.tenantPage.overviewTab.taxRegistration.option.registered': 'Registered',
  'dashboard.tenantPage.overviewTab.taxRegistration.option.not_registered': 'Not Registered',
  'dashboard.tenantPage.overviewTab.taxRegistration.option.joint_elected': 'Joint Elected',

  /******************* Create Tenant Page ***************************/
  'dashboard.createnewtenantspage.heading': 'Create New Tenant',
  'dashboard.tenantPage.chargersTab.tenantcreated': 'has been successfully created!',

  /******************* Analytics Page *********************************/
  'dashboard.analyticsPage.heading': 'Disconnection Summary',
  'dashboard.analyticsPage.searchtenant': 'Search Tenant',
  'dashboard.analyticsPage.searchBtn': 'Search',
  'dashboard.analyticsPage.table.heading.avgDisconnectDuration': 'Tenant',
  'dashboard.analyticsPage.table.heading.chargerSerialNumber': 'Charger Serial Number',
  'dashboard.analyticsPage.table.heading.date': 'Date',
  'dashboard.analyticsPage.table.heading.avgDailyDisconnect': 'Avg Daily Disconnect',
  'dashboard.analyticsPage.table.heading.totalDisconnect': 'Total Disconnect',
  'dashboard.analyticsPage.table.heading.disconnectHigh': 'Disconnect High',
  'dashboardanalyticsPage.table.heading.avgDisconnectDuration': 'Avg Disconnect Duration',

  /******************* Users Page *********************************/
  'dashboard.usersPage.heading': 'Users',
  'dashboard.usersPage.searchUser': 'Search User',
  'dashboard.usersPage.authorizeDriver': 'Authorize Driver',
  'dashboard.usersPage.inviteUsersTo': 'Invite user to',
  'dashboard.usersPage.inviteUser': 'INVITE USER',
  'dashboard.usersPage.selectatenant': 'Select a tenant',
  'dashboard.usersPage.table.heading.id': 'ID',
  'dashboard.usersPage.table.heading.email': 'Email',
  'dashboard.usersPage.table.heading.name': 'Name',
  'dashboard.usersPage.table.heading.user': 'User',
  'dashboard.usersPage.table.heading.role': 'Role',
  'dashboard.usersPage.table.heading.role.user': 'User',
  'dashboard.usersPage.table.heading.role.admin': 'Admin',
  'dashboard.usersPage.table.heading.role.support': 'Support',
  'dashboard.usersPage.table.heading.tenant': 'Tenant Access',
  'dashboard.usersPage.table.heading.action': 'Action',
  'dashboard.usersPage.table.heading.access': 'Access',
  'dashboard.usersPage.table.heading.noaccess': 'No Access',
  'dashboard.usersPage.table.heading.access.modify': 'Modify',
  'dashboard.usersPage.table.heading.access.modify.saveBtn': 'Save',
  'dashboard.usersPage.table.heading.access.modify.cancelBtn': 'Cancel',
  'dashboard.usersPage.table.heading.access.info': "User's dashboard access to",
  'dashboard.usersPage.table.heading.action.toggleRole': 'Toggle Role',
  'dashboard.usersPage.table.nestedTable.associatedTenants': 'Associated Tenants',
  'dashboard.usersPage.table.nestedTable.accessLevel': 'Access Level',
  'dashboard.usersPage.table.nestedTable.accessLevel.manager': 'Manager',
  'dashboard.usersPage.table.nestedTable.accessLevel.driver': 'Driver',
  'dashboard.usersPage.table.nestedTable.accessLevel.driver.info': 'A user that has access to private listings in',
  'dashboard.usersPage.table.nestedTable.accessLevel.viewer': 'Viewer',
  'dashboard.usersPage.invitedUsersTo': 'Invited User to',
  'dashboard.usersPage.user.tenant.warning.info': 'Note: The user is not associated with all tenants selected',
  'dashboard.usersPage.user.tenant.warning.exapandedView': 'The user is not associated with this tenant',

  /******************* Users Page Invite User Modal Form *************************/
  'dashboard.usersPage.inviteUser.heading': 'Invite User to',
  'dashboard.usersPage.inviteUser.heading2': 'Invite User',
  'dashboard.usersPage.inviteUser.headingInfo': `Invite existing ${configProvider.config.siteTitle} users to access the dashboard and authorize drivers to access restricted listings in a tenant.`,
  'dashboard.usersPage.inviteUser.oktext': 'Invite',
  'dashboard.usersPage.inviteUser.sendInvite': 'SEND INVITATION',
  'dashboard.usersPage.inviteUser.addMore': 'ADD MORE',
  'dashboard.usersPage.inviteUser.cancelText': 'Cancel',
  'dashboard.usersPage.inviteUser.cancelText2': 'CANCEL',
  'dashboard.usersPage.inviteUser.email.heading': 'Email',
  'dashboard.usersPage.inviteUser.firstName.heading': 'First Name',
  'dashboard.usersPage.inviteUser.lastName.heading': 'Last Name',
  'dashboard.usersPage.inviteUser.role.heading': 'Role',
  'dashboard.usersPage.inviteUser.role.heading2': 'ROLE',
  'dashboard.usersPage.inviteUser.email.newWarning': 'Please enter a valid email address',
  'dashboard.usersPage.inviteUser.email.warning': 'Email is required!',
  'dashboard.usersPage.inviteUser.email.notValid.warning': 'Email is not a valid email!',
  'dashboard.usersPage.inviteUser.firstName.warning': 'First Name is required!',
  'dashboard.usersPage.inviteUser.lastName.warning': 'Last Name is required!',
  'dashboard.usersPage.inviteUser.role.warning': 'Role is required!',
  'dashboard.usersPage.inviteUser.tenant.heading': 'Tenant',
  'dashboard.usersPage.inviteUser.tenant.warning': 'A tenant must be selected to process',
  'dashboard.usersPage.inviteUser.tenants.heading': 'TENANTS',
  'dashboard.usersPage.inviteUser.driver.heading': 'DRIVER',
  'dashboard.usersPage.inviteUser.none': 'None',
  'dashboard.usersPage.inviteUser.driverTooltip':
    'Authorized drivers will gain access to restricted chargers in the tenant',

  /******************* Users Page Edit User Name Modal Form *************************/
  'dashboard.usersPage.editUserName.heading': "Edit User's Name",
  'dashboard.usersPage.editUserName.firstName.heading': 'First Name',
  'dashboard.usersPage.editUserName.lastName.heading': 'Last Name',
  'dashboard.usersPage.editUserName.cancelText': 'Cancel',
  'dashboard.usersPage.editUserName.updateText': 'Update',
  'dashboard.usersPage.editUserName.firstName.warning': 'First name is required',
  'dashboard.usersPage.editUserName.lastName.warning': 'Last name is required',
  'dashboard.usersPage.editUserName.firstName.maxlength.warning': 'First name cannot exceed 20 characters!',
  'dashboard.usersPage.editUserName.lastName.maxlength.warning': 'Last name cannot exceed 20 characters!',

  /******************* Users Page Edit User Modal Form *************************/
  'dashboard.usersPage.editUser.heading': 'Edit User',
  'dashboard.usersPage.editUser.sendInvite': 'SEND INVITATION',
  'dashboard.usersPage.editUser.save': 'SAVE',
  'dashboard.usersPage.editUser.cancelText': 'CANCEL',
  'dashboard.usersPage.editUser.tenants.heading': 'TENANTS',
  'dashboard.usersPage.editUser.driver.heading': 'DRIVER',
  'dashboard.usersPage.editUser.role.heading': 'ROLE',
  'dashboard.usersPage.editUser.none': 'None',
  'dashboard.usersPage.editUser.addMore': 'ADD MORE',
  'dashboard.usersPage.editUser.driverTooltip':
    'Authorized drivers will gain access to restricted chargers in the tenant',

  /******************* Users Page Add User To Multiple Tenants Form *************************/
  'dashboard.usersPage.selectTenants.heading': 'Add User To Multiple Tenants',
  'dashboard.usersPage.selectTenants.field': 'Select Tenants',
  'dashboard.usersPage.selectTenants.tbl.heading': 'Add User to Tenants',
  'dashboard.usersPage.selectTenants.field.warning': 'A tenant must be selected!',
  'dashboard.usersPage.selectTenants.addbtn': 'Add',
  'dashboard.usersPage.selectTenants.adduserbtn': 'Add User',
  'dashboard.usersPage.selectTenants.successmssg': 'Successfully added user to tenants',
  'dashboard.usersPage.selectTenants.failedmssg': 'Could not add user to tenants',

  /******************* Users Page Authorize Modal Form *************************/
  'dashboard.usersPage.authorizeModal.heading': 'Authorize drivers to access private listings',
  'dashboard.usersPage.authorizeModal.info.heading': 'Driver Authorization',
  'dashboard.usersPage.authorizeModal.info.p1': 'Authorized drivers will gain access to private listings at',
  'dashboard.usersPage.authorizeModal.userSearchField.userText': 'User',
  'dashboard.usersPage.authorizeModal.userSearchField.searchUserText': 'Search User',
  'dashboard.usersPage.authorizeModal.userSearchField.searchBtn': 'Search',
  'dashboard.usersPage.authorizeModal.authorizeBtn': 'Authorize User',
  'dashboard.usersPage.authorizeModal.cancelBtn': 'Cancel',
  'dashboard.usersPage.authorizeModal.warning': 'User already has access to the selected tenant',
  'dashboard.usersPage.authorizeModal.noMatchFound':
    'No match found. Please confirm the user has registered a SWTCH account with the indicated e-mail address.',

  /******************* Mobile Calendar *********************************/
  'dashboard.mobileCalendar.selectDate': 'Select Date',
  'dashboard.mobileCalendar.startDate': 'Start Date',
  'dashboard.mobileCalendar.endDate': 'End Date',
  'dashboard.mobileCalendar.title': 'Calendar',
  'dashboard.mobileCalendar.today': 'Today',
  'dashboard.mobileCalendar.month': 'Month',
  'dashboard.mobileCalendar.year': 'Year',
  'dashboard.mobileCalendar.am': 'AM',
  'dashboard.mobileCalendar.pm': 'PM',
  'dashboard.mobileCalendar.dateTimeFormat': 'MM/dd/yyyy w hh:mm',
  'dashboard.mobileCalendar.dateFormat': 'yyyy/MM/dd w',
  'dashboard.mobileCalendar.nochoose': 'No Choose',
  'dashboard.mobileCalendar.week.sun': 'Sun',
  'dashboard.mobileCalendar.week.mon': 'Mon',
  'dashboard.mobileCalendar.week.tue': 'Tue',
  'dashboard.mobileCalendar.week.wed': 'Wed',
  'dashboard.mobileCalendar.week.thu': 'Thu',
  'dashboard.mobileCalendar.week.fri': 'Fri',
  'dashboard.mobileCalendar.week.sat': 'Sat',
  'dashboard.mobileCalendar.clear': 'Clear',
  'dashboard.mobileCalendar.selectTime': 'Select Time',
  'dashboard.mobileCalendar.selectStartTime': 'Select Start Time',
  'dashboard.mobileCalendar.selectEndTime': 'Select End Time',
  'dashboard.mobileCalendar.start': 'Start',
  'dashboard.mobileCalendar.end': 'End',
  'dashboard.mobileCalendar.begin': 'Start',
  'dashboard.mobileCalendar.over': 'End',
  'dashboard.mobileCalendar.begin_over': 'S/E',
  'dashboard.mobileCalendar.confirm': 'Confirm',
  'dashboard.mobileCalendar.monthTitle': 'yyyy/MM',
  'dashboard.mobileCalendar.loadPrevMonth': 'Load Prev Month',
  'dashboard.mobileCalendar.yesterday': 'Yesterday',
  'dashboard.mobileCalendar.lastWeek': 'Last Week',
  'dashboard.mobileCalendar.lastMonth': 'Last Month',

  /******************* Mobile ListView *********************************/
  'dashboard.mobilelistview.prev': 'Prev',
  'dashboard.mobilelistview.next': 'Next',

  /******************* Disconnection Page *********************************/
  'dashboard.disconnectionpage.heading': 'Disconnection Summary',
  'dashboard.disconnectionpage.table.heading.avgDisconnectDuration': 'Tenant',
  'dashboard.disconnectionpage.table.heading.chargerSerialNumber': 'Charger Serial Number',
  'dashboard.disconnectionpage.table.heading.date': 'Date',
  'dashboard.disconnectionpage.table.heading.avgDailyDisconnect': 'Avg Daily Disconnect',
  'dashboard.disconnectionpage.table.heading.totalDisconnect': 'Total Disconnect',
  'dashboard.disconnectionpage.table.heading.disconnectHigh': 'Disconnect High',
  'dashboard.disconnectionPage.table.heading.avgDisconnectDuration': 'Avg Disconnect Duration',
  'dashboard.disconnectionpage.table.fromHeading': 'From',

  /*************************** Report Page ***************************/
  'dashboard.report.heading': 'Reports',
  'dashboard.reports.table.heading.entity': 'Entity',
  'dashboard.reports.table.heading.entityName': 'Name',
  'dashboard.reports.table.heading.entityType': 'Type',
  'dashboard.reports.table.heading.dateRange': 'Date',
  'dashboard.reports.table.heading.generator': 'Author',
  'dashboard.reports.table.heading.download': 'Download',
  'dashboard.reports.table.heading.email': 'Email',
  'dashboard.reports.table.row.download': 'Download File',
  'dashboard.reports.table.row.timeframe.quarterly': 'Quarterly',
  'dashboard.reports.table.row.type.transaction': 'Transactions',
  'dashboard.reports.table.row.type.erroredTransaction': 'Errored Transactions',
  'dashboard.reports.table.row.timeframe.yearly': 'Yearly',
  'dashboard.reports.form.sendReport.emails.label': 'Emails (comma seperated)',
  'dashboard.reports.form.sendReport.emails.warning': 'Emails are required!',
  'dashboard.reports.form.sendReport.emails.notValid': 'Email is not a valid email!',
  'dashboard.reports.form.sendReport.oktext': 'Email Report',
  'dashboard.reports.form.sendReport.generateText': 'Generate',
  'dashboard.reports.form.sendReport.cancelText': 'Cancel',
  'dashboard.reports.form.sendReport.reportFrom': 'report from',
  'dashboard.reports.modal.title.generateReport': 'Generate Report',
  'dashboard.reports.modal.title.generateErroredReport': 'Generate Errored  Report',
  'dashboard.reports.modal.generate.introText':
    'This will generate a PDF report from the previous quarter for all tenants between',
  'dashboard.reports.modal.generateErroredReport.introText':
    'This will generate Errored Transactions PDF report for all tenants between',
  'dashboard.reports.modal.generate.endingText': 'This process may take a few hours to complete once started.',
  'dashboard.reports.table.text.generator': 'system',
  'dashboard.reports.table.text.reportGeneratedBy': 'Report generated by',
  'dashboard.reports.table.text.generatedBy': 'Generated by',
  'dashboard.reports.message.generatingReport': 'Generating reports',
  'dashboard.reports.message.generatingErroredTransactionsReport': 'Generating errored transactions reports',
  'dashboard.reports.modal.title.deleteReport': 'Delete Report',
  'dashboard.reports.modal.message.deleteReport.text': 'You are about to delete. Are you sure?',
  'dashboard.reports.modal.message.deleting': 'Deleting report',

  /************************* Load Management Tab *************************/
  'dashboard.loadManagementTab.addMixedCircuit': 'Add Mixed Circuit',
  'dashboard.loadManagementTab.addDedicatedCircuit': 'Add Dedicated Circuit',
  'dashboard.loadManagementTab.button.sendUpdates': 'Send Updates',
  'dashboard.loadManagementTab.table.locale.unconfig': 'No more unconfigured chargers',
  'dashboard.loadManagementTab.table.locale.panel.title': 'No electrical panel is connected',
  'dashboard.loadManagementTab.table.locale.panel.subtitle': 'Please use the button on the top left to create one',
  'dashboard.loadManagementTab.table.locale.branch':
    'There is no branch connected. Please click on Add Branch button on the top right of this  circuit to create one',
  'dashboard.loadManagementTab.table.title.unconfig': 'Unconfigured Chargers',
  'dashboard.loadManagementTab.circuitName': 'Circuit Name',
  'dashboard.loadManagementTab.branchName': 'Branch Name',
  'dashboard.loadManagementTab.editCircuit': 'Edit Circuit',
  'dashboard.loadManagementTab.addCircuit': 'Add Circuit',
  'dashboard.loadManagementTab.editBranch': 'Edit Branch',
  'dashboard.loadManagementTab.addBranch': 'Add Branch',
  'dashboard.loadManagementTab.editCharger': 'Edit Charger',
  'dashboard.loadManagementTab.updateCharger': 'Update Charger',
  'dashboard.loadManagementTab.type': 'Circuit Type',
  'dashboard.loadManagementTab.loadManagement': 'Load Management',
  'dashboard.loadManagementTab.circuitBranch': 'Circuit/Branch',
  'dashboard.loadManagementTab.ipAddress': 'IP Address',
  'dashboard.loadManagementTab.ctClampConfig': 'CT Clamp Configuration for Mixed Circuit',
  'dashboard.loadManagementTab.unconfiguredChargers': 'Unconfigured Chargers',
  'dashboard.loadManagementTab.rule.existingLoad': 'Please enter a valid existing load (A)',
  'dashboard.loadManagementTab.rule.ipAddress': 'Please enter a valid IP Address',
  'dashboard.loadManagementTab.rule.ipAddress.warning':
    'A valid IP address has four decimal numbers separated by periods',
  'dashboard.loadManagementTab.rule.port': 'Please enter a valid port',
  'dashboard.loadManagementTab.rule.circuitSize': 'Please enter a valid circuit size (A)',
  'dashboard.loadManagementTab.circuitTypeField': 'Dedicated or Mixed Type',
  'dashboard.loadManagementTab.circuitName.message': 'Please enter a name for the circuit',
  'dashboard.loadManagementTab.label.circuitSize': 'Circuit Size (A)',
  'dashboard.loadManagementTab.label.existingLoad': 'Existing Load (A)',
  'dashboard.loadManagementTab.label.maxAmp': 'Max. Cap (A)',
  'dashboard.loadManagementTab.label.minAmp': 'Min. Cap (A)',
  'dashboard.loadManagementTab.label.fallbackAmp': 'Fallback (A)',
  'dashboard.loadManagementTab.label.breakerSize': 'Breaker Size (A)',
  'dashboard.loadManagementTab.label.circuitId': 'Circuit ID',
  'dashboard.loadManagementTab.cloudLoadMgmt.message':
    "This listing’s cloud load management has been disabled. To enable, click on the listing, click on 'Edit Listing' and click on 'Allow Cloud Load Management'",
  'dashboard.loadManagementTab.rule.endPoint': 'Endpoint cannot be empty!',
  'dashboard.loadManagementTab.rule.http': 'Must be either an HTTPS or HTTP protocol',
  'dashboard.loadManagementTab.text.instanceIP': 'Instance IP',
  'dashboard.loadManagementTab.text.sendUpdates': 'Send Updates',
  'dashboard.loadManagementTab.result.title.tenantAllowLoadMgmt':
    'Currently, the load management is disabled for this tenant',
  'dashboard.loadManagementTab.result.subTitle.tenantAllowLoadMgmt':
    'To allow load management, click the Enable button below',
  'dashboard.loadManagementTab.disableLoadMgmt': 'Disable Load Management',
  'dashboard.loadManagementTab.result.title.error': 'Enabling Load Management Failed',
  'dashboard.loadManagementTab.result.subtitle.error':
    'Please check if the tenant is capable of load management and contact the administrator for further help',
  'dashboard.loadManagementTab.missingListing.message':
    'This charger has no listing. To attach a listing, click on the Add Listing button',
  'dashboard.loadManagementTab.button.addListing': 'Add Listing',
  'dashboard.loadManagementTab.maxCap.message': 'Cannot be greater than the max cap',
  'dashboard.loadManagementTab.minCap.message': 'Cannot be less than the min cap',
  'dashboard.loadManagementTab.rule.branchName': 'Branch name must be different',
  'dashboard.loadManagementTab.addBranch.toolTip.message':
    'For every N unconfigured chargers, there can only be N branches',

  /******************* Plan and Subscription Page *********************/
  'dashboard.subscriptionsPage.button.title': 'Create Plan',
  'dashboard.subscriptionsPage.placeholder.searchPlan': 'Search Plan',
  'dashboard.subscriptionsPage.createPlanForm.selectPlan.warning': 'A plan must be selected to process',
  'dashboard.subscriptionsPage.createPlanForm.searchUser.warning':
    'No match found. Please confirm the user has registered a SWTCH account with the indicated e-mail address.',
  'dashboard.subscriptionsPage.inviteForm.help.start': 'The start of subscription',
  'dashboard.subscriptionsPage.inviteForm.help.end': 'The end of subscription',
  'dashboard.subscriptionsPage.invitePlanForm.user.warning': 'A user must be selected to process',
  'dashboard.plan.modal.delete': 'Are you sure you want to delete the plan ',
  'dashboard.plan.modal.archive': 'Are you sure you want to archive the plan ',
  'dashboard.plan.modal.unarchive': 'Are you sure you want to unarchive the plan ',
  'dashboard.subscriptionsPage.modal.delete': 'Are you sure you want to cancel the subscription for ',
  'dashboard.subscriptionsPage.modal.pause': 'Are you sure you want to pause the subscription for ',
  'dashboard.subscriptionsPage.modal.unpause': 'Are you sure you want to unpause the subscription for ',
  'dashboard.subscriptionsPage.editSubscription': 'Edit Subscription',
  'dashboard.subscriptionsPage.deleteSubscription': 'Delete Subscription',
  'dashboard.plan.subscriptionPlan': 'Subscription Plans',
  'dashboard.plan.subscribeUser': 'Subscribe User',
  'dashboard.plan.editPlan': 'Edit Plan',
  'dashboard.plan.togglePlan': 'Toggle Plan',
  'dashboard.text.subscribeNewUser': 'Subscribe New User',
  'dashboard.text.cancelSubscription': 'Cancel Subscription',
  'dashboard.text.pauseSubscription': 'Pause Subscription',
  'dashboard.text.unpauseSubscription': 'Unpause Subscription',

  /*************************** Create Listing Page ***************************/
  'dashboard.listing.ListingsText': 'Listings',
  'dashboard.listing.createListingRequiredText': 'Listing Title is Required',
  'dashboard.listing.listingAlreadyExistsText': 'Listing title already exists',
  'dashboard.listing.CreateNewListing': 'Create Listing',
  'dashboard.listing.ListingsTable.titleText': 'Title',
  'dashboard.listing.ListingsTable.authorText': 'Author',
  'dashboard.listing.ListingsTable.categoryText': 'Category',
  'dashboard.listing.ListingsTable.chargerText': 'Charger',
  'dashboard.listing.ListingsTable.connectorIdText': 'Connector ID',
  'dashboard.listing.ListingsTable.priceText': 'Price',
  'dashboard.listing.ListingsTable.priceTypeText': 'Price Type',
  'dashboard.listing.ListingsTable.activeText': 'Active',
  'dashboard.listing.ListingsTable.activeText.open': 'Open',
  'dashboard.listing.ListingsTable.activeText.closed': 'Closed',
  'dashboard.createNewListing.txt': 'Create a Listing',
  'dashboard.createNewListing.ListingTab': 'Listing',
  'dashboard.createNewListing.chargerTab': 'Charger',
  'dashboard.createNewListing.loiteringTab': 'Loitering',
  'dashboard.createNewListing.priceTab': 'Price',
  'dashboard.createNewListing.commissionTab': 'Commission & Fee',
  'dashboard.createNewListing.reviewTab': 'Review',
  'dashboard.createNewListing.nextBtn': 'Next',
  'dashboard.createNewListing.previousBtn': 'Previous',
  'dashboard.createNewListing.submitBtn': 'Submit',
  'dashboard.createNewListing.listingStep.heading': 'Listing Title',
  'dashboard.createNewListing.listingStep.detailedDescription.heading': 'Listing Info',
  'dashboard.createNewListing.listingStep.detailedDescription.warning': '255 characters is the limit',
  'dashboard.createNewListing.listingStep.detailedDescription.info':
    'If your description contains YouTube links, the videos will be shown below the description',
  'dashboard.createNewListing.listingStep.listingAuthor.heading': 'Listing Author',
  'dashboard.createNewListing.listingStep.listingAuthor.selectTxt': 'Select Author',
  'dashboard.createNewListing.listingStep.listingAuthor.warning': 'Listing Author is required',
  'dashboard.createNewListing.listingStep.stripeAccount.heading': 'Stripe Account is required',
  'dashboard.createNewListing.listingStep.stripeAccount.selectTxt': 'Select Stripe Account',
  'dashboard.createNewListing.listingStep.listingCategory.Txt': 'Listing Category',
  'dashboard.createNewListing.listingStep.listingCategory.Reservation': 'Reservation',
  'dashboard.createNewListing.listingStep.listingCategory.Non Reservation': 'Non Reservation',
  'dashboard.createNewListing.listingStep.listingCategory.Destination': 'Destination',
  'dashboard.createNewListing.listingStep.listingCategory.Non Network': 'Non Network',
  'dashboard.createNewListing.listingStep.currencyCategory.txt': 'Currency',
  'dashboard.createNewListing.listingStep.walletCategory.txt': 'Wallet Functionality',
  'dashboard.createNewListing.listingStep.walletCategory.walletOption': 'Wallet',
  'dashboard.createNewListing.listingStep.walletCategory.nowalletOption': 'No Wallet',
  'dashboard.createNewListing.listingStep.listingCategory.txt': 'Access Control',
  'dashboard.createNewListing.listingStep.listingCategory.privateOption': 'Restricted',
  'dashboard.createNewListing.listingStep.listingCategory.publicOption': 'Open',
  'dashboard.createNewListing.listingStep.hiddenCategory.txt': 'Listings hidden from map',
  'dashboard.createNewListing.listingStep.carDetailsCategory.txt': 'Users need car details to book',
  'dashboard.createNewListing.chargerStep.tenantCategory.txt': 'Associated Tenant',
  'dashboard.createNewListing.chargerStep.tenantCategory.warning': 'Tenant is required',
  'dashboard.createNewListing.chargerStep.chargerOutput.txt': 'Charger Output (kW)',
  'dashboard.createNewListing.chargerStep.chargerOutput.timeOfDayCharging.txt': 'Select if time of day charging',
  'dashboard.createNewListing.chargerStep.customNotification.txt': 'Custom notification',
  'dashboard.createNewListing.chargerStep.customNotification.info':
    'Provide instructions on how to operate the charger if necessary',
  'dashboard.createNewListing.priceStep.allowClouldLoadManagement.txt': 'Allow Cloud Load Management',
  'dashboard.createNewListing.priceStep.localLoadManagementAutoStop.txt':
    'Local Load Management: Transaction Auto-stop',
  'dashboard.createNewListing.priceStep.preferredPricing.txt': 'Preferred pricing for tenant members',
  'dashboard.createNewListing.comissionStep.txt': 'Commission (%)',
  'dashboard.createNewListing.comissionStep.guestFee.txt': 'Guest Fee ($)',
  'dashboard.createNewListing.comissionStep.paymentLimit.txt': 'Payment Limit ($)',
  'dashboard.createNewListing.reviewStep.listing.title': 'Title',
  'dashboard.createNewListing.reviewStep.listing.category': 'Category',
  'dashboard.createNewListing.reviewStep.listing.paymentType': 'Payment Type',
  'dashboard.createNewListing.reviewStep.listing.accessType': 'Access Type',
  'dashboard.createNewListing.reviewStep.listing.hiddenFromMap': 'Hidden From Map',
  'dashboard.createNewListing.reviewStep.listing.carDetailRequired': 'Car Detail Required',
  'dashboard.createNewListing.reviewStep.listing.author': 'Author',
  'dashboard.createNewListing.reviewStep.listing.stripeAccount': 'Stripe Account',
  'dashboard.createNewListing.reviewStep.listing.currency': 'Currency',
  'dashboard.createNewListing.reviewStep.listing.description': 'Location Description',
  'dashboard.createNewListing.reviewStep.charger.chargerOutput': 'Charger Output',
  'dashboard.createNewListing.reviewStep.charger.enableTimeOfDayCharging': 'Enable Time of Day Charging',
  'dashboard.createNewListing.reviewStep.charger.customNotification': 'Custom notification',
  'dashboard.createNewListing.reviewStep.price.preferedPricingtxt': 'Prefered Price Per Unit Cents ($)',
  'dashboard.createNewListing.reviewStep.price.hourlyPricingtxt': 'Hourly pricing based on kWh ($)',
  'dashboard.createNewListing.reviewStep.price.todPriceSettingstxt': 'Time of Day Price Settings',
  'dashboard.createNewListing.listingCreatedSuccessfullyText': 'Listing -1 Created Successfully',
  'dashboard.updated.listing': 'Listing has been updated successfully',
  'dashboard.createNewListing.refreshAuthorsText': 'Refresh Authors and Stripe Accounts',
}
