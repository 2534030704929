import { useState } from 'react'
import { Card, Col, Row } from 'antd'

import { GhgInfoModal } from '../../ghg-info-modal'
import { InfoIcon } from '../../../../assets/svg/info'
import { useHomeTranslation } from '../../../../hooks/translation/useHomeTranslation'
import { TrxSummary } from '../../../../models/transaction'
import { CardWrapper } from '../../../../atom/card'

interface props {
  loading: boolean
  trxProfileSummary?: TrxSummary
}

const GHGMetrics: React.FC<props> = ({ loading, trxProfileSummary }) => {
  const [showGHGModal, setShowGHGModal] = useState(false)

  const { GHGSavingsTxt } = useHomeTranslation()

  return (
    <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
      <CardWrapper>
        <Card
          title={GHGSavingsTxt}
          loading={loading}
          className="ghg-savings"
          extra={<InfoIcon onClick={() => setShowGHGModal(true)} />}
        >
          <Row align="middle" justify="space-between">
            <Col span={24}>
              <h2 className="heading-02-black" style={{ display: 'inline-block' }}>
                {trxProfileSummary &&
                  Number.parseFloat(
                    `${trxProfileSummary.ghgSavingTotal ? trxProfileSummary.ghgSavingTotal / 1000 : 0}`,
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
              </h2>
              <p className="paragraph-04-regular" style={{ marginLeft: '5px', display: 'inline-block' }}>
                <span>
                  KG CO<sub>2</sub> eq
                </span>
              </p>
            </Col>
          </Row>
        </Card>
        <GhgInfoModal visible={showGHGModal} onClose={() => setShowGHGModal(false)} />
      </CardWrapper>
    </Col>
  )
}

export default GHGMetrics
