import { Button, Card, Col, Row, Space, Tabs } from 'antd'

import { Section } from '../../../atom/box'
import { styled, theme } from '../../../theme'
import { useFormatMessage } from '../../../helpers/intl'
import { CustomDateRangePicker } from '../../../components/redesign/datepicker'
import { HomePageOperationOverviewChart } from '../charts/active-users/operation-overview'
import { calculateTransactionInterval } from '../../../services/data-provider/transactionv2'
import { useCalendarTransactions } from '../../../hooks/translation/useCalendarTranslation'
import { TrxnOverview } from '../../../models/transaction'
import { CardWrapper } from '../../../atom/card'

const OperationOverviewTabsContainer = styled.div`
  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: end;
  }
  padding: 10px;
`

interface props {
  loading: boolean
  startDate: moment.Moment
  endDate: moment.Moment
  trnxOverview?: TrxnOverview[]
  operationOverviewTabKey: string
  reveneChartVisible: boolean
  energyChartVisible: boolean
  transactionsChartVisible: boolean
  onChangeDate: (dates: moment.Moment[]) => void
  onFetchTransactionSummary: () => void
  onHandleTabChangeOperationOverviewTab: (activeKey: string) => void
}

const OperationOverview: React.FC<props> = ({
  loading,
  startDate,
  endDate,
  trnxOverview,
  operationOverviewTabKey,
  reveneChartVisible,
  energyChartVisible,
  transactionsChartVisible,
  onChangeDate,
  onFetchTransactionSummary,
  onHandleTabChangeOperationOverviewTab,
}) => {
  const { calendarRanges } = useCalendarTransactions()
  return (
    <Section style={{ margin: '30px auto' }}>
      <Row align="middle" justify="space-between" gutter={[15, 15]} style={{ marginBottom: '14px' }}>
        <Col xl={16} xxl={16}>
          <h5
            className="heading-05-regular"
            style={{ color: theme.colors.softBlack, marginBottom: 0, paddingTop: '10px', fontWeight: 400 }}
          >
            {useFormatMessage('dashboard.homePage.operationoverview.heading', 'Operation Overview')}
          </h5>
        </Col>
        <Col xl={8} xxl={8} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          <Space>
            <CustomDateRangePicker
              startDate={startDate}
              endDate={endDate}
              onChangeDate={onChangeDate}
              calendarRanges={calendarRanges}
            />

            <Button type="default" onClick={onFetchTransactionSummary}>
              <span style={{ fontFamily: theme.fontFamily.lightFontFamily }} className="regular-cap">
                {useFormatMessage('dashboard.homePage.search', 'Search')}
              </span>
            </Button>
          </Space>
        </Col>
      </Row>
      <Row align="middle" justify="start" gutter={[15, 15]} className="content">
        <Col sm={24} md={24} lg={24} xl={24} xxl={24} style={{ background: theme.colors.white }}>
          <CardWrapper>
            <Card loading={loading}>
              <OperationOverviewTabsContainer>
                <Tabs
                  className="operation-overview-tabs"
                  onChange={onHandleTabChangeOperationOverviewTab}
                  activeKey={operationOverviewTabKey}
                >
                  <Tabs.TabPane
                    tab={
                      <span className="regular-cap">{useFormatMessage('dashboard.homePage.revenue', 'Revenue')}</span>
                    }
                    key="Revenue"
                  >
                    {trnxOverview && reveneChartVisible && (
                      <HomePageOperationOverviewChart
                        trnxOverview={trnxOverview}
                        tabKey={operationOverviewTabKey}
                        transactionInterval={calculateTransactionInterval(startDate, endDate)}
                      />
                    )}
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <span className="regular-cap">
                        {useFormatMessage('dashboard.homePage.transaction', 'Transactions')}
                      </span>
                    }
                    key="Transactions"
                  >
                    {trnxOverview && transactionsChartVisible && (
                      <HomePageOperationOverviewChart
                        trnxOverview={trnxOverview}
                        tabKey={operationOverviewTabKey}
                        transactionInterval={calculateTransactionInterval(startDate, endDate)}
                      />
                    )}
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={<span className="regular-cap">{useFormatMessage('dashboard.homePage.energy', 'Energy')}</span>}
                    key="Energy"
                  >
                    {trnxOverview && energyChartVisible && (
                      <HomePageOperationOverviewChart
                        trnxOverview={trnxOverview}
                        tabKey={operationOverviewTabKey}
                        transactionInterval={calculateTransactionInterval(startDate, endDate)}
                      />
                    )}
                  </Tabs.TabPane>
                </Tabs>
              </OperationOverviewTabsContainer>
            </Card>
          </CardWrapper>
        </Col>
      </Row>
    </Section>
  )
}

export default OperationOverview
