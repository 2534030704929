import { theme } from "../../theme"

const fontFamily = theme.fontFamily.regularFontFamily

const categories = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
]

export const initialChartOption = {
  chart: {
    zoomType: 'x',
    // styledMode: true,
    height: 310,
  },

  xAxis: {
    categories: categories,
    type: 'spline',
    tickInterval: 1,
    minPadding: 0,
    maxPadding: 0,
    startOnTick: true,
    endOnTick: true,
    title: {
      text: '',
      margin: 20,
      style: {
        fontFamily,
        fontWeight: 400,
        fontSize: '12px',
      },
    },
    crosshair: {
      width: 3,
      color: '#242E2C',
      dashStyle: 'ShortDash',
    },
    labels: {
      rotation: -45,
      style: {
        fontFamily,
        fontWeight: 400,
        fontSize: '11px',
        color: '#000',
      },
      //@ts-ignore
      formatter: function () {
        //@ts-ignore
        // return categories[this.value]
        return this.value
      },
    },
  },

  yAxis: {
    crosshair: {
      width: 3,
      color: '#242E2C',
      dashStyle: 'ShortDash',
    },
    title: {
      text: null,
    },
    labels: {
      format: `{value} kW`,
      style: {
        fontFamily,
        fontWeight: 400,
        fontSize: '11px',
        color: '#000',
      },
    },
    gridLineDashStyle: 'dash',
  },

  title: {
    text: '',
  },

  credits: {
    enabled: false,
  },

  defs: {
    gradient0: {
      tagName: 'linearGradient',
      id: 'gradient-0',
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 1,
      children: [
        {
          tagName: 'stop',
          offset: 0,
        },
        {
          tagName: 'stop',
          offset: 1,
        },
      ],
    },
    gradient1: {
      tagName: 'linearGradient',
      id: 'gradient-1',
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 1,
      children: [
        {
          tagName: 'stop',
          offset: 0,
        },
        {
          tagName: 'stop',
          offset: 1,
        },
      ],
    },
  },

  plotOptions: {
    area: {
      fillColor: {
        linearGradient: {
          x1: '0%',
          y1: '0%',
          x2: '0%',
          y2: '100%',
        },
        stops: [
          [0, 'rgba(48, 215, 186, 0.21)'],
          [0.52, 'rgba(48, 215, 186, 0.21)'],
          [1, 'rgba(48, 215, 186, 0)'],
        ],
      },
      marker: {
        enabled: false,
        radius: 2,
        states: {
          hover: {
            fillColor: '#242e2c',
            lineColor: '#242e2c',
          },
        },
      },
      //   lineWidth: 1,
      states: {
        hover: {
          //   lineWidth: 1,
        },
      },
      threshold: null,
    },
    series: {
      lineWidth: 1,
    },
  },

  exporting: {
    buttons: {
      contextButton: {
        enabled: false,
      },
    },
    csv: {
      dateFormat: '%Y-%m-%d',
    },
    filename: '',
    chartOptions: {
      chart: {
        style: {
          fontFamily,
        },
      },
      title: {
        style: {
          fontFamily,
          fontWeight: 400,
          fontSize: '10px',
        },
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: '0%',
              y1: '0%',
              x2: '0%',
              y2: '100%',
            },
            stops: [
              [0, 'rgb(226,249,245)'],
              [1, 'rgb(255, 255, 255)'],
            ],
          },
        },
      },
      xAxis: {
        title: {
          style: {
            fontFamily,
            fontWeight: 400,
            fontSize: '8px',
          },
        },
        labels: {
          style: {
            fontFamily,
            fontWeight: 400,
            fontSize: '8px',
          },
        },
      },
      yAxis: {
        title: {
          style: {
            fontFamily,
            fontWeight: 400,
            fontSize: '8px',
          },
        },
        labels: {
          style: {
            fontFamily,
            fontWeight: 400,
            fontSize: '8px',
          },
        },
      },
    },
  },

  series: [
    {
      showInLegend: false,
      type: 'area',
      color: '#54DEC7',
      name: 'Consumption Load Profile (kW)',

      //   pointStart: Date.UTC(2010, 0, 1),
      // pointIntervalUnit: 'month',
      data: [],
    },
  ],
  // tooltip: {
  //   // color: '#fff',
  //   backgroundColor: '#FCFFC5',
  //   //@ts-ignore
  //   formatter: function () {
  //     //@ts-ignore
  //     return `$ ${this.y}`
  //   },
  //   crosshairs: [true, true],
  // },
  tooltip: {
    // backgroundColor: '#000',
    // borderWidth: 1,
    // borderColor: '#000',
    style: {
      color: '#fff',
      fontWeight: 'bold',
    },
    //@ts-ignore
    formatter: function () {
      //@ts-ignore
      return `${this.y} kW`
    },
  },
}