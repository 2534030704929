import React, { useState } from 'react'

import { Button, Form, Input, Modal, Select, Space, message } from 'antd'
import { formLayout, tailLayout } from '../../atom/form/page-layout'
import { SwtchError } from '../../models/error'
import { PeakShavingProgram } from '../../models/peak-shaving'
import { CreatePeakShavingProgram } from '../../services/data-provider/peak-shaving'
import { AlertError } from '../../components/error'

interface props {
  visible: boolean
  onCancel(): void
  onSubmit(): void
}

export const CreateNewProgramModal: React.FC<props> = ({ visible, onCancel, onSubmit }) => {
  const [loading, setLoading] = useState(false)
  const [CreateNewProgramForm] = Form.useForm()
  const [error, setError] = useState<SwtchError>()

  const handleOk = (values: Omit<PeakShavingProgram, 'id'>) => {
    setLoading(true)
    CreatePeakShavingProgram(values)
      .then((resp) => {
        message.success(`Program ${resp.name} created successfully!`)
        onSubmit()
        CreateNewProgramForm.resetFields()
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <Modal
        title="Create New Program"
        visible={visible}
        confirmLoading={loading}
        footer={null}
        onCancel={onCancel}
        width={1024}
      >
        <AlertError error={error} />
        <Form
          {...formLayout}
          form={CreateNewProgramForm}
          onFinish={handleOk}
          style={{ marginTop: '2rem' }}
          initialValues={{ category: 'peak shaving' }}
        >
          <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Name is required!' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Category" name="category">
            <Select
              options={[
                { value: 'peak shaving', label: 'Peak Shaving' },
                { value: 'demand response', label: 'Demand Response' },
              ]}
            />
          </Form.Item>
          <Form.Item label="Initiative" name="initiative">
            <Input.TextArea rows={5} />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Space>
              <Button onClick={onCancel}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
