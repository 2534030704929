import { PageHeader } from 'antd'

import { Box } from '../../atom/box'
import { ButtonLink } from '../../atom/button'
import { plusSignSVG } from '../../assets/svg/plusSign'
import { BackgroundContainer } from '../../atom/layout/backgroundContainer'
import { useAppState } from '../../state'

import { AlertError } from '../../components/error'
import { withAuthenticatedLayout } from '../../components/layouts/layout'

import { useListingsTranslation } from '../../hooks/translation/useListingsTranslation'
import ListingsTable from './table/ListingsTable'

import ListingAllSelectors from './selectors'
import { useListings } from '../../hooks/useListings'
import { Links } from '../../components/routes/paths'

const ListingsBasePage: React.FC = () => {
  document.querySelector('body')?.classList.add('redesignActive')
  const { selectedTenant, currentUser } = useAppState()

  const { createListing } = useListingsTranslation()

  const {
    handlePaginationChange,
    handleTenantSelection,
    handleListingTitle,
    handleListingIds,
    handleServiceStatuses,
    fetchListings,
    setTenants,
    setFilter,
    filter,
    tenants,
    listings,
    pagination,
    loading,
    error,
  } = useListings()

  const createListingButton = <ButtonLink loading={loading} icon={plusSignSVG} text={createListing} url={Links.listingCreate()}/>

  const onlyAdminCanCreateListing = currentUser?.role === 'admin' ? createListingButton : null

  const renderTitle = (): React.ReactNode => (
    <ListingAllSelectors
      loading={loading}
      filter={filter}
      defaultTenant={selectedTenant}
      tenants={tenants}
      onTenants={setTenants}
      onFilter={setFilter}
      onServiceStatus={handleServiceStatuses}
      onListingTitle={handleListingTitle}
      onListingIds={handleListingIds}
      onFetchListings={fetchListings}
      onTenantSelection={handleTenantSelection}
    />
  )

  return (
    <BackgroundContainer>
      <PageHeader title={renderTitle()} extra={onlyAdminCanCreateListing} />
      <AlertError error={error} />
      <Box>
        <ListingsTable
          loading={loading}
          listings={listings}
          pagination={pagination}
          onPageChange={handlePaginationChange}
        />
      </Box>
    </BackgroundContainer>
  )
}

export const ListingsPageV2 = withAuthenticatedLayout(ListingsBasePage)
