import { useState } from 'react'
import moment from 'moment'
import { Button, Card, Collapse, List, Space } from 'antd'

import { DateTime } from '../date-time'
import { styled, theme } from '../../theme'
import { errorTransaction, hasEnded, Transaction } from '../../models/transaction'
import { TransactionStateBadge } from './transaction-state-badge'

import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'
import { calculateDuration, formatDuration } from '../../helpers/duration'
import { formatEnergy, formatPrice } from '../../helpers/number-format'
import { useAppState } from '../../state'

const { Panel } = Collapse

const HeadingWrapper = styled.div`
  .ant-list-item-meta-title {
    font-size: ${theme.fontSizes[4]};
  }
`

export interface props {
  transactions: Transaction[]
  onStartTransaction(transaction: Transaction): Promise<void>
  onStopTransaction(transaction: Transaction): Promise<void>
  onErrorTransaction(transaction: Transaction): Promise<void>
  loading: boolean
}

export const TransactionsListView: React.FC<props> = ({
  transactions,
  onStartTransaction,
  onStopTransaction,
  onErrorTransaction,
  loading,
}) => {
  const { currentUser } = useAppState()
  const [actLoading, setActLoading] = useState(false)

  const trasactionIDHeading = useFormatMessage('dashboard.transactionPage.transactionId', 'Transaction ID')

  const handleStopTransaction = (transaction: Transaction): void => {
    setActLoading(true)
    onStopTransaction(transaction).finally(() => setActLoading(false))
  }

  const handleStartTransaction = (transaction: Transaction): void => {
    setActLoading(true)
    onStartTransaction(transaction).finally(() => setActLoading(false))
  }

  const handleErrorTransaction = (transaction: Transaction): void => {
    setActLoading(true)
    onErrorTransaction(transaction).finally(() => setActLoading(false))
  }

  const actionButtons = (transaction: Transaction) => (
    <Space>
      {transaction.ocppTransactionId && !hasEnded(transaction) && (
        <Button onClick={() => handleStopTransaction(transaction)} block={true} loading={actLoading}>
          {renderFormatMessage('dashboard.transactionPage.stop', 'Stop')}
        </Button>
      )}
      {!transaction.ocppTransactionId && !hasEnded(transaction) && (
        <Button type="primary" block={true} loading={actLoading} onClick={() => handleStartTransaction(transaction)}>
          {renderFormatMessage('dashboard.transactionPage.start', 'Start')}
        </Button>
      )}
      {errorTransaction(transaction) && (
        <Button
          type="ghost"
          block={true}
          danger
          loading={actLoading}
          onClick={() => {
            setActLoading(true)
            handleErrorTransaction(transaction)
          }}
          style={{ marginBottom: '5px' }}
        >
          {renderFormatMessage('dashboard.text.error', 'Error')}
        </Button>
      )}
    </Space>
  )

  return (
    <Collapse>
      {transactions.map((transaction) => (
        <Panel
          key={transaction.id}
          extra={<TransactionStateBadge transaction={transaction} />}
          header={`${trasactionIDHeading}: ${transaction.id}`}
        >
          <List
            loading={loading}
            dataSource={[transaction]}
            renderItem={(transaction) => {
              const {
                percent,
                startMethod,
                startAt,
                endAt,
                chargeStartAt,
                chargeEndAt,
                timezone,
                duration,
                energy,
                financial,
                user,
                listing,
                tenant,
              } = transaction

              return (
                <>
                  <List.Item actions={[startMethod]}>
                    <List.Item.Meta
                      title={renderFormatMessage('dashboard.transactionPage.startmethod', 'Start Method')}
                    />
                  </List.Item>
                  <List.Item actions={[user.name]}>
                    <List.Item.Meta title={renderFormatMessage('dashboard.transactionPage.user', 'User')} />
                  </List.Item>
                  <List.Item actions={[tenant.displayName || tenant.name]}>
                    <List.Item.Meta title={renderFormatMessage('dashboard.transactionPage.tenant', 'Tenant')} />
                  </List.Item>
                  <List.Item actions={[listing.title]}>
                    <List.Item.Meta title={renderFormatMessage('dashboard.text.listing', 'Listing')} />
                  </List.Item>
                  <List.Item actions={[percent]}>
                    <List.Item.Meta
                      title={renderFormatMessage(
                        'dashboard.transactionsPage.table.nestedTable.heading.battery',
                        'Battery Percent (%)',
                      )}
                    />
                  </List.Item>
                  <List.Item actions={[energy ? formatEnergy(energy / 1000) : '---']}>
                    <List.Item.Meta
                      title={renderFormatMessage('dashboard.transactionsPage.table.energy', 'Energy (kWh)')}
                    />
                  </List.Item>
                  <List.Item style={{ borderBottom: `1px solid ${theme.colors.grey4}` }}>
                    <HeadingWrapper>
                      <List.Item.Meta
                        title={renderFormatMessage(
                          'dashboard.transactionsPage.table.nestedTable.heading.chargingStats',
                          'Charging Stats',
                        )}
                      />
                    </HeadingWrapper>
                  </List.Item>
                  <Card size="small" bordered={false}>
                    <>
                      <List.Item actions={[<DateTime date={startAt} timezone={timezone} />]}>
                        <List.Item.Meta
                          title={renderFormatMessage('dashboard.transactionsPage.table.connectedAt', 'Connected At')}
                        />
                      </List.Item>
                      <List.Item actions={[endAt ? <DateTime date={endAt} timezone={timezone} /> : '---']}>
                        <List.Item.Meta
                          title={renderFormatMessage(
                            'dashboard.transactionsPage.table.disconnectedAt',
                            'Disconnected At',
                          )}
                        />
                      </List.Item>
                      <List.Item
                        actions={[!endAt ? '---' : <span>{calculateDuration(moment(startAt), moment(endAt))}</span>]}
                      >
                        <List.Item.Meta
                          title={renderFormatMessage(
                            'dashboard.transactionsPage.table.plugInDuration',
                            'Plug-in Duration',
                          )}
                        />
                      </List.Item>
                      <List.Item
                        actions={[
                          chargeStartAt ? <DateTime date={moment(chargeStartAt)} timezone={timezone} /> : '---',
                        ]}
                      >
                        <List.Item.Meta
                          title={renderFormatMessage('dashboard.transactionPage.chargeStartAt', 'Charged At')}
                        />
                      </List.Item>
                      <List.Item
                        actions={[
                          chargeEndAt && chargeStartAt && <DateTime date={moment(chargeEndAt)} timezone={timezone} />,
                        ]}
                      >
                        <List.Item.Meta
                          title={renderFormatMessage(
                            'dashboard.transactionsPage.table.nestedTable.heading.endChargedAt',
                            'End Charging At',
                          )}
                        />
                      </List.Item>

                      <List.Item
                        actions={[
                          duration === 0 || !chargeStartAt ? (
                            '---'
                          ) : (
                            <span>{formatDuration(moment.duration(duration, 'seconds'))}</span>
                          ),
                        ]}
                      >
                        <List.Item.Meta
                          title={renderFormatMessage(
                            'dashboard.transactionsPage.table.nestedTable.heading.chargingDuration',
                            'Charging Duration',
                          )}
                        />
                      </List.Item>
                    </>
                  </Card>

                  <List.Item style={{ borderBottom: `1px solid ${theme.colors.grey4}` }}>
                    <HeadingWrapper>
                      <List.Item.Meta title={renderFormatMessage('dashboard.text.cost', 'Cost')} />
                    </HeadingWrapper>
                  </List.Item>
                  <Card size="small" bordered={false}>
                    <List.Item actions={[formatPrice(financial.subtotal / 100)]}>
                      <List.Item.Meta
                        title={renderFormatMessage(
                          'dashboard.transactionsPage.table.nestedTable.heading.subtotal',
                          'Sub-total',
                        )}
                      />
                    </List.Item>
                    <List.Item actions={[formatPrice(financial.tax / 100)]}>
                      <List.Item.Meta
                        title={renderFormatMessage('dashboard.transactionsPage.table.nestedTable.heading.tax', 'Tax')}
                      />
                    </List.Item>
                    <List.Item actions={[formatPrice(financial.tenantAmount / 100)]}>
                      <List.Item.Meta
                        title={renderFormatMessage(
                          'dashboard.transactionsPage.table.nestedTable.heading.seller',
                          'Seller',
                        )}
                      />
                    </List.Item>
                    <List.Item
                      actions={[
                        financial.total && financial.total !== 0
                          ? formatPrice(financial.total / 100)
                          : formatPrice(transaction.financial.total),
                      ]}
                    >
                      <List.Item.Meta title={renderFormatMessage('dashboard.transactionsPage.table.total', 'Total')} />
                    </List.Item>
                  </Card>
                  {currentUser?.role === 'admin' ? (
                    <List.Item actions={[actionButtons(transaction)]}>
                      <List.Item.Meta title={renderFormatMessage('dashboard.text.action', 'Action')} />
                    </List.Item>
                  ) : null}
                </>
              )
            }}
          />
        </Panel>
      ))}
    </Collapse>
  )
}
