import { log } from '../../logger'
import { returnMock } from './mock'
import { apiClient } from './client'
import configProvider from '../../config'
import { addQueryString } from '../../helpers/url'

import { TenantRef } from '../../models/tenant'
import { ListingOption } from '../../models/listing'
import { TenantPermission } from '../../models/user'
import { ChargerRef } from '../../models/charger'
import { ConnectorLogsResponse, ConnectorsResponse } from '../../models/http'
import { mockConnectorLogsResp, mockConnectors, mockConnectorsResp } from '../../mock/connector-mock'
import { Connector, ConnectorRef, ConnectorServiceStatus, ConnectorStatus } from '../../models/connector'

export async function GetConnectors(
  page: number,
  listings?: ListingOption[],
  defaultTenant?: TenantRef,
  tenants?: TenantRef[],
  charger?: ChargerRef,
  vendors?: string[],
  serviceStatuses?: ConnectorServiceStatus[],
  OCPPstatuses?: ConnectorStatus[],
  selectedCountries?: string[],
  selectedProvinces?: string[],
): Promise<ConnectorsResponse> {
  let url = '/connectors'
  let queryParams: { [key: string]: string } = {}

  if (defaultTenant) {
    queryParams['tenant_id'] = `${defaultTenant.id}`
  }

  if (tenants && tenants.length > 0) {
    queryParams['tenant_id'] = `${tenants.map((t) => t.id)}`
  }

  if (charger) {
    queryParams['charger_id'] = `${charger.id}`
  }

  if (vendors && vendors.length !== 0) {
    queryParams['vendors'] = vendors.join(',')
  }

  if (listings && listings.length !== 0) {
    queryParams['listing_id'] = `${listings.map((l) => l.value)}`
  }

  if (page) {
    queryParams['page'] = page.toString()
  }

  if (serviceStatuses && serviceStatuses.length !== 0) {
    queryParams['service_statuses'] = serviceStatuses.join(',')
  }

  if (OCPPstatuses && OCPPstatuses.length !== 0) {
    queryParams['ocpp_statuses'] = OCPPstatuses.join(',')
  }

  if (selectedCountries && selectedCountries.length > 0) {
    queryParams['country'] = `${selectedCountries}`
  }

  if (selectedProvinces && selectedProvinces.length > 0) {
    queryParams['province'] = `${selectedProvinces}`
  }

  url = addQueryString(url, queryParams)
  log('fetching chargers', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockConnectorsResp(defaultTenant, OCPPstatuses, serviceStatuses, charger, vendors, page))
  }
  return apiClient(url, {})
}

export async function GetConnectorLogs(
  connector: ConnectorRef,
  page: number,
  defaultTenant?: TenantPermission,
): Promise<ConnectorLogsResponse> {
  let url = `/connectors/${connector.id}/logs`
  let queryParams: { [key: string]: string } = {
    page: `${page}`,
  }
  if (defaultTenant) {
    queryParams['tenant_id'] = `${defaultTenant.id}`
  }
  url = addQueryString(url, queryParams)
  log('fetching chargers', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockConnectorLogsResp)
  }
  return apiClient(url, {})
}

export async function FindConnectors(charger: ChargerRef): Promise<Connector[]> {
  let url = '/connectors/find'
  let queryParams: { [key: string]: string } = {
    charger_id: `${charger.id}`,
  }

  url = addQueryString(url, queryParams)
  log('fetching chargers', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockConnectors)
  }
  return apiClient(url, {})
}
