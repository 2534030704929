import moment, { duration } from 'moment'

export const calculateDuration = (start: moment.Moment, end: moment.Moment) => {
  const result = durationDiff(start, end)

  return formatDuration(result)
}

export const durationDiff = (start: moment.Moment, end: moment.Moment) => {
  return duration(end.diff(start))
}

export const chargingDuration = (start: moment.Moment, duration: number) => {
  const end = start.add(duration, 'seconds')
  return calculateDuration(start, end)
}

export const formatDuration = (duration: moment.Duration) => {
  // TODO: use destructuring objects!!!
  const months = duration.months()
  const days = duration.days()
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  if (months !== 0 && days >= 1) {
    return `${months} month ${days} day${days === 1 ? '' : 's'}`
  } else if (months !== 0 && days === 0) {
    return `${months} month${months === 1 ? '' : `s`}`
  } else if (months === 0 && days >= 1) {
    return `${days} day${days === 1 ? '' : `s`} ${hours}h ${minutes}m`
  } else {
    return `${hours}h ${minutes}m ${seconds}s`
  }
}

export const timeConvert = (n: number) => {
  const num = n
  const hours = num / 60
  const rhours = Math.floor(hours)
  const minutes = (hours - rhours) * 60
  const rminutes = Math.round(minutes)
  if (rhours !== 0 && rminutes === 0) {
    return `${rhours} hour`
  } else if (rhours >= 2) {
    return `${rhours} hours`
  } else if (rhours !== 0 && rminutes === 1) {
    return rhours + ' hour and ' + rminutes + ' minute'
  } else if (rhours !== 0 && rminutes >= 2) {
    return rhours + ' hour and ' + rminutes + ' minutes'
  } else if (rhours === 0 && rminutes >= 2) {
    return `${rminutes} minutes`
  } else {
    return `${rminutes} minute`
  }
}

