import { Statistic } from 'antd'

interface props {
  value?: string | number
  precision?: number
  prefix?: string | JSX.Element
  valueStyle?: React.CSSProperties
}

const StatisticValue: React.FC<props> = ({ value, precision, prefix, valueStyle }) => {
  return <Statistic value={value} precision={precision} prefix={prefix} valueStyle={valueStyle} />
}

export default StatisticValue
