import { Button, Form, PageHeader, Skeleton } from 'antd'
import { Box } from '../../atom/box'
import { FormContainer } from '../../atom/form/form-layout'

import { formLayout, oneButtonLayout } from '../../atom/form/page-layout'

import { AlertError } from '../../components/error'
import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { ListingAccessVisibility } from '../../components/listings/listing-access-visibility'
import { ListingAuthorV2 } from '../../components/listings/listing-author-v2'
import { ListingBasic } from '../../components/listings/listing-basic'
import { ListingChargerV2 } from '../../components/listings/listing-charger-v2'
import { ListingCommissionFee } from '../../components/listings/listing-commission-fee'
import { ListingLoitering } from '../../components/listings/listing-loitering'
import { ListingPrice } from '../../components/listings/listing-price'

import { renderFormatMessage } from '../../helpers/intl'

import { useListing } from '../../hooks/useListing'
import { useAppState } from '../../state'
import { ListingPeakShaving } from '../../components/listings/listing-peak-shaving'

const ListingBasePage: React.FC = () => {
  document.querySelector('body')?.classList.remove('redesignActive')
  const { loading, error, listing, initialValues, form, onFinish } = useListing()

  const { currentUser } = useAppState()

  const peakShavingPageAccessEmails = [
    'suleman.rai@swtchenergy.com',
    'jing.guo@swtchenergy.com',
    'alton.yeung@swtchenergy.com',
    'nicole.politis@swtchenergy.com',
    'michael.vu@swtchenergy.com',
    'sangit@peakpowerenergy.com',
    'aryan.sharma@peakpowerenergy.com',
    'sogand.okhovatian@peakpowerenergy.com',
    'ben.delevi@peakpowerenergy.com',
  ]
  return (
    <>
      <PageHeader title={listing?.title ? `Edit ${listing?.title}` : ''} />
      <AlertError error={error} />

      <Box padding>
        <Skeleton loading={loading}>
          <FormContainer>
            <Form
              {...formLayout}
              form={form}
              initialValues={initialValues}
              onFinish={onFinish}
              scrollToFirstError={true}
            >
              <ListingBasic form={form} />
              <ListingAuthorV2
                form={form}
                listingAuthor={listing?.person}
                tenant={listing?.tenant}
                stripeAccount={listing?.stripeAccount}
              />
              <ListingAccessVisibility form={form} />
              <ListingChargerV2 form={form} />
              <ListingPrice form={form} isTimeOfDayPricingEnabled={listing?.enableTimeOfDayPricing} />
              <ListingCommissionFee form={form} />
              <ListingLoitering form={form} />
              {currentUser && peakShavingPageAccessEmails.includes(currentUser.email) && (
                <ListingPeakShaving form={form} />
              )}
              <Form.Item {...oneButtonLayout}>
                <Button type="primary" block={true} htmlType="submit">
                  {renderFormatMessage('dashboard.createNewListing.submitBtn', 'Submit')}
                </Button>
              </Form.Item>
            </Form>
          </FormContainer>
        </Skeleton>
      </Box>
    </>
  )
}

export const ListingPageV2 = withAuthenticatedLayout(ListingBasePage)
