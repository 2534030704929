import { Modal, Table } from 'antd'

import { closeSVG } from '../../../assets/svg/close'
import { formatCentsPrice } from '../../../helpers/number-format'
import { assemblyTimeOfDayTable, resetTimeSlot } from '../../../helpers/time-range'
import { columns } from './TimeOfDayItem'

interface props {
  title: string
  isModalOpen: boolean
  priceType: string
  onModalOpenClose: () => void
  timeOfDayPriceWeekend: number[]
  timeOfDayPriceWeekday: number[]
  flatFee?: number
}

const TimeOfDayModal: React.FC<props> = ({
  title,
  isModalOpen,
  priceType,
  onModalOpenClose,
  timeOfDayPriceWeekday,
  timeOfDayPriceWeekend,
  flatFee,
}) => {
  assemblyTimeOfDayTable(timeOfDayPriceWeekday, priceType, 'weekdays')
  const timeOfDayPriceModified = assemblyTimeOfDayTable(timeOfDayPriceWeekend, priceType, 'weekends')

  const handleClose = () => {
    resetTimeSlot()
    return onModalOpenClose()
  }

  return (
    <Modal
      key={`${timeOfDayPriceWeekday[0]}-${title}`}
      title={`${title}'s Time of Day Pricing`}
      width={540}
      visible={isModalOpen}
      footer={flatFee ? `NOTE: A ${formatCentsPrice(flatFee)} flat fee is applied` : null}
      onOk={handleClose}
      onCancel={handleClose}
      afterClose={handleClose}
      bodyStyle={{ padding: 0 }}
      closeIcon={closeSVG}
      style={{ boxShadow: '40px rgba(0, 0, 0, 0.12)', borderRadius: '50px' }}
    >
      <Table rowKey="uid" bordered dataSource={timeOfDayPriceModified || []} columns={columns} pagination={false} />
    </Modal>
  )
}

export default TimeOfDayModal
