import { useEffect, useState } from 'react'
import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { PeakShavingEvent, PeakShavingProgram } from '../../models/peak-shaving'
import { GetAllPeakShavingEvents } from '../../services/data-provider/peak-shaving'
import { SwtchError } from '../../models/error'
import { Button, PageHeader, Space, Spin, Table } from 'antd'
import { Link } from 'react-router-dom'
import { Links } from '../../components/routes/paths'
import { CreateNewEventModal } from './create-new-event-modal'
import { AlertError } from '../../components/error'
import { PaginationMeta } from '../../models/pagination'
import { PeakShavingProgramSelector } from '../../components/selector/peak-shaving-program-selector'
import { PeakShavingEventFilter } from '../../models/filter'
import _ from 'lodash'
import { useAppState } from '../../state'

export const peakPowerEnergyFolks = [
  'sangit@peakpowerenergy.com',
  'aryan.sharma@peakpowerenergy.com',
  'sogand.okhovatian@peakpowerenergy.com',
  'ben.delevi@peakpowerenergy.com',
  'suleman.rai@swtchenergy.com',
  'michael.vu@swtchenergy.com',
]

const PeakShavingEventsBasePage: React.FC = () => {
  const [peakShavingEvents, setPeakShavingEvents] = useState<PeakShavingEvent[]>([])
  const [eventModalVisible, setEventModalVisible] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState<PaginationMeta>()
  const [filter, setFilter] = useState<PeakShavingEventFilter>({ page: 1, perPage: 20 })
  const [term, setTerm] = useState(`${_.random(Number.MIN_VALUE, Number.MAX_VALUE)}`)
  const { currentUser } = useAppState()

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      render: (id: number) => {
        return <Link to={Links.peakShavingEvent({ peakShavingEventId: id })}>{id}</Link>
      },
    },
    {
      title: 'Admin Person Id',
      dataIndex: 'adminPersonId',
    },
    {
      title: 'Program',
      dataIndex: 'program',
      render: (program: PeakShavingProgram) => {
        return <Link to={Links.peakShavingProgram({ programId: `${program.id}` })}>{program.name}</Link>
      },
    },
    {
      title: 'Allow Opt Out',
      dataIndex: 'allowOptOut',
      render: (allowOptOut: boolean) => {
        return allowOptOut ? 'Yes' : 'No'
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
    },

    {
      title: 'Notes',
      dataIndex: 'notes',
    },
    {
      title: 'State',
      dataIndex: 'state',
    },
    {
      title: 'Source',
      dataIndex: 'source',
    },
    {
      title: 'System Generated',
      dataIndex: 'systemGenerated',
      render: (systemGenerated: boolean) => {
        return systemGenerated ? 'System' : 'User'
      },
    },
  ]

  const getAllPeakShavingEvents = () => {
    setLoading(true)
    if (currentUser && peakPowerEnergyFolks.includes(currentUser?.email)) {
      filter.programId = 1
    }
    GetAllPeakShavingEvents(filter)
      .then(({ data, pagination }) => {
        setPeakShavingEvents(data)
        setPagination(pagination)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    document.querySelector('body')?.classList.add('redesignActive', 'peakShavingEvents')
    getAllPeakShavingEvents()
  }, [filter, term])

  const onPaginationChange = (page: number, pageSize?: number) => {
    setFilter({ ...filter, page })
  }

  return (
    <>
      <Spin spinning={loading}>
        <AlertError error={error} />
        <PageHeader
          title="Events"
          extra={
            <Space>
              <PeakShavingProgramSelector
                onProgramSelected={(l) => setFilter({ ...filter, programId: l.id })}
                onClear={() => setFilter({ ...filter, programId: undefined, programName: '' })}
              />
              <Button type="primary" onClick={() => setEventModalVisible(!eventModalVisible)}>
                Create New Event
              </Button>
            </Space>
          }
        />
        <Table
          columns={columns}
          dataSource={peakShavingEvents}
          loading={loading}
          rowKey="id"
          pagination={{
            position: ['bottomCenter'],
            total: pagination?.totalEntries,
            current: pagination?.currentPage,
            pageSize: pagination?.perPage,
            showSizeChanger: false,
            onChange: onPaginationChange,
          }}
        />

        <CreateNewEventModal
          visible={eventModalVisible}
          onCancel={() => setEventModalVisible(!eventModalVisible)}
          onSubmit={() => {
            setEventModalVisible(!eventModalVisible)
            setTerm(`${_.random(Number.MIN_VALUE, Number.MAX_VALUE)}`)
          }}
        />
      </Spin>
    </>
  )
}

export const PeakShavingEventsPage = withAuthenticatedLayout(PeakShavingEventsBasePage)
