import { ListingAccess } from '../../../models/listing'
import { useAppState } from '../../../state'

interface props {
  permission: ListingAccess
}

const AccessPermission: React.FC<props> = ({ permission }) => {
  const { IsDesktop, IsTablet, IsLaptop, IsMobile } = useAppState()

  const isMobile = IsMobile()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()

  const isSmallScreen = isMobile || isTablet ? 'flex-right' : ''
  const isLargeScreen = isLaptop || isDesktop ? 'flex-and-gap-below' : ''

  return (
    <>
      {!permission.hidden && permission.accessType === 'public' && isOpenForPublic(isSmallScreen, isLargeScreen)}

      {permission.hidden &&
        permission.accessType === 'public' &&
        isOpenButHiddenFromPublic(isSmallScreen, isLargeScreen)}

      {permission.accessType === 'private' && isPrivateWithCode(permission, isSmallScreen, isLargeScreen)}
    </>
  )
}

const isOpenForPublic = (isSmallScreen: string, isLargeScreen: string) => (
  <div className={isLargeScreen}>
    <div className={`${isSmallScreen} paragraph-02-regular`}>Open</div>
    <span className="opacity-06 paragraph-04-light">Visbile On Map</span>
  </div>
)

const isOpenButHiddenFromPublic = (isSmallScreen: string, isLargeScreen: string) => (
  <div className={isLargeScreen}>
    <div className={`${isSmallScreen} paragraph-02-regular`}>Open</div>
    <div className="opacity-06 paragraph-04-light">Hidden From Map</div>
  </div>
)

const isPrivateWithCode = (permission: ListingAccess, isSmallScreen: string, isLargeScreen: string) => (
  <div className={isLargeScreen}>
    <div className={`${isSmallScreen} paragraph-02-regular`}>Restricted</div>
    <div className="opacity-06 paragraph-04-light">Code: {permission.accessCode}</div>
  </div>
)

export default AccessPermission
