import React, { ReactElement, useEffect, useState } from 'react'
import { Button, Checkbox, Form, Modal, Select, Switch, Tooltip, message } from 'antd'
import { SwtchError } from '../../models/error'
import { TenantRef } from '../../models/tenant'
import { User, UserInviteInfo, UserPermissions, UserRef, userInviteInfo } from '../../models/user'
import { NewInviteUser } from '../../services/data-provider/users'
import { theme } from '../../theme'
import { FindTenants } from '../../services/data-provider/tenants'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { cancelSVG } from '../../assets/svg/cancel'
import { addSVG } from '../../assets/svg/add'
import { sendSVG } from '../../assets/svg/send'
import { AlertError } from '../../components/error'
import {
  AddMoreWrapper,
  Body,
  ButtonTextSpacing,
  DropdownWrapper,
  FooterLayout,
  Heading,
  HeadingDescription,
  SvgSpacing,
  SwitchLabel,
  TenantInputBody,
  UnselectedDropdownWrapper,
} from '../../atom/user-invite'
import { useUserTranslation } from '../../hooks/translation/useUserTranslation'
import UserEmail from './modal/user-email'
import { RolesDropdownWrapper, RolesSelectWrapper } from '../../atom/user-edit'

const { Option } = Select

interface props {
  onCancel: (e: React.FormEvent<EventTarget>) => void
  onUserInvited: (user: User) => void
}

export const UserInvitationRedesign: React.FC<props> = ({ onCancel, onUserInvited }) => {
  const [form] = Form.useForm()
  const [error, setError] = useState<SwtchError>()
  const [selectedUsers, setSelectedUsers] = useState<UserInviteInfo[]>([])
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [tenantInputs, setTenantInputs] = useState(1)
  const [tenantDropdownData, setTenantDropdownData] = useState({ 1: [...tenants] })
  const [loading, setLoading] = useState(false)
  const [validateStatus, setValidateStatus] = useState<string>('')
  const [disableSendInviteButton, setDisableSendInviteButton] = useState(true)
  const [userPermissions, setUserPermissions] = useState<UserPermissions[]>([
    { name: '', id: '', access: '', driver: false },
  ])
  const {
    heading,
    headingInfo,
    role2Text,
    tenantsInviteText,
    driverInviteText,
    addMoreInviteText,
    tooltipInviteText,
    managerInviteText,
    viewerInviteText,
    sendInvite,
    cancelInviteText,
  } = useUserTranslation()

  useEffect(() => {
    FindTenants()
      .then((tenants) => {
        const tenantsArr: TenantRef[] = []
        tenants.map((tenant) => {
          if (tenant.name) tenantsArr.push(tenant)
          return tenantsArr
        })
        setTenants([...tenantsArr])
        setTenantDropdownData({ 1: [...tenantsArr] })
      })
      .catch((err: SwtchError) => {
        setTenants([])
        message.error(err.description)
        setError(err)
      })
  }, [])

  useEffect(() => {
    let disable = false
    userPermissions.map((permission) => {
      if (permission.name === '' || permission.access === '' || permission.id === '') {
        disable = true
      }
      return disable
    })
    if (disable) {
      setDisableSendInviteButton(true)
    } else {
      setDisableSendInviteButton(false)
    }
  }, [userPermissions])

  useEffect(() => {
    form.validateFields(['email'])
  }, [form.getFieldValue('email')])

  const afterClose = () => {
    form.resetFields()
  }

  const onOk = () => {
    if (userPermissions[0].name === '') {
      return
    }
    const tempSelectedUsersArr: UserRef[] = []
    selectedUsers.map((user) => {
      const tempSelectedUsersObj = {
        id: user.id,
        email: user.email,
        name: user.name,
      }
      return tempSelectedUsersArr.push(tempSelectedUsersObj)
    })
    NewInviteUser(userPermissions, tempSelectedUsersArr)
      .then((user) => {
        message.success(`Invited users`)
        return onUserInvited(user)
      })
      .catch((error) => {
        setError(error)
      })
      .finally(() => setLoading(false))
  }

  const handleTenantChange = (value: string, index: number) => {
    const tempData = userPermissions
    let id = ''
    tenantDropdownData[1].map((tenant: any) => {
      if (tenant.name === value) {
        return (id = tenant.id)
      } else return null
    })
    tempData[index] = { name: value, id: id, access: tempData[index].access, driver: tempData[index].driver }
    setUserPermissions([...tempData])
  }

  const handleRoleChange = (value: string, index: number) => {
    const tempData = userPermissions
    tempData[index] = {
      name: tempData[index].name,
      id: tempData[index].id,
      access: value,
      driver: tempData[index].driver,
    }
    setUserPermissions([...tempData])
  }

  const handleDriverChange = (index: number) => {
    const tempData = userPermissions
    tempData[index] = {
      name: tempData[index].name,
      id: tempData[index].id,
      access: tempData[index].access,
      driver: !tempData[index].driver,
    }
    setUserPermissions([...tempData])
  }

  const handleClearTenant = (index: number) => {
    const tempData = userPermissions
    tempData[index] = { name: '', id: '', access: tempData[index].access, driver: tempData[index].driver }
    setUserPermissions([...tempData])
  }

  const renderOption = (tenant: TenantRef, index: any): ReactElement => {
    if (userPermissions[index].name === tenant.name) {
      return (
        <Option style={{ width: '100%', padding: 0 }} key={index} value={tenant.name}>
          <DropdownWrapper>
            <Checkbox
              style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
              checked={userPermissions[index].name === tenant.name}
            >
              {tenant.name}
              <Button type="link" onClick={() => handleClearTenant(index)}>
                {cancelSVG}
              </Button>
            </Checkbox>
          </DropdownWrapper>
        </Option>
      )
    } else {
      return (
        <Option style={{ marginTop: '10px' }} key={tenant.id} value={tenant.name}>
          <UnselectedDropdownWrapper>
            <Checkbox checked={userPermissions[index].name === tenant.name}>{tenant.name}</Checkbox>
          </UnselectedDropdownWrapper>
        </Option>
      )
    }
  }

  return (
    <>
      <AlertError error={error} />
      <Modal
        title={
          <>
            <Heading>{heading}</Heading>
            <HeadingDescription>{headingInfo}</HeadingDescription>
          </>
        }
        closable={true}
        visible={true}
        afterClose={afterClose}
        onCancel={onCancel}
        onOk={onOk}
        width={540}
        footer={
          <FooterLayout disabled={disableSendInviteButton}>
            <Button style={{ width: '240px' }} onClick={onCancel}>
              <span className="paragraph-02-regular">{cancelInviteText}</span>
            </Button>
            <Button style={{ width: '240px' }} disabled={disableSendInviteButton} type="primary" onClick={onOk}>
              <ButtonTextSpacing>
                <span className="paragraph-02-regular">{sendInvite}</span>
              </ButtonTextSpacing>
              {sendSVG}
            </Button>
          </FooterLayout>
        }
      >
        <Form form={form} labelAlign="right" layout="vertical">
          <UserEmail
            selectedUsers={selectedUsers}
            validateStatus={validateStatus}
            loading={loading}
            setValidateStatus={setValidateStatus}
            setSelectedUsers={setSelectedUsers}
            setLoading={setLoading}
          />
          {selectedUsers.length > 0 && (
            <>
              <hr
                style={{
                  border: 0,
                  borderTop: '1px solid #f0f0f0',
                  margin: '24px -24px 0px',
                }}
              />
              <TenantInputBody>
                <Body>
                  <Form.Item className="tenant-select" label={tenantsInviteText}>
                    <Select
                      dropdownMatchSelectWidth={false}
                      showSearch
                      onChange={(value) => handleTenantChange(value, 0)}
                      value={userPermissions[0].name}
                    >
                      {tenantDropdownData[1]
                        .map((v) => renderOption(v, 0))
                        .sort(
                          (a: ReactElement, b: ReactElement) =>
                            b.props.children.props.checked - a.props.children.props.checked,
                        )}
                    </Select>
                  </Form.Item>
                  <Form.Item className="role-select" key="role" label={role2Text} name={0}>
                    <RolesSelectWrapper>
                      <Select onChange={(value) => handleRoleChange(value, 0)}>
                        {userInviteInfo.map((role) => (
                          <Select.Option key={role} value={role}>
                            <RolesDropdownWrapper selected={userPermissions[0].access === role}>
                              {role === 'none' ? '-' : role === 'manager' ? managerInviteText : viewerInviteText}
                            </RolesDropdownWrapper>
                          </Select.Option>
                        ))}
                      </Select>
                    </RolesSelectWrapper>
                  </Form.Item>
                  <Form.Item
                    className="switch"
                    key="driver"
                    label={
                      <>
                        {driverInviteText}
                        <Tooltip overlayInnerStyle={{ borderRadius: '5px' }} placement="top" title={tooltipInviteText}>
                          <ExclamationCircleFilled
                            style={{ fontSize: theme.fontSizes[3], marginLeft: '5px', color: '#DEDEDE' }}
                          />
                        </Tooltip>
                      </>
                    }
                    name="driver"
                  >
                    <div>
                      <SwitchLabel>Off</SwitchLabel>
                      <Switch
                        style={{
                          marginLeft: '5px',
                          marginRight: '5px',
                          backgroundColor: userPermissions[0].driver ? '#30D7BA' : '#bfbfbf',
                        }}
                        onChange={() => handleDriverChange(0)}
                      ></Switch>
                      <SwitchLabel>On</SwitchLabel>
                    </div>
                  </Form.Item>
                </Body>
                {tenantInputs &&
                  [...Array(tenantInputs)].map((tenantInput, tenantInputIndex) => {
                    return (
                      <Body key={tenantInputIndex}>
                        {tenantInputIndex > 0 && (
                          <>
                            <Form.Item className="tenant-select">
                              <Select
                                dropdownMatchSelectWidth={false}
                                showSearch
                                onChange={(value) => handleTenantChange(value, tenantInputIndex)}
                                value={userPermissions[tenantInputIndex].name}
                              >
                                {tenantDropdownData[tenantInputIndex as keyof typeof tenantDropdownData]
                                  .map((v) => renderOption(v, tenantInputIndex))
                                  .sort(
                                    (a: ReactElement, b: ReactElement) =>
                                      b.props.children.props.checked - a.props.children.props.checked,
                                  )}
                              </Select>
                            </Form.Item>
                            <Form.Item className="role-select" key="role">
                              <RolesSelectWrapper>
                                <Select onChange={(value) => handleRoleChange(value, tenantInputIndex)}>
                                  {userInviteInfo.map((role) => (
                                    <Select.Option key={role} value={role}>
                                      <RolesDropdownWrapper
                                        selected={userPermissions[tenantInputIndex].access === role}
                                      >
                                        {role === 'none'
                                          ? '-'
                                          : role === 'manager'
                                          ? managerInviteText
                                          : viewerInviteText}
                                      </RolesDropdownWrapper>
                                    </Select.Option>
                                  ))}
                                </Select>
                              </RolesSelectWrapper>
                            </Form.Item>
                            <Form.Item className="switch" key="driver" name="driver">
                              <SwitchLabel>Off</SwitchLabel>
                              <Switch
                                style={{
                                  marginLeft: '5px',
                                  marginRight: '5px',
                                  backgroundColor: userPermissions[tenantInputIndex].driver ? '#30D7BA' : '#bfbfbf',
                                }}
                                onChange={() => handleDriverChange(tenantInputIndex)}
                              ></Switch>
                              <SwitchLabel>On</SwitchLabel>
                            </Form.Item>
                          </>
                        )}
                      </Body>
                    )
                  })}
              </TenantInputBody>
              <AddMoreWrapper>
                <Form.Item className="add-more-button">
                  <Button
                    style={{
                      color: '#30D7BA',
                      padding: 0,
                      height: 'fit-content',
                      marginBottom: '0px',
                      letterSpacing: '0.05em',
                    }}
                    type="link"
                    onClick={() => {
                      setUserPermissions([...userPermissions, { name: '', id: '', access: '', driver: false }])
                      setTenantInputs((currentTenantInputs) => currentTenantInputs + 1)
                      setTenantDropdownData({ ...tenantDropdownData, [tenantInputs + 1]: [...tenants] })
                    }}
                  >
                    <SvgSpacing>{addSVG}</SvgSpacing>
                    {addMoreInviteText}
                  </Button>
                </Form.Item>
              </AddMoreWrapper>
            </>
          )}
        </Form>
      </Modal>
    </>
  )
}
