import { theme } from '../../theme'

export const calendarSVG = (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.90625 6.42855H13.7188M3.43304 1.5V2.78586M12.0312 1.5V2.78571M14.5625 4.78571L14.5625 14.5C14.5625 15.6046 13.6671 16.5 12.5625 16.5H3.0625C1.95793 16.5 1.0625 15.6046 1.0625 14.5V4.78571C1.0625 3.68114 1.95793 2.78571 3.0625 2.78571H12.5625C13.6671 2.78571 14.5625 3.68114 14.5625 4.78571Z"
      stroke={theme.colors.darkGray}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
