import configProvider from '../config'
import { BaseLocal } from './base'

export const French: BaseLocal = {
  /*************************** General ***************************/
  'dashboard.title': 'Tableau de bord',
  'dashboard.title.admindashboard': 'Tableau de bord administrateur',
  'dashboard.title.supportdashboard': "Tableau de bord d'assistance",
  'dashboard.calendar.today': "Aujourd'hui",
  'dashboard.calendar.yesterday': 'Hier',
  'dashboard.calendar.past7days': '7 derniers jours',
  'dashboard.calendar.weektodate': 'Semaine à ce jour',
  'dashboard.calendar.monthtodate': 'Mois à ce jour',
  'dashboard.calendar.yeartodate': 'Année à ce jour',
  'dashboard.calendar.lastweek': 'La semaine dernière',
  'dashboard.calendar.lastmonth': 'Le mois dernier',
  'dashboard.calendar.lastquarter': 'Dernier quart',
  'dashboard.calendar.customdate': 'Date personnalisée',
  'dashboard.calendar.thisWeek': 'Cette semaine',
  'dashboard.calendar.thisMonth': 'Ce mois-ci',
  'dashboard.calendar.thisYear': 'Cette année',
  'dashboard.calendar.previousQuarter': 'Trimestre précédent',
  'dashboard.error.somethingwentwrong': "Quelque chose s'est mal passé",
  'dashboard.text.active': 'Actif',
  'dashboard.text.access': 'Accès',
  'dashboard.text.country': 'Pays',
  'dashboard.text.on': 'au',
  'dashboard.text.close': 'close',
  'dashboard.selected': 'choisi',
  'dashboard.button.delete': 'Supprimer',
  'dashboard.text.masterAccount': 'Compte principal',
  'dashboard.text.channelPartner': 'Partenaire de canal',
  'dashboard.text.generated': 'Généré',
  'dashboard.text.hour': 'Heure',
  'dashboard.text.by': 'Par',
  'dashboard.text.billingAddress': 'Adresse de facturation',
  'dashboard.button.create': 'Créer',
  'dashboard.button.city': 'Ville',
  'dashboard.button.country': 'Les Pays',
  'dashboard.text.currency': 'Devise',
  'dashboard.text.description': 'La description',
  'dashboard.button.subscribe': "S'abonner",
  'dashboard.button.cancel': 'Annuler',
  'dashboard.text.charger': 'Chargeur',
  'dashboard.text.search': 'Chercher',
  'dashboard.text.modify': 'Modifier',
  'dashboard.text.refresh': 'Actualiser',
  'dashboard.text.action': 'Action',
  'dashboard.text.details': 'Details',
  'dashboard.text.inactive': 'Inactif',
  'dashboard.text.interval': 'Intervalle',
  'dashbaord.text.installateur': 'Installer',
  'dashboard.text.configure': 'Configurer',
  'dashboard.text.listing': 'Référencement',
  'dashboard.text.name': 'Nom',
  'dashboard.text.note': 'Remarque',
  'dashboard.text.status': 'Statut',
  'dashboard.text.online': 'En ligne',
  'dashboard.text.offline': 'Hors ligne',
  'dashboard.text.panel': 'Panneau',
  'dashboard.text.plans': 'Des Plans',
  'dashboard.text.plan': 'Le Plan',
  'dashboard.text.postalCodeZipCode': 'Code postal',
  'dashboard.text.price': 'Prix',
  'dashboard.text.provinceState': 'Province/État',
  'dashboard.text.enable': 'Activer',
  'dashboard.text.enabled': 'Activé',
  'dashboard.text.disabled': 'Infirme',
  'dashboard.text.cancel': 'Annuler',
  'dashboard.text.apply': 'Appliquer',
  'dashboard.text.cancelled': 'Annulé',
  'dashboard.text.paused': 'En pause',
  'dashboard.text.save': 'Sauver',
  'dashboard.text.state': 'État',
  'dashboard.text.streetAddress': 'Adresse de rue',
  'dashboard.text.subscribers': 'Les Abonnés',
  'dashboard.text.subscription': 'abonnement',
  'dashboard.text.subscriptions': 'Abonnements',
  'dashboard.text.user': 'Utilisateur',
  'dashboard.text.add': 'Ajouter',
  'dashboard.text.edit': 'Éditer',
  'dashboard.text.tenant': 'Locataire',
  'dashboard.text.vendor': 'Vendeur',
  'dashboard.text.start': 'Démarrer',
  'dashboard.text.stop': 'Arrêter',
  'dashboard.text.update': 'Mise à jour',
  'dashboard.text.error': 'Erreur',
  'dashboard.text.cost': 'Le Coût',
  'dashboard.text.location': 'Emplacement',
  'dashboard.text.searchlocation': 'Emplacement de recherche',
  'dashboard.text.map&access': 'Plan & Accès',
  'dashboard.text.loiteringStatuses': 'Statuts de vagabondage',
  'dashboard.text.all': 'Tout',
  'dashboard.text.weekday': 'Jour de la semaine',
  'dashboard.text.weekend': 'Fin de semaine',
  'dashboard.text.level2': 'Niveau 2',
  'dashboard.text.noDataAvailable': 'Désolé, aucune donnée à afficher.',
  'dashboard.text.unknown': 'Inconnue',
  'dashboard.text.switchHomePage': 'Passer à la vue classique du tableau de bord',
  'dashboard.text.switchUsersPage': 'Passer à la page des utilisateurs classiques',
  'dashboard.text.switchHomePageNewVersion': 'Passer à la nouvelle vue du tableau de bord',
  'dashboard.text.switchUsersPageNewVersion': 'Passer à la page des nouveaux utilisateurs',

  /************************* Listing *************************/
  'dashboard.text.listingTitles': "Titre(s) de l'annonce",
  'dashboard.text.generalPrice': 'Prix ​​général',
  'dashboard.text.preferredPrice': 'Prix ​​préférentiel',
  'dashboard.text.smartCharging': 'Chargement intelligent',

  /************************* Loitering *************************/
  'dashboard.text.loitering': 'Rester En Arrière',

  /***************** Connector Service Status *****************/
  'dashboard.serviceStatus.awaitingCommissioning': 'En attente de mise en service',
  'dashboard.serviceStatus.decommissioned': 'Désaffectée',
  'dashboard.serviceStatus.commissioned': 'Commandé',
  'dashboard.serviceStatus.testing': 'Essai',
  'dashboard.serviceStatus.activatingPendingProperty': 'Activation - Propriété en attente',
  'dashboard.serviceStatus.onHold': 'En attente',
  'dashboard.serviceStatus.activatingPendingDriverSub': 'Activation - Abonnement du conducteur en attente',
  'dashboard.serviceStatus.other': 'Autre',
  'dashboard.serviceStatus.troubleshooting': 'Dépannage',
  'dashboard.serviceStatus.terminated': 'Résilié',

  /************************** Placeholder **************************/
  'dashboard.placeholder.masterAccount': 'Sélectionnez un compte principal',
  'dashboard.placeholder.pleaseEnterPartner': 'Veuillez saisir un partenaire',

  /*********************** General Messasge ***********************/
  'dashboard.message.positiveNumber': 'Veuillez entrer un nombre positif',
  'dashboard.placeholder.searchEmail': 'Chercher un email',

  /*************************** Navigation ***************************/
  'dashboard.navigation.dashboard': 'Tableau de bord',
  'dashboard.navigation.chargers': 'Chargeurs',
  'dashboard.navigation.transactions': 'Transactions',
  'dashboard.navigation.tenants': 'Locataires',
  'dashboard.navigation.listings': 'Annonces',
  'dashboard.navigation.analytics': 'Analytiques',
  'dashboard.navigation.reports': 'Rapports',
  'dashboard.navigation.users': 'Utilisateurs',

  /*********************** Pagination ***********************/
  'dashboard.pagination.previous': 'Précédent',
  'dashboard.pagination.next': 'Suivant',

  /*************************** Header ***************************/
  'dashboard.header': 'Tableau de bord administrateur',
  'dashboard.header.logout': 'Se déconnecter',
  'dashboard.header.chooseLanguage': 'Choisissez la langue',

  /*************************** Footer ***************************/
  'dashboard.footer.poweredby': 'Produite par',

  /************************ Export and Search Button *********************/
  'dashboard.exportbtn.heading': 'Exportation',
  'dashboard.exportbtn.title': 'Exporter des données',
  'dashboard.exportbtn.csv': 'CSV',
  'dashboard.exportbtn.pdf': 'PDF',
  'dashboard.exportbtn.info': "Choisissez d'exporter les données via PDF ou CSV. Le dossier sera envoyé par mail à",
  'dashboard.searchBtn.title': 'Chercher',
  'dashboard.search.placeholder.user': 'Rechercher un utilisateur',

  /*************************** Selector ***************************/
  'dashboard.selector.searchTenant': 'Rechercher un locataire',
  'dashboard.selector.Tenants': 'Locataire(s)',
  'dashboard.selector.searchTenants': 'Rechercher locataire(s)',
  'dashboard.selector.searchCharger': 'Rechercher un chargeur',
  'dashboard.selector.searchListing': 'Liste de recherche',
  'dashboard.selector.selectConnectorStatus': 'État du connecteur',
  'dashboard.selector.serviceStatus': 'État du service',

  /*************************** Charger ***************************/
  'dashboard.charger.phases': 'Phases électriques',
  'dashboard.charger.minAmp': 'Capacité Min.',
  'dashboard.charger.maxAmp': 'Capacité Max.',
  'dashboard.charger.fallback': 'Repli',
  'dashboard.charger.circuitSize': 'Taille du Circuit',
  'dashboard.charger.existingLoad': 'Charge Existante',
  'dashboard.charger.breakerSize': 'Taille du Disjoncteur',
  'dashboard.charger.endPoint': 'Point Final',
  'dashboard.charger.type.mixed': 'Mixte',
  'dashboard.charger.type.dedicated': 'Dédié',
  'dashboard.charger.serviceStatus.active': 'Actif',
  'dashboard.charger.serviceStatus.commissioning': 'Mise en service',
  'dashboard.charger.serviceStatus.activating': 'Activation',
  'dashboard.charger.serviceStatus.underRepair': 'En réparation',
  'dashboard.charger.serialNumber': 'Numéro de série',
  'dashboard.charger.vendor': 'Vendeur',
  'dashboard.charger.model': 'Modèle',

  /*************************** Connector ***************************/
  'dashboard.connector.connectorId': 'Identifiant du connecteur',
  'dashboard.connector.lastStatusChangedAt': 'Dernier statut modifié à',

  /*************************** Tenant Selector Page ***************************/
  'dashboard.tenantselectorpage.pagereload': 'Chargement de votre profil',
  'dashboard.tenantselectorpage.sessionexpired': 'Session expirée',
  'dashboard.tenantselectorpage.redirect': 'Redirection vers la page de connexion!',
  'dashboard.tenantselectorpage.title': "Oups quelque chose s'est mal passée",
  'dashboard.tenantselectorpage.subtitle': 'Essayer à nouveau.',
  'dashboard.tenantselectorpage.backtologin': 'Retour à la page de connexion',
  'dashboard.tenantselectorpage.tenantpermission.title': "Vous n'avez pas accès à un Locataire",
  'dashboard.tenantselectorpage.tenantpermission.subtitle': 'Veuillez contacter un administrateur',

  /*************************** Auth0 Login Page ***************************/
  'dashboard.authpage.title': "Oups quelque chose s'est mal passé",
  'dashboard.authpage.subtitle': "Nous n'avons pas pu vous connecter. Réessayez",
  'dashboard.authpage.backtologin': 'Retour à la page de connexion',
  'dashboard.authpage.error,invalidcallbackparameters': 'Paramètres de rappel non valides',

  /*************************** Home Page ***************************/
  'dashboard.homePage.home': 'Accueil',
  'dashboard.homePage.selectTenant': 'Sélectionnez un locataire',
  'dashboard.homePage.search': 'Chercher',
  'dashboard.homePage.revenue': 'Revenu',
  'dashboard.homePage.transaction': 'Transaction',
  'dashboard.homePage.transactions': 'Transactions',
  'dashboard.homePage.transactions.info.p1': 'des transactions ont commencé après 18h',
  'dashboard.homePage.transactions.info.p2': 'des transactions ont été lancées en semaine',
  'dashboard.homePage.demandResponseText': 'de la réponse à la demande',
  'dashboard.homePage.chargingText': 'Mise en charge',
  'dashboard.homePage.fromChargingText': 'de la charge',
  'dashboard.homePage.loiteringText': 'de flâner',
  'dashboard.homePage.energy': 'Énergie',
  'dashboard.homePage.energy.info.p1': 'de charge a eu lieu pendant les heures de pointe',
  'dashboard.homePage.energyConsumptions': "Consommation d'énergie (kWh)",
  'dashboard.homePage.energyConsumptions.info.header': 'Infos sur les gaz à effet de serre',
  'dashboard.homePage.energyConsumptions.info.p1':
    'Comment mes économies de gaz à effet de serre sont-elles calculées?',
  'dashboard.homePage.energyConsumptions.info.p2':
    "Consommation de carburant ICE en 2017 basée sur un véhicule moyen au Canada : 8,9 L d'essence/100 km",
  'dashboard.homePage.energyConsumptions.info.p3':
    "Émissions de CO2 eq par litre d'essence pour ICE : 2,3 kg de CO2 eq/L d'essence",
  'dashboard.homePage.energyConsumptions.info.p4':
    'Consommation de carburant des VE basés sur le parc de véhicules électriques à batterie du Canada en 2020 : 19,51 kWh/100 km',
  'dashboard.homePage.energyConsumptions.info.p5': 'Émissions de CO2 eq par kWh au Canada : 318,9 g CO2 eq/kWh',
  'dashboard.homePage.energyConsumptions.info.p6': 'Au Canada',
  'dashboard.homePage.energyConsumptions.info.okbtn': "D'accord",
  'dashboard.homePage.ghgsavings': 'Économies de GES (kg C02 eq)',
  'dashboard.homePage.ghgsavings.text': 'Économies de GES',
  'dashboard.homePage.totalConnectors': 'Nombre total de connecteurs',
  'dashboard.homePage.inuse': 'Utilisé',
  'dashboard.homePage.available': 'Disponible',
  'dashboard.homePage.unavailable': 'Indisponible',
  'dashboard.homePage.commissioning': 'Mise en service',
  'dashboard.homePage.totalActive': 'Nombre total de connecteurs actifs',
  'dashboard.homePage.activating': "En cours d'activation",
  'dashboard.homePage.underRepair': 'En cours de réparation',
  'dashboard.homePage.info.totalActive':
    "Il s'agit de tous les connecteurs des blocs Disponible, En cours d'utilisation et Non disponible",
  'dashboard.homePage.info.totalActivating':
    "Connecteurs où l'état du service est défini sur Commissionné, Activation - Propriété en attente ou Activation - Abonnement de pilote en attente",
  'dashboard.homePage.info.totalCommissioning':
    "Connecteurs où l'état du service est défini sur En attente, En attente de mise en service ou Dépannage",
  'dashboard.homePage.stationoverview.heading': 'Aperçu de la station',
  'dashboard.homePage.stationoverview.activeUsers': 'Utilisateurs actifs',
  'dashboard.homePage.stationoverview.activeUsers.idle': 'Inactif',
  'dashboard.homePage.stationOverview.activeUsers.idle.info':
    'La transaction est en cours, mais le véhicule ne charge pas activement.',
  'dashboard.homePage.stationoverview.connectorsPluggedIn': 'Connecteurs branchés',
  'dashboard.homePage.stationoverview.connectorsPluggedIn.level2connectors': 'Connecteurs de niveau 2',
  'dashboard.homePage.stationoverview.connectorsPluggedIn.dcfastconnectors': 'Connecteurs rapides CC',
  'dashboard.homePage.stationoverview.chargersParcipatingDREvents.heading':
    'Chargeurs inscrits dans la réponse à la demande',
  'dashboard.homePage.stationoverview.chargersParcipatingDREvents.level2connectors': 'Connecteurs de niveau 2',
  'dashboard.homePage.stationoverview.chargersParcipatingDREvents.dcfastconnectors': 'Connecteurs rapides CC',
  'dashboard.homePage.stationoverview.connectorAvailability': 'Disponibilité du connecteur',
  'dashboard.homePage.stationoverview.connectorAvailability.level2connectors': 'Connecteurs de niveau 2',
  'dashboard.homePage.stationoverview.connectorAvailability.dcfastconnectors': 'Connecteurs rapides CC',
  'dashboard.homePage.operationoverview.heading': 'Présentation du fonctionnement',
  'dashboard.homePage.operationoverview.timeZone.heading': 'Heure (HNE)',
  'dashboard.homePage.utilizationInsights.heading': "Informations sur l'utilisation",
  'dashboard.homePage.utilizationInsights.dial.chargingDurationText': 'Durée de charge',
  'dashboard.homePage.utilizationInsights.dial.plugInDurationText': 'Plug-in-durée',
  'dashboard.homePage.utilizationInsights.numberOfConnectorsPluggedIn': 'Nombre de connecteurs branchés',
  'dashboard.homePage.consumptionInsights.heading': 'Informations sur la consommation',
  'dashboard.homePage.consumptionInsights.uniqueUsers.heading': 'Utilisateurs uniques',
  'dashboard.homePage.consumptionInsights.avgNumberOfTransactions.heading': 'Nombre moyen de transactions',
  'dashboard.homePage.consumptionInsights.avgNumberOfTransactions.day': 'jour',
  'dashboard.homePage.consumptionInsights.averageConsumption.averageUserSpend': 'Montant moyen des transactions',
  'dashboard.homePage.consumptionInsights.averageConsumption.heading': 'Consommation énergétique moyenne',
  'dashboard.homePage.consumptionInsights.chart.title': 'Profil de charge quotidienne moyenne',
  'dashboard.homePage.stationActivation.heading': 'Activation des stations',
  'dashboard.homePage.transactionOverview.heading': 'Aperçu des transactions',
  'dashboard.homePage.seeAllTransactions.heading': 'Voir toutes les opérations',
  'dashboard.homePage.stationoverview.stations.heading': 'Gares',
  'dashboard.homePage.stationoverview.current.heading': 'Actuel',
  'dashboard.homePage.stationoverview.stations.onlinechargers.text': 'Connecteurs en ligne',
  'dashboard.homePage.stationoverview.stations.offlinechargers.text': 'Connecteurs hors ligne',
  'dashboard.homePage.stationoverview.stations.underrepairchargers.text': 'En réparation',
  'dashboard.homePage.stationoverview.stations.onlinechargers.description':
    'Fonctionnel et connexion établie permettant la surveillance et la gestion à distance',
  'dashboard.homePage.stationoverview.stations.offlinechargers.description':
    "Impossible d'établir la connexion et peut nécessiter un dépannage supplémentaire ou une escalade",
  'dashboard.homePage.stationoverview.stations.underrepairchargers.description':
    "Actuellement hors service en raison d'une maintenance pour restaurer la fonctionnalité",
  'dashboard.homePage.stationoverview.current.description1': 'Courant de chargeur actif',
  'dashboard.homePage.stationoverview.current.description2': 'Courant maximal du chargeur',
  'dashboard.homePage.stationActivation.webapp': 'Application Web',
  'dashboard.homePage.stationActivation.roamingpartner': "Partenaire d'itinérance",
  'dashboard.homePage.stationActivation.mobileapp': 'Application mobile',
  'dashboard.homePage.exportGraph.pdf': 'Exportation au format PDF',
  'dashboard.homePage.exportGraph.csv': 'Exportation au format CSV',

  /*************************** Chargers Page ***************************/
  'dashboard.chargersPage.connector.heading': 'Connecteur',
  'dashboard.chargersPage.searchTenant': 'Rechercher un locataire',
  'dashboard.chargersPage.selectVendor': 'Sélectionnez le fournisseur',
  'dashboard.chargersPage.selectStatus': 'Sélectionnez le statut',
  'dashboard.chargersPage.table.heading.connectorId': 'Identifiant du connecteur',
  'dashboard.chargersPage.table.heading.tenant': 'Locataire',
  'dashboard.chargersPage.table.heading.serviceStatus': 'État du service',
  'dashboard.chargersPage.table.heading.status': 'État du connecteur',
  'dashboard.chargersPage.modal.updatechargers': 'Mettre à jour les chargeurs',
  'dashboard.chargersPage.modal.updatechargers.selected': 'choisi',
  'dashboard.chargersPage.modal.firmware': 'Micrologiciel',
  'dashboard.chargersPage.modal.firmware.location': 'Emplacement',
  'dashboard.chargersPage.modal.firmware.location.warning': 'Veuillez saisir un emplacement!',
  'dashboard.chargersPage.modal.firmware.location.help':
    'Date après laquelle le chargeur doit avoir la (nouvelle) firmware',
  'dashboard.chargersPage.modal.firmware.retrieveDate': 'Date d`obtenation',
  'dashboard.chargersPage.modal.firmware.retrieveDate.warning': 'Veuillez obtenir une date!',
  'dashboard.chargersPage.modal.firmware.updatefirmware': 'Mise à jour du firmware',
  'dashboard.chargersPage.modal.configuration': 'Configuration',
  'dashboard.chargersPage.modal.configuration.key': 'Clé',
  'dashboard.chargersPage.modal.configuration.key.info': 'Clé de la valeur que vous souhaitez modifier',
  'dashboard.chargersPage.modal.configuration.key.warning': 'Veuillez saisir une clé ',
  'dashboard.chargersPage.modal.configuration.value': 'Valeur',
  'dashboard.chargersPage.modal.configuration.value.info': 'Valeur à modifier',
  'dashboard.chargersPage.modal.configuration.updateConfiguration': 'Mettre à jour la configuration',
  'dashboard.chargersPage.modal.configuration.updatefirmwarechanges': 'Envoi de la mise à jour du firmware pour',
  'dashboard.chargersPage.modal.configuration.trigger.heading':
    'Sélectionnez un message ci-dessous pour les chargeurs sélectionnés pour lancer un message de déclenchement',
  'dashboard.chargersPage.modal.configuration.trigger.message':
    "Envoi du message de déclenchement '123' aux chargeurs sélectionnés",

  /*************************** Charger Page ***************************/
  'dashboard.chargerPage.heading': 'Chargeur',
  'dashboard.chargerPage.allConnectors': 'Tous les connecteurs',
  'dashboard.chargerPage.noListing': 'Aucune annonce',
  'dashboard.chargerPage.chargerStatus.heading': 'État du chargeur',
  'dashboard.chargerPage.chargerStatus.energyConsumption': "Consommation d'énergie (kWh)",
  'dashboard.chargerPage.chargerStatus.revenue': 'Revenus ($)',
  'dashboard.chargerPage.graph.heading': "Revenus, transactions et consommation d'énergie",
  'dashboard.chargerPage.connectorStatus.heading': 'État du connecteur',
  'dashboard.chargerPage.connectorStatus.disconnected': 'Débranché',
  'dashboard.chargerPage.connectorStatus.disconnected.message': 'Environ -1 à -2',
  'dashboard.chargerPage.chargerStatus.offline': 'Hors ligne',
  'dashboard.chargerPage.chargerStatus.online': 'En ligne',
  'dashboard.chargerPage.chargerStatus.available': 'Disponible',
  'dashboard.chargerPage.chargerStatus.charging': 'Mise en charge',
  'dashboard.chargerPage.chargerStatus.commissioning': 'Mise en service',
  'dashboard.chargerPage.chargerStatus.commissioned': 'Commandé',
  'dashboard.chargerPage.chargerStatus.suspendedEV': 'EV suspendu',
  'dashboard.chargerPage.chargerStatus.preparing': 'En cours de préparation',
  'dashboard.chargerPage.chargerStatus.suspendedEVSE': 'EVSE suspendu',
  'dashboard.chargerPage.chargerStatus.faulted': 'En panne',
  'dashboard.chargerPage.chargerStatus.unavailable': 'Indisponible',
  'dashboard.chargerPage.chargerStatus.reserved': 'Réservé',
  'dashboard.chargerPage.chargerStatus.finishing': 'Finition',
  'dashboard.chargerPage.chargerStatus.unknown': 'Inconnue',
  'dashboard.chargerPage.tab.detail': 'Des détails',
  'dashboard.chargerPage.tab.detail.title': 'Titre',
  'dashboard.chargerPage.tab.detail.connectorid': 'Identifiant du connecteur',
  'dashboard.chargerPage.tab.detail.status': 'Statut',
  'dashboard.chargerPage.tab.detail.tenant': 'Locataire',
  'dashboard.chargerPage.tab.detail.vendor': 'Vendeur',
  'dashboard.chargerPage.tab.detail.firmwareVersion': 'Version du firmware',
  'dashboard.chargerPage.diagnosticsTab.heading': 'Diagnostique',
  'dashboard.chargerPage.diagnostics.files.requestTime.heading': 'Heure de la demande',
  'dashboard.chargerPage.diagnostics.files.state.heading': 'État',
  'dashboard.chargerPage.diagnostics.files.filename.heading': 'Nom de fichier',
  'dashboard.chargerPage.diagnostics.button.text': 'Demander un diagnostic',
  'dashboard.chargerPage.diagnostics.kickButton.text': 'Chargeur de coup de pied',
  'dashboard.chargerPage.remoteControlTab.heading': 'Télécommande',
  'dashboard.chargerPage.remoteControlTab.description': 'Sélectionnez un connecteur pour continuer',
  'dashboard.chargerPage.remoteControlTab.restartChargeBox.heading': 'Redémarrer la boîte de charge',
  'dashboard.chargerPage.remoteControlTab.restartChargeBox.p1': 'Cliquez ci-dessous pour redémarrer le',
  'dashboard.chargerPage.remoteControlTab.restartChargeBox.restartbtn': 'Redémarrage',
  'dashboard.chargerPage.remoteControlTab.restartChargeBox.chargerStarted': 'Le chargeur 1234 a été redémarré',
  'dashboard.chargerPage.remoteControlTab.restartChargeBox.chargerFailedToStart':
    "Envoi d'une demande de réinitialisation matérielle pour 1234",
  'dashboard.chargerPage.remoteControlTab.restartChargeBox.hardResetRequest':
    "Envoi d'une demande de réinitialisation matérielle pour",
  'dashboard.chargerPage.remoteControlTab.clearCache.heading': 'Vider le cache',
  'dashboard.chargerPage.remoteControlTab.clearCache.title': 'Cliquez ci-dessous pour vider le cache du chargeur',
  'dashboard.chargerPage.remoteControlTab.clearCache.btn.heading': 'Effacer le cache 1234',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.errormessage': 'Sélectionnez un type de message à envoyer',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.message':
    'Envoi du message 0000 au connecteur -1 pour le chargeur 1234',
  'dashboard.chargerPage.remoteControlTab.clearCache.request': "Envoi de la demande d'effacement du cache au",
  'dashboard.chargerPage.remoteControlTab.triggerMessage.heading': 'Message déclencheur',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.text':
    'Sélectionnez un message ci-dessous pour 1234 - connecteur -1 pour initier un message de déclenchement',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.trigger': 'Trigger',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.success':
    'La 1234 a été envoyée à 0000 - connecteur -1 pour le chargeur 1111',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.error.message.accepted': 'Accepté',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.error.message.rejected': 'Rejeté',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.error':
    "La 1234 n'a pas pu être envoyée au connecteur -1 du chargeur 1111",
  'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.error.message': 'Rejeté',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.placeholder': 'Sélectionnez un type de message',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.bootNotification': 'Notification de démarrage',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.diagnosticsStatusNotification':
    "Notification d'état de diagnostic",
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.firmwareStatusNotification':
    "Notification d'état du micrologiciel",
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.heartbeat': 'Battement de coeur',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.meterValue': 'Valeur du compteur',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.statusNotification': "Notification d'état",
  'dashboard.chargerPage.remoteControlTab.triggerMessage.btn.sendTriggerMessage': 'Envoyer un message déclencheur',
  'dashboard.chargerPage.remoteControlTab.updateFirmware.heading': 'Mise à jour du firmware',
  'dashboard.chargerPage.remoteControlTab.changeConfiguration.heading': 'Modifier la configuration',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.heading': 'Obtenir la configuration',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.text':
    'Cliquez ci-dessous pour obtenir la configuration du chargeur',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.getchargerconfiguration': 'Obtenir la configuration de 1234',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.sendchargerconfiguration':
    "Envoi de la demande d'obtention de la configuration au",
  'dashboard.chargerPage.remoteControlTab.getConfiguration.key': 'Clé',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.readonly': 'Lecture seulement',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.value': 'Valeur',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.table.callresult': "Résultat de l'appel",
  'dashboard.chargerPage.remoteControlTab.getConfiguration.table.callid': "ID d'appel",
  'dashboard.chargerPage.remoteControlTab.getConfiguration.modal.heading': 'Configuration du 1234',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.modal.configurationRetrieved':
    'Configuration du chargeur 1234 récupérée',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.modal.configurationDidNotRetrieve':
    'Impossible de récupérer la configuration du chargeur 1234',
  'dashboard.chargerPage.DREventTab.heading': 'Réponse à la demande',
  'dashboard.chargerPage.logs.chargerLogs.heading': 'Journaux du chargeur',
  'dashboard.chargerPage.logs.timestamp.heading': 'Horodatage',
  'dashboard.chargerPage.logs.heading': 'Enregistrer',
  'dashboard.chargerPage.logs.errorCode.heading': "Code d'erreur",
  'dashboard.chargerPage.logs.vendorErrorCode.heading': "Code d'erreur du fournisseur",
  'dashboard.chargerPage.listing.heading': 'Référencement',
  'dashboard.chargerPage.listing.listingTitle.heading': "Titre de l'annonce",
  'dashboard.chargerPage.listing.connectorid.heading': 'Identifiant du connecteur',
  'dashboard.chargerPage.listing.price.heading': 'Prix',
  'dashboard.chargerPage.listing.pricetype.heading': 'Type de prix',
  'dashboard.chargerPage.listing.action.heading': 'Action',
  'dashboard.chargerPage.listing.action.modifybtn': 'Modifier',
  'dashboard.chargerPage.listing.action.saveBtn': 'Sauvegarder',
  'dashboard.chargerPage.listing.action.cancelBtn': 'Annuler',

  /*************************** Transactions Page ***************************/
  'dashboard.transactionsPage.heading': 'Transactions',
  'dashboard.transactionsPage.exportbtn': 'Exportation',
  'dashboard.transactionsPage.advancedSearch': 'Recherche Avancée',
  'dashboard.transactionsPage.advancedSearchHide': 'Masquer la Recherche Avancée',
  'dashboard.transactionsPage.advancedSearch.transactionId': 'identifiant de transaction',
  'dashboard.transactionsPage.advancedSearch.username': "Nom d'utilisateur",
  'dashboard.transactionsPage.advancedSearch.useremail': "Courriel de l'utilisateur",
  'dashboard.transactionsPage.advancedSearch.useremail.warning': "L'entrée n'est pas valide E-mail!",
  'dashboard.transactionsPage.advancedSearch.ocppTransactionId': 'ID de transaction OCPP',
  'dashboard.transactionsPage.advancedSearch.startMethod': 'Méthode de démarrage',
  'dashboard.transactionsPage.advancedSearch.state': 'État',
  'dashboard.transactionsPage.advancedSearch.state.cancelled': 'annulé',
  'dashboard.transactionsPage.advancedSearch.state.confirmed': 'confirmé',
  'dashboard.transactionsPage.advancedSearch.state.errored': 'erroné',
  'dashboard.transactionsPage.advancedSearch.state.free': 'gratuit',
  'dashboard.transactionsPage.advancedSearch.state.no_reservation': 'pas de réservation',
  'dashboard.transactionsPage.advancedSearch.state.paid': 'payé',
  'dashboard.transactionsPage.advancedSearch.state.preauthorized': 'préautorisé',
  'dashboard.transactionsPage.advancedSearch.state.checked_in': 'enregistré',
  'dashboard.transactionsPage.advancedSearch.state.pending_payment': 'En attente de paiement',
  'dashboard.transactionsPage.advancedSearch.startMethod.rfid': 'rfid',
  'dashboard.transactionsPage.advancedSearch.startMethod.mobile': 'mobile',
  'dashboard.transactionsPage.advancedSearch.startMethod.website': 'site Internet',
  'dashboard.transactionsPage.advancedSearch.startMethod.location': 'Lieu',
  'dashboard.transactionsPage.totalenergy': 'Énergie totale (kWh)',
  'dashboard.transactionsPage.totalnetenergy': 'Revenu net total',
  'dashboard.transactionsPage.totalpluginduration': 'Durée totale du plug-in',
  'dashboard.transactionsPage.table.transactionId': 'Identifiant de transaction',
  'dashboard.transactionsPage.table.startMethod': 'Méthode de démarrage',
  'dashboard.transactionsPage.table.user': 'Utilisateur',
  'dashboard.transactionsPage.table.userName': "Nom d'utilisateur",
  'dashboard.transactionsPage.table.state': 'État',
  'dashboard.transactionsPage.table.state.errored': 'Erreur',
  'dashboard.transactionsPage.table.state.pendingpayment': 'En attente de paiement',
  'dashboard.transactionsPage.table.state.free': 'Libérer',
  'dashboard.transactionsPage.table.state.confirmed': 'Confirmé',
  'dashboard.transactionsPage.table.state.cancelled': 'Annulé',
  'dashboard.transactionsPage.table.state.no_reservation': 'pas de réservation',
  'dashboard.transactionsPage.table.state.paid': 'Payé',
  'dashboard.transactionsPage.table.state.preauthorized': 'préautorisé',
  'dashboard.transactionsPage.table.state.checkedin': 'Enregistré',
  'dashboard.transactionsPage.table.connectedAt': 'Connecté à',
  'dashboard.transactionsPage.table.connected': 'Connecté',
  'dashboard.transactionsPage.table.disconnected': 'Débranché',
  'dashboard.transactionsPage.table.disconnectedAt': 'Déconnecté à',
  'dashboard.transactionsPage.table.plugInDuration': 'Durée du plug-in',
  'dashboard.transactionsPage.table.energy': 'Énergie (kWh)',
  'dashboard.transactionsPage.table.total': 'Le total',
  'dashboard.transactionsPage.table.totalEnergy': 'Énergie totale',
  'dashboard.transactionsPage.table.action': 'Action',
  'dashboard.transactionsPage.table.action.error': 'Erreur',
  'dashboard.transactionsPage.table.nestedTable.heading.connectorId': 'Identifiant du connecteur',
  'dashboard.transactionsPage.table.nestedTable.heading.battery': 'Pourcentage de batterie (%)',
  'dashboard.transactionsPage.table.nestedTable.heading.chargedAt': 'Facturé à',
  'dashboard.transactionsPage.table.nestedTable.heading.endChargedAt': 'Fin de charge à',
  'dashboard.transactionsPage.table.nestedTable.heading.chargingDuration': 'Durée de charge',
  'dashboard.transactionsPage.table.nestedTable.heading.subtotal': 'Sous-total',
  'dashboard.transactionsPage.table.nestedTable.heading.tax': 'Impôt',
  'dashboard.transactionsPage.table.nestedTable.heading.seller': 'Vendeur',
  'dashboard.transactionsPage.table.nestedTable.heading.chargingStats': 'Statistiques de charge',
  'dashboard.transactionsPage.statsView.totalTransactions': 'Transactions totales',
  'dashboard.transactionsPage.statsView.totalEnergy': 'Énergie totale (kWh)',
  'dashboard.transactionsPage.statsView.totalNetRevenue': 'Revenu net total',
  'dashboard.transactionsPage.statsView.totalPlugInDuration': 'Durée totale du plug-in',
  'dashboard.transactionsPage.export.title': 'Exportation des données',
  'dashboard.transactionsPage.export.message':
    "Choisissez d'exporter les données via PDF ou CSV. Le fichier sera envoyé par courriel à",

  /*************************** Transaction Page ***************************/
  'dashboard.transactionPage.transactionId': 'identifiant de transaction',
  'dashboard.transactionPage.tenant': 'Locataire',
  'dashboard.transactionPage.startMethod': 'Méthode de démarrage',
  'dashboard.transactionPage.user': 'Utilisateur',
  'dashboard.transactionPage.batteryLevel': 'Niveau de batterie (%)',
  'dashboard.transactionPage.connectedAt': 'Connecté à',
  'dashboard.transactionPage.disconnectedAt': 'Déconnecté à',
  'dashboard.transactionPage.pluginDuraction': 'Durée du plug-in',
  'dashboard.transactionPage.energy': 'Énergie (kWh)',
  'dashboard.transactionPage.total': 'Le total',
  'dashboard.transactionPage.stop': 'Arrêter',
  'dashboard.transactionPage.start': 'Démarrer',
  'dashboard.transactionPage.error': 'Erreur',
  'dashboard.transactionPage.action': 'action',
  'dashboard.transactionPage.listing': 'Référencement',
  'dashboard.transactionPage.connectorId': 'Identifiant du connecteur',
  'dashboard.transactionPage.chargedAt': 'Facturé à',
  'dashboard.transactionPage.endChargingAt': 'Fin de charge à',
  'dashboard.transactionPage.chargingDuration': 'Durée de charge',
  'dashboard.transactionPage.subtotal': 'Sous-Total',
  'dashboard.transactionPage.tax': 'Impôt',
  'dashboard.transactionPage.seller': 'Vendeur',
  'dashboard.transactionsPage.statsView.cancel': 'Annuler',
  'dashboard.transactionsPage.statsView.error': 'Erreur',
  'dashboard.transactionsPage.statsView.total': 'Le total',

  /*************************** Transaction State Badge  ***************************/
  'dashboard.transaction.confirmed': 'Confirmé',
  'dashboard.transaction.free': 'Libérer',
  'dashboard.transaction.pending_payment': 'En attente de paiement',
  'dashboard.transaction.canceled': 'Annulé',
  'dashboard.transaction.errored': 'Erreur',
  'dashboard.transaction.paid': 'Payé',
  'dashboard.transaction.preauthorized': 'Pré autorisé',
  'dashboard.transaction.noReservation': 'Pas de réservation',

  /*************************** Tenants Page ***************************/
  'dashboard.tenantsPage.heading': 'Locataires',
  'dashboard.tenantsPage.createNewTenantBtn': 'Créer un nouveau locataire',
  'dashboard.tenantsPage.searchTenantTxt': 'Rechercher un locataire',
  'dashboard.tenantsPage.table.heading.name': 'Nom',
  'dashboard.tenantsPage.table.heading.chargerCount': 'Nombre de chargeurs',

  /******************* Individual Tenant Page ***************************/
  'dashboard.tenantPage.detailTab.heading': 'Détail',
  'dashboard.tenantPage.overviewTab.uniformServiceStatuses': 'Statuts de service uniformes',
  'dashboard.tenantPage.overviewTab.heading': 'Aperçu',
  'dashboard.tenantPage.overviewTab.channelPartner': 'Partenaire de canal',
  'dashboard.tenantPage.chargersTab.heading': 'Chargeurs',
  'dashboard.tenantPage.detailTab.accessCode.warning': "Veuillez entrer un code d'accès à 6 chiffres",
  'dashboard.tenantPage.detailTab.ocppName.heading': 'Nom OCPP',
  'dashboard.tenantPage.detailTab.ocppName.warning': 'Veuillez saisir un nom OCPP',
  'dashboard.tenantPage.detailTab.address.warning': "entrez une adresse s'il vous plaît",
  'dashboard.tenantPage.detailTab.city.warning': 'Veuillez entrer la ville',
  'dashboard.tenantPage.detailTab.displayName.heading': 'Afficher un nom',
  'dashboard.tenantPage.detailTab.address.heading': 'Adresse',
  'dashboard.tenantPage.detailTab.city.heading': 'Ville',
  'dashboard.tenantPage.detailTab.postalCode.heading': 'code postal',
  'dashboard.tenantPage.detailTab.postalCode.warning':
    "C'est un peu long non ? Veuillez vous limiter à 10 caractères ici.",
  'dashboard.tenantPage.detailTab.countryAndProvince.heading': 'Pays et province',
  'dashboard.tenantPage.detailTab.timezone': 'Fuseau horaire',
  'dashboard.tenantPage.detailTab.location': 'Emplacement',
  'dashboard.tenantPage.detailTab.open247': 'Ouvert 24h/24 et 7j/7',
  'dashboard.tenantPage.detailTab.ocpiSharing': 'Partage OCPI',
  'dashboard.tenantPage.detailTab.saveBtn': 'Sauvegarder',
  'dashboard.tenantPage.detailTab.resetbtn': 'Réinitialiser',
  'dashboard.tenantPage.detailTab.ocppNameAlreadyExists.warning': 'Le nom OCPP existe déjà',
  'dashboard.tenantPage.unSavedChanges.warning':
    'Il y a des modifications non enregistrées dans le locataire. Êtes-vous sûr de vouloir quitter?',
  'dashboard.tenantPage.postalCode.19103.info': "Un taux de taxe de 8 % s'appliquera aux chargeurs de ce code postal",
  'dashboard.tenantPage.overviewTab.heading.accessPolicy': "Politique d'accès",
  'dashboard.tenantPage.overviewTab.heading.billingPlan': 'Plan de facturation',
  'dashboard.tenantPage.contactInfo.ownerContact': 'Contact du propriétaire',
  'dashboard.tenantPage.contactInfo.ownerContact.info':
    "Si le chargeur est la propriété privée d'un résident en copropriété ou en copropriété, indiquez ici les coordonnées du propriétaire",
  'dashboard.tenantPage.contactInfo.accountContact': 'Coordonnées du compte',
  'dashboard.tenantPage.contactInfo.onsiteContact': 'Contact sur place',
  'dashboard.tenantPage.contactInfo.installerContact': "Coordonnées de l'installateur",
  'dashboard.tenantPage.contactInfo.additionalContacts': 'Contacts supplémentaires',
  'dashboard.tenantPage.contactInfo.add-additionalContacts': 'Ajouter des contacts supplémentaires',
  'dashboard.tenantPage.contactInfo.location.err': "Veuillez mettre à jour l'emplacement des locataires",
  'dashboard.tenantPage.contactInfo.firstName': 'Prénom',
  'dashboard.tenantPage.contactInfo.lastName': 'Nom de famille',
  'dashboard.tenantPage.contactInfo.title': "Titre d'emploi",
  'dashboard.tenantPage.contactInfo.modal.title': 'Ajouter un contact supplémentaire',
  'dashboard.tenantPage.contactInfo.modal.addbtn': 'Ajouter',
  'dashboard.tenantPage.contactInfo.phoneNumber': 'Numéro de téléphone',
  'dashboard.tenantPage.contactInfo.email': 'E-mail',
  'dashboard.tenantPage.contactInfo.typeofcontact': 'Type de contact',
  'dashboard.tenantPage.contactInfo.validemail.warning': 'Entrez une adresse email valide!',
  'dashboard.tenantPage.contactInfo.typeofcontactalreadyexixts.warning': 'Le type de contact existe déjà',
  'dashboard.tenantPage.contactInfo.typeofcontact.warning': 'Veuillez entrer le type de contact',
  'dashboard.tenantPage.contactInfo.propertymanagementcompany': 'Société de gestion immobilière',
  'dashboard.tenantPage.contactInfo.contactUpdated': 'Contact mis à jour',
  'dashboard.tenantPage.contactInfo.contactCreated': 'Contact créé',
  'dashboard.tenantPage.deployment.parkingstalls.heading': 'Stationnement',
  'dashboard.tenantPage.deployment.parkingstall.heading': 'Stationnement',
  'dashboard.tenantPage.deployment.loadmanagementconfiguration.heading': 'Configuration de la gestion de la charge',
  'dashboard.tenantPage.deployment.remotedesktopids.heading': 'ID de bureau à distance',
  'dashboard.tenantPage.deployment.electricalservice.heading': 'Service électrique',
  'dashboard.tenantPage.deployment.networkdevice.heading': 'Périphérique réseau',
  'dashboard.tenantPage.deployment.notes.heading': 'Remarques',
  'dashboard.tenantPage.deployment.btn.save': 'Sauver',
  'dashboard.tenantPage.deployment.btn.modify': 'Modifier',
  'dashboard.tenantPage.deployment.btn.addfield': 'Ajouter le champ',
  'dashboard.tenantPage.deployment.btn.cancel': 'Annuler',
  'dashboard.tenantPage.deployment.successmsg': 'Informations de déploiement mises à jour',
  'dashboard.tenantPage.deployment.modal.addnewparkingstall.heading':
    'Ajouter un nouveau champ de place de stationnement',
  'dashboard.tenantPage.deployment.modal.loadmanagementconfiguration.heading':
    'Ajouter un nouveau champ de configuration de gestion de la charge',
  'dashboard.tenantPage.deployment.modal.remotedesktopids.heading': 'Ajouter un nouvel ID de bureau à distance',
  'dashboard.tenantPage.deployment.field.warning': 'Le champ ne peut pas être vide !',
  'dashboard.tenantPage.overviewTab.heading.userType': "Type d'utilisateur",
  'dashboard.tenantPage.overviewTab.heading.networkinformation': 'Informations sur le réseau',
  'dashboard.tenantPage.overviewTab.heading.circuitSharingInformation': 'Informations sur le partage de circuits',
  'dashboard.tenantPage.overviewTab.saveBtn': 'Sauvegarder',
  'dashboard.tenantPage.overviewTab.modifybtn': 'Modifier',
  'dashboard.tenantPage.overviewTab.cancelBtn': 'Annuler',
  'dashboard.tenantPage.overviewTab.tenantUpdatedMessage': 'le -1 a été mis à jour !',
  'dashboard.tenantPage.overviewTab.status.heading': 'Statut',
  'dashboard.tenantPage.overviewTab.status.inprogress': 'En cours',
  'dashboard.tenantPage.overviewTab.status.commissioned': 'Commissionné',
  'dashboard.tenantPage.overviewTab.status.active': 'Actif',
  'dashboard.tenantPage.overviewTab.status.inmaintenance': 'En maintenance',
  'dashboard.tenantPage.overviewTab.status.nonNetworked': 'Non en réseau',
  'dashboard.tenantPage.overviewTab.status.testerInternal': 'Testeur – Interne',
  'dashboard.tenantPage.overviewTab.status.testerExternal': 'Testeur – Externe',
  'dashboard.tenantPage.overviewTab.status.decommissioned': 'Désaffecté',
  'dashboard.tenantPage.overviewTab.o_m_opt_in.heading': 'Opt-in O&M',
  'dashboard.tenantPage.overviewTab.o_m_opt_in.yes': 'Oui',
  'dashboard.tenantPage.overviewTab.o_m_opt_in.no': 'Non',
  'dashboard.tenantPage.overviewTab.chargerServiceStatus': 'État du service du chargeur',
  'dashboard.tenantPage.overviewTab.chargerServiceStatus.help':
    "IMPORTANT : La sélection de l'une des valeurs mettra à jour l'état de service de tous les chargeurs associés à ce locataire",
  'dashboard.tenantPage.overviewTab.connectorServiceStatus': 'État du service du connecteur',
  'dashboard.tenantPage.locationTab.uniformupdate.help':
    'IMPORTANT : Si vous cochez cette case, toutes les annonces associées à ce locataire seront masquées sur la carte.',
  'dashboard.tenantPage.location.heading': 'Lieu',
  'dashboard.tenantPage.accesscontrolsetting.heading': "Paramètre de contrôle d'accès",
  'dashboard.tenantPage.accesscontrolsetting.restricted': 'Limité',
  'dashboard.tenantPage.accesscontrolsetting.openaccess': 'Libre accès',
  'dashboard.tenantPage.accesscode.heading': "Code d'accès",
  'dashboard.tenantPage.accesspolicy.heading': "Politique d'accès",
  'dashboard.tenantPage.accesscode.option.public': 'Public',
  'dashboard.tenantPage.accesscode.option.privateindividual': 'Privé individuel',
  'dashboard.tenantPage.accesscode.option.privateshared': 'Privé Partagé',
  'dashboard.tenantPage.notes': 'Remarques',
  'dashboard.tenantPage.notes.warning': 'La limite est de 65 535 caractères',
  'dashboard.tenantPage.heading.pricing': 'Tarification',
  'dashboard.tenantPage.heading.contactInfo': 'Informations de contact',
  'dashboard.tenantPage.heading.loadManagement': 'Gestion de la charge',
  'dashboard.tenantPage.heading.deployment': 'Déploiement',
  'dashboard.tenantPage.chargersTab.listings': 'Annonces',
  'dashboard.tenantPage.chargersTab.tenantupdated': 'a été mis à jour!',
  'dashboard.tenantPage.eyedroIpAddress.heading': 'Service du bâtiment - Charge du panneau EV',
  'dashboard.tenantPage.eyedroIpAddress.powerfactor': 'Facteur de puissance',
  'dashboard.tenantPage.eyedroIpAddress.voltage': 'Tension (V)',
  'dashboard.tenantPage.eyedroIpAddress.amperage': 'Ampérage (A)',
  'dashboard.tenantPage.eyedroIpAddress.wattage': 'Puissance (W)',
  'dashboard.tenantPage.eyedroIpAddress.ipaddress': 'Adresse IP',
  'dashboard.tenantPage.error.geocodingError': "Le résultat du géocodage est à l'extérieur de la province",
  'dashboard.tenantPage.error.coordinateError': 'Impossible de trouver les coordonnées de cette adresse',
  'dashboard.tenantPage.error.countryAndProvinceError': 'Veuillez choisir un pays et une province pour ce locataire',
  'dashboard.tenantPage.error.googleLatLongError':
    'Google ne nous a pas donné de latitude et de longitude pour cette adresse',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.checkbox.text': 'Tarification uniforme',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.enabled':
    'Tarification uniforme activée pour toutes les annonces du locataire',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.disabled':
    'Tarification uniforme désactivée pour toutes les annonces du locataire',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.updated': 'tarification uniforme mise à jour !',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.info.p1':
    "S'il est activé, il indique que tous les chargeurs de ce locataire auront la même configuration de tarification.",
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.info.p2':
    'Si désactivé, les paramètres de tarification de chaque chargeur doivent être configurés individuellement',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.listing': "Auteur de l'annonce",
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.stripeAccount.text': 'Compte Stripe',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectListingAuthorText': "Sélectionnez l'auteur de la liste",
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectStripeAccountText': 'Sélectionnez un compte Stripe',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.priceCentsText': 'Prix ​​($)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.kwOutputBasedPricingText':
    'Tarification horaire basée sur le kWh',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.enableTenantPreferredPricingText':
    'Activer la tarification préférentielle du locataire',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.tenantPreferredPricingText':
    'Tarification préférentielle pour les locataires ($)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.tenantPreferredWarningText':
    'Le prix préféré ne peut pas être vide !',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.tenantPreferredPriceTypeWarningText':
    'Veuillez sélectionner un type pour le prix préféré',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.priceText': 'Prix',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.priceTypeHourText': 'Heure',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.flatFeeText': 'Frais fixes ($)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.defineNewComissionTypeText':
    'Définir un nouveau type de frais de commission',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.defineNewComissionFeeText':
    'Définir les nouveaux frais de commission (%)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.defineNewComissionTypeWarningText':
    'Les frais de commission ne peuvent pas être vides !',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.guestFeeText': "Frais d'invité ($)",
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.paymentLimitText': 'Limite de paiement ($)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText': 'Statut de vagabondage',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText.none': 'Aucun',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText.statusBased': 'Basé sur le statut',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText.timeBased': 'Basé sur le temps',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringtimeLimitText':
    'Limite de temps de flânerie basée sur le temps en minutes',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringPenaltyPerHour':
    'Pénalité de vagabondage par heure ($)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringtimeLimit.emptyfield':
    'Le champ ne peut pas être vide !',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringtimeLimitText.gracetimelimit.minutes':
    'Limite de temps de flânerie pour la période de grâce en minutes',
  'dashboard.tenantPage.uniformPricingTab.updatedtext': 'prix uniforme mis à jour !',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectComissionFeeText':
    'Sélectionnez les frais de commission',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectPreferredPricingOptionText':
    "Sélectionnez l'option de tarification préférée",
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectPricingOptionText':
    "Sélectionnez l'option de tarification",
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.saveBtn': 'Sauvegarder',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.modifybtn': 'Modifier',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.cancelBtn': 'Annuler',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.toggleUniformPricingText': 'Basculer la tarification uniforme',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.uniformPricing.text': 'Tarification uniforme',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.uniformPricing..enable.text': 'Activer',
  'dashboard.tenantPage.accessCodeuniformUpdateText': "Contrôle d'accès uniforme",
  'dashboard.tenantPage.mapSettingsuniformUpdateText': 'Paramètres de carte uniformes',
  'dashboard.tenantPage.locationTab.hideTenantFromListingsText': 'Annonces cachées sur la carte',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.heading':
    "Activer la tarification selon l'heure de la journée",
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.weekdays': 'Jours de la semaine',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.weekends': 'Fins de semaine',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.start': 'Commencer',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.end': 'Fin',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.enablePricingForTenantMembers':
    'Activer la tarification pour les membres locataires',
  'dashboard.tenantPage.enableLoitering': 'Désactiver le vagabondage pour le membre locataire',
  'dashboard.tenantPage.overviewTab.taxRegistration.heading': 'Enregistrement fiscal',
  'dashboard.tenantPage.overviewTab.taxRegistration.option.registered': 'Enregistré',
  'dashboard.tenantPage.overviewTab.taxRegistration.option.not_registered': 'Non enregistré',
  'dashboard.tenantPage.overviewTab.taxRegistration.option.joint_elected': 'Élu conjoint',

  /******************* Create Tenant Page ***************************/
  'dashboard.createnewtenantspage.heading': 'Créer un nouveau locataire',
  'dashboard.tenantPage.chargersTab.tenantcreated': 'a été créé avec succès!',

  /******************* Analytics Page *********************************/
  'dashboard.analyticsPage.heading': 'Résumé de déconnexion',
  'dashboard.analyticsPage.searchtenant': 'Rechercher un locataire',
  'dashboard.analyticsPage.searchBtn': 'Chercher',
  'dashboard.analyticsPage.table.heading.avgDisconnectDuration': 'Locataire',
  'dashboard.analyticsPage.table.heading.chargerSerialNumber': 'Numéro de série du chargeur',
  'dashboard.analyticsPage.table.heading.date': 'Date',
  'dashboard.analyticsPage.table.heading.avgDailyDisconnect': 'Déconnexion quotidienne moyenne',
  'dashboard.analyticsPage.table.heading.totalDisconnect': 'Déconnexion totale',
  'dashboard.analyticsPage.table.heading.disconnectHigh': 'Déconnexion élevée',
  'dashboardanalyticsPage.table.heading.avgDisconnectDuration': 'Durée moyenne de déconnexion',

  /******************* Users Page *********************************/
  'dashboard.usersPage.heading': 'Utilisateurs',
  'dashboard.usersPage.searchUser': 'Rechercher un utilisateur',
  'dashboard.usersPage.authorizeDriver': 'Autoriser le conducteur',
  'dashboard.usersPage.inviteUsersTo': "Inviter l'utilisateur à",
  'dashboard.usersPage.selectatenant': 'Sélectionnez un locataire',
  'dashboard.usersPage.table.heading.id': 'identifiant',
  'dashboard.usersPage.table.heading.email': 'E-mail',
  'dashboard.usersPage.table.heading.name': 'Nom',
  'dashboard.usersPage.table.heading.role': 'Rôle',
  'dashboard.usersPage.table.heading.role.user': 'Utilisateur',
  'dashboard.usersPage.table.heading.role.admin': 'Administrateur',
  'dashboard.usersPage.table.heading.role.support': 'Soutien',
  'dashboard.usersPage.table.heading.tenant': 'Accès locataire',
  'dashboard.usersPage.table.heading.action': 'Action',
  'dashboard.usersPage.table.heading.access': 'Accès',
  'dashboard.usersPage.table.heading.access.modify': 'Modifier',
  'dashboard.usersPage.table.heading.access.modify.saveBtn': 'Sauvegarder',
  'dashboard.usersPage.table.heading.access.modify.cancelBtn': 'Annuler',
  'dashboard.usersPage.table.heading.access.info': "Accès au tableau de bord de l'utilisateur pour",
  'dashboard.usersPage.table.heading.action.toggleRole': 'Basculer le rôle',
  'dashboard.usersPage.table.nestedTable.associatedTenants': 'Locataires associés',
  'dashboard.usersPage.table.nestedTable.accessLevel': "Niveau d'accès",
  'dashboard.usersPage.table.nestedTable.accessLevel.manager': 'Directeur',
  'dashboard.usersPage.table.nestedTable.accessLevel.driver': 'Conducteur',
  'dashboard.usersPage.table.nestedTable.accessLevel.driver.info':
    'Un utilisateur qui a accès à des listes privées dans',
  'dashboard.usersPage.table.nestedTable.accessLevel.viewer': 'Téléspectateur',
  'dashboard.usersPage.invitedUsersTo': 'Utilisateur invité à',
  'dashboard.usersPage.user.tenant.warning.info':
    "Remarque : L'utilisateur n'est pas associé à tous les locataires sélectionnés",
  'dashboard.usersPage.user.tenant.warning.exapandedView': "L'utilisateur n'est pas associé à ce locataire",

  /******************* Users Page Invite User Modal Form *************************/
  'dashboard.usersPage.inviteUser.heading': "Inviter l'utilisateur à",
  'dashboard.usersPage.inviteUser.heading2': 'Inviter un utilisateur',
  'dashboard.usersPage.inviteUser.headingInfo': `Invitez les utilisateurs ${configProvider.config.siteTitle} existants à accéder au tableau de bord et autorisez les conducteurs à accéder aux listes restreintes dans un locataire.`,
  'dashboard.usersPage.inviteUser.oktext': 'Inviter',
  'dashboard.usersPage.inviteUser.cancelText': 'Annuler',
  'dashboard.usersPage.inviteUser.email.heading': 'E-mail',
  'dashboard.usersPage.inviteUser.firstName.heading': 'Prénom',
  'dashboard.usersPage.inviteUser.lastName.heading': 'Nom de famille',
  'dashboard.usersPage.inviteUser.role.heading': 'Rôle',
  'dashboard.usersPage.inviteUser.email.warning': "L'e-mail est requis!",
  'dashboard.usersPage.inviteUser.email.notValid.warning': "L'e-mail n'est pas un e-mail valide!",
  'dashboard.usersPage.inviteUser.firstName.warning': 'Le prénom est requis!',
  'dashboard.usersPage.inviteUser.lastName.warning': 'Le nom de famille est requis!',
  'dashboard.usersPage.inviteUser.role.warning': 'Le rôle est requis!',
  'dashboard.usersPage.inviteUser.tenant.heading': 'Locataire',
  'dashboard.usersPage.inviteUser.tenant.warning': 'Un locataire doit être sélectionné pour le traitement',

  /******************* Users Page Edit User Name Modal Form *************************/
  'dashboard.usersPage.editUserName.heading': "Modifier le nom de l'utilisateur",
  'dashboard.usersPage.editUserName.firstName.heading': 'Prénom',
  'dashboard.usersPage.editUserName.lastName.heading': 'Nom de famille',
  'dashboard.usersPage.editUserName.cancelText': 'Annuler',
  'dashboard.usersPage.editUserName.updateText': 'Mettre à jour',
  'dashboard.usersPage.editUserName.firstName.warning': 'Le prénom est requis',
  'dashboard.usersPage.editUserName.lastName.warning': 'Le nom de famille est requis',
  'dashboard.usersPage.editUserName.firstName.maxlength.warning': 'Le prénom ne peut pas dépasser 20 caractères!',
  'dashboard.usersPage.editUserName.lastName.maxlength.warning':
    'Le nom de famille ne peut pas dépasser 20 caractères!',

  /******************* Users Page Add User To Multiple Tenants Form *************************/
  'dashboard.usersPage.selectTenants.heading': 'Ajouter un utilisateur à plusieurs locataires',
  'dashboard.usersPage.selectTenants.field': 'Sélectionnez les locataires',
  'dashboard.usersPage.selectTenants.tbl.heading': 'Ajouter un utilisateur aux locataires',
  'dashboard.usersPage.selectTenants.field.warning': 'Un locataire doit être sélectionné!',
  'dashboard.usersPage.selectTenants.addbtn': 'Ajouter',
  'dashboard.usersPage.selectTenants.adduserbtn': 'Ajouter un utilisateur',
  'dashboard.usersPage.selectTenants.successmssg': "L'utilisateur a bien été ajouté aux locataires",
  'dashboard.usersPage.selectTenants.failedmssg': "Impossible d'ajouter l'utilisateur aux locataires",

  /******************* Users Page Authorize Modal Form *************************/
  'dashboard.usersPage.authorizeModal.heading': 'Autoriser les conducteurs à accéder aux listes privées',
  'dashboard.usersPage.authorizeModal.info.heading': 'Autorisation de conducteur',
  'dashboard.usersPage.authorizeModal.info.p1': 'Les conducteurs autorisés auront accès aux listes privées au',
  'dashboard.usersPage.authorizeModal.userSearchField.userText': 'Utilisateur',
  'dashboard.usersPage.authorizeModal.userSearchField.searchUserText': 'Rechercher un utilisateur',
  'dashboard.usersPage.authorizeModal.userSearchField.searchBtn': 'Chercher',
  'dashboard.usersPage.authorizeModal.authorizeBtn': "Autoriser l'utilisateur",
  'dashboard.usersPage.authorizeModal.cancelBtn': 'Annuler',
  'dashboard.usersPage.authorizeModal.warning': "L'utilisateur a déjà accès au locataire sélectionné",
  'dashboard.usersPage.authorizeModal.noMatchFound':
    "Pas de résultat trouvé. Veuillez confirmer que l'utilisateur a crée un compte SWTCH avec l'adresse e-mail indiquée.",

  /******************* Mobile Calendar *********************************/
  'dashboard.mobileCalendar.selectDate': 'Sélectionner une date',
  'dashboard.mobileCalendar.startDate': 'Date de début',
  'dashboard.mobileCalendar.endDate': 'Date de fin',
  'dashboard.mobileCalendar.title': 'Calendrier',
  'dashboard.mobileCalendar.today': "Aujourd'hui",
  'dashboard.mobileCalendar.month': 'Mois',
  'dashboard.mobileCalendar.year': 'An',
  'dashboard.mobileCalendar.am': 'AM',
  'dashboard.mobileCalendar.pm': 'PM',
  'dashboard.mobileCalendar.dateTimeFormat': 'MM/jj/aaaa w hh:mm',
  'dashboard.mobileCalendar.dateFormat': 'aaaa/MM/jj w',
  'dashboard.mobileCalendar.nochoose': 'Non Choisir',
  'dashboard.mobileCalendar.week.sun': 'dimanche',
  'dashboard.mobileCalendar.week.mon': 'lundi',
  'dashboard.mobileCalendar.week.tue': 'mardi',
  'dashboard.mobileCalendar.week.wed': 'mercredi',
  'dashboard.mobileCalendar.week.thu': 'jeudi',
  'dashboard.mobileCalendar.week.fri': 'vendredi',
  'dashboard.mobileCalendar.week.sat': 'samedi',
  'dashboard.mobileCalendar.clear': 'Dégager',
  'dashboard.mobileCalendar.selectTime': "Sélectionnez l'heure",
  'dashboard.mobileCalendar.selectStartTime': "Sélectionnez l'heure de début",
  'dashboard.mobileCalendar.selectEndTime': "Sélectionnez l'heure de fin",
  'dashboard.mobileCalendar.start': 'Démarrer',
  'dashboard.mobileCalendar.end': 'Finir',
  'dashboard.mobileCalendar.begin': 'Démarrer',
  'dashboard.mobileCalendar.over': 'Finir',
  'dashboard.mobileCalendar.begin_over': 'S/E',
  'dashboard.mobileCalendar.confirm': 'Confirmer',
  'dashboard.mobileCalendar.monthTitle': 'aaaa/MM',
  'dashboard.mobileCalendar.loadPrevMonth': 'Charger le mois précédent',
  'dashboard.mobileCalendar.yesterday': 'Hier',
  'dashboard.mobileCalendar.lastWeek': 'La semaine dernière',
  'dashboard.mobileCalendar.lastMonth': 'Le mois dernier',

  /******************* Mobile ListView *********************************/
  'dashboard.mobilelistview.prev': 'Précédent',
  'dashboard.mobilelistview.next': 'Suivant',

  /******************* Disconnection Page *********************************/
  'dashboard.disconnectionpage.heading': 'Résumé de déconnexion',
  'dashboard.disconnectionpage.table.heading.avgDisconnectDuration': 'Locataire',
  'dashboard.disconnectionpage.table.heading.chargerSerialNumber': 'Numéro de série du chargeur',
  'dashboard.disconnectionpage.table.heading.date': 'Date',
  'dashboard.disconnectionpage.table.heading.avgDailyDisconnect': 'Déconnexion quotidienne moyenne',
  'dashboard.disconnectionpage.table.heading.totalDisconnect': 'Déconnexion totale',
  'dashboard.disconnectionpage.table.heading.disconnectHigh': 'Déconnexion élevée',
  'dashboard.disconnectionPage.table.heading.avgDisconnectDuration': 'Durée moyenne de déconnexion',
  'dashboard.disconnectionpage.table.fromHeading': 'À partir de',

  /*************************** Report Page ***************************/
  'dashboard.report.heading': 'Rapports',
  'dashboard.reports.table.heading.entity': 'Entité',
  'dashboard.reports.table.heading.entityName': 'Nom',
  'dashboard.reports.table.heading.entityType': 'Taper',
  'dashboard.reports.table.heading.dateRange': 'Date',
  'dashboard.reports.table.heading.generator': "L'auteur",
  'dashboard.reports.table.heading.download': 'Télécharger',
  'dashboard.reports.table.heading.email': 'Email',
  'dashboard.reports.table.row.download': 'Télécharger un fichier',
  'dashboard.reports.table.row.timeframe.quarterly': 'Trimestriel',
  'dashboard.reports.table.row.type.transaction': 'Transactions',
  'dashboard.reports.table.row.type.erroredTransaction': 'Transactions erronées',
  'dashboard.reports.table.row.timeframe.yearly': 'Annuel',
  'dashboard.reports.form.sendReport.emails.label': 'Emails (séparées par des virgules)',
  'dashboard.reports.form.sendReport.emails.warning': 'Les e-mails sont obligatoires!',
  'dashboard.reports.form.sendReport.emails.notValid': "L'email n'est pas un e-mail valide!",
  'dashboard.reports.form.sendReport.oktext': 'Rapport par e-mail',
  'dashboard.reports.form.sendReport.generateText': 'Générer',
  'dashboard.reports.form.sendReport.cancelText': 'Annuler',
  'dashboard.reports.form.sendReport.reportFrom': 'rapport de',
  'dashboard.reports.modal.title.generateReport': 'Générer un rapport',
  'dashboard.reports.modal.title.generateErroredReport': 'Générer un rapport erroné',
  'dashboard.reports.modal.generateErroredReport.introText':
    'Cela générera un rapport PDF sur les transactions erronées pour tous les locataires entre',
  'dashboard.reports.modal.generate.introText':
    'Cela générera un rapport PDF du trimestre précédent pour tous les locataires entre',
  'dashboard.reports.modal.generate.endingText': 'Ce processus peut prendre quelques heures une fois lancé.',
  'dashboard.reports.table.text.generator': 'système',
  'dashboard.reports.table.text.reportGeneratedBy': 'Rapport généré par',
  'dashboard.reports.table.text.generatedBy': 'Généré par',
  'dashboard.reports.message.generatingReport': 'Génération de rapports',
  'dashboard.reports.message.generatingErroredTransactionsReport': 'Génération de rapports de transactions erronées',
  'dashboard.reports.modal.title.deleteReport': 'Supprimer le rapport',
  'dashboard.reports.modal.message.deleteReport.text': 'Vous êtes sur le point de supprimer. Êtes-vous sûr?',
  'dashboard.reports.modal.message.deleting': 'Suppression du rapport',

  /************************* Load Management Tab *************************/
  'dashboard.loadManagementTab.addMixedCircuit': 'Ajouter un Circuit Mixte',
  'dashboard.loadManagementTab.addDedicatedCircuit': 'Ajouter un Circuit Dédié',
  'dashboard.loadManagementTab.button.sendUpdates': 'Envoyer des mises à jour',
  'dashboard.loadManagementTab.table.locale.unconfig': 'Plus de chargeurs non configurés',
  'dashboard.loadManagementTab.table.locale.panel.title': "Aucun panneau électrique n'est connecté",
  'dashboard.loadManagementTab.table.locale.panel.subtitle':
    'Veuillez utiliser le bouton en haut à gauche pour en créer un',
  'dashboard.loadManagementTab.table.title.unconfig': 'Chargeurs non configurés',
  'dashboard.loadManagementTab.table.locale.branch':
    "Il n'y a pas de branche connectée. Veuillez cliquer sur le bouton Ajouter une branche en haut à droite de ce circuit pour en créer une",
  'dashboard.loadManagementTab.circuitName': 'Nom du circuit',
  'dashboard.loadManagementTab.branchName': 'Nom de la filiale',
  'dashboard.loadManagementTab.editCircuit': 'Modifier le Circuit',
  'dashboard.loadManagementTab.addCircuit': 'Ajouter de la Circuit',
  'dashboard.loadManagementTab.editBranch': 'Modifier de la Filiale',
  'dashboard.loadManagementTab.addBranch': 'Ajouter de la Filiale',
  'dashboard.loadManagementTab.editCharger': 'Modifier le chargeur',
  'dashboard.loadManagementTab.updateCharger': 'Mettre à jour les Chargeur',
  'dashboard.loadManagementTab.type': 'Type de Circuits',
  'dashboard.loadManagementTab.loadManagement': 'Gestion de la Charge',
  'dashboard.loadManagementTab.circuitBranch': 'Circuit/Filiale',
  'dashboard.loadManagementTab.ipAddress': 'Adresse IP',
  'dashboard.loadManagementTab.ctClampConfig': 'Configuration de pince CT pour circuit mixte',
  'dashboard.loadManagementTab.unconfiguredChargers': 'Chargeurs non Configurés',
  'dashboard.loadManagementTab.rule.existingLoad': 'Veuillez entrer une charge existante valide (A)',
  'dashboard.loadManagementTab.rule.circuitSize': 'Veuillez entrer une taille de circuit valide (A)',
  'dashboard.loadManagementTab.rule.ipAddress':
    'Une adresse IP valide comporte quatre nombres décimaux séparés par des points',
  'dashboard.loadManagementTab.rule.ipAddress.warning': 'Veuillez entrer une adresse IP valide',
  'dashboard.loadManagementTab.rule.port': 'veuillez entrer un port valide',
  'dashboard.loadManagementTab.circuitTypeField': 'Type dédié ou mixte',
  'dashboard.loadManagementTab.circuitName.message': 'Veuillez entrer un nom pour le circuit',
  'dashboard.loadManagementTab.label.circuitSize': 'Circuit Valide (A)',
  'dashboard.loadManagementTab.label.existingLoad': 'Existante Valide (A)',
  'dashboard.loadManagementTab.label.maxAmp': 'Capacité Min. (A)',
  'dashboard.loadManagementTab.label.minAmp': 'Capacité Max. (A)',
  'dashboard.loadManagementTab.label.fallbackAmp': 'Repli (A)',
  'dashboard.loadManagementTab.label.breakerSize': 'Taille du Disjoncteur (A)',
  'dashboard.loadManagementTab.label.circuitId': 'ID de Circuit',
  'dashboard.loadManagementTab.cloudLoadMgmt.message':
    "La gestion de la charge cloud de cette annonce a été désactivée. Pour l'activer, cliquez sur la liste, accédez à 'Modifier la liste' et cliquez sur 'Autoriser la gestion de la charge dans le cloud'",
  'dashboard.loadManagementTab.rule.endPoint': 'point final',
  'dashboard.loadManagementTab.text.instanceIP': "IP de l'instance",
  'dashboard.loadManagementTab.text.sendUpdates': 'Envoyer des mises à jour',
  'dashboard.loadManagementTab.rule.http': 'Doit être un protocole HTTPS ou HTTP',
  'dashboard.loadManagementTab.result.title.tenantAllowLoadMgmt':
    'Actuellement, la gestion de la charge est désactivée pour ce locataire',
  'dashboard.loadManagementTab.result.subTitle.tenantAllowLoadMgmt':
    'Pour autoriser la gestion de la charge, cliquez sur le bouton Activer ci-dessous',
  'dashboard.loadManagementTab.disableLoadMgmt': 'Désactiver la gestion de la charge',
  'dashboard.loadManagementTab.result.title.error': "L'activation de la gestion de la charge a échoué",
  'dashboard.loadManagementTab.result.subtitle.error':
    "Veuillez vérifier si le locataire est capable de gérer la charge et contactez l'administrateur pour obtenir de l'aide",
  'dashboard.loadManagementTab.missingListing.message':
    "Ce chargeur n'a pas de liste. Pour joindre une annonce, cliquez sur le bouton Ajouter une annonce",
  'dashboard.loadManagementTab.button.addListing': 'Ajouter une annonce',
  'dashboard.loadManagementTab.maxCap.message': 'Ne peut pas être supérieur au plafond maximal',
  'dashboard.loadManagementTab.rule.branchName': 'Le nom de la succursale doit être différent',
  'dashboard.loadManagementTab.addBranch.toolTip.message':
    'Pour chaque N chargeurs non configurés, il ne peut y avoir que N branches',

  /******************* Plan and Subscription Page *********************/
  'dashboard.subscriptionsPage.button.title': 'Créer un plan',
  'dashboard.subscriptionsPage.placeholder.searchPlan': 'Plan de recherche',
  'dashboard.subscriptionsPage.createPlanForm.selectPlan.warning': 'Un plan doit être sélectionné pour traiter',
  'dashboard.subscriptionsPage.createPlanForm.searchUser.warning':
    "Pas de résultat trouvé. Veuillez confirmer que l'utilisateur a crée un compte SWTCH avec l'adresse e-mail indiquée.",
  'dashboard.subscriptionsPage.inviteForm.help.start': "Le début de l'abonnement",
  'dashboard.subscriptionsPage.inviteForm.help.end': "La fin de l'abonnement",
  'dashboard.subscriptionsPage.invitePlanForm.user.warning': 'Un utilisateur doit être sélectionné pour traiter',
  'dashboard.plan.modal.delete': 'Êtes-vous sûr de vouloir supprimer le plan ',
  'dashboard.plan.modal.archive': 'Êtes-vous sûr de vouloir archiver le plan ',
  'dashboard.plan.modal.unarchive': 'Êtes-vous sûr de vouloir désarchiver le plan ',
  'dashboard.subscriptionsPage.modal.delete': "Êtes-vous sûr de vouloir annuler l'abonnement pour ",
  'dashboard.subscriptionsPage.modal.pause': "Etes-vous sûr de vouloir suspendre l'abonnement pour ",
  'dashboard.subscriptionsPage.modal.unpause': "Etes-vous sûr de vouloir réactiver l'abonnement pour ",
  'dashboard.subscriptionsPage.editSubscription': "Modifier l'abonnement",
  'dashboard.subscriptionsPage.deleteSubscription': "Supprimer l'abonnement",
  'dashboard.plan.subscriptionPlan': "Formules d'abonnement",
  'dashboard.plan.subscribeUser': "Abonnez-vous à l'utilisateur",
  'dashboard.plan.editPlan': 'Modifier le forfait',
  'dashboard.plan.togglePlan': 'Basculer le plan',
  'dashboard.text.subscribeNewUser': "S'abonner Nouvel utilisateur",
  'dashboard.text.cancelSubscription': "Annuler l'abonnement",
  'dashboard.text.pauseSubscription': "Suspendre l'abonnement",
  'dashboard.text.unpauseSubscription': "Annuler la pause de l'abonnement",

  /*************************** Create Listing Page ***************************/
  'dashboard.listing.ListingsText': 'Annonces',
  'dashboard.listing.createListingRequiredText': "Le titre de l'annonce est requis",
  'dashboard.listing.listingAlreadyExistsText': "Le titre de l'annonce existe déjà",
  'dashboard.listing.CreateNewListing': 'Créer une annonce',
  'dashboard.listing.ListingsTable.titleText': 'Titre',
  'dashboard.listing.ListingsTable.authorText': 'Auteur',
  'dashboard.listing.ListingsTable.categoryText': 'Catégorie',
  'dashboard.listing.ListingsTable.chargerText': 'Chargeur',
  'dashboard.listing.ListingsTable.connectorIdText': 'ID de connecteur',
  'dashboard.listing.ListingsTable.priceText': 'Prix',
  'dashboard.listing.ListingsTable.priceTypeText': 'Type de prix',
  'dashboard.listing.ListingsTable.activeText': 'Actif',
  'dashboard.listing.ListingsTable.activeText.open': 'Ouvert',
  'dashboard.listing.ListingsTable.activeText.closed': 'Fermé',
  'dashboard.createNewListing.txt': 'Créer une annonce',
  'dashboard.createNewListing.ListingTab': 'Référencement',
  'dashboard.createNewListing.chargerTab': 'Chargeur',
  'dashboard.createNewListing.loiteringTab': 'Flâner',
  'dashboard.createNewListing.priceTab': 'Prix',
  'dashboard.createNewListing.commissionTab': 'Frais de commission',
  'dashboard.createNewListing.reviewTab': 'Examen',
  'dashboard.createNewListing.nextBtn': 'Prochain',
  'dashboard.createNewListing.previousBtn': 'Précédent',
  'dashboard.createNewListing.submitBtn': 'Soumettre',
  'dashboard.createNewListing.listingStep.heading': "Titre de l'annonce",
  'dashboard.createNewListing.listingStep.detailedDescription.heading': "Informations sur l'inscription",
  'dashboard.createNewListing.listingStep.detailedDescription.warning': '255 caractères est la limite',
  'dashboard.createNewListing.listingStep.detailedDescription.info':
    'Si votre description contient des liens YouTube, les vidéos seront affichées sous la description',
  'dashboard.createNewListing.listingStep.listingAuthor.heading': "Auteur de l'annonce",
  'dashboard.createNewListing.listingStep.listingAuthor.selectTxt': "Sélectionnez l'auteur",
  'dashboard.createNewListing.listingStep.listingAuthor.warning': "L'auteur de l'annonce est requis",
  'dashboard.createNewListing.listingStep.stripeAccount.heading': 'Un compte Stripe est requis',
  'dashboard.createNewListing.listingStep.stripeAccount.selectTxt': 'Sélectionnez un compte Stripe',
  'dashboard.createNewListing.listingStep.listingCategory.Txt': "Catégorie d'annonce",
  'dashboard.createNewListing.listingStep.listingCategory.Reservation': 'Réservation',
  'dashboard.createNewListing.listingStep.listingCategory.Non Reservation': 'Non Réservation',
  'dashboard.createNewListing.listingStep.listingCategory.Destination': 'Destination',
  'dashboard.createNewListing.listingStep.listingCategory.Non Network': 'Hors réseau',
  'dashboard.createNewListing.listingStep.currencyCategory.txt': 'Devise',
  'dashboard.createNewListing.listingStep.walletCategory.txt': 'Fonctionnalité du portefeuille',
  'dashboard.createNewListing.listingStep.walletCategory.walletOption': 'Porte monnaie',
  'dashboard.createNewListing.listingStep.walletCategory.nowalletOption': 'Pas de portefeuille',
  'dashboard.createNewListing.listingStep.listingCategory.txt': "Contrôle d'accès",
  'dashboard.createNewListing.listingStep.listingCategory.privateOption': 'Limité',
  'dashboard.createNewListing.listingStep.listingCategory.publicOption': 'Ouvert',
  'dashboard.createNewListing.listingStep.hiddenCategory.txt': 'Annonces cachées de la carte',
  'dashboard.createNewListing.listingStep.carDetailsCategory.txt':
    'Les utilisateurs ont besoin des détails de la voiture pour réserver',
  'dashboard.createNewListing.chargerStep.tenantCategory.txt': 'Locataire associé',
  'dashboard.createNewListing.chargerStep.tenantCategory.warning': 'Le locataire est requis',
  'dashboard.createNewListing.chargerStep.chargerOutput.txt': 'Sortie chargeur (kW)',
  'dashboard.createNewListing.chargerStep.chargerOutput.timeOfDayCharging.txt':
    "Sélectionnez si l'heure de la journée de charge",
  'dashboard.createNewListing.chargerStep.customNotification.txt': 'Avis personnalisé',
  'dashboard.createNewListing.chargerStep.customNotification.info':
    'Fournir des instructions sur la façon de faire fonctionner le chargeur si nécessaire',
  'dashboard.createNewListing.priceStep.allowClouldLoadManagement.txt':
    'Autoriser la gestion de la charge dans le cloud',
  'dashboard.createNewListing.priceStep.localLoadManagementAutoStop.txt':
    'Gestion de la charge locale : arrêt automatique des transactions',
  'dashboard.createNewListing.priceStep.preferredPricing.txt':
    'Tarification préférentielle pour les membres locataires',
  'dashboard.createNewListing.comissionStep.txt': 'Commission (%)',
  'dashboard.createNewListing.comissionStep.guestFee.txt': "Frais d'invité ($)",
  'dashboard.createNewListing.comissionStep.paymentLimit.txt': 'Limite de paiement ($)',
  'dashboard.createNewListing.reviewStep.listing.title': 'Titre',
  'dashboard.createNewListing.reviewStep.listing.category': 'Catégorie',
  'dashboard.createNewListing.reviewStep.listing.paymentType': 'Type de paiement',
  'dashboard.createNewListing.reviewStep.listing.accessType': "Type d'accès",
  'dashboard.createNewListing.reviewStep.listing.hiddenFromMap': 'Caché de la carte',
  'dashboard.createNewListing.reviewStep.listing.carDetailRequired': 'Détail de la voiture requis',
  'dashboard.createNewListing.reviewStep.listing.author': 'Auteur',
  'dashboard.createNewListing.reviewStep.listing.stripeAccount': 'Compte Stripe',
  'dashboard.createNewListing.reviewStep.listing.currency': 'Devise',
  'dashboard.createNewListing.reviewStep.listing.description': "Description de l'emplacement",
  'dashboard.createNewListing.reviewStep.charger.chargerOutput': 'Sortie chargeur',
  'dashboard.createNewListing.reviewStep.charger.enableTimeOfDayCharging':
    "Activer la charge selon l'heure de la journée",
  'dashboard.createNewListing.reviewStep.charger.customNotification': 'Avis personnalisé',
  'dashboard.createNewListing.reviewStep.price.preferedPricingtxt': 'Prix ​​préférentiel par unité Cents ($)',
  'dashboard.createNewListing.reviewStep.price.hourlyPricingtxt': 'Tarification horaire basée sur le kWh ($)',
  'dashboard.createNewListing.reviewStep.price.todPriceSettingstxt': "Paramètres de prix selon l'heure de la journée",
  'dashboard.createNewListing.listingCreatedSuccessfullyText': 'Liste -1 mis à jour avec succés',
  'dashboard.updated.listing': 'La liste a été mise à jour avec succès',
  'dashboard.createNewListing.refreshAuthorsText': 'Actualiser les auteurs et les comptes Stripe',
}
