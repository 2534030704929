export const priceType = ['Hour', 'kWh'] as const
export type PriceType = typeof priceType[number]

export interface Price extends TimeOfDayPrice {
  preferPricePerUnitCents?: number
  pricePerUnitCents: number
  priceType: PriceType
  preferredPricingPriceType: PriceType
  flatFee: number | string
  timeOfDayEnabled: boolean
}

export interface TimeOfDayPrice {
  timeOfDayPriceWeekday: number[]
  timeOfDayPriceWeekend: number[]
}
