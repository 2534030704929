import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'

export const useTenantTranslation = () => {
  const tenantText = useFormatMessage('dashboard.text.tenant', 'Tenant')
  const uniformServiceStatusWarningText = renderFormatMessage(
    'dashboard.tenantPage.overviewTab.chargerServiceStatus.help',
    "IMPORTANT: Selecting one of the values will update to all chargers' service status associated with this tenant",
  )
  const connectorServiceStatusText = renderFormatMessage(
    'dashboard.tenantPage.overviewTab.connectorServiceStatus',
    'Connector Service Status',
  )
  const uniformServiceStatusText = renderFormatMessage(
    'dashboard.tenantPage.overviewTab.uniformServiceStatus',
    'Uniform Service Statuses',
  )
  const omOptInText = renderFormatMessage('dashboard.tenantPage.overviewTab.o_m_opt_in.heading', 'O&M Opt-in')

  const masterAccountText = renderFormatMessage('dashboard.text.masterAccount', 'Master Account')

  const channelPartnerText = renderFormatMessage('dashboard.tenantPage.overviewTab.channelPartner', 'Channel Partner')

  const ocppNameText = useFormatMessage('dashboard.tenantPage.detailTab.ocppName.heading', 'OCPP Name')

  const taxRegistrationText = useFormatMessage(
    'dashboard.tenantPage.overviewTab.taxRegistration.heading',
    'Tax Registration',
  )

  const taxRegistrationOptionText = useFormatMessage(
    'dashboard.tenantPage.overviewTab.taxRegistration.option.registered',
    'Registered',
  )
  const taxRegistrationOptionNotRegisteredText = useFormatMessage(
    'dashboard.tenantPage.overviewTab.taxRegistration.option.not_registered',
    'Not Registered',
  )
  const taxRegistrationOptionJointElectedText = useFormatMessage(
    'dashboard.tenantPage.overviewTab.taxRegistration.option.joint_elected',
    'Joint Elected',
  )
  return {
    tenantText,
    uniformServiceStatusWarningText,
    connectorServiceStatusText,
    uniformServiceStatusText,
    omOptInText,
    masterAccountText,
    channelPartnerText,
    ocppNameText,
    taxRegistrationText,
    taxRegistrationOptionText,
    taxRegistrationOptionNotRegisteredText,
    taxRegistrationOptionJointElectedText,
  }
}
