import { UserRef } from './user'
import { ListingRef } from './listing'
import { ChargerRef } from './charger'
import { ConnectorRef } from './connector'
import { TenantRef } from './tenant'

export type ExportType = 'pdf' | 'csv'

export const transactionStartMethods = ['rfid', 'mobile', 'website'] as const
export type TransactionStartMethod = typeof transactionStartMethods[number]

export const transactionState = [
  'canceled',
  'confirmed',
  'errored',
  'free',
  'no_reservation',
  'paid',
  'preauthorized',
  'checked_in',
  'pending_payment',
] as const
export type TransactionState = typeof transactionState[number]

export interface Transaction {
  id: number
  ocppTransactionId?: number
  percent: number
  startMethod: string
  currentState: TransactionState

  startAt: string
  endAt?: string
  chargeStartAt?: string
  chargeEndAt?: string

  timezone: string
  duration: number
  energy: number
  financial: {
    subtotal: number
    total: number
    tax: number
    serviceFee: number
    tenantAmount: number
    propertySubtotal: number
    propertyTax: number
  }
  charger: ChargerRef
  tenant: TenantRef
  connector: ConnectorRef
  user: UserRef
  listing: ListingRef
}

export interface Transactionv2 {
  txnId: number
  startMethod: string
  plugInDuration: number
  connected: string
  disconnected: string
  energy: number
  state: string
  total: number
  username: string
}

export const hasEnded = (trx: Transaction): boolean => {
  return trx.currentState === 'free' || trx.currentState === 'confirmed' || trx.currentState === 'errored'
}

export const errorTransaction = (trx: Transaction): boolean => {
  return trx.currentState === 'free' || trx.currentState === 'confirmed'
}

export const hasErrored = (trx: Transaction): boolean => {
  return trx.currentState === 'canceled' || trx.currentState === 'errored'
}

export const calculateGHGSavings = (energy: number, province?: string) => {
  /* For now, return Canada's electric carbon intensity
  and for the future, uncomment the code below */
  // switch (province) {
  //   case 'ON':
  //     return 1.009 * energy: number;
  //   case 'AB':
  //     return 0.149 * energy: number;
  //   case 'BC':
  //     return 1.038 * energy: number;
  //   case 'QC':
  //     return 1.048 * energy: number;
  //   default: // Canada
  return 0.73 * energy
  // }
}

export interface TrxnOverview {
  datetime: string
  revenue: number
  txnCount: number
  energy: number
}

export interface ChargerProfile {
  time: string
  chargerInUseWeekday: number
  chargerInUseWeekend: number
  chargerInUseWeek: number
}

export interface DailyProfile {
  time: string
  consumeWeekday: number
  consumeWeekend: number
  consumeWeek: number
}

export interface TrxSummary {
  txnCount: number
  txnAfterHr: number
  txnOnDays: number
  revenueTotal: number
  revenueDemandResponse: number
  revenueCharging: number
  revenueLoitering: number
  energyConsumeTotal: number
  energyConsumeInPeakHrs: number
  ghgSavingTotal: number
  chargerAverageDayDuration: number
  chargerAverageDayLevel2Duration: number
  chargerAverageDayDcfcDuration: number
  chargerAverageDayPlugin: number
  chargerAverageDayLevel2Plugin: number
  chargerAverageDayDcfcPlugin: number
  usersTotal: number
  avgTxnDay: number
  avgEnergyConsumePerTxn: number
  avgUserSpend: number
  stationActivation: StationActivation
}

export interface StationActivation {
  mobile: number
  web: number
  rfid: number
  roaming: number
}
