import { Col, Layout, Menu, MenuProps, Row, Space } from 'antd'
import { useLocation } from 'react-router-dom'

import { styled } from '../../../theme'
import { useAppState } from '../../../state'
import { Links } from '../../routes/paths'

import frflag from '../../../assets/images/fr-flag.png'
import usaflag from '../../../assets/images/usa-flag.png'
import { arrowLeftSVG } from '../../../assets/svg/arrow'
import { globeSVG } from '../../../assets/svg/globe'
import { listingSVG } from '../../../assets/svg/listing'
import { logoutSVG } from '../../../assets/svg/logout'
import { pieChartSVG } from '../../../assets/svg/pieChart'
import { plugNeonGreenHeaderSVG } from '../../../assets/svg/plug'
import { reportSVG } from '../../../assets/svg/report'
import { tenantSVG } from '../../../assets/svg/tenant'
import { transactionSVG } from '../../../assets/svg/transaction'
import { userSVG } from '../../../assets/svg/user'
import { useAppHeaderTranslation } from '../../../hooks/translation/useAppHeaderTranslation'
import { AppHeaderContainer } from '../../../atom/app-header'

const MenuSpacing = styled.div`
  .ant-layout-header {
    padding: 0 25px 0 15px;
  }
`

interface props {
  collapsed: boolean
  handleToggleMenu(): void
}

export const AppHeader: React.FC<props> = ({ collapsed, handleToggleMenu }) => {
  const {
    currentUser,
    selectedTenant,
    logout,
    language,
    setLanguage,
    ToggleClassicDashboardView,
    isClassicView,
  } = useAppState()

  const location = useLocation()

  const {
    adminDashboardText,
    supportDashboardText,
    dashboardText,
    listingText,
    logoutText,
    switchHomePageText,
    switchHomePageNewVersionText,
  } = useAppHeaderTranslation()

  const changeLocal = () => {
    switch (language) {
      case 'en':
        setLanguage('fr')
        break
      case 'fr':
        setLanguage('en')
        break
    }
  }

  const handleLogout: MenuProps['onClick'] = (e) => {
    if (e.key === 'logout') {
      logout()
      window.location.replace(Links.auth0login())
    }
  }

  if (!currentUser) {
    return <div />
  }

  const renderTitle = () => {
    const splitPathName = location.pathname.split('/')

    let title = ''
    let icon = pieChartSVG

    switch (splitPathName[1]) {
      case 'listings':
        icon = listingSVG
        title = listingText
        break
      case 'chargers':
        icon = plugNeonGreenHeaderSVG
        title = 'Chargers'
        break
      case 'tenants':
        icon = tenantSVG
        title = 'Tenants'
        break
      case 'transactions':
        icon = transactionSVG
        title = 'Transactions'
        break
      case 'reports':
        icon = reportSVG
        title = 'Reports'
        break
      case 'users':
        icon = userSVG
        title = 'Users'
        break
      case 'usersv2':
        icon = userSVG
        title = 'Users'
        break
      default:
        switch (currentUser?.role) {
          case 'admin':
            title = adminDashboardText
            break
          case 'support':
            title = supportDashboardText
            break
          case 'user':
            const tenantDisplayName = currentUser?.accesses.filter((obj) => {
              return obj.display === selectedTenant?.name
            })
            if (selectedTenant && tenantDisplayName.length === 1) {
              title = tenantDisplayName[0].displayName || tenantDisplayName[0].display
            } else {
              title = dashboardText
            }
        }
        break
    }

    return (
      <>
        <span style={{ position: 'absolute', left: '5px', top: '13px' }}>{icon}</span>
        {title}
      </>
    )
  }
  return (
    <AppHeaderContainer>
      <MenuSpacing style={{ zIndex: 1 }}>
        <Layout.Header>
          <Row justify="start" align="middle">
            <Col xs={22} sm={23} md={17} lg={18} xl={20} xxl={20}>
              <Space>
                <h1 className="heading-01-regular dashboard-title" style={{ marginBottom: 0, marginLeft: '30px' }}>
                  {renderTitle()}
                </h1>
              </Space>
            </Col>
            <Col xs={2} sm={1} md={7} lg={6} xl={4} xxl={4}>
              <Menu theme="light" mode="horizontal" className="sub-menu">
                <Menu.SubMenu
                  key="language"
                  title={language === 'en' ? 'ENG' : 'FRA'}
                  className="language"
                  icon={globeSVG}
                >
                  <Menu.Item
                    disabled={language === 'en' ? true : false}
                    key="en"
                    onClick={changeLocal}
                    icon={<img src={usaflag} alt="english-language-with-usa-flag" />}
                    className="lng-option en"
                  >
                    ENGLISH
                  </Menu.Item>
                  <Menu.Item
                    disabled={language === 'fr' ? true : false}
                    key="fr"
                    onClick={changeLocal}
                    icon={<img src={frflag} alt="french-language-with-france-flag" />}
                    className="lng-option fr"
                  >
                    FRANCAIS
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu key="signedUser" title={currentUser.name[0].toUpperCase()} className="signed-in-user">
                  <Menu.Item key="signed-user" onClick={changeLocal} className="menu-2 current-user">
                    {currentUser.email}
                  </Menu.Item>
                  <Menu.Item
                    key="switch-homepage"
                    onClick={() => ToggleClassicDashboardView()}
                    icon={arrowLeftSVG}
                    className="menu-2 switch-old-homepage-toggle"
                  >
                    {isClassicView ? switchHomePageNewVersionText : switchHomePageText}
                  </Menu.Item>
                  <Menu.Item key="logout" onClick={handleLogout} icon={logoutSVG} className="menu-2 logout-optn">
                    {logoutText}
                  </Menu.Item>
                </Menu.SubMenu>
              </Menu>
            </Col>
          </Row>
        </Layout.Header>
      </MenuSpacing>
    </AppHeaderContainer>
  )
}
