import { Modal, Skeleton, Tabs } from 'antd'

import { RemoteClearCacheController } from '../../../components/chargers/remote-clear-cache-controller'
import { RemoteFirmwareUpdateController } from '../../../components/chargers/remote-firmware-update-controller'
import { RemoteRestartChargerController } from '../../../components/chargers/remote-restart-charger-controller'
import { RemoteTriggerMultipleChargers } from '../../../components/chargers/remote-trigger-multiple-chargers'
import { RemoteUpdateConfiguration } from '../../../components/chargers/remote-update-configuration'

import { useFormatMessage } from '../../../helpers/intl'

import { ChargerRef } from '../../../models/charger'
import { RemoteMultiChargerTriggerMessageParams } from '../../../models/charger-remote'

interface props {
  loading: boolean
  selectedCharger: ChargerRef[]
  selectedChargersConns: RemoteMultiChargerTriggerMessageParams[]
  onOk: () => void
  onCancel: () => void
}

export const ChargerModal: React.FC<props> = ({ loading, selectedCharger, selectedChargersConns, onOk, onCancel }) => {
  const updateChargersText = useFormatMessage('dashboard.chargersPage.modal.updatechargers', 'Update Chargers')
  const selectedText = useFormatMessage('dashboard.chargersPage.modal.updatechargers.selected', 'selected')
  const firmwareText = useFormatMessage('dashboard.chargersPage.modal.firmware', 'Firmware')
  const configurationText = useFormatMessage('dashboard.chargersPage.modal.configuration', 'Configuration')
  const restartText = useFormatMessage('dashboard.chargerPage.remoteControlTab.restartChargeBox.restartbtn', 'Restart')
  const clearCacheText = useFormatMessage('dashboard.chargerPage.remoteControlTab.clearCache.heading', 'Clear Cache')
  const triggerMessageText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.triggerMessage.trigger',
    'Trigger',
  )

  const modalTitle = `${updateChargersText} (${selectedCharger.length} ${selectedText})`

  return (
    <Modal title={modalTitle} visible={true} onCancel={onCancel} onOk={onOk} footer={null} width={600}>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={firmwareText} key="firmware">
          <Skeleton loading={loading}>
            {selectedCharger && <RemoteFirmwareUpdateController chargers={selectedCharger} />}
          </Skeleton>
        </Tabs.TabPane>

        <Tabs.TabPane tab={configurationText} key="configuration">
          <Skeleton loading={loading}>
            {selectedCharger && <RemoteUpdateConfiguration chargers={selectedCharger} />}
          </Skeleton>
        </Tabs.TabPane>

        <Tabs.TabPane tab={restartText} key="restart">
          <Skeleton loading={loading}>
            {selectedCharger && <RemoteRestartChargerController chargers={selectedCharger} />}
          </Skeleton>
        </Tabs.TabPane>

        <Tabs.TabPane tab={clearCacheText} key="clear_cache">
          {selectedCharger && <RemoteClearCacheController chargers={selectedCharger} />}
        </Tabs.TabPane>

        <Tabs.TabPane tab={triggerMessageText} key="trigger">
          {selectedCharger && <RemoteTriggerMultipleChargers chargers={selectedChargersConns} />}
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  )
}
