import { Badge } from 'antd'

import { connectorServiceStatus, connectorStatus } from '../../models/connector'
import { theme } from '../../theme'
import { useFormatMessage } from '../../helpers/intl'
import { serviceStatus } from '../../models/charger'
import { renderConnectionMessage } from '../../helpers/status'

export const allStatus = [
  ...connectorStatus,
  ...serviceStatus,
  ...connectorServiceStatus,
  'Commissioning',
  'Online',
] as const
export type AllStatus = typeof allStatus[number]

interface props {
  state: AllStatus
}

const ServiceStatusBadge: React.FC<props> = ({ state }) => {
  const [status, backgroundColor, fontColor] = getColorStatus(state)
  const translatedStatus = useFormatMessage(renderConnectionMessage(state), status)
  return (
    <Badge
      count={translatedStatus}
      style={{ backgroundColor, color: fontColor, boxShadow: `${backgroundColor} 0px 0px 0px 0.5px` }}
    />
  )
}

// Returns the a tupple, where the first argument in the Humanize state and the second is the css class
export const getColorStatus = (status: AllStatus): string[] => {
  switch (status) {
    case 'Unavailable':
    case 'unavailable':
    case 'Faulted':
    case 'faulted':
    case 'under_repair':
    case 'Under Repair':
    case 'Troubleshooting':
    case 'troubleshooting':
    case 'Inactive':
    case 'inactive':
      return [status, theme.colors.underRepair, theme.colors.dangerPrimary]
    case 'Terminated':
    case 'terminated':
    case 'Other':
    case 'other':
    case 'Decommissioned':
    case 'decommissioned':
    case 'Testing':
    case 'testing':
    case 'on_hold':
    case 'On Hold':
      return [status, theme.colors.black05, theme.colors.black]
    case 'Offline':
    case 'offline':
      return [status, theme.colors.grey12, theme.colors.black]
    case 'Available':
    case 'Online':
    case 'Active':
    case 'active':
    case 'service_mode':
      return [status, theme.colors.active, theme.colors.neonGreen]
    case 'Preparing':
    case 'SuspendedEV':
    case 'SuspendedEVSE':
    case 'Finishing':
    case 'Commissioned':
    case 'commissioned':
    case 'activating':
    case 'Activating':
    case 'property_activating':
    case 'driver_activating':
    case 'Activating - Pending Property':
    case 'Activating - Pending Driver Subscription':
      return [status, theme.colors.orangePeel15, theme.colors.warningPrimary]
    case 'Charging':
    case 'charging':
    case 'in_maintenance':
    case 'Commissioning':
    case 'Awaiting Commissioning':
    case 'awaiting_commissioning':
      return [status, theme.colors.skyBlue15, theme.colors.carribeanCurrent]
    default:
      return ['Unknown', theme.colors.gray]
  }
}

export default ServiceStatusBadge
