export const loiteringStatusType = ['no_penalty', 'time_based', 'status_based'] as const
export type LoiteringStatusType = typeof loiteringStatusType[number]

export interface Loitering {
  loiteringStatus: LoiteringStatusType
  loiteringTimeLimit: number
  loiteringGracePeriod: number
  loiteringPenalty: number
  disableLoiteringForTenantMember: boolean
}

export interface UniformLoitering {
  disableLoitering: boolean
}