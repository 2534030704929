import { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsMore from 'highcharts/highcharts-more'
import { useMediaQuery } from 'react-responsive'

import { ChargerStationInfo, ConnectorInfo } from '../../../../models/chargerv2'
import { useFormatMessage } from '../../../../helpers/intl'
import { styled } from '../../../../theme'

highchartsMore(Highcharts)

const options = {
  chart: {
    type: 'gauge',
    styledMode: true,
    className: 'stations-gauge',
    // width: 190,
    height: 312,
    events: {
      render: function () {
        var chart: any = this,
          yAxis = chart.yAxis[0],
          key,
          tick,
          minorTick

        // console.log('-----chart----')
        // console.log(chart.series[0].data[0].y)

        for (key in yAxis.ticks) {
          tick = yAxis.ticks[key]

          if (tick.pos < 120) {
            tick.mark.attr({ stroke: 'red' })
          } else if (tick.pos >= 120 && tick.pos < 160) {
            tick.mark.attr({ stroke: 'green' })
          } else if (tick.pos >= 160) {
            tick.mark.attr({ stroke: 'yellow' })
          }
        }

        for (key in yAxis.minorTicks) {
          minorTick = yAxis.minorTicks[key]

          // console.log('---minorTick--')
          // console.log(minorTick)

          if (minorTick.pos <= chart.series[0].data[0].y) {
            minorTick.mark.attr({ stroke: '#54DEC7' })
          } else {
            minorTick.mark.attr({ stroke: '#999' })
          }
        }
      },
      events: {
        load() {
          //@ts-ignore
          setTimeout(this.reflow.bind(this), 0)
        },
      },
    },
  },

  title: {
    text: '',
  },

  pane: {
    startAngle: -110,
    endAngle: 110,
    size: '50%',
    center: ['50%', '50%'],
  },

  // the value axis
  yAxis: {
    min: 0,
    max: 200,

    minorTickInterval: 'auto',
    minorTickLength: 5,
    minorTickPosition: 'inside',

    tickPixelInterval: 30,
    tickPosition: 'outside',
    tickLength: 1,
    labels: {
      enabled: false,
    },
  },

  tooltip: {
    enabled: false,
  },

  series: [
    {
      name: 'Charging Stations',
      data: [40],
      dial: {
        path: [
          'M 55.0574 1.344 C 55.6109 2.91 57.1044 4.032 58.86 4.032 C 61.0868 4.032 62.892 2.2268 62.892 0 C 62.892 -2.2268 61.0868 -4.032 58.86 -4.032 C 57.1044 -4.032 55.6109 -2.91 55.0574 -1.344 L 45.42 -1.344 V 1.344 H 55.0574 Z',
        ],
      },

      // dataLabels: {
      //   format:
      //     '<div  class="stations-gauge">' +
      //     '<span style="font-size:25px">{y}</span> </br>' +
      //     '<span >Charging</span' +
      //     '</div>',
      // },
    },
  ],

  plotOptions: {
    gauge: {
      dial: {
        radius: '100%',
        // backgroundColor: 'silver',
        borderColor: 'black',
        borderWidth: 1,
        // baseWidth: 10,
        topWidth: 1,
      },
    },
  },
}

const HomePageGaugeChartContainer = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  position: relative;

  .highcharts-gauge-series .highcharts-pivot {
    stroke-width: 1px;
    stroke: black;
    fill-opacity: 0.3;
  }

  .highcharts-yaxis-grid .highcharts-grid-line,
  .highcharts-yaxis-grid .highcharts-minor-grid-line {
    display: none;
  }

  .highcharts-yaxis .highcharts-minor-tick {
    stroke-width: 2px;
    /* stroke: #999; */
  }

  .highcharts-plot-band,
  .highcharts-pane {
    fill: transparent !important;
  }

  .stations-gauge {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .stations-gauge::after {
    content: '';
    width: 90px;
    height: 90px;
    border-radius: 50%;
    text-align: center;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .stations-gauge.laptop::after {
    width: 100px;
    height: 100px;
  }

  .highcharts-data-labels.highcharts-series-0.highcharts-gauge-series.highcharts-color-0.highcharts-tracker {
    /* display: none; */
  }

  .highcharts-label-box.highcharts-data-label-box {
    stroke-width: 0;
  }

  .gauge-text-container {
    position: absolute;
    text-align: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 42%;
  }
  .gauge-text-container h4,
  .gauge-text-container p {
    margin: 0;
  }

  .gauge-text-container p {
    opacity: 0.5;
  }

  .highcharts-axis.highcharts-yaxis.highcharts-radial-axis {
    stroke: transparent;
  }

  .minmax-container {
    position: absolute;
    text-align: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 33%;
    display: flex;
    justify-content: 'center';
    align-items: 'center';
  }

  .min,
  .max {
    position: absolute;
    /* bottom: 20px; */
    color: #242e2c;
  }

  .min {
    left: 30%;
  }

  .max {
    right: 30%;
  }

  ${(props) => props.theme.breakpoints.down('xxl')} {
    .minmax-container {
      bottom: 33%;
    }

    .min {
      left: 20%;
    }
    .max {
      right: 20%;
    }
  }

  .stations-gauge.largeLaptop::after {
    width: 90px;
    height: 90px;
  }

  .minmax-container.custom-media-active .min {
    left: 25%;
  }
  .minmax-container.custom-media-active .max {
    right: 25%;
  }
`

interface props {
  connectorInfo: ConnectorInfo
  chargerStationInfo: ChargerStationInfo
}

export const HomePageGaugeChart: React.FC<props> = ({ connectorInfo, chargerStationInfo }) => {
  const isLaptopLarge = useMediaQuery({ minWidth: 1200, maxWidth: 1400 })

  const customMediaQuery = useMediaQuery({ minWidth: 1600, maxWidth: 2000 })

  if (customMediaQuery) {
    document.querySelector('.minmax-container')?.classList.add('custom-media-active')
  } else {
    document.querySelector('.minmax-container')?.classList.remove('custom-media-active')
  }

  const [guageOptions, setGauageOptions] = useState(options)

  useEffect(() => {
    if (isLaptopLarge) {
      document.querySelector('.stations-gauge')?.classList.add('largeLaptop')
      options.series[0].dial.path = [
        'M 50.0574 1.344 C 50.6109 2.91 52.1044 4.032 53.86 4.032 C 56.0868 4.032 57.892 2.2268 57.892 0 C 57.892 -2.2268 56.0868 -4.032 53.86 -4.032 C 52.1044 -4.032 50.6109 -2.91 50.0574 -1.344 L 40.42 -1.344 V 1.344 H 50.0574 Z',
      ]
    } else {
      document.querySelector('.stations-gauge')?.classList.remove('largeLaptop')
      options.series[0].dial.path = [
        'M 55.0574 1.344 C 55.6109 2.91 57.1044 4.032 58.86 4.032 C 61.0868 4.032 62.892 2.2268 62.892 0 C 62.892 -2.2268 61.0868 -4.032 58.86 -4.032 C 57.1044 -4.032 55.6109 -2.91 55.0574 -1.344 L 45.42 -1.344 V 1.344 H 55.0574 Z',
      ]
    }
    setGauageOptions({
      ...guageOptions,

      // the value axis
      yAxis: {
        ...guageOptions.yAxis,
        max: chargerStationInfo.online + chargerStationInfo.offline + chargerStationInfo.repair,
      },

      series: [
        {
          ...guageOptions.series[0],
          data: [connectorInfo.charging],
        },
      ],
    })
  }, [connectorInfo])

  return (
    <HomePageGaugeChartContainer className="gauge-chart">
      <HighchartsReact highcharts={Highcharts} options={guageOptions} />
      <div className="gauge-text-container">
        <h4 className="heading-04-black">{connectorInfo.charging}</h4>
        <p className="paragraph-04-regular">{useFormatMessage('dashboard.homePage.chargingText', 'Charging')}</p>
      </div>
      <div className="minmax-container">
        <p className="min paragraph-04-regular">
          <b>0</b>
        </p>
        <p className="max paragraph-04-regular">
          <b>{chargerStationInfo.online + chargerStationInfo.offline + chargerStationInfo.repair}</b>
        </p>
      </div>
    </HomePageGaugeChartContainer>
  )
}
