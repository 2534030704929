import { PartnerRef } from '../models/partner'

export const mockPartnerRef: { [name: string]: PartnerRef } = {
  AutoGrid: {
    id: 1,
    name: 'AutoGrid',
  },
  'Natural Resources of Canada': {
    id: 2,
    name: 'Natural Resources of Canada',
  },
  'Signature Electric': {
    id: 3,
    name: 'Signature Electric',
  },
  'Leading Head Energy': {
    id: 4,
    name: 'Leading Head Energy',
  },
  'Opus One Solutions': {
    id: 5,
    name: 'Opus One Solutions',
  },
  'CSC Serviceworks': {
    id: 6,
    name: 'CSC Serviceworks',
  },
  SmartOne: {
    id: 7,
    name: 'SmartOne',
  },
  'Aligned Climate Capital': {
    id: 8,
    name: 'Aligned Climate Capital',
  },
}

export const mockPartners = [
  {
    id: 1,
    name: 'AutoGrid',
  },
  {
    id: 2,
    name: 'Natural Resources of Canada',
  },
  {
    id: 3,
    name: 'Signature Electric',
  },
  {
    id: 4,
    name: 'Leading Head Energy',
  },
  {
    id: 5,
    name: 'Opus One Solutions',
  },
  {
    id: 6,
    name: 'CSC Serviceworks',
  },
  {
    id: 7,
    name: 'SmartOne',
  },
  {
    id: 8,
    name: 'Aligned Climate Capital',
  },
] as PartnerRef[]
