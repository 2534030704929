import { theme } from '../../theme'

export const InfoIcon = (props: any) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9 18C4.0293 18 0 13.9707 0 9C0 4.0293 4.0293 0 9 0C13.9707 0 18 4.0293 18 9C18 13.9707 13.9707 18 9 18ZM8.1 11.7V13.5H9.9V11.7H8.1ZM9.9 10.2195C10.6233 10.0015 11.2442 9.53096 11.6497 8.89357C12.0552 8.25618 12.2183 7.49435 12.1093 6.74682C12.0002 5.99929 11.6263 5.31579 11.0556 4.82081C10.4849 4.32583 9.75544 4.05228 9 4.05C8.27177 4.04994 7.566 4.30214 7.00271 4.76369C6.43943 5.22525 6.05341 5.86767 5.9103 6.5817L7.6761 6.9354C7.72621 6.68469 7.84649 6.45336 8.02293 6.26833C8.19937 6.08331 8.42473 5.95219 8.67278 5.89023C8.92083 5.82828 9.18138 5.83804 9.4241 5.91837C9.66682 5.99871 9.88174 6.14631 10.0438 6.34402C10.206 6.54173 10.3086 6.7814 10.3398 7.03516C10.371 7.28892 10.3295 7.54632 10.2202 7.77742C10.1108 8.00853 9.9381 8.20383 9.72209 8.3406C9.50608 8.47737 9.25567 8.54998 9 8.55C8.7613 8.55 8.53239 8.64482 8.3636 8.8136C8.19482 8.98239 8.1 9.2113 8.1 9.45V10.8H9.9V10.2195Z"
        fill={theme.colors.lightGray}
      />
    </svg>
  )
}
