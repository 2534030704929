import { useEffect, useState } from 'react'
import { Badge, Dropdown, Pagination, Space, Table, Tooltip } from 'antd'
import type { TableProps } from 'antd/es/table'
import { AlertError } from '../error'
import { PaginationMeta } from '../../models/pagination'
import { Subscription } from '../../models/subscription'
import { SwtchError } from '../../models/error'
import { GetSubscriptions } from '../../services/data-provider/subscriptions'
import { useAppState } from '../../state'
import { SubscriptionStatusBadge } from './subscription-status-badge'
import { SubscriptionCollapseView } from '../../pages/subscription/views/subscription-collapse-view'
import { theme } from '../../theme'
import { useSubscriptionTranslation } from '../../hooks/translation/useSubscriptionTranslation'
import { useGeneralTranslation } from '../../hooks/translation/useGeneralTranslation'
import { formatMomentDate } from '../../helpers/date-format'
import { MoreButtonWrapper, MoreDropdownWrapper } from '../../atom/plans'
import { cancelSVG } from '../../assets/svg/cancel'
import { pauseSVG } from '../../assets/svg/pause'
import { playSVG } from '../../assets/svg/play'
import PauseSubscriptionRedesignModal from './PauseSubscriptionRedesign'
import UnpauseSubscriptionRedesignModal from './UnpauseSubscriptionRedesign'
import { CancelSubscriptionRedesignModal } from './form/cancel-subscription-modal-redesign'
import CreateSubscriptionRedesignForm from './form/CreateSubscriptionFormRedesign'
import { UserWallet } from '../../models/user'
import { TableStyling } from '../../atom/users-page-redesign-styles'

interface props {
  showInviteForm: boolean
  searchUser?: UserWallet
  searchSubscriptions: boolean
  onUserInvited: () => void
}

export const SubscriptionsRedesign: React.FC<props> = ({
  showInviteForm,
  searchUser,
  searchSubscriptions,
  onUserInvited,
}) => {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
  const [cancelSubscription, setCancelSubscription] = useState<Subscription>()
  const [pauseSubscription, setPauseSubscription] = useState<Subscription>()
  const [unpauseSubscription, setUnpauseSubscription] = useState<Subscription>()
  const [loading, setLoading] = useState<boolean>(false)
  const [filter, setFilter] = useState<string | undefined>()
  const [pagination, setPagination] = useState<PaginationMeta>()
  const [currentPage, setCurrentPage] = useState(1)
  const [error, setError] = useState<SwtchError>()
  const [verticalMoreButton, setVerticalMoreButton] = useState(false)

  const { IsMobile, IsTablet, IsDesktop, IsLaptop } = useAppState()
  const isMobile = IsMobile()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()

  useEffect(() => {
    getSubscriptions()
  }, [currentPage, filter, searchSubscriptions, searchUser])

  const getSubscriptions = () => {
    setError(undefined)
    setLoading(true)
    GetSubscriptions(currentPage, filter, searchUser)
      .then((resp) => {
        setSubscriptions(resp.data)
        setPagination(resp.pagination)
      })
      .catch((err: SwtchError) => setError(err))
      .finally(() => setLoading(false))
  }

  const { billingAddressText, planText } = useSubscriptionTranslation()
  const { statusText, startDateText, endDateText, unavailableText } = useGeneralTranslation()

  const handleNewSubscription = (newSub: Subscription) => {
    setSubscriptions([...subscriptions, newSub])
    onUserInvited()
  }

  const handleCancelSubscription = (deleteSub: Subscription) => {
    const remainingSub = subscriptions.filter((sub) => sub.id !== deleteSub.id)
    setSubscriptions(remainingSub)
    setCancelSubscription(undefined)
  }

  const handlePauseSubscription = (pauseSub: Subscription) => {
    setSubscriptions((prevSubs) => {
      return prevSubs.map((sub) => {
        if (sub.id === pauseSub.id) {
          return { ...pauseSub, status: 'paused' }
        }
        return sub
      })
    })
    setPauseSubscription(undefined)
  }

  const handleUnpauseSubscription = (unpauseSub: Subscription) => {
    setSubscriptions((prevSubs) => {
      return prevSubs.map((sub) => {
        if (sub.id === unpauseSub.id) {
          return { ...unpauseSub, status: 'active' }
        }
        return sub
      })
    })
    setUnpauseSubscription(undefined)
  }

  const onPaginationChange = (page: number, pageSize?: number) => setCurrentPage(page)

  const handleUnavailablePlan = (subscription: Subscription) => (
    <Tooltip title="No plan is associated">
      <Badge
        key={subscription.id}
        style={{
          backgroundColor: theme.colors.underRepair,
          color: theme.colors.dangerPrimary,
          boxShadow: `${theme.colors.underRepair} 0px 0px 0px 0.5px`,
        }}
      >
        {unavailableText}
      </Badge>
    </Tooltip>
  )

  const handleStatus = (subscription: Subscription) => {
    return !subscription.plan ? (
      handleUnavailablePlan(subscription)
    ) : (
      <SubscriptionStatusBadge subscription={subscription} />
    )
  }

  const handleBillingAddress = (subscription: Subscription) => {
    if (subscription.billingAddress) {
      const { zip, state, country } = subscription.billingAddress
      return (
        <span>
          {state}, {zip}, {country}
        </span>
      )
    }
    return null
  }

  const disableUnpauseButton = (sub: Subscription) => {
    return !sub.plan || sub.status === 'active' || sub.status === 'inactive' || sub.status === 'pending'
  }

  const disablePauseButton = (sub: Subscription) => {
    return !sub.plan || sub.status === 'paused' || sub.status === 'pending'
  }

  const moreDropDown = (sub: Subscription, index: number) => (
    <MoreDropdownWrapper
      pauseSubscriptionDisabled={disablePauseButton(sub)}
      unpauseSubscriptionDisabled={disableUnpauseButton(sub)}
      cancelSubscriptionDisabled={!sub.plan || sub.status === 'canceled'}
    >
      <div
        className="dropdown-list-item pause-subscription"
        onClick={() => {
          setVerticalMoreButton(false)
          if (!disablePauseButton(sub)) setPauseSubscription(sub)
        }}
      >
        {pauseSVG} Pause Subscription
      </div>
      <div
        className="dropdown-list-item unpause-subscription"
        onClick={() => {
          setVerticalMoreButton(false)
          if (!disableUnpauseButton(sub)) setUnpauseSubscription(sub)
        }}
      >
        {playSVG} Unpause Subscription
      </div>
      <div
        className="last-item cancel-subscription"
        onClick={() => {
          setVerticalMoreButton(false)
          setCancelSubscription(sub)
        }}
      >
        {cancelSVG} Cancel Subscription
      </div>
    </MoreDropdownWrapper>
  )

  const handleSubscriptionAction = (sub: Subscription, index: number) => {
    return (
      <Space>
        <MoreButtonWrapper vertical={verticalMoreButton}>
          <Dropdown.Button
            onVisibleChange={(e: any) => {
              if (e === true) setVerticalMoreButton(true)
              if (e === false) setVerticalMoreButton(false)
            }}
            className="opened"
            trigger={['click']}
            overlay={moreDropDown(sub, index)}
          ></Dropdown.Button>
        </MoreButtonWrapper>
      </Space>
    )
  }

  const columns: any = [
    { title: 'Email', dataIndex: ['subscriber', 'email'] },
    { title: planText, dataIndex: ['plan', 'name'] },
    {
      title: billingAddressText,
      key: 'billingAdress',
      render: (subscription: Subscription) => handleBillingAddress(subscription),
    },
    {
      title: statusText,
      key: 'status',
      filters: [
        { text: 'Active', value: 'active' },
        { text: 'Cancelled', value: 'canceled' },
        { text: 'Inactive', value: 'inactive' },
        { text: 'Pending', value: 'pending' },
        { text: 'Paused', value: 'paused' },
      ],
      filterMultiple: false,
      width: '10%',
      render: (subscription: Subscription) => handleStatus(subscription),
    },
    {
      title: startDateText,
      key: 'startDate',
      render: (sub: Subscription) => formatMomentDate(sub.startDate, 'YYYY-MM-DD'),
    },
    {
      title: endDateText,
      key: 'endDate',
      render: (sub: Subscription) => (sub.endDate ? formatMomentDate(sub.endDate, 'YYYY-MM-DD') : '---'),
    },
    {
      title: '',
      key: 'charger_action',
      align: 'right' as 'right',
      render: (sub: Subscription, record: any, index: number) => handleSubscriptionAction(sub, index),
    },
  ]

  const onPageChange = (page: number) => setCurrentPage(page)

  const handleChange: TableProps<any>['onChange'] = (pagination: any, filters: any) => {
    if (!filters.status) {
      setFilter(undefined)
    } else {
      setFilter(filters.status[0])
    }
    setCurrentPage(pagination.current)
  }

  return (
    <>
      <AlertError error={error} />
      {(isDesktop || isLaptop) && (
        <TableStyling>
          <Table
            loading={loading}
            rowKey="id"
            dataSource={subscriptions}
            columns={columns}
            onChange={handleChange}
            pagination={{
              position: ['bottomCenter'],
              total: pagination?.totalEntries,
              current: pagination?.currentPage,
              pageSize: pagination?.perPage,
              showSizeChanger: false,
              onChange: onPaginationChange,
            }}
          />
        </TableStyling>
      )}
      {(isMobile || isTablet) && (
        <>
          <SubscriptionCollapseView
            loading={loading}
            subscriptions={subscriptions}
            pauseSub={(sub) => setPauseSubscription(sub)}
            unpauseSub={(sub) => setUnpauseSubscription(sub)}
            cancelSub={(sub) => setCancelSubscription(sub)}
          />
          <br />
          <Pagination
            current={currentPage}
            onChange={onPageChange}
            total={pagination?.totalEntries}
            showQuickJumper={false}
            showSizeChanger={false}
            showTitle={true}
            style={{ textAlign: 'center' }}
          />
        </>
      )}

      {pauseSubscription && (
        <PauseSubscriptionRedesignModal
          subscription={pauseSubscription}
          onCancel={() => setPauseSubscription(undefined)}
          onPauseSubscription={() => handlePauseSubscription(pauseSubscription)}
        />
      )}
      {unpauseSubscription && (
        <UnpauseSubscriptionRedesignModal
          subscription={unpauseSubscription}
          onCancel={() => setUnpauseSubscription(undefined)}
          onUnpauseSubscription={() => handleUnpauseSubscription(unpauseSubscription)}
        />
      )}
      {cancelSubscription && (
        <CancelSubscriptionRedesignModal
          subscription={cancelSubscription}
          onCancel={() => setCancelSubscription(undefined)}
          onCancelSubscription={() => handleCancelSubscription(cancelSubscription)}
        />
      )}
      {showInviteForm && (
        <CreateSubscriptionRedesignForm onCancel={onUserInvited} onSubscribe={handleNewSubscription} />
      )}
    </>
  )
}
