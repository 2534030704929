import { theme } from '../../theme'

export const checkmarkSVG = (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5 5.5L7.04217 13.5L4.5 10.773"
      stroke={theme.colors.neonGreen}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
