import { useState } from 'react'
import { GitlabFilled, GlobalOutlined } from '@ant-design/icons'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Drawer, Layout, Menu, MenuProps, Row } from 'antd'
import { Link, useLocation } from 'react-router-dom'

import { useNetwork } from '../../../context/network'
import { renderFormatMessage } from '../../../helpers/intl'
import { checkDriverAccess, isManagerToTenants, isTenantManager } from '../../../models/user'
import { useAppState } from '../../../state'
import { styled } from '../../../theme'
import { Links, Paths } from '../../routes/paths'
import { listingWhiteSVG } from '../../../assets/svg/listing'
import { pieChartSVG } from '../../../assets/svg/pieChart'
import { plugWhiteSVG } from '../../../assets/svg/plug'
import { transactionWhiteSVG } from '../../../assets/svg/transaction'
import { tenantWhiteSVG } from '../../../assets/svg/tenant'
import { userWhiteSVG } from '../../../assets/svg/user'
import { multiArrow } from '../../../assets/svg/arrow'

const { Sider } = Layout
const NAVIGATION_COLLAPSE_SIZE = 70

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
`
interface props {
  collapsed?: boolean
  skeleton?: boolean
  handleToggleMenu(): void
}

interface IProps {
  collaped: boolean
  isDesktop: boolean
}

const NavigationMenuWrapper = styled.div<IProps>`
  ${({ isDesktop, collaped }) =>
    !isDesktop &&
    !collaped &&
    `
    position: fixed;
    z-index: 1000;
    `}

  .swtch-icon {
    margin: 2.1rem 2.28rem auto 2.6rem;
  }

  svg:not(:root) {
    overflow: visible;
  }

  .ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-dark.ant-menu-inline-collapsed li.ant-menu-item {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    margin: 0 auto 0.3rem;
    padding: 0;
    display: -webkit-flex;
    display: flex;
    justify-items: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
  }

  li.ant-menu-item:not(.ant-menu-item-selected) svg {
    path,
    circle,
    ellipse {
      opacity: 0.5;
    }
  }

  .ant-menu-dark .ant-menu-item > span > a {
    color: ${(props) => props.theme.colors.white};
  }

  .ant-menu-dark .ant-menu-item:not(.ant-menu-item-selected):hover {
    background-color: #0e1412;
    .ant-menu-title-content {
      a {
        color: ${(props) => props.theme.colors.white};
      }
    }
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: ${(props) => props.theme.colors.softBlack};
  }

  .ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-dark.ant-menu-inline-collapsed .ant-menu-title-content,
  .ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark .ant-menu-title-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark
    li.ant-menu-item:last-child.ant-menu-item-only-child
    .ant-menu-title-content {
    justify-content: end;
  }

  .ant-menu-title-content a {
    margin-left: 10px;
  }

  .ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed .ant-menu-item a {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }

  .ant-menu-item.ant-menu-item-selected svg {
    path,
    circle,
    ellipse {
      fill: ${(props) => props.theme.colors.neonGreen};
    }
  }

  .ant-tooltip-inner > svg {
    display: none !important;
  }

  .toggleNavigation {
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    background-color: rgb(0, 0, 0);
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
  }

  .toggleNavigation.expanded {
    right: 0;
  }

  .toggleNavigation svg {
    transform: translateX(-50%);
    margin: 2rem auto;
  }

  .toggleNavigation.expanded svg {
    position: absolute;
    bottom: 0;
    right: 2.9rem;
    transform: rotate(180deg) translateY(-25%);
  }

  .ant-menu-item {
    border-radius: 46px;
  }

  .ant-menu-dark.ant-menu-inline .ant-menu-item {
    width: 92.4%;
    margin: 4px auto;
    padding-left: 16.5px !important;
  }

  .ant-menu-dark .ant-menu-item-selected > span > a {
    color: ${(props) => props.theme.colors.neonGreen};
  }

  .toggleNavigation::before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #ffff;
    left: 0;
    bottom: 5.7rem;
    position: absolute;
    display: block;
    opacity: 0.3;
  }

  .ant-menu.ant-menu-root {
    padding: 0;
    margin: 4.1rem 1rem auto 1rem;
    height: 100%;
  }
`

export const Navigation: React.FC<props> = ({ skeleton, handleToggleMenu }) => {
  const { currentUser, IsMobile, IsDesktop, selectedTenant, language, logout, setLanguage } = useAppState()
  const location = useLocation()
  const network = useNetwork()
  const displaySkeleton = skeleton ? skeleton : false
  const isMobile = IsMobile()
  const isDesktop = IsDesktop()

  const [collapsed, setCollapsed] = useState(true)

  const changeLocal = () => {
    switch (language) {
      case 'en':
        setLanguage('fr')
        break
      case 'fr':
        setLanguage('en')
        break
    }
  }

  const handleLogout: MenuProps['onClick'] = (e) => {
    if (e.key === 'logout') {
      logout()
      window.location.replace(Links.auth0login())
    }
  }

  const canDriverAccess =
    currentUser?.role === 'admin' ||
    isTenantManager(selectedTenant) ||
    (currentUser?.role === 'user' && checkDriverAccess(currentUser?.accesses))

  const canMangeTenant =
    currentUser?.role === 'admin' ||
    isTenantManager(selectedTenant) ||
    (currentUser?.role === 'user' && isManagerToTenants(currentUser.accesses))

  const MenuSelection = (
    <>
      <Menu defaultSelectedKeys={[location.pathname]} mode="inline" theme="dark">
        {canDriverAccess && (
          <>
            <Menu.Item key={Paths.dashboard} disabled={displaySkeleton}>
              {pieChartSVG}
              <Link
                to={Links.dashboard}
                className="paragraph-01-regular"
                onClick={() => {
                  document.querySelector('body')?.classList.add('redesignActive')
                }}
              >
                {renderFormatMessage('dashboard.navigation.dashboard', 'Dashboard')}
              </Link>
            </Menu.Item>
            <Menu.Item key={Paths.chargers} disabled={displaySkeleton}>
              {plugWhiteSVG}
              <Link
                to={Links.chargers()}
                className="paragraph-01-regular"
                onClick={() => {
                  document.querySelector('body')?.classList.remove('redesignActive')
                }}
              >
                {renderFormatMessage('dashboard.navigation.chargers', 'Chargers')}
              </Link>
            </Menu.Item>
            <Menu.Item key={Paths.transactions} disabled={displaySkeleton}>
              {transactionWhiteSVG}
              <Link
                to={Links.transactions()}
                className="paragraph-01-regular"
                onClick={() => {
                  document.querySelector('body')?.classList.remove('redesignActive')
                }}
              >
                {renderFormatMessage('dashboard.navigation.transactions', 'Transactions')}
              </Link>
            </Menu.Item>
          </>
        )}
        {canMangeTenant && (
          <Menu.Item key={Paths.listings} disabled={displaySkeleton}>
            {listingWhiteSVG}
            <Link
              to={Links.listings()}
              className="paragraph-01-regular"
              onClick={() => {
                document.querySelector('body')?.classList.remove('redesignActive')
              }}
            >
              {renderFormatMessage('dashboard.navigation.listings', 'Listings')}
            </Link>
          </Menu.Item>
        )}
        {currentUser?.role !== 'user' && (
          <>
            <Menu.Item key={Paths.tenants} disabled={displaySkeleton}>
              {tenantWhiteSVG}
              <Link
                to={Links.tenants()}
                className="paragraph-01-regular"
                onClick={() => {
                  document.querySelector('body')?.classList.remove('redesignActive')
                }}
              >
                {renderFormatMessage('dashboard.navigation.tenants', 'Tenants')}
              </Link>
            </Menu.Item>
            {/* <Menu.Item key={Paths.reports} disabled={displaySkeleton}>
              {reportWhiteSVG}
              <Link
                to={Links.reports()}
                className="paragraph-01-regular"
                onClick={() => {
                  document.querySelector('body')?.classList.remove('redesignActive')
                }}
              >
                {renderFormatMessage('dashboard.navigation.reports', 'Reports')}
              </Link>
            </Menu.Item> */}
          </>
        )}
        {canMangeTenant && (
          <Menu.Item key={Paths.users} disabled={displaySkeleton}>
            {userWhiteSVG}
            <Link
              to={Links.users()}
              className="paragraph-01-regular"
              onClick={() => {
                document.querySelector('body')?.classList.add('redesignActive')
              }}
            >
              {renderFormatMessage('dashboard.navigation.users', 'UsersRedesign')}
            </Link>
          </Menu.Item>
        )}
        {currentUser?.role === 'admin' && canMangeTenant && (
          <Menu.Item key={Paths.classicUsersPage} disabled={displaySkeleton}>
            <GitlabFilled style={{ fontSize: '18px', marginTop: '4px' }} />
            <Link
              to={Links.classicUsersPage()}
              className="paragraph-01-regular"
              onClick={() => {
                document.querySelector('body')?.classList.add('redesignActive')
              }}
            >
              {renderFormatMessage('dashboard.navigation.usersv2', 'Users')}
            </Link>
          </Menu.Item>
        )}
        {isMobile ? (
          <>
            <Menu.SubMenu
              key="language"
              title="EN | FR"
              icon={<GlobalOutlined />}
              style={{ bottom: '40px', position: 'absolute', marginLeft: '-24px' }}
            >
              <Menu.Item disabled={language === 'en' ? true : false} key="en" onClick={changeLocal}>
                EN - ENGLISH
              </Menu.Item>
              <Menu.Item disabled={language === 'fr' ? true : false} key="fr" onClick={changeLocal}>
                FR - FRANCAIS
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.Item
              key="logout"
              icon={<FontAwesomeIcon icon={faSignOutAlt} />}
              onClick={handleLogout}
              style={{ position: 'absolute', bottom: 0 }}
            >
              {renderFormatMessage('dashboard.header.logout', 'Logout')}
            </Menu.Item>
          </>
        ) : null}
      </Menu>
      <div className={`toggleNavigation ${collapsed ? '' : 'expanded'}`} onClick={() => setCollapsed(!collapsed)}>
        {multiArrow}
      </div>
    </>
  )

  return (
    <NavigationMenuWrapper className="tesr" collaped={collapsed} isDesktop={isDesktop}>
      {
        <Sider
          collapsed={collapsed}
          collapsedWidth={isMobile && collapsed ? 60 : NAVIGATION_COLLAPSE_SIZE}
          style={{ backgroundColor: '#000' }}
          width={'250px'}
        >
          <Wrapper>
            {collapsed && network.icon}
            {!collapsed && (
              <Row justify="center" style={{ width: '100%' }}>
                <Col span={24}>{network.logo}</Col>
              </Row>
            )}

            {MenuSelection}
          </Wrapper>
        </Sider>
      }
      {isMobile && (
        <Drawer
          closable={false}
          placement="left"
          // visible={collapsed}
          onClose={handleToggleMenu}
          width="54vw"
          drawerStyle={{ backgroundColor: '#000' }}
        >
          <p>{network.logo}</p>
          {MenuSelection}
        </Drawer>
      )}
    </NavigationMenuWrapper>
  )
}
