import React, { useState } from 'react'
import { AutoComplete, message, Spin } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { SelectProps } from 'antd/es/select'

import { SwtchError } from '../../models/error'
import { UserWallet } from '../../models/user'
import { SearchUsers } from '../../services/data-provider/users'
import { debounce } from 'lodash'
import { NoIconSearch } from '../redesign/no-icon-search'
import { searchSVG } from '../../assets/svg/search'
import { useUserTranslation } from '../../hooks/translation/useUserTranslation'

interface props {
  country?: string
  onUserSelected: (user: UserWallet) => void
  onPressEnter: () => void
}

const renderUser = (user: UserWallet): any => {
  return {
    value: user.id,
    user,
    label: (
      <div>
        <UserOutlined /> {user.email || user.name}
      </div>
    ),
  }
}

export const UserSelectorNoTagRedesign: React.FC<props> = ({ onUserSelected, onPressEnter, country }) => {
  const [value, setValue] = useState('')
  const [selected, setSelected] = useState<UserWallet>()
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<SelectProps<object>['options']>([])
  const { emailTxt } = useUserTranslation()
  const ref = React.createRef()

  const searchResult = (value: string) => {
    setLoading(true)
    SearchUsers(value, country)
      .then((users) => {
        const opts = users.map((user) => renderUser(user))
        setOptions(opts)
      })
      .catch((err: SwtchError) => {
        setOptions([])
        message.error(err.description)
      })
      .finally(() => setLoading(false))
  }

  const onSelect = (value: string) => {
    options?.forEach((opt) => {
      if (opt.value === value) {
        setValue(opt.user.email)
        setSelected(opt.user)
        onUserSelected(opt.user)
      }
    })
  }

  const handleOnSearch = (value: string) => {
    options?.forEach((opt) => {
      if (opt.user.email !== null && opt.user.email === selected?.email) {
        setValue(opt.user.email)
        setSelected(opt.user)
        onUserSelected(opt.user)
      }
    })
  }

  return (
    <AutoComplete
      value={value}
      dropdownMatchSelectWidth={false}
      options={options}
      onSelect={onSelect}
      onSearch={debounce(searchResult, 800)}
      notFoundContent={loading ? <Spin size="small" /> : null}
    >
      <NoIconSearch
        ref={ref}
        svg={searchSVG}
        value={value}
        setValue={setValue}
        className="subscription-email-search"
        placeholder={emailTxt}
        onChange={(e) => setValue(e.target.value)}
        onPressEnter={onPressEnter}
        onSearch={handleOnSearch}
      />
    </AutoComplete>
  )
}
