import numeral from 'numeral'

export const formatNumber = (transactionCount: number): string => {
  return numeral(transactionCount).format('0,0[.]00')
}

export const formatEnergy = (energyAmount: number): string => {
  return numeral(energyAmount).format('0,0[.]000')
}

export const formatCentsPrice = (price: number): string => {
  return formatPrice(price / 100.0)
}

export const formatPrice = (price: number): string => {
  let formatStr = '$0,0.00'
  if (price < 0) {
    formatStr = '(' + formatStr + ')'
  }
  return numeral(price).format(formatStr)
}

export const formatDollarsToCents = (price: number): string => (price / 100).toFixed(2)
