import { log } from '../../logger'
import configProvider from '../../config'

import { apiClient } from './client'
import { PropertyMgmtComp } from '../../models/property-management'
import { mockPropertyMgmtComps } from '../../mock/property-management-company-mock'

export async function GetPropertyMgmtComps(): Promise<PropertyMgmtComp[]> {
  const url = '/property_management_companies'

  log('fetching property management companies', { url: url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockPropertyMgmtComps)
  }
  return apiClient(url, {})
}

export async function CreatePropertyMgmtComp(name: string): Promise<PropertyMgmtComp> {
  const url = '/property_management_companies'

  log('create a property management company', { url: url })
  if (configProvider.config.mockEnabled) {
    const newMockPartner = { id: mockPropertyMgmtComps.length + 1, name }
    return Promise.resolve(newMockPartner)
  }
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      property_management_company: {
        name,
      },
    }),
  })
}
