import { Button, Col, Divider, Form, Input, message, Modal, Row, Select, Space, Spin } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import React, { useEffect, useState } from 'react'
import { layout } from '../../atom/form/modal-layout'
import { useFormatMessage } from '../../helpers/intl'
import { SwtchError } from '../../models/error'
import { Tenant, TenantDeployment } from '../../models/tenant'
import { GetTenant, UpdateTenantDeployment } from '../../services/data-provider/tenants'
import { AlertError } from '../error'

interface tenantTabDetailProps {
  tenant: Tenant
}

const RendermModal: React.FC<{
  title: String
  visible: boolean
  OnOk?: (e: any) => void
  OnCancel: (e: any) => void
  onSubmitModalForm: (e: any) => void
  valueToUpdate: string
  form: FormInstance<any>
  confirmLoading: boolean
  warningMessage: string
}> = ({ title, visible, OnCancel, OnOk, onSubmitModalForm, valueToUpdate, form, confirmLoading, warningMessage }) => {
  return (
    <>
      <Modal title={title} visible={visible} onOk={OnOk} onCancel={OnCancel} confirmLoading={confirmLoading}>
        <Form {...layout} onFinish={onSubmitModalForm} autoComplete="off" form={form}>
          <Form.Item
            name={valueToUpdate}
            rules={[
              {
                required: true,
                type: 'string',
                message: warningMessage,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export const TenantDeploymentTab: React.FC<tenantTabDetailProps> = ({ tenant }) => {
  const [defaultTenant, setDefaultTenant] = useState<Tenant>(tenant)
  const { tenantDeployment } = defaultTenant

  const { parkingStalls, loadManagementConfiguration, remoteDesktopIds, electricalService, networkDevice, notes } =
    tenantDeployment || ''
  const [isParkingDisabled, setIsParkingDisabled] = useState(true)
  const [isLoadmanagementConfigurationDisabled, setLoadmanagementConfigurationDisabled] = useState(true)
  const [isremoteDesktopIdDisabled, setRemoteDesktopIdDisabled] = useState(true)
  const [addionalFieldsDisabled, setAdditionalFieldsDisabled] = useState(true)
  const [tenantDeploymentInfo, setTenantDeploymentInfo] = useState<TenantDeployment>(tenantDeployment)
  const [loading, setLoading] = useState<boolean>()
  const [parkingStallVisible, setParkingStallVisible] = useState(false)
  const [loadManagementConfigurationVisible, setLoadManagementConfigurationVisible] = useState(false)
  const [remoteDesktopIdVisible, setRemoteDesktopIdVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = React.useState(false)

  const [parkingStallform] = Form.useForm()
  const [loadManagementForm] = Form.useForm()
  const [remoteDesktopIdsForm] = Form.useForm()
  const [additionalFieldsForm] = Form.useForm()
  const [modalForm] = Form.useForm()
  const [error, setError] = useState<SwtchError>()

  const { Option } = Select

  const parkingStallHeading = useFormatMessage('dashboard.tenantPage.deployment.parkingstall.heading', 'Parking Stall')

  const loadManagementConfigurationText = useFormatMessage(
    'dashboard.tenantPage.deployment.loadmanagementconfiguration.heading',
    'Load Management Configuration',
  )

  const remoteDesktopIDs = useFormatMessage(
    'dashboard.tenantPage.deployment.remotedesktopids.heading',
    'Remote Desktop IDs',
  )

  const electricalServiceHeading = useFormatMessage(
    'dashboard.tenantPage.deployment.electricalservice.heading',
    'Electrical Service',
  )
  const networkDeviceHeading = useFormatMessage(
    'dashboard.tenantPage.deployment.networkdevice.heading',
    'Network Device',
  )
  const notesHeading = useFormatMessage('dashboard.tenantPage.deployment.notes.heading', 'Notes')

  const saveHeading = useFormatMessage('dashboard.tenantPage.deployment.btn.save', 'Save')
  const modifyBtnHeading = useFormatMessage('dashboard.tenantPage.deployment.btn.modify', 'Modify')
  const addFieldHeading = useFormatMessage('dashboard.tenantPage.deployment.btn.addfield', 'Add Field')
  const cancelHeading = useFormatMessage('dashboard.tenantPage.deployment.btn.cancel', 'Cancel')

  const deploymentUpadtedMssg = useFormatMessage(
    'dashboard.tenantPage.deployment.successmsg',
    'Deployment Information Updated',
  )

  const addNewParkingStallFieldHeading = useFormatMessage(
    'dashboard.tenantPage.deployment.modal.addnewparkingstall.heading',
    'Add New Parking Stall Field',
  )
  const addloadManagementFieldHeading = useFormatMessage(
    'dashboard.tenantPage.deployment.modal.loadmanagementconfiguration.heading',
    'Add New Load Management Configuration Field',
  )
  const addremoteDesktopIDHeading = useFormatMessage(
    'dashboard.tenantPage.deployment.modal.remotedesktopids.heading',
    'Add New Remote Desktop ID',
  )

  const blankFieldWarningHeading = useFormatMessage(
    'dashboard.tenantPage.deployment.field.warning',
    'Field cannot be blank!',
  )

  useEffect(() => {
    setLoading(true)
    GetTenant(Number(defaultTenant.id))
      .then((tenant) => {
        setDefaultTenant(tenant)
        return tenant
      })
      .catch((err: SwtchError) => setError(err))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    defaultTenant &&
      loading &&
      UpdateTenantDeployment(defaultTenant.id, tenantDeploymentInfo)
        .then((tenant) => {
          setDefaultTenant(tenant)
        })
        .catch((err: SwtchError) => {
          setError(err)
        })
        .finally(() => {
          setIsParkingDisabled(true)
          setParkingStallVisible(false)
          setLoadManagementConfigurationVisible(false)
          setRemoteDesktopIdVisible(false)

          setLoadmanagementConfigurationDisabled(true)
          setRemoteDesktopIdDisabled(true)
          setAdditionalFieldsDisabled(true)

          setConfirmLoading(false)
          setIsParkingDisabled(true)

          setLoading(false)
        })
  }, [tenantDeploymentInfo])

  const onFinish = (values: any, key: string) => {
    let updatedTenantDeploymentObj: TenantDeployment = tenantDeployment

    if (key !== 'additionalFields') {
      const updatedValues: string[] = [...Object.values(values)] as string[]
      switch (key) {
        case 'parkingStalls':
          updatedTenantDeploymentObj = {
            ...tenantDeployment,
            parkingStalls: Object.values(updatedValues),
          }
          break
        case 'loadManagementConfiguration':
          updatedTenantDeploymentObj = {
            ...tenantDeployment,
            loadManagementConfiguration: Object.values(updatedValues),
          }
          break
        case 'remoteDesktopIds':
          updatedTenantDeploymentObj = {
            ...tenantDeployment,
            remoteDesktopIds: Object.values(updatedValues),
          }
          break
      }
    } else if (key === 'additionalFields') {
      updatedTenantDeploymentObj = {
        ...tenantDeployment,
        electricalService: values['electricalService'],
        networkDevice: values['networkDevice'],
        notes: values['notes'],
      }
    }

    setLoading(true)
    setTenantDeploymentInfo(updatedTenantDeploymentObj)
    message.success(deploymentUpadtedMssg)
  }

  const onSubmitModalForm = (value: any, key: string) => {
    let updatedTenantDeploymentObj: TenantDeployment = tenantDeployment

    switch (key) {
      case 'parkingStalls':
        let updatedParkingStalls: string[]
        if (parkingStalls) {
          updatedParkingStalls = [...parkingStalls, value[key]] as string[]
        } else {
          updatedParkingStalls = [value[key]] as string[]
        }
        updatedTenantDeploymentObj = {
          ...tenantDeployment,
          parkingStalls: updatedParkingStalls,
        }
        break
      case 'loadManagementConfiguration':
        let updatedPloadManagementConfiguration: string[]
        if (loadManagementConfiguration) {
          updatedPloadManagementConfiguration = [...loadManagementConfiguration, value[key]] as string[]
        } else {
          updatedPloadManagementConfiguration = [value[key]] as string[]
        }
        updatedTenantDeploymentObj = {
          ...tenantDeployment,
          loadManagementConfiguration: updatedPloadManagementConfiguration,
        }

        break
      case 'remoteDesktopIds':
        let updatedRemoteDesktopIds: string[]
        if (remoteDesktopIds) {
          updatedRemoteDesktopIds = [...remoteDesktopIds, value[key]] as string[]
        } else {
          updatedRemoteDesktopIds = [value[key]] as string[]
        }
        updatedTenantDeploymentObj = {
          ...tenantDeployment,
          remoteDesktopIds: updatedRemoteDesktopIds,
        }

        break
    }

    setLoading(true)
    setConfirmLoading(true)
    setTenantDeploymentInfo(updatedTenantDeploymentObj)
    message.success(deploymentUpadtedMssg)
  }

  return (
    <Spin spinning={loading}>
      <AlertError error={error} />
      <Row align="middle" justify="center">
        <Col xs={24} sm={24} md={16} lg={12} xxl={12}>
          <Form
            autoComplete="off"
            {...layout}
            form={additionalFieldsForm}
            onFinish={() => onFinish(additionalFieldsForm.getFieldsValue(), 'additionalFields')}
          >
            <Form.Item label={electricalServiceHeading} name="electricalService" initialValue={electricalService}>
              <Input disabled={addionalFieldsDisabled} />
            </Form.Item>

            <Form.Item label={networkDeviceHeading} name="networkDevice" initialValue={networkDevice}>
              <Select defaultValue={networkDevice} disabled={addionalFieldsDisabled}>
                <Option value="SWTCH modem">SWTCH modem</Option>
                <Option value="Cellular">Cellular</Option>
                <Option value="Building Wi-Fi">Building Wi-Fi</Option>
                <Option value="Wired Ethernet (RJ45)">Wired Ethernet (RJ45)</Option>
                <Option value="Other">Other</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={notesHeading}
              name="notes"
              initialValue={notes}
              rules={[{ max: 65535 }, { required: false }]}
            >
              <Input.TextArea rows={10} maxLength={65535} disabled={addionalFieldsDisabled} showCount />
            </Form.Item>
            <Form.Item
              {...{
                wrapperCol: { md: { offset: 12, span: 16 }, xs: { offset: 8, span: 16 } },
              }}
            >
              <Space>
                <Button htmlType="submit" type="primary" disabled={addionalFieldsDisabled}>
                  {saveHeading}
                </Button>

                {addionalFieldsDisabled ? (
                  <Button
                    htmlType="button"
                    onClick={() => setAdditionalFieldsDisabled(false)}
                    disabled={false}
                    type="primary"
                  >
                    {modifyBtnHeading}
                  </Button>
                ) : (
                  <Button
                    htmlType="button"
                    onClick={() => {
                      setAdditionalFieldsDisabled(true)
                    }}
                    danger
                  >
                    {cancelHeading}
                  </Button>
                )}
              </Space>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Divider />
      <Row align="middle" justify="center">
        <Col xs={24} sm={24} md={16} lg={8} xxl={8}>
          <Row align="middle" justify="center">
            <Col xs={24} sm={24} md={16} lg={12} xxl={12}>
              <Row align="middle" justify="center">
                <h3>{useFormatMessage('dashboard.tenantPage.deployment.parkingstalls.heading', 'Parking Stalls')}</h3>
              </Row>
              <Form
                form={parkingStallform}
                onFinish={() => onFinish(parkingStallform.getFieldsValue(), 'parkingStalls')}
                autoComplete="off"
                {...layout}
              >
                {parkingStalls && parkingStalls.length > 0 ? (
                  parkingStalls?.map((obj, index) => {
                    return (
                      <Form.Item
                        key={`${obj}-${index}`}
                        name={`${obj}-${index}`}
                        initialValue={obj}
                        rules={[
                          {
                            required: true,
                            type: 'string',
                            message: blankFieldWarningHeading,
                          },
                        ]}
                      >
                        <Input key={`${obj}-${index}`} placeholder={obj} disabled={isParkingDisabled} />
                      </Form.Item>
                    )
                  })
                ) : (
                  <Form.Item
                    name={'parkingStalls'}
                    rules={[
                      {
                        required: true,
                        type: 'string',
                        message: blankFieldWarningHeading,
                      },
                    ]}
                  >
                    <Input placeholder={parkingStallHeading} disabled={isParkingDisabled} />
                  </Form.Item>
                )}
                <Form.Item
                  {...{
                    wrapperCol: {
                      xxl: { offset: 5, span: 16 },
                      lg: { offset: 0, span: 16 },
                      xs: { offset: 5, span: 16 },
                    },
                  }}
                >
                  <Space>
                    <Button htmlType="submit" type="primary" disabled={isParkingDisabled}>
                      {saveHeading}
                    </Button>

                    {isParkingDisabled ? (
                      <Button
                        htmlType="button"
                        onClick={() => setIsParkingDisabled(false)}
                        disabled={false}
                        type="primary"
                      >
                        {modifyBtnHeading}
                      </Button>
                    ) : (
                      <Button
                        htmlType="button"
                        onClick={() => {
                          setIsParkingDisabled(true)
                        }}
                        danger
                      >
                        {cancelHeading}
                      </Button>
                    )}
                    <Button
                      onClick={() => {
                        modalForm.resetFields(['parkingStalls'])
                        setParkingStallVisible(true)
                      }}
                    >
                      {addFieldHeading}
                    </Button>
                  </Space>
                </Form.Item>
                <RendermModal
                  title={addNewParkingStallFieldHeading}
                  visible={parkingStallVisible}
                  OnOk={modalForm.submit}
                  OnCancel={() => setParkingStallVisible(false)}
                  valueToUpdate={'parkingStalls'}
                  onSubmitModalForm={() => onSubmitModalForm(modalForm.getFieldsValue(), 'parkingStalls')}
                  form={modalForm}
                  confirmLoading={confirmLoading}
                  warningMessage={blankFieldWarningHeading}
                />
              </Form>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={16} lg={8} xxl={8}>
          <Row align="middle" justify="center">
            <Col xs={24} sm={24} md={16} lg={12} xxl={12}>
              <Row align="middle" justify="center">
                <h3>{loadManagementConfigurationText}</h3>
              </Row>
              <Form
                form={loadManagementForm}
                onFinish={() => onFinish(loadManagementForm.getFieldsValue(), 'loadManagementConfiguration')}
                autoComplete="off"
                {...layout}
              >
                {loadManagementConfiguration && loadManagementConfiguration.length > 0 ? (
                  loadManagementConfiguration?.map((obj, index) => {
                    return (
                      <Form.Item
                        key={`${obj}-${index}`}
                        name={`${obj}-${index}`}
                        initialValue={obj}
                        rules={[
                          {
                            required: true,
                            type: 'string',
                            message: blankFieldWarningHeading,
                          },
                        ]}
                      >
                        <Input
                          key={`${obj}-${index}`}
                          placeholder={obj}
                          disabled={isLoadmanagementConfigurationDisabled}
                        />
                      </Form.Item>
                    )
                  })
                ) : (
                  <Form.Item
                    name={'loadManagementConfiguration'}
                    rules={[
                      {
                        required: true,
                        type: 'string',
                        message: blankFieldWarningHeading,
                      },
                    ]}
                  >
                    <Input
                      placeholder={loadManagementConfigurationText}
                      disabled={isLoadmanagementConfigurationDisabled}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  {...{
                    wrapperCol: {
                      xxl: { offset: 5, span: 16 },
                      lg: { offset: 0, span: 16 },
                      xs: { offset: 5, span: 16 },
                    },
                  }}
                >
                  <Space>
                    <Button htmlType="submit" type="primary" disabled={isLoadmanagementConfigurationDisabled}>
                      {saveHeading}
                    </Button>

                    {isLoadmanagementConfigurationDisabled ? (
                      <Button
                        htmlType="button"
                        onClick={() => setLoadmanagementConfigurationDisabled(false)}
                        disabled={false}
                        type="primary"
                      >
                        {modifyBtnHeading}
                      </Button>
                    ) : (
                      <Button
                        htmlType="button"
                        onClick={() => {
                          setLoadmanagementConfigurationDisabled(true)
                        }}
                        danger
                      >
                        {cancelHeading}
                      </Button>
                    )}
                    <Button
                      onClick={() => {
                        modalForm.resetFields(['loadManagementConfiguration'])
                        setLoadManagementConfigurationVisible(true)
                      }}
                    >
                      {addFieldHeading}
                    </Button>
                  </Space>
                </Form.Item>
                <RendermModal
                  title={addloadManagementFieldHeading}
                  visible={loadManagementConfigurationVisible}
                  OnOk={modalForm.submit}
                  OnCancel={() => setLoadManagementConfigurationVisible(false)}
                  valueToUpdate={'loadManagementConfiguration'}
                  onSubmitModalForm={() => onSubmitModalForm(modalForm.getFieldsValue(), 'loadManagementConfiguration')}
                  form={modalForm}
                  confirmLoading={confirmLoading}
                  warningMessage={blankFieldWarningHeading}
                />
              </Form>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={16} lg={8} xxl={8}>
          <Row align="middle" justify="center">
            <Col xs={24} sm={24} md={16} lg={12} xxl={12}>
              <Row align="middle" justify="center">
                <h3>{remoteDesktopIDs}</h3>
              </Row>
              <Form
                form={remoteDesktopIdsForm}
                onFinish={() => onFinish(remoteDesktopIdsForm.getFieldsValue(), 'remoteDesktopIds')}
                autoComplete="off"
                {...layout}
              >
                {remoteDesktopIds && remoteDesktopIds.length > 0 ? (
                  remoteDesktopIds?.map((obj, index) => {
                    return (
                      <Form.Item
                        key={`${obj}-${index}`}
                        name={`${obj}-${index}`}
                        initialValue={obj}
                        rules={[
                          {
                            required: true,
                            type: 'string',
                            message: blankFieldWarningHeading,
                          },
                        ]}
                      >
                        <Input key={`${obj}-${index}`} placeholder={obj} disabled={isremoteDesktopIdDisabled} />
                      </Form.Item>
                    )
                  })
                ) : (
                  <Form.Item
                    name={'remoteDesktopIds'}
                    rules={[
                      {
                        required: true,
                        type: 'string',
                        message: blankFieldWarningHeading,
                      },
                    ]}
                  >
                    <Input placeholder={remoteDesktopIDs} disabled={isremoteDesktopIdDisabled} />
                  </Form.Item>
                )}
                <Form.Item
                  {...{
                    wrapperCol: {
                      xxl: { offset: 5, span: 16 },
                      lg: { offset: 0, span: 16 },
                      xs: { offset: 5, span: 16 },
                    },
                  }}
                >
                  <Space>
                    <Button htmlType="submit" type="primary" disabled={isremoteDesktopIdDisabled}>
                      {saveHeading}
                    </Button>

                    {isremoteDesktopIdDisabled ? (
                      <Button
                        htmlType="button"
                        onClick={() => setRemoteDesktopIdDisabled(false)}
                        disabled={false}
                        type="primary"
                      >
                        {modifyBtnHeading}
                      </Button>
                    ) : (
                      <Button
                        htmlType="button"
                        onClick={() => {
                          setRemoteDesktopIdDisabled(true)
                        }}
                        danger
                      >
                        {cancelHeading}
                      </Button>
                    )}
                    <Button
                      onClick={() => {
                        modalForm.resetFields(['remoteDesktopIds'])
                        setRemoteDesktopIdVisible(true)
                      }}
                    >
                      {addFieldHeading}
                    </Button>
                  </Space>
                </Form.Item>
                <RendermModal
                  title={addremoteDesktopIDHeading}
                  visible={remoteDesktopIdVisible}
                  OnOk={modalForm.submit}
                  OnCancel={() => setRemoteDesktopIdVisible(false)}
                  valueToUpdate={'remoteDesktopIds'}
                  onSubmitModalForm={() => onSubmitModalForm(modalForm.getFieldsValue(), 'remoteDesktopIds')}
                  form={modalForm}
                  confirmLoading={confirmLoading}
                  warningMessage={blankFieldWarningHeading}
                />
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  )
}
