import { theme } from '../../theme'

export const subscribeUserSVG = (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.00042 12.4259C5.60142 12.4259 3.42277 13.5607 2.42871 15.2188M11.7004 6.66875C11.7004 8.15992 10.4916 9.36875 9.00042 9.36875C7.50925 9.36875 6.30042 8.15992 6.30042 6.66875C6.30042 5.17758 7.50925 3.96875 9.00042 3.96875C10.4916 3.96875 11.7004 5.17758 11.7004 6.66875Z"
      stroke={theme.colors.darkGray}
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M15.3 13.8689H13.275M13.275 13.8689H11.25M13.275 13.8689V15.8937M13.275 13.8689V11.8438"
      stroke={theme.colors.darkGray}
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
)
