import { theme } from '../../theme'

export const archiveSVG = (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.1492 5.43398H1.85092M11.2498 7.90897C9.4924 7.90897 6.74976 7.90897 6.74976 7.90897M16.2 5.85891V14.434C16.2 15.4281 15.3942 16.234 14.4 16.234H3.60005C2.60594 16.234 1.80005 15.4281 1.80005 14.434V5.85891C1.80005 5.57947 1.86511 5.30386 1.99008 5.05392L3.22692 2.58025C3.4556 2.12289 3.92305 1.83398 4.43439 1.83398H13.5657C14.077 1.83398 14.5445 2.12289 14.7732 2.58025L16.01 5.05392C16.135 5.30386 16.2 5.57947 16.2 5.85891Z"
      stroke={theme.colors.darkGray}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
