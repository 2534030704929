import { Button, Form, Layout, PageHeader, Skeleton } from 'antd'
import { FormContainer } from '../../atom/form/form-layout'

import { formLayout, tailLayout } from '../../atom/form/page-layout'
import { AlertError } from '../../components/error'

import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { ListingAccessVisibility } from '../../components/listings/listing-access-visibility'
import { ListingAuthorV2 } from '../../components/listings/listing-author-v2'
import { ListingBasic } from '../../components/listings/listing-basic'
import { ListingChargerV2 } from '../../components/listings/listing-charger-v2'
import { ListingCommissionFee } from '../../components/listings/listing-commission-fee'
import { ListingLoitering } from '../../components/listings/listing-loitering'
import { ListingPrice } from '../../components/listings/listing-price'

import { renderFormatMessage } from '../../helpers/intl'
import { useCreateListing } from '../../hooks/useCreateListing'
import { useAppState } from '../../state'
import { ListingPeakShaving } from '../../components/listings/listing-peak-shaving'

const ListingCreateBasePage: React.FC = () => {
  document.querySelector('body')?.classList.remove('redesignActive')
  const { loading, error, initialValues, onFinish, form } = useCreateListing()

  const { currentUser } = useAppState()

  return (
    <PageHeader title={renderFormatMessage('dashboard.createNewListing.txt', 'Create a Listing')}>
      <AlertError error={error} />
      <Skeleton loading={loading}>
        <FormContainer>
          <Layout.Content>
            <Form
              {...formLayout}
              form={form}
              initialValues={initialValues}
              onFinish={onFinish}
              style={{ marginTop: '2rem' }}
            >
              <ListingBasic form={form} />
              <ListingAccessVisibility form={form} />
              <ListingAuthorV2 form={form} />
              <ListingChargerV2 form={form} />
              <ListingPrice form={form} />
              <ListingCommissionFee form={form} />
              <ListingLoitering form={form} />
              {currentUser &&
                (currentUser?.email === 'suleman.rai@swtchenergy.com' ||
                  currentUser?.email === 'jing.guo@swtchenergy.com' ||
                  currentUser?.email === 'alton.yeung@swtchenergy.com' ||
                  currentUser?.email === 'nicole.politis@swtchenergy.com' ||
                  currentUser?.email === 'michael.vu@swtchenergy.com') && <ListingPeakShaving form={form} />}
              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                  {renderFormatMessage('dashboard.createNewListing.submitBtn', 'Submit')}
                </Button>
              </Form.Item>
            </Form>
          </Layout.Content>
        </FormContainer>
      </Skeleton>
    </PageHeader>
  )
}

export const ListingCreatePageV2 = withAuthenticatedLayout(ListingCreateBasePage)
