import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'

export const useListingAccessTranslation = () => {
  const mapAndAccess = renderFormatMessage('dashboard.text.map&access', 'Map & Access')
  const accessControlText = renderFormatMessage(
    'dashboard.createNewListing.listingStep.listingCategory.txt',
    'Access Control',
  )
  const publicOpenText = renderFormatMessage(
    'dashboard.createNewListing.listingStep.listingCategory.publicOption',
    'Open',
  )
  const privateRestrictedText = renderFormatMessage(
    'dashboard.createNewListing.listingStep.listingCategory.privateOption',
    'Restricted',
  )
  const hiddenMapText = useFormatMessage(
    'dashboard.createNewListing.listingStep.hiddenCategory.txt',
    'Listings hidden from map',
  )

  const carRequiredText = useFormatMessage(
    'dashboard.createNewListing.listingStep.carDetailsCategory.txt',
    'Users need car details to book',
  )
  return {
    mapAndAccess,
    accessControlText,
    publicOpenText,
    privateRestrictedText,
    hiddenMapText,
    carRequiredText
  }
}
