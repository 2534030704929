import { renderFormatMessage, useFormatMessage } from "../../helpers/intl"

export const useListingCommissionFeeTranslation = () => {
  const selectComissionFeeText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectComissionFeeText',
    'Select Comission Fee',
  )

  const defineNewComissionTypeText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.defineNewComissionTypeText',
    'Define New Commission Fee Type',
  )
  const defineNewComissionFeeText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.defineNewComissionFeeText',
    'Define New Commission Fee (%)',
  )

  const defineNewComissionTypeWarningText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.defineNewComissionTypeWarningText',
    'Commission fee cannot be empty!',
  )

  const flatFeeText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.flatFeeText',
    'Flat Fee ($)',
  )

  const commissionFeeText = renderFormatMessage('dashboard.createNewListing.commissionTab', 'Commission & Fee')

  const commissionPerecentText = renderFormatMessage('dashboard.createNewListing.comissionStep.txt', 'Commission (%)')

  const guestFeeCents = renderFormatMessage('dashboard.createNewListing.comissionStep.guestFee.txt', 'Guest Fee ($)')

  const enableLoadMgmtAutoStopText = renderFormatMessage(
    'dashboard.createNewListing.priceStep.localLoadManagementAutoStop.txt',
    'Local Load Management: Transaction Auto-stop',
  )
  return {
    selectComissionFeeText,
    defineNewComissionTypeText,
    defineNewComissionFeeText,
    defineNewComissionTypeWarningText,
    flatFeeText,
    commissionFeeText,
    guestFeeCents,
    commissionPerecentText,
    enableLoadMgmtAutoStopText,
  }
}